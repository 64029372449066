import { useMemo } from 'react';
import { Column, TableState, UseSortByColumnOptions } from 'react-table';
import { useMediaQuery, Theme } from '@material-ui/core';
import { TaskNameCell, TaskNameMobileCell } from './cells/TaskNameCell';
import { CompletionStatusCell } from './cells/CompletionStatusCell';
import { Breakpoints } from 'utils/styles/constants';
import { IStoreTaskGridRowData } from './model';
import { ColorStatusCell } from 'modules/task/taskList/components/sharedCells/ColorStatusCell';
import { CreatorCell } from 'modules/task/taskList/components/sharedCells/CreatorCell';
import { DueDateCell } from 'modules/task/taskList/components/sharedCells/DueDateCell';
import { ActionsCell } from './cells/ActionCell';
import { t } from 'i18next';

type StoreTaskGridColumn = (
    Column<IStoreTaskGridRowData>
    & Partial<UseSortByColumnOptions<IStoreTaskGridRowData>>
);

enum ColumnId {
    Task = 'task',
    Status = 'completion-status-store',
    ColorStatus = 'color-status',
    DueDate = 'due-date',
    Creator = 'creator',
    Actions = 'actions',
}

export const initialSortingRules = [
    {
        id: ColumnId.Status,
        desc: false,
    },
];

export const initialSortOptions: Partial<TableState<IStoreTaskGridRowData>> = {
    sortBy: initialSortingRules,
};

export function useColumns(): Array<StoreTaskGridColumn> {
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );

    const columns: Array<StoreTaskGridColumn> = useMemo(() => {
        const taskNameColumn: Array<StoreTaskGridColumn> = isMobile ? [{
            id: ColumnId.Task,
            Header: `${t('Task')}`,
            accessor: 'title',
            Cell: TaskNameMobileCell,
            defaultCanSort: true,
            width: 220,
        }] : [{
            id: ColumnId.Task,
            Header: `${t('Task')}`,
            accessor: 'title',
            Cell: TaskNameCell,
            defaultCanSort: true,
            width: 220,
        }];

        const statusColumn: Array<StoreTaskGridColumn> = isMobile ? [] : [
            {
                id: ColumnId.Status,
                Header: `${t('Completion Status')}`,
                Cell: CompletionStatusCell,
                accessor: 'completionState',
                defaultCanSort: true,
            },
        ];

        return [
            {
                id: ColumnId.ColorStatus,
                accessor: 'completionState',
                Cell: ColorStatusCell,
                defaultCanSort: false,
                disableSortBy: true,
                width: '7px',
            },
            ...taskNameColumn,
            {
                id: ColumnId.DueDate,
                Header: `${t('Due Date')}`,
                accessor: 'dueDate',
                Cell: DueDateCell,
                defaultCanSort: true,
                width: 100,
            },
            ...statusColumn,
            {
                id: ColumnId.Creator,
                Header: `${t('Creator')}`,
                accessor: row => row.createdBy,
                Cell: CreatorCell,
                defaultCanSort: false,
                disableSortBy: true,
                width: 160,
            },
            {
                id: ColumnId.Actions,
                accessor: 'id',
                Cell: ActionsCell,
                defaultCanSort: false,
                disableSortBy: true,
                width: '52px',
            },
        ];
    }, [isMobile]);

    return columns;
}
