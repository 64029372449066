import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { useAttachmentStyles } from './styles';
import { formatSize } from 'utils/helpers/fileHelpers';
import { Trans } from 'react-i18next';

export interface IFileInfoProps {
    type?: string;
    size?: number;
}

function resolveFileInfo(type?: string, size?: number): string {
    const infoString: string[] = [];

    if (type) {
        infoString.push(type.toUpperCase());
    }

    if (typeof size === 'number') {
        infoString.push(formatSize(size));
    }

    return infoString.join(' · ');
}

export function AttachmentInfo({ type, size }: IFileInfoProps) {
    const classes = useAttachmentStyles([]);
    const infoString = useMemo(() => resolveFileInfo(type, size), [type, size]);

    return (
        <Typography variant="caption" className={classes.fileInfo}>
            {infoString || <Trans>No information</Trans>}
        </Typography>
    );
}
