import React, { useEffect, useState } from 'react';
import { Box, Fade, makeStyles, Theme, Typography } from '@material-ui/core';
import { BlueColor, Breakpoints, UtilColors } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        boxSizing: 'border-box',
        height: '100%',
        padding: '20px 20px 20px 0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            padding: '20px',
        },
    },
    wrapper: {
        backgroundColor: UtilColors.White,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
    },
    text: {
        fontFamily: `'Inter', 'Arial', sans-serif`,
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center',
        color: BlueColor.Blue70,
        width: '315px',
    },
}));

export function ChannelPlaceholder(): React.ReactElement {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setVisible(true), 100);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Fade in={visible} timeout={800}>
            <Box className={classes.root}>
                <Box className={classes.wrapper}>
                    <Typography component="span" className={classes.text}>
                        <Trans>Select a channel or send a direct message
                        to start to interact with your team</Trans>
                    </Typography>
                </Box>
            </Box>
        </Fade>
    );
}
