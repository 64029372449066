import React from 'react';
import { useLandingStyles } from 'modules/connect/landingPage/landingStyles';
import { Box, Typography } from '@material-ui/core';
import phoneImage from 'images/phone.png';
import clsx from 'clsx';
import logo from 'images/Logo/LogoLight.png';
import { Trans } from 'react-i18next';

export function LandingPage() {
    const classes = useLandingStyles([]);
    return (
        <Box className={classes.wrapper}>
            <Typography className={classes.comingSoon}>
                <Trans>Coming Soon</Trans>
            </Typography>
            <Box display="flex">
                <Box className={classes.content}>
                    <Typography className={clsx(classes.mainHeader, classes.appName)} variant="h1">
                        <Trans>Connect</Trans>
                    </Typography>
                    <Box className={classes.description}>
                        <span className={classes.appName}><Trans>Connect</Trans></span> <Trans>the entire organization in real-time,
                    develop inclusivity,
                    and protect information with customizable permissions</Trans>.
                    </Box>
                </Box>
                <img className={classes.phoneImage} src={phoneImage}/>
            </Box>
            <img className={classes.logoImage} src={logo}/>
        </Box>
    );
}
