import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { LocationsLabel } from './LocationsLabel';

const boxProps: BoxProps = {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gridColumn: '1/3',
    alignItems: 'start',
};

export type LocationsSectionProps = {
    count?: number;
}

export const LocationsSection: React.FC<LocationsSectionProps> = ({ count, children }) => {
    return (
        <Box {...boxProps}>
            <LocationsLabel count={count} />
            {children}
        </Box>
    );
}
