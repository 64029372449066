import { useDispatch, useSelector } from "react-redux";
import {
    selectIsOpenUserImportModal,
    selectIsSavingBulkStatus,
    selectIsUserBulkActionConfirmationOpen,
    selectUserListLoading,
    selectUsersCheckedOnlyArray
} from "store/modules/user/userSelectors";
import { ListPageLayout } from "modules/shared/components/ListPageLayout";
import { UsersGrid } from "./UsersGrid";
import { useUsersGridData } from "./useUsersGridData";
import { CreateButton } from "modules/shared/components/CreateButton";
import { useHistory } from "react-router";
import { Routes } from "components/router/model";
import { useFeatureState } from "utils/hooks/useFeatureState";
import { FeatureFlags } from "utils/featureFlags";
import React, { useCallback, useMemo, useState } from "react";
import { UserListFilterPanel } from "./UserListFilterPanel";
import { UserListFilter } from "./model";
import { ActivateDeactivateButton, ActivateDeactivateVariant } from '../../shared/components/ActivateDeactivateButton';
import { Box, Theme, makeStyles } from '@material-ui/core';
import { openUserImportModal, openUsersBulkStatusChangeConfirm } from '../../../store/modules/user/userActions';
import { requestUserBulkStatusChange, UserStatusBulkChange } from '../../../api/user/userActions';
import { UserBulkConfirmDialog } from '../user-profile/UserStatusConfirmationBulk';
import { ImportButton } from '../../shared/components/ImportButton';
import { UserImportModal } from '../user-import/UserImportModal';
import { selectIsBulkUsersFileUploading } from '../../../store/modules/files/filesSelectors';
import { selectFeatureFlags } from '../../../store/modules/featureFlags/selectors';
import { SplashPage } from "components/mainContent/SplashPage";
import { selectAppLoadingLoginAs } from "store/modules/auth/authSelectors";
import { Trans } from "react-i18next";
import { t } from "i18next";

const useStyles = makeStyles((theme: Theme) => ({
    containerBtnActions: {
        position:'absolute',
        display:'flex',
        right: 30,
        bottom: 30,
        flexDirection: 'row',
        gap: 4
    }
}));

export const UserListPage = () => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState<UserListFilter>({});
    const data = useUsersGridData(filter);

    const isLoading = useSelector(selectUserListLoading);
    const isSavingBulkStatusUpdate = useSelector(selectIsSavingBulkStatus);

    const history = useHistory();
    const isSearchEnabled = useFeatureState(FeatureFlags.UserSearchByFullName);
    const isBulkDeactivateUsersEnabled = useFeatureState(FeatureFlags.BulkDeactivateUsers);
    const usersSelected = useSelector(selectUsersCheckedOnlyArray);
    const loadingLoginAs = useSelector(selectAppLoadingLoginAs);
    const anyUsersSelected = usersSelected.length > 0;
    const isActiveShown = (filter.UserState ?? '0') === '0';
    const isBulkImportFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.BulkImportUsers]?.enabled;
    const isImportShown = useSelector(selectIsOpenUserImportModal);

    const buttonVariantMain = isActiveShown ? ActivateDeactivateVariant.Deactivate : ActivateDeactivateVariant.Activate;
    const deactActMainText = useMemo(() => {
        return isActiveShown ? `${t('Deactivate')} ` + usersSelected.length + ` ${t('UserSelected', {count: usersSelected.length})}` : `${t('Activate')} ` + usersSelected.length + ` ${t('UserSelected', {count: usersSelected.length})}`;
    }, [isActiveShown, usersSelected]);
    const classes = useStyles();

    const textToShowOnBulkConfirmRaw = useSelector(selectIsUserBulkActionConfirmationOpen);
    const textToShowOnBulkConfirm = useMemo(() => {
        return `${t('Are you sure you want to')} ` + textToShowOnBulkConfirmRaw + ' ' + usersSelected.length + ` ${t('UserSelected', {count: usersSelected.length})}?`;
    }, [textToShowOnBulkConfirmRaw, usersSelected]);
    const isConfirmationOpen = textToShowOnBulkConfirmRaw !== '';
    const confirmationDirection = textToShowOnBulkConfirmRaw === t('Activate')
        ? ActivateDeactivateVariant.Activate : ActivateDeactivateVariant.Deactivate;
    const isUploadingBulk = useSelector(selectIsBulkUsersFileUploading);

    const activateConfirmInitiate = useCallback((direction: ActivateDeactivateVariant) => {
        switch (direction) {
            case ActivateDeactivateVariant.Activate:
                dispatch(openUsersBulkStatusChangeConfirm(`${t('Activate')}`));
                return;
            case ActivateDeactivateVariant.Deactivate:
                dispatch(openUsersBulkStatusChangeConfirm(`${t('Deactivate')}`));
                return;
            default:
                return;
        }
    }, [dispatch]);

    const importInitiate = useCallback(() => {
        dispatch(openUserImportModal(true));
    }, [dispatch])

    const closeConfirmDialog = useCallback(() => {
        dispatch(openUsersBulkStatusChangeConfirm(''));
    }, [dispatch]);

    const processConfirm = useCallback((direction: ActivateDeactivateVariant, notify: boolean) => {
        const payload = {
            activateDirection: direction !== ActivateDeactivateVariant.Activate,
            ids: usersSelected,
            currentFilter: filter,
            notify: notify ?? false,
        } as UserStatusBulkChange;
        dispatch(requestUserBulkStatusChange.init(payload));
    }, [dispatch, usersSelected, filter]);

    return (
        <>
            {loadingLoginAs &&
                <SplashPage isLoginAs />
            }
            <ListPageLayout style={{
                width: '100%',
                overflow: 'hidden',
            }}>
                {isSearchEnabled && (
                    <UserListFilterPanel
                        filter={filter}
                        onFilterChange={setFilter}
                    />
                )}
                <UsersGrid
                    data={data}
                    filter={filter}
                    isFetching={isLoading}
                />
                <Box className={classes.containerBtnActions}>                    
                    {!anyUsersSelected && (
                        <CreateButton
                            children={<Trans>Add New User</Trans>}
                            onClick={() => history.push(Routes.AddUser)}
                            isFixedPosition={false}
                        />
                    )
                    }
                    {isBulkImportFeatureEnabled && (
                        <ImportButton
                            children={isBulkDeactivateUsersEnabled ? `${t('Bulk Actions')}` : `${t('Import users')}`}
                            onClick={() => importInitiate()}
                        />
                    )}
                    {anyUsersSelected && (
                        <ActivateDeactivateButton
                            children={deactActMainText}
                            onClick={() => activateConfirmInitiate(buttonVariantMain)}
                            buttonVariant={buttonVariantMain}
                            count={usersSelected.length}
                        />
                    )
                    }
                </Box>
                <UserBulkConfirmDialog
                    open={isConfirmationOpen}
                    text={textToShowOnBulkConfirm}
                    agreeButtonText={textToShowOnBulkConfirmRaw}
                    discardButtonText={`${t('Cancel')}`}
                    onAgree={(notify) => processConfirm(confirmationDirection, notify)}
                    onDiscard={closeConfirmDialog}
                    isLoading={isSavingBulkStatusUpdate}
                    showConfirmation={confirmationDirection === ActivateDeactivateVariant.Activate}
                    count={usersSelected.length}
                />
                <UserImportModal
                    isOpen={isImportShown}
                    isLoading={isUploadingBulk}
                />
            </ListPageLayout>
        </>
    );
};
