import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Paper, styled } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectUserRoles, selectUserRolesLoading } from 'store/modules/user/userSelectors';
import { UserRoleDTO } from 'api/core/model';
import { RoleSelect } from './RoleSelect';
import { SettingsGrid } from './SettingsGrid';
import { selectRoleSettingsById, selectRoleSettingsLoadingById, selectRoleSettingsUpdatingById } from 'store/modules/knock/knockSelectors';
import { useActions } from 'utils/store/useActions';
import { requestRoleSettings, updateRoleSettings } from 'api/knock/knockActions';
import { RoleSettingsDTO } from 'api/knock/knockModel';
import { Trans } from 'react-i18next';

const SettingsPageContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const RoleSelectContainer = styled(Box)(({ theme }) => ({
    flex: '1 1 auto',
    marginRight: theme.spacing(2),
}));

export const SettingsPage = () => {
    const [role, setRole] = useState<UserRoleDTO | null>(null);
    const [changedRoleSettings, setChangedRoleSettings] = useState<RoleSettingsDTO | null>(null);

    const roles = useSelector(selectUserRoles);
    const loading = useSelector(selectUserRolesLoading);
    const roleSettingsById = useSelector(selectRoleSettingsById);
    const roleSettingsLoadingById = useSelector(selectRoleSettingsLoadingById);
    const roleSettingsUpdatingById = useSelector(selectRoleSettingsUpdatingById);

    const actions = useActions({
        requestRoleSettings: requestRoleSettings.init,
        updateRoleSettings: updateRoleSettings.init,
    });

    const saveClickHandler = useCallback(() => {
        if (role && changedRoleSettings) {
            actions.current.updateRoleSettings({
                roleId: role.roleId,
                settings: changedRoleSettings,
            })
        }
    }, [actions, role, changedRoleSettings]);

    useEffect(() => {
        if (role) {
            actions.current.requestRoleSettings(role.roleId);
        }
    }, [actions, role]);

    const roleSettings = role && roleSettingsById[role.roleId];
    const roleSettingsLoading = role && roleSettingsLoadingById[role.roleId];
    const roleSettingsUpdating = role && roleSettingsUpdatingById[role.roleId];

    return (
        <SettingsPageContainer>
            <StyledPaper>
                <RoleSelectContainer>
                    <RoleSelect
                        isLoading={loading}
                        roles={roles}
                        onRoleChange={setRole}
                    />
                </RoleSelectContainer>
                <Box>
                    <Button
                        disabled={loading || roleSettingsUpdating || !role}
                        onClick={saveClickHandler}
                        variant="contained"
                        color="primary"
                    >
                        <Trans>Save</Trans>
                    </Button>
                </Box>
            </StyledPaper>
            {(roleSettingsLoading || roleSettingsUpdating) && (
                <LinearProgress />
            )}
            {roleSettings && !roleSettingsLoading && (
                <SettingsGrid
                    roleSettings={roleSettings}
                    onRoleSettingsChange={setChangedRoleSettings}
                    disabled={!!roleSettingsUpdating}
                />
            )}
        </SettingsPageContainer>
    );
};
