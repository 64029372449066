
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';

export const LabelFormikRadio = ({ ...props}: { [x: string]: any; name: string}) => {

    const useStyles = makeStyles({
        root: {
            margin: "5px",
            borderRadius: '5px',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '5%',
            width: 20,
            height: 20,
            'input:hover ~ &': {
                backgroundColor: 'rgba(255,255,255,0.5)',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#fff',
            borderRadius: '50%',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
                borderRadius: '50%'
            },
            'input:hover ~ &': {
                backgroundColor: '#fff',
                borderRadius: '50%'
            },
        },
    });

    const classes = useStyles();

    const [field] = useField({ ...props, type: "radio"});

    return (
        <Radio
        {...field} 
        className={classes.root}
        disableRipple
        checkedIcon={<span className={classes.checkedIcon + " " + classes.icon} />}
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': props.value }}
        style={{ backgroundColor: props.value }}
    />
    );
  };

