import { RegularTaskType } from "api/task/editTaskCreator/editTaskCreatorModel";
import { createSingleAction } from "utils/store/actionUtils";

export const EDIT_TASK_CREATOR_EDITION = 'edit-task-creator/EDITION';
export const changeEdition = createSingleAction<boolean, typeof EDIT_TASK_CREATOR_EDITION>(
  EDIT_TASK_CREATOR_EDITION,
);

export const TASK_CREATOR = 'edit-task-creator/TASK_CREATOR';
export const currentTaskCreator = createSingleAction<string | undefined, typeof TASK_CREATOR>(
  TASK_CREATOR,
);

export const TASK_CREATOR_TASK_TYPE = 'edit-task-creator/TASK_CREATOR_TASK_TYPE';
export const taskCreatorTaskType = createSingleAction<string, typeof TASK_CREATOR_TASK_TYPE>(
  TASK_CREATOR_TASK_TYPE,
);

export const UPDATE_TASK_CREATOR = 'edit-task-creator/UPDATE_TASK_CREATOR';
export const updateTaskCreator = createSingleAction<RegularTaskType, typeof UPDATE_TASK_CREATOR>(
  UPDATE_TASK_CREATOR,
);

export type EditTaskCreatorActions =(
  | ReturnType<typeof changeEdition>
  | ReturnType<typeof currentTaskCreator>
  | ReturnType<typeof taskCreatorTaskType>
  | ReturnType<typeof updateTaskCreator>
);

