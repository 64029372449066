import React, { useCallback } from 'react';
import {
    Box,
    FormControl,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { useUserRoles } from './hooks';
import { UtilColors } from 'utils/styles/constants';
import { UserRoleDTOExtended } from 'api/core/model';
import { MultiSelect } from '../../../../../../../components/basicInputs/selectInput/MultiSelect';
import { RoleInviteItem } from './RoleInviteItem';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '90vw',
        maxWidth: '500px',

        [theme.breakpoints.down(510)]: {
            width: '70vw',
        },
    },
    formControl: {
        width: theme.spacing(40),

        [theme.breakpoints.down(410)]: {
            width: '70vw',
        },

        '& .Mui-focused .MuiSelect-select': {
            background: UtilColors.White,
        },
    },
    select: {
        width: '100%',
    },
}));

export interface ISelectRolesProps {
    roles: ReturnType<typeof useUserRoles>;
}

export function SelectRoles({ roles }: ISelectRolesProps) {
    const classes = useStyles();

    const nameGetter = useCallback(x => (x as UserRoleDTOExtended).description, []);

    return (
        <Box className={classes.container}>
            <FormControl className={classes.formControl} variant="outlined">
                <MultiSelect
                    options={roles.availableExtended}
                    getName={nameGetter}
                    RenderValue={RoleInviteItem}
                    RenderPreview={RoleInviteItem}
                    name="roles"
                    label={<Trans>User roles</Trans>}
                />
            </FormControl>
        </Box>
    );
}
