import React from 'react';
import { Box, List, makeStyles } from '@material-ui/core';
import { AttachmentPlainItem } from 'modules/task/attachments/components/AttachmentPlainItem/AttachmentPlainItem';
import { Dropzone } from 'modules/task/attachments/components/Dropzone';
import { EDropzoneVariant } from 'modules/task/attachments/components/Dropzone/Dropzone';
import { StoreTaskAttachmentModel, StoreTaskChecklistModel } from 'api/task/storeTask/storeTaskModel';
import { AttachmentsGallery } from 'modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery';
import { useDropHandler, useRemoveHandler } from './hooks';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { StyledBoxChecklistAudit } from './StoreTaskChecklistAvatarCompleted';

interface IStoreTaskChecklistFilesProps {
    checklistItem: StoreTaskChecklistModel,
    taskId: string | undefined,
    disabled?: boolean,
}

export const useStoreTaskChecklistFilesStyles = makeStyles(() => ({
    filesList: {
        width: '100%',
    },
    attachmentsListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        width: '100%',
    },
}));

export function StoreTaskChecklistFiles({ checklistItem, taskId, disabled }: IStoreTaskChecklistFilesProps) {
    const classes = useStoreTaskChecklistFilesStyles([]);
    const dropHandler = useDropHandler(taskId || '', checklistItem.id);
    const onAttachmentRemove = useRemoveHandler(taskId || '', checklistItem.id);
    const uploadedFiles: StoreTaskAttachmentModel[] = [];
    const uploadingFiles: StoreTaskAttachmentModel[] = [];
    const isTasksChecklistAuditEnabled = useFeatureState(FeatureFlags.TasksChecklistAudit);

    checklistItem.attachments.forEach(attachment =>
        attachment.fileId === attachment.name
            ? uploadingFiles.push(attachment)
            : uploadedFiles.push(attachment),
    );

    return (
        <Box display="flex" width="100%">
            {isTasksChecklistAuditEnabled && <StyledBoxChecklistAudit />}
            <Box ml={3} width="100%">
                {uploadedFiles.length > 0 && (
                    <AttachmentsGallery
                        attachments={uploadedFiles}
                        onDeleteHandler={disabled ? undefined : onAttachmentRemove}
                    />
                )}

                {uploadingFiles.length > 0 && (
                    <List className={classes.filesList}>
                        {uploadingFiles.map(attachment => (
                            <AttachmentPlainItem
                                key={attachment.fileId}
                                attachment={attachment}
                                className={classes.attachmentsListItem}
                                loading={attachment.fileId === attachment.name}
                                onRemove={onAttachmentRemove}
                                disabled={disabled}
                            />
                        ))}
                    </List>
                )}

                {checklistItem.requiresAttachment && (
                    <Box>
                        <Dropzone
                            onDrop={dropHandler}
                            variant={EDropzoneVariant.Light}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
}
