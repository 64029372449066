import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Link, Typography, styled } from "@material-ui/core";

import { PageLoader } from "components/router/PageLoader";
import { selectUserCompany, selectUserCompanyFetching } from "store/modules/company/companySelector";
import { FormPageView } from "modules/shared/components/FormPageView";
import { ListPageLayout } from "modules/shared/components/ListPageLayout";
import { CompanyTab } from "./CompanyTab";
import { UtilColors } from "utils/styles/constants";
import { createLegacyPage } from "modules/legacyWrappers/createLegacyPage";
import { useFeatureState } from "utils/hooks/useFeatureState";
import { FeatureFlags } from "utils/featureFlags";
import { Trans } from "react-i18next";

const CopyRigthStyledPaper = styled(Box)(({ theme }) => ({
    position:'absolute',
    bottom:'10px',
    left:'0px',
    width:'100%',
    height:'35px',
    display:'flex',
    alignItems:'center',
    paddingLeft: theme.spacing(3),
    backgroundColor: UtilColors.White
}));

export const CompanyProfilePage = () => {
    const isNewCompanyProfileEnabled = useFeatureState(FeatureFlags.NewCompanyProfile);
    const RouteComponent = useMemo(() => createLegacyPage('Admin/Company'), []);

    if(!isNewCompanyProfileEnabled){
        return <RouteComponent />;
    }
    return <NewCompanyProfilePage />
}

const NewCompanyProfilePage = () => {
    const companyInformation = useSelector(selectUserCompany);
    const companyFetching = useSelector(selectUserCompanyFetching);

    if (companyFetching || !companyInformation ) {
        return <PageLoader />;
    }
    const year = new Date().getFullYear();
    return (
        <ListPageLayout style={{
            width: '100%',
            overflow: 'hidden',
            position:"relative"
        }}>
            <FormPageView
                title={<><Trans>Company Profile:</Trans> {companyInformation?.name}</>}
            >
                <CompanyTab />
            </FormPageView>
            <CopyRigthStyledPaper>
                <Typography variant="body2" style={{fontSize:9, color: '#90A4AE'}}>
                    <Trans>Copyright</Trans> © {year} <Link href="https://progressretail.com/" target="_blank" style={{color: '#1E9FF2'}}>Progress Retail</Link>, <Trans>All rights reserved</Trans>.
                </Typography>
            </CopyRigthStyledPaper>
        </ListPageLayout>
    );
};
