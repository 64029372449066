import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { CircularProgress, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { User as StreamUser, StreamChat } from 'stream-chat';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserCompany } from 'store/modules/company/companySelector';
import { AutocompleteUser } from '../components/AutocompleteUser';
import { requestUserInfos } from 'store/modules/user/userActions';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type User = {
    id: string;
    name: string;
}

export function createAutocompleteUser(user: StreamUser) {
    return {
        id: user.id,
        name: user.name ?? ''
    }
}

export function getUserLabel(user: User) {
    return user.name;
}

export function renderOption(option: User) {
    return (
        <AutocompleteUser {...option} />
    )
}

export function useQueryUsers(
    client: StreamChat,
) {
    const dispatch = useDispatch();
    const [isGettingUsers, setIsGettingUsers] = useState(false);
    const [foundUsers, setFoundUsers] = useState<Array<User>>([]);
    const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
    const userCompany = useSelector(selectUserCompany);

    const filterNotSelected = useCallback(
        (user: User) => !selectedUsers.some(foundUser => foundUser.id === user.id),
        [selectedUsers],
    );

    const filterNotSelf = useCallback(
        (user: User) => user.id !== client.userID,
        [client?.userID],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getUsers = useCallback(debounce((value: any) => {
        if (!userCompany) return;
        setIsGettingUsers(true);
        client
            .queryUsers({   
                name: { $autocomplete: value },
                teams: { $contains: userCompany.id },
            })
            .then(({ users }) => users.map(createAutocompleteUser))
            .then(users => users.filter(user => filterNotSelected(user) && filterNotSelf(user)))
            .then(users => {
                setFoundUsers(users);
                dispatch(requestUserInfos(users.map(({ id }) => id)));
            })
            .finally(() => setIsGettingUsers(false));
    }, 300), [client, filterNotSelected, filterNotSelf, userCompany]);

    const handleInputChange = useCallback((event: any) => {
        if (!event?.target?.value?.trim()) {
            return;
        }
        getUsers(event.target.value);
    }, [getUsers]);

    const handleSelectorChange = useCallback((event, users) => {
        if (users === null) {
            setSelectedUsers([]);
            return;
        }
        if (Array.isArray(users)) {
            setSelectedUsers(users);
            return;
        }
        setSelectedUsers([users]);
    }, []);

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        const adornment = (
            <React.Fragment>
                {isGettingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
            </React.Fragment>
        );
        return (
            <TextField
                {...params}
                label={<Trans>Search people</Trans>}
                placeholder={`${t('Start typing')}...`}
                variant="outlined"
                onChange={handleInputChange}
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: adornment,
                }}
            />
        );
    }, [handleInputChange, isGettingUsers]);

    return {
        selectedUsers,
        foundUsers,
        setFoundUsers,
        setSelectedUsers,
        renderInput,
        handleSelectorChange,
    };
}
