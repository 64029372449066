import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { ChildTaskWithNested } from 'modules/task/taskList/views/TaskByLocationsView/TaskByLocationsView';
import { colorMap, colorMapDisabled } from 'modules/task/taskList/model';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../../utils/featureFlags';

export function ColorStatusChildCell({ value, row }: CellProps<ChildTaskWithNested>) {
    const { original } = row;
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isOverdueEnabled = isOverdueColumnsFeature?.enabled ?? false;

    const colorKey = useMemo(() => {
        if (original.isSkeleton) {
            return DisplayedTaskStatus.Default;
        }

        return value as DisplayedTaskStatus;
    }, [original, value]);

    return (
        <Box
            width="7px"
            minHeight="52px"
            height="100%"
            bgcolor={isOverdueEnabled ? colorMap[colorKey] : colorMapDisabled[colorKey]}
        />
    );
}
