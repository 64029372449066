import { StreamChat, StreamChatOptions } from 'stream-chat';
import { SerializableError } from 'utils/error/serializableError';
import { getStreamIoApiKey } from 'utils/globals';
import { logger } from 'utils/logger';
import { createStreamChatLogger } from 'utils/logger/streamChatLogger';
import { UserData } from './types';

const STREAM_CHAT_DEFAULT_OPTIONS: StreamChatOptions = {
    timeout: 6000,
    logger: createStreamChatLogger(logger),
    browser: true,
    warmUp: true,
};

export class ClientService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _client: StreamChat | null = null;

    public get client(): StreamChat | null {
        return this._client;
    }

    public get isConnected(): boolean {
        return this._client !== null;
    }

    public async connect(userData: UserData, userToken: string, options: Partial<StreamChatOptions> = {}) {
        if (this.isConnected) return;

        const totalOptions = { ...STREAM_CHAT_DEFAULT_OPTIONS, ...options };
        const client = StreamChat.getInstance(getStreamIoApiKey, totalOptions);
        try {
            await client.connectUser(userData, userToken);
            this._client = client;
        } catch (error) {
            logger.reportError(
                new Error('Failed to setup stream chat user'),
                JSON.stringify(new SerializableError(error), null, 4),
            );
        }
    }

    public async disconnect() {
        try {
            await this._client?.disconnectUser();
        } catch (error) {
            logger.reportError(
                new Error('Failed to disconnect from stream chat'),
                JSON.stringify(new SerializableError(error), null, 4),
            );
        }
        this._client = null;
    }
}
