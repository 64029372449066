import { createSingleAction } from 'utils/store/actionUtils';
import { IToastMessage } from './appStateModel';

export const APP_SHOW_TOAST_MESSAGE = 'app/SHOW_TOAST_MESSAGE';
export const showToastMessage = createSingleAction<IToastMessage, typeof APP_SHOW_TOAST_MESSAGE>(
    APP_SHOW_TOAST_MESSAGE,
);

export const APP_SET_LEFT_MENU_MINIMIZED = 'app/SET_LEFT_MENU_MINIMIZED';
export const appSetLeftMenuOpened = createSingleAction<boolean, typeof APP_SET_LEFT_MENU_MINIMIZED>(
    APP_SET_LEFT_MENU_MINIMIZED,
);

export const APP_LEGACY_PAGE_LOADED = 'app/LEGACY_PAGE_LOADED';
export const notifyLegacyPageLoaded = (
    createSingleAction<string, typeof APP_LEGACY_PAGE_LOADED>(APP_LEGACY_PAGE_LOADED)
);

export const APP_NAVIGATE_TO = 'app/NAVIGATE_TO';
export const navigateTo = createSingleAction<string, typeof APP_NAVIGATE_TO>(APP_NAVIGATE_TO);

export type AppStateActions =
    | ReturnType<typeof showToastMessage>
    | ReturnType<typeof appSetLeftMenuOpened>
    | ReturnType<typeof notifyLegacyPageLoaded>
    | ReturnType<typeof navigateTo>;
