import { useCallback } from 'react';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { IAction } from 'components/button/actionButton/model';
import { useActions } from 'utils/store/useActions';
import { Delete as DeleteIcon, Edit as EditIcon} from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { launchDeleteModalOpen, launchModalOpen } from 'api/launch/launchActions';
import { Launch } from 'api/launch/launchModel';

export const LaunchActionsCell = (props: Launch) => {
    const actions = useActions({ openModalDeleteLaunch: launchDeleteModalOpen, openModalEditLaunch: launchModalOpen });

    const handleLaunchDelete = useCallback(() => {
        actions.current.openModalDeleteLaunch({launch: {id: props.id, caption: props.caption, isLaunchCover: false}, isOpen: true})
    }, [actions, props]);
    const handleLaunchEdit = useCallback(() => {
        actions.current.openModalEditLaunch({launch: props, isOpen: true})
    }, [actions, props]);

    const items: Array<IAction<string>> = [
        {
            name: '',
            action: handleLaunchEdit,
            isAvailable: true,
            Icon: EditIcon,
            key: `edit-${props.id}`
        },
        {
            name: '',
            action: handleLaunchDelete,
            isAvailable: true,
            Icon: DeleteIcon,
            key: `delete-${props.id}`
        },
    ];

    return (
        <Box position="absolute" right={0} top={4}>
            <ActionButton
                autoHide
                disabled={false}
                entity={props.id}
                width={6}
                items={items}
                marginLeft={-30}
            />
        </Box>
    );
};
