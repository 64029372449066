import React, {useMemo} from 'react';
import {
    Avatar,
    Box,
    Fade,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { BlueColor } from 'utils/styles/constants';
import { ChannelMemberActions } from './ChannelMemberActions';
import { useSelector } from 'react-redux';
import { selectUsersByIds } from '../../../../../../../../store/modules/user/userSelectors';
import { formatDateLocalized, shortDateFormat } from 'utils/helpers/dateHelper';
import { useStyles } from './styles';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export interface IChannelMemeberProps {
    id: string;
    name: string;
    avatarUrl: string;
    jobTitle: string | null;
    isAdmin: boolean,
    isOwner: boolean,
    channelCreatedAt: string
    deactivatedUser: boolean;
}

export function ChannelMember({
    id,
    name,
    avatarUrl,
    jobTitle,
    isAdmin,
    isOwner,
    channelCreatedAt,
    deactivatedUser
}: IChannelMemeberProps) {
    const classes = useStyles();
    const primary = useMemo(()=>(<Box display="flex" alignItems="center">
        <Typography className={classes.primaryText} style={{ textDecoration: deactivatedUser ? 'line-through' : 'none'}}>{name}</Typography>
            {isOwner &&
            <Tooltip title={`${t('Created')} ${formatDateLocalized(channelCreatedAt, shortDateFormat)}`} arrow placement='top' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <Typography className={classes.ownerText} variant="body2"><Trans>Creator</Trans></Typography>
            </Tooltip>}
        </Box>
    ),[name, isOwner, channelCreatedAt, classes, deactivatedUser]);
    const secondary = <Typography variant="caption" className={classes.secondaryText}>{jobTitle}</Typography>;
    const user = useSelector(selectUsersByIds)[id || ''];

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar alt={name} src={avatarUrl}>
                    <div style={{
                        backgroundColor: BlueColor.Dark,
                        color:'white',
                        width:'30px',
                        textAlign: 'center',
                        lineHeight:'30px',
                    }}>
                        {user?.firstName?.substring(0, 1)?.toUpperCase()}{user?.lastName?.substring(0, 1)?.toUpperCase()}
                    </div>
                </Avatar>
            </ListItemAvatar>

            <ListItemText
                primary={primary}
                secondary={secondary}
            />

            <ChannelMemberActions userId={id} name={name} isAdmin={isAdmin}/>
        </ListItem>
    );
}
