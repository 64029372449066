import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import { launchDelete, launchDeleteModalOpen } from 'api/launch/launchActions';
import { useCallback } from 'react'
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLaunchCoverFetching, selectLaunchModalDelete, selectLaunchUpdatingById } from 'store/modules/launch/launchSelectors';
import { useActions } from 'utils/store/useActions';
import { useLaunchStyles } from '../styles';

export const ModalDeleteLaunch = () => {
  const classes = useLaunchStyles();
  const modalData = useSelector(selectLaunchModalDelete);
  const isFetching = useSelector(selectLaunchUpdatingById)[modalData.launch?.id || ''];
  const isLaunchCoverImgFetching = useSelector(selectLaunchCoverFetching);
  const actions = useActions({ closeModal: launchDeleteModalOpen, deleteLaunch: launchDelete });
  const handleClickCancel = useCallback(() => actions.current.closeModal({ isOpen: false }), [actions]);
  const handleClickDelete = useCallback(() => actions.current.deleteLaunch(), [actions]);

  return (
    <Dialog open={modalData.isOpen}>
      <DialogTitle disableTypography>
        <Typography className={classes.modalDeleteTitleWrapper}>
          <Trans>Delete</Trans> <strong>{modalData.launch?.caption}</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.modalDeleteDescription}>
          <Trans>You will not be able to undo this. Do you want to proceed?</Trans>
        </Typography>
      </DialogContent>
      <DialogActions style={{padding: '12px'}}>
        {(isFetching || isLaunchCoverImgFetching) ? <CircularProgress size={24} />
          :
          <>
            <Button onClick={handleClickCancel} disabled={isFetching} className={classes.modalDeleteCancelButton}><Trans>Cancel</Trans></Button>
            <Button onClick={handleClickDelete} disabled={isFetching} className={classes.modalDeleteDeleteButton}><Trans>Delete</Trans></Button>
          </>}
      </DialogActions>
    </Dialog>
  );
};
