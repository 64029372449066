import type { KeycloakConfig } from 'keycloak-js';
import { keycloackAuthUrl, keycloackClientId, keycloackRealm } from 'utils/globals';

const config: KeycloakConfig = {
    url: keycloackAuthUrl,
    realm: keycloackRealm,
    clientId: keycloackClientId,
};

export default config;
