import { alpha, makeStyles, Theme } from '@material-ui/core';

export const useAttachmentsPanelStyles = makeStyles((theme: Theme) => ({
    filesList: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 0, 1, 0),
        borderRadius: theme.spacing(3),
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        backgroundColor: alpha(theme.palette.getContrastText(theme.palette.primary.dark), 0.2),

        '&::-webkit-scrollbar': {
            width: '7px',
            backgroundColor: 'rgba(0, 0, 0, 0)',

        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            borderRadius: '3px',
        },
    },
    fileListHeight: {
        maxHeight: 'calc(78vh - 124px)'
    },
    fileListHeightView: {
        maxHeight: '89vh',
    },
}));
