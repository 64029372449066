import { IChecklistItemFileOperation, StoreTaskAttachmentModel, StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { ItemsById } from 'model/entity';

export function removeTaskChecklistFile<T extends ItemsById<any>>(
    state: T,
    payload: IChecklistItemFileOperation & StoreTaskAttachmentModel,
): T {
    const { taskId, checklistItemId, fileId } = payload;
    if (Reflect.has(state, taskId)) {
        const task = state[taskId];
        if (Reflect.has(task, 'checklistItems')) {
            const storeTask = task as StoreTaskFullModel;
            const checklistItem = storeTask.checklistItems.find(item => item.id === checklistItemId);
            if (checklistItem) {
                const newAttachments = (
                    checklistItem.attachments
                        .filter(item => item.fileId !== fileId)
                );

                const newChecklistItem: typeof checklistItem = { ...checklistItem, attachments: newAttachments };
                const newItems = (
                    storeTask.checklistItems
                        .map(item => item.id === checklistItemId ? newChecklistItem : item)
                );
                return {
                    ...state,
                    [taskId]: {
                        ...storeTask,
                        checklistItems: newItems,
                    },
                };
            }
        }
    }
    return state;
}
