import { combineReducers } from 'redux';
import { TASK_GALLERY_FILTER, TASK_GALLERY_MODAL_BY_PARENT_TASK, TASK_GALLERY_MODAL_BY_PARENT_TASK_RESET, TASK_GALLERY_MODAL_CLOSE, TASK_GALLERY_MODAL_OPEN, TaskGalleryAction } from './taskGalleryActions';
import { TaskGalleryFilter, TaskGalleryFilterModel, TaskGalleryList } from 'api/task/taskGallery/taskGalleryModel';
import { StoreTaskApiActions, requestApproveStoreTask, requestDeclineStoreTask } from 'api/task/storeTask/storeTaskActions';
import { TaskGalleryApiActions, requestTaskGallery } from 'api/task/taskGallery/taskGalleryActions';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';

export type TaskGalleryState = {
    isModalOpen: boolean;
    items: TaskGalleryList;
    tasksGalleryFetching: boolean,
    updatingItems: Record<string, { status?: DisplayedTaskStatus, isLoading: boolean } | undefined>;
    parentTaskId: string;
    taskGalleryFilter: TaskGalleryFilterModel;
};

const initialState: TaskGalleryState = {
    isModalOpen: false,
    items: [],
    tasksGalleryFetching: false,
    updatingItems: {},
    parentTaskId: '',
    taskGalleryFilter: {
        status: TaskGalleryFilter.Init, 
        task: null
    }
};

function isModalOpenReducer(state = initialState.isModalOpen, action: TaskGalleryAction): boolean {
    switch (action.type) {
        case TASK_GALLERY_MODAL_OPEN:
            return true;
        case TASK_GALLERY_MODAL_CLOSE:
            return false;
        default:
            return state;
    }
}

function modalParentTaskReducer(state = initialState.parentTaskId, action: TaskGalleryAction): string {
    switch (action.type) {
        case TASK_GALLERY_MODAL_BY_PARENT_TASK:
            return action.payload;
        case TASK_GALLERY_MODAL_BY_PARENT_TASK_RESET:
            return '';
        default:
            return state;
    }
}

function itemsReducer(
    state = initialState.items,
    action: TaskGalleryApiActions,
): TaskGalleryList {
    switch (action.type) {
        case requestTaskGallery.successType:
            return action.payload;
        case requestTaskGallery.errorType:
            return [];
        default:
            return state;
    }
}

function tasksGalleryFetching(state = initialState.tasksGalleryFetching, action: TaskGalleryApiActions): boolean {
    switch (action.type) {
        case requestTaskGallery.initType:
            return true;
        case requestTaskGallery.successType:
        case requestTaskGallery.errorType:
            return false;
        default:
            return state;
    }
}

function updatingItemsReducer(
    state = initialState.updatingItems,
    action: StoreTaskApiActions,
): Record<string, { status?: DisplayedTaskStatus, isLoading: boolean } | undefined> {
    switch (action.type) {
        case requestApproveStoreTask.initType:
            return { ...state, [action.payload]: { isLoading: true } };
        case requestDeclineStoreTask.initType:
            return { ...state, [action.payload.taskId]: { isLoading: true } };
        case requestApproveStoreTask.successType:
            return { ...state, [action.payload.id]: { status: DisplayedTaskStatus.Completed, isLoading: false } };
        case requestDeclineStoreTask.successType:
                return { ...state, [action.payload.id]: { status: DisplayedTaskStatus.Declined, isLoading: false } };
        case requestApproveStoreTask.errorType:
        case requestDeclineStoreTask.errorType:
                return { ...state, [action.payload.id]: { isLoading: false } };
        default:
            return state;
    }
}

function taskGalleryFilter(state = initialState.taskGalleryFilter, action: TaskGalleryAction): TaskGalleryFilterModel {
    if(action.type === TASK_GALLERY_FILTER){
        return action.payload;
    }
    return state;
}

export const taskGalleryReducer = combineReducers({
    isModalOpen: isModalOpenReducer,
    items: itemsReducer,
    tasksGalleryFetching: tasksGalleryFetching,
    updatingItems: updatingItemsReducer,
    parentTask: modalParentTaskReducer,
    taskGalleryFilter: taskGalleryFilter,
});
