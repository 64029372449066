import { useMemo, useState } from "react";
import { formatDateLocalized, shortDateFormat } from "utils/helpers/dateHelper";
import { EventDescription } from "./EventDescription";
import { checkTaskEvents, FieldChange, TASK_FIELDS } from "./model";
import difference from 'lodash/difference';
import { t } from "i18next";
import ReactQuill from "react-quill";
import { EditorFormats } from "components/basicInputs/MarkdownInput";
import { Box, Button } from "@material-ui/core";
import { Trans } from "react-i18next";
import { useConversationStyles } from "modules/task/taskConversation/common/conversationStyles";

export type TaskEditedEventBodyProps = {
    changes: FieldChange[];
}

export const TaskEditedEventBody = ({ changes }: TaskEditedEventBodyProps) => {
    const knownChanges = useMemo(() => {
        return changes
            .filter(checkTaskEvents)
            .map(({ fieldName, oldValue, newValue }) => {
                const fromValue = (oldValue as string)?.length ? `from ${oldValue}` : '';
                switch (fieldName) {
                    case TASK_FIELDS.ADD_WATCHER:
                        return `${t('Add watcher')} ${newValue}`;
                    case TASK_FIELDS.REMOVE_WATCHER:
                        return `${t('Remove watcher')} ${newValue}`;
                    case TASK_FIELDS.DUE_DATE:
                    case TASK_FIELDS.SCHEDULED_DUE_DATE: {
                        const fromDate = formatDateLocalized(oldValue as string, shortDateFormat);
                        const toDate = formatDateLocalized(newValue as string, shortDateFormat);
                        return `${t('Due Date changed from')} ${fromDate} ${t('to')} ${toDate}`;
                    }
                    case TASK_FIELDS.SCHEDULED_TITLE:
                    case TASK_FIELDS.EVENT_EVENT_TITLE:
                        return `* ${t('The title has been updated')} ${fromValue} ${t('to')} ${newValue}`;
                    case TASK_FIELDS.SCHEDULED_DESCRIPTION:
                    case TASK_FIELDS.EVENT_EVENT_DESCRIPTION:
                        return <EventDescriptionMark fromValue={`${oldValue}`} newValue={`${newValue}`} />
                    case TASK_FIELDS.SCHEDULED_CHECKLIST:
                    case TASK_FIELDS.SCHEDULED_ATTACHMENTS:
                    case TASK_FIELDS.SCHEDULED_TAG:
                    case TASK_FIELDS.SCHEDULED_RECURRENCE:
                    case TASK_FIELDS.SCHEDULED_WATCHERS:
                        return `* ${fieldName.replace('schedule ', '')} ${t('was edited')}`;
                    case TASK_FIELDS.SCHEDULED_SELECTED_STORES: {
                        const oldStoresValue = (oldValue as string).split(',').map(item => item.trim());
                        const newStoresValue = (newValue as string).split(',').map(item => item.trim());
                        const storeRemoved = difference(oldStoresValue, newStoresValue);
                        return `* ${t('storeRemovedMessage', { count: storeRemoved.length, stores: storeRemoved.join(', ') })}`;
                    }
                    case TASK_FIELDS.EVENT_EVENT_END: {
                        const fromDate = formatDateLocalized(oldValue as string, shortDateFormat);
                        const toDate = formatDateLocalized(newValue as string, shortDateFormat);
                        return `* ${t('Event end date changed from')} ${fromDate} ${t('to')} ${toDate}`;
                    }
                    case TASK_FIELDS.EVENT_EVENT_START: {
                        const fromDate = formatDateLocalized(oldValue as string, shortDateFormat);
                        const toDate = formatDateLocalized(newValue as string, shortDateFormat);
                        return `* ${t('Event start date changed from')} ${fromDate} ${t('to')} ${toDate}`;
                    }
                    default:
                        throw new Error(`${t('Unexpected field name')}: ${fieldName}`);
                }
            })
    }, [changes]);

    return (
        <>
            {knownChanges.map((change, i) => (
                <EventDescription key={i}>{change}</EventDescription>
            ))}
        </>
    )
};

const EventDescriptionMark: React.FC<{fromValue: string, newValue: string, maxLines?: number}> = ({fromValue, newValue, maxLines = 2}) => {
    const [showFullText, setShowFullText] = useState(false);
    const classes = useConversationStyles();
    const contentStyle = {
        maxHeight: showFullText ? '1000px' : `${maxLines * 1.5}em`,
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out',
        opacity: showFullText ? 1 : 0.8,
    };

    return (
        <>
            <p>* The description has been updated</p>
            <Box style={contentStyle}>
                <ReactQuill
                    theme="snow"
                    value={fromValue}
                    modules={{ toolbar: false }}
                    formats={EditorFormats}
                    preserveWhitespace
                    readOnly={true}
                    style={{
                        maxHeight: showFullText ? 'none' : `${maxLines * 1.5}em`,
                        overflow: 'hidden',
                    }}
                />
            </Box>
            <p>to:</p>
            <Box style={contentStyle}>
                <ReactQuill
                    theme="snow"
                    value={newValue}
                    modules={{ toolbar: false }}
                    formats={EditorFormats}
                    preserveWhitespace
                    readOnly={true}
                    style={{
                        maxHeight: showFullText ? 'none' : `${maxLines * 1.5}em`,
                        overflow: 'hidden',
                    }}
                />
            </Box>
            <Button
                onClick={() => setShowFullText(!showFullText)}
                color='secondary'
                size='small'
                className={classes.actionsButtonComment}
                style={{marginTop: 8}}
            >
                <Trans>{showFullText ? 'See Less' : 'See More'}</Trans>
            </Button>
        </>
    );
}
