/* eslint-disable @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { LocationPickerModal } from '../LocationPickerModal';
import { PickItem } from '../PickItem';
import { buildLocationsTree, countMapByMainLocation, mapLocationsTreeToPickItems } from './helpers';
import {  findNode, fromTopChildren, getAllChildren, getAllParents, getTopChildren } from 'utils/helpers/trees';
import { nonEmpty } from 'utils/helpers/collections';
import { Store, Stores } from 'api/store/storeModel';
import { selectLocationsUserTreeById, selectRelationsLocationsUserTreeById } from 'store/modules/hierarchy/hierarchySelectors';

export interface ILocationPickerProps {
    isOpened: boolean;
    onClose: () => void;
    onSave: (selected: Array<string>, storesCount: number) => void;
    initialSelectedLocations?: Array<string>;
    showSearchByStore?: boolean
    valueFromTemplate?: Stores
}

export function LocationPicker({
    isOpened,
    onClose,
    onSave,
    initialSelectedLocations = [],
    showSearchByStore = false,
    valueFromTemplate
}: ILocationPickerProps) {
    const [isSelectedValueFromTemplate, setIsSelectedValueFromTemplate] = useState<string>('');
    const [expandedItems, setExpandedItems] = useState<Array<string>>([]);
    const [selectSearchStore, setSelectSearchStore] = useState<Stores>([]);
    const locationsByIds = useSelector(selectLocationsUserTreeById);
    const relationsByIds = useSelector(selectRelationsLocationsUserTreeById);

    const currentUser = useCurrentUser();

    const locationsTree = useMemo(
        () => currentUser ? buildLocationsTree(currentUser.companyLocationId ?? '', relationsByIds) : null,
        [currentUser, relationsByIds],
    );
    const initialAllChildren = useMemo(() => {
        if (!locationsTree) {
            return initialSelectedLocations;
        }
        return fromTopChildren(locationsTree, initialSelectedLocations).map(item => item.value);
    }, [initialSelectedLocations, locationsTree]);
    const [selectedLocations, setSelectedLocations] = useState<Array<string>>(initialAllChildren);

    const selectLocation = useCallback((id: string, selected: boolean) => {
        const predicate = (itemId: string) => itemId === id;
        const node = locationsTree ? findNode(locationsTree, predicate) : null;
        const nodeChildren = node ? getAllChildren(node).map(item => item.value) : [];
        const nodeParents = node ? getAllParents(node).map(item => item.value) : [];
        if (selected) {
            setSelectedLocations(prev => {
                const newState = new Set([...prev, ...nodeChildren, id]);
                if (nodeParents.length >= 2) {
                    for (let i = 0; i < nodeParents.length - 1; i++) {
                        const parent = nodeParents[i];
                        if (relationsByIds[parent].every(item => newState.has(item))) {
                            newState.add(parent);
                        }
                    }
                }
                return [...newState];
            });
        } else {
            setSelectedLocations(prev => {
                const newState = new Set([...prev]);
                [...nodeChildren, ...nodeParents, id].forEach(item => {
                    if (newState.has(item)) {
                        newState.delete(item);
                    }
                });
                return [...newState];
            })
            setSelectSearchStore(prev => {
                const isParent = prev.some(store => JSON.stringify(store.parent).includes(id))
                const newState = prev.filter(store=> (store.id !== id )).filter(s=> (!isParent));
                return [...newState];
            });
        }
    }, [locationsTree, relationsByIds, setSelectedLocations, setSelectSearchStore]);

    const expandItem = useCallback((id: string, expanded: boolean) => {
        if (expanded) {
            setExpandedItems(state => uniq([...state, id]));
        } else {
            setExpandedItems(state => state.filter(item => item !== id));
        }
    }, []);

    const pickItems = useMemo(() => {
        const items = (
            locationsTree
                ? mapLocationsTreeToPickItems(
                    locationsTree,
                    locationsByIds,
                    selectedLocations,
                    selectLocation,
                    expandedItems,
                    expandItem,
                )
                : []
        );
        return orderBy(items, 'title', 'asc');
    }, [expandItem, expandedItems, locationsByIds, locationsTree, selectLocation, selectedLocations]);

    const topSelectedLocations = useMemo(() => {
        if (!locationsTree) {
            return [];
        }
        return getTopChildren(locationsTree, id => selectedLocations.includes(id)).map(item => item.value);
    }, [locationsTree, selectedLocations]);

    const topSearchStore = useMemo(() => {
        return selectSearchStore
            .filter(s => {
                const findSelected = selectedLocations.some(location => JSON.stringify(s.parent).includes(location))
                if(!locationsTree || findSelected) return false;
                const predicate = (itemId: string) => itemId === s.id;
                return findNode(locationsTree, predicate) === null
            })
            .map(loc=>loc.id);
    }, [locationsTree, selectSearchStore, selectedLocations]);
    
    const countByMainLocations = useMemo(() => {
        return countMapByMainLocation(
            currentUser?.companyLocationId ?? '',
            relationsByIds,
            topSelectedLocations,
            locationsByIds,
            locationsTree,
            selectSearchStore
        )
    }, [topSelectedLocations, locationsByIds, currentUser, locationsTree, relationsByIds, selectSearchStore]);

    const selectedStoresCount = useMemo(() => {
        return (topSelectedLocations
            .map(id => locationsByIds[id])
            .filter(nonEmpty)
            .map(location => location.storesCount)
            .reduce((sum, count) => sum + count, topSearchStore.length));
    }, [locationsByIds, topSearchStore, topSelectedLocations]);

    useEffect(() => {
        const childToAdd = (
            selectedLocations
                .filter(id => id in relationsByIds)
                .flatMap(id => relationsByIds[id])
                .filter(id => !selectedLocations.includes(id))
        );
        if (childToAdd.length) {
            setSelectedLocations(prev => uniq([...prev, ...childToAdd]));
        }
    }, [relationsByIds, selectedLocations]);

    const handlerSelectStore = useCallback((store: Store)=>{
        setSelectSearchStore(prev =>([...prev, store]));
        selectLocation(store.id, true);
    },[selectLocation, setSelectSearchStore]);

    useEffect(()=>{
        if(valueFromTemplate && valueFromTemplate?.length > 0 && isSelectedValueFromTemplate !== valueFromTemplate.map(value => value.id).join("-")){
            setSelectedLocations([]);
            const stores = [];
            for (const store of valueFromTemplate) {
                stores.push(store.id);
                handlerSelectStore(store);
            }
            setIsSelectedValueFromTemplate(valueFromTemplate.map(value => value.id).join("-"));
            onSave(stores, valueFromTemplate.length);
        }
    }, [valueFromTemplate, handlerSelectStore, isSelectedValueFromTemplate, onSave])

    return (
        <>
            <LocationPickerModal
                open={isOpened}
                storesSelected={selectedStoresCount}
                onCancel={onClose}
                onSave={() => {
                    const concatSelectedLocations = uniq([...topSelectedLocations, ...topSearchStore]);
                    onSave(concatSelectedLocations, selectedStoresCount);
                    onClose();
                }}
                onReset={() => {setSelectedLocations([]);setSelectSearchStore([])}}
                onSelectStore={handlerSelectStore}
                showSearchByStore={showSearchByStore}
                selectedStores={selectSearchStore}
            >
                {pickItems.length === 0 && (
                    <Box mb="12px">
                        There are no stores currently available
                    </Box>
                )}
                {pickItems.map(item => (
                    <Box key={item.id} mb="12px">
                        <PickItem {...item} countByMainLocations={countByMainLocations}/>
                    </Box>
                ))}
            </LocationPickerModal>
        </>
    );
}
