import { IStoreState } from 'store/rootReducer';

export const selectUsersByIds = (state: IStoreState) => state.user.usersByIds;
export const selectUsersLoadingById = (state: IStoreState) => state.user.usersLoadingById;
export const selectUsersUpdatingById = (state: IStoreState) => state.user.usersUpdatingById;
export const selectUserCreating = (state: IStoreState) => state.user.userCreating;
export const selectUsersIds = (state: IStoreState): Array<string> => Object.keys(state.user.usersByIds);
export const selectUserById = (id: string) => (state: IStoreState) => state.user.usersByIds[id];
export const selectNotificationsCount = (state: IStoreState) => state.user.notificationsCount;
export const selectStreamUserToken = (state: IStoreState) => state.user.getStreamUserToken;
export const selectUserRoles = (state: IStoreState) => state.user.userRoles;
export const selectUserRolesLoading = (state: IStoreState) => state.user.userRolesLoading;

export const selectUserListLoading = (state: IStoreState) => state.user.userListLoading;
export const selectUserListNextPageToken = (state: IStoreState) => state.user.userListNextPageToken;
export const selectUserListOrder = (state: IStoreState) => state.user.userListOrder;

export const selectUserIdRequireConfirmation = (state: IStoreState) => state.user.userIdRequireReactivationConfirmation;

export const selectAvatarEditorOpened = (state: IStoreState) => state.user.avatarEditorOpened;
export const selectAvatarUploadingExplicit = (state: IStoreState) => state.user.avatarUploadingExplicit;

export const selectUsersChecked = (state: IStoreState) => state.user.checkedUserItems;

export const selectUsersCheckedOnlyArray = (state: IStoreState) => state.user.checkedUserItemsArray;

export const selectIsUserBulkActionConfirmationOpen = (state: IStoreState) =>
    state.user.openedBulkUserStatusChangeConfirm;

export const selectIsSavingBulkStatus = (state: IStoreState) => state.user.bulkUsersStatusUpdating;

export const selectIsOpenUserImportModal = (state: IStoreState) => state.user.openedUserImportModal;

export const selectBulkUsersImportStep = (state: IStoreState) => state.user.bulkUsersImportStep;
