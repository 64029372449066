import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { LanguageCode } from 'api/user/userModel';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnEmptyString: false,
        fallbackLng: LanguageCode?.en ?? 'en',
    });

export default i18n;

