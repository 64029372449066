import { call, put, select, takeLatest } from 'typed-redux-saga';
import { TASK_REVIEW_MODAL_CLOSE, TASK_REVIEW_MODAL_OPEN, parentTaskReviewModalReset } from './taskReviewActions';
import { requestStoreTasksForReview, requestStoreTasksForReviewCount } from 'api/task/taskReview/taskReviewActions';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { selectUsersIds } from 'store/modules/user/userSelectors';
import difference from 'lodash/difference';
import { selectTaskTagsFilter } from '../taskTag/taskTagSelectors';
import { authApi } from 'api/auth/authApi';
import { eraseTokensReload } from 'modules/auth/storage';
import { selectParentTaskReview } from './taskReviewSelectors';
import { ParentTaksReview } from './taskReviewReducer';
import { selectTaskFilterCreatedBySelected } from '../taskFilter/taskFilterSelectors';

function* taskReviewSlideoutOpenedWatcher() {
    yield takeLatest(
        TASK_REVIEW_MODAL_OPEN,
        function* () {
            const tags: string[] = yield* select(selectTaskTagsFilter);
            const createdBy: string[] = yield* select(selectTaskFilterCreatedBySelected);
            const parentTaskReview: ParentTaksReview = yield* select(selectParentTaskReview);
            yield put(requestStoreTasksForReview.init({ tags, parentTaskId: parentTaskReview?.taskId, createdBy }));
        },
    );
}

function* tasksForReviewSuccessSaga({ payload: tasks }: ReturnType<typeof requestStoreTasksForReview.success>) {
    const userIds = new Set<string>();
    tasks.forEach(({ createdBy, submittedBy }) => {
        userIds.add(createdBy);
        userIds.add(submittedBy);
    });
    const loadedUserIds = yield* select(selectUsersIds);
    const idsToLoad = difference(Array.from(userIds), loadedUserIds);
    yield put(requestMultipleUsersInfo.init(idsToLoad));
}

function* storeTasksForReviewWatcher() {
    yield* takeLatest(requestStoreTasksForReview.successType, tasksForReviewSuccessSaga);
}

function* tasksForReviewCountErrorSaga({ payload }: ReturnType<typeof requestStoreTasksForReviewCount.error>) {
    if(payload?.error?.message.includes('401')){
        yield call(authApi.legacyLogout);
        yield call(eraseTokensReload);
    }
}

function* storeTasksForReviewCountWatcher() {
    yield* takeLatest(requestStoreTasksForReviewCount.errorType, tasksForReviewCountErrorSaga);
}

function* taskReviewSlideoutClosedWatcher() {
    yield takeLatest(
        TASK_REVIEW_MODAL_CLOSE,
        function* () {
            yield put(parentTaskReviewModalReset());
        },
    );
}

export const taskReviewSagas = [
    taskReviewSlideoutOpenedWatcher,
    storeTasksForReviewWatcher,
    storeTasksForReviewCountWatcher,
    taskReviewSlideoutClosedWatcher
];
