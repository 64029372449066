import React from 'react';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import { Box, Typography, useTheme } from '@material-ui/core';
import { Trans } from 'react-i18next';

export function AttachmentsEmptyState() {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            padding={theme.spacing(1)}
        >
            <InboxOutlinedIcon fontSize="large"/>
            <Typography variant="h5">
                <Trans>No files</Trans>
            </Typography>
        </Box>
    );
}
