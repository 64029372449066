import React, { useCallback, useMemo } from 'react';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { BackButton } from 'modules/shared/components/BackButton';
import { PageLoader } from 'components/router/PageLoader';
import { UserProfileForm } from './UserProfileForm';
import { UserDTO, UserProfileDTO } from 'api/user/userModel';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAvatarUploadingExplicit,
    selectUsersUpdatingById
} from 'store/modules/user/userSelectors';
import { requestUserUpdate } from 'api/user/userActions';
import { CropperModal } from './avatar/CropperModal';
import { Avatar, Box, makeStyles } from '@material-ui/core';
import { getFilesConfig } from '../../../store/modules/files/filesSelectors';
import { openAvatarEditor } from '../../../store/modules/user/userActions';
import { BlueColor } from '../../../utils/styles/constants';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const createFormData = (user: UserDTO): UserProfileDTO => {
    return {
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email ?? '',
        phoneNumber: user.phoneNumber ?? '',
        language: user.language ?? 'en'
    }
};

const useStyles = makeStyles(() => ({
    formBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '10px',
    },
    formBoxInner: {
        width: '100px',
        height: '100px',
    },
    formBoxOverlay: {
        opacity: 0,
        textAlign: 'center',
        lineHeight: '100px',
        height: '100px',
        width: '100px',
        cursor: 'pointer',
        transition: '.3s ease',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '50%',
        zIndex: 1000,
        marginTop: '-100px',
        '&:hover': {
            opacity: 0.7,
        },
    },
    avatar: {
        width: '100px',
        height: '100px',
        backgroundColor: 'white',
        color: 'darkblue',
    },
    avatarInner: {
        backgroundColor: BlueColor.Dark,
        color:'white',
        width:'100px',
        textAlign: 'center',
        lineHeight:'100px',
    },
}));

export function UserProfilePage() {
    const dispatch = useDispatch();
    const user = useCurrentUser();
    const classes = useStyles();
    const fileStorageConfig = useSelector(getFilesConfig);
    const isUpdatingUser = useSelector(selectUsersUpdatingById)[user?.id || ''];
    const isUpdatingAvatar = useSelector(selectAvatarUploadingExplicit)
    const isUpdating = isUpdatingUser || isUpdatingAvatar;

    const avatarUrl = useMemo(() => {
        return fileStorageConfig && user?.avatarId
            ? `${fileStorageConfig.avatarsBaseUrl}/${user.avatarId}`
            : '';
    }, [fileStorageConfig, user])

    const formData = useMemo(() => {
        return user ? createFormData(user) : null;
    }, [user]);

    const avatarEditHandler = useCallback(() => {
        dispatch(openAvatarEditor(true));
    }, [dispatch]);

    const submitHandler = useCallback((data: UserProfileDTO) => {
        dispatch(requestUserUpdate.init({
            id: user!.id,
            companyLocationId: user?.companyLocationId,
            isDeleted: user!.isDeleted,
            employeeTitle: user?.employeeTitle ?? undefined,
            roleId: user!.roleId,
            ...data
        }));
    }, [dispatch, user]);

    if (!formData) {
        return <PageLoader />;
    }

    return (
        <FormPageView
            title={<Trans>Edit Profile</Trans>}
            actionBar={<BackButton><Trans>Go Back</Trans></BackButton>}
        >
            <Box className={classes.formBox}>
                <Box className={classes.formBoxInner} onClick={avatarEditHandler}>
                    <Avatar className={classes.avatar}
                        src={avatarUrl}
                        alt={`${t('avatar')}`}
                    >
                        <div className={classes.avatarInner}>
                            {user?.firstName?.substring(0, 1)?.toUpperCase()}{user?.lastName?.substring(0, 1)?.toUpperCase()}
                        </div>
                    </Avatar>
                    <div className={classes.formBoxOverlay}><Trans>Change</Trans></div>
                </Box>
            </Box>
            <UserProfileForm
                initialValues={formData}
                onSubmit={submitHandler}
                isInProgress={isUpdating}
            />
            <CropperModal/>
        </FormPageView>
    );
}
