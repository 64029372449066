import React, { useCallback } from 'react';
import { Box, Button, TextField, Theme } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { Formik } from 'formik';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { userEditSchema, UserFormData } from './model';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocations } from 'store/modules/hierarchy/hierarchySelectors';
import { selectUserIdRequireConfirmation, selectUserRoles } from 'store/modules/user/userSelectors';
import { AutocompleteDropdown } from 'components/basicInputs/selectInput/AutocompleteDropdown';
import { CommonUserFormInputs } from './CommonUserFormInuputs';
import { FormActions } from 'modules/shared/components/FormActions';
import { useParams } from 'react-router';
import { requestConfirmUserReactivation } from '../../../store/modules/user/userActions';
import { BaseInput } from '../../../components/basicInputs/BaseInput';
import { UserDTO } from '../../../api/user/userModel';
import { requestMultipleUsersInfo } from '../../../api/user/userActions';
import { useButtonStyles } from '../../../utils/styles/buttons';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type UserEditFormProps = {
    initialValues: UserFormData;
    onSubmit: (data: UserFormData) => void;
    isInProgress?: boolean;
}

const useActiveButtonStyles = makeStyles((theme: Theme) => ({
    activatorButton: {
        height: '40px',
        marginTop: 'auto',
    },
}));

export function UserEditForm({ initialValues, isInProgress, onSubmit }: UserEditFormProps) {
    const classes = useFormStyles();
    const buttonClasses = useButtonStyles();
    const classesCustomExtensions = useActiveButtonStyles([]);
    const dispatch = useDispatch();
    const confirmationUserId = useSelector(selectUserIdRequireConfirmation);
    const locations = useSelector(selectLocations);
    const roles = useSelector(selectUserRoles);
    const { userId } = useParams<{ userId: string }>();
    const hasId = !!userId;
    const activeString = !initialValues.isDeleted ? `${t('Active')}` : `${t('Inactive')}`;
    const initializeConfirmation = useCallback(() => {
        dispatch(requestConfirmUserReactivation(initialValues));
    }, [dispatch, initialValues])
    const handleDeactivate = useCallback((e) => {

        const changedUser = {
            ...initialValues,
            isDeleted: true,
            id: userId,
            meta: undefined,
        } as UserDTO;
        dispatch(requestMultipleUsersInfo.success({ requestedIds: [userId], users: [changedUser] }));
    }, [dispatch, initialValues, userId]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={userEditSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Box className={classes.formInputs}>
                        <CommonUserFormInputs disabled={isInProgress} isAdminEdit={true} />

                        <AutocompleteDropdown
                            name="roleId"
                            label={t('User Role')}
                            options={roles}
                            keyProp='roleId'
                            valueProp='description'
                            disabled={isInProgress || !!confirmationUserId}
                        />

                        <TextInput
                            name="employeeTitle"
                            label={t('Position Title')}
                            disabled={isInProgress || !!confirmationUserId}
                        />

                        <AutocompleteDropdown
                            classes={{ root: classes.titleInput }}
                            name="companyLocationId"
                            label={t('Company Location')}
                            options={locations}
                            keyProp='id'
                            valueProp='name'
                            disabled={isInProgress || !!confirmationUserId}
                        />

                        { hasId && (
                            <BaseInput
                                id={'user_status'}
                                label={t('Status')}
                                separateLabel={true}
                            >
                                <TextField
                                    id={'user_status_inner'}
                                    value={activeString}
                                    variant={'outlined'}
                                    placeholder={'Status'}
                                    disabled={true}
                                />
                            </BaseInput>
                        )}

                        {!initialValues.isDeleted && hasId && (
                            <Button
                                className={clsx(buttonClasses.muiButton, classesCustomExtensions.activatorButton)}
                                onClick={handleDeactivate}
                            ><Trans>Deactivate</Trans></Button>
                        )}

                        {initialValues.isDeleted && hasId && (
                            <Button
                                className={clsx(buttonClasses.muiButton, classesCustomExtensions.activatorButton)}
                                onClick={initializeConfirmation}
                                disabled={!!confirmationUserId}
                            ><Trans>Reactivate</Trans></Button>
                        )}
                    </Box>

                    <FormActions isInProgress={isInProgress} />
                </form >
            )}
        </Formik>
    );
}
