import { createSingleAction } from 'utils/store/actionUtils';

export const INTEGRATIONS_GET = 'INTEGRATIONS_GET';

export const getIntegrations = createSingleAction<
    void, typeof INTEGRATIONS_GET
>(INTEGRATIONS_GET);

export const INTEGRATIONS_GET_MOCK = 'INTEGRATIONS_GET_MOCK';

export const getIntegrationsMock = createSingleAction<
    void, typeof INTEGRATIONS_GET_MOCK
>(INTEGRATIONS_GET_MOCK);

export type IntegrationActions =
    | ReturnType<typeof getIntegrations>
    | ReturnType<typeof getIntegrationsMock>;
