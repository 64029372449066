import { combineReducers } from 'redux';
import uniqBy from 'lodash/uniqBy';
import {
    AvatarFileActions,
    FilesActions,
    FilesConfigActions,
    requestDownloadTasksBulk,
    requestFileByFileStorage,
    requestFileStorageToken,
    requestTasksBulkUpload,
    requestUploadAvatar,
    requestUploadFile,
    requestUserBulkImportTemplate,
    requestUsersBulkUpload,
    TasksBulkUploadActions,
    UserBulkTemplateDownloadActions,
    UsersBulkUploadActions
} from 'api/files/filesActions';
import {
    FILES_RESET_PENDING_FILES,
    FILES_ADD_PENDING_FILES,
    FILES_REMOVE_PENDING_FILE,
    FILES_ADD_UPLOADED_FILES,
    FILES_RESET_UPLOADED_FILES,
    FILES_REMOVE_UPLOADED_FILE,
    PendingFilesAction,
    UploadedFilesAction,
    PendingUserBulkImportActions,
    FILES_USERS_BULK_RESET_PENDING_FILES,
    FILES_USERS_BULK_ADD_PENDING_FILES,
    PendingConversationActions,
    FILES_RESET_PENDING_CONVERSATION_FILES,
    FILES_ADD_PENDING_CONVERSATION_FILES,
    UploadedConversationFilesAction,
    FILES_RESET_UPLOADED_CONVERSATION_FILES,
    FILES_ADD_UPLOADED_CONVERSATION_FILES,
    FILES_REMOVE_UPLOADED_CONVERSATION_FILE,
} from './filesActions';
import { PendingFile, UploadedFile } from './filesModel';
import { IFilesConfig, requestFileByFileStorageResponse } from 'api/files/filesModel';
import { ImportActionsType, OPEN_USER_IMPORT_MODAL } from '../user/userActions';
import { ItemsById } from 'model/entity';

export interface IFilesStoreState {
    fileStorageToken: string | null;
    fileStorageTokenExpiration: number | null;
    filesUploading: boolean;
    pendingFiles: PendingFile[],
    uploadedFiles: UploadedFile[],
    configuration: IFilesConfig | null,
    uploadingAvatar: boolean,
    pendingUserBulkFile: PendingFile | null,
    startedUserBulkTemplateDownload: boolean,
    uploadingUsersBulkFile: boolean,
    fileByFileStorage: ItemsById<requestFileByFileStorageResponse>,
    fileByFileStorageFetchingById: ItemsById<boolean>,

    pendingConversationFiles: PendingFile[],
    uploadedConversationFiles: UploadedFile[]
    uploadingTasksBulkFile: boolean,
    downloadTasksBulkFile: boolean
}

const initialState: IFilesStoreState = {
    fileStorageToken: null,
    fileStorageTokenExpiration: null,
    filesUploading: false,
    pendingFiles: [],
    uploadedFiles: [],
    configuration: null,
    uploadingAvatar: false,
    pendingUserBulkFile: null,
    startedUserBulkTemplateDownload: false,
    uploadingUsersBulkFile: false,
    fileByFileStorage: {},
    fileByFileStorageFetchingById: {},
    pendingConversationFiles: [],
    uploadedConversationFiles: [],
    uploadingTasksBulkFile: false,
    downloadTasksBulkFile: false
};

function fileStorageToken(
    state = initialState.fileStorageToken,
    action: FilesActions,
): string | null {
    switch (action.type) {
        case requestFileStorageToken.successType:
            return action.payload.token;
        default:
            return state;
    }
}

function fileStorageTokenExpiration(
    state = initialState.fileStorageTokenExpiration,
    action: FilesActions,
): number | null {
    switch (action.type) {
        case requestFileStorageToken.successType:
            return action.payload.expireAt;
        default:
            return state;
    }
}

function avatarUploading(
    state: boolean = initialState.uploadingAvatar,
    action: AvatarFileActions,
): boolean {
    switch (action.type) {
        case requestUploadAvatar.successType:
        case requestUploadAvatar.errorType:
            return false;
        case requestUploadAvatar.initType:
            return true;
        default:
            return state;
    }
}

function filesUploading(
    state: boolean = initialState.filesUploading,
    action: FilesActions,
): boolean {
    switch (action.type) {
        case requestUploadFile.successType:
        case requestUploadFile.errorType:
            return false;
        case requestUploadFile.initType:
            return true;
        default:
            return state;
    }
}

function pendingFiles(state = initialState.pendingFiles, action: PendingFilesAction) {
    switch (action.type) {
        case FILES_RESET_PENDING_FILES:
            return initialState.pendingFiles;
        case FILES_ADD_PENDING_FILES:
            return uniqBy([...state, ...action.payload], a => a.localKey);
        case FILES_REMOVE_PENDING_FILE:
            return [...state.filter(f => f.localKey !== action.payload)];
        default:
            return state;
    }
}

function uploadedFiles(
    state = initialState.uploadedFiles,
    action: UploadedFilesAction,
) {
    switch (action.type) {
        case FILES_RESET_UPLOADED_FILES:
            return [];
        case FILES_ADD_UPLOADED_FILES:
            return [...state, ...action.payload];
        case FILES_REMOVE_UPLOADED_FILE:
            return [...state.filter(file => file.fileId !== action.payload)];
        default:
            return state;
    }
}

function configuration(state = initialState.configuration, action: FilesConfigActions) {
    if (action.type === 'response/file/CONFIG_SUCCESS') {
        return action.payload;
    }
    return state;
}

function pendingUserBulkImportFile(
    state: PendingFile | null = initialState.pendingUserBulkFile,
    action: PendingUserBulkImportActions | ImportActionsType,
): PendingFile | null {
    switch (action.type) {
        case FILES_USERS_BULK_RESET_PENDING_FILES:
        case OPEN_USER_IMPORT_MODAL:
            return null;
        case FILES_USERS_BULK_ADD_PENDING_FILES:
            return action.payload;
        default:
            return state;
    }
}

function startedDownloadBulkUserImportTemplate(
    state: boolean = initialState.startedUserBulkTemplateDownload,
    action: UserBulkTemplateDownloadActions,
): boolean {
    switch (action.type) {
        case requestUserBulkImportTemplate.initType:
            return true;
        case requestUserBulkImportTemplate.errorType:
        case requestUserBulkImportTemplate.successType:
            return false;
        default:
            return state;
    }
}

function uploadingUsersBulkFile(
    state: boolean = initialState.uploadingUsersBulkFile,
    action: UsersBulkUploadActions,
): boolean {
    switch (action.type) {
        case requestUsersBulkUpload.initType:
            return true;
        case requestUsersBulkUpload.errorType:
        case requestUsersBulkUpload.successType:
            return false;
        default:
            return state;
    }
}

function fileByFileStorage(
    state: ItemsById<{fileId: string, objectURL: string}> = initialState.fileByFileStorage,
    action: FilesActions,
): ItemsById<requestFileByFileStorageResponse> {
    switch (action.type) {
        case requestFileByFileStorage.successType:
            const { fileId } = action.payload;
            return {...state, [fileId]: action.payload};
        case requestFileByFileStorage.initType:
        case requestFileByFileStorage.errorType:
        default:
            return state;
    }
}

function fileByFileStorageFetchingById(
    state: Record<string, boolean | undefined> = initialState.fileByFileStorageFetchingById,
    action: FilesActions,
): Record<string, boolean | undefined> {
    switch (action.type) {
        case requestFileByFileStorage.initType:
            return { ...state, [action.payload]: true };
        case requestFileByFileStorage.successType:
            return { ...state, [action.payload.fileId]: false };
        case requestFileByFileStorage.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}


function pendingConversationFiles(
    state: PendingFile[] = initialState.pendingConversationFiles,
    action: PendingConversationActions | ImportActionsType,
): PendingFile[] {
    switch (action.type) {
        case FILES_RESET_PENDING_CONVERSATION_FILES:
            return [];
        case FILES_ADD_PENDING_CONVERSATION_FILES:
            return action.payload;
        default:
            return state;
    }
}

function uploadedConversationFiles(
    state = initialState.uploadedConversationFiles,
    action: UploadedConversationFilesAction,
) {
    switch (action.type) {
        case FILES_RESET_UPLOADED_CONVERSATION_FILES:
            return [];
        case FILES_ADD_UPLOADED_CONVERSATION_FILES:
            return [...state, ...action.payload];
        case FILES_REMOVE_UPLOADED_CONVERSATION_FILE:
            return [...state.filter(file => file.fileId !== action.payload)];
        default:
            return state;
    }
}

function uploadingTasksBulkFile(
    state: boolean = initialState.uploadingTasksBulkFile,
    action: TasksBulkUploadActions,
): boolean {
    switch (action.type) {
        case requestTasksBulkUpload.initType:
            return true;
        case requestTasksBulkUpload.errorType:
        case requestTasksBulkUpload.successType:
            return false;
        default:
            return state;
    }
}

function downloadTasksBulkFile(
    state: boolean = initialState.downloadTasksBulkFile,
    action: FilesActions,
): boolean {
    switch (action.type) {
        case requestDownloadTasksBulk.initType:
            return true;
        case requestDownloadTasksBulk.errorType:
        case requestDownloadTasksBulk.successType:
            return false;
        default:
            return state;
    }
}

export const files = combineReducers({
    fileStorageToken,
    fileStorageTokenExpiration,
    filesUploading,
    pendingFiles,
    uploadedFiles,
    configuration,
    avatarUploading,
    pendingUserBulkImportFile,
    startedDownloadBulkUserImportTemplate,
    uploadingUsersBulkFile,
    fileByFileStorage,
    fileByFileStorageFetchingById,
    pendingConversationFiles,
    uploadedConversationFiles,
    uploadingTasksBulkFile,
    downloadTasksBulkFile
});
