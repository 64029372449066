import React, { useCallback, useEffect } from 'react';
import {
    Backdrop,
    Box, Button, ButtonBase, IconButton, List, ListItem, Theme, useMediaQuery,
} from '@material-ui/core';
import { useLeftMenuStyles } from 'modules/root/LeftMenu/LeftMenuStyles';
import { leftMenuBottomItems, leftMenuItemsRender, leftMenuTopItems, leftMenuTopItemsMyTasksRender } from 'modules/root/LeftMenu/LeftMenuItems';
import { useActions } from "utils/store/useActions";
import { authLogout } from 'api/auth/authActions';
import { useAppPermissions } from 'store/modules/apps/appsHooks';
import { ExitToApp, PlayCircleFilled } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectLeftMenuOpened } from 'store/modules/appState/appStateSelectors';
import { appSetLeftMenuOpened } from 'store/modules/appState/appStateActions';
import clsx from 'clsx';
import { Breakpoints } from 'utils/styles/constants';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router';
import { UserMenu } from '../UserMenu';
import { LeftMenuLogo } from './LeftMenuLogo';
import { LeftMenuUser } from './LeftMenuUser';
import { FeatureFlags } from 'utils/featureFlags';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { Trans } from 'react-i18next';

const CollapseMenu = ({isOpened, onToggle}: {isOpened: boolean, onToggle: () => void}) => {
    const classes = useLeftMenuStyles([]);
    return (            
        <Box className={clsx(classes.rootCollapse, { 'closed': isOpened })}>
            <IconButton
                aria-label="Collapse"
                className={classes.collapseMenuButton}
                onClick={onToggle}
            >
                <PlayCircleFilled/>
            </IconButton>
        </Box>
    )
}

export function LeftMenu() {
    const location = useLocation();
    const classes = useLeftMenuStyles([]);
    const { isOpened: isOpenedCollapseMenu, onToggle: onToggleCollapseMenu } = useOpenedState();
    const actions = useActions({
        logout: authLogout,
        setLeftMenuOpened: appSetLeftMenuOpened,
    });
    const featureFlags = useSelector(selectFeatureFlags);
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const isTablet = useMediaQuery(
        (theme: Theme) => theme.breakpoints.between(Breakpoints.MobileLarge, Breakpoints.Tablet),
        { noSsr: true },
    );
    const isDesktop = useMediaQuery(
        (theme: Theme) => theme.breakpoints.up(Breakpoints.Tablet),
        { noSsr: true },
    );

    const hasAppAccess = useAppPermissions();
    const isFeatureEnabled = useCallback((featureFlag: FeatureFlags) => {
        return !!featureFlags[featureFlag]?.enabled;
    }, [featureFlags]);

    const leftMenuOpened = useSelector(selectLeftMenuOpened);

    const closeLeftMenu = useCallback(() => {
        actions.current.setLeftMenuOpened(false);
    }, [actions]);

    const onSignOut = useCallback(() => {
        actions.current.logout();
    }, [actions]);

    useEffect(() => {
        closeLeftMenu();
    }, [closeLeftMenu, location.key]);

    const rootClasses = clsx(classes.root, { 'opened': leftMenuOpened, 'collapseClosed': isDesktop && isOpenedCollapseMenu });

    const showTooltip = isOpenedCollapseMenu && isDesktop;

    return (
        <>
            <CollapseMenu isOpened={isOpenedCollapseMenu} onToggle={onToggleCollapseMenu} />
            <Box className={rootClasses}>

                {isDesktop && !isOpenedCollapseMenu && <LeftMenuLogo />}

                {isMobile && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pr="18px"
                    >
                        <UserMenu />

                        <IconButton
                            className={classes.closeMenuButton}
                            onClick={closeLeftMenu}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}

                <List component="nav" className={classes.nav}>
                    <Box>
                        {leftMenuTopItemsMyTasksRender(hasAppAccess, isFeatureEnabled, showTooltip)}

                        {leftMenuItemsRender(leftMenuTopItems, hasAppAccess, isFeatureEnabled, showTooltip)}

                        {leftMenuItemsRender(leftMenuBottomItems, hasAppAccess, isFeatureEnabled, showTooltip)}

                        <ListItem className={classes.navItem}>
                            {isMobile && (
                                <Button
                                    variant="outlined"
                                    className={classes.signOutButton}
                                    onClick={onSignOut}
                                >
                                    <Trans>Sign Out</Trans>
                                </Button>
                            )}
                            {isTablet && (
                                <ButtonBase onClick={onSignOut}>
                                    <ExitToApp />
                                </ButtonBase>
                            )}
                        </ListItem>
                    </Box>
                    {( isDesktop || isTablet ) && 
                        <Box>
                            
                            <ListItem style={{position: 'relative', height: '95px'}}>
                                <LeftMenuUser showTooltip={showTooltip}/>
                            </ListItem>
                        </Box>
                    }
                </List>

            </Box>

            {isMobile && (
                <Backdrop
                    className={classes.menuBackdrop}
                    open={leftMenuOpened}
                    onClick={closeLeftMenu}
                />
            )}
        </>
    );
}
