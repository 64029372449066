import { Theme, useMediaQuery } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';

export function useMediaBefore(value: Breakpoints | number) {
    return useMediaQuery(
        (theme: Theme) => {
            return theme.breakpoints.down(value);
        },
        { noSsr: true },
    );
}
