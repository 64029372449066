import { isTest } from 'utils/globals';
import { apiErrorHandler } from 'store/apiCommunication/errorHandler';
import { eventChannel, EventChannel } from 'redux-saga';
import { IMessage, workerSource } from 'api/model';
import { createWorkerConnectionMockable } from 'store/apiCommunication/workerConnection';

let webWorker: Worker;

export function createWorkerConnection() {
    webWorker = createWorkerConnectionMockable(apiErrorHandler);
}

function createWebWorkerListenerReal(): EventChannel<MessageEvent> {
    return eventChannel(emitter => {
        webWorker.onmessage = (({ data }: MessageEvent) => {
            if (data.source && data.source === workerSource) {
                emitter(data);
            }
        });

        return () => {
            webWorker.onmessage = null;
        };
    });
}

export function postMessageWebWorker<P>(message: IMessage<P>) {
    webWorker.postMessage(message);
}

export let eventDispatcherFromWebWorker: (input: MessageEvent) => void;

function createWebWorkerListenerTest(): EventChannel<MessageEvent> {
    return eventChannel<MessageEvent>(emitter => {
        eventDispatcherFromWebWorker = emitter;

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    });
}

export function createWebWorkerListener(): EventChannel<MessageEvent> {
    return isTest
        ? createWebWorkerListenerTest()
        : createWebWorkerListenerReal();
}
