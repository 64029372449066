import { makeStyles, Theme } from '@material-ui/core';
import { BlueColor } from 'utils/styles/constants';

export const useAttachmentStyles = makeStyles((theme: Theme) => ({
    textPrimary: {
        maxWidth: theme.spacing(19),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    fileInfo: {
        color: theme.palette.getContrastText(theme.palette.primary.dark)
    },
    fileAvatar: {
        backgroundColor: BlueColor.Dark,
    },
}));
