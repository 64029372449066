import { put, select, takeEvery } from 'typed-redux-saga';
import {
    CreateConversationSuccessActions, GetStreamRoleMembersActions,
    requestAddChannelImage,
    requestChatRoles,
    requestCreateChannel,
    requestCreateDirectMessageChat, requestEditChannelImage, requestInviteByRoles, requestRemoveChannelRoles, RoleFromChatActions
} from 'api/getStream/getStreamActions';
import { StreamChatChannelType } from 'modules/connect/messenger/model';
import { browserHistory, Routes } from 'components/router/model';
import { generatePath } from 'react-router-dom';
import { selectUserRoles } from '../user/userSelectors';
import { requestCompanyRoles } from '../../../api/core/actions';
import { selectUser } from '../auth/authSelectors';
import { IInviteByRolesData, IRoleInviteSuccessResponse } from '../../../api/getStream/getStreamModel';
import { openAvatarEditor } from '../user/userActions';

function handleConversationCreated({ type, payload }: CreateConversationSuccessActions) {
    const channelType = type === requestCreateChannel.successType
        ? StreamChatChannelType.Team
        : StreamChatChannelType.Messaging;

    browserHistory.push(generatePath(Routes.Connect, { cid: `${channelType}:${payload}` }));
}

function* conversationCreatedSaga() {
    yield takeEvery([
        requestCreateChannel.successType,
        requestCreateDirectMessageChat.successType,
    ], handleConversationCreated);
}

function* handleRolesChatRetrieved({ payload }: GetStreamRoleMembersActions) {
    const roleIds = (payload as IInviteByRolesData)?.roles ?? [];
    if (!roleIds || roleIds.length < 1) {
        return;
    }
    const currentRoleIds = yield* select(selectUserRoles);
    const fetchedRoleIds = currentRoleIds.map(x => x.roleId);
    const missingRoleIds = roleIds.filter(x => !fetchedRoleIds.includes(x));
    if (missingRoleIds && missingRoleIds.length > 0) {
        const currentUser = yield* select(selectUser);
        yield* put(requestCompanyRoles.init(currentUser?.companyId ?? ''))
    }
}

function* chatRolesRetrievedSaga() {
    yield takeEvery(requestChatRoles.successType, handleRolesChatRetrieved);
}

function* handleChatRoleRemoval({ payload }: RoleFromChatActions) {
    if (typeof payload === 'string') {
        yield* put(requestChatRoles.init(payload));
    } else {
        const chatId = (payload as IRoleInviteSuccessResponse)?.channelId;
        if (chatId) {
            yield* put(requestChatRoles.init(chatId));
        }
    }
}

function* chatRoleRemoveSaga() {
    yield takeEvery([
        requestRemoveChannelRoles.successType,
        requestInviteByRoles.successType,
    ], handleChatRoleRemoval);
}
function* removeChannelRoles() {
    yield* takeEvery(
        requestRemoveChannelRoles.successType,
        function* ({ payload }: ReturnType<typeof requestRemoveChannelRoles.success>) {
            if(payload?.rolesToInvite?.length){
                yield* put(requestInviteByRoles.init({           
                    channelId: payload.channelId,
                    roles: payload?.rolesToInvite || []
                }));
            }
        },
    );
}

function* editChannelImageSaga() {
    yield* takeEvery([
        requestAddChannelImage.successType,
        requestEditChannelImage.successType,
    ],
        function* ({ payload }: ReturnType<typeof requestEditChannelImage.success>) {
            yield* put(openAvatarEditor(false));
        },
    );
}

export const getStreamSagas = [
    conversationCreatedSaga,
    chatRolesRetrievedSaga,
    chatRoleRemoveSaga,
    removeChannelRoles,
    editChannelImageSaga
];
