import { Skeleton } from '@material-ui/lab';

export const SkeletonLaunchCover = () => {
    return (
        <Skeleton
            animation="wave"
            height={200}
            width={"100%"}
            variant="rect"
            style={{
                borderRadius: '4px'
            }}
    />
    )
}