import React, { useCallback, useMemo, useRef } from 'react';
import { StoreTaskAttachmentModel } from 'api/task/storeTask/storeTaskModel';
import { makeStyles, Theme } from '@material-ui/core';
import { AttachmentThumbnail, useGetAttachmentUrl, useGetThumbnailUrl } from '../AttachmentThumbnail/AttachmentThumbnail';
import { Lightbox, LightboxApi, LightboxItem } from '../Lightbox/Lightbox';
import { VideoSlide } from '../Lightbox/VideoSlide';
import { useActions } from 'utils/store/useActions';
import { requestDownloadFile } from 'api/files/filesActions';
import videoImageUrl from 'images/video.png';
import { IDocumentPreviewFile, PDFSlide } from '../Lightbox/PDFSlide';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { thumbnailHeight } from '../AttachmentThumbnail/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',

        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),

            '&:last-child': {
                marginRight: 0,
            },
        },
    },
}));

interface IAttachmentsGalleryProps {
    attachments: StoreTaskAttachmentModel[];
    onDeleteHandler?: (attachment: StoreTaskAttachmentModel) => void;
    fallbackImageHeigth?: number;
}

interface IElementExtended extends LightboxItem {
    fileId: string;
}

const viewableImageFileNameRegex = /\.(avif|jpe?g|a?png|gif|webp|bmp)($|\?)/i;
const viewableVideoFileNameRegex = /\.(mp4|ogg|webm)($|\?)/i;
const viewablePDFFileNameRegex = /\.(pdf)($|\?)/i;

const getAttachmentThumbnailUrl = (
    attachment: StoreTaskAttachmentModel,
    isVideo: boolean,
    urlGetter: (fileId: string) => string,
): string | undefined => {
    const { fileId, hasThumbnail } = attachment;

    if (hasThumbnail) {
        return urlGetter(fileId);
    } else if (isVideo) {
        return videoImageUrl;
    }

    return undefined;
};
const CallbackListItem = (props: IDocumentPreviewFile) => viewableVideoFileNameRegex.test(props?.description as string) ? <VideoSlide {...props} /> : <PDFSlide {...props} />;

export function AttachmentsGallery({ attachments, onDeleteHandler, fallbackImageHeigth = thumbnailHeight }: IAttachmentsGalleryProps) {
    const classes = useStyles();
    const lightboxRef = useRef<LightboxApi>();
    const actions = useActions({ requestFileDonwolad: requestDownloadFile.init });
    const attachmentUrlGetter = useGetAttachmentUrl();
    const thumbnailUrlGetter = useGetThumbnailUrl();
    const isTasksEnableDocumentPreviewEnabled = useFeatureState(FeatureFlags.TasksEnableDocumentPreview);


    const galleryItems: IElementExtended[] = useMemo(() => {
        return attachments
            .filter(({ name }) => isTasksEnableDocumentPreviewEnabled ? true : (viewableImageFileNameRegex.test(name) || viewableVideoFileNameRegex.test(name)))
            .map((attachment) => {
                const { fileId, name } = attachment;
                const isVideo = viewableVideoFileNameRegex.test(name);
                const isPDF = viewablePDFFileNameRegex.test(name);
                const isImage = viewableImageFileNameRegex.test(name) || viewableVideoFileNameRegex.test(name);

                return {
                    fileId,
                    description: name,
                    original: isImage || isPDF ? attachmentUrlGetter(fileId) : thumbnailUrlGetter(fileId),
                    thumbnail: getAttachmentThumbnailUrl(attachment, isVideo, thumbnailUrlGetter),
                    renderItem: isVideo || isPDF ? CallbackListItem : undefined,
                };
            });
    }, [attachments, attachmentUrlGetter, thumbnailUrlGetter, isTasksEnableDocumentPreviewEnabled]);

    const onDownloadHandler = useCallback(async ({ fileId, name }: StoreTaskAttachmentModel) => {
        actions.current.requestFileDonwolad({
            fileId,
            fileName: name,
        });
    }, [actions]);

    const onClickHandler = useCallback((attachment: StoreTaskAttachmentModel) => {
        const idx = galleryItems.findIndex(element => element.fileId === attachment.fileId);

        if (idx >= 0) {
            lightboxRef.current?.open(idx);
        } else {
            onDownloadHandler(attachment);
        }
    }, [galleryItems, onDownloadHandler]);

    return (
        <>
            <div className={classes.root}>
                {attachments.map(item => (
                    <AttachmentThumbnail
                        key={item.fileId}
                        attachment={item}
                        onClickHandler={onClickHandler}
                        onDeleteHandler={onDeleteHandler}
                        onDownloadHandler={onDownloadHandler}
                        fallbackImageHeigth={fallbackImageHeigth}
                    />
                ))}
            </div>
            <Lightbox items={galleryItems} apiRef={lightboxRef} />
        </>
    );
}
