import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { Check, Close, ThumbDownAltOutlined } from '@material-ui/icons';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { Breakpoints, GreenColor, orangeColor, UtilColors } from 'utils/styles/constants';
import { ForwardMailIcon } from 'components/icons/ForwardMailIcon';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ITaskIconProps {
    status: DisplayedTaskStatus | undefined;
}

const useTaskIconStyles = makeStyles((theme: Theme) => ({
    icon: {
        margin: theme.spacing(0, 0.5),
        height: theme.spacing(4),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            width: '20px',
        },
    },
    iconCompleted: {
        color: GreenColor.Green2,
    },
    iconError: {
        color: UtilColors.Red2,
    },
    iconExpired:{
        color: orangeColor,
    },
    iconDefault: {
        color: UtilColors.TextSecondary,
    },
}));

export function TaskIcon({ status }: ITaskIconProps) {
    const classes = useTaskIconStyles([]);
    switch (status){
        case DisplayedTaskStatus.Completed:
        case DisplayedTaskStatus.CompletedOverdue:
            return (
                <Check className={clsx(classes.icon, classes.iconCompleted)}/>
            );
        case DisplayedTaskStatus.Overdue:
            return (
                <Close className={clsx(classes.icon, classes.iconError)}/>
            );
        case DisplayedTaskStatus.Expired:
            return (
                <FontAwesomeIcon  className={clsx(classes.icon, classes.iconExpired)} icon={faHourglassHalf}/>
            );
        case DisplayedTaskStatus.Default:
            return (
                <Close className={clsx(classes.icon, classes.iconDefault)}/>
            );
        case DisplayedTaskStatus.Submitted:
        case DisplayedTaskStatus.SubmittedOverdue:
            return (
                <ForwardMailIcon className={clsx(classes.icon, classes.iconDefault)}/>
            );
        case DisplayedTaskStatus.Declined:
            return (
                <ThumbDownAltOutlined className={clsx(classes.icon, classes.iconError)}/>
            );
        default:
            return null;
    }
}
