import { Fade, Tooltip } from '@material-ui/core';
import React from 'react';
import TimerIcon from '@material-ui/icons/Timer';
import { dateTimezone } from 'utils/helpers/dateHelper';

type TooltipTimezoneProps = {
    timeZoneId: string,
    isStore: boolean,
    isSelected: boolean
}

export const TooltipTimezone = React.forwardRef<HTMLDivElement, TooltipTimezoneProps>(({ timeZoneId, isStore, isSelected}, ref) => {
    const dateIanaTimezone = dateTimezone(timeZoneId);

    if(!isStore || !isSelected) return <></>;

    return (
        <Tooltip title={dateIanaTimezone} ref={ref} arrow TransitionComponent={Fade}>
            <TimerIcon fontSize='small'/>
        </Tooltip>
    )}
)
