import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import {
    BulkImportUsersSteps,
    openUserImportModal
} from '../../../store/modules/user/userActions';
import { baseTheme } from '../../root/theme';
import { Dialog, DialogTitle, Theme, ThemeProvider, Typography, useMediaQuery } from '@material-ui/core';
import { Breakpoints } from '../../../utils/styles/constants';
import { selectBulkUsersImportStep } from '../../../store/modules/user/userSelectors';
import { UserBulkImportStep1 } from './UserBulkImportStep1';
import { requestUsersBulkUpload } from '../../../api/files/filesActions';
import { PendingFile } from '../../../store/modules/files/filesModel';
import { useUserBulk } from './useUserBulk';
import { UserBulkAction } from 'api/files/filesModel';
import { Trans } from 'react-i18next';

type UserImportModalProps = {
    isOpen: boolean;
    isLoading?: boolean;
    theme?: Theme;
}

export const UserImportModal = (
    {
        isOpen,
        isLoading,
        theme = baseTheme,
    }: UserImportModalProps) => {
    const dispatch = useDispatch();
    const currentStep = useSelector(selectBulkUsersImportStep);
    const fullscreen = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const { bulkTypeAction, handleChangeBulkAction } = useUserBulk();

    const handleDiscard = useCallback(() => {
        dispatch(openUserImportModal(false));
    }, [dispatch]);
    const handleSubmit = useCallback((files: PendingFile[], sendEmailConfirmation: boolean) => {
        dispatch(requestUsersBulkUpload.init({
            localFiles: files,
            emailSend: bulkTypeAction.type === UserBulkAction.Import ? sendEmailConfirmation : false,
            bulkTypeAction: bulkTypeAction.type
        }));
    }, [dispatch, bulkTypeAction]);

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleDiscard}
                fullScreen={fullscreen}
            >
                <DialogTitle>
                    <Typography variant="h2" component="span">
                        <Trans>Bulk User</Trans> {bulkTypeAction.label}
                    </Typography>
                </DialogTitle>
                {currentStep === BulkImportUsersSteps.Initial && (
                    <UserBulkImportStep1
                        isLoading={isLoading ?? false}
                        onSubmit={handleSubmit}
                        onCancel={handleDiscard}
                        onChangeBulkAction={handleChangeBulkAction}
                        bulkTypeAction={bulkTypeAction}
                    />
                )}
            </Dialog>
        </ThemeProvider>
    );
}
