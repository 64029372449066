import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { IStoreState } from 'store/rootReducer';

export const useSelectorOnce = <T>(selector: (state: IStoreState) => T) => {
    const value = useSelector(selector)
    const ref = useRef(value);

    useEffect(() => {
        if (!ref.current) {
            ref.current = value;
        }
    }, [value])

    return ref.current;
};
