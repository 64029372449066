import { makeStyles, Theme } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';

export const useTaskGalleryStyles = makeStyles((theme: Theme) => ({
    containerDetailHeader: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
        }
    },
    card: {
        marginLeft: theme.spacing(.25),
        marginRight: theme.spacing(.25),
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: '24px',
        lineHeight: '32.02px',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            lineHeight: '26.68px',
        },
    },
    flex: {
        display: 'flex',
    },
    locationName: {
        fontSize: '24px',
        lineHeight: '32.02px',
        fontWeight: 600,

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            lineHeight: '26.68px',
        },
    },
    parentLocationName:{
        marginRight: 8,
        lineHeight: 'normal'
    },
    directLinkIcon: {
        lineHeight: 0,
        marginLeft: theme.spacing(0.5),
    },
    icon: {
        margin: theme.spacing(0, 0.5),
        height: theme.spacing(4),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            width: '20px',
        },
    },
    containerSelectStore: {
        position: 'relative',
        height: 32,
        minWidth: 250,
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5)
        }
    },
    selectStore: {
        width: '100%',
        position: 'absolute',
        top: -13
    },
    containerHeader: {
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            flexWrap: 'wrap',
        },
    },
    taskStoreSelect: {
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            margin: 'auto'
        }
    }
}));
