import { Box } from '@material-ui/core'
import { UserInfo } from 'components/userInfo/UserInfo';
import React, { useCallback, useMemo } from 'react'
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useActions } from 'utils/store/useActions';
import { useSelector } from 'react-redux';
import { selectCommentById, selectCommentUpdating, selectIsActiveTextEditor, selectTaskConversationStore } from 'store/modules/task/taskConversation/taskConversationSelectors';
import { isActiveTextEditor } from 'store/modules/task/taskConversation/taskConversationActions';
import { resetPendingConversationFiles, resetUploadedConversationFiles } from 'store/modules/files/filesActions';
import { selectStoreTaskModalActiveId } from 'store/modules/task/storeTask/storeTaskSelectors';
import { ConversationType } from 'api/task/taskConversation/taskConversationModel';
import { TextComment } from '../common/TextComment';
import { getUploadedConversationFiles } from 'store/modules/files/filesSelectors';
import { requestEditComment } from 'api/task/taskConversation/taskConversationActions';
import { addTaskConversationSchema, getMentions } from './validation';
import { Formik } from 'formik';
import { TextConversationEditor } from '../common/TextConversationEditor';

type CommentProps = {
  conversation: ConversationType;
  storeTaskId: string;
}

export const Comment: React.FC<CommentProps>  = ({ conversation, storeTaskId }) => {
  const currentUser = useCurrentUser();
  const storeFilterValue = useSelector(selectTaskConversationStore);
  const modalTaskId = useSelector(selectStoreTaskModalActiveId);
  const activeTextEditor = useSelector(selectIsActiveTextEditor);
  const actions = useActions({
      handleShowTextEditor: isActiveTextEditor,
      resetPendigFiles: resetPendingConversationFiles,
      resetUploadedFiles: resetUploadedConversationFiles
  });

  if(!currentUser || (!storeFilterValue && !modalTaskId)) return <></>;

  const onChangeActiveTextEditor = (type: string) => {
      actions.current.resetPendigFiles();
      actions.current.resetUploadedFiles();
      actions.current.handleShowTextEditor(type);
  };

  return (
    <Box display="flex" flexDirection="row" mt={2}>
        <Box width={50}>
          <UserInfo userId={conversation.createdBy} showInformation={false}/>
        </Box>
        {activeTextEditor === conversation.id ? 
            <EditConversationForm />
            : <TextComment conversation={conversation} onEdit={()=>onChangeActiveTextEditor(conversation.id)} storeTaskId={storeTaskId}/>
        }
    </Box>
  )
}

const EditConversationForm: React.FC = () => {
  const commentId = useSelector(selectIsActiveTextEditor);
  const comment = useSelector(selectCommentById)[commentId];

  const commentUpdating = useSelector(selectCommentUpdating);
  const uploadedFiles = useSelector(getUploadedConversationFiles);
  const actions = useActions({
      editComment: requestEditComment.init,
      resetPendigFiles: resetPendingConversationFiles,
      resetUploadedFiles: resetUploadedConversationFiles,
      handleShowTextEditor: isActiveTextEditor,
  });

  const initialFormValues = useMemo(() => ({
    text: comment?.text,
    mentionedUsers: [],
    commentId: comment?.id,
    attachments: comment?.attachments
  }),[comment]);

  const onSubmit = useCallback((values) => {
    if (uploadedFiles.length > 0) {
        values.attachments = [...uploadedFiles];
    }
  
    const mentions = getMentions(values.text);
    actions.current.editComment({...values, mentionedUsers: mentions});
  },[actions, uploadedFiles]);
  

  const handleCancelEditor =  useCallback((type: string) => {
      actions.current.resetPendigFiles();
      actions.current.resetUploadedFiles();
      actions.current.handleShowTextEditor(type)
  },[actions]);

  if(!comment) return <></>;

  return(
    <Formik
        initialValues={initialFormValues}
        validationSchema={addTaskConversationSchema}
        onSubmit={onSubmit}
    >
        {props => (
            <TextConversationEditor isUpdating={commentUpdating} onCancel={() => handleCancelEditor('')} {...props}/>
        )}
    </Formik>
  )
}
