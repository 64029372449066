import React, { useCallback, useState } from 'react';
import { Box, InputAdornment, SvgIconProps, TextField, TextFieldProps } from '@material-ui/core';
import { useField } from 'formik';
import { Close } from '@material-ui/icons';
import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import { BaseInput } from 'components/basicInputs/BaseInput';
import { IInputProps, ITextInputClasses } from 'components/basicInputs/model';
import { InputEndAdornment } from 'components/basicInputs/InputEndAdornment';
import { hasFormikError } from 'components/basicInputs/utils';
import { InputCounter } from '../InputCounter';
import { InputError } from '../InputError';

interface ITextInputProps extends IInputProps,
    Pick<TextFieldProps, 'size' | 'multiline' | 'variant' | 'onKeyDown' | 'onBlur' | 'autoFocus' | 'disabled'> {
    classes?: ITextInputClasses;
    inputProps?: TextFieldProps['inputProps'],
    separateLabel?: boolean,
    showAdornment?: boolean,
    showCounter?: boolean,
    maxLength?: number,
}

export function TextInput({
    name,
    id = name,
    label,
    size,
    separateLabel = true,
    showAdornment = true,
    classes: customClasses = {},
    inputProps = {},
    variant = 'outlined',
    showCounter,
    maxLength,
    onBlur: onBlurExternal,
    ...textFieldProps
}: ITextInputProps) {
    const classes = useInputsStyles([]);
    const [field, meta] = useField(name);
    const [active, setActive] = useState(false);
    const hasError = hasFormikError(meta);

    const { onChange, onBlur, value } = field;
    const trimmedValue = value?.trim() || '';

    const onInputFocus = setActive.bind(null, true);

    const onInputBlur = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setActive(false);
        onBlur(event);
        onBlurExternal?.(event);
    }, [setActive, onBlur, onBlurExternal]);

    const onReset = useCallback((event: React.MouseEvent) => {
        event.preventDefault();
        onChange({ target: { name, value: '' } });
    }, [onChange, name]);

    const fontSize: SvgIconProps['fontSize'] = size === 'small' ? 'small' : 'medium';
    let endAdornment: JSX.Element | null = active && trimmedValue.length > 0
        ? (
            <InputAdornment position="end" onMouseDown={onReset}>
                <Close className={classes.clickableIcon} fontSize={fontSize} />
            </InputAdornment>
        )
        : (
            <InputEndAdornment
                value={trimmedValue}
                hasError={hasError}
                fontSize={fontSize}
            />
        )

    if (!showAdornment) {
        endAdornment = null;
    }

    const inputPropsClasses = {
        input: customClasses?.input,
        root: customClasses?.inputRoot,
        notchedOutline: customClasses?.notchedOutline,
    };

    return (
        <BaseInput
            id={id}
            label={label}
            classes={customClasses}
            separateLabel={separateLabel}
        >
            <TextField
                id={id}
                name={name}
                classes={{ root: customClasses?.controlRoot }}
                error={hasError}
                onChange={onChange}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                InputProps={{
                    classes: inputPropsClasses,
                    endAdornment,
                }}
                inputProps={{
                    'data-lpignore': true,
                    ...inputProps,
                }}
                value={value}
                variant={variant}
                size={size}
                placeholder={separateLabel ? undefined : label}
                {...textFieldProps}
            />
            <Box display="flex" justifyContent="space-between">
                {hasError && (
                    <InputError error={meta.error} />
                )}
                <Box />
                {showCounter && (
                    <InputCounter
                        current={trimmedValue.length}
                        max={maxLength}
                    />
                )}
            </Box>
        </BaseInput>
    );
}
