import { LaunchLayoutItem } from './LaunchLayoutItem'
import { Launch } from 'api/launch/launchModel'
import { Box, Link, Tooltip } from '@material-ui/core';
import { FaviconThumbanail } from './FaviconThumbanail';
import { getFaviconGoogleService } from 'api/helpers';
import { LaunchActionsCell } from './LaunchActionsCell';
import { LaunchDragHandle } from '../launchReorder/LaunchDragHandle';

export type LaunchItemProps = {
  value: Launch
  canCreateDelete?: boolean,
  showDrag?: boolean,
  isDisabledDrag?: boolean
}

export const LaunchItem = (props: LaunchItemProps) => {
  const { value, canCreateDelete, showDrag, isDisabledDrag } = props;

  return (
    <LaunchLayoutItem>
      <Box display="flex" justifyContent="center" alignContent="center">
        {canCreateDelete && showDrag && <LaunchDragHandle isDisabledDrag={isDisabledDrag}/>}
        {canCreateDelete && <LaunchActionsCell {...value} />}
        <Tooltip title={value.caption}>
          <Link
            href={value.target}
            target='_blank'
            rel="noopener noreferrer"
          >
            {value.thumbnail
              ? <FaviconThumbanail
                caption={value.caption}
                target={value.target}
                thumbnail={value.thumbnail} />
              :
              <img
                width={80}
                height={80}
                src={getFaviconGoogleService(value.target)} alt={value.caption}
                style={{
                  filter: 'brightness(110%) contrast(90%)',
                  borderRadius: '25%'
                }}
              />}
          </Link>
        </Tooltip>
      </Box>
    </LaunchLayoutItem>
  )
};

