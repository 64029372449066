import React from 'react';
import { Box } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { Formik } from 'formik';
import { userProfileSchema } from './model';
import { CommonUserFormInputs } from './CommonUserFormInuputs';
import { UserProfileDTO } from 'api/user/userModel';
import { FormActions } from 'modules/shared/components/FormActions';

type UserProfileFormProps = {
    initialValues: UserProfileDTO;
    onSubmit: (data: UserProfileDTO) => void;
    isInProgress?: boolean;
};

export function UserProfileForm({ initialValues, isInProgress, onSubmit }: UserProfileFormProps) {
    const classes = useFormStyles();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={userProfileSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Box className={classes.formInputs}>
                        <CommonUserFormInputs disabled={isInProgress} isAdminEdit={false} />
                    </Box>

                    <FormActions isInProgress={isInProgress} />
                </form>
            )}
        </Formik>
    );
}
