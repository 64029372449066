import { put, select, takeEvery } from 'typed-redux-saga';
import { getTaskTemplate, getScheduledTaskHistory, requestScheduledTasks, updateTaskTemplate, getStoreIdsByScheduledTask, getScheduledTaskHistoryFlags, SCHEDULED_TASK_HISTORY_FLAGS_GET } from 'api/task/scheduledTask/scheduledTaskActions';
import { requestUserInfos } from 'store/modules/user/userActions';
import { navigateTo, showToastMessage } from 'store/modules/appState/appStateActions';
import { generatePath } from 'react-router';
import { Routes } from 'components/router/model';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { selectUsersIds, selectUsersLoadingById } from 'store/modules/user/userSelectors';
import { difference } from 'lodash';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { ItemsById } from 'model/entity';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { TasksScheduledFlagsHistory } from 'modules/task/singleTask/components/taskActivity/model';
import i18n from 'i18n';

function* requestScheduledTasksSuccessWatcher() {
    yield* takeEvery(
        requestScheduledTasks.successType,
        function* ({ payload }: ReturnType<typeof requestScheduledTasks.success>) {
            const { tasks } = payload;
            yield put(requestUserInfos(tasks.map(({ createdBy }) => createdBy)));
        },
    );
}

export function* updateScheduledTaskWatcherSaga() {
    yield takeEvery(
        updateTaskTemplate.successType,
        function* () {
            yield put(navigateTo(generatePath(Routes.TaskListScheduled)));
            yield put(showToastMessage({
                message: i18n.t('Task Updated'),
                options: {
                    variant: ToastVariant.Success
                },
            }))
        },
    );
}

export function* getScheduledTaskHistorySaga() {
    yield takeEvery(
        getScheduledTaskHistory.successType,
        function* ({ payload: { data: taskHistory} }: ReturnType<typeof getScheduledTaskHistory.success>) {
            const userIds = taskHistory.map(task => (task.userId));
            const loadedUserIds = yield* select(selectUsersIds);
            const loadingUserIds = yield* select(selectUsersLoadingById);
            const missingUserIds = difference(userIds, loadedUserIds).filter(userId => !loadingUserIds[userId]);
            if (missingUserIds.length > 0) {
                yield* put(requestMultipleUsersInfo.init(missingUserIds));
            }
        },
    );
}

function* getTaskTemplateSuccessWatcher() {
    yield takeEvery(
        getTaskTemplate.successType,
        function* ({ payload }: ReturnType<typeof getTaskTemplate.success>) {
            const { locationIds, hasCompleteLocationTree } = payload;
            if(!hasCompleteLocationTree && locationIds.length){
                yield* put(getStoreIdsByScheduledTask.init({id: payload.id, locationIds}));
            }
        },
    );
}

function* getScheduledTaskHistoryFlagsWatcher() {
    yield takeEvery(
        SCHEDULED_TASK_HISTORY_FLAGS_GET,
        function* ({ payload: templateId }: ReturnType<typeof getScheduledTaskHistoryFlags>) {
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const flagsDisabled: string[] = [];
            Object.keys(TasksScheduledFlagsHistory).forEach(flag => {
                if(!featureFlags[flag]?.enabled){
                    flagsDisabled.push(TasksScheduledFlagsHistory[flag])
                }
            });
            yield* put(getScheduledTaskHistory.init({templateId, filterFeatureFlags: flagsDisabled}));
        },
    );
}

export const scheduledTaskSagas = [
    requestScheduledTasksSuccessWatcher,
    updateScheduledTaskWatcherSaga,
    getScheduledTaskHistorySaga,
    getTaskTemplateSuccessWatcher,
    getScheduledTaskHistoryFlagsWatcher
];
