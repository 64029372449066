import { Button, Fade, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { t } from 'i18next';
import { useStylesViewSwtich } from './useStylesViewSwitch';
import { MyTasksCounterBadge } from 'modules/root/LeftMenu/badgeWrappers';
import { useActions } from 'utils/store/useActions';
import { Routes } from 'components/router/model';
import { NavLink } from 'react-router-dom';
import { taskFilterTab } from 'store/modules/task/taskFilter/taskFilterActions';

export const MyTasksButton = ({ ...res}) => {
    const classes = useStylesViewSwtich();
    const tasksAutomationMyTasksEnabled = useFeatureState(FeatureFlags.TasksAutomationMyTasks);
    const actions = useActions({
        updateTaskFilterTab: taskFilterTab
    });

    const btnClass = clsx(res.className, classes.button);

    if (!tasksAutomationMyTasksEnabled) {
        return <></>;
    }
    const restProps = {...res, className: btnClass}

    const handleFilterTasks = () => {
        actions.current.updateTaskFilterTab(TasksViewModeMyTasks);
    }
    return (
        <Button
            component={NavLink}
            to={Routes.MyTasks}
            onClick={handleFilterTasks}
            {...restProps}
        >
            <Tooltip title={`${t('My Tasks')}`} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <PersonOutlineRoundedIcon />
            </Tooltip>
        <MyTasksCounterBadge isTaskPage/>
        </Button>
    );
};
