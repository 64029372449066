/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useState } from 'react';
import { IConnectContext } from './model';
import { useInitContext } from './hooks/useInitConnect';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';

init({data})

const ConnectContext = React.createContext<IConnectContext | null>(null);

export function ConnectProvider({ children }: React.PropsWithChildren<any>) {
    const [context, setContext] = useState<IConnectContext | null>(null);
    useInitContext(setContext);

    return (
        <ConnectContext.Provider value={context}>
            { children }
        </ConnectContext.Provider>
    );
}

export const ConnectConsumer = ConnectContext.Consumer;

export function useConnect() {
    const context = useContext(ConnectContext);
    return context;
}
