import { useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export interface FixedSizeListWidthData {
    containerRef: React.MutableRefObject<HTMLElement | null>,
    width: string,
}

export function useFixedSizeListWidth(widthInner: number) {
    const [widthState, setWidthState] = useState<number>(0);
    const [widthValue, setWidthValue] = useState<string>('');
    const containerRef = useRef<HTMLElement | null>(null);
    useResizeDetector({ targetRef: containerRef });
    const element = containerRef.current;

    let width = '100%';
    if (element && element.clientWidth < element.scrollWidth && widthInner > element.scrollWidth  ) {
        width = widthState !== widthInner ? element.scrollWidth + 7 + 'px' : widthValue;
        if (widthState !== widthInner) {
            setWidthState(widthInner);
            setWidthValue(width);
        }
    }

    return { containerRef, width };
}
