import React, { useCallback } from 'react';
import { AddCircleOutline } from '@material-ui/icons';
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { KeyNames } from 'utils/globals';
import { TASK_MAX_TITLE_LENGTH } from 'modules/task/common/form/validation';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) => ({
    checklistInput: {
        flexGrow: 1,
    },
    addButton: {
        '&&': {
            margin: theme.spacing(0, 0, 0, 1),
        },
    },
}));

export interface IAddChecklistItemInputProps {
    handleSubmit: (e?: any) => void; // intended to be used in bundle with the Formik component
}

export function AddChecklistItemInput({ handleSubmit }: IAddChecklistItemInputProps) {
    const classes = useStyles();

    const handleKeyDown = useCallback(event => {
        if (event.key === KeyNames.Enter) {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit();
        }
    }, [handleSubmit]);

    return (
        <Box display="flex">
            <TextInput
                name="item"
                label={`${t('Add another item')}`}
                variant="standard"
                classes={{ root: classes.checklistInput }}
                separateLabel={false}
                onKeyDown={handleKeyDown}
                showCounter
                maxLength={TASK_MAX_TITLE_LENGTH}
            />
            <Box>
                <IconButton
                    onClick={handleSubmit as any}
                    className={classes.addButton}
                >
                    <AddCircleOutline color="primary" />
                </IconButton>
            </Box>
        </Box>
    );
}
