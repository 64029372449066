import React from "react";
import Textarea from 'react-textarea-autosize';
import { CustomTextEditor } from "./CustomTextEditor";
import { CloseIcon, DefaultStreamChatGenerics, LoadingIndicatorProps, SendButton, SuggestionListProps } from 'stream-chat-react';
import { markdownConverter, maxLengthCharacters, removeEmptyBlockComments } from "../../utils/customTextEditor";
import clsx from "clsx";
import { SearchQueryParams } from "stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch";
import { UserResponse } from "stream-chat";
import { IconButton } from "@material-ui/core";
import { ReactTextareaAutocomplete } from "./GetStream/ReactTextareaAutocomplete";
import "./style.css";

const errorMessage = (message: string) =>
    console.error(
        `RTA: dataProvider fails: ${message}
        \nCheck the documentation or create issue if you think it's bug. https://github.com/webscopeio/react-textarea-autocomplete/issues`,
);

interface IReactTextareaAutocomplete {
    className: string;
    closeOnClickOutside: boolean;
    containerClassName: string;
    containerStyle: object;
    disableMentions: boolean;
    dropdownClassName: string;
    dropdownStyle: object;
    itemClassName: string;
    itemStyle: object;
    listClassName: string;
    listStyle: object;
    loaderClassName: string;
    loaderStyle: object;
    loadingComponent: LoadingIndicatorProps;
    minChar: number;
    onBlur: () => void;
    onCaretPositionChange: () => void;
    onChange: () => void;
    onSelect: () => void;
    shouldSubmit: () => void;
    style: object;
    SuggestionList: React.ComponentType<
        SuggestionListProps<DefaultStreamChatGenerics>
    >;
    trigger: ({ trigger }: { trigger: any }) => Error | null;
    value: string;
    handleSubmit: (event: React.BaseSyntheticEvent) => void;
    setText: (text: string) => void;
    numberofuploads: number;
    isEdition: boolean;
    mentionQueryParams?: SearchQueryParams<DefaultStreamChatGenerics>['userFilters'];
    mentionedUsers: UserResponse<DefaultStreamChatGenerics>[];
};

class CustomGetStreamAutocomplete extends ReactTextareaAutocomplete {

    refContainer: any;
    resetCount: number;
    valueAux: string = '';
    constructor(props: IReactTextareaAutocomplete) {
        super(props);
        this.refContainer = React.createRef();
        this._onEnter = this._onEnter.bind(this);
        this.resetCount = 0;
    }

    onValueChange = (newText: string, selectionEnd: number, selectionStart: number) => {
        if(newText === '<br>'){
            this.textEditorHandler('', 0, 0);
            return
        }
        this.textEditorHandler(newText, selectionEnd, selectionStart);
    };

    textEditorHandler = (newValue: string, selectionEnd: number, selectionStart: number) => {
        const { minChar, onCaretPositionChange, trigger } = this.props;

        if(!this.textareaRef) return;
        const value = newValue;

        if (onCaretPositionChange) onCaretPositionChange(this.getCaretPosition());

        this.setState({ value });

        let currentTrigger;
        let lastToken: any;

        if (this._isCommand(value)) {
            currentTrigger = '/';
            lastToken =  value;
        } else {
            const triggerTokens = Object.keys(trigger).join().replace('/', '');
            const triggerNorWhitespace = `[^\\s${triggerTokens}]*`;
            const regex = new RegExp(
                `(?!^|\\W)?[${triggerTokens}]${triggerNorWhitespace}\\s?${triggerNorWhitespace}$`,
                'g',
            );
            const tokenMatch = regex.exec(value.slice(0, selectionEnd));

            lastToken = tokenMatch && tokenMatch[tokenMatch.length - 1].trim();

            currentTrigger = (lastToken && Object.keys(trigger).find((a) => a === lastToken[0])) || null;
        }
        /*
            if we lost the trigger token or there is no following character we want to close
            the autocomplete
        */
        if (!lastToken || lastToken.length <= minChar) {
            const { currentTrigger, data, dataLoading, left, top } = this.state
            if (currentTrigger !== null || data !== null || dataLoading || left !== null || top !== null) {
                this._closeAutocomplete();
            }
            return;
        }

        const actualToken = lastToken.slice(1);

        if (!currentTrigger) return;

        this.setState(
            {
                actualToken,
                currentTrigger,
                selectionEnd,
                selectionStart,
            },
            () => {
                try {
                    this._getValuesFromProvider();
                } catch (err) {
                    errorMessage(err.message);
                }
            },
        );
    };

    handleClickSubmit = (value: string) => {
        if(this.refContainer?.current){
            this.valueAux = value;
            this.props.setText(this.textareaRef?.value);
            setTimeout(() => {
                const thirdChildButton = this.refContainer.current.lastChild;
                thirdChildButton.click();
            }, 0);
        }
    }

    isActiveSomeTrigger = () => {
        const accordionOperationChildren = Array.from(this.refContainer.current?.children ?? []);
        return accordionOperationChildren.length > 3;
    }

    handleSendMessage = (event: React.BaseSyntheticEvent<object, any, any>) => {
        event.persist();
        const markdownContent = removeEmptyBlockComments(markdownConverter.makeMarkdown(this.valueAux).replace(/<br\s*\/?>/g, "").replace(/\n\n/g, '\n'), true);
        this.props.setText(markdownContent);
        setTimeout(() => {
            if(((this.textareaRef?.value.length || 0) < maxLengthCharacters + 1) || this.props.numberofuploads < 0){
                this.resetCount = this.resetCount + 1;
                this.props.handleSubmit(event);
            }
        }, 0);
    }

    _cleanUpProps = () => {
        const props = { ...this.props };
        const notSafe = [
            'additionalTextareaProps',
            'className',
            'closeCommandsList',
            'closeMentionsList',
            'closeOnClickOutside',
            'containerClassName',
            'containerStyle',
            'disableMentions',
            'dropdownClassName',
            'dropdownStyle',
            'grow',
            'handleSubmit',
            'innerRef',
            'itemClassName',
            'itemStyle',
            'listClassName',
            'listStyle',
            'loaderClassName',
            'loaderStyle',
            'loadingComponent',
            'minChar',
            'movePopupAsYouType',
            'onCaretPositionChange',
            'onChange',
            'ref',
            'replaceWord',
            'scrollToItem',
            'shouldSubmit',
            'showCommandsList',
            'showMentionsList',
            'SuggestionItem',
            'SuggestionList',
            'trigger',
            'value',
            'maxRows',
            'setText',
            'isEdition',
            'mentionQueryParams',
            'mentionedUsers',
            'clearEditingState'
        ];
    
        // eslint-disable-next-line
        for (const prop in props) {
            if (notSafe.includes(prop)) delete props[prop];
        }
    
        return props;
    };

    _getItemOnSelect = (paramTrigger: any) => {
        const { currentTrigger: stateTrigger } = this.state;
        const triggerSettings = this._getCurrentTriggerSettings(paramTrigger);
    
        const currentTrigger = paramTrigger || stateTrigger;
    
        if (!currentTrigger || !triggerSettings) return null;
    
        const { callback } = triggerSettings;
        console.log('triggerSettings,', triggerSettings)
    
        if (!callback) return null;
    
        return (item: any) => {
          if (typeof callback !== 'function') {
            throw new Error(
              'Output functor is not defined! You have to define "output" function. https://github.com/webscopeio/react-textarea-autocomplete#trigger-type',
            );
          }
          if (callback) {
            //TODO: Here we can check the mention trigger
            return callback(item, currentTrigger);

          }
          return null;
        };
      };

    render() {
        const {
            onBlur,
            onChange,
            onClick,
            onFocus,
            onKeyDown,
            onScroll,
            onSelect,
            ...restAdditionalTextareaProps
        } = this.props.additionalTextareaProps || {};
        const { value } = this.state;
        return (
            <div
                className="team-message-input__form"
                ref={this.refContainer}
                style={{width: '100%'}}
            >

                {this.renderSuggestionListContainer()}
                <Textarea
                    data-testid='message-input'
                    {...this._cleanUpProps()}
                    className={clsx('rta__textarea', this.props.className)}
                    maxRows={1} 
                    ref={(ref) => {
                        this.props?.innerRef(ref);
                        this.textareaRef = ref;
                    }}
                    onFocus={(e) => {}}
                    value={value}
                    {...restAdditionalTextareaProps}
                    defaultValue={undefined}
                    style={{display: 'none'}}
                />
                <CustomTextEditor
                    valueText={this.state.value}
                    onChange={this.onValueChange}
                    sendMessage={this.handleClickSubmit}
                    isActiveSomeTrigger={this.isActiveSomeTrigger}
                    resetCount={this.resetCount}
                    mentionedUsers={this.props.mentionedUsers}
                    placeholder={this.props.placeholder}
                />
                {this.props.isEdition && (
                    <IconButton onClick={this.props.clearEditingState}>
                        <CloseIcon />
                    </IconButton>
                )}
                <SendButton
                    sendMessage={this.handleSendMessage}
                    style={{display: 'none'}}
                />
            </div>
        );
    }
}

export default CustomGetStreamAutocomplete;
