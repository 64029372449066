import React, { useCallback } from 'react';
import { Box, Paper, PaperProps, Checkbox } from '@material-ui/core';
import { useSelectStyles } from 'components/basicInputs/selectInput/selectStyles';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

interface ISelectAllPaperProps extends PaperProps {
    allSelected: boolean;
    toggleSelectAll: (newChecked: boolean) => void;
    disabled?: boolean;
}

export function SelectAllPaper({ allSelected, toggleSelectAll, children, disabled = false, ...props }: ISelectAllPaperProps) {
    const classes = useSelectStyles([]);
    const handleChange = useCallback(() => {
        if(!disabled){
            toggleSelectAll(!allSelected);
        }
    }, [allSelected, toggleSelectAll, disabled]);

    return (
        <Paper onMouseDown={event => {
            event.preventDefault();
        }}
        {...props}
        className={clsx(classes.multiselectPaperWrapper, props.className)}
        >
            <Box className={classes.selectAll}
                onClick={handleChange}
            >
                <Trans>Select All</Trans>
                <Checkbox className={classes.radio} color="primary"
                    checked={allSelected}
                />
            </Box>
            {children}
        </Paper>
    );
}
