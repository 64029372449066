import { IStoreState } from 'store/rootReducer';

export const selectIsActiveTextEditor = (state: IStoreState) => state.task.taskConversation.isActiveTextEditor;
export const selectTaskConversationStore = (state: IStoreState) => state.task.taskConversation.taskConversationStore;
export const selectTaskPrototypeIdConversation = (state: IStoreState) => state.task.taskConversation.taskPrototypeIdConversation;
export const selectStoreTaskConversationById = (state: IStoreState) => state.task.taskConversation.storeTaskConversationById;
export const selectCommentById = (state: IStoreState) => state.task.taskConversation.commentById;
export const selectTaskConversationCreating = (state: IStoreState) => state.task.taskConversation.taskConversationCreating;
export const selectSuggestionIds = (state: IStoreState) => state.task.taskConversation.suggestionIds;
export const selectSuggestions = (state: IStoreState) => state.task.taskConversation.suggestions;
export const selectCommentToRemove = (state: IStoreState) => state.task.taskConversation.commentToRemove;
export const selectCommentToRemoveFetching = (state: IStoreState) => state.task.taskConversation.commentToRemoveFetching;
export const selectCommentModalActiveId = (state: IStoreState) => state.task.taskConversation.commentModalActiveId;
export const selectCommentUpdating = (state: IStoreState) => state.task.taskConversation.commentUpdating;
export const selectSuggestionsFetching = (state: IStoreState) => state.task.taskConversation.suggestionsFetching;
