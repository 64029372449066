import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, styled, Typography } from '@material-ui/core';
import { BlueColor } from 'utils/styles/constants';

export interface ITaskSecitonProps {
    sectionTitle: string | React.ReactNode;
}

const Wrapper = styled(Box)({
    '& + &': {
        marginTop: '32px',
    },
});

const StyledSectionTitle = styled(Typography)({
    color: BlueColor.Dark,
    font: `'Inter', 'Arial', sans-serif`,
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 400,
    letterSpacing: '0.15px',
    fontStyle: 'normal',
});

export const TaskSection: React.FC<PropsWithChildren<ITaskSecitonProps & Partial<BoxProps>>> = ({
    children,
    sectionTitle,
    ...boxProps
}) => (
    <Wrapper {...boxProps}>
        <StyledSectionTitle>{ sectionTitle }</StyledSectionTitle>
        <Box mt={1}>
            { children }
        </Box>
    </Wrapper>
);
