import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUsersByIds } from "store/modules/user/userSelectors";
import { Channel, StreamChat } from "stream-chat";
import { ChatContextValue } from "stream-chat-react";

export function getDirectMessageUserId(client: StreamChat, channel?: Channel) {
    const ids = Object.keys(channel?.state?.members ?? {});
    return ids.find(id => id !== client.userID);
}

export const useDirectMessageUser = ({ channel, client }: ChatContextValue) => {
    const userId = useMemo(() => getDirectMessageUserId(client, channel), [client, channel]);

    return useSelector(selectUsersByIds)[userId || ''];
}
