import {
    deleteTaskTemplate,
    getScheduledTaskHistory,
    getTaskTemplate,
    getStoreIdsByScheduledTask,
    requestScheduledTasks,
    ScheduledTaskActions,
    updateTaskTemplate,
} from 'api/task/scheduledTask/scheduledTaskActions';
import { ScheduledTaskDetails, ScheduledTaskShort } from 'api/task/scheduledTask/scheduledTaskModel';
import { ItemsById } from 'model/entity';
import { combineReducers } from 'redux';
import { insertSavingOrder } from 'utils/helpers/collections';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { TaskListComponentActions, TASK_LIST_RESET_DATA } from '../taskListComponent/taskListComponentActions';

export type ScheduledTasksStoreState = {
    scheduledTasksFetching: boolean;
    scheduledTasksNextPageToken: string | null;
    scheduledTasksById: ItemsById<ScheduledTaskShort>,
    scheduledTasksOrder: string[],
    templatesById: ItemsById<ScheduledTaskDetails>;
    templatesLoadingById: ItemsById<boolean>;
    templatesUpdatingById: ItemsById<boolean>;
}

const initialState = {
    scheduledTasksFetching: false,
    scheduledTasksNextPageToken: null,
    scheduledTasksById: {},
    scheduledTasksOrder: [],
    templatesById: {},
    templatesLoadingById: {},
    templatesUpdatingById: {},
    storesByScheduledTaskFetching: false,
};

function scheduledTasksFetching(
    state: boolean = initialState.scheduledTasksFetching,
    action: ScheduledTaskActions,
): boolean {
    switch (action.type) {
        case requestScheduledTasks.initType:
            return true;
        case requestScheduledTasks.successType:
        case requestScheduledTasks.errorType:
            return false;
        default:
            return state;
    }
}

function scheduledTasksNextPageToken(
    state: string | null = initialState.scheduledTasksNextPageToken,
    action: ScheduledTaskActions,
): string | null {
    if (action.type === requestScheduledTasks.successType) {
        return action.payload.nextPageToken;
    }
    return state;
}

function scheduledTasksById(
    state: ItemsById<ScheduledTaskShort> = initialState.scheduledTasksById,
    action: ScheduledTaskActions | TaskListComponentActions,
): ItemsById<ScheduledTaskShort> {
    switch (action.type) {
        case requestScheduledTasks.successType:
            return { ...state, ...itemsByIds(action.payload.tasks) };
        case deleteTaskTemplate.successType:
            return { ...state, [action.payload.id]: undefined };
        case TASK_LIST_RESET_DATA:
            return initialState.scheduledTasksById;
        default:
            return state;
    }
}

function scheduledTasksOrder(
    state: Array<string> = initialState.scheduledTasksOrder,
    action: ScheduledTaskActions | TaskListComponentActions,
): Array<string> {
    switch (action.type) {
        case requestScheduledTasks.successType:
            return insertSavingOrder(state, action.payload.tasks.map(({ id }) => id));
        case TASK_LIST_RESET_DATA:
            return initialState.scheduledTasksOrder;
        default:
            return state;
    }
}

function templatesById(
    state: ItemsById<ScheduledTaskDetails> = initialState.templatesById,
    action: ScheduledTaskActions | TaskListComponentActions,
): ItemsById<ScheduledTaskDetails> {
    switch (action.type) {
        case getTaskTemplate.successType:
            return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload} };
        case getStoreIdsByScheduledTask.successType:
            const { id, storeIds } = action.payload;
            const currentScheduledTask = { 
                [id]: { ...state[id], locationIds: storeIds, storeIds}
            } as ItemsById<ScheduledTaskDetails>;
            return {
                ...state,
                ...currentScheduledTask
            };
        case getScheduledTaskHistory.successType:
            return { 
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id] as ScheduledTaskDetails,
                    events: action.payload.data
                }};
        case deleteTaskTemplate.successType:
            return { ...state, [action.payload.id]: undefined };
        default:
            return state;
    }
}

function templatesLoadingById(
    state: ItemsById<boolean> = initialState.templatesLoadingById,
    action: ScheduledTaskActions | TaskListComponentActions,
): ItemsById<boolean> {
    switch (action.type) {
        case getTaskTemplate.initType:
            return { ...state, [action.payload]: true };
        case getTaskTemplate.successType:
        case getTaskTemplate.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function storesByScheduledTaskFetching(
    state: boolean = initialState.storesByScheduledTaskFetching,
    action: ScheduledTaskActions | TaskListComponentActions,
): boolean {
    switch (action.type) {
        case getStoreIdsByScheduledTask.initType:
            return true;
        case getStoreIdsByScheduledTask.successType:
        case getStoreIdsByScheduledTask.errorType:
            return false;
        default:
            return state;
    }
}

function templatesUpdatingById(
    state: ItemsById<boolean> = initialState.templatesUpdatingById,
    action: ScheduledTaskActions | TaskListComponentActions,
): ItemsById<boolean> {
    switch (action.type) {
        case updateTaskTemplate.initType:
            return { ...state, [action.payload.id]: true };
        case updateTaskTemplate.successType:
        case updateTaskTemplate.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

export const scheduledTaskReducer = combineReducers({
    scheduledTasksFetching,
    scheduledTasksNextPageToken,
    scheduledTasksById,
    scheduledTasksOrder,
    templatesById,
    templatesLoadingById,
    templatesUpdatingById,
    storesByScheduledTaskFetching
});
