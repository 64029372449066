import { createSingleAction } from 'utils/store/actionUtils';
import { Tokens } from 'api/auth/authModel';

export const AUTH_SUCCESS = 'auth/AUTH_SUCCESS';
export const tokensAuthSuccess = createSingleAction<Tokens, typeof AUTH_SUCCESS>(AUTH_SUCCESS);

export const TOKENS_REFRESH_SUCCESS = 'auth/TOKENS_REFRESH_SUCCESS';
export const tokensRefreshSuccess = createSingleAction<Tokens, typeof TOKENS_REFRESH_SUCCESS>(TOKENS_REFRESH_SUCCESS);

export const AUTH_LOGOUT = 'auth/LOGOUT';
export const authLogout = createSingleAction<void, typeof AUTH_LOGOUT>(AUTH_LOGOUT);

export const AUTH_LOGIN_AS = 'auth/LOGIN_AS';
export const authLoginAs = createSingleAction<string, typeof AUTH_LOGIN_AS>(AUTH_LOGIN_AS);

export const ACTIVE_TOKEN = 'auth/ACTIVE_TOKEN';
export const activeToken = createSingleAction<string, typeof ACTIVE_TOKEN>(ACTIVE_TOKEN);

export type AuthActions =
    | ReturnType<typeof tokensAuthSuccess>
    | ReturnType<typeof tokensRefreshSuccess>
    | ReturnType<typeof authLogout>
    | ReturnType<typeof authLoginAs>
    | ReturnType<typeof activeToken>;
