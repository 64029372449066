import { useEffect, useRef, useCallback } from 'react';

type UseEscapeHook = (typeKeyboard: Array<keyof typeof KeyboardEnum>, onEscape: (key?: KeyboardEnum, event?: KeyboardEvent) => void) => void;
export enum KeyboardEnum {
    Escape = 'Escape',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight'
}

export const useListenerLightboxKeyBoard: UseEscapeHook = (typeKeyboard, onEscape) => {
    const onEscapeRef = useRef(onEscape);

    useEffect(() => {
        onEscapeRef.current = onEscape;
    }, [onEscape]);

    const handleEsc = useCallback((event: KeyboardEvent) => {
        switch (event.key) {
            case KeyboardEnum.ArrowLeft:
            case KeyboardEnum.ArrowRight:
                if(typeKeyboard.includes(event.key) && getPDFRenderOnDOM()) onEscapeRef.current(event.key as KeyboardEnum, event);
                break;
            case KeyboardEnum.Escape:
                if(typeKeyboard.includes(event.key)) onEscapeRef.current(event.key as KeyboardEnum);
                break;
            default:
                break;
        }
    }, [typeKeyboard]);

    useEffect(() => {
        window.addEventListener('keydown', handleEsc, { capture: true });
        return () => window.removeEventListener('keydown', handleEsc, { capture: true });
    }, [handleEsc]);
};

const getPDFRenderOnDOM = () => !!document.getElementsByClassName("image-gallery-slide  center")[0]?.getElementsByClassName('react-pdf__Document')?.length;
