import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import { BaseInput } from 'components/basicInputs/BaseInput';
import { IInputProps, ITextInputClasses } from 'components/basicInputs/model';

interface ICheckboxInputProps extends IInputProps {
    classes?: ITextInputClasses;
}

export function CheckboxInput({
    name,
    id = name,
    label,
    classes = {},
}: ICheckboxInputProps) {
    const [field] = useField<boolean | undefined>(name);
    const { value = false, onChange } = field;

    return (
        <BaseInput
            id={id}
            label={label}
            separateLabel={false}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={onChange}
                        name={name}
                        color="primary"
                    />
                }
                label={label}
            />
        </BaseInput>
    );
}
