import { Box, Collapse } from '@material-ui/core';
import { TaskReviewModel } from 'api/task/taskReview/taskReviewModel';
import React, { useState, useEffect } from 'react';
import { TaskReviewListItem } from './TaskReviewListItem';
import { Scrollbar } from 'components/containers/Scrollbar';

export type TaskReviewListProps = {
    tasks: TaskReviewModel[];
};

function renderTaskReview(task: TaskReviewModel) {
    return (
        <Collapse key={task.id} in timeout="auto" unmountOnExit>
            <TaskReviewListItem key={task.id} task={task} />
        </Collapse>
    );
}

export function TaskReviewList({ tasks }: TaskReviewListProps) {
    
    const [tasksRender, setTasksRender] = useState<TaskReviewModel[]>([]);

    useEffect(() => {
        if(tasks.length > 0 && tasksRender.length <= 0){
            const aux = tasks.slice(0, 5);
            setTasksRender(aux);
        }
        if(!(tasksRender.every(taskRender=>( tasks.find(task=> task.id === taskRender.id))))){
            const filterTasks = tasksRender.filter(rende=>( tasks.find(task=> task.id === rende.id)));
            setTasksRender(filterTasks);
        }   
    }, [tasks, tasksRender])

    const onScroll = (scrollTop: number, scrollHeight: number, clientHeight: number) => {
        if(scrollHeight - 2 < scrollTop + clientHeight 
            && scrollTop + clientHeight < scrollHeight + 2
            && tasksRender.length < tasks.length) {
            const aux = tasks.slice(tasksRender.length, tasksRender.length + 5);
            setTasksRender(prev =>([...prev, ...aux]));
        }
    };

    return (
        <Box style={{height: 'calc(100vh - 120px)', marginLeft: "-12px", marginRight: "-12px"}}>
            <Scrollbar
                onScrollY={(container) =>{
                    onScroll(container.scrollTop, container.scrollHeight, container.clientHeight)
                }}>
                    {tasksRender.map(task => renderTaskReview(task))}
            </Scrollbar>
        </Box>
    );
}
