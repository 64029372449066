import { Channel } from 'stream-chat';

export function countChannelUnreads(channel: Channel): number {
    const streamChatUnreads = channel.countUnread();

    if (!streamChatUnreads) {
        return 0;
    }

    const currentUserId = channel?.getClient()?.user?.id || '';
    const { messages, read } = channel.state;
    const readInfo = read[currentUserId];

    if (!readInfo) {
        return streamChatUnreads;
    }

    let count = 0;
    for (const message of messages) {
        if (currentUserId === message.user?.id
            || message.shadowed
            || message.silent
            || message.type === 'deleted') {
            continue;
        }
        if (!readInfo.last_read) {
            count++;
            continue;
        }
        if (message.created_at > readInfo.last_read) {
            count++;
        }
    }
    return count;
}
