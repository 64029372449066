import { IEntityError } from 'model/entity';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { TaskPrototype, TaskPrototypeUpdate } from './prototypeModel';

const TASK_PROTOTYPE_GET = 'tasks/prototype/GET';
export const getTaskPrototype = createApiActionsByName<
    {prototypeId: string, isClone?: boolean }, TaskPrototype, IEntityError, typeof TASK_PROTOTYPE_GET
>(TASK_PROTOTYPE_GET);

const TASK_PROTOTYPE_UPDATE = 'tasks/prototype/UPDATE';
export const updateTaskPrototype = createApiActionsByName<
    TaskPrototypeUpdate, TaskPrototype, IEntityError, typeof TASK_PROTOTYPE_UPDATE
>(
    TASK_PROTOTYPE_UPDATE,
);

export type TaskPrototypeActions =
    | ActionsReturnTypes<typeof getTaskPrototype>
    | ActionsReturnTypes<typeof updateTaskPrototype>;
