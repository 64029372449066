import { makeStyles, Theme } from '@material-ui/core/styles';
import { BlueColor, UtilColors, ZIndex } from 'utils/styles/constants';

export const useInputsStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        lineHeight: 1.25,
        color: UtilColors.TextPrimary,
        marginBottom: theme.spacing(0.25),
    },
    clickableIcon: {
        zIndex: ZIndex.InputIcon,
        cursor: 'pointer',
    },
    select: {
        marginTop: theme.spacing(0.5),
        border: `2px solid ${BlueColor.Blue30}`,
        borderRadius: theme.spacing(1),
    },
    selectItem: {
        '& + *': {
            borderTop: `1px solid ${BlueColor.Blue30}`,
        },
    },
    maxLengthDisplay: {
        color: BlueColor.Blue50,
        textAlign: 'right',
    },
}));
