import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { taskGalleryModalClose } from 'store/modules/task/taskGallery/taskGalleryActions';
import { selectParentTaskGallery, selectTaskGalleryIsModalOpen } from 'store/modules/task/taskGallery/taskGallerySelectors';
import { useActions } from "utils/store/useActions";
import { TaskSlideout } from '../common/TaskSlideout';
import { SimpleTaskListHeader } from '../taskList/components/SimpleTaskListHeader';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { selectParentTaskById } from 'store/modules/task/parentTask/parentTaskSelectors';
import { CopyButton } from 'components/button/copyButton/CopyButton';
import LinkIcon from '@material-ui/icons/Link';
import { Box, Link, Tooltip } from '@material-ui/core';
import { Routes } from 'components/router/model';
import { generatePath, Link as RouterLink, useHistory } from 'react-router-dom';
import { TaskIcon } from '../singleTask/components/TaskIcon';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { ApprovableHeaderIcon } from '../taskList/components/ApprovableIcon';
import { TagBoxColorTooltip } from '../common/taglist/TagBoxColorTooltip';
import { ExpireHeaderIcon } from '../taskList/components/ExpireIcon';
import { TaskGalleryList } from './TaskGalleryList';
import { useTaskGalleryStyles } from './useTaskGalleryStyles';
import clsx from 'clsx';
import { TaskStoreSelect } from './TaskStoreSelect';
const baseUrlOrigin = window.location.origin;

export const TaskGallerySlideout = () => {
    const classes = useTaskGalleryStyles();
    const isOpen = useSelector(selectTaskGalleryIsModalOpen);
    const actions = useActions({ close: taskGalleryModalClose });
    const taskId = useSelector(selectParentTaskGallery);
    const parentTask = useSelector(selectParentTaskById)[taskId];
    const history = useHistory();
    
    const onClose = useCallback(() => {
        actions.current.close();
        history.replace(generatePath(Routes.TaskList));
    }, [actions, history]);

    const title = useMemo(() => (
        !taskId || !parentTask ? `${t('Loading')}: ...` : (
            <Tooltip title={<Trans>Open task location view</Trans>}>
                <Link
                    className={clsx(classes.title, classes.flex)}
                    component={RouterLink}
                    to={{
                        pathname: generatePath(Routes.TaskList, { uuid: taskId ?? '' }),
                        search: '?taskGallery=true',
                    }}
                    onClick={()=>{
                        actions.current.close();
                    }}
                >
                    <TaskIcon status={DisplayedTaskStatus.Submitted} />
                    {parentTask?.title}
                </Link>
            </Tooltip>
    )), [taskId, classes, parentTask, actions]);

    const detailHeader = useMemo(() => (
        <Box className={classes.containerDetailHeader}>
            <Box display="flex" justifyContent="flex-start" flexDirection="row">
                {title}
            </Box>
            <Box display="flex" justifyContent="flex-start" flexDirection="row">
                <ApprovableHeaderIcon />
                {parentTask?.isExpirable  && <ExpireHeaderIcon />}
                {parentTask?.tag && (<Box display="flex" alignItems="center" ml={.5}><TagBoxColorTooltip tag={parentTask?.tag} /></Box>)}
                <CopyButton 
                    textToCopy={`${baseUrlOrigin}/tasks/list?taskIdGallery=${taskId}`}
                    messageCopied={<Trans>Copied the url of the task</Trans>}
                    iconCopy={<LinkIcon />}
                />
            </Box>
            <Box ml={2} className={classes.taskStoreSelect}>
                <TaskStoreSelect />
            </Box>
        </Box>
    ), [taskId, parentTask, title, classes]);

    return (
        <TaskSlideout
            open={isOpen}
            onClose={onClose}
            header={(
                <SimpleTaskListHeader
                    header={`${t('Task Gallery')}`}
                    regionName={''}
                    customComponent={detailHeader}
                    styles={classes.containerHeader}
                />
            )}
            minWidthSideout={'80vw'}
        >
            <TaskGalleryList />
        </TaskSlideout>
    );
}
