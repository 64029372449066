import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux';
import { selectStoreTaskConversationById } from 'store/modules/task/taskConversation/taskConversationSelectors';
import { Comment } from './taskConversationForm/Comment';

type ConversationsProps= {
  storeTaskId: string;
}
export const Conversations: React.FC<ConversationsProps> = ({ storeTaskId }) => {
  const storeTaskConversations = useSelector(selectStoreTaskConversationById)[storeTaskId];

  if(!storeTaskConversations) return <></>;

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      {
        storeTaskConversations?.comments.map((conversation) => (<Comment key={conversation.id} conversation={conversation} storeTaskId={storeTaskConversations.storeTaskId}/>))
      }
    </Box>
  )
}
