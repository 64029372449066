import { useState, useRef, useCallback, ChangeEvent, useLayoutEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, IconButton, TextField } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';
import { TagBoxColor } from './TagBoxColor';
import { generateColor } from 'components/basicInputs/utils';
import { KeyNames } from 'utils/globals';
import { useActions } from 'utils/store/useActions';
import { requestAddTaskTag } from 'api/task/tag/tagActions';
import { TaskTagNewType } from 'api/task/tag/taskTagModel';
import { useSelector } from 'react-redux';
import { selectTasksTagAdding } from 'store/modules/task/taskTag/taskTagSelectors';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        padding: theme.spacing(1, 1) 
    },
    inputColor: {
        visibility: 'hidden'
    },
    buttonAction: {
        padding: theme.spacing(.5)
    },
    btnNewTag: {
        paddingLeft: 0,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    }
}));

const MIN_TAG_NAME_LENGTH = 3;
type NewTagItemProp = {
    onNewTag: () => void;
}

export const NewTagItem = ({onNewTag}: NewTagItemProp) => {
    const actions = useActions({ addTaskTag: requestAddTaskTag.init });
    const tagAdding = useSelector(selectTasksTagAdding);
    const [showEditTagName, setEditTagName] = useState<boolean>(false)
    const [newTag, setNewTag] = useState<TaskTagNewType>({color: generateColor(), name: ''})
    const inputColorRef = useRef<HTMLInputElement | null>(null);
    const classes = useStyles();

    useLayoutEffect(()=>{
        if(!tagAdding) {
            setEditTagName(false);
            setNewTag({color: generateColor(), name: ''});
            onNewTag();
        }
    },[tagAdding, onNewTag])

    const handlerColor = () => {
        if(showEditTagName){
            inputColorRef.current?.click();
            inputColorRef.current?.focus();
        }
    }

    const handlerAddTag = useCallback(() => {
        actions.current.addTaskTag(newTag);
    },[actions, newTag])

    const handleColorChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setNewTag({...newTag, color: event.target.value})
    },[setNewTag, newTag])

    const handleInputChange = useCallback((valueName: string) => {
        setNewTag({...newTag, name: valueName})
    },[setNewTag, newTag])

    const handleKeyDown = useCallback(event => {
        if (event.key === KeyNames.Enter) {
            event.stopPropagation();
            event.preventDefault();
            handlerAddTag();
        }
    }, [handlerAddTag]);
    
    const showEditMode = useCallback(() => {
        handleInputChange('')
        setEditTagName(true);
    },[handleInputChange, setEditTagName])

    return (
        <Box className={classes.item} display="flex" alignItems="center">
            <TagBoxColor bgcolor={newTag.color} onClick={handlerColor}>
                <input ref={inputColorRef} className={classes.inputColor} id="input-color" type="color"onChange={ handleColorChange} disabled={tagAdding}/> 
            </TagBoxColor>
            { !showEditTagName ? 
                <Button color="primary" onClick={showEditMode} className={classes.btnNewTag}><Trans>Create New</Trans></Button>
                :<>
                    <TextField
                        id="new-tag-name"
                        name="new tag name"
                        onChange={ (event) => handleInputChange(event.target.value)}
                        onKeyDown={handleKeyDown}
                        value={newTag.name}
                        variant="outlined"
                        placeholder={`${t('Enter tag name')}`}
                        aria-describedby="new-tag-name-description"
                        inputProps={{ maxLength: 15 }}
                        disabled={tagAdding}
                    />
                    <Box display="flex" flexDirection="column">
                        {tagAdding ?
                            <CircularProgress size="25px" className={classes.buttonAction}/>
                        :<>
                            <IconButton aria-label="Save" className={classes.buttonAction} onClick={handlerAddTag} disabled={!!!newTag?.name || newTag?.name?.length < MIN_TAG_NAME_LENGTH}>
                                <Done fontSize="small" />
                            </IconButton>
                            <IconButton aria-label="Close" className={classes.buttonAction} onClick={()=>setEditTagName(false)} >
                                <Close fontSize="small" />
                            </IconButton>
                        </>}
                    </Box>
                </>}
        </Box>
    );
}
