import React, { useCallback } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { TaskHeader } from '../components/TaskHeader';
import { TaskSlideout } from '../../common/TaskSlideout';
import { TaskSidebarContent } from 'modules/task/singleTask/components/TaskSidebarContent';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { useSelector } from 'react-redux';
import {
    selectStoreTaskDetailsById,
    selectStoreTaskModalActiveId,
    selectStoreTasksById,
} from 'store/modules/task/storeTask/storeTaskSelectors';
import { useActions } from 'utils/store/useActions';
import { selectChildrenTasks } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { Breakpoints } from 'utils/styles/constants';
import { TaskStatus } from 'api/task/taskModel';
import { Trans } from 'react-i18next';

export function StoreTaskView() {
    const nonMobile = useMediaQuery((theme: Theme) => theme.breakpoints.up(Breakpoints.MobileLarge));
    const actions = useActions({ setModalTaskId: storeTaskSetActiveId });
    const modalTaskId = useSelector(selectStoreTaskModalActiveId);
    const shortTask = useSelector(selectStoreTasksById)[modalTaskId?.taskId || ''];
    const fullTask = useSelector(selectStoreTaskDetailsById)[modalTaskId?.taskId || ''];
    const childStoreTask = useSelector(selectChildrenTasks)[modalTaskId?.taskId || ''];

    const onModalClose = useCallback(() => {
        actions.current.setModalTaskId(null);
    }, [actions]);

    const task = fullTask || shortTask || childStoreTask;

    const disabledTask = !!fullTask?.isExpired && 
        fullTask?.status !== TaskStatus.Submitted && fullTask?.status !== TaskStatus.Declined

    return (
        <TaskSlideout
            open={!!modalTaskId}
            onClose={onModalClose}
            header={
                <TaskHeader
                    task={task}
                    showProgress={nonMobile}
                    isAutomationTask={modalTaskId?.isAutomation}
                />
            }
            hasDisabledContainer={disabledTask}
            textDisabledContainer={<Trans>Sorry! This task has expired</Trans>}
        >
            <TaskSidebarContent task={task} isAutomationTask={modalTaskId?.isAutomation}/>
        </TaskSlideout>
    );
}
