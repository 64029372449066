import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { UserRoleDTO } from 'api/core/model';

const REQUEST_USERS_ROLES = 'user/ROLES';

export const requestUserRoles = createApiActionsByName<void, UserRoleDTO[], any, typeof REQUEST_USERS_ROLES>(
    REQUEST_USERS_ROLES
);

const REQUEST_COMPANY_ROLES = 'company/ROLES';

export const requestCompanyRoles = createApiActionsByName<string, UserRoleDTO[], any, typeof REQUEST_COMPANY_ROLES>(
    REQUEST_COMPANY_ROLES
);

export type CoreActions =
    | ActionsReturnTypes<typeof requestUserRoles>
    | ActionsReturnTypes<typeof requestCompanyRoles>;
