import React from 'react'
import { AutomationTasksBaseGrid, IAutomationTasksGridBaseProps } from './AutomationTasksBaseGrid';
import { useSelector } from 'react-redux';
import { selectAutomationTasksFetching } from 'store/modules/task/automationTask/automationTaskSelectors';

export const AutomationTasksGrid = (props: IAutomationTasksGridBaseProps) => {
  const areAutomationTasksFetching = useSelector(selectAutomationTasksFetching);
  
  return (
    <AutomationTasksBaseGrid
        isFetching={areAutomationTasksFetching}
        {...props}
    />
  )
}
