import { Theme, darken, lighten, makeStyles } from "@material-ui/core";
import { Breakpoints, GrayColor, UtilColors } from "utils/styles/constants";

export const useLaunchStyles = makeStyles((theme: Theme) => ({
  containerLaunchList: {
    overflow: 'auto',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      paddingRight: 0
    }
  },
  containerLaunch: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  card: {
    height: 112,
    width: 112,
    marginLeft: theme.spacing(.25),
    marginRight: theme.spacing(.25),
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  descriptionContainer: {
    marginTop: 6,
    width: '100%',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  description: {
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: 400,
    padding: '2px 8px'
  },
  formActions: {
    padding: theme.spacing(0, 2, 3),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fixedButtons: {
    boxSizing: 'border-box',
    width: theme.spacing(13.5),
    height: theme.spacing(5),
    boxShadow: `0px 4px 8px rgba(58, 58, 68, 0.16)`,

    '& > span': {
      fontSize: '14px',
      lineHeight: '21px',
      textTransform: 'uppercase',
    },
  },
  textInput: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  titleSection: {
    fontWeight: 'bold'
  },
  deleteContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    top: 0,
    backgroundColor: 'rgb(5 5 5 / 50%)'
  },
  launchAddBtn: {
    width: 80,
    height: 80,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '25%',
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.getContrastText(theme.palette.primary.dark)
    }
  },
  iconLaunchAdd: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  modalDeleteTitleWrapper:{
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  
    '& strong': {
      fontWeight: 700,
    },
  },
  modalDeleteDescription:{
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
  },
  modalDeleteDeleteButton: {
    backgroundColor: UtilColors.Red3,
    padding: '6px 16px',
    marginLeft: '16px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: darken(UtilColors.Red3, 0.05),
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: UtilColors.White,
    },
  },
  modalDeleteCancelButton:{
    backgroundColor: GrayColor.GrayE0,
    padding: '6px 16px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: darken(GrayColor.GrayE0, 0.05),
    },
    '& span': {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: lighten(UtilColors.Black, 0.22),
    },
  }
}))