import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Close as CloseIcon } from '@material-ui/icons';
import { ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useConnect } from 'modules/connect/context/ConnectContext';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { ConfirmationDialog } from 'components/dialogs/ConfirmationDialog';
import { useChatContext } from 'stream-chat-react';
import { requestRemoveChannelRoles } from '../../../../../../../../api/getStream/getStreamActions';
import { Trans } from 'react-i18next';

export interface IChannelRoleMemberActionsProps {
    roleId: string;
    name: string;
}

export function ChannelRoleMemberActions(props: IChannelRoleMemberActionsProps) {
    const canRemoveUser = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));

    return (
        <ListItemSecondaryAction>
            {canRemoveUser && <RemoveRoleFromChannelButton {...props} />}
        </ListItemSecondaryAction>
    );
}

function RemoveRoleFromChannelButton({ roleId, name }: IChannelRoleMemberActionsProps) {
    const connectContext = useConnect();
    const { channel } = useChatContext();
    const { conversationsService, clientService } = connectContext?.connectService || {};

    const dispatch = useDispatch();
    const handleRemoveRole = useCallback(() => {
        dispatch(requestRemoveChannelRoles.init({
            channelId: channel?.id ?? '',
            roles: [roleId],
        }));
    }, [dispatch, roleId, channel]);
    const { isOpened, onOpen, onClose } = useOpenedState();
    const handleAgree = useCallback(() => {
        handleRemoveRole();
        onClose();
    }, [handleRemoveRole, onClose]);
    const text = 
        (<Trans i18nKey="textToRemoveRole" name={name}>
            The Role {{name}} and all Role members will be removed from the channel.
        </Trans>);

    if (!conversationsService || !clientService) {
        return null;
    }

    return (
        <>
            <IconButton onClick={onOpen}>
                <CloseIcon />
            </IconButton>

            <ConfirmationDialog
                open={isOpened}
                text={text}
                onDiscard={onClose}
                onAgree={handleAgree}
                agreeButtonText={<Trans>Remove</Trans>}
                discardButtonText={<Trans>Cancel</Trans>}
            />
        </>
    );
}
