import React, { useMemo } from 'react';
import { IItemComponent } from 'components/basicInputs/selectInput/model';
import { useSelector } from 'react-redux';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { StyledAvatar, AVATAR_SIZE } from 'components/userInfo/styles';
import { BlueColor } from 'utils/styles/constants';
import { WatcherType } from 'api/task/watcher/watcherModel';

export function WatcherImage({ item: watcher }: IItemComponent<WatcherType>) {
    const fileStorageConfig = useSelector(getFilesConfig);
    const avatarUrl = useMemo(() => {
        return fileStorageConfig && watcher?.avatarId
            ? `${fileStorageConfig.avatarsBaseUrl}/${watcher.avatarId}`
            : '';
    }, [fileStorageConfig, watcher])

    return (
        <StyledAvatar
            src={avatarUrl}
            alt="avatar"
            style={{
                backgroundColor: 'white',
                color:'darkblue',
            }}
        >
            <div style={{
                backgroundColor: BlueColor.Dark,
                color:'white',
                width:AVATAR_SIZE,
                textAlign: 'center',
                lineHeight:AVATAR_SIZE,
                fontSize: '1.25rem'
            }}>
                {watcher.firstName.substring(0, 1).toUpperCase()}{watcher.lastName.substring(0, 1).toUpperCase()}
            </div>
        </StyledAvatar>
    );
}
