import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponse,
    AxiosResponseTransformer,
} from 'axios';
import {
    isTest,
    baseCoreUrl,
    baseFilesUrl,
    baseTaskUrl,
    baseNotificationsUrl,
    coreFunctionKey,
    taskFunctionKey,
    filesFunctionKey,
    notificationsFunctionKey,
    legacyAppUrl,
    baseStreamUrl,
    streamFunctionKey,
    keycloakAppUrl
} from 'utils/globals';
import { logger } from 'utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { camelCaseResponseTransformer } from 'api/helpers';
import { authApi } from './auth/authApi';
import { retry } from './async-helpers';
import { tryGetLivingLanguage } from 'modules/auth/storage';

const axiosCoreInstance = axios.create({
    baseURL: baseCoreUrl,
});

const axiosTaskInstance = axios.create({
    baseURL: baseTaskUrl,
});

const axiosFilesInstance = axios.create({
    baseURL: baseFilesUrl,
});

const axiosGetStreamInstance = axios.create({
    baseURL: baseStreamUrl,
});

const axiosNotificationsInstance = axios.create({
    baseURL: baseNotificationsUrl,
});

// do not init legacy instance
const axiosLegacyInstance = axios.create({
    baseURL: legacyAppUrl,
});

const axiosKeycloakInstance = axios.create({
    baseURL: keycloakAppUrl,
});

if (!isTest) {
    initAxiosInstance(axiosCoreInstance, coreFunctionKey);
    initAxiosInstance(axiosTaskInstance, taskFunctionKey);
    initAxiosInstance(axiosFilesInstance, filesFunctionKey);
    initAxiosInstance(axiosGetStreamInstance, streamFunctionKey);
    initAxiosInstance(axiosNotificationsInstance, notificationsFunctionKey);
}

export const requestIdHeaderName = 'X-Correlation-Id';
const languageHeaderName = 'Accept-Language'

const noCacheHeaders: AxiosRequestHeaders = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};

function initAxiosInstance(instance: AxiosInstance, apiCode: string) {
    let retryCount = 0;

    instance.interceptors.request.use(
        config => {
            return Promise.all([authApi.getAccessToken(), tryGetLivingLanguage()])
                .then(([ token, language ]) => {
                    config.headers = config.headers ?? {};
                    config.headers['x-functions-key'] = apiCode;
                    config.headers[requestIdHeaderName] = uuidv4();
                    config.headers[languageHeaderName] = language;

                    if (token) {
                        config.headers['Authorization'] = `Bearer ${token}`;
                    }

                    return config;
                })
                .catch(() => {
                    logger.reportMessage('Token update is failed');
                    return config;
                });
        },
    );

    instance.interceptors.response.use(
        undefined,
        (error: AxiosError) => {
            const statusCode = error.response?.status;
            if (statusCode !== 401) {
                if (statusCode && statusCode >= 500) {
                    logger.reportError(error);
                }

                return Promise.reject(error);
            }

            if (retryCount >= 2) {
                return Promise.reject(error);
            }

            retryCount++;
            return retry(() => {
                return instance.request(error.config)
            }, 5000);
        },
    );
}

export type ApiOptions = Partial<{
    convertToCamelCase: boolean;
}>;

const apiOptionsToConfig = (options?: ApiOptions): AxiosRequestConfig => {
    const { transformResponse: defaults } = axios.defaults;
    const transformResponse: AxiosResponseTransformer[] = [];

    if (defaults instanceof Array) {
        transformResponse.push(...defaults);
    } else if (defaults) {
        transformResponse.push(defaults);
    }

    // transform when not explicitly set to false
    if (options?.convertToCamelCase !== false) {
        transformResponse.push(camelCaseResponseTransformer);
    }

    return { transformResponse };
}

export const getBaseApi = (axiosInstance: AxiosInstance) => ({
    get: <Response>(
        url: string,
        params?: any,
        options?: ApiOptions,
    ) => axiosInstance.get<Response>(url, { params, ...apiOptionsToConfig(options) }),

    getBlob: async (
        url: string,
        options?: ApiOptions,
    ): Promise<AxiosResponse & { blob: Blob }> => {
        const response = await axiosInstance.get(url, {
            headers: noCacheHeaders,
            responseType: 'blob',
            ...apiOptionsToConfig(options),
        });

        return {
            ...response,
            blob: response.request?.response as Blob,
        };
    },

    post: <Request, Response>(
        url: string,
        body: Request,
        params?: any,
        options?: ApiOptions,
    ) => axiosInstance.post<Response>(url, body, { params, ...apiOptionsToConfig(options) }),

    put: <Request, Response>(
        url: string,
        body: Request,
        params?: any,
        options?: ApiOptions,
    ) => axiosInstance.put<Response>(url, body, { params: { ...params }, ...apiOptionsToConfig(options) }),

    patch: <Request, Response>(
        url: string,
        body: Request,
        params?: any,
        options?: ApiOptions,
    ) => axiosInstance.patch<Response>(url, body, { params, ...apiOptionsToConfig(options) }),

    delete: <Response>(
        url: string,
        id: string,
        options?: ApiOptions,
    ) => axiosInstance.delete<Response>(`/${url}/${id}`, apiOptionsToConfig(options)),

    get instance() {
        return axiosInstance;
    },
});

export const baseCoreApi = getBaseApi(axiosCoreInstance);
export const baseTaskApi = getBaseApi(axiosTaskInstance);
export const baseFilesApi = getBaseApi(axiosFilesInstance);
export const baseLegacyApi = getBaseApi(axiosLegacyInstance);
export const baseStreamApi = getBaseApi(axiosGetStreamInstance);
export const baseNotificationsApi = getBaseApi(axiosNotificationsInstance);
export const baseKeycloakApi = getBaseApi(axiosKeycloakInstance);
