import React from 'react';
import ReactDOM from 'react-dom';
import App from 'modules/root/App';
import 'modules/root/index.css';
import 'react-mde/lib/styles/css/react-mde-all.css';
import ReactGA from 'react-ga';
import { googleTagKey, isProduction } from 'utils/globals';
import { initBrowserLoggers } from 'utils/logger/browserInit';
import './i18n';

if (isProduction) {
    ReactGA.initialize(googleTagKey);
}

initBrowserLoggers();

ReactDOM.render(
    <App/>,
    document.getElementById('root'),
);
