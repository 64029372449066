import React, { useLayoutEffect, useMemo } from 'react';
import { Avatar, NotificationCell } from '@knocklabs/react-notification-feed';
import { knockUserAvatar } from 'api/knock/knockActions';
import { useActions } from 'utils/store/useActions';
import { selectUserById } from 'store/modules/user/userSelectors';
import { useSelector } from 'react-redux';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { FeedItem, GenericData } from '@knocklabs/client';

type NotificationCustomCellProps = {
    item: FeedItem<GenericData> & { actors: any};
    onItemClick?: ((item: FeedItem<GenericData>) => void);
    archiveButton?: React.ReactNode
}
export const NotificationCustomCell = ({ item, ...props }: NotificationCustomCellProps) => {
    const actions = useActions({ knockUserAvatar: knockUserAvatar });
    const user = useSelector(selectUserById(item?.actors[0]?.id));
    const fileStorageConfig = useSelector(getFilesConfig);
    const avatar = useMemo(() => (
        fileStorageConfig && user?.avatarId
            ? { name: `${user.firstName} ${user.lastName}`, url: `${fileStorageConfig.avatarsBaseUrl}/${user?.avatarId}` }
            : { name: item?.actors.length ? item.actors[0]?.name: '', url: ''}
    ), [fileStorageConfig, user, item]);
    const showAvatar = useMemo(() => (
        avatar.url || avatar.name 
            ? <Avatar name={avatar?.name } src={avatar.url}/>
            : <></>
    ), [avatar]);

    useLayoutEffect(()=>{
        if(item?.actors.length){
            actions.current.knockUserAvatar(item?.actors[0].id)
        }
    },[item, actions])

    return(
        <NotificationCell
            {...props}
            item={item}
            avatar={showAvatar}
        />)
};
