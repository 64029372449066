import React, { useCallback } from 'react';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { ListItem, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { useDownloadFile } from 'store/modules/files/filesHooks';
import { AttachmentItemAvatar, AttachmentItemMeta, DownloadAction } from './attachmentItemElements';
import { resolveType } from 'utils/helpers/fileHelpers';
import { removeUploadedFile } from 'store/modules/files/filesActions';
import { useActions } from 'utils/store/useActions';

export type AttachmentItemProps = {
    name: string;
    fileId: string;
    size?: number;
}

export function AttachmentItem({ fileId, name, size }: AttachmentItemProps) {
    const actions = useActions({ removeUploadedFile });
    const handleDownloadClick = useDownloadFile(fileId, name);
    const handleRemoveClick = useCallback(() => {
        actions.current.removeUploadedFile(fileId);
    }, [actions, fileId]);

    return (
        <ListItem>
            <AttachmentItemAvatar />
            <AttachmentItemMeta
                title={name}
                size={size}
                type={resolveType(name)}
            />

            <ListItemSecondaryAction>
                {fileId && <DownloadAction handleDownloadClick={handleDownloadClick} />}

                <IconButton color="primary" onClick={handleRemoveClick}>
                    <ClearOutlinedIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
