import React from 'react';
import { Channel } from 'stream-chat';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckedIcon from '@material-ui/icons/CheckBox';
import { getRawFrozen } from '../../../../../helpers';
import { ChannelDetailsSection } from './ChannelDetailsSection';
import { Trans } from 'react-i18next';

export type ChannelFrozenStateProps = {
    channel: Channel
}

export function ChannelFrozenState({ channel }: ChannelFrozenStateProps) {
    const isFrozen = getRawFrozen(channel) ?? false;

    return (
        <ChannelDetailsSection
            title={<Trans>Freeze Channel</Trans>}
            Icon={isFrozen ? CheckedIcon : UncheckedIcon}
            expandable={false}
        />
    );
}
