import { useMemo } from 'react';
import { useLocation } from 'react-router';

const parseQueryString = (qs: string): Record<string, string> => {
    if (!qs?.length)
        return {};

    /**
     * smart way to transform query keys and values format to json
     * taken from https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object
     */
    const queryJSON = '{"' + qs.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}';

    return JSON.parse(queryJSON, (key, value) => {
        return key === '' ? value : decodeURIComponent(value);
    });
};

export function useQueryParams<T>(): Partial<{ [key in keyof T]: string }> {
    const location = useLocation();

    return useMemo(() => {
        return parseQueryString(location.search) as any;
    }, [location.search]);
}
