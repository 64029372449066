import { makeStyles, Theme } from '@material-ui/core/styles';
import { Breakpoints, UtilColors, ZIndex } from 'utils/styles/constants';

export const useLeftMenuStyles = makeStyles((theme: Theme) => ({
    root: {
        boxSizing: 'border-box',
        height: '100%',
        maxHeight: `100vh`,
        width: theme.spacing(35.25),
        backgroundColor: theme.palette.primary.dark,
        paddingBottom: theme.spacing(5),
        float: 'left',
        overflowY: 'auto',
        transition: 'width 0.1s, transform 0.1s',
        position: 'relative',

        [theme.breakpoints.between(Breakpoints.MobileLarge, Breakpoints.Tablet)]: {
            width: theme.spacing(9),
            overflowX: 'hidden',

            '& > $nav': {
                marginRight: 0,
            },
        },
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            width: '292px',
            position: 'absolute',
            maxHeight: 'none',
            borderRadius: '0 20px 20px 0',
            zIndex: ZIndex.Sidebar,
            top: 0, bottom: 0, left: '-292px',
            transform: 'translateX(0)',

            '&.opened': {
                transform: `translateX(292px)`,
            },
        },

        '&:after': {
            visibility: 'hidden',
            display: 'block',
            fontSize: 0,
            content: ' ',
            clear: 'both',
            height: 0,
        },

        '&.collapseClosed':{
            width: theme.spacing(9),
            overflowX: 'hidden',
    
            '& > $nav': {
                marginRight: 0,
            },
        },
    },
    closeMenuButton: {
        '& svg': {
            color: theme.palette.getContrastText(theme.palette.primary.dark),
            width: '36px',
            height: '36px',
        },
    },
    menuBackdrop: {
        zIndex: ZIndex.Backdrop,
    },
    nav: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: theme.spacing(2),
        padding: 0,
        height: '100%',
        
        [theme.breakpoints.up(Breakpoints.Tablet)]: {
            height: 'calc(100% - 72px)'
        },
    },
    navItem: {
        fontSize: 16,
        padding: theme.spacing(2, 3),
        color: theme.palette.getContrastText(theme.palette.primary.dark),

        '&.active': {
            color: theme.palette.getContrastText(theme.palette.secondary.light),
            backgroundColor: theme.palette.secondary.light,
            borderRadius: theme.spacing(0, 1.25, 1.25, 0),

            '& svg': {
                color: theme.palette.getContrastText(theme.palette.secondary.light),
            },
        },
    },
    notificationsExpand: {
        marginLeft: theme.spacing(2),
    },
    signOutButton: {
        width: '100%',
        borderRadius: theme.spacing(2.5),
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        borderColor: theme.palette.getContrastText(theme.palette.primary.dark),
        '& > span': {
            fontWeight: 'bold',
        },
    },
    toggleButton: {
        margin: theme.spacing(1, 2),
        '& > svg': {
            color: UtilColors.White,
            fontSize: theme.spacing(5),
        },
    },

    rootCollapse: {
        position:'absolute',
        top: 56,
        left: 268,
        zIndex: 1,
        transform: `rotate(180deg)`,
        transition: 'transform 0.3s',
        '&.closed': {
            left: 62,
            transform: `rotate(0deg)`,
            transition: 'transform 0.3s',
        },
        [theme.breakpoints.down(Breakpoints.Tablet)]: {
            display: 'none'
        }
    },
    
    collapseMenuButton: {
        backgroundColor: theme.palette.getContrastText(theme.palette.primary.dark),
        padding: 0,
        boxSizing: 'border-box',
        background: UtilColors.ShadowLight,
        '& svg': {
            color: theme.palette.primary.dark,
            width: '36px',
            height: '36px',
            '&:hover': {
                color: theme.palette.getContrastText(theme.palette.primary.dark),
                background: theme.palette.primary.dark,
                borderRadius: '50%'
            },
        },
    },
}));
