import { alpha } from '@material-ui/core/styles';
import { makeStyles, Theme } from '@material-ui/core';

const grey = '#aaaaaa';
const red = '#d50000';

export const useUploadStyles = makeStyles((theme: Theme) => ({
    previewEnvelope: {
        display: 'flex',
        minHeight: '200px',
        minWidth: '200px',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fileUpload: {
        marginBottom: theme.spacing(2),
    },
    fileDropzone: {
        width: '100%',
        height: theme.spacing(12),
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: grey['500'],
        backgroundColor: grey['100'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '&:hover': {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.14)}`,
        },
        cursor: 'pointer',
    },
    fileDropzoneOver: {
        width: '100%',
        height: theme.spacing(12),
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: grey['500'],
        backgroundColor: `${alpha(theme.palette.primary.main, 0.14)}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    fileDropzoneError: {
        width: '100%',
        height: theme.spacing(12),
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: grey['500'],
        backgroundColor: `${alpha(theme.palette.error.main, 0.14)}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    error: {
        borderColor: red,
        backgroundColor: alpha('#d50000', 0.1),
    },
    uploadHeader: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    uploadHeaderTitle: {
        textTransform: 'uppercase',
        marginLeft: theme.spacing(1),
    },
    uploadIcon: {
        color: '#aaaaaa',
    },
    uploadError: {
        color: '#d50000',
    },
    preview: {
        padding: theme.spacing(0, 11),
        maxHeight: '20vh',
        display: 'flex',
    },
    filePreviewZone: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'relative',
    },
    imageButton: {
        bottom: theme.spacing(2),
        float: 'right',
        right: theme.spacing(2),
        color: '#fff',
        backgroundColor: grey,
        borderRadius: theme.spacing(1),
        position: 'absolute',
        '&:hover': {
            backgroundColor: grey,
            color: '#000',
        },
    },
    paper: {
        backgroundColor: 'inherit',
        overflow: 'inherit',
        height: '100%',
        display: 'flex',
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            boxShadow: 'none',
        },
    },
    root: {
        flexGrow: 1,
    },
    header: {
        width: '100%',
        textAlign: 'center',
        boxSizing: 'border-box',
        border: `10px solid '#fff`,
        borderBottom: 0,
        backgroundColor: '#fff',
    },
    imageContainer: {
        maxWidth: '100%',
        height: 'auto',
        minHeight: '50%',
        maxHeight: '80vh',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        background: '#fff',
    },
    img: {
        overflow: 'hidden',
        display: 'block',
        height: 'auto',
        maxHeight: '100%',
        maxWidth: '100%',
        boxSizing: 'border-box',
        objectPosition: 'center',
    },
    previewImg: {
        border: `${theme.spacing(1.2)}px solid '#fff`,
    },
    modalPreview: {
        objectFit: 'contain',
    },
    closeButton: {
        position: 'fixed',
        right: theme.spacing(4),
        top: theme.spacing(1),
        color: '#fff',
        [theme.breakpoints.down('sm')]:
            {
                right: theme.spacing(0),
                top: theme.spacing(0),
            },
    },
    middleContainer: {
        marginTop: theme.spacing(10),
        width: '50%',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    stepContainer: {
        width: '25%',
        color: '#fff',
        marginTop: theme.spacing(40),
        [theme.breakpoints.down('sm')]: {
            width: '15%',
        },
    },
    pullLeft: {
        float: 'left',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0),
            float: 'left',
        },
    },
    pullRight: {
        float: 'right',
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(0),
            float: 'right',

        },
    },
    dialog: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
    },
    iconButton: {
        color: '#fff',
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
    },
    bottomBar: {
        position: 'absolute',
        bottom: 0,
        padding: theme.spacing(1, 0),
        margin: theme.spacing(1.2),
        boxSizing: 'border-box',
        width: 'calc(100% - 19px)',
        backgroundColor: alpha('#000', 0.5),
        display: 'flex',
    },
    imageTitle: {
        color: '#fff',
        marginLeft: theme.spacing(2),
        width: '80%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    stepActions: {
        fontSize: 70,
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
    },
    closeAction: {
        fontSize: 40,
    },
    buttonRoot: {
        margin: 0,
        padding: 0,
        minWidth: 0,
    },
    bottomActions: {
        cursor: 'pointer',
        marginRight: theme.spacing(1),
        fontSize: 20,
        color: '#fff',
    },
    previewFiles: {
        width: 'inherit',
        height: 'inherit',
    },
    pdfPreview: {
        color: red,
        fontSize: 200,
    },
    note: {
        margin: theme.spacing(0, 8),
        textAlign: 'center',
        fontSize: 11,
    },
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    underLoading: {
        opacity: 0.2,
    },
    previewFile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    filePreviewIcon: {
        fontSize: '100px',
    },
    closeIcon: {
        position: 'absolute',
        top: '50%',
        right: theme.spacing(0.5),
        transform: 'translateY(-50%)',
    },
    previewTitle: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        wordWrap: 'break-word'
    }
}));
