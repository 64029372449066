import { useCallback } from "react";
import { generatePath, useHistory } from 'react-router';

import { Routes } from 'components/router/model';
import { IAction } from "components/button/actionButton/model";
import { useStoreTaskSidebar } from "./useStoreTaskSidebar";
import { Visibility, Delete, Edit  } from '@material-ui/icons';
import { useActions } from "utils/store/useActions";
import { setParentTaskToRemove } from "store/modules/task/parentTask/parentTaskActions";
import { parentTaskReviewModal, taskReviewModalOpen } from "store/modules/task/taskReview/taskReviewActions";
import { IStoreTaskGridRowData } from "../views/StoreTasksView/grid/model";
import { useFeatureState } from "utils/hooks/useFeatureState";
import { FeatureFlags } from "utils/featureFlags";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { BlueColor } from "utils/styles/constants";
import { t } from "i18next";
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { parentTaskGalleryModal, taskGalleryModalOpen } from "store/modules/task/taskGallery/taskGalleryActions";
import { TasksFilterType, TasksViewModeMyTasks } from "api/task/taskFilter/taskFilterModel";
import { useSelector } from "react-redux";
import { selectTaskFilter } from "store/modules/task/taskFilter/taskFilterSelectors";

const IconCopy = () => <Box mr={1} ml={.5}><FontAwesomeIcon size="xs" icon={faCopy} color={BlueColor.Blue70} /></Box>;

export function useCellTaskAction(task: IStoreTaskGridRowData, isPermittedUser: boolean, isStore?: boolean) {
    const { id: taskId, prototypeId = '', approvable } = task;
    const { openStoreTaskSidebar } = useStoreTaskSidebar(task.id);
    const history = useHistory();
    const cloneTaskEnabled = useFeatureState(FeatureFlags.TaskClone);
    const taskReviewEnabled = useFeatureState(FeatureFlags.TaskReviewByParentTask);
    const taskGalleryEnabled = useFeatureState(FeatureFlags.TaskGallery);
    const valuesFiltersTasksStore: TasksFilterType = useSelector(selectTaskFilter);
    const isTasksAutomationMyTasksEnabled = useFeatureState(FeatureFlags.TasksAutomationMyTasks);

    const actions = useActions({
        setParentTaskToRemove,
        openModal: taskReviewModalOpen,
        parentTaskReview: parentTaskReviewModal,
        parentTaskGallery: parentTaskGalleryModal,
        openModalGallery: taskGalleryModalOpen,
    });

    const handleEditClick = useCallback(() => {
        history.push(generatePath(Routes.EditTask, { prototypeId }));
    }, [history, prototypeId]);

    const handleRemoveClick = useCallback(() => {
        actions.current.setParentTaskToRemove(taskId);
    }, [actions, taskId]);

    const handleCloneClick = useCallback(() => {
        history.push({
            pathname:generatePath( Routes.EditTask, { prototypeId }),
            search: '?isClone=true'
        });
    }, [history, prototypeId]);

    const handleGalleryClick = useCallback(() => {
        actions.current.parentTaskGallery(taskId);
        actions.current.openModalGallery();
        history.push({
            pathname:generatePath( Routes.TaskList),
            search: `?taskIdGallery=${taskId}`
        });
    }, [history, taskId, actions]);

    const handlereviewClick = useCallback(() => {
        actions.current.parentTaskReview({ title: task.title, taskId });
        actions.current.openModal();
    }, [actions, task, taskId]);

    const handleViewClick = useCallback(() => {
        history.push(`/tasks/list/${taskId}`);
    }, [history, taskId]);

    const itemStoreGrid = { name: `${t('View')}`, action: openStoreTaskSidebar, isAvailable: true, Icon: Visibility };
    const itemParentGrid = { name: `${t('View')}`, action: handleViewClick, isAvailable: true, Icon: Visibility };
    const itemsBase: Array<IAction<string>> = [
        {
            name: `${t('Edit')}`,
            action: handleEditClick,
            isAvailable: isPermittedUser,
            Icon: Edit,
        },
        {
            name: `${t('Delete')}`,
            isAvailable: isPermittedUser,
            action: handleRemoveClick,
            Icon: Delete,
        },
    ];

    const itemBaseGrid = isStore ? [ itemStoreGrid, ...itemsBase] : [ itemParentGrid, ...itemsBase]

    const itemClone =  cloneTaskEnabled ? { name: `${t('Clone')}`, isAvailable: isPermittedUser, action: handleCloneClick, Icon: IconCopy } : {}; 
    const itemReview =  taskReviewEnabled && !isStore ? { name: `${t('Review')}`, isAvailable: isPermittedUser, action: handlereviewClick, Icon: Visibility } : {};
    const itemGalery =  taskGalleryEnabled && !isStore && approvable? { name: `${t('Gallery')}`, isAvailable: isPermittedUser, action: handleGalleryClick, Icon: PermMediaIcon } : {};

    const allItems = [ ...itemBaseGrid, itemClone, itemGalery, itemReview ] as Array<IAction<string>>;

    const filterMyTasks = isTasksAutomationMyTasksEnabled && valuesFiltersTasksStore.tasksViewMode === TasksViewModeMyTasks
        ? [itemParentGrid] : allItems

    return { allItems: filterMyTasks, taskId };
}
