import { makeStyles, Theme } from '@material-ui/core/styles';
import { BlueColor, UtilColors } from 'utils/styles/constants';

const fontFamily = '"Bebas Neue", cursive';

export const useLandingStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        width: '100%',
        height: '100%',
        backgroundColor: '#A2A1E3',
        letterSpacing: theme.spacing(-0.5),
    },
    comingSoon: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: theme.spacing(64),
        backgroundColor: BlueColor.Dark,
        color: UtilColors.White,
        textTransform: 'uppercase',
        fontSize: 50,
        fontFamily,
        transform: `rotate(-45deg) translate(${theme.spacing(-19)}px, 0)`,
    },
    content: {
        marginTop: '10%',
        marginLeft: '16%',
    },
    mainHeader: {
        fontSize: 160,
        fontWeight: 900,
        fontFamily,
        fontStyle: 'italic',
        textTransform: 'uppercase',
        paddingLeft: theme.spacing(2),
    },
    appName: {
        color: UtilColors.HoverColor,
    },
    description: {
        color: UtilColors.White,
        fontSize: 40,
        letterSpacing: 'normal',
        lineHeight: 1.5,
    },
    phoneImage: {
        height: '80vh',
        margin: theme.spacing(0, 10, 0, 2),
    },
    logoImage: {
        position: 'absolute',
        bottom: theme.spacing(5),
        marginLeft: theme.spacing(10),
    },
}));
