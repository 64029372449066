import { grey } from '@material-ui/core/colors';
import { makeStyles, Theme, alpha, darken } from '@material-ui/core/styles';

import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';

export const useButtonStyles = makeStyles((theme: Theme) => ({
    darkButton: {
        color: GrayColor.Gray0,
        backgroundColor: BlueColor.Dark,
    },
    fixedSize: {
        width: 250,
    },
    smallLabel: {
        '& > .MuiButton-label': {
            fontSize: 14,
        },
    },
    muiButton: {
        border: `1px solid ${BlueColor.BluePrimaryMain}`,
        borderRadius: '4px',
        borderColor: alpha(BlueColor.BluePrimaryMain, 0.5),
        padding: theme.spacing(1, 1.75),
        color: BlueColor.BluePrimaryMain,
        transition: 'border-color 0.3s',

        fontFamily: `'Inter', sans-serif`,
        fontSize: theme.spacing(1.75),
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: theme.spacing(3) + 'px',
        letterSpacing: '0.4px',
        textAlign: 'left',

        '& > span': {
            textTransform: 'uppercase',

            '& > svg': {
                marginRight: theme.spacing(1.25),
            },
        },

        '&:hover': {
            backgroundColor: UtilColors.Transparent,
            borderColor: BlueColor.BluePrimaryMain,
            color: BlueColor.BluePrimaryMain,
        },
    },

    muiRoundedButton: {
        borderRadius: theme.spacing(3),
        padding: theme.spacing(1, 2),
        fontFamily: `'Inter', sans-serif`,
        fontSize: theme.spacing(1.75),
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: theme.spacing(3) + 'px',
        letterSpacing: '0.4px',
        textAlign: 'left',
        backgroundColor: grey[300],
        transition: 'background-color 0.3s',

        '& .MuiButton-label': {
            textTransform: 'uppercase',
        },

        '&.primary': {
            backgroundColor: BlueColor.BluePrimaryMain,
            color: UtilColors.White,

            '&.dark': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.getContrastText(theme.palette.primary.dark),
                fontWeight: 700,

                '&:hover': {
                    backgroundColor: darken(theme.palette.primary.dark, 0.05),
                    color: theme.palette.getContrastText(theme.palette.primary.dark),
                },
            },

            '&:hover': {
                backgroundColor: darken(BlueColor.BluePrimaryMain, 0.05),
                color: UtilColors.White,
            },
        },

        '&.dark': {
            backgroundColor: GrayColor.Gray87,
            color: UtilColors.White,
            fontWeight: 700,

            '&:hover': {
                color: UtilColors.White,
                backgroundColor: darken(GrayColor.Gray87, 0.05),
            },
        },

        '&:hover': {
            backgroundColor: darken(grey[300], 0.05),
            color: theme.palette.text.primary,
        },
    },
}));
