import { IEntityError } from 'model/entity';
import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import {
    CompanyLocationSearchRequest,
    CompanyLocationSearchResultList,
    LocationChildrenRequest,
    LocationChildrenResponse
} from './locationModel';

const REQUEST_LOCATION_GET_CHILDREN = 'request/LOCATION/GET_CHILDREN';
const RESPONSE_LOCATION_GET_CHILDREN_SUCCESS = 'response/LOCATION/GET_CHILDREN_SUCCESS';
const RESPONSE_LOCATION_GET_CHILDREN_ERROR = 'response/LOCATION/GET_CHILDREN_ERROR';

export const requestLocationChildren = (
    createApiActions<LocationChildrenRequest, LocationChildrenResponse, IEntityError,
        typeof REQUEST_LOCATION_GET_CHILDREN,
        typeof RESPONSE_LOCATION_GET_CHILDREN_SUCCESS,
        typeof RESPONSE_LOCATION_GET_CHILDREN_ERROR
    >(
        REQUEST_LOCATION_GET_CHILDREN,
        RESPONSE_LOCATION_GET_CHILDREN_SUCCESS,
        RESPONSE_LOCATION_GET_CHILDREN_ERROR,
    )
);
export type RequestLocationChildrenTypes = ActionsReturnTypes<typeof requestLocationChildren>;

const REQUEST_LOCATION_SEARCH = 'request/LOCATION/SEARCH';
const RESPONSE_LOCATION_SEARCH_SUCCESS = 'response/LOCATION/SEARCH_SUCCESS';
const RESPONSE_LOCATION_SEARCH_ERROR = 'response/LOCATION/SEARCH_ERROR';

export const requestCompanyLocationSearch = (
    createApiActions<CompanyLocationSearchRequest, CompanyLocationSearchResultList, IEntityError,
        typeof REQUEST_LOCATION_SEARCH,
        typeof RESPONSE_LOCATION_SEARCH_SUCCESS,
        typeof RESPONSE_LOCATION_SEARCH_ERROR
    >(
        REQUEST_LOCATION_SEARCH,
        RESPONSE_LOCATION_SEARCH_SUCCESS,
        RESPONSE_LOCATION_SEARCH_ERROR,
    )
);

export type RequestCompanyLocationSearchTypes = ActionsReturnTypes<typeof requestCompanyLocationSearch>;
