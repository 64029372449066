import { Box, Paper, styled, TextField, Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDebouncedValue } from "utils/hooks/useDebouncedValue";
import { Breakpoints } from "utils/styles/constants";
import { UserListFilter } from "./model";
import { OptionsDropdown } from '../../shared/components/OptionsDropdown';
import { makeStyles } from '@material-ui/core/styles';
import { CompanyLocationFilterPanel } from './CompanyLocationFilterSubpanel';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/modules/auth/authSelectors';
import { t } from "i18next";

export type UserListFilterPanelProps = {
    filter: UserListFilter;
    onFilterChange: (filter: UserListFilter) => void;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        marginTop: theme.spacing(1),
    },
}));

const useActiveFilterStyles = makeStyles((theme: Theme) => ({
    filterIsActive: {
        minWidth: '120px',
        marginLeft: '5px',
    }
}));

export const UserListFilterPanel = ({ filter, onFilterChange }: UserListFilterPanelProps) => {
    const [searchTerm, setSearchTerm] = useState(filter.fullName);
    const [isActiveFilter, setIsActiveFilter] = useState(filter.UserState);
    const classes = useActiveFilterStyles([]);
    const debouncedSearchTerm = useDebouncedValue(searchTerm, 1000);
    const currentUser = useSelector(selectUser);
    const isSuperAdmin = currentUser?.isSuperAdmin ?? false;
    const setIsActiveFilterCallback = useCallback((val) => {
        setIsActiveFilter(val);
        if (filter.CompanyId || !isSuperAdmin) {
            onFilterChange({
                ...filter,
                fullName: debouncedSearchTerm,
                UserState: val,
            });
        }
    }, [onFilterChange, filter, setIsActiveFilter, debouncedSearchTerm, isSuperAdmin]);
    const options = [
        {
            value:'2',
            title: `${t('All')}`
        },
        {
            value:'0',
            title: `${t('Active')}`
        },
        {
            value:'1',
            title: `${t('Inactive')}`
        },
    ];

    useEffect(() => {
        if (filter.fullName !== debouncedSearchTerm) {
            if (filter.CompanyId || !isSuperAdmin) {
                onFilterChange({
                    ...filter,
                    fullName: debouncedSearchTerm,
                    UserState: isActiveFilter,
                })
            }
        }
    }, [filter, filter.CompanyId, debouncedSearchTerm, isActiveFilter, onFilterChange, isSuperAdmin]);

    return (
        <StyledPaper>
            <Box display={'flex'}>
                <TextField
                    value={searchTerm || ''}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={`${t('Search first or last name to filter results')}`}
                    variant="outlined"
                    fullWidth
                />
                <OptionsDropdown
                    unsetValue={'0'}
                    options={options}
                    onValueChange={(val) => setIsActiveFilterCallback(val)}
                    value={isActiveFilter || '0'}
                    className={classes.filterIsActive}
                />
            </Box>
            <CompanyLocationFilterPanel
                filter={filter}
                onFilterChange={onFilterChange}
            />
        </StyledPaper>
    );
};
