import { useCallback, useMemo, useState } from 'react';
import clsx from "clsx";
import { Button, Modal, Box, Typography } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import { Formik, Form} from 'formik';
import * as yup from 'yup';
import { ICreateLabel } from 'modules/task/addTask/model';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { InputError } from 'components/basicInputs/InputError';
import { useButtonStyles } from 'utils/styles/buttons';
import { LabelFormikRadio as FormikRadioButton } from './LabelFormikRadio';
import { Trans } from 'react-i18next';
/*TODO send actual request to corresponding api
import { transformFormValues } from 'utils/helpers/formSubmit';
import { useActions } from "utils/store/useActions";
import { requestCreateLabel } from 'api/task/taskActions';
import { createLabelToPayload } from 'modules/task/addTask/model';
*/
export function LabelCreator() {

    const buttonClasses = useButtonStyles([]);

    const initialFormValues = useMemo(() => ({
        name: '',
        color: ''
    }), [])

    const [showModal, setShowModal] = useState(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A'];

    const labelSchema = yup.object({
        name: yup.string().required("Required"),
        color: yup.string().required("Color is required")
    });


    /*TODO send actual request to corresponding api
    const actions = useActions({
        requestCreateLabel: requestCreateLabel.init
    });

    const submitCreateLabel = useCallback((values: ICreateLabel) => {
            createLabel(values);
            transformFormValues(values);
            actions.current.requestCreateLabel(createLabelToPayload(values));
        }, [actions]);
    */

    const createLabel = useCallback((values: ICreateLabel) => {
        setShowModal(false)
    },[])

    const handleModal = useCallback(() => setShowModal(!showModal), [showModal])

    return (
        <>
            <Button className={buttonClasses.muiButton} style={{border: "none"}} variant="text" onClick={handleModal}><Trans>Create New</Trans></Button>
            <Modal
                open={showModal}
                onClose={handleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <Trans>Create a new label</Trans>
                    </Typography>
                    <Formik
                        initialValues={initialFormValues}
                        validationSchema={labelSchema}
                        onSubmit={createLabel}
                    >
                        {({ errors, dirty }) => (
                            <Form>
                                <TextInput id="outlined-basic" name="name" label="Name" />

                                <Box sx={{ my: 2 }}>
                                    <FormLabel component="legend"><Trans>Select a color</Trans></FormLabel>
                                    {colorArray.map((color, idx) => {
                                        let key = idx+color;
                                        return <FormikRadioButton 
                                            name="color"
                                            value={color}
                                            key={key}
                                        />
                                    })}
                                </Box>
                                <InputError error={errors.color} />
                                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                                    <Button className={buttonClasses.muiRoundedButton} onClick={handleModal}><Trans>Cancel</Trans></Button>
                                    <Button className={clsx(buttonClasses.muiRoundedButton, 'primary')} disabled={!dirty} type="submit"><Trans>Save</Trans></Button>
                                </Box>
                            </Form>)}
                    </Formik>
                </Box>
            </Modal>
        </>
    );
}
