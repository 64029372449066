import React, { PropsWithChildren } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';

const useStyles = makeStyles(() => ({
    root: {
        height: '30vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 40px',
    },
    wrapper: {
        boxSizing: 'border-box',
        padding: '10px',
        width: '100%',
        height: '100%',
        background: UtilColors.ShadowLight,
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontFamily: `'Inter', 'Arial', sans-serif`,
        fontWeight: 500,
        fontSize: '13px',
        textAlign: 'center',
        color: '#8A96A9',
    },
}));

export function ChannelListPlaceholder({ children }: PropsWithChildren<any>): React.ReactElement {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>
                <Box className={classes.text}>
                    { children }
                </Box>
            </Box>
        </Box>
    );
}
