import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Fade, LinearProgress, makeStyles } from '@material-ui/core';
import {
    selectInvitationInProgress,
    selectIsChannelCreating,
    selectIsChannelDeleting,
    selectIsChannelUpdating,
    selectIsDirectMessagingChatCreating,
    selectIsUserRemovingFromChannel,
} from 'store/modules/getStream/getStreamSelectors';
import { ZIndex } from 'utils/styles/constants';
import { selectLocationsLoading } from 'store/modules/location/locationSelectors';
import { selectTaskCreating } from 'store/modules/task/taskSelectors';
import { selectStoreTasksFetching, selectUpdatingStoreTasks } from 'store/modules/task/storeTask/storeTaskSelectors';
import { selectParentTasksFetching, selectParentTasksRemoving } from 'store/modules/task/parentTask/parentTaskSelectors';
import { selectUserRolesLoading } from 'store/modules/user/userSelectors';

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        top: 0, left: 0, right: 0,
        height: 'auto',
        zIndex: ZIndex.TopProgress,
    },
}));

export function TopProgress() {
    const classes = useStyles();

    const locationsLoading = useSelector(selectLocationsLoading);
    const isChannelCreating = useSelector(selectIsChannelCreating);
    const isChannelUpdating = useSelector(selectIsChannelUpdating);
    const isChannelDeleting = useSelector(selectIsChannelDeleting);
    const isDMCreating = useSelector(selectIsDirectMessagingChatCreating);
    const isInvitationInProgress = useSelector(selectInvitationInProgress);
    const isRolesLoading = useSelector(selectUserRolesLoading);
    const isUserRemovingFromChannel = useSelector(selectIsUserRemovingFromChannel);
    const taskCreating = useSelector(selectTaskCreating);
    const parentTasksFetching = useSelector(selectParentTasksFetching);
    const parentTasksRemoving = useSelector(selectParentTasksRemoving);
    const storeTasksFetching = useSelector(selectStoreTasksFetching);
    const updatingStoreTasks = useSelector(selectUpdatingStoreTasks);

    const show = [
        isChannelCreating,
        isChannelDeleting,
        isChannelUpdating,
        isDMCreating,
        isInvitationInProgress,
        isRolesLoading,
        isUserRemovingFromChannel,
        locationsLoading,
        parentTasksFetching,
        storeTasksFetching,
        taskCreating,
        parentTasksRemoving.length > 0,
        updatingStoreTasks.length > 0,
    ].some(statement => statement);

    return (
        <Box className={classes.root}>
            <Fade in={show} timeout={300}>
                <LinearProgress />
            </Fade>
        </Box>
    );
}
