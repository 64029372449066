import { UserPermissionChannel } from 'api/getStream/getStreamModel';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Channel, User, UserResponse } from 'stream-chat';
import { v4 as uuidv4 } from 'uuid';
import { IChannelFile } from './components/ChatHeader/chatActions/ViewDetailsAction/ChannelDetails/model';
import { isBeforeDate } from 'utils/helpers/dateHelper';
import i18n from 'i18n';

const uuidKeyLength = 36;

export function getRawChannelName(channel?: Channel) {
    return get(channel || {}, 'data.name', null);
}

export function getChannelName(channel: Channel): string {
    const channelName = getRawChannelName(channel) || `${i18n.t('Unknown channel')}`;
    return `# ${channelName}`;
}

export function getRawDescription(channel?: Channel) {
    return get(channel || {}, 'data.description', null);
}

export function getRawFrozen(channel?: Channel): boolean | undefined {
    return get(channel || {}, 'data.frozen', undefined);
}

export function getChannelDescription(channel: Channel): string {
    const description: string | null = getRawDescription(channel);
    return description ?? `${i18n.t('No description')}`;
}

export function getChannelImage(channel: Channel): string {
    const image = get(channel || {}, 'data.image', null);
    if(!image) return image;
    const imageId = image.split('/');
    return imageId[imageId.length - 1];
}

export function getChannelId(channel?: Channel) {
    return get(channel || {}, 'data.id', null);
}

function getCompareValue(user: User) {
    return (user.name || `${i18n.t('unknown')}`).toLowerCase();
}

export function getChannelMembers(channel: Channel): Array<User> {
    const members = get(channel, 'state.members', {});
    const memberIdList = Object.keys(members);
    const list: Array<User> = (
        memberIdList
            .filter(key => key.length === uuidKeyLength)
            .map(uuid => members[uuid].user)
    );
    const uniqItems = uniqBy(list, 'id');
    return orderBy(uniqItems, [getCompareValue], ['asc']);
}

export function searchMembers(channel: Channel, searchTerm: string = '', offset = 0, limit = 100 ): any {
    const search = searchTerm !== '' ? {name: {$autocomplete:searchTerm}}:{};
    return channel.queryMembers(search, { }, { limit, offset })
        .then(res=>{
            const list: Array<UserResponse> = (
                res.members
                    .map(member => ({...member.user,
                        role: member?.role,
                        userRole: member?.user?.role,
                        channel_created_at: member.created_at,
                    } as UserResponse))
            );
            return list;
        });
}

export function searchFiles(channel: Channel, offset = 0 ): Promise<IChannelFile[]> {
    return channel.search({ attachments: { $exists: true } }, { limit: 50, offset })
        .then(({ results }) => {
            let attchments: IChannelFile[] = [];
            results.forEach(({message}) => {
                const attachment = message?.attachments?.map((attachment)=>({
                    id: uuidv4(),
                    file_url: attachment.image_url ?? attachment.asset_url,
                    name: attachment.fallback ?? attachment.title,
                    thumb_url: attachment.thumb_url
                }))
                attchments = [
                    ...attchments,
                    ...attachment || []
                ];
            });
            return attchments.reverse();
        });
}

export function sortByOwner(members: UserResponse[]){
    const memberOwnerIndex = members.findIndex((member) => member.role === UserPermissionChannel.Owner);

    if (memberOwnerIndex !== -1) {
        const memberOwner = members.splice(memberOwnerIndex, 1)[0];
        members.unshift(memberOwner);
    }
    return members;
}

export function filterDeactivatedMembers(members: UserResponse[], isActiveUsersFilter: boolean){
    return members.filter(user => {
        if(isActiveUsersFilter){
            return (!user?.deactivated_at || !isBeforeDate(new Date(), user?.deactivated_at));
        }
        return user;
    } );
}
