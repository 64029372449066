import React, { useMemo } from 'react';
import { requestApproveStoreTask } from 'api/task/storeTask/storeTaskActions';
import { useActions } from "utils/store/useActions";
import { TaskDecline } from '../singleTask/components/taskDecline/TaskDecline';
import { ApproveTaskButton } from '../singleTask/components/buttons';
import { useSelector } from 'react-redux';
import { selectTaskReviewUpdatingItems } from 'store/modules/task/taskReview/taskReviewSelectors';
import { Box, Card, CardContent, Link, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { TaskSection } from '../singleTask/components/TaskSection';
import { StoreTaskReadonlyChecklist } from '../singleTask/components/StoreTaskReadonlyChecklist';
import { Breakpoints } from 'utils/styles/constants';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { TaskHeader } from '../singleTask/components/TaskHeader';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from 'components/router/model';
import { TaskReviewModel } from 'api/task/taskReview/taskReviewModel';
import { TaskHeaderDetails } from '../taskList/components/TaskHeaderDetails/TaskHeaderDetails';
import { TaskHeaderSubmissionDetails } from '../taskList/components/TaskHeaderDetails/TaskHeaderSubmissionDetails';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export type TaskReviewListItemProps = {
    task: TaskReviewModel;
};

export const useClasses = makeStyles((theme: Theme) => ({
    card: {
        marginLeft: theme.spacing(.25),
        marginRight: theme.spacing(.25),
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: '24px',
        lineHeight: '32.02px',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            lineHeight: '26.68px',
        },
    },
}));

export function TaskReviewListItem({ task }: TaskReviewListItemProps) {
    const classes = useClasses();
    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const actions = useActions({ approve: requestApproveStoreTask.init });
    const isTaskUpdating = useSelector(selectTaskReviewUpdatingItems)[task.id];
    const handleApproveClick = () => actions.current.approve(task.id);

    const checklistItems = task.checklistItems || [];
    const checklistTotal = checklistItems.length;
    const checklistCompleted = checklistItems.reduce((acc, item) => acc + Number(item.completed), 0);

    const title = useMemo(() => {
        return (
            <Tooltip title={<Trans>Open parent task location view</Trans>}>
                <Link
                    className={classes.title}
                    component={RouterLink}
                    to={{
                        pathname: generatePath(Routes.ViewTask, { uuid: task.id }),
                        search: '?parent=true',
                    }}
                >
                    {task.title}
                </Link>
            </Tooltip>
        );
    }, [task, classes])

    const details = useMemo(() => {
        return (
            <>
                <TaskHeaderDetails
                    createdBy={task.createdBy}
                    dueDate={task.dueDate}
                    taskStatus={task.displayedStatus}
                />
                <TaskHeaderSubmissionDetails
                    submittedBy={task.submittedBy}
                    submissionDate={task.submissionDate}
                />
            </>
        );
    }, [task])

    return (
        <Card className={classes.card}>
            <CardContent>
                <TaskHeader
                    task={task}
                    title={title}
                    details={details}
                />
                <TaskSection mt={2} sectionTitle={`${t('Checklist')} (${checklistCompleted}/${checklistTotal})`}>
                    <StoreTaskReadonlyChecklist checklistItems={checklistItems} />
                </TaskSection>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                    alignItems="center"
                >
                    <TaskDecline taskId={task.id} disabled={isTaskUpdating} />
                    <Box pr="25px" />
                    <ApproveTaskButton onClick={handleApproveClick} disabled={isTaskUpdating} />
                </Box>
            </CardContent>
        </Card>
    );
}
