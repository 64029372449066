import { IEntityError } from 'model/entity';
import { IResponseErrorPayload } from 'model/error';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import { RoleSettings } from './knockModel';

const KNOCK_USER_TOKEN = 'knock/USER_TOKEN';

export const requestKnockUserToken = createApiActionsByName<
    void, string, IResponseErrorPayload, typeof KNOCK_USER_TOKEN
>(KNOCK_USER_TOKEN);

const KNOCK_ROLE_SETTINGS_GET = 'knock/GET_ROLE_SETTINGS';

export const requestRoleSettings = createApiActionsByName<
    string, RoleSettings, IEntityError, typeof KNOCK_ROLE_SETTINGS_GET
>(KNOCK_ROLE_SETTINGS_GET);

const KNOCK_ROLE_SETTINGS_SET = 'knock/SET_ROLE_SETTINGS';

export const updateRoleSettings = createApiActionsByName<
    RoleSettings, RoleSettings, IEntityError, typeof KNOCK_ROLE_SETTINGS_SET
>(KNOCK_ROLE_SETTINGS_SET);

export const KNOCK_USER_AVATAR = 'knock/KNOCK_USER_AVATAR';
export const knockUserAvatar = createSingleAction<string, typeof KNOCK_USER_AVATAR>(
    KNOCK_USER_AVATAR,
);

export type KnockActions =
    | ActionsReturnTypes<typeof requestKnockUserToken>
    | ActionsReturnTypes<typeof requestRoleSettings>
    | ActionsReturnTypes<typeof updateRoleSettings>
    | ReturnType<typeof knockUserAvatar>;
