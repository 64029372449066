import {
    requestInviteByRoles,
    requestInviteToChannel,
    requestRemoveChannelRoles
} from 'api/getStream/getStreamActions';
import { useQueryUsers } from 'modules/connect/messenger/hooks/useQueryUsers';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserRoles, selectUserRolesLoading } from 'store/modules/user/userSelectors';
import { useActions } from "utils/store/useActions";
import { InviteTab } from './model';
import { UserRoleDTOExtended } from '../../../../../../../api/core/model';
import { selectChatRoleIds } from '../../../../../../../store/modules/getStream/getStreamSelectors';

export function useUserRoles() {
    const roles = useSelector(selectUserRoles);
    const isLoadingRoles = useSelector(selectUserRolesLoading);
    const [selectedRoles, setSelectedRoles] = useState<Array<string>>([]);
    const currentMemberRoles = useSelector(selectChatRoleIds);

    return useMemo(() => ({
        get loading() {
            return isLoadingRoles;
        },
        get available() {
            return roles;
        },
        get availableExtended() {
            return roles.map((x) => {
                const extended = x as UserRoleDTOExtended;
                extended.id = x.roleId;
                return extended;
            });
        },
        get chatRoleIds() {
            return currentMemberRoles;
        },
        get selected() {
            return selectedRoles;
        },
        get selectedInstances() {
            return roles.filter(role => selectedRoles.some(id => role.roleId === id));
        },
        set selected(items: Array<string>) {
            setSelectedRoles(items);
        },
    }), [isLoadingRoles, roles, selectedRoles, currentMemberRoles]);
}

export function useInvite(
    tab: InviteTab,
    roles: ReturnType<typeof useUserRoles>,
    usersHelper: ReturnType<typeof useQueryUsers>,
    channelId: string,
    onClose: () => void,
) {
    const actions = useActions({
        inviteMembers: requestInviteToChannel.init,
        inviteByRoles: requestInviteByRoles.init,
        deleteRoleInvites: requestRemoveChannelRoles.init,
    });

    const inviteDisabled: boolean = (
        (tab === InviteTab.Individual && !usersHelper.selectedUsers.length)
        || (tab === InviteTab.UserRoles && !roles.selected.length)
    );

    const make = useCallback(() => {
        if (tab === InviteTab.Individual) {
            actions.current.inviteMembers({
                channelId,
                members: usersHelper.selectedUsers.map(item => item.id),
            });
        } else if (tab === InviteTab.UserRoles && roles.selected.length > 0) {
            const rolesOriginal = roles.chatRoleIds[channelId ?? ''];
            const rolesToRemove = rolesOriginal?.filter((x) => !roles.selected.find(y => y === x));
            const rolesToInvite = roles.selected?.filter((x) => !rolesOriginal?.find(y => y === x));
            if (rolesToRemove && rolesToRemove.length > 0) {
                actions.current.deleteRoleInvites({
                        channelId: channelId,
                        roles: rolesToRemove,
                        rolesToInvite
                    }
                );
            }
            if(rolesToInvite.length && !rolesToRemove?.length){
                actions.current.inviteByRoles({
                    channelId,
                    roles: rolesToInvite,
                });
            }
        }
        onClose();
        usersHelper.setSelectedUsers([]);
        usersHelper.setFoundUsers([]);
        roles.selected = [];
    }, [actions, channelId, onClose, roles, tab, usersHelper]);

    return useMemo(() => ({
        get isDisabled() {
            return inviteDisabled;
        },
        make,
    }), [inviteDisabled, make]);
}
