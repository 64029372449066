import React from 'react';
import { StoreTaskAttachmentModel } from 'api/task/storeTask/storeTaskModel';
import { AttachmentsGallery } from 'modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery';
import { Box } from '@material-ui/core';
import { StyledBoxChecklistAudit } from './StoreTaskChecklistAvatarCompleted';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

interface IStoreTaskReadonlyChecklistFilesProps {
    attachments: StoreTaskAttachmentModel[],
}

export function StoreTaskReadonlyChecklistFiles({ attachments }: IStoreTaskReadonlyChecklistFilesProps) {
    const isTasksChecklistAuditEnabled = useFeatureState(FeatureFlags.TasksChecklistAudit);

    if (!attachments.length)return <></>;

    return (
        <Box ml={3} display="flex" width="100%">
            {isTasksChecklistAuditEnabled && <StyledBoxChecklistAudit />}
            <AttachmentsGallery attachments={attachments}/>
        </Box>
    );
}
