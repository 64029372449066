import React from 'react';
import { FilterStatus, KnockFeedProvider } from '@knocklabs/react-notification-feed';
import { knockAppApiKey, knockAppUserFeedId } from 'utils/globals';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/modules/auth/authSelectors';
import i18n from 'i18n';

import '@knocklabs/react-notification-feed/dist/index.css';
import { selectKnockUserToken } from 'store/modules/knock/knockSelectors';

export const NotificationsProvider = ({ children }: { children: React.ReactElement}) => {
    const user = useSelector(selectUser);
    const userToken = useSelector(selectKnockUserToken);

    if (!user?.id || !userToken) {
        return (
            <>{children}</>
        );
    }
    const dataByLangugage = i18n.getDataByLanguage(i18n.language);
    const i18nNotifcationes = { locale: i18n.language, translations: dataByLangugage?.translation.knocklabs || {}}

    return (
        <KnockFeedProvider
            apiKey={knockAppApiKey}
            feedId={knockAppUserFeedId}
            userId={user.id}
            userToken={userToken}
            rootless
            defaultFeedOptions={{ status: FilterStatus.Unread }}
            i18n={i18nNotifcationes}
        >
            {children}
        </KnockFeedProvider>
    );
};
