import React, { useMemo } from 'react';
import { DirectMessagesSection } from './DirectMessagesSection';
import { DirectMessagePreview } from './DirectMessagePreview';
import { Channel } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { Box, Collapse, Fade } from '@material-ui/core';
import { ListLoadingPlaceholder } from '../../../placeholders/ListLoadingPlaceholder';
import { ChannelListPlaceholder } from '../../../placeholders/ChannelListPlaceHolder';
import { countChannelUnreads } from 'modules/connect/messenger/utils/channels';
import { ISectionContainerProps } from '../../types';

type SetChannelFn = (channel: Channel) => void;
const animationTimeoutMs = 500;

const createDataMapper = (
    activeChannelId: string | undefined,
    currentUserId: string,
    setActiveChannel: SetChannelFn,
    countUnreads: (channel: Channel) => number,
) => (conversation: Channel) => {
    const ids = Object.keys(conversation.state.members ?? {});
    const userId = ids.find(id => id !== currentUserId) ?? '';
    const user = conversation?.state?.members[userId]?.user;
    const isSelected = activeChannelId === conversation.cid;
    return {
        key: conversation.cid,
        cid: conversation.cid,
        image: user?.image as unknown as string,
        title: user?.name ?? 'Unknown User',
        isOnline: Boolean(user?.online),
        isSelected,
        unreadCount: isSelected ? 0 : countUnreads(conversation),
        onSelect: () => setActiveChannel(conversation),
    };
};

export function DirectMessagesSectionContainer({
    conversations,
    activeConversationCid,
    setActiveConversation,
    showLoader,
    totalUnreads,
}: ISectionContainerProps) {
    const { client } = useChatContext();
    const userId = client.userID ?? '';
    const mapConversation = useMemo(
        () => createDataMapper(activeConversationCid, userId, setActiveConversation, countChannelUnreads),
        [activeConversationCid, userId, setActiveConversation],
    );
    const showEmptyPlaceholder = !showLoader && conversations.length === 0;
    const showChannelList = !showLoader && conversations.length > 0;

    const chatList = useMemo(
        () => conversations
            .map(mapConversation)
            .map(({ key, ...props }) => <DirectMessagePreview key={key} userId={userId} {...props} />),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [conversations, mapConversation, totalUnreads],
    );

    return (
        <DirectMessagesSection conversations={conversations}>
            <Collapse in={showLoader} timeout={animationTimeoutMs}>
                <Fade in={showLoader} timeout={animationTimeoutMs}>
                    <Box>
                        <ListLoadingPlaceholder />
                    </Box>
                </Fade>
            </Collapse>

            <Collapse in={showEmptyPlaceholder} timeout={animationTimeoutMs}>
                <Fade in={showEmptyPlaceholder} timeout={animationTimeoutMs}>
                    <Box>
                        <ChannelListPlaceholder>
                            You have no chats yet
                        </ChannelListPlaceholder>
                    </Box>
                </Fade>
            </Collapse>

            <Collapse in={showChannelList} timeout={animationTimeoutMs}>
                <Fade in={showChannelList} timeout={animationTimeoutMs}>
                    <Box>
                        {chatList}
                    </Box>
                </Fade>
            </Collapse>

        </DirectMessagesSection>
    );
}
