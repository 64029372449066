import { Box, styled } from "@material-ui/core";

export const EventDescription = styled(Box)({
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
    font: `'Inter', 'Arial', sans-serif`,

    '& + &': {
        marginTop: 0,
    },
});
