import * as t from 'io-ts';
import { IResponseSuccessPayload } from 'model/success';

export const taskTagSchema = t.type({
    id: t.string,
    name: t.string,
    color: t.string,
});

export const taskTagNewSchema = t.type({
    name: t.string,
    color: t.string,
});

export type TaskTagNewType = t.TypeOf<typeof taskTagNewSchema>;
export type TaskTagType = t.TypeOf<typeof taskTagSchema>;
export interface ITaskTagUpdateSuccess extends IResponseSuccessPayload {
    taskTag: TaskTagType;
}
export const TaskTagTypeResponseSchema = t.array(taskTagSchema);
