import {
    StoreTaskActivityEvent,
    TaskDeclinedActivityEvent,
    TaskEditedActivityEvent,
} from "api/task/storeTask/storeTaskModel";
import { FeatureFlags } from "utils/featureFlags";

export const TASK_APPROVED = 'TaskApproved';
export const TASK_DECLINED = 'TaskDeclined';
export const TASK_SUBMITTED = 'TaskSubmitted';
export const TASK_CREATED = 'TaskCreated';
export const TASK_EDITED = 'TaskEdited';
export const TASK_COMPLETED = 'TaskCompleted';
export const TASK_INCOMPLETED = 'TaskIncompleted';
export const TASK_SCHEDULED_EDITED = 'Edit';
export const TASK_SCHEDULED_CREATE = 'Create'

export const TASK_OVERDUE = 'TaskOverdue';

export enum TASK_FIELDS {
    DUE_DATE = "DueDate",
    ADD_WATCHER = "Added watchers",
    REMOVE_WATCHER = "Removed watchers",

    SCHEDULED_DUE_DATE = "schedule DueDate",
    SCHEDULED_WATCHERS = "schedule Watchers",
    SCHEDULED_TITLE = "schedule Title",
    SCHEDULED_DESCRIPTION = "schedule Description",
    SCHEDULED_CHECKLIST = "schedule Checklist",
    SCHEDULED_ATTACHMENTS = "schedule Attachments",
    SCHEDULED_TAG = "schedule Tag",
    SCHEDULED_RECURRENCE = "schedule Recurrence",
    SCHEDULED_SELECTED_STORES = "schedule Selected stores",

    EVENT_EVENT_END = "EventEnd",
    EVENT_EVENT_START = "EventStart",
    EVENT_EVENT_DESCRIPTION = "Description",
    EVENT_EVENT_TITLE = "Title"
}

export const TasksScheduledFlagsHistory: Record<string, string> = {
    [FeatureFlags.TasksScheduledTaskRemoveStore]: 'Selected stores'
}

export type FieldChange<T = unknown> = {
    fieldName: string;
    oldValue: T;
    newValue: T;
}

export const checkTaskDeclineEvent = (event: StoreTaskActivityEvent): event is TaskDeclinedActivityEvent => {
    return event.eventType === TASK_DECLINED;
};

export const checkTaskEditEvent = (event: StoreTaskActivityEvent): event is TaskEditedActivityEvent => {
    return event.eventType === TASK_EDITED ||  event.eventType === TASK_SCHEDULED_EDITED;
};

export const checkTaskEvents = (fieldChange: FieldChange): boolean => {
    switch (fieldChange.fieldName) {
        case TASK_FIELDS.DUE_DATE:
        case TASK_FIELDS.ADD_WATCHER:
        case TASK_FIELDS.REMOVE_WATCHER:
        case TASK_FIELDS.SCHEDULED_WATCHERS:
        case TASK_FIELDS.SCHEDULED_TITLE:
        case TASK_FIELDS.SCHEDULED_DESCRIPTION:
        case TASK_FIELDS.SCHEDULED_CHECKLIST:
        case TASK_FIELDS.SCHEDULED_ATTACHMENTS:
        case TASK_FIELDS.SCHEDULED_TAG:
        case TASK_FIELDS.SCHEDULED_RECURRENCE:
        case TASK_FIELDS.SCHEDULED_SELECTED_STORES:
        case TASK_FIELDS.SCHEDULED_DUE_DATE:
        case TASK_FIELDS.EVENT_EVENT_END:
        case TASK_FIELDS.EVENT_EVENT_START:
        case TASK_FIELDS.EVENT_EVENT_DESCRIPTION:
        case TASK_FIELDS.EVENT_EVENT_TITLE:
            return true;
        default:
            return false;
    }
};
