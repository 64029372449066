import { IItemComponent } from '../../../components/basicInputs/selectInput/model';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Location, LocationTree } from '../../../api/hierarchy/hierarchyModel';

export function LocationItem({ item: location }: IItemComponent<Location | LocationTree>) {
    return (
        <Box display="flex" alignItems="center">
            <Typography variant="caption">
                {location.name}
            </Typography>
        </Box>
    );
}
