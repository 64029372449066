import { Environment } from 'model/environment';

export const environmentName: Environment = process.env.REACT_APP_DEPLOY_ENV as Environment;
export const isTest: boolean = process.env.NODE_ENV === 'test'; // for `yarn test` only
export const isProduction = environmentName === Environment.Production;

// #region App.Config
export const appConfigConnectionString: string = process.env.REACT_APP_CONFIG_CONNECTION_STRING as string;
// #endregion App.Config

// #region URLs
export const sentryUrl: string = process.env.REACT_APP_SENTRY_URL as string;
export const legacyAppUrl: string = process.env.REACT_APP_LEGACY_APP_URL as string;
export const baseCoreUrl: string = process.env.REACT_APP_BASE_CORE_URL as string;
export const baseTaskUrl: string = process.env.REACT_APP_BASE_TASK_URL as string;
export const baseFilesUrl: string = process.env.REACT_APP_BASE_FILES_URL as string;
export const baseFilesCdnUrl: string = process.env.REACT_APP_FILES_CDN_URL as string;
export const baseStreamUrl: string = process.env.REACT_APP_BASE_STREAM_URL as string;
export const baseNotificationsUrl: string = process.env.REACT_APP_BASE_NOTIFICATIONS_URL as string;
// #endregion URLs

// #region keycloak
export const keycloakAppUrl: string = process.env.REACT_APP_KEYCLOACK_APP_URL as string;
export const keycloackAuthUrl: string = process.env.REACT_APP_KEYCLOACK_AUTH_URL as string;
export const keycloackRealm: string = process.env.REACT_APP_KEYCLOACK_REALM as string;
export const keycloackClientId: string = process.env.REACT_APP_KEYCLOACK_CLIENT_ID as string;
// #endregion keycloak

// #region Keys
export const appInsightKey: string = process.env.REACT_APP_APP_INSIGHT_KEY as string;
export const coreFunctionKey: string = process.env.REACT_APP_CORE_FUNCTION_KEY as string;
export const taskFunctionKey: string = process.env.REACT_APP_TASK_FUNCTION_KEY as string;
export const filesFunctionKey: string = process.env.REACT_APP_FILES_FUNCTION_KEY as string;
export const streamFunctionKey: string = process.env.REACT_APP_STREAM_FUNCTION_KEY as string;
export const notificationsFunctionKey: string = process.env.REACT_APP_NOTIFICATIONS_FUNCTION_KEY as string;
export const googleTagKey: string = process.env.REACT_APP_GOOGLE_TAG as string;
export const getStreamIoApiKey: string = process.env.REACT_APP_GET_STREAM_IO_API_KEY as string;
// #endregion Keys

// #region Knock.App
export const knockAppApiKey: string = process.env.REACT_APP_KNOCKAPP_API_KEY as string;
export const knockAppUserFeedId: string = process.env.REACT_APP_KNOCKAPP_USER_FEED_ID as string;
// #endregion Knock.App

export const IDLE_REMINDER_COUNTDOWN_SECONDS = 20;
export const IDLE_REMINDER_AUTOLOGOUT_SECONDS = 60 /* minutes */ * 60 /* seconds */;

export const bulkUserImportTemplateUrl = process.env.REACT_APP_BULK_USER_IMPORT_CSV_TEMPLATE_URL as string;
export const bulkUserDeactivateTemplateUrl = process.env.REACT_APP_BULK_USER_DEACTIVATE_CSV_TEMPLATE_URL as string;
export const bulkTasksTemplateUrl = process.env.REACT_APP_BULK_TASK_IMPORT_CSV_TEMPLATE_URL as string;


export enum KeyNames {
    Enter = 'Enter',
}
