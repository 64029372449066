import React, { useCallback, useState } from 'react';
import {
    Box, Button, Checkbox, CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle, styled,
    Theme,
    ThemeProvider,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { baseTheme } from '../../root/theme';
import { Breakpoints } from '../../../utils/styles/constants';
import { useFormStyles } from '../../shared/components/formStyles';
import { useButtonStyles } from '../../../utils/styles/buttons';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

type UserBulkActivationDeactivationModalProps = {
    agreeButtonText?: string | React.ReactNode;
    discardButtonText?: string | React.ReactNode;
    text: React.ReactNode;
    onAgree: (x: boolean) => void;
    onDiscard: () => void;
    open: boolean;
    theme?: Theme;
    isLoading?: boolean;
    showConfirmation?: boolean;
    count?: number;
}

const UserDialogDescription = styled(Typography)({
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
});

export function UserBulkConfirmDialog(
    {
        text,
        onAgree,
        onDiscard,
        agreeButtonText = <Trans>Yes</Trans>,
        discardButtonText = <Trans>No</Trans>,
        open,
        theme = baseTheme,
        isLoading = false,
        showConfirmation = false,
        count = 0,
    }: UserBulkActivationDeactivationModalProps) {
    const fullscreen = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const [mailConfirmation, setMailConfirmation] = useState(false);
    const formStyles = useFormStyles();
    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');
    const isOpen = open && ((count ?? 0) > 0);

    const internalAgree = useCallback(() => {
        onAgree(mailConfirmation);
    }, [onAgree, mailConfirmation]);

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={onDiscard}
                fullScreen={fullscreen}
            >
                <DialogTitle>
                    <Typography variant="h2" component="span">
                        {text}
                    </Typography>
                </DialogTitle>

                <DialogActions>
                    {isLoading && (
                        <Box width="100%" display="flex" overflow="hidden" justifyContent="center">
                            <CircularProgress className={formStyles.createTaskItem} />
                        </Box>
                    )}
                    {!isLoading && (
                        <Box
                            width="100%"
                            marginLeft="15px"
                            marginRight="15px"
                        >
                            {showConfirmation && (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    marginLeft={'-10px'}
                                >
                                    <Checkbox
                                        disabled={false}
                                        color="primary"
                                        checked={mailConfirmation}
                                        onClickCapture={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setMailConfirmation(!mailConfirmation);
                                        }}
                                    />
                                    <UserDialogDescription><Trans  i18nKey="textToSendEmailConfirmation" count={count}>Send email confirmation for {{count}} selected users</Trans></UserDialogDescription>
                                </Box>
                            )}
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                                marginTop="20px"
                            >
                                <Button
                                    onClick={onDiscard}
                                    className={clsx(buttonClasses.muiRoundedButton)}
                                >{discardButtonText}</Button>
                                <Button
                                    onClick={internalAgree}
                                    className={saveButtonClasses}
                                >{agreeButtonText}</Button>
                            </Box>
                        </Box>
                    )}
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}
