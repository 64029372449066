import { put, select, takeEvery } from 'typed-redux-saga';
import { CALENDAR_CALENDAR_FILTER_LOCATION_IDS, CALENDAR_FILTER_CREATOR, CALENDAR_FILTER_STATUS, CALENDAR_FILTER_TAGS } from './calendarFilterTaskActions';
import { getCalendarTasks } from '../calendarTask/calendarTaskActions';
import { ICalendarComponentOnNavigate } from '../calendarTask/calendarTaskModel';
import { selectCalendarComponentActiveView, selectCalendarComponentOnNavigate } from '../calendarTask/calendarSelectors';
import { View } from 'react-big-calendar';
import { dateRangeByView } from 'modules/task/taskCalendar/updateDataToScheduler';
import { selectUser } from 'store/modules/auth/authSelectors';
import { UserDTO } from 'api/user/userModel';

function* calendarFilterWatcher() {
    yield* takeEvery([
      CALENDAR_FILTER_TAGS,
      CALENDAR_FILTER_STATUS,
      CALENDAR_CALENDAR_FILTER_LOCATION_IDS,
      CALENDAR_FILTER_CREATOR
    ],function* () {
        const currentUser: UserDTO | null | undefined = yield* select(selectUser);
        if(!currentUser) return;

        const onNavigate: ICalendarComponentOnNavigate = yield select(selectCalendarComponentOnNavigate);
        const activeView: View = yield select(selectCalendarComponentActiveView);
        const rangeDate = dateRangeByView(activeView, onNavigate?.newDate ?? undefined);

        yield* put(getCalendarTasks(rangeDate))
      },
    );
}

export const calendarFilterSagas = [
  calendarFilterWatcher
];
