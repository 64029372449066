import React, { useEffect, useCallback } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PDFViewer from './PreviewPDF';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { useActions } from 'utils/store/useActions';
import { requestFileByFileStorage } from 'api/files/filesActions';
import { useSelector } from 'react-redux';
import { selectFileByFileStorage, selectFileByFileStorageFetchingById } from 'store/modules/files/filesSelectors';

export interface IDocumentPreviewFile extends ReactImageGalleryItem{
    fileId: string;
}

export const UnMemoizedPDFSlide = ({ fileId } : IDocumentPreviewFile) => {
    const pdfPath = useSelector(selectFileByFileStorage)[fileId];
    const loading = useSelector(selectFileByFileStorageFetchingById)[fileId];
    
    const actions = useActions({
        getFile: requestFileByFileStorage.init,
    });

    const loadPdf = useCallback(async() => {
        actions.current.getFile(fileId);        
    }, [fileId, actions]);

    useEffect( ()=> {
        loadPdf();
    },[loadPdf]);

    return (
        <Box className="image-gallery-video">
            {loading ? 
                <CircularProgress />
                : <PDFViewer 
                    fileUrl={pdfPath?.objectURL as string}
                />
            }
        </Box>
    );
};

export const PDFSlide = React.memo(
    UnMemoizedPDFSlide,
) as typeof UnMemoizedPDFSlide;
