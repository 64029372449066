import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, Fade, IconButton, Tooltip } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Close } from '@material-ui/icons';
import { Trans } from 'react-i18next';

const TagButton = withStyles((theme: Theme) => ({
    root: {
        padding: 0,
        minWidth: 24,
        minHeight: 24,
        height: 24,
        borderRadius: 3,
        width: 24,
        backgroundColor: ({ bgcolor }: {bgcolor:string}) => bgcolor ?? '#f0f1f1',
        background: ({ bgcolor, showgradient }: {bgcolor:string, showgradient: string | null}) => showgradient ? bgcolor : 'linear-gradient(to right, #fe3a3a, orange, yellow, #5df15d, #D0FFFF )',
        '&:hover': {
            backgroundColor:  ({ bgcolor }: {bgcolor:string}) => bgcolor ?? '#f0f1f1',
            background: ({ bgcolor, showgradient }: {bgcolor:string, showgradient: string | null}) => showgradient ? bgcolor : 'linear-gradient(to right, #fe3a3a, orange, yellow, #5df15d, #D0FFFF )',
        },
        '& svg':{
            fill: 'black'
        }
    },
    }))(Button);

export type TagSelectedProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
    title: string | null,
    bgcolor: string | null,
    onClean: React.MouseEventHandler<HTMLButtonElement> | undefined,
}

export const TagSelected = ({ title, bgcolor, onClick, onClean }: TagSelectedProps) => (
    <Box position="relative">
        <Tooltip title={title ? <>{title}</> : <Trans>Empty</Trans>} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <TagButton variant="contained" onClick={onClick} bgcolor={bgcolor ?? "#f1f1f1"} showgradient={bgcolor}>
                { !!!title?.length && !!!bgcolor?.length && <NotInterestedIcon color="action"/>}
            </TagButton>
        </Tooltip>
        { !!title?.length && !!bgcolor?.length && 
            <Box position="absolute" top={-20} right={-12}>
                <IconButton aria-label="Close" onClick={onClean} size="small">
                    <Close fontSize="small" />
                </IconButton>
            </Box>
        }
    </Box>
    );
