import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import { IEntityError, IEntitySuccess } from 'model/entity';
import { TaskTemplateDetailsType, TaskTemplateRequestType, TaskTemplateType } from './taskTemplateModel';
import { TaskCreateRequest } from '../taskModel';

const REQUEST_TASK_TEMPLATES_GET = 'request/task/templates/GET';
const RESPONSE_TASK_TEMPLATES_GET_SUCCESS = 'response/task/templates/GET_SUCCESS';
const RESPONSE_TASK_TEMPLATES_GET_ERROR = 'response/task/templates/GET_ERROR';

export const requestTaskTemplates = createApiActions<void, Array<TaskTemplateType>, any, typeof REQUEST_TASK_TEMPLATES_GET,
    typeof RESPONSE_TASK_TEMPLATES_GET_SUCCESS, typeof RESPONSE_TASK_TEMPLATES_GET_ERROR
>(
    REQUEST_TASK_TEMPLATES_GET, RESPONSE_TASK_TEMPLATES_GET_SUCCESS, RESPONSE_TASK_TEMPLATES_GET_ERROR,
);

const REQUEST_TASK_TEMPLATE_DELETE = 'request/task/template/DELETE';
const RESPONSE_TASK_TEMPLATE_DELETE_SUCCESS = 'response/task/template/DELETE_SUCCESS';
const RESPONSE_TASK_TEMPLATE_DELETE_ERROR = 'response/task/template/DELETE_ERROR';

export const requestDeleteTaskTemplate = (
    createApiActions<{id: string }, IEntitySuccess, IEntityError,
        typeof REQUEST_TASK_TEMPLATE_DELETE,
        typeof RESPONSE_TASK_TEMPLATE_DELETE_SUCCESS,
        typeof RESPONSE_TASK_TEMPLATE_DELETE_ERROR
    >(
        REQUEST_TASK_TEMPLATE_DELETE,
        RESPONSE_TASK_TEMPLATE_DELETE_SUCCESS,
        RESPONSE_TASK_TEMPLATE_DELETE_ERROR,
    )
);

const REQUEST_TASK_TAG_TEMPLATE = 'request/task/template/CREATE';
const RESPONSE_TASK_TAG_TEMPLATE_SUCCESS = 'response/task/template/CREATE_SUCCESS';
const RESPONSE_TASK_TAG_TEMPLATE_ERROR = 'response/task/template/CREATE_ERROR';

export const requestCreateTaskTemplate = (
    createApiActions<TaskCreateRequest, TaskTemplateType, any,
        typeof REQUEST_TASK_TAG_TEMPLATE,
        typeof RESPONSE_TASK_TAG_TEMPLATE_SUCCESS,
        typeof RESPONSE_TASK_TAG_TEMPLATE_ERROR
    >(
        REQUEST_TASK_TAG_TEMPLATE,
        RESPONSE_TASK_TAG_TEMPLATE_SUCCESS,
        RESPONSE_TASK_TAG_TEMPLATE_ERROR,
    )
);

const REQUEST_TASK_TEMPLATE_GET = 'request/task/template/GET';
const RESPONSE_TASK_TEMPLATE_GET_SUCCESS = 'response/task/template/GET_SUCCESS';
const RESPONSE_TASK_TEMPLATE_GET_ERROR = 'response/task/template/GET_ERROR';

export const requestGetTaskTemplate = createApiActions<{ id: string, isEditTemplate?: boolean }, TaskTemplateDetailsType, IEntityError,
    typeof REQUEST_TASK_TEMPLATE_GET, typeof RESPONSE_TASK_TEMPLATE_GET_SUCCESS, typeof RESPONSE_TASK_TEMPLATE_GET_ERROR
>(
    REQUEST_TASK_TEMPLATE_GET, RESPONSE_TASK_TEMPLATE_GET_SUCCESS, RESPONSE_TASK_TEMPLATE_GET_ERROR,
);
export const TASK_TEMPLATE_GET_RESET = 'task/template/RESET';
export const resetGetTaskTemplate = createSingleAction<void, typeof TASK_TEMPLATE_GET_RESET>(
    TASK_TEMPLATE_GET_RESET,
);

const REQUEST_TASK_TEMPLATE_UPDATE = 'request/task/template/UPDATE';
const RESPONSE_TASK_TEMPLATE_UPDATE_SUCCESS = 'response/task/template/UPDATE_SUCCESS';
const RESPONSE_TASK_TEMPLATE_UPDATE_ERROR = 'response/task/template/UPDATE_ERROR';

export const requestUpdateTaskTemplate = (
    createApiActions<TaskTemplateRequestType, any, IEntityError,
        typeof REQUEST_TASK_TEMPLATE_UPDATE,
        typeof RESPONSE_TASK_TEMPLATE_UPDATE_SUCCESS,
        typeof RESPONSE_TASK_TEMPLATE_UPDATE_ERROR
    >(
        REQUEST_TASK_TEMPLATE_UPDATE,
        RESPONSE_TASK_TEMPLATE_UPDATE_SUCCESS,
        RESPONSE_TASK_TEMPLATE_UPDATE_ERROR,
    )
);
export type TaskTemplateApiActions = (
    | ActionsReturnTypes<typeof requestTaskTemplates>
    | ActionsReturnTypes<typeof requestDeleteTaskTemplate>
    | ActionsReturnTypes<typeof requestCreateTaskTemplate>
    | ActionsReturnTypes<typeof requestGetTaskTemplate>
    | ReturnType<typeof resetGetTaskTemplate>
    | ActionsReturnTypes<typeof requestUpdateTaskTemplate>
);
