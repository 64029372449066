import { ConversationsStoreTaskType } from "api/task/taskConversation/taskConversationModel";
import { UserDTO } from "api/user/userModel";
import { createSingleAction } from "utils/store/actionUtils";

export const SHOW_TEXT_EDITOR = 'task/conversation/SHOW_TEXT_EDITOR';
export const isActiveTextEditor = createSingleAction<string, typeof SHOW_TEXT_EDITOR>(
  SHOW_TEXT_EDITOR,
);

export const STORE_FILTER_CONVERSATION = 'task/conversation/STORE_FILTER_CONVERSATION';
export const storeFilterConversation = createSingleAction<string, typeof STORE_FILTER_CONVERSATION>(
  STORE_FILTER_CONVERSATION,
);

export const TASK_PROTOTYPE_ID_CONVERSATION = 'task/conversation/TASK_PROTOTYPE_ID_CONVERSATION';
export const taskPrototypeIdConversation = createSingleAction<string, typeof TASK_PROTOTYPE_ID_CONVERSATION>(
  TASK_PROTOTYPE_ID_CONVERSATION,
);

export const SUGGESTIONS = 'task/conversation/SUGGESTIONS';
export const suggestionsConversation = createSingleAction<UserDTO[], typeof SUGGESTIONS>(
  SUGGESTIONS,
);

export const RESET_CONVERSATIONS = 'task/conversation/RESET_CONVERSATIONS';
export const resetConversations = createSingleAction<void, typeof RESET_CONVERSATIONS>(
  RESET_CONVERSATIONS,
);

export const SET_TASK_COMMENT_TO_REMOVE_ID = 'task/conversation/SET_TASK_COMMENT_TO_REMOVE_ID';
export const setTaskCommentToRemove = (
    createSingleAction<string | null, typeof SET_TASK_COMMENT_TO_REMOVE_ID>(SET_TASK_COMMENT_TO_REMOVE_ID)
);

export const COMMENT_ID_MODAL = 'task/calendar/COMMENT_ID_MODAL';
export const commentModalActiveId = createSingleAction<string | null, typeof COMMENT_ID_MODAL>(
    COMMENT_ID_MODAL,
);

export const UPDATE_REACTION_CONVERSATION = 'task/conversation/UPDATE_REACTION_CONVERSATION';
export const updateReactionConversation = createSingleAction<{locationId: string, comments: ConversationsStoreTaskType}, typeof UPDATE_REACTION_CONVERSATION>(
  UPDATE_REACTION_CONVERSATION,
);

export const SUGGESTIONS_USERS = 'task/conversation/SUGGESTIONS_USERS';
export const setSuggestionsUsersConversation = createSingleAction<boolean, typeof SUGGESTIONS_USERS>(
  SUGGESTIONS_USERS,
);

export type TaskConversationActions =
    | ReturnType<typeof storeFilterConversation>
    | ReturnType<typeof isActiveTextEditor>
    | ReturnType<typeof suggestionsConversation>
    | ReturnType<typeof resetConversations>
    | ReturnType<typeof setTaskCommentToRemove>
    | ReturnType<typeof commentModalActiveId>
    | ReturnType<typeof taskPrototypeIdConversation>
    | ReturnType<typeof updateReactionConversation>
    | ReturnType<typeof setSuggestionsUsersConversation>;
