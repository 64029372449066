import { useSelector } from 'react-redux';
import {
    selectParentTasksById,
    selectParentTasksOrder,
    selectParentTasksRemoving,
} from 'store/modules/task/parentTask/parentTaskSelectors';
import { IParentTaskGridRowData } from './model';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { ParentTaskShort } from 'api/task/parentTask/parentTaskModel';
import { nonEmpty } from 'utils/helpers/collections';

const resolveCompletionState = (item: ParentTaskShort) => {
    if (!item) return DisplayedTaskStatus.Default;
    switch (true) {
        case item.overdue > 0:
            return DisplayedTaskStatus.Overdue;
        case item.completedOverdue > 0:
            return DisplayedTaskStatus.CompletedOverdue;
        case item.submittedOverdue > 0:
            return DisplayedTaskStatus.SubmittedOverdue;
        case item.expired > 0:
            return DisplayedTaskStatus.Expired;
        case item.storeTasks === item.completed:
            return DisplayedTaskStatus.Completed;
        default:
            return DisplayedTaskStatus.Default;
    }
};

export function useParentTasksGridData() {
    const parentTasksOrder = useSelector(selectParentTasksOrder);
    const parentTasksById = useSelector(selectParentTasksById);
    const removingParentTasks = useSelector(selectParentTasksRemoving);

    const data: Array<IParentTaskGridRowData> = (
        parentTasksOrder
            .map(id => parentTasksById[id])
            .filter(nonEmpty)
            .map(item => ({
                id: item.id,
                prototypeId: item.prototypeId || '',
                rootLocationId: item.rootLocationId || '',
                title: item.title,
                dueDate: item.dueDate,
                storeTasks: item.storeTasks,
                completedStoreTasks: item.completed,
                overdueStoreTasks: item.overdue,
                submittedStoreTasks: item.submittedStoreTasks,
                completionState: resolveCompletionState(item),
                createdBy: item.createdBy,
                isSkeleton: removingParentTasks.length > 0 && removingParentTasks.some(id => id === item.id),
                approvable: item.isApproval,
                expire: item.isExpirable,
                tag: item.tag,
                createdAt: item.createdAt
            }))
    );

    return data;
}
