import { useState, useEffect } from 'react';
import { Box, Collapse } from '@material-ui/core';
import { Scrollbar } from 'components/containers/Scrollbar';
import { useSelector } from 'react-redux';
import { selectTaskGalleryFetching, selectTaskGalleryFilter, selectTaskGalleryItems } from 'store/modules/task/taskGallery/taskGallerySelectors';
import { TaskGalleryFilter, TaskGalleryModel } from 'api/task/taskGallery/taskGalleryModel';
import { TaskGalleryItem } from './TaskGalleryItem';
import { TaskGallerySkeleton } from './TaskGallerySkeleton';

function renderTaskGallery(task: TaskGalleryModel) {
    return (
        <Collapse key={task.id} in timeout="auto" unmountOnExit>
            <TaskGalleryItem task={task} />
        </Collapse>
    );
}

export const TaskGalleryList = () => {
    const tasks = useSelector(selectTaskGalleryItems);
    const isLoading = useSelector(selectTaskGalleryFetching);
    const taskFilter = useSelector(selectTaskGalleryFilter);

    const [tasksRender, setTasksRender] = useState<TaskGalleryModel[]>([]);

    useEffect(() => {
        if(taskFilter.status === TaskGalleryFilter.Active && taskFilter.task && tasksRender.length > 1 ){
            setTasksRender([taskFilter.task])
        }
        if((tasks.length > 0 && tasksRender.length <= 0) || (taskFilter.status === TaskGalleryFilter.Reset && tasksRender.length <= 1)){
            const aux = tasks.slice(0, 5);
            setTasksRender(aux);
        }
        if(!(tasksRender.every(taskRender=>( tasks.find(task=> task.id === taskRender.id))))){
            const filterTasks = tasksRender.filter(rende=>( tasks.find(task=> task.id === rende.id)));
            setTasksRender(filterTasks);
        }   
    }, [tasks, tasksRender, taskFilter])

    const onScroll = (scrollTop: number, scrollHeight: number, clientHeight: number) => {
        if(scrollHeight - 2 < scrollTop + clientHeight 
            && scrollTop + clientHeight < scrollHeight + 2
            && tasksRender.length < tasks.length) {
            const aux = tasks.slice(tasksRender.length, tasksRender.length + 5);
            setTasksRender(prev =>([...prev, ...aux]));
        }
    };

    if(isLoading){
        return <TaskGallerySkeleton />
    }

    return (
        <Box style={{height: 'calc(100vh - 120px)', marginLeft: "-12px", marginRight: "-12px"}}>
            <Scrollbar
                onScrollY={(container) =>{
                    onScroll(container.scrollTop, container.scrollHeight, container.clientHeight)
                }}>
                    {tasksRender.map(task => renderTaskGallery(task))}
            </Scrollbar>
        </Box>
    );
}
