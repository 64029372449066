import React, { useEffect, useMemo, useState, useContext } from 'react';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { useDispatch, useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { selectStoreTasksFetching } from 'store/modules/task/storeTask/storeTaskSelectors';
import { useActions } from "utils/store/useActions";
import { TaskListPageLayout } from '../../components/TaskListPageLayout';
import { useFixedSizeListHeight } from '../../hooks/useFixedSizeListHeight';
import { StoreTasksGrid } from './grid/StoreTasksGrid';
import { useStoreTasksGridData } from './grid/useStoreTasksGridData';
import { useQueryParams } from 'utils/hooks';
import { TaskQueryParams } from 'modules/task/common/model';
import { validate } from 'uuid';
import { StoreTaskStatusFilter } from 'api/task/storeTask/storeTaskModel';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { emptyTaskRangeDate } from '../../../../../store/modules/task/statistics/statisticsActions';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { RemoveParentTaskConfimation } from '../ParentTasksView/RemoveParentTaskConfirmation';
import { SearchTasks } from '../../components/SearchTasks';
import { TasksFilterContext } from '../../context/taskFilterContext';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { AutomationTasksGrid } from '../../components/AutomationTaskGrid';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { TaskFilterContainer } from 'modules/task/common/taskFilter/TaskFilterContainer';
import { TasksFilterType } from 'modules/task/common/taskFilter/TypeTaskFilterMap';

export type StoreTasksViewParams = {
    locationId: string;
};

export const StoreTasksView = ({ locationId }: StoreTasksViewParams) => {
    const dispatch = useDispatch();
    const data = useStoreTasksGridData();
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const areStoreTasksFetching = useSelector(selectStoreTasksFetching);
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();
    const { openTask: openTaskId } = useQueryParams<TaskQueryParams>();
    const [searchTasks, setSearchTasks] = useState<string>('');
    const isTasksSearchEnabled = useFeatureState(FeatureFlags.TasksSearch);

    const { 
        valuesFiltersTasks,
        showTableTask,
    } = useContext(TasksFilterContext);

    useEffect(() => {
        dispatch(emptyTaskRangeDate());
    }, [dispatch]);

    const header = useMemo(() => {
        return (
            <TaskFilterContainer taskFiltertype={TasksFilterType.StoreTasks} locationId={locationId}>
                {isTasksSearchEnabled && <SearchTasks 
                    onSearchTaskValuesChange={setSearchTasks}
                />}
            </TaskFilterContainer>
        )
    }, [locationId, isTasksSearchEnabled])

    const actions = useActions({
        setModalTaskId: storeTaskSetActiveId,
    });

    useEffect(() => {
        if (openTaskId && validate(openTaskId)) {
            actions.current.setModalTaskId({taskId: openTaskId});
        }
    }, [actions, openTaskId]);

    return (
        <>
            <TaskListPageLayout
                header={header}
                layoutHeaderRef={layoutHeaderRef}
                showCreateButton={canCreateTask}
            >
                { showTableTask &&
                    <>
                        { 
                            valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ?
                                <StoreTasksGrid
                                    locationId={locationId}
                                    filter={ valuesFiltersTasks.filter as StoreTaskStatusFilter | undefined}
                                    filterTag={valuesFiltersTasks.tags}
                                    filterCreatedBy={valuesFiltersTasks.createdBy}
                                    data={data}
                                    isFetching={areStoreTasksFetching}
                                    height={gridHeight}
                                    searchTerm={searchTasks}
                                    tasksViewMode={valuesFiltersTasks.tasksViewMode}
                                />
                                :
                                <AutomationTasksGrid
                                    filter={valuesFiltersTasks.filter as AutomationTaskStatusFilter | undefined}
                                    filterTag={valuesFiltersTasks.tags}
                                    height={gridHeight}
                                    searchTerm={searchTasks}
                                />
                        }

                        <StoreTaskView />
                    </>
                }

            </TaskListPageLayout>
            <RemoveParentTaskConfimation isFromStoreTask/>
        </>
    );
};
