import { put, takeEvery, takeLatest } from 'typed-redux-saga';
import { requestNotificationsCount } from 'api/legacy/legacyActions';
import { AppStateActions, APP_LEGACY_PAGE_LOADED, APP_NAVIGATE_TO, navigateTo } from './appStateActions';
import { browserHistory, LegacyRoutes } from 'components/router/model';

function* notificationsPageLoadedSaga(action: AppStateActions) {
    if (action.payload === LegacyRoutes.UsersNotifications) {
        yield put(requestNotificationsCount.init());
    }
}

function* notificationsPageLoadedWatcher() {
    yield* takeLatest(
        APP_LEGACY_PAGE_LOADED,
        notificationsPageLoadedSaga,
    );
}

function* navigationWatcherSaga() {
    yield* takeEvery(
        APP_NAVIGATE_TO,
        function ({ payload: path }: ReturnType<typeof navigateTo>) {
            browserHistory.push(path);
        },
    );
}

export const appStateSagas = [
    notificationsPageLoadedWatcher,
    navigationWatcherSaga,
];
