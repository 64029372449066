import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { TaskStatus } from 'api/task/taskModel';

interface IStatusResolvable {
    isOverdue: boolean;
    isExpired?: boolean;
    status: TaskStatus | string;
    isCompletedOverdue?: boolean;
    isSubmittedOverdue?: boolean;
}

export function resolveStoreTaskDisplayedStatus(item: IStatusResolvable): DisplayedTaskStatus {
    if ( item.isOverdue && item.status === TaskStatus.Incomplete) {
        return item.isExpired ? DisplayedTaskStatus.Expired : DisplayedTaskStatus.Overdue;
    }
    switch (item.status) {
        case TaskStatus.Done:
            if (item.isCompletedOverdue) {
                return DisplayedTaskStatus.CompletedOverdue;
            } else {
                return DisplayedTaskStatus.Completed;
            }
        case TaskStatus.Declined: return DisplayedTaskStatus.Declined;
        case TaskStatus.Submitted:
            if (item.isSubmittedOverdue) {
                return DisplayedTaskStatus.SubmittedOverdue;
            } else {
                return DisplayedTaskStatus.Submitted;
            }
        case TaskStatus.Incomplete: return DisplayedTaskStatus.Default;
        default: {
            return DisplayedTaskStatus.Default;
        }
    }
}
