import React, { useCallback } from 'react'
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { UserInfo } from 'components/userInfo/UserInfo';
import { Trans } from 'react-i18next';
import { EditCreator } from './EditCreator';
import { selectCurrentTaskCreator, selectEditTaskCreatorIsEdition } from 'store/modules/task/editTaskCreator/editTaskCreatorSelectors';
import { useActions } from 'utils/store/useActions';
import { changeEdition } from 'store/modules/task/editTaskCreator/editTaskCreatorActions';
import PersonIcon from '@material-ui/icons/Person';
import { useButtonStyles } from 'utils/styles/buttons';

export const TaskCreator: React.FC<{isDisabled?: boolean}> = ({ isDisabled = false }) => {
    const isTasksReassignCreatorEnabled = useFeatureState(FeatureFlags.TasksReassignCreator);
    const { prototypeId } = useParams<{ prototypeId: string }>();
    const { uuid: templateId } = useParams<{ uuid: string }>();
    const hasPrototypeId = !!templateId || !!prototypeId;
    const showEditCreator = useSelector(selectEditTaskCreatorIsEdition);
    const currentTaskCreator = useSelector(selectCurrentTaskCreator);
    const actions = useActions({ changeEdition })
    const buttonClasses = useButtonStyles([]);

    const handleEditCreator = useCallback(()=> actions.current.changeEdition(true), [actions]);


    if(!isTasksReassignCreatorEnabled || !hasPrototypeId || !currentTaskCreator) return <></>;

    return (
      <Box mb={4} mt={2}>
        <Trans>Creator</Trans>
        <Box>
          { !showEditCreator ?
            <Box mt={2}>
              <UserInfo userId={currentTaskCreator} />
              <Box mt={1}>
                <Button onClick={handleEditCreator} size='medium'
                  className={buttonClasses.muiButton}
                  disabled={isDisabled}
                >
                  <PersonIcon />
                  <Trans>Edit Creator</Trans>
                </Button>
              </Box>
            </Box>
          : <EditCreator targetUserId={currentTaskCreator} prototypeId={prototypeId || templateId}/>
          }
        </Box>
      </Box>
    )
}
