import { createSingleAction } from "utils/store/actionUtils";

export const OPEN_SIDE_OUT_TASKS_BULK = 'task/tasks-bulk/OPEN_SIDE_OUT_TASKS_BULK';
export const openSideOutTasksBulk = createSingleAction<boolean, typeof OPEN_SIDE_OUT_TASKS_BULK>(
  OPEN_SIDE_OUT_TASKS_BULK,
);

export const SET_TASK_BULK_TO_REMOVE_ID = 'task/tasks-bulk/SET_TASK_BULK_TO_REMOVE_ID';
export const setTaskBulkToRemove = (
    createSingleAction<string | null, typeof SET_TASK_BULK_TO_REMOVE_ID>(SET_TASK_BULK_TO_REMOVE_ID)
);

export type TasksBulkActions =(
  | ReturnType<typeof openSideOutTasksBulk>
  | ReturnType<typeof setTaskBulkToRemove>
);
