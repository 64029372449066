import React from 'react';
import { CellProps } from 'react-table';
import { IAutomationTaskGridRowData } from '../model';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { useStoreTaskSidebar } from 'modules/task/taskList/hooks/useStoreTaskSidebar';
import { Visibility } from '@material-ui/icons';
import { t } from "i18next";
import { IAction } from 'components/button/actionButton/model';

export const ActionsCell: React.FC<CellProps<IAutomationTaskGridRowData, string>> = props => {
    const { original: task } = props.row;
    const { openStoreTaskSidebar } = useStoreTaskSidebar(task.id, true);
    const itemsBase: Array<IAction<string>> = [
        { name: `${t('View')}`, action: openStoreTaskSidebar, isAvailable: true, Icon: Visibility }
    ];

    return (
        <ActionButton
            autoHide
            disabled={task.isSkeleton}
            width={16}
            entity={task.id}
            items={itemsBase}
        />
    );
};
