import { combineReducers } from 'redux';
import { AuthActions, AUTH_LOGIN_AS, ACTIVE_TOKEN } from 'api/auth/authActions';
import { requestSelfUserInfo, UserActions } from 'api/user/userActions';

const initialState = {
    user: null,
    loading: true,
    loadingLoginAs: false,
    userToken: ''
};

function selfId(state: string | null = initialState.user, action: AuthActions | UserActions): string | null {
    switch (action.type) {
        case requestSelfUserInfo.successType:
            return action.payload.id;
        default:
            return state;
    }
}

function loading(state = initialState.loading, action: AuthActions| UserActions): boolean {
    switch (action.type) {
        case requestSelfUserInfo.successType:
            return false;
        case requestSelfUserInfo.initType:
            return true;
        default:
            return state;
    }
}

function loadingLoginAs(state = initialState.loadingLoginAs, action: AuthActions| UserActions): boolean {
    switch (action.type) {
        case AUTH_LOGIN_AS:
            return true;
        default:
            return state;
    }
}

function userToken(state: string = initialState.userToken, action: AuthActions | UserActions): string | null {
    if(action.type === ACTIVE_TOKEN) return action.payload;
    return state;
}

export const auth = combineReducers({
    selfId,
    loading,
    loadingLoginAs,
    userToken
});
