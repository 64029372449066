import React, { PropsWithChildren } from 'react';
import { Channel } from 'stream-chat';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { SidebarSection } from '../SidebarSection';
import { CreateDirectMessageDialog } from './CreateDirectMessageDialog';
import { Trans } from 'react-i18next';

export interface IDirectMessagesSectionProps {
    conversations: Array<Channel>;
}

export function DirectMessagesSection({
    children,
    conversations,
}: PropsWithChildren<IDirectMessagesSectionProps>) {
    const { isOpened, onOpen, onClose } = useOpenedState();
    return (
        <>
            <SidebarSection
                title={<Trans>Direct Messages</Trans>}
                onAddClick={onOpen}
                allowAdd
            >
                {children}
            </SidebarSection>

            <CreateDirectMessageDialog
                isOpened={isOpened}
                onClose={onClose}
                conversations={conversations}
            />
        </>
    );
}
