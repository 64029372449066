import React, { ReactNode, useCallback, useState } from 'react';
import { Fade, IconButton, Tooltip } from '@material-ui/core';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { useActions } from 'utils/store/useActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { t } from 'i18next';

type CopyButtonProps = {
  textToCopy: string;
  messageCopied?: ReactNode;
  iconCopy?: ReactNode;
}

export const CopyButton = ({ textToCopy, messageCopied, iconCopy = <FileCopyIcon color="inherit"/> }: CopyButtonProps) => {
  const actionCustomReponse = useActions({ 
    showMessage: showToastMessage,
  });
  const [ isCopying, setIsCopying] = useState<boolean>(false);

  const handleCopy = useCallback((e: React.MouseEvent) => {
    if(!textToCopy) return;
    setIsCopying(true)
    e.preventDefault();
    e.stopPropagation();
  
    const message = messageCopied ?? `${t('Copied content')}: "${textToCopy}"`;
    navigator.clipboard.writeText(textToCopy);
    actionCustomReponse.current.showMessage({
        message, options: { variant: ToastVariant.Success, autoHideDuration: 2000 },
    })
    setIsCopying(false)
  },[textToCopy, actionCustomReponse, messageCopied]);

  return (
    <Tooltip title={`${t('Copy')}`} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
      <IconButton aria-label="Copy" onClick={handleCopy} style={{ padding: 0}} disabled={isCopying} >
          {iconCopy}
      </IconButton>
    </Tooltip>
  )
}
