import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import { selectNotificationsCount } from 'store/modules/user/userSelectors';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useConnect } from 'modules/connect/context/ConnectContext';
import { selectIncompleteTasksCount, selectIncompleteUserTasksCount } from 'store/modules/task/taskCommon/taskCommonSelectors';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { selectTaskReviewCounter } from 'store/modules/task/taskReview/taskReviewSelectors';

export function NotificationsCounter({ children }: React.PropsWithChildren<any>) {
    const notificationsCount = useSelector(selectNotificationsCount);
    return (
        <Badge badgeContent={notificationsCount} color="error">
            {children}
        </Badge>
    );
}

export function ConnectNotificationsCounter({ children }: React.PropsWithChildren<any>) {
    const connectContext = useConnect();
    const hasAccess = useSelector(selectHasAppAccess(AppName.Connect));
    const { conversationsService } = connectContext?.connectService || {};
    const { totalUnreadCount } = conversationsService || {};
    const [count, setCount] = useState(conversationsService?.totalUnreadCount.value || 0);

    useEffect(() => {
        if (totalUnreadCount) {
            setCount(totalUnreadCount.value);
        }
        const unsubscribe = totalUnreadCount?.onChange(setCount);
        return () => unsubscribe && unsubscribe();
    }, [totalUnreadCount]);

    const result = hasAccess ? count : 0;

    return (
        <Badge badgeContent={result} color="error">
            {children}
        </Badge>
    );
}

export const TasksCounterBadge: React.FC<{ children?: ReactNode, isFromSwitchTab?: boolean }> = ({ children, isFromSwitchTab = false }) => {
    const incompleteTasksCount = useSelector(selectIncompleteTasksCount);
    const tasksToReviewCount = useSelector(selectTaskReviewCounter);
    const isMultiSiteManager = !useCurrentUser()?.isCompanyLocationStore;
    const badgeContent = isMultiSiteManager ? tasksToReviewCount : incompleteTasksCount;

    return (
        <Badge badgeContent={badgeContent} max={99}
            color="error" style={isFromSwitchTab ? {top: -20} : {}}>
            {children}
        </Badge>
    );
};

export const MyTasksCounterBadge: React.FC<{ children?: ReactNode, isTaskPage?: boolean }> = ({ children, isTaskPage = false }) => {
    const incompleteUserTasksCount = useSelector(selectIncompleteUserTasksCount);
    return (
        <Badge badgeContent={incompleteUserTasksCount} max={99}
            color="error" style={isTaskPage ? {top: -20} : {}}>
            {children}
        </Badge>
    );
};
