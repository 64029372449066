import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { useChatContext } from 'stream-chat-react';
import { StreamChatChannelType } from '../../model';
import { ChannelHeader } from './ChannelHeader';
import { DirectMessageHeader } from './DirectMessageHeader';

const useStyles = makeStyles(() => ({
    channelHeaderRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

export function ChatHeader() {
    const chatContext = useChatContext();
    const classes = useStyles();
    const headerClasses = clsx(classes.channelHeaderRoot, 'str-chat__header-livestream');
    const isChannel = chatContext.channel?.data?.type === StreamChatChannelType.Team;

    return (
        <Box className={headerClasses}>
            {isChannel
                ? <ChannelHeader chatContext={chatContext}/>
                : <DirectMessageHeader chatContext={chatContext} />}
        </Box>
    );
}
