import React, { useEffect, useMemo, useState, useContext, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectParentTasksFetching } from 'store/modules/task/parentTask/parentTaskSelectors';
import { TaskListPageLayout } from '../../components/TaskListPageLayout';
import { ParentTasksGrid } from './grid/ParentTasksGrid';
import { useParentTasksGridData } from './grid/useParentTasksGridData';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useFixedSizeListHeight } from '../../hooks/useFixedSizeListHeight';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { RemoveParentTaskConfimation } from './RemoveParentTaskConfirmation';
import { TaskReviewSlideout } from 'modules/task/taskReview/TaskReviewSlideout';
import { useQueryParams } from 'utils/hooks';
import { generatePath, useHistory } from 'react-router';
import { Routes } from 'components/router/model';
import { taskReviewModalOpen } from 'store/modules/task/taskReview/taskReviewActions';
import { useActions } from "utils/store/useActions";
import { TaskQueryParams } from 'modules/task/common/model';
import { ParentTaskStatusFilter } from 'api/task/parentTask/parentTaskModel';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { SearchTasks } from '../../components/SearchTasks';
import { TasksFilterContext } from '../../context/taskFilterContext';
import { TaskGallerySlideout } from 'modules/task/taskGallery/TaskGallerySlideout';
import { parentTaskGalleryModal, taskGalleryModalOpen } from 'store/modules/task/taskGallery/taskGalleryActions';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { AutomationTasksGrid } from '../../components/AutomationTaskGrid';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { TaskFilterContainer } from 'modules/task/common/taskFilter/TaskFilterContainer';
import { TasksFilterType } from 'modules/task/common/taskFilter/TypeTaskFilterMap';

export const GlobalTasksView: React.FC = () => {
    const isTasksSearchEnabled = useFeatureState(FeatureFlags.TasksSearch);
    const data = useParentTasksGridData();
    const [searchTasks, setSearchTasks] = useState<string>('');
    const areParentTasksFetching = useSelector(selectParentTasksFetching);
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();
    const currentUser = useCurrentUser();
    const { openTasksToReview, taskIdGallery } = useQueryParams<TaskQueryParams>();
    const history = useHistory();
    const actions = useActions({
        openModal: taskReviewModalOpen,
        openGalleryModal: taskGalleryModalOpen,
        parentTaskGallery: parentTaskGalleryModal,
    });
    const {
        valuesFiltersTasks,
        showTableTask
    } = useContext(TasksFilterContext);

    const header = useMemo(() => (
        <TaskFilterContainer taskFiltertype={TasksFilterType.ParentTasks} locationId={currentUser?.companyLocationId ?? ''}>
            {isTasksSearchEnabled && <SearchTasks 
                onSearchTaskValuesChange={setSearchTasks}
            />}
        </TaskFilterContainer>
    ), [isTasksSearchEnabled, currentUser]);

    useEffect(() => {
        if (openTasksToReview) {
            actions.current.openModal();
            history.replace(generatePath(Routes.TaskList));
        }
    }, [actions, history, openTasksToReview]);

    useLayoutEffect(()=>{
        if(taskIdGallery){
            actions.current.parentTaskGallery(taskIdGallery);
            actions.current.openGalleryModal();
        }
    }, [actions, taskIdGallery]);

    return (
        <>
            <TaskListPageLayout
                header={<>
                    {header}
                </>}
                layoutHeaderRef={layoutHeaderRef}
                showCreateButton={canCreateTask}
            >
                { showTableTask &&
                <>
                    { 
                        valuesFiltersTasks?.tasksViewMode !== TasksViewModeMyTasks ?
                            <ParentTasksGrid
                                data={data}
                                isFetching={areParentTasksFetching}
                                filter={valuesFiltersTasks.filter as ParentTaskStatusFilter | undefined}
                                filterTag={valuesFiltersTasks.tags}
                                filterCreatedBy={valuesFiltersTasks.createdBy}
                                height={gridHeight}
                                searchTerm={searchTasks}
                                tasksViewMode={valuesFiltersTasks.tasksViewMode}
                            />
                            :<>
                                <AutomationTasksGrid
                                    filter={valuesFiltersTasks.filter as AutomationTaskStatusFilter | undefined}
                                    filterTag={valuesFiltersTasks.tags}
                                    height={gridHeight}
                                    searchTerm={searchTasks}
                                />
                                <StoreTaskView />
                            </>
                    }
                </>
                } 
            </TaskListPageLayout>

            <TaskReviewSlideout />
            <TaskGallerySlideout />
            <RemoveParentTaskConfimation />
        </>
    );
};
