import React from 'react';
import {
    Box, ButtonBase, Drawer, DrawerProps, Theme, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breakpoints } from 'utils/styles/constants';
import { ThemeProviderSlidebar } from 'components/sidebar/ThemeProviderSlidebar';
import { Close } from '@material-ui/icons';

export const useSidebarStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'linear-gradient(180deg, rgba(35, 62, 94, 0.5) 0%, rgba(253, 220, 218, 0.5) 100%)',
    },
    paper: {
        backgroundColor: theme.palette.primary.dark,
        borderRadius: theme.spacing(4, 0, 0, 4),
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        width: theme.spacing(45),
        height: '100%',
        padding: theme.spacing(3, 2),
        overflow: 'hidden',
        boxSizing: 'border-box',

        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            width: '95%',
        },
    },
    title: {
        maxWidth: '90%',
        wordBreak: 'break-all',
    },
}));

export interface ISidebarProps extends Pick<DrawerProps, 'anchor' | 'open'> {
    title?: string | React.ReactNode;
    onClose: () => void;
    children: React.ReactNode;
}

export function Sidebar({ children, open, onClose, title, anchor = 'right' }: ISidebarProps) {
    const classes = useSidebarStyles([]);
    return (
        <Drawer anchor={anchor} open={open}
            onClose={onClose}
            classes={{ root: classes.root, paper: classes.paper }}
        >
            <ThemeProviderSlidebar>
                <Box display="flex" justifyContent="space-between"
                    pb={2}
                >
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <ButtonBase onClick={onClose}>
                        <Close/>
                    </ButtonBase>
                </Box>
                {children}
            </ThemeProviderSlidebar>
        </Drawer>
    );
}
