import { Box, styled } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';

export const ListPageLayout = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3, 4),

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: 0,
    },
}));
