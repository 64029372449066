import { useEffect, useState } from 'react';

const INTERVAL_MS = 1000;

export function useClientIdleTimer() {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => setSeconds(value => value + 1), INTERVAL_MS);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handler = () => setSeconds(0);
        window.addEventListener('mousemove', handler);
        window.addEventListener('touchstart', handler);
        window.addEventListener('keydown', handler);
        return () => {
            window.removeEventListener('mousemove', handler);
            window.removeEventListener('touchstart', handler);
            window.removeEventListener('keydown', handler);
        };
    }, []);

    return seconds;
}
