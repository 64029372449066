import { IDescribedEntityError, IEntitySuccess } from 'model/entity';
import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { ParentTaskDeleteRequest, StoreTasksDeleteError, StoreTasksDeleteSuccess } from './parentTaskModel';

const PARENT_TASK_DELETE = 'task/parent/DELETE';
export const requestDeleteParentTask = (
    createApiActionsByName<ParentTaskDeleteRequest, IEntitySuccess & {isFromStoreTask?: boolean}, IDescribedEntityError & {isFromStoreTask?: boolean}, typeof PARENT_TASK_DELETE>(
        PARENT_TASK_DELETE,
    )
);

const REQUEST_STORE_TASKS_DELETE = 'request/store/tasks/DELETE';
const RESPONSE_STORE_TASKS_DELETE_SUCCESS = 'response/store/tasks/DELETE_SUCCESS';
const RESPONSE_STORE_TASKS_DELETE_ERROR = 'response/store/tasks/DELETE_ERROR';

export const requestStoreTasksDelete = (
    createApiActions<string, StoreTasksDeleteSuccess, StoreTasksDeleteError,
        typeof REQUEST_STORE_TASKS_DELETE,
        typeof RESPONSE_STORE_TASKS_DELETE_SUCCESS,
        typeof RESPONSE_STORE_TASKS_DELETE_ERROR
    >(
        REQUEST_STORE_TASKS_DELETE,
        RESPONSE_STORE_TASKS_DELETE_SUCCESS,
        RESPONSE_STORE_TASKS_DELETE_ERROR,
    )
);
export type ParentTaskActions = (
    | ActionsReturnTypes<typeof requestDeleteParentTask>
    | ActionsReturnTypes<typeof requestStoreTasksDelete>    
);
