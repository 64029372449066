import * as t from 'io-ts';
import { attachmentSchema } from '../prototype/prototypeModel';
import { storeTaskStatus, taskStatusSchema } from '../common/taskCommonModel';
import { fromEnum } from 'utils/helpers/fromEnum';

export const taskGallerySchema = t.intersection([
    storeTaskStatus,
    taskStatusSchema,
    t.type({
        locationId: t.string,
        locationName: t.string,
        dueDate: t.string,
        id: t.string,
        attachments: t.array(attachmentSchema),
        parentLocationName: t.string,
    })
]);

export enum TaskGalleryFilter {
    Init = 'init',
    Reset = 'reset',
    Active = 'active'
}

const tasksGalleryFilterSchema = t.type({
    status: fromEnum('TaskGalleryFilter', TaskGalleryFilter), 
    task: t.union([ taskGallerySchema, t.null])
});

export type TaskGalleryList = Array<TaskGalleryModel>;
export type TaskGalleryModel = t.TypeOf<typeof taskGallerySchema>;
export type TaskGalleryFilterModel = t.TypeOf<typeof tasksGalleryFilterSchema>;
