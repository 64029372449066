import { Box, styled } from "@material-ui/core";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { CellProps } from "react-table";
import { selectLocationsByIds, selectLocationsByParentId } from "store/modules/hierarchy/hierarchySelectors";
import { Location } from "api/hierarchy/hierarchyModel";
import { ExpandableLocation } from './ExpandableLocation';
import { StoreLocation } from './StoreLocation';

export type LocationCellProps = CellProps<Location, string>;

const StyledLocationCell = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

export const LocationCell = ({ row, toggleRowExpanded }: LocationCellProps) => {
    const { id: locationId } = row.original;
    const location = useSelector(selectLocationsByIds)[locationId];
    const childLocations = useSelector(selectLocationsByParentId)[locationId];
    const isExpandable = !!childLocations;

    const toggleRow = useCallback(() => {
        toggleRowExpanded([row.id]);
    }, [row, toggleRowExpanded]);

    if (!location || !locationId) {
        return <></>;
    }

    return (
        <StyledLocationCell ml={row.depth * 3}>
            {isExpandable && (
                <ExpandableLocation
                    location={location}
                    isExpanded={row.isExpanded}
                    toggleExpanded={toggleRow}
                />
            )}
            {!isExpandable && (
                <StoreLocation location={location} />
            )}
        </StyledLocationCell>
    );
};
