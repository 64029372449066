import { lighten, styled, Typography, TypographyProps } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';

export type StyledDateTimeWrapperProps = TypographyProps & {
    textcolor: 'primary' | 'secondary';
};

export const StyledDateTimeWrapper = styled(Typography)({
    fontFamily: `'IBM Plex Sans', 'Arial', sans-serif`,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    color: ({ textcolor }: StyledDateTimeWrapperProps) => textcolor === 'primary' ? UtilColors.Black : lighten(UtilColors.Black, .5),
});
