import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { MutableRefObject, useMemo, useRef } from 'react';


export function useActions<T extends ActionCreatorsMapObject>(rawActions: T): MutableRefObject<T> {
    const dispatch = useDispatch();

    // Raw actions should not change during the execution
    // However, new object is created on each render
    //eslint-disable-next-line react-hooks/exhaustive-deps
    const actions = useMemo(() => bindActionCreators(rawActions, dispatch), [dispatch]);

    return useRef(actions);
}
