/* eslint-disable @typescript-eslint/naming-convention */
import { ClientService } from './ClientService';
import { ConversationsService } from './ConversationsService';
import { EventService } from './EventService';
import { UserData } from './types';

export class ConnectService {
    private _initializing = false;
    private _clientService: ClientService | null = null;
    private _conversationsService: ConversationsService | null = null;
    private _eventsService: EventService | null = null;

    public get isInitialized(): boolean {
        return (
            this._clientService !== null
            && this._clientService.isConnected
            && this._conversationsService !== null
            && !this._initializing);
    }

    public get isInitializing(): boolean {
        return this._initializing;
    }

    public get clientService() {
        return this._clientService;
    }

    public get conversationsService() {
        return this._conversationsService;
    }

    public async initialize(userData: UserData, userToken: string) {
        if (this.isInitialized || this._initializing) return;
        this._initializing = true;
        this._clientService = new ClientService();
        await this._clientService.connect(userData, userToken);
        this._conversationsService = new ConversationsService(this._clientService);
        this._eventsService = new EventService(this._clientService, this._conversationsService);
        this._eventsService.initialize();
        this._initializing = false;
        this.conversationsService?.refreshChannels();
        this.conversationsService?.refreshChats();
    }

    public async dispose() {
        this._eventsService?.dispose();
        this._eventsService = null;
        this._conversationsService?.dispose();
        this._conversationsService = null;
        await this._clientService?.disconnect();
        this._clientService = null;
    }
}
