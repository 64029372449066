import { combineReducers } from 'redux';
import { AppsType } from 'api/apps/appsModel';
import { AppsActions, requestAppsGet } from 'api/apps/appsActions';
import { ItemsById } from 'model/entity';
import { itemsByCustomIds } from 'utils/helpers/itemsByIds';

const initialState = {
    appsListByName: {},
    appListLoading: true,
};

function appListLoading(state = initialState.appListLoading, action: AppsActions): boolean {
    switch (action.type) {
        case requestAppsGet.successType:
        case requestAppsGet.errorType:
            return false;
        case requestAppsGet.initType:
            return true;
        default:
            return state;
    }
}

function appsListByName(
    state: ItemsById<AppsType> = initialState.appsListByName,
    action: AppsActions,
): ItemsById<AppsType> {
    switch (action.type) {
        case requestAppsGet.successType:
            return itemsByCustomIds(action.payload, 'AppName');
        default:
            return state;
    }
}

export const apps = combineReducers({
    appsListByName,
    appListLoading,
});
