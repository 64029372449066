import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { TitleExpiredDate } from './TitleExpiredDate';
import { ApprovalMark } from '../ApprovalMark';

interface ITitleForm {
    isApproval: boolean;
    showHourGlass?: boolean | null,
    title: string | ReactNode,
    tagList?: ReactNode
}

export const TitleForm = React.forwardRef<HTMLDivElement, ITitleForm>(({ title, showHourGlass =  false, isApproval, tagList}, ref) =>
    (
        <Box mb={3}>
            <Typography
                variant="h3"
            >
                <TitleExpiredDate title={title} showHourGlass={showHourGlass} tagList={tagList}/>
            </Typography>
            {isApproval && <ApprovalMark>* <Trans>Requires Approval</Trans></ApprovalMark>}
        </Box>
    )
)
