import { TaskTagType } from 'api/task/tag/taskTagModel';
import { ICalendarTaskRowData, TaskType } from '../taskList/views/CalendarTasksView/calendar/model';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { Views } from 'react-big-calendar';

export interface IEventCalendar extends ICalendarTaskRowData {
  taskId: string,
  tag?: null | TaskTagType;
  prototypeId?: string;
  taskType: TaskType;
  displayedStatus: DisplayedTaskStatus;
  storeTasksCount: number;
  canEdit?: boolean;
}

export interface Time {
  nonWorkingTime: boolean,
  time: string
}

export const langCalendar: any = {
  en: { previous: 'Previous' },
  es: {
    week: 'Semana',
    work_week: 'Semana de trabajo',
    day: 'Día',
    month: 'Mes',
    previous: 'Atrás',
    next: 'Después',
    today: 'Hoy',
    agenda: 'El Diario',

    showMore: (total: number) => `+${total} más`,
  },
  fr: {
    week: 'La semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    month: 'Mois',
    previous: 'Antérieur',
    next: 'Prochain',
    today: `Aujourd'hui`,
    agenda: 'Ordre du jour',

    showMore: (total: number) => `+${total} plus`,
  },
};

export const DefaultViews = [Views.MONTH, Views.WEEK, Views.DAY];
