import { SimpleTaskListHeader } from 'modules/task/taskList/components/SimpleTaskListHeader';
import { ButtonSwitchType } from 'modules/task/taskList/components/ViewSwitch/TypeViewSwithMap';
import { ViewSwitch } from 'modules/task/taskList/components/ViewSwitch/ViewSwitch';
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { allTasksSyntheticValue, getAutomationTaskFilterOptions } from 'modules/task/taskList/components/model';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { useActions } from 'utils/store/useActions';
import { selectCalendarFilterStatus } from 'store/modules/task/calendarFilter/calendarFilterSelectors';
import { calendarFilterStatus } from 'store/modules/task/calendarFilter/calendarFilterTaskActions';
import { TasksFilterComponentProps } from '../../TypeTaskFilterMap';
import { useTaskFilterStyles } from '../../taskFilterStyles';
import { FlexBoxContainer } from '../common/FlexBoxContainer';
import { FilterButton } from '../common/FilterButton';
import { FlexBox } from 'modules/shared/components/FlexBox';

export const CalendarHeader: React.FC<TasksFilterComponentProps & { handleChangeCollapse: () => void, collapsed: boolean }> = ({ locationId, handleChangeCollapse, collapsed }) => {
  const location = useSelector(selectLocationsByIds)[locationId];
  const canAccessTask = useSelector(selectHasAppAccess(AppName.Tasks));
  const classes = useTaskFilterStyles();
  const options = useMemo(() => (getAutomationTaskFilterOptions()), []);
  const typeTasksAppAccess = canAccessTask ? ButtonSwitchType.ParentTasks : ButtonSwitchType.NoAccessTaskPage;
  const filterStatus = useSelector(selectCalendarFilterStatus);
  const actions = useActions({ calendarFilterStatus });

  const handleSetFilterStatus = useCallback((newValue?: AutomationTaskStatusFilter) => {
    actions.current.calendarFilterStatus(newValue ?? null)
  },[actions])

  return (
    <FlexBoxContainer flexDirection='row' flexWrap='wrap' alignItems='center' className={classes.taskContainer}>
      <SimpleTaskListHeader regionName={location?.name} showFullRegionName={false} />
      <ViewSwitch type={typeTasksAppAccess} />
      <OptionsDropdown
        className={classes.statusFilter}
        selectClasses={{ select: classes.select }}
        unsetValue={allTasksSyntheticValue}
        options={options}
        value={filterStatus ?? undefined}
        onValueChange={handleSetFilterStatus}
      />
      <FlexBox className={classes.containerLeft}>
        <FilterButton onChange={handleChangeCollapse} checked={collapsed}/>
      </FlexBox>
    </FlexBoxContainer>
  )
}
