import { TOKENS_REFRESH_SUCCESS } from 'api/auth/authActions';
import { requestIncompleteTasksCount, requestIncompleteUserTasksCount } from 'api/task/common/taskCommonActions';
import { tryGetLivingTokens } from 'modules/auth/storage';
import { selectUser } from 'store/modules/auth/authSelectors';
import { put, take, delay, takeLatest, select } from 'typed-redux-saga';
import {
    INCOMPLETE_TASKS_COUNT_REQUEST_ERROR_INTERVAL_STEP_MS,
    INCOMPLETE_TASKS_COUNT_REQUEST_INTERVAL_MS,
} from './taskCommonConstants';

let requestIntervalOnErrorMs = INCOMPLETE_TASKS_COUNT_REQUEST_ERROR_INTERVAL_STEP_MS;

function* tryGetIncompleteTasksCount() {
    const currentUser = yield* select(selectUser);
    if (currentUser?.id && currentUser.isCompanyLocationStore) {
        const { accessToken, refreshToken } = yield tryGetLivingTokens();
        if (accessToken && refreshToken) {
            yield* put(requestIncompleteTasksCount.init());
            yield* put(requestIncompleteUserTasksCount.init());
            
        } else {
            yield* take(TOKENS_REFRESH_SUCCESS);
            yield* put(requestIncompleteTasksCount.init());
            yield* put(requestIncompleteUserTasksCount.init());
        }
    }
}

function* incompleteTasksCountSuccessWatcher() {
    yield* takeLatest(
        requestIncompleteTasksCount.successType,
        function*() {
            requestIntervalOnErrorMs = INCOMPLETE_TASKS_COUNT_REQUEST_ERROR_INTERVAL_STEP_MS;
            yield* delay(INCOMPLETE_TASKS_COUNT_REQUEST_INTERVAL_MS);
            yield* tryGetIncompleteTasksCount();
        },
    );
}

function* incompleteTasksCountErrorWatcher() {
    yield* takeLatest(
        requestIncompleteTasksCount.errorType,
        function* () {
            yield* delay(requestIntervalOnErrorMs);
            requestIntervalOnErrorMs *= 2;
            yield* tryGetIncompleteTasksCount();
        },
    );
}

export const taskCommonSagas = [
    incompleteTasksCountSuccessWatcher,
    incompleteTasksCountErrorWatcher,
];
