import React from 'react';
import { CellProps } from 'react-table';
import { Skeleton } from '@material-ui/lab';
import { IHasCompletionState, ISkeleton } from '../../model';
import { UtilColors } from 'utils/styles/constants';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { formatDate, StyledDateCellWrapper } from './DateCell';

type DueDateCellData = ISkeleton & IHasCompletionState;

export const DueDateCell: React.FC<CellProps<DueDateCellData, string>> = props => {
    if (props.row.original.isSkeleton) {
        return <Skeleton width="75px" />;
    }
    const color: string = (
        props.row.original.completionState === DisplayedTaskStatus.Overdue
            ? UtilColors.Red2
            : UtilColors.TextPrimary
    );
    return (
        <StyledDateCellWrapper color={color}>
            {formatDate(props.value)}
        </StyledDateCellWrapper>
    );
};
