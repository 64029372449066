import React from 'react';
import { IRouteConfiguration } from 'components/router/model';
import { Route } from 'react-router';
import { withMainContent } from 'components/mainContent/withMainContent';
import { withPermissionsCheck } from './hoc/withPermissionsCheck';
import { withFeatureSwitch } from './hoc/withFeatureSwitch';

export const getRouteComponent = (route: IRouteConfiguration) => route.legacy || route.withMain === false
    ? route.component
    : withMainContent(route.component);

export function mapRoutes(route: IRouteConfiguration) {
    let routeComponent = getRouteComponent(route);

    if (route.appName) {
        routeComponent = withPermissionsCheck(route.appName)(routeComponent);
    }

    if (route.featureFlag) {
        routeComponent = withFeatureSwitch(route.featureFlag)(routeComponent);
    }

    return (
        <Route
            key={route.path}
            component={routeComponent}
            path={route.path}
            exact={route.exact}
        />
    );
}
