import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { BlueColor } from 'utils/styles/constants';

export const useLegacySubRoutesStyles = makeStyles((theme: Theme) => ({
    tabsIndicator: {
        backgroundColor: theme.palette.primary.dark,
    },
    tabsRoot: {
        backgroundColor: '#fff',
        color: BlueColor.BluePrimaryMain
    },
    tab: {
        '&&': {
            color: theme.palette.primary.dark,
        },
    },
    componentWrapper: {
        display: 'flex',
        flexGrow: 1,
    },
}));
