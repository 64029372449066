import React, { useMemo } from 'react';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { TaskListPageLayout } from '../../components/TaskListPageLayout';
import { useFixedSizeListHeight } from '../../hooks/useFixedSizeListHeight';
import { useCalendarTasksData } from './calendar/useCalendarTasksData';
import { CalendarTasks } from './calendar/CalendarTasks';
import { TaskByLocationsSideOut } from './TaskByLocationsSideOut';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { CreateEventButton } from '../../components/CreateEventButton';
import { RemoveCalendarEventConfimation } from 'modules/task/taskCalendar/RemoveCalendarEventConfirmation';
import { EventSideOut } from 'modules/task/taskCalendar/eventSideOut/EventSideOut';

import { TaskFilterContainer } from 'modules/task/common/taskFilter/TaskFilterContainer';
import { TasksFilterType } from 'modules/task/common/taskFilter/TypeTaskFilterMap';

export type StoreTasksViewParams = {
    locationId: string;
};

export const CalendarTasksView = ({ locationId }: StoreTasksViewParams) => {
    const data = useCalendarTasksData();
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const { isCompanyLocationStore = true } = useCurrentUser() || {};
    const { gridHeight, layoutHeaderRef } = useFixedSizeListHeight();

    const header = useMemo(() => {
        const filterType = isCompanyLocationStore ? TasksFilterType.CalendarStoreTasks : TasksFilterType.CalendarParentTasks;
        return (
            <TaskFilterContainer
                taskFiltertype={filterType}
                locationId={locationId}
            />
        )
    }, [locationId, isCompanyLocationStore]);

    return (
        <>
            <TaskListPageLayout
                header={header}
                layoutHeaderRef={layoutHeaderRef}
                showCreateButton={canCreateTask}
                createButton={<CreateEventButton/>}
            >
                <CalendarTasks 
                    data={data}
                    height={gridHeight}
                />
                <StoreTaskView />
                <TaskByLocationsSideOut />
                <EventSideOut />
            </TaskListPageLayout>
            <RemoveCalendarEventConfimation />
        </>
    );
};
