import { IStoreState } from 'store/rootReducer';

export const selectStoreTaskModalActiveId = (state: IStoreState) => state.task.storeTask.storeTaskModalActiveId;
export const selectChecklistItemsLoading = (state: IStoreState) => state.task.storeTask.checklistItemsLoading;
export const selectStoreTasksOrder = (state: IStoreState) => state.task.storeTask.storeTasksOrder;
export const selectStoreTasksById = (state: IStoreState) => state.task.storeTask.storeTasksById;
export const selectStoreTasksFetching = (state: IStoreState) => state.task.storeTask.storeTasksFetching;
export const selectStoreTasksNextPageToken = (state: IStoreState) => state.task.storeTask.storeTasksNextPageToken;
export const selectStoreTaskAttachmentsRemoving = (state: IStoreState) =>
    state.task.storeTask.storeTaskAttachmentsRemoving;
export const selectStoreTaskAttachmentsUploading = (state: IStoreState) =>
    state.task.storeTask.storeTaskAttachmentsUploading;
export const selectUpdatingStoreTasks = (state: IStoreState) => state.task.storeTask.updatingStoreTasks;
export const selectApprovableByUserTasks = (state: IStoreState) => state.task.storeTask.approvableByUserStoreTasks;
export const selectStoreTaskDetailsById = (state: IStoreState) => state.task.storeTask.storeTaskDetailsById;
export const selectStoreTaskParentIdsById = (state: IStoreState) => state.task.storeTask.storeTaskParentIdsById;
export const selectStoreTaskParentIdsLoadingById = (state: IStoreState) =>
    state.task.storeTask.storeTaskParentIdsLoadingById;
export const selectStoreTaskCustomResponseFetchingById = (state: IStoreState) =>
        state.task.storeTask.storeTaskCustomResponseFetchingById;
