import { IResponseErrorPayload } from 'model/error';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { TaskGalleryList } from './taskGalleryModel';

const TASK_LIST_FOR_REVIEW = 'task/gallery/GET_LIST';
export const requestTaskGallery = createApiActionsByName<
    string, TaskGalleryList, IResponseErrorPayload, typeof TASK_LIST_FOR_REVIEW
>(
    TASK_LIST_FOR_REVIEW,
);

export type TaskGalleryApiActions =
    | ActionsReturnTypes<typeof requestTaskGallery>;
