import { useCallback } from 'react';
import { Box, ButtonBase, styled } from '@material-ui/core';
import { Location } from "api/hierarchy/hierarchyModel";
import { storeTasksModalOpen } from 'store/modules/task/storeTasksModal/storeTasksModalActions';
import { useActions } from 'utils/store/useActions';

// this is to mimic space obtained by ButtonBase
const Spacer = styled(Box)(({ theme }) => ({
    paddingRight: theme.spacing(3),
    marginRight: theme.spacing(0.5),
}));

export type StoreLocationProps = {
    location: Location;
};

export const StoreLocation = ({ location }: StoreLocationProps) => {
    const actions = useActions({ open: storeTasksModalOpen });

    const open = useCallback(() => {
        actions.current.open(location.id);
    }, [actions, location]);

    return (
        <>
            <Spacer />
            <ButtonBase onClick={open} style={{textAlign: "left"}}>{location.name}</ButtonBase>
        </>
    );
};
