import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectStoreTasksModalLocationId } from 'store/modules/task/storeTasksModal/storeTasksModalSelectors';
import { storeTasksModalClose } from 'store/modules/task/storeTasksModal/storeTasksModalActions';
import { useActions } from "utils/store/useActions";
import { TaskSlideout } from '../../../common/TaskSlideout';
import { SimpleTaskListHeader } from '../../components/SimpleTaskListHeader';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { StoreTasksGrid } from '../StoreTasksView/grid/StoreTasksGrid';
import { selectStoreTasksFetching } from 'store/modules/task/storeTask/storeTaskSelectors';
import { useStoreTasksGridData } from '../StoreTasksView/grid/useStoreTasksGridData';
import { CreateTaskButton } from '../../components/CreateTaskButton';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { Box, styled, makeStyles, Theme } from '@material-ui/core';
import { StoreTaskStatusFilter } from 'api/task/storeTask/storeTaskModel';
import { TABLE_HEAD_HEIGHT } from 'modules/shared/components/StyledTable';
import { useElementHeight } from 'modules/shared/hooks';
import { allTasksSyntheticValue, getStoreTaskFilterOptions } from '../../components/model';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';

type StoreTasksSlideoutViewProps = {
    locationId: string;
    filter?: StoreTaskStatusFilter;
    className?: string;
};

const StyledStoreTasksContainer = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));

const useStyles = makeStyles((theme: Theme) => ({
    createButton: {
        right: theme.spacing(4),
    },
    statusFilter: {
        width: '100%',
        maxWidth: '300px',
        marginBottom: theme.spacing(1),
    },
    gridContainer: {
        flex: '1 1 auto',
    },
}));

const StoreTasksSlideoutView = ({ locationId, filter, className }: StoreTasksSlideoutViewProps) => {
    const areStoreTasksFetching = useSelector(selectStoreTasksFetching);
    const data = useStoreTasksGridData();
    const [containerRef, containerHeight] = useElementHeight();

    return (
        /* @ts-ignore - ref is actually presented as property of this container */
        <Box ref={containerRef} className={className}>
            <StoreTasksGrid
                locationId={locationId}
                filter={filter}
                data={data}
                isFetching={areStoreTasksFetching}
                height={containerHeight - TABLE_HEAD_HEIGHT}
            />
        </Box>
    );
};

const DefaultStatusFilter: StoreTaskStatusFilter = StoreTaskStatusFilter.Incomplete;

export function StoreTasksSlideout() {
    const classes = useStyles();
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isExpirableColumnsFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.TaksExpirable];
    const options = useMemo(() => {
        return getStoreTaskFilterOptions(isOverdueColumnsFeature?.enabled ?? false, isExpirableColumnsFeatureEnabled?.enabled ?? false)
    }, [isOverdueColumnsFeature?.enabled, isExpirableColumnsFeatureEnabled?.enabled]);
    const storeLocationId = useSelector(selectStoreTasksModalLocationId);
    const storeLocation = useSelector(selectLocationsByIds)[storeLocationId || ''];
    const actions = useActions({ close: storeTasksModalClose });
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const [filter, setFilter] = useState<StoreTaskStatusFilter | undefined>(DefaultStatusFilter);

    const onClose = useCallback(() => {
        actions.current.close();

        setFilter(DefaultStatusFilter);
    }, [actions]);

    return (
        <TaskSlideout
            open={!!storeLocationId}
            onClose={onClose}
            header={<SimpleTaskListHeader regionName={storeLocation?.name} />}
            flex
        >
            {storeLocationId && (
                <StyledStoreTasksContainer>
                    <OptionsDropdown
                        className={classes.statusFilter}
                        unsetValue={allTasksSyntheticValue}
                        options={options}
                        value={filter}
                        onValueChange={setFilter}
                    />
                    <StoreTasksSlideoutView
                        className={classes.gridContainer}
                        locationId={storeLocationId}
                        filter={filter}
                    />
                    {canCreateTask && (
                        <CreateTaskButton className={classes.createButton} />
                    )}
                </StyledStoreTasksContainer>
            )}
        </TaskSlideout>
    );
}
