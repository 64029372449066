import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { IInputProps } from "components/basicInputs/model";
import { useField } from "formik";
import { TASK_DATE_FORMAT_YYYYMMDD } from "modules/task/common/form/validation";
import { DateInput } from "../dateInput/DateInput";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { isBeforeDate, todayMoment } from "utils/helpers/dateHelper";
import { useDateRangeInputStyles } from "./DateRangeInputStyles";

interface IDateRangeInputProps extends IInputProps {
    disabled?: boolean;
    clearable?: boolean;
    valueFormat?: string;
    nameStart?: string;
    nameEnd: string;
    onSelectDate?: () => void
}

export function DateRangeInput({
    name,
    nameStart = name,
    nameEnd,
    label,
    valueFormat = TASK_DATE_FORMAT_YYYYMMDD,
    disabled = false,
    onSelectDate
}: IDateRangeInputProps) {
    const [fieldStart] = useField(nameStart);
    const [fieldEnd] = useField(nameEnd);
    const clasess = useDateRangeInputStyles([])

    const [updateValue, setUpdateValue] = useState('')

    useEffect(() => {
        if(fieldEnd?.value !== '' && isBeforeDate(fieldStart?.value,fieldEnd?.value)){
            setUpdateValue(fieldStart?.value)
        }
    }, [fieldStart, fieldEnd]);

    return (
        <Box className={clasess.container}>
            <Box  className={clasess.title}>
                <Typography variant="caption">{label}</Typography>
            </Box>
            <Box
                flexWrap="wrap"
                display="flex"
                alignContent="center"
                alignItems="center"
            >
                <Box className={clasess.containerInput}>
                    <DateInput
                        name={nameStart}
                        label=""
                        valueFormat={valueFormat}
                        disabled={disabled}
                        maxDate={todayMoment()}
                        onSelectDate={onSelectDate}
                    />
                </Box>
                <ArrowForwardIcon color="primary"/>
                <Box className={clasess.containerInput}>
                    <DateInput
                        name={nameEnd}
                        label=""
                        valueFormat={valueFormat}
                        minDate={fieldStart?.value || ""}
                        disabled={!fieldStart?.value}
                        updateValue={updateValue}
                        onSelectDate={onSelectDate}
                        maxDate={todayMoment()}
                    />
                </Box>
            </Box>
        </Box>
    );
}
