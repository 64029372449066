import React from 'react';
import { getUserLabel, renderOption, useQueryUsers } from 'modules/connect/messenger/hooks/useQueryUsers';
import { Autocomplete } from '@material-ui/lab';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: theme.spacing(40),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            width: '70vw',
        },
    },
}));

export interface ISelectUsersProps {
    helpers: ReturnType<typeof useQueryUsers>;
}

export function SelectUsers({ helpers }: ISelectUsersProps) {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Autocomplete
                fullWidth
                multiple
                options={helpers.foundUsers}
                getOptionLabel={getUserLabel}
                renderOption={renderOption}
                renderInput={helpers.renderInput}
                onChange={helpers.handleSelectorChange}
                autoHighlight
                noOptionsText={<Trans>No variants for current input</Trans>}
                onClose={() => helpers.setFoundUsers([])}
            />
        </Box>
    );
}
