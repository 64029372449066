import { TaskGalleryFilterModel } from 'api/task/taskGallery/taskGalleryModel';
import { createSingleAction } from 'utils/store/actionUtils';

export const TASK_GALLERY_MODAL_OPEN = 'task/gallery/MODAL_OPEN';
export const TASK_GALLERY_MODAL_CLOSE = 'task/gallery/MODAL_CLOSE';

export const taskGalleryModalOpen = createSingleAction<void, typeof TASK_GALLERY_MODAL_OPEN>(
    TASK_GALLERY_MODAL_OPEN,
);

export const taskGalleryModalClose = createSingleAction<void, typeof TASK_GALLERY_MODAL_CLOSE>(
    TASK_GALLERY_MODAL_CLOSE,
);

export const TASK_GALLERY_MODAL_BY_PARENT_TASK = 'task/gallery/MODAL_BY_PARENT_TASK';
export const TASK_GALLERY_MODAL_BY_PARENT_TASK_RESET = 'task/gallery/MODAL_BY_PARENT_TASK_RESET';

export const parentTaskGalleryModal = createSingleAction<string, typeof TASK_GALLERY_MODAL_BY_PARENT_TASK>(
    TASK_GALLERY_MODAL_BY_PARENT_TASK,
);

export const parentTaskGalleryModalReset = createSingleAction<void, typeof TASK_GALLERY_MODAL_BY_PARENT_TASK_RESET>(
    TASK_GALLERY_MODAL_BY_PARENT_TASK_RESET,
);

export const TASK_GALLERY_FILTER = 'task/gallery/TASK_GALLERY_FILTER';
export const filterTaskGallery = createSingleAction<TaskGalleryFilterModel, typeof TASK_GALLERY_FILTER>(
    TASK_GALLERY_FILTER,
);

export type TaskGalleryAction =
    | ReturnType<typeof taskGalleryModalOpen>
    | ReturnType<typeof taskGalleryModalClose>
    | ReturnType<typeof parentTaskGalleryModal>
    | ReturnType<typeof parentTaskGalleryModalReset>
    | ReturnType<typeof filterTaskGallery>;
