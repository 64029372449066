import { EventDescription } from "./EventDescription";

export type TaskDeclinedEventBodyProps = {
    reason: string;
}

export const TaskDeclinedEventBody = ({ reason }: TaskDeclinedEventBodyProps) => {
    return (
        <>
            {reason.split(/\r?\n/i).map((chunk, i) => (
                <EventDescription key={i}>{chunk}</EventDescription>
            ))}
        </>
    )
};
