import React, { useMemo } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AttachmentsGallery } from 'modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import ReactQuill from 'react-quill';
import { convertMarkdowntoHTML } from 'modules/connect/messenger/utils/customTextEditor';
import { TaskSection } from 'modules/task/singleTask/components/TaskSection';
import { useSelector } from 'react-redux';
import { selectCalendarEventDetail } from 'store/modules/task/calendarTask/calendarSelectors';
import { TaskActivity } from 'modules/task/singleTask/components/taskActivity/TaskActivity';

const useEventContentStyles = makeStyles((theme: Theme) => ({
    descriptionBlock: {
        '& .ql-container': {
            border: 'none',
        },
        '& .ql-editor': {
            padding: 9,
            fontSize: 16
        }
    },
}));

export const EventContent: React.FC = () => {
    const classes = useEventContentStyles([]);
    const event = useSelector(selectCalendarEventDetail);
    const eventDescription = useMemo(() => {
        const htmlDescription = event?.description
            ? convertMarkdowntoHTML(event?.description)
            : '';
        return htmlDescription;
    }, [event]);

    const eventAttchmentsCount = event?.attachments?.length || 0;

    return (
        <Box display="flex" position="relative">
            <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                maxWidth="100%"
            >

                <TaskSection sectionTitle={<Trans>Description</Trans>}>
                    {event && typeof event.description === 'string' ? (
                        <ReactQuill
                            value={eventDescription}
                            modules={{"toolbar": false}}
                            readOnly
                            className={classes.descriptionBlock}
                        />
                    ) : (
                        <Skeleton width={200} />
                    )}
                </TaskSection>

                <TaskSection sectionTitle={`${t('Calendar Event Creator Attachments')} (${eventAttchmentsCount})`}>
                    {event
                        ? (
                            <AttachmentsGallery attachments={event.attachments ?? []} />
                        ) : (
                            <>
                                <Skeleton width={160} />
                                <Skeleton width={160} />
                            </>
                        )}
                </TaskSection>

                {event && <TaskActivity task={event} /> }
            </Box>
            <Box
                display="flex"
                flexDirection="column"
            />
        </Box>
    );
}
