import * as Sentry from '@sentry/react';
import { environmentName, sentryUrl } from 'utils/globals';
import { Environment } from 'model/environment';

export function initSentry() {
    if (environmentName !== Environment.Local) {
        Sentry.init({
            dsn: sentryUrl,
            environment: environmentName,
            normalizeDepth: 8,
        });
    }
}

export function reportError(error: Error, details: any) {
    Sentry.captureException(
        error,
        {
            level: 'error',
            extra: details,
        },
    );
}

export function reportMessage(message: string, details?: any) {
    Sentry.captureMessage(message, {
        extra: details,
    });
}
