import { Box, Tooltip } from '@material-ui/core';
import { TaskTagType } from 'api/task/tag/taskTagModel';
import { StyledLink } from 'modules/shared/components/StyledTable';
import { TagBoxColorTooltip } from 'modules/task/common/taglist/TagBoxColorTooltip';
import { ApprovableIcon } from '../ApprovableIcon';
import { ExpireIcon } from '../ExpireIcon';

export type TaskNameCoreProps = {
    title: string;
    link?: string;
    isApprovable: boolean;
    isExpirable: boolean;
    tag: null | TaskTagType;
};

export const TaskNameCore: React.FC<TaskNameCoreProps> = ({ title, link, isApprovable, tag = null, isExpirable }) => {
    const titleComponent = (
        <Tooltip title={title} placement="bottom-start">
            <span>{title}</span>
        </Tooltip>
    );

    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
        >
            {tag && 
                <Box position="absolute" style={{left: '12px'}}>
                    <TagBoxColorTooltip tag={tag} />
                </Box>
            }
            <Box
                maxWidth="90%"
                overflow="hidden"
                textOverflow="ellipsis"
                display="inline-block"
                textAlign="left"
            >
                {link
                    ? <StyledLink to={link}>{titleComponent}</StyledLink>
                    : titleComponent}
            </Box>
            {isApprovable && (
                <Box display="inline-block">
                    <ApprovableIcon />
                </Box>
            )}
            {isExpirable && (
                <Box display="inline-block">
                    <ExpireIcon /> 
                </Box>
            )}
        </Box>
    );
};
