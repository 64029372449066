import { combineReducers } from "redux";
import { requestWatchers, WatcherActions } from "api/task/watcher/watcherActions";

const initialState = {
    watchers: [],
    watchersFetching: false,
};

function watchers(
    state: string[] = initialState.watchers,
    action: WatcherActions
): Array<string> {
    switch (action.type) {
        case requestWatchers.successType:
            return action.payload;
        case requestWatchers.errorType:
            return state;
        default:
            return state;
    }
}

function watchersFetching(
    state = initialState.watchersFetching,
    action: WatcherActions
): boolean {
    switch (action.type) {
        case requestWatchers.initType:
            return true;
        case requestWatchers.successType:
        case requestWatchers.errorType:
            return false;
        default:
            return state;
    }
}

export const watcherReducer = combineReducers({
    watchers,
    watchersFetching
});
