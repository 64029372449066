import { delay, put, takeLatest } from 'typed-redux-saga';
import { getIntegrationsMock, INTEGRATIONS_GET } from './integrationActions';

const delayToGetIntegrationsMs = 200;

function* getIntegerationMockWatcher() {
    yield* takeLatest(INTEGRATIONS_GET, function* () {
        yield delay(delayToGetIntegrationsMs);
        yield put(getIntegrationsMock());
    });
}

export const integrationSagas = [getIntegerationMockWatcher];
