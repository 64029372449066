import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { Location, LocationTreeResponse } from 'api/hierarchy/hierarchyModel';
import { IResponseErrorPayload } from 'model/error';

const REQUEST_COMPANY_LOCATIONS = `company/locations`;

export const requestHierarchy = createApiActionsByName<string, Location[], any, typeof REQUEST_COMPANY_LOCATIONS>(
    REQUEST_COMPANY_LOCATIONS
);

const REQUEST_COMPANY_LOCATIONS_USER_LOCATION_TREE = 'request/company/locations/user-location-tree/GET_LIST';
const RESPONSE_COMPANY_LOCATIONS_USER_LOCATION_TREE_SUCCESS = 'response/company/locations/user-location-tree/GET_LIST_SUCCESS';
const RESPONSE_COMPANY_LOCATIONS_USER_LOCATION_TREE_ERROR = 'response/company/locations/user-location-tree/GET_LIST_ERROR';

export const requestCompanyLocationsUserTree = (
    createApiActions<void, LocationTreeResponse, IResponseErrorPayload,
        typeof REQUEST_COMPANY_LOCATIONS_USER_LOCATION_TREE,
        typeof RESPONSE_COMPANY_LOCATIONS_USER_LOCATION_TREE_SUCCESS,
        typeof RESPONSE_COMPANY_LOCATIONS_USER_LOCATION_TREE_ERROR
    >(
        REQUEST_COMPANY_LOCATIONS_USER_LOCATION_TREE,
        RESPONSE_COMPANY_LOCATIONS_USER_LOCATION_TREE_SUCCESS,
        RESPONSE_COMPANY_LOCATIONS_USER_LOCATION_TREE_ERROR,
    )
);

export type HierarchyActions = (
    ActionsReturnTypes<typeof requestHierarchy>
    | ActionsReturnTypes<typeof requestCompanyLocationsUserTree>
);
