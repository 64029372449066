import clsx from "clsx"
import { Messages, Navigate, NavigateAction, View } from "react-big-calendar";

type ViewNamesGroupProps = {
  messages: Messages;
  onView:(view: View) => void;
  view: View;
  views: View[];
};

const ViewNamesGroup: React.FC<ViewNamesGroupProps> = ({ views: viewNames, view, messages, onView }) => (
    <>
        {viewNames.map((name: string) => (
            <button
              type="button"
              key={name}
              className={clsx({ 'rbc-active': view === name })}
              onClick={() => onView(name as View)}
            >
              {messages[name as View]}
            </button>
        ))}
    </>
)

export type CustomToolbarProps = {
  date: Date;
  view: View;
  views: View[];
  label: string;
  localizer: { messages: Messages };
  onNavigate: (navigate: NavigateAction, date?: Date) => void;
  onView: (view: View) => void;
  children?: React.ReactNode;
};

export const CustomToolbar: React.FC<CustomToolbarProps> = ({
  label,
  localizer: { messages },
  onNavigate,
  onView,
  view,
  views,
}) => {
  return (
    <div className={clsx('rbc-toolbar', 'home--custom-toolbar')}>
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={() => onNavigate(Navigate.TODAY)}
          aria-label={messages?.today}
        >
          {messages?.today}
        </button>
        <button
          type="button"
          onClick={() => onNavigate(Navigate.PREVIOUS)}
          aria-label={messages?.previous}
        >
          {messages?.previous}
        </button>
        <button
          type="button"
          onClick={() => onNavigate(Navigate.NEXT)}
          aria-label={messages?.next}
        >
          {messages?.next}
        </button>
      </span>

      <span className="rbc-toolbar-label">{label}</span>

      <span className="rbc-btn-group">
        <ViewNamesGroup
          view={view}
          views={views}
          messages={messages}
          onView={onView}
        />
      </span>
    </div>
  )
}
