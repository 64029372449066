import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Box, CircularProgress, Fade, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { useActions } from 'utils/store/useActions';
import { BaseInput } from 'components/basicInputs/BaseInput';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Breakpoints } from 'utils/styles/constants';
import { useSelector } from 'react-redux';
import { TaskTemplateItem } from './TaskTemplateItem';
import { ITaskTemplate } from './model';
import { selectTaskTemplates, selectTaskTemplateFetching } from 'store/modules/task/taskTemplate/taskTemplateSelectors';
import { requestGetTaskTemplate, requestTaskTemplates, resetGetTaskTemplate } from 'api/task/taskTemplate/taskTemplateActions';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { DialogTemplate } from './DialogTemplate';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { isEqual } from 'lodash';
import { useFormikContext } from 'formik';
import { TooltipTitle } from './TooltipTitle';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        gridColumnStart: 1,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            gridColumn: '1/3',
            alignItems: 'stretch',
        }
    },
    selectField: {
        '& .MuiSelect-select': {
            paddingTop: theme.spacing(.5),
            paddingBottom: theme.spacing(.5),
        }
    },
}));

const emptyItems = () => (
    <Box px={2} py={.5} style={{pointerEvents:"none"}}>
        <Typography variant="body2">
            <Trans>No options</Trans>
        </Typography>
    </Box>
)

const renderItems = (templateList: ITaskTemplate[], handleDeleteTaskTemplate: (taskTemplateId: string) => void, deleteTaskTemplate: string, isDisabled = false) => {
    return (
        templateList.map((option: ITaskTemplate) => (
        <MenuItem key={option.id} value={option.id}>
            <TaskTemplateItem 
                title={option.title}
                id={option.id}
                isDeleting={deleteTaskTemplate === option.id}
                onDeleteTaskTemplate={handleDeleteTaskTemplate}
                isDisabled={isDisabled}
            />
        </MenuItem>
    ))
)}

export const TasksTemplate = () => {
    const [valueTask, setValueTask] = useState<string>();
    const valueTaskId = useRef<{id:string, title: string}>({id:'', title: ''});
    const [deleteTaskTemplate, setDeleteTaskTemplate] = useState<string>('');
    const { isOpened, onClose, onOpen } = useOpenedState();
    const { values, initialValues } = useFormikContext();

    const templateList = useSelector(selectTaskTemplates);
    const isFetching = useSelector(selectTaskTemplateFetching);
    const classes = useStyles();

    const actionsTaskTemplates = useActions({
        getTemplates: requestTaskTemplates.init,
        getTemplate: requestGetTaskTemplate.init,
        resetTemplate: resetGetTaskTemplate
    });

    useEffect(()=>{
        actionsTaskTemplates.current.getTemplates();
    },[actionsTaskTemplates])

    const handleDeleteTaskTemplate = useCallback(( taskTemplateId: string)=>{
        const edit = deleteTaskTemplate === taskTemplateId ? '' : taskTemplateId;
        setDeleteTaskTemplate(edit);
    },[deleteTaskTemplate])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target;
        const findTemplate = templateList.find(t=>t.id === value);
        if(findTemplate){
            if(!isEqual(initialValues, values)){
                valueTaskId.current = findTemplate;
                onOpen();
                return;
            }
            actionsTaskTemplates.current.resetTemplate();
            setValueTask(findTemplate.title)
            actionsTaskTemplates.current.getTemplate({id: findTemplate.id})
        }
    };

    const items = useMemo(
        () => {
            if(!templateList.length) return emptyItems();
            return renderItems(templateList, handleDeleteTaskTemplate, deleteTaskTemplate, isFetching)
        },[templateList, handleDeleteTaskTemplate, deleteTaskTemplate, isFetching],
    );

    return (
        <>
            <DialogTemplate 
                open={isOpened}
                onCancel={onClose}
                onContinue={()=>{
                    actionsTaskTemplates.current.resetTemplate();
                    setValueTask(valueTaskId.current.title);
                    actionsTaskTemplates.current.getTemplate({ id: valueTaskId.current.id });
                    onClose();
                }}
            />
            <Box className={classes.container}>
                <Box width="100%">
                    <BaseInput label="" separateLabel={false}>
                        <Box display="flex">
                            <Typography variant="body2" style={{ whiteSpace: "pre-wrap"}}> 
                                <Trans>Templates</Trans>
                            </Typography>
                            <Tooltip title={<Trans>Select or edit a saved template to pre-fill task creation form</Trans>}
                                arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                                <ContactSupportIcon
                                    color="inherit"
                                    fontSize='small'
                                />
                            </Tooltip>
                        </Box>
                        <TextField
                            variant="outlined"
                            size="small"
                            id="tasks-template"
                            value={valueTask || ''} 
                            select
                            SelectProps={{
                                renderValue: () => <TooltipTitle title={valueTask} maxLength={14}/>,
                                onClose: () => setDeleteTaskTemplate(''),
                                className: classes.selectField,
                            }}
                            onChange={handleChange}
                        >
                            {items}
                        </TextField>
                    </BaseInput>
                </Box>
                { isFetching &&
                    <Box mt={3} ml={1} mr={1} position="absolute" right={-42} width={30}>
                        <CircularProgress size={26}/>
                    </Box>
                }
            </Box>
        </>
    );
}
