import { combineReducers } from "redux";
import {
    requestDeleteTaskTag,
    requestTaskTags,
    requestUpdateTaskTag,
    TaskTagActions,
    requestAddTaskTag,
    TASK_TAG_FILTER_ACTIVE,
} from "api/task/tag/tagActions";
import { TaskTagType } from "api/task/tag/taskTagModel";

const initialState = {
    taskTags: [],
    taskTagsFetching: false,
    taskTagUpdating: false,
    taskTagAdding: false,
    taskTagsFilter: [],
};

function updateTaskTaglist(
    state: TaskTagType[],
    taskTag: TaskTagType
): TaskTagType[] {
    if (!state.length) {
        return state;
    }
    return state.map((tag) => ({
        ...tag,
        name: tag.id === taskTag.id ? taskTag.name : tag.name,
        color: tag.id === taskTag.id ? taskTag.color : tag.color,
    }));
}
const checkTagColor = (tags : TaskTagType[])=>(
    tags
    .map(tag =>(
        {...tag, color: /^#[0-9a-f]{3,6}$/i.test(tag.color) ? tag.color : ''}
    ))
)

function taskTags(
    state: TaskTagType[] = initialState.taskTags,
    action: TaskTagActions
): Array<TaskTagType> {
    switch (action.type) {
        case requestTaskTags.successType:
            return checkTagColor(action.payload);
        case requestUpdateTaskTag.successType:
            return updateTaskTaglist(state, action.payload.taskTag);
        case requestAddTaskTag.successType:
            return [action.payload, ...state];
        case requestDeleteTaskTag.successType:
            const filterTags = state.filter(
                (tag) => action.payload.id !== tag.id
            );
            return filterTags;
        case requestDeleteTaskTag.errorType:
            return state;
        default:
            return state;
    }
}

function taskTagUpdating(
    state = initialState.taskTagUpdating,
    action: TaskTagActions
): boolean {
    switch (action.type) {
        case requestUpdateTaskTag.initType:
            return true;
        case requestUpdateTaskTag.successType:
        case requestUpdateTaskTag.errorType:
            return false;
        default:
            return state;
    }
}

function taskTagsFetching(
    state = initialState.taskTagsFetching,
    action: TaskTagActions
): boolean {
    switch (action.type) {
        case requestDeleteTaskTag.initType:
        case requestTaskTags.initType:
            return true;
        case requestDeleteTaskTag.successType:
        case requestDeleteTaskTag.errorType:
        case requestTaskTags.successType:
        case requestTaskTags.errorType:
            return false;
        default:
            return state;
    }
}

function taskTagAdding(
    state = initialState.taskTagAdding,
    action: TaskTagActions
): boolean {
    switch (action.type) {
        case requestAddTaskTag.initType:
            return true;
        case requestAddTaskTag.successType:
        case requestAddTaskTag.errorType:
            return false;
        default:
            return state;
    }
}

function taskTagsFilter(
    state = initialState.taskTagsFilter,
    action: TaskTagActions
): Array<string> {
    switch (action.type) {
        case TASK_TAG_FILTER_ACTIVE:
            return action.payload;
        default:
            return state;
    }
}

export const taskTagReducer = combineReducers({
    taskTags,
    taskTagsFetching,
    taskTagUpdating,
    taskTagsFilter,
    taskTagAdding
});
