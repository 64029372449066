import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logger } from 'utils/logger';
import { LanguageCode, UserDTO } from 'api/user/userModel';
import { selectUser } from 'store/modules/auth/authSelectors';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { selectStreamUserToken } from 'store/modules/user/userSelectors';
import { ConnectService } from 'services/connect/ConnectService';
import { SerializableError } from 'utils/error/serializableError';
import { IConnectContext } from '../model';
import { environmentName } from 'utils/globals';
import { Environment } from 'model/environment';
import { getUserName } from 'utils/helpers/user';
import { TranslationLanguages } from 'stream-chat';

async function initConnection(
    user: UserDTO,
    avatarsBaseUrl: string,
    userToken: string,
): Promise<ConnectService | null> {
    const connectService = new ConnectService();
    const userData = {
        id: user.id,
        name: getUserName(user),
        image: `${avatarsBaseUrl}/${user.avatarId}`,
        language: (user?.language ?? LanguageCode.en) as TranslationLanguages,
    };
    try {
        await connectService.initialize(userData, userToken);

        if (environmentName === Environment.Local) {
            // DO NOT USE IT IN CODE - ONLY FOR DEBUGGING PURPOSES IN CONSOLE
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.connectService = connectService;
        }

        return connectService;
    } catch (error) {
        logger.reportError(
            new Error('Failed to init connect context'),
            JSON.stringify(new SerializableError(error), null, 4),
        );
        return null;
    }
}

export function useInitContext(setContext: React.Dispatch<React.SetStateAction<IConnectContext | null>>) {
    const currentUser = useSelector(selectUser);
    const filesConfig = useSelector(getFilesConfig);
    const userToken = useSelector(selectStreamUserToken);

    useEffect(() => {
        if (currentUser && filesConfig?.avatarsBaseUrl && userToken) {
            initConnection(currentUser, filesConfig.avatarsBaseUrl, userToken)
                .then((connectService: ConnectService | null) => (
                    connectService
                        ? setContext({ connectService })
                        : setContext(null)
                ));
        } else {
            setContext(prev => {
                prev?.connectService.dispose();

                return null;
            });
        }
    }, [currentUser, filesConfig?.avatarsBaseUrl, setContext, userToken]);
}
