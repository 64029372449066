import { CellProps } from 'react-table';
import {
    ChildLocationTask,
    ChildStoreTask,
} from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import React from 'react';
import { Box, styled } from '@material-ui/core';
import { ChildTaskWithNested } from 'modules/task/taskList/views/TaskByLocationsView/TaskByLocationsView';
import { Skeleton } from '@material-ui/lab';
import {
    fontColorMap, fontColorMapDisabled,
    parentLevelIconMap,
    storeLevelIconMap,
    textMap,
    textMapDisabledOverdue
} from 'modules/task/taskList/model';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { Breakpoints } from 'utils/styles/constants';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { useSelector } from 'react-redux';
import { selectParentTaskDetailsLoading } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { selectFeatureFlags } from '../../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../../utils/featureFlags';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const StatusWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        fontSize: '10px',
        lineHeight: '12.1px',
        textTransform: 'uppercase',
    },
}));

export function TaskByLocationStatus({ row }: CellProps<ChildTaskWithNested>) {
    const { original: task } = row;
    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const isTaskLoading = useSelector(selectParentTaskDetailsLoading)[task.id];

    if (task.isSkeleton || isTaskLoading) {
        return <Skeleton width={160} />;
    }

    const status = (
        Reflect.has(task, 'completed')
            ? <LocationTaskByLocationStatus isMobile={isMobile} task={task as ChildLocationTask} />
            : <StoreTaskByLocationStatus isMobile={isMobile} task={task as ChildStoreTask} />
    );

    return (
        <StatusWrapper>
            {status}
        </StatusWrapper>
    );
}

const resolveIconFontSize = (isMobile: boolean) => isMobile ? '14px' : undefined;

interface ITaskByLocationStatus<TaskType> {
    task: TaskType;
    isMobile: boolean;
}

function LocationTaskByLocationStatus({ task, isMobile }: ITaskByLocationStatus<ChildLocationTask>) {
    const { displayedStatus } = task;
    const Icon = parentLevelIconMap[displayedStatus || DisplayedTaskStatus.Default];
    const fontSize = resolveIconFontSize(isMobile);
    const paddingLeft = isMobile ? '6px' : '13px';
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isOverdueEnabled = isOverdueColumnsFeature?.enabled ?? false;
    return (
        <Box
            color={isOverdueEnabled ? fontColorMap[displayedStatus] : fontColorMapDisabled[displayedStatus]}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Icon style={{ fontSize }} />
            <Box pl={paddingLeft}>
                <b>{task.completed} <Trans>of</Trans> {task.storeTasks}</b> <Trans>Stores completed</Trans>
            </Box>
        </Box>
    );
}

function StoreTaskByLocationStatus({ task, isMobile }: ITaskByLocationStatus<ChildStoreTask>) {
    const { displayedStatus } = task;
    const Icon = storeLevelIconMap[displayedStatus || DisplayedTaskStatus.Default];
    const fontSize = resolveIconFontSize(isMobile);
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isOverdueEnabled = isOverdueColumnsFeature?.enabled ?? false;
    return (
        <Box
            color={isOverdueEnabled ? fontColorMap[displayedStatus] : fontColorMapDisabled[displayedStatus]}
            fontWeight={600}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Icon style={{ fontSize }} />
            <Box pr="9px" />
            {isOverdueEnabled ? `${t(textMap[task.displayedStatus])}` : `${t(textMapDisabledOverdue[task.displayedStatus])}`}
        </Box>
    );
}
