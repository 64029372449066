import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';

export function SplashPage({isLoginAs = false}) {
    return (
        <Box display="flex" alignItems="center"
            position="absolute" justifyContent="center"
            height="100vh" width="100%" style={isLoginAs? { zIndex:1, backgroundColor: UtilColors.Shadow1 }: {}}
        >
            <CircularProgress />
        </Box>
    );
}
