import moment, { unitOfTime } from "moment";
import { View, Views } from "react-big-calendar";

export const dateRangeByView = (view: View, date?: Date ): {dateStart: string, dateEnd: string} => {
  let unitOfTime: unitOfTime.StartOf | null = null;
  const currentDate = moment(date);
  switch (view) {
    case Views.WORK_WEEK:
      unitOfTime = Views.WEEK
      break;
    case Views.AGENDA:
      return {
        dateStart: currentDate.clone().format('YYYY-MM-DD'),
        dateEnd: currentDate.clone().add(1, 'M').format('YYYY-MM-DD')
      }
    default:
      unitOfTime = view
      break;
  };

  return {
    dateStart: currentDate.clone().startOf(unitOfTime).format('YYYY-MM-DD'),
    dateEnd: currentDate.clone().endOf(unitOfTime).format('YYYY-MM-DD')
  }
}