import React from 'react'
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Box } from '@material-ui/core';
import { LocationPickerModal } from 'modules/locationPicker/LocationPickerModal';
import { LocationItem } from 'modules/task/locations/components/LocationItem';
import { LocationsButton } from 'modules/task/locations/components/LocationsButton';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { useOpenedState } from 'utils/hooks/useOpenedState';

type EventLocationSectionProps = {
  storeLocationIds: string[];
}
export const EventLocationSection: React.FC<EventLocationSectionProps> = ({ storeLocationIds }) => {
  const { isOpened, onClose, onOpen } = useOpenedState();
  const locationsById = useSelector(selectLocationsByIds);

  return (
      <>
        <LocationsButton text={<Trans>View Stores</Trans>} onClick={onOpen} />
          <LocationPickerModal
              storesSelected={storeLocationIds.length}
              open={isOpened}
              onCancel={onClose}
          >
              {storeLocationIds.map(id => locationsById[id] && (
                  <Box key={id} mb="12px">
                      <LocationItem location={locationsById[id]!} onDeleteStore={()=>{}}/>
                  </Box>
              ))}
          </LocationPickerModal>
      </>
  )
}
