import { ItemsById } from 'model/entity';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { FeatureFlagActions, requestFeatureFlags } from './actions';
import { FeatureFlagState } from './model';

export type FeatureFlagsState = {
    featureFlags: ItemsById<FeatureFlagState>;
    featureFlagsLoading: boolean;
}

const initialState: FeatureFlagsState = {
    featureFlags: {},
    featureFlagsLoading: false,
};

export const featureFlags = (state: FeatureFlagsState = initialState, action: FeatureFlagActions): FeatureFlagsState => {
    switch (action.type) {
        case requestFeatureFlags.initType:
            return {
                ...state,
                featureFlagsLoading: true,
            };
        case requestFeatureFlags.successType:
            return {
                featureFlags: itemsByIds(action.payload),
                featureFlagsLoading: false,
            };
        case requestFeatureFlags.errorType:
            return {
                ...initialState,
                featureFlagsLoading: false,
            };
        default:
            return state;
    }
};
