import { DependencyList, useMemo } from 'react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

type Callback = (...args: any[]) => any;

export function useThrottledCallback(callback: Callback, timeoutMs: number, deps: DependencyList | undefined) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => throttle(callback, timeoutMs), [timeoutMs, ...(deps || [])]);
}

export function useDebouncedCallback(callback: Callback, timeoutMs: number, deps: DependencyList | undefined) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => debounce(callback, timeoutMs), [timeoutMs, ...(deps || [])]);
}
