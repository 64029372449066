import React, { useCallback, useMemo } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { StoreTaskAttachmentModel } from 'api/task/storeTask/storeTaskModel';
import { CloudDownload, Delete } from '@material-ui/icons';
import { getFileExtension } from 'utils/helpers/files';
import { thumbnailHeight, useStyles } from './styles';
import { NotSupportedThumbnail } from './NotSupportedThumbnail';
import { FallbackImage } from './FallbackImage';
import { getFileCdnUrl, getFileUrl, getThumbnailCdnUrl, getThumbnailUrl } from 'api/helpers';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { selectFileStorageToken } from 'store/modules/files/filesSelectors';
import { useSelectorOnce } from 'utils/hooks/useSelectorOnce';

type AttachmentThumbnailClickHandler = (attachment: StoreTaskAttachmentModel) => void;

interface IAttachmentThumbnailProps {
    attachment: StoreTaskAttachmentModel;
    onClickHandler?: AttachmentThumbnailClickHandler;
    onDeleteHandler?: AttachmentThumbnailClickHandler;
    onDownloadHandler?: AttachmentThumbnailClickHandler;
    fallbackImageHeigth?: number;
}

function useClickCallback(
    callback: AttachmentThumbnailClickHandler | undefined,
    attachment: StoreTaskAttachmentModel,
) {
    return useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        callback?.(attachment);

    }, [attachment, callback]);
}

export const useGetThumbnailUrl = () => {
    const cdnEnabled = useFeatureState(FeatureFlags.FilesCDN);
    const filesToken = useSelectorOnce(selectFileStorageToken);

    return useCallback((fileId: string) => {
        return cdnEnabled
            ? getThumbnailCdnUrl(fileId, filesToken || '')
            : getThumbnailUrl(fileId);
    }, [cdnEnabled, filesToken])
};

export const useGetAttachmentUrl = () => {
    const cdnEnabled = useFeatureState(FeatureFlags.FilesCDN);
    const filesToken = useSelectorOnce(selectFileStorageToken);

    return useCallback((fileId: string) => {
        return cdnEnabled
            ? getFileCdnUrl(fileId, filesToken || '')
            : getFileUrl(fileId);
    }, [cdnEnabled, filesToken])
};

export function AttachmentThumbnail({
    attachment,
    onClickHandler,
    onDeleteHandler,
    onDownloadHandler,
    fallbackImageHeigth = thumbnailHeight
}: IAttachmentThumbnailProps) {
    const classes = useStyles({fallbackImageHeigth});
    const { fileId, name, hasThumbnail } = attachment;
    const fileExtension = useMemo(() => getFileExtension(name), [name]);
    const thumbnailUrl = useGetThumbnailUrl()(fileId);

    const innerOnClickHandler = useClickCallback(onClickHandler, attachment);
    const innerOnDeleteHandler = useClickCallback(onDeleteHandler, attachment);
    const innerOnDownloadHandler = useClickCallback(onDownloadHandler, attachment);

    return (
        <div
            className={classes.thumbnail}
            onClick={innerOnClickHandler}
        >
            {hasThumbnail
                ? <FallbackImage src={thumbnailUrl} alt={name} fallbackImageHeigth={fallbackImageHeigth}/>
                : <NotSupportedThumbnail fileExtension={fileExtension} notSupportedThumbnailHeight={fallbackImageHeigth}/>}

            <Box className={classes.imageBar}>
                <Tooltip title={name}>
                    <Typography
                        className={classes.title}
                        component="div"
                    >
                        {name}
                    </Typography>
                </Tooltip>
                {onDownloadHandler && (
                    <Tooltip title="Download">
                        <IconButton
                            color="inherit"
                            onClick={innerOnDownloadHandler}
                        >
                            <CloudDownload />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>

            {onDeleteHandler && (
                <Box className={classes.actionBar}>
                    <Tooltip title="Delete">
                        <IconButton
                            color="inherit"
                            onClick={innerOnDeleteHandler}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </div>
    );
}
