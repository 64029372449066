import React from 'react';
import { Box, styled, Typography, useTheme } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { Breakpoints, GrayColor } from 'utils/styles/constants';

const Wrapper = styled(Box)({
    width: '100%',
    height: '50vh',
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Text = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(4),
    color: GrayColor.Gray5,
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
        fontSize: theme.spacing(3),
    },
}));

export type EmptyStateProps = {
    Icon: SvgIconComponent;
    text: string;
}

export const EmptyState = ({ Icon, text }: EmptyStateProps) => {
    const theme = useTheme();

    return (
        <Wrapper>
            <Container>
                <Icon style={{
                    height: theme.spacing(15),
                    width: theme.spacing(15),
                    color: GrayColor.Gray5,
                }} />
                <Text>{text}</Text>
            </Container>
        </Wrapper>
    )
};
