import { buildLocationsTree, getChildrenFromBottom } from "modules/locationPicker/LocationPicker/helpers";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectLocationsUserTreeById, selectRelationsLocationsUserTreeById } from "store/modules/hierarchy/hierarchySelectors";
import { selectTaskPrototypesById } from "store/modules/task/prototypes/prototypesSelectors";
import { useCurrentUser } from "utils/hooks/useCurrentUser";

export function useLocationTreeUser(prototypeId: string) {
    const taskPrototype = useSelector(selectTaskPrototypesById)[prototypeId];
    const currentUser = useCurrentUser();
    const relationsByIds = useSelector(selectRelationsLocationsUserTreeById);
    const locationsUserTreeById = useSelector(selectLocationsUserTreeById);

    const locationsTree = useMemo(
        () => currentUser ? buildLocationsTree(currentUser.companyLocationId ?? '', relationsByIds) : null,
        [currentUser, relationsByIds],
    );

    return useMemo(() => {
        return getChildrenFromBottom(locationsTree, locationsUserTreeById, taskPrototype?.locationIds ?? [])
    }, [taskPrototype, locationsTree, locationsUserTreeById]);
}
