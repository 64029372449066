import React from 'react';
import { Channel } from 'stream-chat';
import { Box } from '@material-ui/core';
import { ChannelDescription } from './ChannelDescription';
import { ChannelMembers } from './ChannelMembers';
import { Scrollbar } from 'components/containers/Scrollbar';
import { EditChannel } from 'modules/connect/messenger/components/EditChannel';
import { ChannelFrozenState } from './ChannelFrozenState';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { ChannelFiles } from './ChannelFiles';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

export interface IChannelDetailsContentProps {
    channel: Channel;
}

export function ChannelDetailsContent({ channel }: IChannelDetailsContentProps) {
    const canSeeFrozen = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));
    const isConnectShowChannelAttachments = useFeatureState(FeatureFlags.ConnectShowChannelAttachments);

    return (
        <Scrollbar>
            <Box>
                <EditChannel channel={channel} />
                <Box>
                    <ChannelDescription
                        expandedByDefault
                        channel={channel}
                    />
                    <ChannelMembers channel={channel} />
                    { isConnectShowChannelAttachments &&
                        <ChannelFiles channel={channel} />
                    }
                    {canSeeFrozen && (
                        <ChannelFrozenState channel={channel} />
                    )}
                </Box>
            </Box>
        </Scrollbar>
    );
}
