import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import { useButtonStyles } from 'utils/styles/buttons';
import { hasFormikError } from 'components/basicInputs/utils';
import { t } from 'i18next';

export function resolveButtonText(buttonText: string, filesQuantity: number): string {
    let result = buttonText;
    if (filesQuantity) {
        result += ` (${filesQuantity})`;
    }
    return result;
}

export interface IAttachmentsButtonProps {
    buttonText: string;
    labelText?: string;
    fieldName?: string;
    handleOpenAttachmentsPanel: () => void;
    disabled?: boolean;
}

export function AttachmentsButton({
    buttonText,
    labelText = `${t('Task Creator Attachments')}`,
    fieldName = 'attachments',
    handleOpenAttachmentsPanel,
    disabled,
}: IAttachmentsButtonProps) {
    const inputClasses = useInputsStyles([]);
    const buttonClasses = useButtonStyles([]);
    const [, meta] = useField(fieldName);

    return (
        <>
            <label className={inputClasses.label}>
                {labelText}
            </label>

            <Button
                onClick={handleOpenAttachmentsPanel}
                className={buttonClasses.muiButton}
                disabled={disabled}
            >
                <FileCopyIcon />
                {buttonText}
            </Button>

            {hasFormikError(meta) && (
                <FormHelperText error>
                    {meta.error}
                </FormHelperText>
            )}
        </>
    );
}
