import { useCallback, useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { Box } from '@material-ui/core';
import { TagSelected } from './TagSelected';
import { Taglist } from './Taglist';
import { ITaskTag } from './model';
import { useField } from 'formik';
import { useActions } from 'utils/store/useActions';
import { requestTaskTags } from 'api/task/tag/tagActions';
import { TaskTagType } from 'api/task/tag/taskTagModel';

export type TaskChecklistProps = {
    nameField: string;
    isDisabled?: boolean
}

export const TaskTaglist = ({ nameField, isDisabled = false }: TaskChecklistProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [field] = useField<TaskTagType>(nameField);
    const { value, onChange } = field;
    const actionsTaskTags = useActions({
        getTags: requestTaskTags.init,
    });

    useEffect(()=>{actionsTaskTags.current.getTags()},[actionsTaskTags])

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    },[]);

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const open = Boolean(anchorEl);
    const id = open ? 'tag-popover' : undefined;

    const handleTagTaskChange = useCallback(({id, name, color}: ITaskTag) => {
        onChange({ target: { name: nameField, value: {name, color, id } } });
        handleClose();
    },[handleClose, onChange, nameField]);

    const handleCleanSelected = useCallback(() => {
        if(!isDisabled){
            onChange({ target: { name: nameField, value: null } });
        }
    },[onChange, nameField, isDisabled]);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" ml={1}>
            <TagSelected title={value?.name || ''} onClick={handleClick} bgcolor={value?.color || ''} onClean={handleCleanSelected}/>
            { !isDisabled &&
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Taglist 
                        tagActive={value?.id  || ''}
                        onTagChange={handleTagTaskChange}
                        onTagClean={handleCleanSelected}
                        valueForm={value}
                        />
                </Popover>
            }
        </Box>
    );
}
