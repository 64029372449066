import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, ButtonBase } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { CellProps } from 'react-table';
import { useActions } from "utils/store/useActions";
import { useTaskByLocationStyles } from 'modules/task/taskList/hooks/useTaskByLocationStyles';
import { ChildTaskWithNested } from 'modules/task/taskList/views/TaskByLocationsView/TaskByLocationsView';
import { TaskByLocationStatus } from 'modules/task/taskList/views/TaskByLocationsView/cells/TaskByLocationStatus';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { LocationName } from 'modules/task/taskList/components/LocationName';
import { requestParentTaskChildren } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { useSelector } from 'react-redux';
import { selectParentChildrenTasks, selectParentChildrenTasksLoading } from 'store/modules/task/taskListComponent/taskListComponentSelectors';

interface ITaskLocationProps extends CellProps<ChildTaskWithNested> {
    children?: JSX.Element;
}

type ToggleIconProps = {
    isExpanded: boolean;
}

const ToggleIcon = ({ isExpanded }: ToggleIconProps) => {
    return isExpanded
        ? <KeyboardArrowDown />
        : <KeyboardArrowLeft />;
};

export function TaskLocationCell({ children, value, row }: ITaskLocationProps) {
    const classes = useTaskByLocationStyles([]);
    const { id, isSkeleton } = row.original;
    const childrenTasks = useSelector(selectParentChildrenTasks)[id];
    const childrenTasksLoading = useSelector(selectParentChildrenTasksLoading)[id];

    const isLocationTask = Reflect.has(row.original, 'storeTasks');
    const isStoreTask = !isLocationTask;

    const actions = useActions({
        fetchChildren: requestParentTaskChildren.init,
        taskModalOpen: storeTaskSetActiveId,
    });

    useEffect(() => {
        if (isLocationTask && row.isExpanded && !childrenTasks && !childrenTasksLoading) {
            actions.current.fetchChildren(id);
        }
    }, [id, isLocationTask, actions, row.isExpanded, childrenTasks, childrenTasksLoading]);

    const modalOpen = useCallback(() => {
        actions.current.taskModalOpen({taskId: id});
    }, [actions, id]);

    const cellContent = useMemo(() => (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
            <LocationName>{value}</LocationName>
            {children || null}
        </Box>
    ), [children, value]);

    return (
        <Box display="flex" ml={row.depth * 3}>
            {isSkeleton ? (
                <Box display="flex" flexDirection="column">
                    <Skeleton width={80} />
                    {children || null}
                </Box>
            ) : (
                <>
                    {isLocationTask && (
                        <ButtonBase
                            className={classes.expandButton}
                            {...row.getToggleRowExpandedProps()} // includes click handler and styles
                        >
                            <ToggleIcon isExpanded={row.isExpanded} />
                        </ButtonBase>
                    )}
                    {isLocationTask && cellContent}
                    {isStoreTask && (
                        <ButtonBase className={classes.storeOpenButton} onClick={modalOpen}>
                            {cellContent}
                        </ButtonBase>
                    )}
                </>
            )}
        </Box>
    );
}

export function TaskLocationMobileCell(props: CellProps<ChildTaskWithNested>) {
    return (
        <TaskLocationCell {...props}>
            <TaskByLocationStatus {...props} />
        </TaskLocationCell>
    );
}
