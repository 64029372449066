import React, { useCallback } from 'react';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { useField } from 'formik';
import { LocationsButton } from 'modules/task/locations/components/LocationsButton';
import { LocationsSection } from './components/LocationsSection';
import { LocationPickerModal } from 'modules/locationPicker/LocationPickerModal';
import { useSelector } from 'react-redux';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { Box } from '@material-ui/core';
import { LocationItem } from './components/LocationItem';
import { Trans } from 'react-i18next';

export type TaskLocationsViewProps = {
    readonly fieldName: string;
    canDeleteStore?: boolean;
}

export const TaskLocationsView: React.FC<TaskLocationsViewProps> = ({ fieldName, canDeleteStore }) => {
    const { isOpened, onClose, onOpen } = useOpenedState();
    const [field, , helpers] = useField<string[]>(fieldName);
    const storeLocationIds = field.value;
    const locationsById = useSelector(selectLocationsByIds);

    const handleDeleteStore = useCallback((storeId: string) => {
        const filtertoreLocationId = storeLocationIds.filter(store => store !== storeId);
        helpers.setValue(filtertoreLocationId);
    },[helpers, storeLocationIds])

    return (
        <LocationsSection count={storeLocationIds.length}>
            <LocationsButton text={<Trans>View Stores</Trans>} onClick={onOpen} />
            <LocationPickerModal
                storesSelected={storeLocationIds.length}
                open={isOpened}
                onCancel={onClose}
            >
                {storeLocationIds.map(id => locationsById[id] && (
                    <Box key={id} mb="12px">
                        <LocationItem location={locationsById[id]!} canDeleteStore={canDeleteStore} onDeleteStore={handleDeleteStore}/>
                    </Box>
                ))}
            </LocationPickerModal>
        </LocationsSection>
    );
}
