import { put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { SAVE_TASK_BY_TYPE, handleSaveTask, setEditTaskType } from './editTaskActions';
import { getTaskPrototype, updateTaskPrototype } from 'api/task/prototype/prototypeActions';
import { EditTaskType } from './editTaskModel';
import { selectEditTaskType } from './editTaskSelectors';
import { requestCreateTask } from 'api/task/taskActions';
import { requestCreateTaskTemplate } from 'api/task/taskTemplate/taskTemplateActions';
import { transformFormValues } from 'utils/helpers/formSubmit';
import { createEditTaskToTemplate, createTaskCloneToPayload } from 'modules/task/addTask/model';

function* handleToSaveTaskSaga() {
    yield* takeLatest(
        SAVE_TASK_BY_TYPE,
        function* ({ payload }: ReturnType<typeof handleSaveTask>) {
            const currentEditTaskType: string = yield select(selectEditTaskType);

            switch (currentEditTaskType) {
                case EditTaskType.CloneTask:
                    transformFormValues(payload);
                    yield* put(requestCreateTask.init(createTaskCloneToPayload(payload)));
                    break;
                case EditTaskType.EditTask:
                    yield* put(updateTaskPrototype.init(payload));
                    break;
                case EditTaskType.CreateTemplate:
                    transformFormValues(payload);
                    yield* put(requestCreateTaskTemplate.init(createEditTaskToTemplate(payload)));
                    break;
                default:
                    break;
            }
        },
    );
}

function* setEditTaskTypeSaga() {
    yield* takeEvery( getTaskPrototype.initType,
        function* ({payload}: ReturnType<typeof getTaskPrototype.init>) {
            if(payload.isClone){
                yield* put(setEditTaskType(EditTaskType.CloneTask))
                return;
            }
            yield* put(setEditTaskType(EditTaskType.EditTask));
        },
    );
}

export const editTaskSagas = [
    handleToSaveTaskSaga,
    setEditTaskTypeSaga
];
