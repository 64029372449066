import * as t from 'io-ts';
import { IResponseSuccessPayload } from '../../model/success';
import { PendingFile } from 'store/modules/files/filesModel';

export enum UserPermissionChannel {
    Admin = 'user_with_frozen_channel_permissions',
    Owner = 'owner',
}

export const createChannelRequestSchema = t.type({
    members: t.array(t.string),
    name: t.string,
    description: t.string,
    frozen: t.union([t.undefined, t.boolean]),
    type: t.union([t.undefined, t.string]),
    singleRoleId: t.union([t.undefined, t.string]),
    imageId: t.union([t.string, t.undefined])
});

export type CreateChannelRequest = t.TypeOf<typeof createChannelRequestSchema>;

export const editChannelRequestSchema = t.type({
    channelId: t.string,
    name: t.string,
    description: t.string,
    frozen: t.union([t.undefined, t.boolean]),
    imageId: t.union([t.string, t.undefined])
});

export type EditChannelRequest = t.TypeOf<typeof editChannelRequestSchema>;

export const inviteToChannelRequestSchema = t.type({
    channelId: t.string,
    members: t.array(t.string),
});

export type InviteToChannelRequest = t.TypeOf<typeof inviteToChannelRequestSchema>;

export interface IInviteByRolesData {
    channelId: string;
    roles: Array<string>;
}

export interface IRemoveChatRoleData {
    channelId: string;
    roles: string[];
    rolesToInvite?: Array<string>;
}

export interface IRemoveChannelMembersRequest {
    channelId: string;
    members: Array<string>;
}

export interface IRoleInviteSuccessResponse extends IResponseSuccessPayload {
    channelId: string;
}
export interface IRemoveChannelRoleSuccessResponse extends IResponseSuccessPayload {
    channelId: string;
    rolesToInvite?: Array<string>;
}

export interface ISearchChatMsg {
    channelId: string;
    searchString: string;
}

export interface IUserObject {
    id: string;
    role: string;
    created_at: string;
    updated_at: string;
    last_active: string;
    deleted_at: string;
    deactivated_at: string;
    banned: boolean;
    ban_expires: string;
    online: boolean;
    invisible: boolean;
    language: string;
    teams: string[];
    name: string;
    image?: string;
}

export interface IActionObject {
    name: string;
    text: string;
    style: string;
    type: string;
    value: string;
}

export interface IFieldObject {
    title: string;
    value: string;
    short: boolean;
}

export interface IImageDataObject {
    url: string;
    width: string;
    height: string;
    size: string;
    frames: string;
}

export interface IImagesObject {
    original: IImageDataObject;
    fixed_height: IImageDataObject;
    fixed_height_still: IImageDataObject;
    fixed_height_downsampled: IImageDataObject;
    fixed_width: IImageDataObject;
    fixed_width_still: IImageDataObject
    fixed_width_downsampled: IImageDataObject
}

export interface IAttachmentObject {
    type: string;
    fallback: string;
    color: string;
    pretext: string;
    author_name: string;
    author_link: string;
    author_icon: string;
    title: string;
    title_link: string;
    text: string;
    image_url: string;
    thumb_url: string;
    footer: string;
    footer_icon: string;
    actions: IActionObject[]
    fields: IFieldObject[]
    asset_url: string
    giphy: IImagesObject
    og_scrape_url: string
    original_width: number
    original_height: number
}

export interface IReactionObject {
    message_id: string;
    user_id: string;
    user: IUserObject;
    type: string;
    score: number;
    created_at: string;
    updated_at: string;
}

export interface IMessage {
    id: string;
    text: string;
    mml: string;
    command: string;
    html: string;
    type: string;
    user: IUserObject;
    attachments: IAttachmentObject[];
    latest_reactions: IReactionObject[];
    own_reactions: IReactionObject[];
    reaction_counts: object;
    reaction_scores: object;
    parent_id: string;
    show_in_channel: boolean;
    reply_count: number;
    quoted_message_id: string;
    quoted_message: IMessage;
    thread_participants: IUserObject[];
    cid: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    shadowed: boolean;
    image_labels: object;
    mentioned_users: IUserObject[];
    i18n: object;
    silent: boolean;
    before_message_send_failed: boolean;
    pinned: boolean;
    pinned_at: string;
    pinned_by: IUserObject;
    pin_expires: string;
}

export interface ISearchResult {
    message: ISearchResultMessage
}

export interface ISearchResultMessage {
    id: string;
    text: string;
    mml: string;
    command: string;
    html: string;
    type: string;
    user: IUserObject;
    attachments: IAttachmentObject[];
    latest_reactions: IReactionObject[];
    own_reactions: IReactionObject[];
    reaction_counts: object;
    reaction_scores: object;
    parent_id: string;
    show_in_channel: boolean;
    reply_count: number;
    quoted_message: IMessage
    thread_participants: IUserObject[];
    cid: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    shadowed: boolean;
    image_labels: object;
    mentioned_users: IUserObject[];
    i18n: object;
    silent: boolean;
    before_message_send_failed: boolean;
    pinned: boolean;
    pinned_at: string;
    pinned_by: IUserObject;
    pin_expires: string;
}

export interface ISearchWarning {
    warning_description: string;
    warning_code: number;
    channel_search_count: number;
    channel_search_cids: string[];
}

export interface ISearchResultData {
    results?: ISearchResult[];
    results_warning?: ISearchWarning;
    next: string;
    previous: string;
    duration: string;
}

export type EditChannelImageRequestType = EditChannelRequest & { file: PendingFile };
