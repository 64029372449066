import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { IEntityError } from 'model/entity';
import { RegularTaskType, UsersCanReassignType } from './editTaskCreatorModel';

export const REGULAR_TASK_EDIT_CREATOR_GET_USERS = 'task-edit-creator/GET_USERS_REGULAR_TASK';
export const requestUsersCanReassignRegularTask = (
    createApiActionsByName<string, UsersCanReassignType, IEntityError, typeof REGULAR_TASK_EDIT_CREATOR_GET_USERS>(
        REGULAR_TASK_EDIT_CREATOR_GET_USERS,
    )
);

export const REGULAR_TAKS_UPDATE_CREATOR = 'task-edit-creator/UPDATE_CREATOR_REGULAR_TASK';
export const requestRegularTaskUpdateCreator = (
    createApiActionsByName<RegularTaskType, RegularTaskType, IEntityError, typeof REGULAR_TAKS_UPDATE_CREATOR>(
        REGULAR_TAKS_UPDATE_CREATOR,
    )
);

export const SCHEDULED_TASK_EDIT_CREATOR_GET_USERS = 'task-edit-creator/GET_USERS_SCHEDULED_TASK';
export const requestUsersCanReassignScheduledTask = (
    createApiActionsByName<string, UsersCanReassignType, IEntityError, typeof SCHEDULED_TASK_EDIT_CREATOR_GET_USERS>(
        SCHEDULED_TASK_EDIT_CREATOR_GET_USERS,
    )
);

export const SCHEDULED_TAKS_UPDATE_CREATOR = 'task-edit-creator/UPDATE_CREATOR_SCHEDULED_TASK';
export const requestScheduledTaskUpdateCreator = (
    createApiActionsByName<RegularTaskType, RegularTaskType, IEntityError, typeof SCHEDULED_TAKS_UPDATE_CREATOR>(
        SCHEDULED_TAKS_UPDATE_CREATOR,
    )
);

export type EditTaskCreatorApiActions = (
    | ActionsReturnTypes<typeof requestUsersCanReassignRegularTask>
    | ActionsReturnTypes<typeof requestRegularTaskUpdateCreator>
    | ActionsReturnTypes<typeof requestUsersCanReassignScheduledTask>
    | ActionsReturnTypes<typeof requestScheduledTaskUpdateCreator>
);
