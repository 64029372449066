import { useSelector } from 'react-redux';
import { selectUser } from 'store/modules/auth/authSelectors';
import { getFilesConfig } from 'store/modules/files/filesSelectors';

export function useCurrentUser() {
    const user = useSelector(selectUser);
    const filesConfig = useSelector(getFilesConfig);

    if (!user) {
        return null;
    }

    return {
        avatar: filesConfig ? `${filesConfig.avatarsBaseUrl}/${user.avatarId}` : undefined,
        ...user,
    };
}
