import { useSelector } from 'react-redux';
import { selectAppsListByName } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';

export function useAppPermissions() {
    const appsList = useSelector(selectAppsListByName);

    return function (name: AppName) {
        return Reflect.has(appsList, name);
    };
}
