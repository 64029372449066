import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { taskReviewModalClose } from 'store/modules/task/taskReview/taskReviewActions';
import { selectTaskReviewIsLoading, selectTaskReviewIsModalOpen, selectTaskReviewItems, selectTaskReviewModalTitle } from 'store/modules/task/taskReview/taskReviewSelectors';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useActions } from "utils/store/useActions";
import { TaskSlideout } from '../common/TaskSlideout';
import { SimpleTaskListHeader } from '../taskList/components/SimpleTaskListHeader';
import { TaskReviewList } from './TaskReviewList';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export function TaskReviewSlideout() {
    const isOpen = useSelector(selectTaskReviewIsModalOpen);
    const isLoading = useSelector(selectTaskReviewIsLoading);
    const tasks = useSelector(selectTaskReviewItems);
    const title = useSelector(selectTaskReviewModalTitle);
    const actions = useActions({ close: taskReviewModalClose });
    const currentUser = useCurrentUser();

    const onClose = useCallback(() => {
        actions.current.close();
    }, [actions]);

    const titleHeader = isLoading
        ? `${t(title)}: ...`
        : `${t(title)}: ${tasks.length}`;

    return (
        <TaskSlideout
            open={isOpen}
            onClose={onClose}
            header={(
                <SimpleTaskListHeader
                    header={titleHeader}
                    regionName={currentUser?.companyLocationName ?? ''}
                />
            )}
        >
            {isLoading && <span><Trans>Loading</Trans>...</span>}
            {!isLoading && <TaskReviewList tasks={tasks}/>}
        </TaskSlideout>
    );
}
