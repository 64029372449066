import { Theme, makeStyles } from "@material-ui/core";
import { orangeColor } from "utils/styles/constants";

export const useStyles = makeStyles((theme: Theme) => ({
    primaryText: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        marginRight: theme.spacing(2),
        wordWrap: 'break-word',
        wordBreak: 'break-word'
    },
    secondaryText: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        wordWrap: 'break-word',
        wordBreak: 'break-word'
    },
    ownerText: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        backgroundColor: orangeColor,
        height: theme.spacing(3),
        borderRadius: theme.spacing(1),
        padding: theme.spacing(.5, .5, 0, .5),
    },
    list: {
        width: '100%',
    },
}));
