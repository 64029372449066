import React from 'react';
import { ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { AttachmentItemAvatar, AttachmentItemMeta, DownloadAction } from './attachmentItemElements';
import { useDownloadFile } from 'store/modules/files/filesHooks';
import { resolveType } from 'utils/helpers/fileHelpers';

export interface IAttachmentItemViewProps {
    title: string;
    uuid: string;
    size?: number;
}

export function AttachmentItemView({ title, uuid, size }: IAttachmentItemViewProps) {
    const handleDownloadClick = useDownloadFile(uuid, title);

    return (
        <ListItem>
            <AttachmentItemAvatar />

            <AttachmentItemMeta
                title={title}
                type={resolveType(title)}
                size={size}
            />

            <ListItemSecondaryAction>
                <DownloadAction handleDownloadClick={handleDownloadClick} />
            </ListItemSecondaryAction>
        </ListItem>
    );
}
