import Quill from 'quill';
const Link = Quill.import('formats/link');

export class LinkBlot extends Link {
  static sanitize(linkValueInput: string): string {
    let val = linkValueInput;

    if (/^\w+:/.test(val)) return val;

    if (!/^(https?:)?\/\//.test(val)) {
      val = "https://" + val;
    }

    return super.sanitize(val);
  }
}
