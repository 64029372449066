import { IUserBulkAction } from 'api/files/filesModel';
import React, { useCallback, useState } from 'react'
import { Trans } from 'react-i18next';
import { bulkUserImportTemplateUrl, bulkUserDeactivateTemplateUrl } from 'utils/globals';

const importBulkAction: IUserBulkAction = { type: 'Import', templateUrl: bulkUserImportTemplateUrl, label: <Trans>Import</Trans> };
const deactivateBulkAction: IUserBulkAction = { type: 'Deactivate', templateUrl: bulkUserDeactivateTemplateUrl, label: <Trans>Deactivate</Trans> };

export const useUserBulk = () => {
    const [ bulkTypeAction, setBulkTypeAction] = useState<IUserBulkAction>(importBulkAction);

    const handleChangeBulkAction = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const bulkAction: IUserBulkAction = event.target.checked
            ? deactivateBulkAction
            : importBulkAction;
        setBulkTypeAction(bulkAction);
    },[]);

    return {
        bulkTypeAction,
        handleChangeBulkAction,
    };
}
