import { makeStyles, Theme } from '@material-ui/core/styles';
import { Breakpoints, UtilColors } from 'utils/styles/constants';

export const useTaskByLocationsHeaderStyles = makeStyles((theme: Theme) => ({
    infoWrapper: {
        display: 'flex',
    },
    backButton: {
        color: UtilColors.TextSecondary,
        height: theme.spacing(4),
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '32.02px',
        flexWrap: 'wrap',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            lineHeight: '26.68px',
        },
    },
    title: {
        fontSize: '24px',
        lineHeight: '32.02px',
        fontWeight: 700,
        color: UtilColors.TextPrimary,
        margin: theme.spacing(0),
        display: 'flex',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '20px',
            lineHeight: '26.68px',
            fontWeight: 700,
        },
    },
    headerTitleLocationContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));
