import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

export function ForbiddenErrorPage() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
        >
            <Box>
                <Typography variant="h2"><Trans>Forbidden: insufficient privileges</Trans></Typography>
                <Typography variant="subtitle1"><Trans>Error 403</Trans></Typography>
            </Box>
        </Box>
    );
}
