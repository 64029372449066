import React, { useCallback } from 'react';
import { Box, Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useButtonStyles } from 'utils/styles/buttons';
import { selectAvatarEditorOpened } from 'store/modules/user/userSelectors';
import { openAvatarEditor } from 'store/modules/user/userActions';
import { PendingFile } from 'store/modules/files/filesModel';
import { ImageEditor } from './ImageEditor';
import { cropperImageStyles } from './cropperImageStyles';

export interface IUpdateLogoFormData {
    Avatar: string;
}
export type CropperModalProps = {
    textPreview: string,
    title: string,
    onSaveImage: (pendingFile: PendingFile) => void,
    isLoading: boolean
}

export function CropperModal({textPreview, title, onSaveImage, isLoading}: CropperModalProps) {
    const dispatch = useDispatch();
    const buttonClasses = useButtonStyles();
    const classes = cropperImageStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');
    const isOpen = useSelector(selectAvatarEditorOpened);

    const handleCancel = useCallback(() => {
        dispatch(openAvatarEditor(false));
    }, [dispatch])

    const handleSave = useCallback(async (data: IUpdateLogoFormData) => {
        const binaryImage = await fetch(data.Avatar);
        const blob = await binaryImage.blob();
        const file = new File([blob], 'avatar.jpeg', {type: 'image/jpeg'});
        const pendingFile = {
            localFile: file,
            localKey: '',
            title: 'avatar.jpeg',
        } as PendingFile;
        onSaveImage(pendingFile)
    }, [onSaveImage]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            fullScreen={false}
        >
            <Box className={classes.dialogBox}>
                <Box margin={'15px'}>
                    <Typography
                        variant="h3"
                        className={classes.pageTitle}
                    >
                        {title}
                    </Typography>
                </Box>
                <Formik
                    initialValues={{Avatar: ''}}
                    onSubmit={handleSave}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Box className={classes.boxInner1}>
                                <Box>
                                    <ImageEditor
                                        name={`${t('Avatar')}`}
                                        textPreview={textPreview}
                                    />
                                </Box>
                                <Box display={'flex'}>
                                    <Typography
                                        variant="body2"
                                        style={{marginLeft: 24}}
                                    >
                                        <Trans>Recommended Aspect Ratio</Trans>: 1 x 1
                                    </Typography>
                                    <Box marginLeft={'auto'}>
                                        <Box margin={'15px'}>
                                            <Button
                                                className={buttonClasses.muiRoundedButton}
                                                onClick={handleCancel}
                                                disabled={isLoading}
                                            ><Trans>Cancel</Trans></Button>
                                            {isLoading && (
                                                <CircularProgress className={classes.circular} />
                                            )}
                                            {!isLoading && (
                                                <Button
                                                    className={saveButtonClasses}
                                                    type={'submit'}
                                                    style={{ marginLeft: '15px' }}
                                                    disabled={isLoading}
                                                ><Trans>Save</Trans></Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    );
}
