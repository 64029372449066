import { Button, InputBase } from "@material-ui/core";
import {  useField } from "formik";
import { InputError } from "components/basicInputs/InputError";
import { BaseInput } from "../BaseInput";
import { Trans } from "react-i18next";
import { t } from "i18next";

type IFileInput = {
    name: string;
    accept: string;
    label?: string;
    id?: string;
}

export const FileInput = ({ name, accept, label = '', id='button-file-input' } : IFileInput) => {
    const [field, meta] = useField(name);
    const { onChange, onBlur, value } = field;

    const handleChange = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const files = Array.from(event.target.files);
        const [file] = files as File[];
        onChange({ target: { name, value: file } });
    };

    return (
        <BaseInput label={label}>
            <InputBase
                id={id}
                name={name}
                type="file"
                inputProps={{ accept: {accept}, 'aria-label': 'naked', placeholder: "dsadasd" }}
                onChange={handleChange}
                onBlur={onBlur}
                style={{ display: 'none' }}
            />
            <label htmlFor={id}>
                <Button 
                    variant="contained"
                    color="primary"
                    aria-label={`${t('upload picture')}`}
                    component="span"
                    style={{marginRight: 4}}
                >
                    <Trans>Choose File</Trans>
                </Button>
                {value?.name ? 
                    <>{value?.name}</>
                    :<Trans>No file chosen</Trans>
                }
            </label>
            {meta.error && (
                <InputError error={meta.error} />
            )}
        </BaseInput>
    );
};