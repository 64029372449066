import { put, select, takeEvery } from 'typed-redux-saga';
import { OPEN_SIDE_OUT_TASKS_BULK, TasksBulkActions } from './tasksBulkActions';
import { requestDeleteTaskBulk, requestTasksBulkHistory } from 'api/task/tasksBulk/tasksBulkActions';
import { selectTasksBulkHistory } from './tasksBulkSelectors';
import { TasksBulk } from 'api/task/tasksBulk/tasksBulkModel';
import { requestTasksBulkUpload } from 'api/files/filesActions';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import i18n from 'i18n';
import { browserHistory, Routes } from 'components/router/model';
import { generatePath } from 'react-router';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { difference } from 'lodash';
import { selectUsersIds, selectUsersLoadingById } from 'store/modules/user/userSelectors';

function* getSuggestionsReset() {
  yield* takeEvery([OPEN_SIDE_OUT_TASKS_BULK],
      function* ({ payload }: TasksBulkActions) {
        if(!payload) return;

        yield put(requestTasksBulkHistory.init(''));
      },
  );
}

function* deleteTaskBulkWatcherSaga() {
  yield* takeEvery([requestDeleteTaskBulk.successType],
      function* ({ payload }: ReturnType<typeof requestDeleteTaskBulk.success>) {
        const tasksBulk: TasksBulk = yield* select(selectTasksBulkHistory);
        const removeTaskBulk = tasksBulk.filter((task)=> task.id !== payload.id);

        yield put(requestTasksBulkHistory.success(removeTaskBulk));
      },
  );
}

function* uploadFileTaskBulkWatcherSaga() {
  yield* takeEvery([requestTasksBulkUpload.successType],
      function* () {
        browserHistory.push(generatePath(Routes.TaskList));
        yield put(showToastMessage({
          message: `${i18n.t('You will receive a notification with the results of the bulk import')}.`, options: { variant: ToastVariant.Success },
        }));
      },
  );
}

function* getTasksBulkHistoryWatcherSaga() {
  yield* takeEvery([requestTasksBulkHistory.successType],
      function* ({ payload }: ReturnType<typeof requestTasksBulkHistory.success>) {
        const userIds = payload.map(bulk=>bulk.createdBy);
        const loadedUserIds: any = yield* select(selectUsersIds);
        const loadingUserIds: any = yield* select(selectUsersLoadingById);
        const missingUserIds: any = difference(userIds, loadedUserIds).filter((userId: any) => !loadingUserIds[userId]);

        if (missingUserIds.length > 0) {
            yield* put(requestMultipleUsersInfo.init(missingUserIds));
        }
      },
  );
}
export const taskBulkSagas = [
  getSuggestionsReset,
  deleteTaskBulkWatcherSaga,
  uploadFileTaskBulkWatcherSaga,
  getTasksBulkHistoryWatcherSaga
];
