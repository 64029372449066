import { combineReducers } from 'redux';
import {
    InitialStateType,
    Integrations,
    integrationsMock,
} from './integrationModel';
import {
    IntegrationActions,
    INTEGRATIONS_GET,
    INTEGRATIONS_GET_MOCK,
} from './integrationActions';

const initialState: InitialStateType = {
    integrations: {
        userProvisioning: [],
        performance: [],
        AISidekick: [],
    },
    integrationsFetching: false,
};

function getIntegrations(
    state: Integrations = initialState.integrations,
    action: IntegrationActions
): Integrations {
    if(action.type === INTEGRATIONS_GET_MOCK){
        return integrationsMock;
    }
    return state;
}

function integrationFetching(
    state: boolean = initialState.integrationsFetching,
    action: IntegrationActions
): boolean {
    switch (action.type) {
        case INTEGRATIONS_GET:
            return true;
        case INTEGRATIONS_GET_MOCK:
            return false;
        default:
            return state;
    }
}

export const integration = combineReducers({
    integrationFetching,
    getIntegrations,
});
