import { useRef, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { Box, Theme, makeStyles } from '@material-ui/core';

import { TagBoxColor } from 'modules/task/common/taglist/TagBoxColor';
import { TextInput } from '../textInput/TextInput';
import { IInputProps, ITextInputClasses } from '../model';
import { BaseInput } from '../BaseInput';

interface IColorInputProps extends IInputProps {
    classes?: ITextInputClasses;
    separateLabel?: boolean;
    showCounter?: boolean;
    maxLength?: number;
    isDisabled: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
    inputColor: {
        visibility: 'hidden',
    }
}));

export const ColorInput = ({ id, name, label, classes: customClasses = {}, separateLabel = true, showCounter = true, maxLength, isDisabled }: IColorInputProps) => {
    const [ colorField ] = useField(name);
    const { value, onChange } = colorField;
    const inputColorRef = useRef<HTMLInputElement>(value);
    const classes = useStyles();

    const handlerColor = useCallback(() => {
        if(!isDisabled){
            inputColorRef.current?.click();
            inputColorRef.current?.focus();
        }
    },[isDisabled])

    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        onChange({ target: { name, value: inputColorRef.current?.value } });
    }

    useEffect(() => {
        if(value){
            inputColorRef.current.value = value;
        }
    }, [value])
    
    return (
        <BaseInput
            id={id}
            label={label}
            classes={customClasses}
            separateLabel={separateLabel}
        >
            <Box display="flex" flexDirection="row" my={1}>
                <Box position="relative" top="12px">
                    <TagBoxColor bgcolor={value} onClick={handlerColor}>
                        <input ref={inputColorRef} id={`${id}-color-input`} type="color" className={classes.inputColor}
                            onChange={handleColorChange}/>
                    </TagBoxColor>
                </Box>
                <TextInput
                    label=""
                    id={name}
                    name={name}
                    maxLength={maxLength}
                    showCounter={showCounter}
                    disabled={isDisabled}
                />
            </Box>
        </BaseInput>
    )
}