import difference from 'lodash/difference';
import { put, select, take, takeEvery, takeLatest } from 'typed-redux-saga';
import {
    STORE_TASK_MODAL_SET_TASK_ID,
    storeTaskSetActiveId,
    StoreTaskActions,
} from 'store/modules/task/storeTask/storeTaskActions';
import { selectParentChildrenTasks } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { requestParentTaskDetails } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import {
    RESPONSE_TASK_IN_ALREADY_DESIRED_STATE,
    RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE,
    StoreTaskApiActions,
    requestApproveStoreTask,
    requestDeclineStoreTask,
    requestIsApprovableByUser,
    requestStoreTaskGet,
    requestSubmitStoreTask,
    taskChecklistItemInAlreadyDesiredState,
    taskInAlreadyDesiredState,
    requestStoreAutomationTaskGet,
} from 'api/task/storeTask/storeTaskActions';
import { selectUsersIds } from 'store/modules/user/userSelectors';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { IStoreTaskSetActiveId } from './storeTaskModel';
import i18n from 'i18n';

type SetTaskIdAction = ReturnType<typeof storeTaskSetActiveId>;
type ChildrenTasksByParent = ReturnType<typeof selectParentChildrenTasks>;

const getParents = (childrenByParent: ChildrenTasksByParent, taskId: string, acc: string[]): string[] => {
    const parentEntry = Object.entries(childrenByParent).find(([, value]) => value?.includes(taskId));

    if (parentEntry) {
        const [parentTaskId] = parentEntry;
        return getParents(childrenByParent, parentTaskId, [...acc, parentTaskId]);
    }

    return acc;
};

function* updateTaskByLocationView() {
    let previousTaskId: string | null = null;

    while (true) {
        const currentAction = (yield* take(STORE_TASK_MODAL_SET_TASK_ID)) as SetTaskIdAction;

        if (previousTaskId !== null) {
            const parentChildrenTasks = yield* select(selectParentChildrenTasks);
            const tasksToFetch = getParents(parentChildrenTasks, previousTaskId, []);

            for (const taskToFetch of tasksToFetch) {
                yield* put(requestParentTaskDetails.init(taskToFetch));
            }
        }
        previousTaskId = currentAction.payload?.taskId ?? null;
    }
}

function* taskSidebarOpenedWatcher() {
    yield* takeLatest(STORE_TASK_MODAL_SET_TASK_ID, function* (action: StoreTaskActions) {
        if(!action.payload || !action.payload?.taskId) return;
        const { taskId: storeTaskId, isAutomation }: IStoreTaskSetActiveId = action.payload;
        if(!isAutomation){
            yield* put(requestStoreTaskGet.init(storeTaskId));
            yield* put(requestIsApprovableByUser.init(storeTaskId));
        }else{
            yield* put(requestStoreAutomationTaskGet.init(storeTaskId));
        }
    });
}

function* taskApprovalFlowWatcher() {
    yield* takeLatest(
        [
            requestSubmitStoreTask.successType,
            requestApproveStoreTask.successType,
            requestDeclineStoreTask.successType,
        ],
        function* (action: StoreTaskApiActions) {
            switch (action.type) {
                case requestSubmitStoreTask.successType:
                    yield* put(showToastMessage({
                        message: i18n.t('Task has been submitted'),
                        options: { variant: 'success' },
                    }));
                    break;
                case requestApproveStoreTask.successType:
                    yield* put(showToastMessage({
                        message: i18n.t('Task has been approved'),
                        options: { variant: 'success' },
                    }));
                    break;
                case requestDeclineStoreTask.successType:
                    yield* put(showToastMessage({
                        message: i18n.t('Task has been declined'),
                        options: { variant: 'success' },
                    }));
                    break;
            }
        },
    );
}

function* storeTaskGetWatcher() {
    yield* takeEvery(
        requestStoreTaskGet.successType,
        function* (action: ReturnType<typeof requestStoreTaskGet.success>) {
            const users = yield* select(selectUsersIds);
            const eventUsers = (action.payload.events || []).map(i => i.userId);
            const allUsers = [...eventUsers, ...action.payload?.watchers || []];
            const absentUsers = difference( allUsers, users);
            if (absentUsers.length > 0) {
                yield* put(requestMultipleUsersInfo.init(absentUsers));
            }
        },
    );
}

function* taskInAlreadyDesiredStateSaga(action: ReturnType<typeof taskInAlreadyDesiredState>) {
    const { taskId, state } = action.payload;

    yield* put(requestStoreTaskGet.init(taskId));
    yield* put(showToastMessage({
        message: `Task is already ${state} by another user`,
        options: {
            variant: 'info',
        },
    }));
}

function* taskInAlreadyDesiredStateSagaWatcher() {
    yield* takeEvery(RESPONSE_TASK_IN_ALREADY_DESIRED_STATE, taskInAlreadyDesiredStateSaga);
}

function* taskChecklistItemInAlreadyDesiredStateSaga(
    action: ReturnType<typeof taskChecklistItemInAlreadyDesiredState>,
) {
    const { taskId, completed } = action.payload;
    const completedText = completed ? 'checked' : 'unchecked';
    yield* put(requestStoreTaskGet.init(taskId));
    yield* put(showToastMessage({
        message: `Checklist item is already ${completedText} by another user`,
        options: {
            variant: 'info',
        },
    }));
}

function* taskChecklistItemInAlreadyDesiredStateSagaWatcher() {
    yield* takeEvery(
        RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE,
        taskChecklistItemInAlreadyDesiredStateSaga
    );
}

export const storeTaskSagas = [
    updateTaskByLocationView,
    taskSidebarOpenedWatcher,
    taskApprovalFlowWatcher,
    storeTaskGetWatcher,
    taskInAlreadyDesiredStateSagaWatcher,
    taskChecklistItemInAlreadyDesiredStateSagaWatcher,
];
