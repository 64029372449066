import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Theme, useMediaQuery, ListItemText } from '@material-ui/core';
import { Formik } from 'formik';
import { Breakpoints } from '../../../utils/styles/constants';
import { makeStyles } from '@material-ui/core/styles';
import { MultiSelect } from 'components/basicInputs/selectInput/MultiSelect';
import { IItemComponent } from 'components/basicInputs/selectInput/model';
import { TagBoxColor } from 'modules/task/common/taglist/TagBoxColor';
import { Close } from '@material-ui/icons';
import { selectTaskTags } from 'store/modules/task/taskTag/taskTagSelectors';
import { TaskTagType } from 'api/task/tag/taskTagModel';
import { useActions } from 'utils/store/useActions';
import { addFilterTaskTag, requestTaskTags } from 'api/task/tag/tagActions';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

const useFilterTagStyles = makeStyles((theme: Theme) => ({
    topEnvelope: {
        width: '100%',
        maxWidth: 300,
        minWidth: 300,
    },
    topEnvelopeMobile: {
        width: '100%',
    },
}));
type TagItemType = TaskTagType & {
    isSelected?: boolean;
}
const TagItem = ({ item: tag }: IItemComponent<TagItemType>) =>{
    return (
        <Box display="flex" alignItems="center" flexDirection="row">
            <TagBoxColor bgcolor={tag.color}> 
                {tag?.isSelected && <Close style={{color:"white"}} />}
            </TagBoxColor>
            <ListItemText primary={tag.name} />
        </Box>
    );
}

type TaskTaglistMultiselectprops = {
    onTagsValuesChange: (newValues: string[]) => void;
    initialTagSelected?: string[];
};

export const TaskTaglistMultiselect = ({ initialTagSelected = [], onTagsValuesChange }: TaskTaglistMultiselectprops) => {
    const classes = useFilterTagStyles([]);
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.Tablet),
        { noSsr: true },
    );
    const topEnvelopeStyle = useMemo(() => {
        return isMobile ? classes.topEnvelopeMobile : classes.topEnvelope;
    }, [isMobile, classes]);
    const tagList = useSelector(selectTaskTags);
    const actionsTaskTags = useActions({ 
        getTags: requestTaskTags.init,
        addFilterTaskTag: addFilterTaskTag
    });

    useEffect(()=>{
        actionsTaskTags.current.getTags()
        return ()=>{
            const cleanFilter = actionsTaskTags;
            cleanFilter.current.addFilterTaskTag([]);
        }
    },[actionsTaskTags])

    const initialValues = { tags: initialTagSelected};

    const tagsSelectedHandler = useCallback((values) => {
        onTagsValuesChange(values.tags)
        actionsTaskTags.current.addFilterTaskTag(values.tags)
    }, [onTagsValuesChange, actionsTaskTags]);

    return (
        <Box className={topEnvelopeStyle} ml={.5} mr={0.5}>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnChange={true}
                validate={(values) => {
                    tagsSelectedHandler(values);
                }}
            >
                <form onSubmit={() => {}}>
                    <Box>
                        <MultiSelect
                            name="tags"
                            label={`${t('Tag')}`}
                            placeholder={`${t('Tag Filter')}`}
                            options={tagList}
                            getName={(x) => { return (x as TaskTagType).name; }}
                            RenderPreview={TagItem}
                            RenderValue={TagItem}
                            showValuesSelected={false}
                            showCheckBox={false}
                            separateLabel={false}
                        />
                    </Box>
                </form>
            </Formik>
        </Box>
    );
}
