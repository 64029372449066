import { AssignmentTurnedIn } from '@material-ui/icons';
import { t } from 'i18next';
import { EmptyState as SharedEmptyState } from 'modules/shared/components/EmptyState';

type EmptyStateProps = {
    text?: string;
};
export const EmptyState = ({text = `${t('No tasks')}`}: EmptyStateProps) => {
    return (
        <SharedEmptyState
            text={text}
            Icon={AssignmentTurnedIn}
        />
    )
};
