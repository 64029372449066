import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';
import { Link } from 'react-router-dom';

export const TABLE_HEAD_HEIGHT = 52;

export const StyledTable = styled(Table)({
    boxSizing: 'border-box',
});
interface StyledTableHeadProps {
    width?: string;
    theme: Theme
}

export const StyledTableHead = styled(TableHead)(({ theme, width = 'auto' }: StyledTableHeadProps) => ({
    boxSizing: 'border-box',
    width: width,

    '& [role="columnheader"]': {
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: 600,
        fontSize: theme.spacing(1.5),
        lineHeight: theme.spacing(3) + 'px',
        userSelect: 'none',
    },
}));

export const StyledTableBody = styled(TableBody)({
    boxSizing: 'border-box',
    '& [role="row"]:hover': {
        background: UtilColors.Shadow1,
    },
});

export const StyledTableBodyMobile = styled(TableBody)({
    boxSizing: 'border-box',
    '&[role="rowgroup"]': {
        overflow: 'hidden'
    }
});

export const StyledTableRow = styled(TableRow)({
    boxSizing: 'border-box',
    background: UtilColors.Transparent,
    transition: 'background 0.3s',
});

export const StyledSelectedTableRow = styled(TableRow)({
    boxSizing: 'border-box',
    background: UtilColors.ShadowLight,
    transition: 'background 0.3s',
});

export const StyledLink = styled(Link)({
    color: 'unset',
    textDecoration: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

export const StyledTableCell = styled(TableCell)({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0 0 16px',

    '&:first-child': {
        flexShrink: 0,
        padding: 0,
        width: '7px',
        height: TABLE_HEAD_HEIGHT,
        marginRight: '10px',
    },
    '&:last-child': {
        padding: 0,
        width: TABLE_HEAD_HEIGHT,
        justifyContent: 'center',
    },
});
