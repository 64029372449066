import isNumber from 'lodash/isNumber';
import { ItemsById, IEntity } from 'model/entity';

export interface IVersionedEntity extends IEntity {
    version?: number;
}

export function upsertVersionedStateItem<T extends IVersionedEntity>(
    state: ItemsById<T>,
    item: T,
): ItemsById<T> {
    const { id, version } = item;
    const { version: prevVersion } = state[id] || {};

    if (isNumber(version) && isNumber(prevVersion) && prevVersion > version) {
        return state;
    }

    return {
        ...state,
        [id]: item,
    };
}
