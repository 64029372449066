import React, { useCallback, useState } from 'react';
import {
    Box,
    Button, Checkbox,
    CircularProgress,
    FormControl,
    makeStyles, styled,
    Switch,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import { PendingFile } from '../../../store/modules/files/filesModel';
import FileInput from '../../shared/components/FileInput';
import { Breakpoints } from '../../../utils/styles/constants';
import { useButtonStyles } from '../../../utils/styles/buttons';
import { CloudDownload } from '@material-ui/icons';
import clsx from 'clsx';
import { IUserBulkAction, UserBulkAction } from 'api/files/filesModel';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { Trans } from 'react-i18next';

export const useStep1Styles = makeStyles((theme: Theme) => ({
    stepEnvelope: {
        display: 'flex',
        flexFlow: 'column',
        overflow: 'hidden',
    },
    circular: {
        float: 'right',
    },
}));

const UserMassConfirmationDialogDescription = styled(Typography)({
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
});

interface IUserBulkImportStep1FormProps {
    isLoading: boolean;
    onSubmit: (files: PendingFile[], sendEmails: boolean) => void;
    onCancel: () => void;
    onChangeBulkAction: (event: React.ChangeEvent<HTMLInputElement>) => void;
    bulkTypeAction: IUserBulkAction,
    hideSwitch?: boolean;
    customClassesContainer?: string;
    customClassesHeader?: string;
    hideMailConfirmation?: boolean;
    customClassesContainerFile?: string;
    cancelClasses?: string;
}

export const UserBulkImportStep1 = ({
    isLoading,
    onSubmit,
    onCancel,
    onChangeBulkAction,
    bulkTypeAction,
    hideSwitch = false,
    customClassesContainer = '',
    customClassesHeader = '',
    hideMailConfirmation = false,
    customClassesContainerFile = '',
    cancelClasses = ''
} : IUserBulkImportStep1FormProps) => {
    const classes = useStep1Styles();
    const buttonClasses = useButtonStyles();
    const isBulkDeactivateUsersEnabled = useFeatureState(FeatureFlags.BulkDeactivateUsers);

    const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const width = isMobile ? '100%' : '100%';

    const [attachments, setAttachment] = useState<PendingFile[]>([]);
    const saveDisabled = (attachments ?? []).length < 1 || isLoading;
    const saveButtonClasses = !saveDisabled ? clsx(buttonClasses.muiRoundedButton, 'primary') : clsx(buttonClasses.muiRoundedButton, 'secondary');
    const [mailConfirmation, setMailConfirmation] = useState(false);

    const onAttachmentChange = useCallback((files: File[]) => {
        const file = files[0];
        if (file) {
            setAttachment([{
                title: file.name,
                localKey: '',
                localFile: file,
            }])
        }
    }, []);

    const onAttachmentRemove = useCallback(() => {
        setAttachment([]);
    }, []);

    const onSaveClick = useCallback(() => {
        onSubmit(attachments, mailConfirmation)
    }, [attachments, onSubmit, mailConfirmation]);

    return (
        <Box className={clsx(classes.stepEnvelope, customClassesContainer)} width={width}>
            <Box marginLeft='25px' marginBottom='25px' display='flex' justifyContent='space-between' className={customClassesHeader}>
                <Box lineHeight='36px' display='flex' alignItems='center'>
                    <Typography variant="subtitle1"><Trans>Upload the file (.CSV)</Trans></Typography>
                </Box>
                <Box marginRight='25px'>
                    <Button
                        className={buttonClasses.muiButton}
                        href={bulkTypeAction.templateUrl}
                    ><CloudDownload/><Trans>CSV template</Trans></Button>
                </Box>
            </Box>
            { (isBulkDeactivateUsersEnabled && !hideSwitch )&& 
                (
                    <Box marginLeft='25px' display="flex" alignItems="center">
                        <Typography variant='body1'><Trans>Activation</Trans></Typography>
                        <Switch
                            color="default"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                            onChange={onChangeBulkAction}
                        />
                        <Typography variant='body1'><Trans>Deactivation</Trans></Typography>
                    </Box>
                )
            }
            <FormControl
                variant="outlined"
            >
                <FileInput
                    attachments={attachments}
                    onAttachmentChange={onAttachmentChange}
                    onAttachmentRemove={onAttachmentRemove}
                    multiple={false}
                    isLoading={isLoading}
                    maxSizeMb={15}
                    fileTypes=".csv"
                    rejectMessage={null}
                    customClasses={customClassesContainerFile}
                />
            </FormControl>
            { (bulkTypeAction.type === UserBulkAction.Import && !hideMailConfirmation ) &&
                <Box display='flex' alignItems='center' paddingLeft='15px'>
                    <Checkbox
                        disabled={false}
                        color="primary"
                        checked={mailConfirmation}
                        onClickCapture={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setMailConfirmation(!mailConfirmation);
                        }}
                    />
                    <UserMassConfirmationDialogDescription><Trans>Send User Email Invitations</Trans></UserMassConfirmationDialogDescription>
                </Box>
            }
            <Box display={'flex'}>
                <Box marginLeft={'auto'}>
                    <Box margin={'15px'}>
                        {!isLoading && (
                        <Button
                            className={clsx(buttonClasses.muiRoundedButton, cancelClasses)}
                            onClick={onCancel}
                            disabled={isLoading}
                        ><Trans>Cancel</Trans></Button>
                        )}
                        {isLoading && (
                            <CircularProgress className={classes.circular} />
                        )}
                        {!isLoading && (
                            <Button
                                className={saveButtonClasses}
                                type={'submit'}
                                style={{ marginLeft: '15px' }}
                                disabled={saveDisabled}
                                onClick={onSaveClick}
                            ><Trans>Save</Trans></Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
