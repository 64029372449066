import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IBaseInputClasses } from 'components/basicInputs/model';
import { useField } from 'formik';
import {
    Box, Checkbox, FormHelperText, PaperProps, TextField,
} from '@material-ui/core';
import { BaseInput } from 'components/basicInputs/BaseInput';
import { useSelectStyles } from 'components/basicInputs/selectInput/selectStyles';
import { Close } from '@material-ui/icons';
import { IItemComponent } from 'components/basicInputs/selectInput/model';
import { IEntity } from 'model/entity';
import { hasFormikError } from 'components/basicInputs/utils';
import { Autocomplete } from '@material-ui/lab';
import { SelectAllPaper } from 'components/basicInputs/selectInput/SelectAllPaper';
import { selectParentTasksFetching } from 'store/modules/task/parentTask/parentTaskSelectors';
import { useSelector } from 'react-redux';
import { selectTasksStatsLoadingByLocationIds } from 'store/modules/task/statistics/statisticsSelectors';
import { t } from 'i18next';

interface ISelectInputProps<T extends IEntity> {
    name: string;
    id?: string;
    label: string | React.ReactNode;
    options: Array<T>;
    getName: (item: T) => string;
    RenderValue: React.FC<IItemComponent<T>>;
    RenderPreview: React.FC<IItemComponent<T>>,
    classes?: IBaseInputClasses;
    users?: boolean;
    showValuesSelected?: boolean;
    showCheckBox?: boolean;
    separateLabel?: boolean;
    placeholder?: string;
    disabled?: boolean;
}

export function MultiSelect<T extends IEntity>({
    name, label, id = name, options, getName, RenderValue, RenderPreview, classes: baseClasses, users = false,
    showValuesSelected = true, showCheckBox = true, separateLabel = true, placeholder = '', disabled = false
}: ISelectInputProps<T>) {
    const classes = useSelectStyles([]);
    const [field, meta] = useField(name);
    const { value, onChange } = field;
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [countSelected, setCountSelected] = useState<number>(0);
    const parentTasksFetching = useSelector(selectParentTasksFetching);
    const parentTasksLocationsFetching = useSelector(selectTasksStatsLoadingByLocationIds);
    const parentTaskLocationsFetching = Object.keys(parentTasksLocationsFetching).some(locId => parentTasksLocationsFetching[locId] === true);

    useEffect(() => {
        setAllSelected(options.length === countSelected && countSelected > 0);
    }, [options, setAllSelected, countSelected]);

    const handleChange = useCallback((values: Array<string>) => {
        onChange({ target: { name, value: values } });
        setCountSelected(values.length);
        setAllSelected(options.length === values.length);
    }, [onChange, name, setAllSelected, options]);

    const toggleSelectAll = useCallback((checked: boolean) => {
        if (!checked) {
            handleChange([]);
        } else {
            handleChange(options.map(option => option.id));
        }
        setAllSelected(checked);
    }, [setAllSelected, handleChange, options]);

    const selectAllPaperComponent = useMemo(() => function ({ ...props }: PaperProps) {
        return (
            <SelectAllPaper allSelected={allSelected} toggleSelectAll={toggleSelectAll}
                disabled={disabled} {...props}
            />
        );
    }, [toggleSelectAll, allSelected, disabled]);

    const customOnChange = useCallback((changes: React.ChangeEvent<{}>, newValue) => {
        if(!disabled){
            handleChange(newValue.map((item: T | string) => typeof item === 'string' ? item : item.id));
        }
    }, [handleChange, disabled]);

    const onRemove = useCallback((removedId: string) => {
        if(!disabled){
            handleChange(value.filter((valueId: string) => valueId !== removedId));
        }
    }, [handleChange, value, disabled]);

    const placeholderRender = useMemo(
        () => value.length > 0 ? '' : placeholder,
        [value,placeholder],
    );

    return (
        <BaseInput id={id} label={label} separateLabel={separateLabel}
            classes={baseClasses}
        >
            <Autocomplete
                getOptionDisabled={() => (parentTasksFetching || parentTaskLocationsFetching)}
                classes={{
                    root: classes.autocompleteRoot,
                    inputRoot: classes.autocompleteInputRoot,
                    input: classes.autocompleteInput,
                    paper: classes.menuPaper,
                    listbox: classes.menuList,
                }}
                id={id}
                {...field}
                onChange={customOnChange}
                options={options}
                PaperComponent={selectAllPaperComponent}
                getOptionLabel={getName}
                getOptionSelected={(option: T, autocompleteValue: T) => {
                    // Autocomplete provided types are incorrect, because we store array of string instead of T
                    const values = autocompleteValue as unknown as Array<string>;
                    return values.includes(option.id);
                }}
                renderTags={tags => tags.length > 0 ? `${tags.length} ${t('Selected')}` : null}
                disableCloseOnSelect={true}
                renderOption={option => {
                    const item: T = option as T;
                    return (
                        <>
                            <RenderValue item={{...item, isSelected: value.includes(item.id)}} />
                            {showCheckBox &&
                                <Checkbox
                                    disabled={disabled}
                                    className={classes.radio}
                                    checked={value.includes(item.id)}
                                    color="primary"
                                />}
                        </>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={placeholderRender}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            'data-lpignore': true,
                        }}
                    />
                )}
                multiple={true}
            />
            {hasFormikError(meta) && (
                <FormHelperText error>
                    {meta.error}
                </FormHelperText>
            )}
            {value.length > 0 && showValuesSelected && (
                <Box display="flex" flexWrap="wrap"
                    mt={1} ml={users? 2 : -0.5}
                >
                    {options.filter(option => value.includes(option.id)).map(option => (
                        <Box key={option.id} className={users ? classes.selectChipAvatars : classes.selectPreview}
                            onClick={onRemove.bind(null, option.id)}
                        >
                            {
                                users ? <RenderPreview item={option} /> : <><Box className={classes.selectChip}>
                                    <RenderPreview item={option} />
                                </Box>
                                    <Close className={classes.removeIcon} fontSize="small" /></>
                            }
                        </Box>
                    ))}
                </Box>
            )}
        </BaseInput>
    );
}
