import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { IResponseErrorPayload } from 'model/error';
import { ConversationType, ConversationsStoreTaskType, CreateTasksConversationType, EditCommentType, ReactionAdd, ReactionDelete, StoreTaskConversationsType, SuggestionListType } from './taskConversationModel';
import { IEntityError, IEntitySuccess } from 'model/entity';

const REQUEST_TASK_CONVERSATION_ADD = 'request/task/conversation/ADD';
const RESPONSE_TASK_CONVERSATION_ADD_SUCCESS = 'response/task/conversation/ADD_SUCCESS';
const RESPONSE_TASK_CONVERSATION_ADD_ERROR = 'response/task/conversation/ADD_ERROR';

export const requestCreateConversation = createApiActions<CreateTasksConversationType, {id:string}, IResponseErrorPayload, typeof REQUEST_TASK_CONVERSATION_ADD,
    typeof RESPONSE_TASK_CONVERSATION_ADD_SUCCESS, typeof RESPONSE_TASK_CONVERSATION_ADD_ERROR
>(
    REQUEST_TASK_CONVERSATION_ADD, RESPONSE_TASK_CONVERSATION_ADD_SUCCESS, RESPONSE_TASK_CONVERSATION_ADD_ERROR,
);

export const REQUEST_SUGGESTION_LIST = 'request/task/conversation/SUGGESTION_LIST';
export const requestSuggestionList = (
    createApiActionsByName<string, SuggestionListType, IEntityError, typeof REQUEST_SUGGESTION_LIST>(
        REQUEST_SUGGESTION_LIST,
    )
);

export const REQUEST_TASK_CONVERSATIONS_BY_STORE_GET = 'request/task/conversations_by_store/GET';
export const requestTaskConversationsByStore = (
    createApiActionsByName<string, ConversationsStoreTaskType, IEntityError, typeof REQUEST_TASK_CONVERSATIONS_BY_STORE_GET>(
        REQUEST_TASK_CONVERSATIONS_BY_STORE_GET,
    )
);

export const REQUEST_TASK_CONVERSATIONS_BY_TASK_GET = 'request/task/conversations_by_task/GET';
export const requestTaskConversationsByTask = (
    createApiActionsByName<string, StoreTaskConversationsType, IEntityError, typeof REQUEST_TASK_CONVERSATIONS_BY_TASK_GET>(
        REQUEST_TASK_CONVERSATIONS_BY_TASK_GET,
    )
);

const REQUEST_TASK_COMMENT_DELETE = 'request/task/conversation/DELETE';
export const requestDeleteTaskComment = (
    createApiActionsByName<string, IEntitySuccess, IEntityError , typeof REQUEST_TASK_COMMENT_DELETE>(
        REQUEST_TASK_COMMENT_DELETE,
    )
);

const REQUEST_TASK_COMMENT_REACTION_ADD = 'request/task/conversation/reaction/ADD';
export const requestAddCommentReaction = (
    createApiActionsByName<ReactionAdd, ConversationType, IEntityError , typeof REQUEST_TASK_COMMENT_REACTION_ADD>(
        REQUEST_TASK_COMMENT_REACTION_ADD,
    )
);

const REQUEST_TASK_COMMENT_REACTION_DELETE = 'request/task/conversation/reaction/DELETE';
export const requestDeleteCommentReaction = (
    createApiActionsByName<ReactionDelete, ConversationType, IEntityError , typeof REQUEST_TASK_COMMENT_REACTION_DELETE>(
        REQUEST_TASK_COMMENT_REACTION_DELETE,
    )
);

const REQUEST_TASK_COMMENT_EDIT = 'request/task/conversation/comment/EDIT';
const RESPONSE_TASK_COMMENT_EDIT_SUCCESS = 'response/task/conversation/comment/EDIT_SUCCESS';
const RESPONSE_TASK_COMMENT_EDIT_ERROR = 'response/task/conversation/comment/EDIT_ERROR';
export const requestEditComment = createApiActions<EditCommentType, ConversationType, IResponseErrorPayload, typeof REQUEST_TASK_COMMENT_EDIT,
    typeof RESPONSE_TASK_COMMENT_EDIT_SUCCESS, typeof RESPONSE_TASK_COMMENT_EDIT_ERROR
>(
    REQUEST_TASK_COMMENT_EDIT, RESPONSE_TASK_COMMENT_EDIT_SUCCESS, RESPONSE_TASK_COMMENT_EDIT_ERROR,
);


export type TaskConversationApiActions = (
    | ActionsReturnTypes<typeof requestCreateConversation>
    | ActionsReturnTypes<typeof requestSuggestionList>
    | ActionsReturnTypes<typeof requestTaskConversationsByStore>
    | ActionsReturnTypes<typeof requestTaskConversationsByTask>
    | ActionsReturnTypes<typeof requestDeleteTaskComment>
    | ActionsReturnTypes<typeof requestAddCommentReaction>
    | ActionsReturnTypes<typeof requestDeleteCommentReaction>
    | ActionsReturnTypes<typeof requestEditComment>
);
