import { combineReducers } from 'redux';
import {
    TaskListSortBy,
} from 'store/modules/task/taskListComponent/taskListComponentModel';
import {
    TASK_PARENT_ACTIVE,
    TASK_LIST_REMOVE_PARENTS_CHILDREN,
    TASK_LIST_REMOVE_STORE,
    TASK_LIST_SET_PARENT_TASKS_SORTING,
    TASK_LIST_SET_STORE_TASKS_SORTING,
    TaskListComponentActions,
    SET_STORE_TASK_TO_REMOVE_ID,
} from 'store/modules/task/taskListComponent/taskListComponentActions';
import {
    requestParentTaskChildren,
    requestParentTaskDetails,
    TaskDetailsActions,
} from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import {
    ParentTaskChildModel,
    ParentTaskDetailsModel,
} from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { itemsByIds } from 'utils/helpers/itemsByIds';

import {
    CHECKLIST_ITEM_FILE_NOT_FOUND,
    requestApproveStoreTask,
    requestChecklistItemFileRemove,
    requestChecklistItemFileUpload,
    requestChecklistItemSetCompleted,
    requestDeclineStoreTask,
    requestStoreTaskGet,
    requestSubmitStoreTask,
    StoreTaskApiActions,
} from 'api/task/storeTask/storeTaskActions';
import { removeTaskChecklistFile } from 'store/modules/task/taskCommon/taskCommonHelpers';
import { upsertVersionedStateItem } from '../storeTask/storeTaskHelpers';
import { StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { ItemsById } from 'model/entity';
import { ParentTaskActions, requestStoreTasksDelete } from 'api/task/parentTask/parentTaskActions';

const initialState = {
    childrenTasksById: {},
    parentChildrenTasks: {},
    parentChildrenTasksLoading: {},
    parentTaskDetailsLoading: {},
    parentTasksSortBy: 'not-set' as unknown as TaskListSortBy,
    storeTasksSortBy: 'not-set' as unknown as TaskListSortBy,
    storeTaskDeleteByIdLoading: {},
    activeParentTaskId: '',
    storeTaskToRemove: null,
};

function parentTasksSortBy(
    state: TaskListSortBy = initialState.parentTasksSortBy,
    action: TaskListComponentActions,
): TaskListSortBy {
    switch (action.type) {
        case TASK_LIST_SET_PARENT_TASKS_SORTING:
            return action.payload;
        default:
            return state;
    }
}

function storeTasksSortBy(
    state: TaskListSortBy = initialState.storeTasksSortBy,
    action: TaskListComponentActions,
): TaskListSortBy {
    switch (action.type) {
        case TASK_LIST_SET_STORE_TASKS_SORTING:
            return action.payload;
        default:
            return state;
    }
}

function childrenTasksById(
    state: ItemsById<ParentTaskDetailsModel | ParentTaskChildModel | StoreTaskFullModel>
        = initialState.childrenTasksById,
    action: TaskDetailsActions | TaskListComponentActions | StoreTaskApiActions,
): ItemsById<ParentTaskDetailsModel | ParentTaskChildModel | StoreTaskFullModel> {
    switch (action.type) {
        case requestParentTaskChildren.successType:
            return {
                ...state,
                ...itemsByIds(action.payload.children),
            };
        case requestStoreTaskGet.successType:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        case requestChecklistItemSetCompleted.successType:
            return upsertVersionedStateItem(state, action.payload.task);
        case requestParentTaskDetails.successType:
            return { ...state, [action.payload.id]: action.payload };
        case requestChecklistItemFileUpload.successType:
            return upsertVersionedStateItem(state, action.payload.task);

        case requestChecklistItemFileRemove.successType:
            return upsertVersionedStateItem(state, action.payload);
        case CHECKLIST_ITEM_FILE_NOT_FOUND:
            return removeTaskChecklistFile(state, action.payload);

        case requestApproveStoreTask.successType:
        case requestDeclineStoreTask.successType:
        case requestSubmitStoreTask.successType:
            return upsertVersionedStateItem(state, action.payload);

        case TASK_LIST_REMOVE_STORE: {
            const cloneState = { ...state };
            delete cloneState[action.payload];
            return cloneState;
        }
        default:
            return state;
    }
}

function parentChildrenTasks(
    state: ItemsById<string[]> = initialState.parentChildrenTasks, action: TaskDetailsActions | TaskListComponentActions,
): ItemsById<string[]> {
    switch (action.type) {
        case requestParentTaskChildren.successType:
            const { parentId, children } = action.payload;
            return {
                ...state,
                [parentId]: children.map(({ id }) => id),
            };

        case TASK_LIST_REMOVE_PARENTS_CHILDREN: {
            const { parent, childrenId } = action.payload;
            return {
                ...state,
                [parent]: state[parent]?.filter(parentId=>parentId !== childrenId)
            };
        }
        default:
            return state;
    }
}

function parentChildrenTasksLoading(
    state: ItemsById<boolean> = initialState.parentChildrenTasksLoading, action: TaskDetailsActions,
): ItemsById<boolean> {
    switch (action.type) {
        case requestParentTaskChildren.initType:
            return { ...state, [action.payload]: true };
        case requestParentTaskChildren.successType:
            return { ...state, [action.payload.parentId]: false };
        case requestParentTaskChildren.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function parentTaskDetailsLoading(
    state: ItemsById<boolean> = initialState.parentTaskDetailsLoading, action: TaskDetailsActions,
): ItemsById<boolean> {
    switch (action.type) {
        case requestParentTaskDetails.initType:
            return { ...state, [action.payload]: true };

        case requestParentTaskDetails.successType:
        case requestParentTaskDetails.errorType:
            return { ...state, [action.payload.id]: false };

        default:
            return state;
    }
}

function storeTaskDeleteByIdLoading(
    state: ItemsById<boolean> = initialState.storeTaskDeleteByIdLoading, action: ParentTaskActions,
): ItemsById<boolean> {
    switch (action.type) {
        case requestStoreTasksDelete.initType:
            return { ...state, [action.payload]: true };

        case requestStoreTasksDelete.successType:
        case requestStoreTasksDelete.errorType:
            return { ...state, [action.payload.storeTaskId]: false };

        default:
            return state;
    }
}


function activeParentTaskId(
    state: string = initialState.activeParentTaskId, action: TaskListComponentActions,
): string {
    switch (action.type) {
        case TASK_PARENT_ACTIVE:
            return action.payload;
        default:
            return state;
    }
}

export function storeTaskToRemove(
    state = initialState.storeTaskToRemove,
    action: TaskListComponentActions | ParentTaskActions,
): string | null {
    switch (action.type) {
        case SET_STORE_TASK_TO_REMOVE_ID:
            return action.payload;
        case requestStoreTasksDelete.initType:
            return null
        default:
            return state;
    }
}


export const taskListComponentReducer = combineReducers({
    parentTasksSortBy,
    storeTasksSortBy,
    childrenTasksById,
    parentChildrenTasks,
    parentChildrenTasksLoading,
    parentTaskDetailsLoading,
    storeTaskDeleteByIdLoading,
    activeParentTaskId,
    storeTaskToRemove
});
