import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ActionCreatorKnownArgs } from 'utils/store/actionUtils';
import { convertToStringType, DataType, requiredStringType } from 'model/dataBasicTypes';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { logger } from 'utils/logger';
import { createWorkerMessage, workerSendMessage } from 'api/helpers';

export const transformFieldName = (fieldName: string) => fieldName.charAt(0).toUpperCase().concat(
    fieldName.slice(1).replace(/[A-Z]/g, ' $&'),
);

export function createStrictSchema(schema: Record<string, DataType>) {
    return Object.entries(schema).reduce((acc: Record<string, any>, [key, value]) => ({
        ...acc,
        [key]: value === convertToStringType ? requiredStringType : value,
    }), {});
}

async function decodeArrayItem<Output, Input>(type: t.Decoder<Input, Output>, value: Input) {
    try {
        return await tPromise.decode(type, value);
    } catch (e) {
        return undefined;
    }
}

export async function validateResponseArray<Output, StrictOutput, Input>(
    successAction: ActionCreatorKnownArgs<Array<Output>, any>, errorAction: ActionCreatorKnownArgs<any, any>,
    reporter: ReporterType,
    decoder: t.Decoder<Input, Output>, values: Array<Input>, strictDecoder?: t.Decoder<Input, StrictOutput>,
) {
    const response = (await Promise.all(values.map(item => decodeArrayItem(decoder, item))))
        .filter(decodedItem => decodedItem !== undefined) as Array<Output>;

    if (response.length > 0) {
        workerSendMessage(createWorkerMessage(successAction(response)));
    } else {
        workerSendMessage(createWorkerMessage(errorAction({})));
    }

    if (strictDecoder) {
        validateSchema(values, strictDecoder, reporter);
    }
}

export async function validateSchema<Input, Output>(
    values: Array<Input>,
    strictDecoder: t.Decoder<Input, Output>,
    reporter: ReporterType,
) {
    values.forEach(item => {
        tPromise.decode(strictDecoder, item)
            .catch(e => {
                processErrors(e.errors, reporter);
            });
    });
}

export type ReporterType = (errors: t.Errors) => Array<string>;

function processErrors(errors: t.Errors, reporter: ReporterType) {
    reporter(errors).forEach(error => {
        logger.reportMessage(error);
        workerSendMessage(createWorkerMessage(showToastMessage({
            message: error, options: { variant: ToastVariant.Error, autoHideDuration: 10000 },
        })));
    });
}

export async function validateResponse<Output, StrictOutput, Input>(
    successAction: ActionCreatorKnownArgs<Output, any>,
    errorAction: ActionCreatorKnownArgs<any, any>,
    reporter: ReporterType,
    decoder: t.Decoder<Input, Output>,
    value: Input,
    strictDecoder?: t.Decoder<Input, StrictOutput>,
) {
    try {
        const result = await tPromise.decode(decoder, value);
        workerSendMessage(createWorkerMessage(successAction(result)));

        if (strictDecoder) {
            try {
                await tPromise.decode(strictDecoder, value);
            } catch (e) {
                processErrors(e.errors, reporter);
            }
        }
    } catch (e) {
        processErrors(e.errors, reporter);
        workerSendMessage(createWorkerMessage(errorAction(e)));
    }
}
