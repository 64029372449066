import { useCallback } from 'react';
import { authLogout } from 'api/auth/authActions';
import { useActions } from "utils/store/useActions";

export function useSignOut() {
    const actions = useActions({ logout: authLogout });

    const handleSignOut = useCallback(() => {
        actions.current.logout();
    }, [actions]);

    return handleSignOut;
}
