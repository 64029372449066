import React, { useCallback } from 'react'
import { DialogTemplate } from './DialogTemplate';
import { useSelector } from 'react-redux';
import { selectModalEditionTemplate } from 'store/modules/task/taskTemplate/taskTemplateSelectors';
import { useActions } from 'utils/store/useActions';
import { confirmationEditTemplate, setShowModalEditTemplate } from 'store/modules/task/taskTemplate/taskTemplateActions';
import { t } from 'i18next';

export const ModalToEditTemplate = () => {
  const modaEditionTemplate = useSelector(selectModalEditionTemplate);
  const actions = useActions({ setShowModalEditTemplate, confirmationEditTemplate });

  const handleOnClose = useCallback(()=> actions.current.setShowModalEditTemplate({isOpened:false, taskTemplate: null}),[actions]);

  const handleOnContinue = useCallback(()=> actions.current.confirmationEditTemplate(true),[actions])

  return (
    <DialogTemplate
      open={modaEditionTemplate.isOpened}
      onCancel={handleOnClose}
      onContinue={handleOnContinue}
      title={`${t('This template has associated locations for which you do not have permissions. Do you want to proceed?')}`}
      hideBodyText
    />
  )
}
