import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/store/useActions';
import { selectEventModalActiveId } from 'store/modules/task/calendarTask/calendarSelectors';
import { eventModalActiveId, resetEventCalendarDetail } from 'store/modules/task/calendarTask/calendarTaskActions';
import { TaskSlideout } from 'modules/task/common/TaskSlideout';
import { EventHeader } from './EventHeader';
import { EventContent } from './EventContent';

export const EventSideOut: React.FC = () => {
    const actions = useActions({ closeSideOut: eventModalActiveId, resetEventDetail: resetEventCalendarDetail });
    const eventId = useSelector(selectEventModalActiveId);

    const onModalClose = useCallback(() => {
        actions.current.resetEventDetail();
        actions.current.closeSideOut(null);
    }, [actions]);

    return (
        <TaskSlideout
            open={!!eventId}
            onClose={onModalClose}
            header={<EventHeader />}
        >
            <EventContent />
        </TaskSlideout>
    )
}
