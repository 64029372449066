import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Close as CloseIcon } from '@material-ui/icons';
import { ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useRemoveUserFromChannel } from 'modules/connect/hooks/useRemoveUserFromChannel';
import { useConnect } from 'modules/connect/context/ConnectContext';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { ConfirmationDialog } from 'components/dialogs/ConfirmationDialog';
import { selectUser } from 'store/modules/auth/authSelectors';
import { useChatContext } from 'stream-chat-react';
import { Trans } from 'react-i18next';

export interface IChannelMemberActionsProps {
    userId: string;
    name: string;
    isAdmin: boolean;
}

export function ChannelMemberActions(props: IChannelMemberActionsProps) {
    const { userId, isAdmin } = props;
    const currentUser = useSelector(selectUser);
    const isCurrentUser = userId === currentUser?.id;
    const canRemoveUser = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));

    return (
        <ListItemSecondaryAction style={{right: 0}}>
            {canRemoveUser && !isCurrentUser  && !isAdmin && <RemoveUserFromChannelButton {...props} />}
        </ListItemSecondaryAction>
    );
}

function RemoveUserFromChannelButton({ userId, name }: IChannelMemberActionsProps) {
    const connectContext = useConnect();
    const { channel } = useChatContext();
    const { conversationsService, clientService } = connectContext?.connectService || {};

    const handleRemove = useRemoveUserFromChannel(userId, channel);
    const { isOpened, onOpen, onClose } = useOpenedState();
    const handleAgree = useCallback(() => {
        handleRemove();
        onClose();
    }, [handleRemove, onClose]);
    const text =
        (<Trans i18nKey="textToRemoveUserFromChannel" name={name}>
            {{name}} will be removed from the channel
        </Trans>);
    if (!conversationsService || !clientService) {
        return null;
    }

    return (
        <>
            <IconButton onClick={onOpen}>
                <CloseIcon/>
            </IconButton>

            <ConfirmationDialog
                open={isOpened}
                text={text}
                onDiscard={onClose}
                onAgree={handleAgree}
                agreeButtonText={<Trans>Remove</Trans>}
                discardButtonText={<Trans>Cancel</Trans>}
            />
        </>
    );
}
