import { Box, Button, CircularProgress } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import { useCallback, useMemo } from "react";
import { editCreatorSchema } from "./validation";
import { AutocompleteDropdown } from "components/basicInputs/selectInput/AutocompleteDropdown";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { selectUsersByIds } from "store/modules/user/userSelectors";
import { useTaskCreatorStyles } from "./taskCreatorStyles";
import { selectEditTaskCreatorIsFetching, selectUserCanReassignIds } from "store/modules/task/editTaskCreator/editTaskCreatorSelectors";
import { Trans } from "react-i18next";
import { useActions } from "utils/store/useActions";
import { changeEdition, updateTaskCreator } from "store/modules/task/editTaskCreator/editTaskCreatorActions";

const EditCreatorForm: React.FC<FormikProps<any>& { isDisabled: boolean; }> = ({ handleSubmit, isDisabled }) => {
    const userIds = useSelector(selectUserCanReassignIds);
    const classes = useTaskCreatorStyles();
    const userByIds = useSelector(selectUsersByIds);
    const users = userIds.map((id: string) => {
        const user = userByIds[id];
        return { id: user?.id, fullName: `${user?.firstName} ${user?.lastName}`}
    });
    const actions = useActions({ changeEdition });

    const handleCancelEditCreator = useCallback(()=> actions.current.changeEdition(false), [actions]);
    
    return (
        <form onSubmit={handleSubmit} data-testid="edit-creator-form">
            <Box mt={2}>
                <AutocompleteDropdown
                    classes={{ root:classes.autocompleteCreator }}
                    name="targetUserId"
                    label={t('Edit Creator')}
                    options={users}
                    keyProp='id'
                    valueProp='fullName'
                    disabled={isDisabled}
                />
            </Box>
            <Box mt={1} mb={1} display="flex" alignItems="center" style={{gap: 6}}>
                <Button type="submit" color='primary' size="small" className={classes.saveBtn} disabled={isDisabled}><Trans>Save</Trans></Button>
                <Button color='secondary' size="small" className={classes.cancelBtn} onClick={handleCancelEditCreator} disabled={isDisabled}><Trans>Cancel</Trans></Button>
                { isDisabled && <CircularProgress size="24px" />}
            </Box>
        </form>
    )
};

export type ViewDateRangeProps = {
    targetUserId: string;
    prototypeId: string;
};

export const EditCreator: React.FC<ViewDateRangeProps> = ({ targetUserId, prototypeId }) => {
    const fetching = useSelector(selectEditTaskCreatorIsFetching);
    const initialFormValues = useMemo(
        () => ({ targetUserId,prototypeId}),
        [targetUserId,prototypeId]
    );
    const actions = useActions({ updateRegularTaskCreator: updateTaskCreator });

    const onSubmit = useCallback((newValue) => {
        actions.current.updateRegularTaskCreator(newValue)
    }, [actions]);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={editCreatorSchema}
            onSubmit={onSubmit}
        >
            {(props) => <EditCreatorForm {...props} isDisabled={fetching}/>}
        </Formik>
    );
};
