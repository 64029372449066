import { UserListFilter } from './model';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserRoles } from '../../../store/modules/user/userSelectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { Formik } from 'formik';
import { MultiSelect } from '../../../components/basicInputs/selectInput/MultiSelect';
import { UserRoleDTOExtended } from '../../../api/core/model';
import { RoleItem } from './RoleItem';
import { selectUser } from '../../../store/modules/auth/authSelectors';
import { requestCompanyRoles } from '../../../api/core/actions';
import { Breakpoints } from '../../../utils/styles/constants';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'i18next';

export type UserRoleFilterPanelProps = {
    filter: UserListFilter;
    onFilterChange: (filter: UserListFilter) => void;
}

const useActiveFilterRoleStyles = makeStyles((theme: Theme) => ({
    topEnvelope: {
        width: '100%',
        marginLeft: '5px',
    },
    topEnvelopeMobile: {
        width: '100%',
        marginLeft: '0px',
    },
}));

export const UserRoleFilterPanel = ({ filter, onFilterChange }: UserRoleFilterPanelProps) => {
    const classes = useActiveFilterRoleStyles([]);
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const topEnvelopeStyle = useMemo(() => {
        return isMobile ? classes.topEnvelopeMobile : classes.topEnvelope;
    }, [isMobile, classes]);
    const dispatch = useDispatch();
    const currentUser = useSelector(selectUser);
    const isSuperAdmin = currentUser?.isSuperAdmin ?? false;
    const roles = useSelector(selectUserRoles);

    const companyId = useMemo(() => {
        return isSuperAdmin ? filter?.CompanyId : currentUser?.companyId ?? '';
        }, [currentUser, filter?.CompanyId, isSuperAdmin]);
    const companyRoles = useMemo(() => roles.filter(x => x.companyId === companyId),
        [roles, companyId]);
    const roleList = companyRoles?.map(x => {
        return {
            ...x,
            id: x.roleId,
        } as UserRoleDTOExtended;
    });
    useEffect(() => {
        if (isSuperAdmin && filter?.CompanyId) {
            dispatch(requestCompanyRoles.init(filter.CompanyId));
        }
    }, [dispatch, filter?.CompanyId, isSuperAdmin]);
    const [rolesFilter, setRolesFilter] = useState(filter.RoleIds);

    const initialValues = {role_ids: rolesFilter || []};

    const userRolesSelectedHandler = useCallback((values) => {
        setRolesFilter(values.role_ids);
        if(isSuperAdmin && filter?.CompanyId) {
            onFilterChange({
                ...filter,
                RoleIds: values.role_ids || null,
            });
        } else if (!isSuperAdmin) {
            onFilterChange({
                ...filter,
                RoleIds: values.role_ids || null,
            });
        }
    }, [filter, setRolesFilter, onFilterChange, isSuperAdmin]);

    return (
        <Box className={topEnvelopeStyle}>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnChange={true}
                validate={(values) => {
                    userRolesSelectedHandler(values);
                }}
            >
                <form onSubmit={() => {}}>
                    <Box>
                        <MultiSelect
                            name={'role_ids'}
                            label={`${t('Role')}`}
                            options={roleList}
                            getName={(x) => { return (x as UserRoleDTOExtended).description; }}
                            RenderPreview={RoleItem}
                            RenderValue={RoleItem}
                        />
                    </Box>
                </form>
            </Formik>
        </Box>
    );
}
