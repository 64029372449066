import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
    requestCreateTask,
    requestParentTasks,
    requestStoreTasks,
    requestTaskDetails,
    requestTasksGet,
    TASK_LIST_CHECK_REQUEST,
    TaskActions,
    TaskListCheckActions,
    TaskListSuccessActions,
} from 'api/task/taskActions';
import { resetPendingFiles, resetUploadedFiles } from '../files/filesActions';
import { navigateTo, showToastMessage } from '../appState/appStateActions';
import { browserHistory, Routes } from 'components/router/model';
import { ToastVariant } from '../appState/appStateModel';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { SerializableError } from 'utils/error/serializableError';
import { generatePath } from 'react-router-dom';
import { selectUsersIds } from '../user/userSelectors';
import { taskListComponentSagas } from './taskListComponent/taskListComponentSagas';
import { storeTaskSagas } from './storeTask/storeTaskSagas';
import { taskCommonSagas } from './taskCommon/taskCommonSagas';
import { TaskListShortItem } from 'api/task/common/taskCommonModel';
import { taskReviewSagas } from './taskReview/taskReviewSagas';
import { statisticsSagas } from './statistics/statisticsSagas';
import { prototypesSagas } from './prototypes/prototypesSagas';
import { requestParentTaskDetailsCombined } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { requestStoreTaskGetParentIds } from 'api/task/storeTask/storeTaskActions';
import { scheduledTaskSagas } from './scheduledTask/scheduledTaskSagas';
import { FeatureFlags } from 'utils/featureFlags';
import { selectFeatureFlags } from '../featureFlags/selectors';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from '../featureFlags/model';
import { watcherSagas } from './watcher/watcherSagas';
import { taskTemplateSagas } from './taskTemplate/taskTemplateSagas';
import { resetGetTaskTemplate } from 'api/task/taskTemplate/taskTemplateActions';
import { taskFilterSagas } from './taskFilter/taskFilterSagas';
import { taskParentActive } from './taskListComponent/taskListComponentActions';
import { taskGallerySagas } from './taskGallery/taskGallerySagas';
import { selectStoreTasksFetching } from './storeTask/storeTaskSelectors';
import { StoreTaskSearchRequest } from 'api/task/storeTask/storeTaskModel';
import { selectUser } from '../auth/authSelectors';
import { ParentTaskSearchRequest } from 'api/task/parentTask/parentTaskModel';
import { selectParentTasksFetching } from './parentTask/parentTaskSelectors';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { AutomationTaskSearchRequest } from 'api/task/automationTask/automationTaskModel';
import { requestAutomationTasks } from 'api/task/automationTask/automationTaskActions';
import { selectHasAppAccess } from '../apps/appsSelector';
import { AppName } from '../apps/appsModel';
import { calendarTaskSagas } from './calendarTask/calendarTaskSagas';
import i18n from 'i18n';
import { taskConversationSagas } from './taskConversation/taskConversationSagas';
import { editTaskCreatorSagas } from './editTaskCreator/editTaskCreatorSagas';
import { editTaskSagas } from './editTask/editTaskSagas';
import { taskBulkSagas } from './TasksBulk/tasksBulkSaga';
import { calendarFilterSagas } from './calendarFilter/calendarFilterSagas';

function* taskCreateSuccessSaga() {
    yield* takeLatest(
        requestCreateTask.successType,
        function* ({ payload: task }: ReturnType<typeof requestCreateTask.success>) {
            const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
            const areScheduledTasksEnabled = featureFlags[FeatureFlags.ScheduledTasks]?.enabled;
            const redirectRoute = task.isScheduled && areScheduledTasksEnabled
                ? Routes.TaskListScheduled
                : Routes.TaskList;

            yield put(resetPendingFiles());
            yield put(resetUploadedFiles());
            browserHistory.push(generatePath(redirectRoute));
            yield put(showToastMessage({
                message: i18n.t('Task successfully created'),
                options: {
                    variant: ToastVariant.Success,
                },
            }));
        },
    );
}

function* taskListSuccessSaga({ payload: tasks }: ReturnType<typeof requestTasksGet.success>) {
    const userIds = Array.from(
        new Set(tasks.map(task => task.createdBy)),
    );
    yield put(requestMultipleUsersInfo.init(userIds));
}

function* taskListSuccessWatcher() {
    yield* takeLatest(requestTasksGet.successType, taskListSuccessSaga);
}

function taskErrorSaga(action: TaskActions) {
    const error = action.payload as SerializableError;
    if (error.message?.indexOf('403') >= 0) {
        browserHistory.push(Routes.Forbidden);
    }
}

function* taskErrorWatcher() {
    yield* takeEvery(requestTaskDetails.errorType, taskErrorSaga);
}

function* parentTasksSuccessWatcher() {
    /**
     * Checks if task authors are presented in users store - otherwise request data for them
     */
    yield* takeEvery(
        [requestParentTasks.successType, requestStoreTasks.successType],
        function* (action: TaskListSuccessActions) {
            const userIds: Array<string> = yield select(selectUsersIds);
            const tasks: Array<TaskListShortItem> = action.payload.tasks;
            const creatorIdList = tasks.map(item => item.createdBy);
            const newUserIds = uniq(creatorIdList);
            const usersToRequest = difference(newUserIds, userIds);
            yield put(resetGetTaskTemplate());
            if (usersToRequest.length > 0) {
                yield put(requestMultipleUsersInfo.init(usersToRequest));
            }
        },
    );
}

function* requestTaskGetErrorWatcher() {
    yield takeEvery(
        [requestStoreTaskGetParentIds.errorType, requestParentTaskDetailsCombined.errorType],
        function* () {
            yield put(navigateTo(generatePath(Routes.TaskList)));
            yield put(showToastMessage({
                message: i18n.t('Task might have been deleted or does not exist'),
                options: { variant: ToastVariant.Error },
            }));
        },
    );
}

function* parentTaskDetailsCombinedSuccessSaga({ payload: parentTaskId }: ReturnType<typeof requestParentTaskDetailsCombined.init>) {
    yield put(taskParentActive(parentTaskId));
}

function* requestParentTaskDetailsCombinedWatcher() {
    yield* takeLatest(requestParentTaskDetailsCombined.initType, parentTaskDetailsCombinedSuccessSaga);
}

const updatedPayload = (payload: StoreTaskSearchRequest | ParentTaskSearchRequest | AutomationTaskSearchRequest) => ({
        descending: payload.descending,
        filter: payload.filter,
        limit: payload.limit,
        sortBy: payload.sortBy,
        nextPageToken : payload.nextPageToken,
    }
)

function* parentTasksCheckWatcher() {
    yield* takeEvery(
        [TASK_LIST_CHECK_REQUEST],
        function* (action: TaskListCheckActions) {
            const currentUser = yield* select(selectUser);
            const isStore: boolean = currentUser?.isCompanyLocationStore === true;
            const taskStoreListLoading = yield select(selectStoreTasksFetching);
            const taskParentListLoading = yield select(selectParentTasksFetching);
            const useCanAccessTasks: boolean = yield* select(selectHasAppAccess(AppName.Tasks));

            if(taskStoreListLoading || taskParentListLoading) return;

            if(action.payload.tasksViewMode === TasksViewModeMyTasks || !useCanAccessTasks){
                const payload = updatedPayload(action.payload);
                yield put(requestAutomationTasks.init(payload as AutomationTaskSearchRequest));
                return;
            }

            if(isStore || (browserHistory.location.pathname === Routes.TaskListByLocation) ){
                yield put(requestStoreTasks.init(action.payload as StoreTaskSearchRequest))
            }else{
                yield put(requestParentTasks.init(action.payload as ParentTaskSearchRequest))
            }
        },
    );
}

export const taskSagas = [
    taskCreateSuccessSaga,
    taskListSuccessWatcher,
    taskErrorWatcher,
    parentTasksSuccessWatcher,
    requestTaskGetErrorWatcher,
    requestParentTaskDetailsCombinedWatcher,
    parentTasksCheckWatcher,

    ...taskListComponentSagas,
    ...storeTaskSagas,
    ...taskCommonSagas,
    ...taskReviewSagas,
    ...statisticsSagas,
    ...prototypesSagas,
    ...scheduledTaskSagas,
    ...watcherSagas,
    ...taskTemplateSagas,
    ...taskFilterSagas,
    ...taskGallerySagas,
    ...calendarTaskSagas,
    ...taskConversationSagas,
    ...editTaskCreatorSagas,
    ...editTaskSagas,
    ...taskBulkSagas,
    ...calendarFilterSagas
];
