import { environmentName } from 'utils/globals';
import { Environment } from 'model/environment';

const registeredActions: Array<string> = [];

export interface IErrorActionPayload {
    // TODO replace any with some param as soon as we have error handling strategy
    error: any;
}

export type ActionCreatorKnownArgs<ArgsType, ReturnType> = (payload: ArgsType) => ReturnType;
export type ActionWithPayload<PayloadType> = ReturnType<
    ActionCreatorKnownArgs<PayloadType, { type: string; payload: PayloadType }>
>

export function createSingleAction<PayloadType, ActionType extends string>(
    actionType: ActionType,
): ActionCreatorKnownArgs<PayloadType, { type: ActionType; payload: PayloadType }> {
    // Prevent multiple actions with the same name from occuring in the application
    if (environmentName === Environment.Local) {
        if (registeredActions.includes(actionType)) {
            throw new Error(`Action ${actionType} is already registered somewhere else`);
        } else {
            registeredActions.push(actionType);
        }
    }

    return (payload: PayloadType) => ({
        type: actionType,
        payload,
    });
}

export interface IActionsCreatorCommon {
    init: ActionCreatorKnownArgs<any, any>;
    initType: string;
    success: ActionCreatorKnownArgs<any, any>;
    successType: string;
    error: ActionCreatorKnownArgs<any, any>;
    errorType: string;
}

export type ActionsReturnTypes<T extends IActionsCreatorCommon> =
    | ReturnType<T['init']>
    | ReturnType<T['success']>
    | ReturnType<T['error']>;
