import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { useActions } from "utils/store/useActions";
import { Trans } from 'react-i18next';
import { selectCalendarEventById, selectCalendarEventToRemove, selectCalendarEventToRemoveFetching } from 'store/modules/task/calendarTask/calendarSelectors';
import { CalendarEventShort } from 'api/task/calendarTask/calendaTaskModel';
import { setCalendarEventToRemove } from 'store/modules/task/calendarTask/calendarTaskActions';
import { requestDeleteCalendarEvent } from 'api/task/calendarTask/calendaTaskActions';
import { CancelButton, DeleteButton, DialogDescription, StyledDialogActions, TitleWrapper } from '../taskList/views/ParentTasksView/RemoveParentTaskConfirmation';

type ModalCalendarEventProps = {
    event: CalendarEventShort;
}

const ModalCalendarEvent: React.FC<ModalCalendarEventProps> = ({ event }) => {
    const actions = useActions({ setCalendarEventToRemove, deleteCalendarEvent: requestDeleteCalendarEvent.init });
    const handleClickNo = useCallback(() => actions.current.setCalendarEventToRemove(null), [actions]);
    const handleClickYes = useCallback(() => {
        actions.current.deleteCalendarEvent(event.id);
    }, [actions, event]);
    const isFetching = useSelector(selectCalendarEventToRemoveFetching);

    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <TitleWrapper>
                    <Trans>Delete</Trans> <strong>{event.title}</strong>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <DialogDescription>
                    <Trans>You will not be able to undo this. Do you want to proceed?</Trans>
                </DialogDescription>
            </DialogContent>
            <StyledDialogActions>
                <CancelButton onClick={handleClickNo} disabled={isFetching}><Trans>Cancel</Trans></CancelButton>
                <DeleteButton onClick={handleClickYes} disabled={isFetching}><Trans>Delete</Trans></DeleteButton>
                {isFetching && (
                    <CircularProgress size={'1rem'} />
                )}
            </StyledDialogActions>
        </Dialog>
    );
};

export const RemoveCalendarEventConfimation: React.FC = () => {
    const calendarEventToRemove = useSelector(selectCalendarEventToRemove);
    const eventToRemove = useSelector(selectCalendarEventById)[calendarEventToRemove];

    if (!eventToRemove) {
        return null;
    }

    return (
        <ModalCalendarEvent 
            event={eventToRemove as CalendarEventShort}
        />
    );
};
