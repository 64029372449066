
import React, { ReactElement } from 'react';
import { Box, Fade, Tooltip as TooltipMaterial, Typography } from '@material-ui/core';

interface ITooltipProps {
  label: string,
  title: string | ReactElement,
  bodyTooltip: ReactElement
}

export const Tooltip = React.forwardRef<HTMLDivElement, ITooltipProps>(({ label, title, bodyTooltip }, ref) => (
  <Box display="flex">
    <Typography variant="caption">
      {label}
    </Typography>
    <TooltipMaterial title={title} ref={ref} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
      {bodyTooltip}
    </TooltipMaterial>
  </Box>
));
