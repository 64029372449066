import { SimpleTaskListHeader } from 'modules/task/taskList/components/SimpleTaskListHeader'
import { ButtonSwitchType } from 'modules/task/taskList/components/ViewSwitch/TypeViewSwithMap'
import { ViewSwitch } from 'modules/task/taskList/components/ViewSwitch/ViewSwitch'
import React, { useMemo } from 'react'
import { TasksFilterComponentProps } from '../TypeTaskFilterMap'
import { useSelector } from 'react-redux'
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors'
import { useCurrentUser } from 'utils/hooks/useCurrentUser'
import { FlexBoxContainer } from './common/FlexBoxContainer'

export const ScheduledTasksFilter: React.FC<TasksFilterComponentProps> = ({ locationId }) => {
  const location = useSelector(selectLocationsByIds)[locationId];
  const { isCompanyLocationStore = true } = useCurrentUser() || {};
  const typeTasksAppAccess = useMemo(() => (
    isCompanyLocationStore ? ButtonSwitchType.StoreTasks : ButtonSwitchType.ParentTasks
  ), [isCompanyLocationStore]);

  return (
    <FlexBoxContainer flexDirection='row' flexWrap='wrap' alignItems='center'>
      <SimpleTaskListHeader regionName={location?.name} showFullRegionName={false}/>
      <ViewSwitch type={typeTasksAppAccess} />
    </FlexBoxContainer>
  )
}
