import { useSelector } from 'react-redux';
import {
    selectIsMessageSearching,
} from '../../../../../store/modules/getStream/getStreamSelectors';
import { Avatar, Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { maxDisplayedLength } from './SelectChatMessage';
import React, { useMemo } from 'react';
import { IUserObject } from '../../../../../api/getStream/getStreamModel';
import moment from 'moment';
import { BlueColor } from '../../../../../utils/styles/constants';

export type AutocompleteMessageProps = {
    id: string;
    text: string;
    created_at: string;
    user: IUserObject
}

const useStyles = makeStyles(() => ({
    avatar: {
        width: '25px',
        height: '25px',
        marginRight: '5px',
    },
}));

export const AutocompleteChatMessage = ({id, text, created_at, user}: AutocompleteMessageProps) => {
    const classes = useStyles();
    const searchMessageLoading = useSelector(selectIsMessageSearching);
    const safeText = useMemo(() => {
        if ((text ?? '').length > maxDisplayedLength) {
            return (text ?? '').substring(0, maxDisplayedLength) + '...';
        } else {
            return text;
        }
    }, [text]);
    const safeDate = useMemo(() => {
        const currentDate = Date.parse(created_at);
        return moment(currentDate).format('YYYY-MM-DD 23:59');
    }, [created_at]);

    return (
        <>
            <Avatar
                src={user?.image}
                variant={"circular"}
                alt={user?.name ?? ''}
                className={classes.avatar}
            >
                <div style={{
                    backgroundColor: BlueColor.Dark,
                    color:'white',
                    width:'15',
                    textAlign: 'center',
                    lineHeight:'23',
                }}>
                    {user?.name?.substring(0, 1)?.toUpperCase()}{user?.name?.split(' ')[1]?.substring(0, 1)?.toUpperCase()}
                </div>
            </Avatar>
            <Box>
                <Typography style={{ fontSize: '12px', color: 'gray', paddingRight: '5px', whiteSpace: 'nowrap'}}>{safeDate}</Typography>
                <Typography style={{ marginRight: '0.3em', fontSize: '12px', }}>{safeText}</Typography>
            </Box>
            {searchMessageLoading
                ? <CircularProgress size="16px" />
                : null}
        </>
    );
}
