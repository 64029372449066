import { createApiActions } from 'utils/api/apiActionUtils';
import { IResponseErrorPayload } from 'model/error';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { CompaniesResponseList, CompanyColorType, CompanyLogoResponse, CompanyLogoType, CompanyType } from 'api/company/companyModel';

const GET_COMPANY_INFO = 'request/company/INFO';
const GET_COMPANY_INFO_SUCCESS = 'response/company/INFO_SUCCESS';
const GET_COMPANY_INFO_ERROR = 'response/company/INFO_ERROR';

export const requestCompanyInfo = createApiActions<string, CompanyType, IResponseErrorPayload,
    typeof GET_COMPANY_INFO, typeof GET_COMPANY_INFO_SUCCESS, typeof GET_COMPANY_INFO_ERROR>(
    GET_COMPANY_INFO, GET_COMPANY_INFO_SUCCESS, GET_COMPANY_INFO_ERROR,
);

const GET_COMPANY_COLOR = 'request/company/GET_COLOR';
const GET_COMPANY_COLOR_SUCCESS = 'response/company/GET_COLOR_SUCCESS';
const GET_COMPANY_COLOR_ERROR = 'response/company/GET_COLOR_ERROR';
export const requestCompanyColor = createApiActions<string, CompanyColorType, IResponseErrorPayload,
    typeof GET_COMPANY_COLOR, typeof GET_COMPANY_COLOR_SUCCESS, typeof GET_COMPANY_COLOR_ERROR>(
    GET_COMPANY_COLOR, GET_COMPANY_COLOR_SUCCESS, GET_COMPANY_COLOR_ERROR,
);

const UPDATE_COMPANY_COLOR = 'request/company/UPDATE_COLOR';
const UPDATE_COMPANY_COLOR_SUCCESS = 'response/company/UPDATE_COLOR_SUCCESS';
const UPDATE_COMPANY_COLOR_ERROR = 'response/company/UPDATE_COLOR_ERROR';
export const requestUpdateCompanyColor = createApiActions<CompanyColorType, CompanyColorType, IResponseErrorPayload,
    typeof UPDATE_COMPANY_COLOR, typeof UPDATE_COMPANY_COLOR_SUCCESS, typeof UPDATE_COMPANY_COLOR_ERROR>(
    UPDATE_COMPANY_COLOR, UPDATE_COMPANY_COLOR_SUCCESS, UPDATE_COMPANY_COLOR_ERROR,
);

const UPDATE_COMPANY_LOGO = 'request/company/UPDATE_LOGO';
const UPDATE_COMPANY_LOGO_SUCCESS = 'response/company/UPDATE_LOGO_SUCCESS';
const UPDATE_COMPANY_LOGO_ERROR = 'response/company/UPDATE_LOGO_ERROR';
export const requestUpdateCompanyLogo = createApiActions<CompanyLogoType, CompanyLogoResponse, IResponseErrorPayload,
    typeof UPDATE_COMPANY_LOGO, typeof UPDATE_COMPANY_LOGO_SUCCESS, typeof UPDATE_COMPANY_LOGO_ERROR>(
    UPDATE_COMPANY_LOGO, UPDATE_COMPANY_LOGO_SUCCESS, UPDATE_COMPANY_LOGO_ERROR,
);

export type RequestCompanyActions = (
    ActionsReturnTypes<typeof requestCompanyInfo>
    | ActionsReturnTypes<typeof requestCompanyColor>
    | ActionsReturnTypes<typeof requestUpdateCompanyColor>
    | ActionsReturnTypes<typeof requestUpdateCompanyLogo>
);

const GET_COMPANIES = 'request/company/ALL';
const GET_COMPANIES_SUCCESS = 'response/company/ALL_SUCCESS';
const GET_COMPANIES_ERROR = 'response/company/ALL_ERROR';

export const requestCompaniesInfo = createApiActions<null, CompaniesResponseList, IResponseErrorPayload,
    typeof GET_COMPANIES, typeof GET_COMPANIES_SUCCESS, typeof GET_COMPANIES_ERROR>(
    GET_COMPANIES, GET_COMPANIES_SUCCESS, GET_COMPANIES_ERROR,
);

export type RequestCompaniesActions = (
    ActionsReturnTypes<typeof requestCompaniesInfo>
);
