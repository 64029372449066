import React from 'react';
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { SidebarSection } from '../SidebarSection';
import { CreateChannelDialog } from './CreateChannelDialog';
import { Trans } from 'react-i18next';

export const ChannelsSection: React.FC = ({ children }) => {
    const { isOpened, onOpen, onClose } = useOpenedState();
    const userCanCreate = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));

    return (
        <>
            <SidebarSection
                title={<Trans>Channels</Trans>}
                onAddClick={onOpen}
                allowAdd={userCanCreate}
            >
                {children}
            </SidebarSection>

            <CreateChannelDialog isOpened={isOpened} onClose={onClose}/>
        </>
    );
};
