/* eslint-disable react/display-name */
import React, { useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { FeedEvent, FeedEventPayload } from '@knocklabs/client';
import { useKnockFeedProviderState } from './hooks';
import { NotificationToast } from './NotificationToast';

const KNOCK_EVENT_NEW_MESSAGE: FeedEvent = 'items.received.realtime';

export const NotificationsToaster = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { feedClient } = useKnockFeedProviderState() || {};

    const newMessageHandler = useCallback(({ items }: FeedEventPayload) => {
        if (items.length) {
            enqueueSnackbar(null, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top',
                },
                content: key => (
                    <NotificationToast key={key} feedItem={items[0]}/>
                ),
            });
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        feedClient?.on(KNOCK_EVENT_NEW_MESSAGE, newMessageHandler);

        return () => feedClient?.off(KNOCK_EVENT_NEW_MESSAGE, newMessageHandler);
    }, [feedClient, newMessageHandler]);

    return null;
};
