import { IStoreState } from 'store/rootReducer';

export const getFiles = (state: IStoreState) => state.files;
export const getFilesLoadingState = (state: IStoreState) => state.files.filesUploading;
export const getPendingFiles = (state: IStoreState) => state.files.pendingFiles;
export const getPendingFilesQuantity = (state: IStoreState) => state.files.pendingFiles.length;
export const getFilesQuantity = (state: IStoreState) => (
    state.files.pendingFiles.length + state.files.uploadedFiles.length
);
export const getUploadedFiles = (state: IStoreState) => state.files.uploadedFiles;
export const getFilesConfig = (state: IStoreState) => state.files.configuration;

export const selectFileStorageToken = (state: IStoreState) => state.files.fileStorageToken;
export const selectFileStorageTokenExpiration = (state: IStoreState) => state.files.fileStorageTokenExpiration;

export const selectIsAvatarUploading = (state: IStoreState) => state.files.avatarUploading;

export const selectIsBulkUsersFileUploading = (state: IStoreState) => state.files.uploadingUsersBulkFile;

export const selectFileByFileStorage = (state: IStoreState) => state.files.fileByFileStorage;
export const selectFileByFileStorageFetchingById = (state: IStoreState) => state.files.fileByFileStorageFetchingById;

export const getPendingConversationFiles = (state: IStoreState) => state.files.pendingConversationFiles;
export const getUploadedConversationFiles = (state: IStoreState) => state.files.uploadedConversationFiles;

export const selectIsBulkTasksFileUploading = (state: IStoreState) => state.files.uploadingTasksBulkFile;
export const selectIsBulkTasksFileDownloading = (state: IStoreState) => state.files.downloadTasksBulkFile;
