import React, { useCallback, useEffect, useState} from 'react';
import { useDebouncedValue } from "utils/hooks/useDebouncedValue";
import { Box, CircularProgress, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams, AutocompleteInputChangeReason } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { selectStores, selectStoresLoading } from "store/modules/store/storeSelectors";
import { useActions } from "utils/store/useActions";
import { requestSearchStores, resetSearchStores } from "api/store/storeActions";
import { selectUserCompany } from "store/modules/company/companySelector";
import { Store, Stores  } from "api/store/storeModel";
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type SearchStoreProps = {
    onSelectStore: (store: Store) => void;
    selectedStores: Store[];
}

export const SearchStore = ({ onSelectStore, selectedStores }: SearchStoreProps) => {
    const storeLoading = useSelector(selectStoresLoading);
    const stores = useSelector(selectStores);
    const userCompany = useSelector(selectUserCompany);
    const actions = useActions({
        requestSearchStores: requestSearchStores.init,
        resetSearchStores: resetSearchStores
    });
    const [options, setOptions] = useState<Stores>([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebouncedValue(searchTerm, 1000);

    useEffect(() => {
        if(debouncedSearchTerm.length > 0){
            setLoading(true);
            actions.current.requestSearchStores({companyId: userCompany?.id || '' , searchTerm: debouncedSearchTerm})
        }
    }, [debouncedSearchTerm, userCompany, actions]);

    useEffect(() => {
        setOptions(stores.filter(op=>!selectedStores.some(loc=>loc.id === op.id)));
    }, [stores, selectedStores, setOptions]);

    useEffect(() => {
        setLoading(storeLoading)
    }, [storeLoading]);

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        const adornment = (
            <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
            </React.Fragment>
        );
        return (
            <TextField
                {...params}
                label={`${t('Search by Store')}`}
                placeholder={`${t('Please, enter the store name')}`}
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: adornment,
                }}
            />
        );
    }, [loading]);

    const onChangeHandler = useCallback((_: React.ChangeEvent<{}>, value: Store | null) => {
        if(value){
            onSelectStore(value)
        }
    }, [onSelectStore]);

    const resetInputAndOptions = useCallback(()=>{
        actions.current.resetSearchStores();
        setSearchTerm("");
        setOptions([]);
    },[setSearchTerm,setOptions, actions])

    const handlerInputChange = useCallback((event: object, value: string, reason: AutocompleteInputChangeReason) => {
        if(reason === 'input'){
            setSearchTerm(value);
        }
        if(reason === 'clear'){
            resetInputAndOptions()
        }
    },[setSearchTerm, resetInputAndOptions])

    return (
        <Autocomplete
            style={{ width: 300 }}
            includeInputInList
            autoHighlight
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            options={options}
            loading={loading}
            renderInput={renderInput}
            noOptionsText={<Trans>No store found</Trans>}
            onChange={onChangeHandler}
            onInputChange={handlerInputChange}
            inputValue={searchTerm}
            onBlur={resetInputAndOptions}
            filterOptions={x => x}
            renderTags={opt => null}
            renderOption={option => {
                const item: Store = option;
                return (
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Typography component="span">
                            {item.name}
                        </Typography>
                    </Box>
                );
            }}
        />
    );
};
