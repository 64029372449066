import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';
import { Theme, ThemeProvider,  createTheme } from '@material-ui/core/styles';
import { IAppOverrides } from 'modules/root/theme';
import { useSelector } from 'react-redux';
import { selectUserCompany } from 'store/modules/company/companySelector';

const getOverrides = (theme: Theme): IAppOverrides => ({
    MuiInputBase: {
        root: {
            color: theme.palette.getContrastText(theme.palette.primary.dark)
        },
    },
    MuiInput: {
        underline: {
            '&&:before': {
                borderColor: GrayColor.Gray0,
            },

            '&&:hover:not($disabled):before': {
                borderColor: GrayColor.Gray5,
            },
        },
    },
    MuiSelect: {
        icon: {
            color: theme.palette.getContrastText(theme.palette.primary.dark)
        },
    },
    MuiRadio: {
        colorPrimary: {
            '&$checked > *': {
                color: BlueColor.Blue70,
            },
        },
    },
    MuiCheckbox: {
        root: {
            color: `${theme.palette.getContrastText(theme.palette.primary.dark)} !important`,
            '&$checked': {
                color: `${theme.palette.getContrastText(theme.palette.primary.dark)} !important`,
            },
        },
    },
    MuiAutocomplete: {
        clearIndicator: {
            color: theme.palette.getContrastText(theme.palette.primary.dark)
        },
        popupIndicator: {
            color: theme.palette.getContrastText(theme.palette.primary.dark)
        },
    },
    MuiInputLabel: {
        outlined: {
            color: theme.palette.getContrastText(theme.palette.primary.dark),
            '&$focused': {
                color: theme.palette.getContrastText(theme.palette.primary.dark),
            },
        },
    },
    MuiOutlinedInput: {
        root: {
            '&:hover $notchedOutline': {
                borderColor: theme.palette.getContrastText(theme.palette.primary.dark)
            },
            '&$focused $notchedOutline': {
                borderColor: 'currentColor',
            },
            
        },
        notchedOutline: {
            borderColor: theme.palette.getContrastText(theme.palette.primary.dark)
        },
        input: {
            padding: theme.spacing(1, 1.5),
        },
    },
    MuiButton: {
        root: {
            margin: 0,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: theme.spacing(3),
            padding: theme.spacing(0.5, 2),
            boxShadow: 'none !important',
            borderColor: GrayColor.Gray5,
            color: theme.palette.getContrastText(theme.palette.primary.dark),

            '&:hover': {
                backgroundColor: `${UtilColors.HoverColor} !important`,
                color: `${theme.palette.primary.contrastText} !important`,
            },
        },
        outlined: {
            borderColor: GrayColor.Gray5,
        },
    },
    MuiSvgIcon: {
        root: {
            fill: theme.palette.getContrastText(theme.palette.primary.dark),
        },
    },
});


export const ThemeProviderSlidebar = ({ children }: {children: React.ReactNode}) => {
    const companyInformation = useSelector(selectUserCompany);
    
    const slidebarTheme = ((theme: Theme) => {
        return createTheme({
            ...theme,
            palette: {
                primary: {
                    ...theme.palette.primary,
                    main: companyInformation?.primaryColor || UtilColors.White,
                    dark: companyInformation?.primaryColor || theme.palette.primary.dark,
                },
                secondary: {
                    ...theme.palette.secondary,
                    light: companyInformation?.secondaryColor || theme.palette.secondary.light
                },
            },
            typography: {
                h6: {
                    textTransform: 'uppercase',
                    fontSize: 16,
                    marginBottom: 16,
                },
            },
            overrides: getOverrides(theme),
        })
    });

    return (
        <ThemeProvider theme={slidebarTheme} >
            {children}
        </ThemeProvider>
    );
};