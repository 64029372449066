import { environmentName } from 'utils/globals';
import { Environment } from 'model/environment';
import { logger } from 'utils/logger';

export function apiErrorHandler(event: ErrorEvent) {
    event.preventDefault();
    if (
        environmentName === Environment.Local
        && event.message === 'ReferenceError: window is not defined'
    ) {
        return;
    }

    logger.reportMessage(`Web Worker: ${event.message}`, event);
}
