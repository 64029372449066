import { makeStyles, Theme } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';

export const useUploadButtonStyles = makeStyles((theme: Theme) => ({
    uploadSection: {
        margin: theme.spacing(0, 2),
    },
    uploadButton: {
        borderColor: UtilColors.White,
        color: UtilColors.White,
        margin: theme.spacing(0, 0, 2),
    },
}));
