import { makeStyles, Theme } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';

export const useTaskByLocationStyles = makeStyles((theme: Theme) => ({
    table: {
        boxSizing: 'border-box',
        backgroundColor: UtilColors.White,
    },
    bodyRow: {
        boxSizing: 'border-box',
        height: '100%',

        '& td:first-child': {
            padding: '0px !important',
        },

        '& td:not(:first-child)': {
            padding: '0 0 0 16px !important',
        },
    },
    headerCell: {
        boxSizing: 'border-box',
        color: UtilColors.TextSecondary,
        fontSize: 12,
        lineHeight: 2,
        fontWeight: 600,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
    },
    headerCellLocation: {
        whiteSpace: 'normal',
    },
    colorCell: {
        boxSizing: 'border-box',
        padding: 0,
        width: 7,
        height: '100%',
    },
    expandButton: {
        boxSizing: 'border-box',
        borderRadius: '50%',
        color: UtilColors.TextSecondary,
        marginRight: theme.spacing(0.5),
    },
    storeOpenButton: {
        boxSizing: 'border-box',
        marginLeft: theme.spacing(3.25),
    },
}));
