import { Fab, FabProps, Theme, makeStyles } from '@material-ui/core';
import React from 'react';
import { ZIndex } from 'utils/styles/constants';

type SeedProps = {
    seed: boolean,
    isCustomInputConectEnabled: boolean,
    isOpened: boolean,
};

const useStyles = makeStyles((theme: Theme) => ({
    floatingButtonBase: {
        position: 'absolute',
        zIndex: ZIndex.ConnectFloatingButton,
    },
    floatingButton: {
        bottom: '95px',
        right: (props: SeedProps) => props.seed ? '17px' : '30px',
        transition: 'bottom 0.3s',
    },
    floatingCustomInput: {
        transition: 'left 0.3s',
        top: '45%',
        left: (props: SeedProps) => props.isOpened ? theme.spacing(33.5) : 0,
        height: theme.spacing(4.5),
        width: theme.spacing(4.5), 
    }
}));

export type FloatingButtonProps = FabProps & SeedProps;

export function FloatingButton({ seed, children, isCustomInputConectEnabled, isOpened, ...rest }: FloatingButtonProps) {
    const classes = useStyles({ seed, isCustomInputConectEnabled, isOpened });

    return (
        <Fab className={`${classes.floatingButtonBase} ${isCustomInputConectEnabled ? classes.floatingCustomInput : classes.floatingButton}`} {...rest}>
            { children }
        </Fab>
    );
}
