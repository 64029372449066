export enum BlueColor {
    Blue0 = '#F5F8FF',
    Blue5 = '#EBF2FF',
    Blue30 = '#9DC2FF',
    Blue40 = '#76A9FF',
    Blue50 = '#4F91FF',
    BluePrimaryMain = '#007DFF',
    Blue60 = '#2979FF',
    Blue70 = '#2264D1',
    Blue80 = '#1B4EA3',
    Blue100 = '#0C2146',
    Dark = '#233E5E',
    Dark2 = '#0057B2',
    DarkOld = '#1F344E',
    Pale = '#D8E6FF',
}

export enum GrayColor {
    Gray0 = '#F7F7FA',
    Gray5 = '#EDEDF0',
    GrayE3 = '#E3E3E3',
    Gray87 = '#878787',
    Gray50 = '#787885',
    Gray60 = '#5A5B6A',
    Gray100 = '#19191D',
    GrayBlue = '#7F85A2',
    GrayBlack = '#1E1F20',
    Gray9E = '#9E9E9E',
    GrayE0 = '#E0E0E0',
}

export enum GreenColor {
    Green60 = '#43A047',
    Green2 = '#4CAF50',
}

export enum UtilColors {
    Black = '#000000',
    White = '#FFFFFF',
    Icon = '#007CDE',
    IconActive = '#DFF7F9',
    TextDisabled = '#B4B4BB',
    Background = '#ECEFF8',
    HoverColor = '#FF842D',
    InputBackground = '#FAFBFF',
    ShadowLight = 'rgba(0, 0, 0, 0.04)',
    Shadow = 'rgba(0, 0, 0, 0.07)',
    Shadow1 = 'rgba(0, 0, 0, 0.08)',
    Shadow2 = 'rgba(0, 0, 0, 0.12)',
    /** IOS compatible transparent color */
    Transparent = 'rgba(0, 0, 0, 0)',
    Red = '#E45851',
    Red2 = '#F44336',
    Red3 = '#FF505F',
    TextPrimary = 'rgba(0,0,0, 0.78)',
    TextSecondary = 'rgba(0, 0, 0, 0.5)',
}

export const orangeColor = '#FF8E00';

export enum Breakpoints {
    /** Mobile Small resolution - width: 320px */
    MobileSmall = 320,

    /** Mobile Medium resolution - width: 375px*/
    MobileMedium = 375,

    /** Mobile Advanced resolution - width: 460px*/
    MobileAdvanced = 460,

    /** Mobile Advanced X resolution - width: 540px*/
    MobileAdvancedX = 540,

    /** Mobile Large resolution - width: 768px */
    MobileLarge = 768,

    /** Tablet resolution - width: 1024px */
    Tablet = 1024,

    /** Desktop Small resolution - width: 1280px */
    DesktopSmall = 1280,

    /** Desktop Medium resolution - width: 1366px */
    DesktopMedium = 1366,

    /** Desktop Full HD resolution - width: 1920px */
    DesktopFHD = 1920,

    /** Desktop Quad HD resolution - width: 2560px */
    DesktopQHD = 2560,

    /** Desktop 4K (Ultra HD) resolution - width: 3840px */
    Desktop4K = 3840,
}

export enum ZIndex {
    TopProgress = 10000,
    InputIcon = 2000,
    ConnectFloatingButton = 1001,
    Sidebar = 1000,
    Backdrop = 900,
    GridTable = 1,
}
