
import React from 'react';
import { Box, Fade, Tooltip } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Trans } from 'react-i18next';

interface IWatcherTooltipProps {
    label: string,
}

export const WatcherTooltip = React.forwardRef<HTMLDivElement, IWatcherTooltipProps>(({ label }, ref) =>
    (
        <Box display="flex">
            {label}
            <Tooltip title={<Trans>Select stores first, and add eligible users that will receive Task Submitted notifications. Eligible users' location must be at or above the store hierarchy</Trans>}
                ref={ref} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <ContactSupportIcon
                    color="inherit"
                    fontSize="small"
                />
            </Tooltip>
        </Box>
    )
)
