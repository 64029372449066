import { createSingleAction } from "utils/store/actionUtils";

export const TASK_FILTER_TAB = 'task/TASK_FILTER_TAB';

export const taskFilterTab = createSingleAction<string, typeof TASK_FILTER_TAB>(
  TASK_FILTER_TAB,
);
export const TASK_CREATED_BY_FILTER_ACTIVE = 'task/filter/TASK_CREATED_BY_FILTER_ACTIVE';
export const addFilterTaskCreatedBy = createSingleAction<string[], typeof TASK_CREATED_BY_FILTER_ACTIVE>(
  TASK_CREATED_BY_FILTER_ACTIVE,
);

export type TaskFilterTab =
    | ReturnType<typeof taskFilterTab>
    | ReturnType<typeof addFilterTaskCreatedBy>