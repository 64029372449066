import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import {
    IChangeTaskStatusRequest,
    IChangeTaskStatusSuccess,
    IChecklistItemUpdateFailed,
    ICheckListItemUpdatePayload,
    ITaskUpdateSuccess,
    IUpdateChecklistSuccess,
    TaskCreateRequest,
    TaskCreateResult,
    TaskDetailsType,
    TaskType,
    LabelCreateRequest,
    LabelCreateResult,
} from 'api/task/taskModel';
import { IEntity, IEntityError, IEntitySuccess } from 'model/entity';
import { IResponseErrorPayload } from 'model/error';
import { ParentTaskSearchRequest, ParentTaskSearchResponse } from './parentTask/parentTaskModel';
import { StoreTaskSearchRequest, StoreTaskSearchResponse } from './storeTask/storeTaskModel';
import { AutomationTaskSearchRequest } from './automationTask/automationTaskModel';

const LABEL_CREATE = `label/CREATE`;
export const requestCreateLabel = createApiActionsByName<LabelCreateRequest, LabelCreateResult, any, typeof LABEL_CREATE>(LABEL_CREATE);

const TASK_CREATE = `task/CREATE`;
export const requestCreateTask = createApiActionsByName<TaskCreateRequest, TaskCreateResult, any, typeof TASK_CREATE>(TASK_CREATE);

const REQUEST_TASKS_GET = 'request/task/GET';
const RESPONSE_TASKS_GET_SUCCESS = 'response/task/GET_SUCCESS';
const RESPONSE_TASKS_GET_ERROR = 'response/task/GET_ERROR';

export const requestTasksGet = createApiActions<void, Array<TaskType>, any, typeof REQUEST_TASKS_GET,
    typeof RESPONSE_TASKS_GET_SUCCESS, typeof RESPONSE_TASKS_GET_ERROR
>(
    REQUEST_TASKS_GET, RESPONSE_TASKS_GET_SUCCESS, RESPONSE_TASKS_GET_ERROR,
);

const REQUEST_TASK_CHANGE_STATUS = 'request/task/CHANGE_STATUS';
const RESPONSE_TASK_CHANGE_STATUS_SUCCESS = 'response/task/CHANGE_STATUS_SUCCESS';
const RESPONSE_TASK_CHANGE_STATUS_ERROR = 'response/task/CHANGE_STATUS_ERROR';

export const requestTaskChangeStatus = createApiActions<
    IChangeTaskStatusRequest, IChangeTaskStatusSuccess, IEntityError,
    typeof REQUEST_TASK_CHANGE_STATUS, typeof RESPONSE_TASK_CHANGE_STATUS_SUCCESS,
    typeof RESPONSE_TASK_CHANGE_STATUS_ERROR
>(
    REQUEST_TASK_CHANGE_STATUS, RESPONSE_TASK_CHANGE_STATUS_SUCCESS, RESPONSE_TASK_CHANGE_STATUS_ERROR,
);

const REQUEST_TASK_DETAILS = 'request/task/DETAILS';
const RESPONSE_TASK_DETAILS_SUCCESS = 'response/task/DETAILS_SUCCESS';
const RESPONSE_TASK_DETAILS_ERROR = 'response/task/DETAILS_ERROR';

export const requestTaskDetails = (
    createApiActions<string, TaskDetailsType, any,
        typeof REQUEST_TASK_DETAILS,
        typeof RESPONSE_TASK_DETAILS_SUCCESS,
        typeof RESPONSE_TASK_DETAILS_ERROR
    >(
        REQUEST_TASK_DETAILS,
        RESPONSE_TASK_DETAILS_SUCCESS,
        RESPONSE_TASK_DETAILS_ERROR,
    )
);

const REQUEST_TASK_UPDATE_CHECKLIST_ITEM = 'request/task/checklist/ITEM_UPDATE';
const RESPONSE_TASK_UPDATE_CHECKLIST_ITEM_SUCCESS = 'response/task/checklist/ITEM_UPDATE_SUCCESS';
const RESPONSE_TASK_UPDATE_CHECKLIST_ITEM_ERROR = 'response/task/checklist/ITEM_UPDATE_ERROR';

export const requestUpdateChecklistItem = (
    createApiActions<ICheckListItemUpdatePayload, IUpdateChecklistSuccess, IChecklistItemUpdateFailed,
        typeof REQUEST_TASK_UPDATE_CHECKLIST_ITEM,
        typeof RESPONSE_TASK_UPDATE_CHECKLIST_ITEM_SUCCESS,
        typeof RESPONSE_TASK_UPDATE_CHECKLIST_ITEM_ERROR
    >(
        REQUEST_TASK_UPDATE_CHECKLIST_ITEM,
        RESPONSE_TASK_UPDATE_CHECKLIST_ITEM_SUCCESS,
        RESPONSE_TASK_UPDATE_CHECKLIST_ITEM_ERROR,
    )
);

const REQUEST_TASK_DELETE = 'request/task/DELETE';
const REQUEST_TASK_DELETE_SUCCESS = 'response/task/DELETE_SUCCESS';
const REQUEST_TASK_DELETE_ERROR = 'response/task/DELETE_ERROR';

export const requestTaskDelete = createApiActions<IEntity, IEntitySuccess, IEntityError,
    typeof REQUEST_TASK_DELETE, typeof REQUEST_TASK_DELETE_SUCCESS, typeof REQUEST_TASK_DELETE_ERROR
>(
    REQUEST_TASK_DELETE, REQUEST_TASK_DELETE_SUCCESS, REQUEST_TASK_DELETE_ERROR,
);

const REQUEST_TASK_UPDATE = 'request/task/UPDATE';
const RESPONSE_TASK_UPDATE_SUCCESS = 'response/task/UPDATE_SUCCESS';
const RESPONSE_TASK_UPDATE_ERROR = 'response/task/UPDATE_ERROR';

export const requestTaskUpdate = (
    createApiActions<TaskDetailsType, ITaskUpdateSuccess, IEntityError,
        typeof REQUEST_TASK_UPDATE,
        typeof RESPONSE_TASK_UPDATE_SUCCESS,
        typeof RESPONSE_TASK_UPDATE_ERROR
    >(
        REQUEST_TASK_UPDATE,
        RESPONSE_TASK_UPDATE_SUCCESS,
        RESPONSE_TASK_UPDATE_ERROR,
    )
);

//#region Tasks 2.0

const REQUEST_PARENT_TASK_LIST_GET = 'request/tasks/parent/GET_LIST';
const RESPONSE_PARENT_TASK_LIST_GET_SUCCESS = 'response/tasks/parent/GET_LIST_SUCCESS';
const RESPONSE_PARENT_TASK_LIST_GET_ERROR = 'response/tasks/parent/GET_LIST_ERROR';

export const requestParentTasks = (
    createApiActions<ParentTaskSearchRequest, ParentTaskSearchResponse, IResponseErrorPayload,
        typeof REQUEST_PARENT_TASK_LIST_GET,
        typeof RESPONSE_PARENT_TASK_LIST_GET_SUCCESS,
        typeof RESPONSE_PARENT_TASK_LIST_GET_ERROR
    >(
        REQUEST_PARENT_TASK_LIST_GET,
        RESPONSE_PARENT_TASK_LIST_GET_SUCCESS,
        RESPONSE_PARENT_TASK_LIST_GET_ERROR,
    )
);

const REQUEST_STORE_TASK_LIST = 'request/task/store/GET_LIST';
const RESPONSE_STORE_TASK_LIST_SUCCESS = 'response/task/store/GET_LIST_SUCCESS';
const RESPONSE_STORE_TASK_LIST_ERROR = 'response/task/store/GET_LIST_ERROR';

export const requestStoreTasks = (
    createApiActions<StoreTaskSearchRequest, StoreTaskSearchResponse, IResponseErrorPayload,
        typeof REQUEST_STORE_TASK_LIST,
        typeof RESPONSE_STORE_TASK_LIST_SUCCESS,
        typeof RESPONSE_STORE_TASK_LIST_ERROR
    >(
        REQUEST_STORE_TASK_LIST,
        RESPONSE_STORE_TASK_LIST_SUCCESS,
        RESPONSE_STORE_TASK_LIST_ERROR,
    )
);

export type TaskListSuccessActions = (
    ReturnType<typeof requestParentTasks.success>
    | ReturnType<typeof requestStoreTasks.success>
)

export const TASK_LIST_CHECK_REQUEST = 'task/TASK_LIST_CHECK_REQUEST';

export const taskListCheckRequest = createSingleAction<ParentTaskSearchRequest | StoreTaskSearchRequest | AutomationTaskSearchRequest, typeof TASK_LIST_CHECK_REQUEST>(
    TASK_LIST_CHECK_REQUEST,
);
export type TaskListCheckActions = (
    ReturnType<typeof taskListCheckRequest>
)
//#endregion

export type TaskActions = (
    ActionsReturnTypes<typeof requestCreateTask>
    | ActionsReturnTypes<typeof requestTasksGet>
    | ActionsReturnTypes<typeof requestTaskChangeStatus>
    | ActionsReturnTypes<typeof requestTaskDetails>
    | ActionsReturnTypes<typeof requestUpdateChecklistItem>
    | ActionsReturnTypes<typeof requestTaskDelete>
    | ActionsReturnTypes<typeof requestTaskUpdate>
    | ActionsReturnTypes<typeof requestParentTasks>
    | ActionsReturnTypes<typeof requestStoreTasks>
    | ActionsReturnTypes<typeof requestCreateLabel>
);
