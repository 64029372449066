import * as t from 'io-ts';
import { storeTaskStatus } from '../common/taskCommonModel';
import { ListSearchRequestBase } from "../../common/model";
import { nullableStringType } from 'model/dataBasicTypes';

export const automationTaskShortSchema = t.intersection([
    t.type({
        id: t.string,
        title: t.string,
        dueDate: t.string,
        createdAt: t.string,
        isOverdue: t.boolean,
        prototypeId: t.union([t.undefined, t.null, t.string]),
    }),
    storeTaskStatus,
]);
export type AutomationTaskShort = t.TypeOf<typeof automationTaskShortSchema>;
export type AutomationTaskSearchFullRequest = {
    payload: AutomationTaskSearchRequest;
    isStore: boolean;
};

export const automationTaskSearchResponseSchema = t.type({
    nextPageToken: nullableStringType,
    tasks: t.array(automationTaskShortSchema),
});
export type AutomationTaskSearchResponse = t.TypeOf<typeof automationTaskSearchResponseSchema>;

export enum AutomationTaskStatusFilter {
    Incomplete = 'incomplete',
    Completed = 'completed',
    Overdue = 'overdue'
}

export enum AutomationTasksSortBy {
    Title = 'title',
    DueDate = 'dueDate',
    Status = 'status',
}

export type AutomationTaskSearchRequest = ListSearchRequestBase<AutomationTasksSortBy> & {
    filter?: AutomationTaskStatusFilter;
    tasksViewMode?: string;
};
