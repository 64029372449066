export function scrollDown(element?: HTMLElement | null) {
    if (element) {
        element.scrollTo({
            top: element.scrollHeight,
            behavior: 'smooth',
        });
    }
}

export function getScrollbarWidth() {
    const scrollDiv = document.createElement('div');
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}

export function hasVerticalScrollbar(element: HTMLElement) {
    return element.scrollHeight > element.offsetHeight;
}
