import { ListSearchRequestBase } from 'api/common/model';
import * as t from 'io-ts';
import { transformFieldName } from 'utils/api/apiValidation';
import i18next from 'i18next';
import i18n from '../../i18n';

export const userUpdateMetadataDto = t.type({
    sendNotification: t.union([t.null, t.boolean]),
});

export type UserUpdateMetadataDto = t.TypeOf<typeof userUpdateMetadataDto>;

export const UserBulkActivateRequestT = t.type({
    userIds: t.array(t.string),
    activationDate: t.string,
    sendNotification: t.boolean,
})

export type UserBulkActivateRequest = t.TypeOf<typeof UserBulkActivateRequestT>;

export const UserBulkDeactivateRequestT = t.type({
    userIds: t.array(t.string),
    activationTime: t.string,
    sendNotification: t.boolean,
})

export type UserBulkDeactivateRequest = t.TypeOf<typeof UserBulkDeactivateRequestT>;

export const userDtoSchema = t.type({
    id: t.string,
    companyId: t.string,
    firstName: t.string,
    lastName: t.string,
    avatarId: t.union([t.null, t.string]),
    companyLocationId: t.union([t.null, t.string]),
    companyLocationName: t.union([t.null, t.string]),
    isCompanyLocationStore: t.union([t.null, t.boolean]),
    email: t.string,
    employeeTitle: t.union([t.null, t.string]),
    role: t.union([t.null, t.string]),
    roleId: t.union([t.null, t.string]),
    phoneNumber: t.union([t.null, t.string]),
    isSuperAdmin: t.union([t.null, t.boolean]),
    isDeleted: t.union([t.null, t.boolean]),
    meta: t.union([t.null, t.undefined, userUpdateMetadataDto]),
    language: t.union([t.undefined, t.string, t.null])
});

export type UserDTO = t.TypeOf<typeof userDtoSchema>;

export type UserProfileDTO = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    language?: string;
}

export type UserEditDTO = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    employeeTitle?: string;
    companyLocationId?: string | null;
    roleId?: string | null;
    isDeleted?: boolean | null;
    meta?: UserUpdateMetadataDto | null | undefined;
    avatarId?: string | null;
    language?: string;
}

export enum UsersSortBy {
    LastName = 'lastName',
}

export type UserSearchRequest = ListSearchRequestBase<UsersSortBy> & {
    fullName?: string;
    userState?: string;
    CompanyId?: string;
    Force?: boolean;
};

export const userSearchResponseSchema = t.type({
    nextPageToken: t.union([t.null, t.string]),
    users: t.array(userDtoSchema),
});

export type UserSearchResponseDTO = t.TypeOf<typeof userSearchResponseSchema>;

export function userErrorReporter(errors: t.Errors) {
    return errors.map(error => {
        const userInfo: any = error.context[0].actual;
        const fieldName = error.context[1].key;
        let identifierSubstring = '';
        if (userInfo) {
            if (userInfo.email) {
                identifierSubstring = userInfo.email + ' ';
            } else if (userInfo.id) {
                identifierSubstring = userInfo.id + ' ';
            }
        }

        return `The user ${identifierSubstring}has no ${transformFieldName(fieldName)} field specified. Please contact your Company administrator`;
    });
}

export type MultipleUsersRequest = Array<string>

export type MultipleUsersResonse = {
    requestedIds: string[];
    users: UserDTO[];
}

export const UserLanguageSchema = t.type({
    language: t.string,
})

export type UserLanguageUpdatePayload = {
    language: string;
    userId: string;
};

export type UserLanguageResponseDTO = t.TypeOf<typeof UserLanguageSchema>;

export enum LanguageCode {
    en = 'en',
    es = 'es',
    fr = 'fr',
};

const languages = {
    [LanguageCode.en]: {
        code: 'en',
        label: 'English',
        translation: 'en',
    },
    [LanguageCode.es]: {
        code: 'es',
        label: 'Spanish',
        translation: 'es',
    },
    [LanguageCode.fr]: {
        code: 'fr',
        label: 'French',
        translation: 'fr',
    },
};

function updateTranslations () {
    languages[LanguageCode.en].label = i18n.t('English');
    languages[LanguageCode.es].label = i18n.t('Spanish');
    languages[LanguageCode.fr].label = i18n.t('French');
}

i18next.on('languageChanged', function() {
    updateTranslations();
});

i18next.on('loaded', function() {
    updateTranslations();
});

export const languageTypes = Object.values(languages).map((language) => ({
    value: language.code,
    label: language.label,
}));

export const getLanguage = (lan: string | undefined | null): string => {
    if(languages.hasOwnProperty(String(lan))){
        return lan as LanguageCode;
    }
    return LanguageCode.en;
}
