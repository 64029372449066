import { FormikValues } from 'formik';
import i18n from 'i18n';
import * as yup from 'yup';

export const taskDeclineValidationSchema = yup.object().shape({
    reason: (
        yup
            .string()
            .trim()
            .max(4096, `${i18n.t('Message is too big – max message length is 4096 characters')}`)
            .required(`${i18n.t('Please provide the reason for declining the task')}`)
    ),
});

export type TaskDeclineFormValues = FormikValues & {
    reason: string;
};

export const initialTaskDeclineFromValues: TaskDeclineFormValues = {
    reason: '',
};
