import { FormHelperText } from "@material-ui/core";

export type InputCounterProps = {
    current: number;
    max?: number;
    style?: React.CSSProperties;
    className?: string;
}

export const InputCounter = ({ current, max, className = ''}: InputCounterProps) => {
    const counterValue = max
        ? `${current} / ${max}`
        : `${current}`

    return (
        <FormHelperText className={className}>
            {counterValue}
        </FormHelperText>
    );
};
