import { makeStyles } from '@material-ui/core/styles';

export const useWrapperStyles = makeStyles(() => ({
    iframeWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        minHeight: '-webkit-fill-available',
        '-webkit-overflow-scrolling': 'touch',
    },
    root: {
        width: '100%',
        height: '99%',
        border: 'none',
    },
}));
