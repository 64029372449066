import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { StoreTaskActivityEvent, StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { TaskSection } from '../TaskSection';
import { TaskActivityEvent } from './TaskActivityEvent';
import { ScheduledTaskDetails } from 'api/task/scheduledTask/scheduledTaskModel';
import { Trans } from 'react-i18next';
import { CalendarEvent } from 'api/task/calendarTask/calendaTaskModel';
import { Button, Theme, Typography, makeStyles } from '@material-ui/core';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { TaskConversation } from 'modules/task/taskConversation/TaskConversation';
import {  selectStoreTaskConversationById, selectTaskConversationStore } from 'store/modules/task/taskConversation/taskConversationSelectors';

enum TabActivity {
    Default = 'task-editing',
    Conversation = 'conversation'
}

const TaskActivityBody: React.FC<{ events: Array<StoreTaskActivityEvent>, hideConversationTab?: boolean }> = ({ events, hideConversationTab = false }) => {
    const [tabActivity, setTabActivity] = useState<TabActivity>(TabActivity.Default);
    const ActivityComponentsShow = ActivityComponents[tabActivity];

    return (
        <TaskSection sectionTitle="">
            <ActivityTab handleTabActivty={(tabActivity)=>setTabActivity(tabActivity)} activeTab={tabActivity} hideConversationTab={hideConversationTab}/>
            <ActivityComponentsShow events={events}/>
        </TaskSection>
    );
};

export type TaskActivityProps = {
    task?: StoreTaskFullModel | ScheduledTaskDetails | CalendarEvent;
    hideConversationTab?: boolean
}

export const TaskActivity: React.FC<TaskActivityProps> = ({ task, hideConversationTab = false }) => {
    const { events = [] } = task ?? {};
    if (!events.length) {
        return null;
    }
    return <TaskActivityBody events={events} hideConversationTab={hideConversationTab}/>;
};


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        gap: 8
    },
    button: {
        borderRadius: 8,
    },
    label: {
        fontSize: 13,
    }
}));

type ActivityTabProps = {
    handleTabActivty: (tab: TabActivity) => void,
    activeTab: TabActivity,
    hideConversationTab?: boolean
}

const ActivityTab: React.FC<ActivityTabProps> = ({handleTabActivty, activeTab, hideConversationTab = false }) => {
    const classes = useStyles();
    const istaskConversationsEnabled = useFeatureState(FeatureFlags.taskConversations);
    const taskStoreId = useSelector(selectTaskConversationStore);
    const comments = useSelector(selectStoreTaskConversationById)[taskStoreId];

    return (
        <div className={classes.root}>
            <Button variant="contained" className={classes.button} size='small' onClick={()=>handleTabActivty(TabActivity.Default)}
                color={activeTab === TabActivity.Default ? 'primary' : 'default'}>
                <Typography className={classes.label}><Trans>Activity</Trans></Typography>
            </Button>
            {(istaskConversationsEnabled && !hideConversationTab) && <Button variant="contained" className={classes.button} size='small' onClick={()=>handleTabActivty(TabActivity.Conversation)}
                color={activeTab === TabActivity.Conversation ? 'primary' : 'default'}>
                <Typography className={classes.label}><Trans>Conversation</Trans> ({comments?.comments.length})</Typography>
            </Button>}
        </div>
    );
}

const ActivityTaskEdition: React.FC<{ events: Array<StoreTaskActivityEvent> }> = ({ events }) => {
    const orderedEvents = useMemo(() => {
        return orderBy(events, ['createdOn'], ['desc']);
    }, [events]);

    return (
        <>
            {orderedEvents.map(event => (
                <TaskActivityEvent
                    event={event}
                    key={event.createdOn}
                />
            ))}
        </>
    );
};

const ActivityComponents: Record<TabActivity, React.FC<{ events: Array<StoreTaskActivityEvent> }>> = {
    [TabActivity.Default]: ({events})=> <ActivityTaskEdition events={events}/>,
    [TabActivity.Conversation]: () => <TaskConversation />,
};
