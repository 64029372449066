import { AutomationTaskFullModel, StoreTaskFullModel } from "api/task/storeTask/storeTaskModel";
import { UserDTO } from "api/user/userModel";
import i18n from 'i18n';

// TODO: We have this because we don't have an automatition user yet, it was approved.
export const USER_AUTOMATION = 'automationUser';
export const automationUser: UserDTO = {
    firstName: "Progress",
    lastName: "Retail",
    id: USER_AUTOMATION,
    companyId: '',
    avatarId: null,
    companyLocationId: null,
    companyLocationName: null,
    isCompanyLocationStore: null,
    email: '',
    role: null,
    roleId: null,
    phoneNumber: null,
    isSuperAdmin: null,
    isDeleted: null,
    meta: undefined,
    language: undefined,
    employeeTitle: `${i18n.t('Automation')}`
}

export const adapterAutomationToStoreTask = (task: AutomationTaskFullModel): StoreTaskFullModel=>({
    attachments: [],
    checklistItems: task.checklist.map(ch=>({
        id: ch.id,
        order: ch.order,
        name: ch.name,
        completed: ch.completed,
        requiresAttachment: ch.requiresAttachment,
        requiresCustomResponse: ch.requiresCustomResponse,
        attachments: ch?.attachments ?? [],
        customResponse: ch.customResponse,
        completedBy: undefined,
        completedAt: undefined
    })),
    createdAt: task.createdAt,
    createdBy: USER_AUTOMATION,
    description: task.description,
    dueDate: task.dueDate,
    id: task.id,
    isOverdue: task.isOverdue,
    title: task.title,
    status: task.status,
    isApproval: false,
    isSubmittedOverdue: false,
    isCompletedOverdue: false,
    isExpirable: false,
    isExpired: false,
    locationId: "",
    locationName: " ",
    parentTaskId: null,
    prototypeAttachments: [],
    prototypeId: "",
    watchers: [],
    tag: null,
    displayedStatus: task.displayedStatus,
    version: 0,
    events: task.events?.map(e => ({
        eventType: e.eventType,
        createdOn: e.createdOn,
        fieldChanges: e.fieldChanges,
        taskId: task.id,
        userId: USER_AUTOMATION,
        isOverdue: undefined,
        clientId: null
    })),
});