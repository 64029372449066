import React from 'react';
import { LightboxItem } from './Lightbox';
import { Trans } from 'react-i18next';

export const VideoSlide = ({ original }: LightboxItem) => {
    return (
        <video
            className="image-gallery-video"
            src={original}
            controls
            controlsList="nofullscreen"
        >
            <Trans>Sorry, your browser doesn\'t support embedded videos</Trans>
        </video>
    );
};
