import { IEntity, IEntityError } from 'model/entity';
import { IResponseErrorPayload } from 'model/error';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import { ScheduledTaskDetails, ScheduledTaskHistorySuccess, storeIdsByScheduledTaskRequest, StoreIdsByScheduledTaskSuccess, ScheduledTaskSearchRequest, ScheduledTaskSearchResponse, ScheduledTaskHistoryRequest } from './scheduledTaskModel';

const SCHEDULED_TASK_LIST = 'tasks/template/GET_LIST';
export const requestScheduledTasks = createApiActionsByName<
    ScheduledTaskSearchRequest, ScheduledTaskSearchResponse, IResponseErrorPayload, typeof SCHEDULED_TASK_LIST
>(SCHEDULED_TASK_LIST);

const TASK_TEMPLATE_GET = 'tasks/template/GET';
export const getTaskTemplate = createApiActionsByName<
    string, ScheduledTaskDetails, IEntityError, typeof TASK_TEMPLATE_GET
>(TASK_TEMPLATE_GET);

const TASK_TEMPLATE_UPDATE = 'tasks/template/UPDATE';
export const updateTaskTemplate = createApiActionsByName<
    ScheduledTaskDetails, ScheduledTaskDetails, IEntityError, typeof TASK_TEMPLATE_UPDATE
>(TASK_TEMPLATE_UPDATE);

const TASK_TEMPLATE_DELETE = 'tasks/template/DELETE';
export const deleteTaskTemplate = createApiActionsByName<
    string, IEntity, IEntityError, typeof TASK_TEMPLATE_DELETE
>(TASK_TEMPLATE_DELETE);

const SCHEDULED_TASK_HISTORY_GET = 'task/template/history/GET';
export const getScheduledTaskHistory = createApiActionsByName<
    ScheduledTaskHistoryRequest, ScheduledTaskHistorySuccess, IEntityError, typeof SCHEDULED_TASK_HISTORY_GET
>(SCHEDULED_TASK_HISTORY_GET);

const TASK_TEMPLATE_STORES_GET = 'tasks/template/stores/GET';
export const getStoreIdsByScheduledTask = createApiActionsByName<
    storeIdsByScheduledTaskRequest, StoreIdsByScheduledTaskSuccess, IEntityError, typeof TASK_TEMPLATE_STORES_GET
>(TASK_TEMPLATE_STORES_GET);

export const SCHEDULED_TASK_HISTORY_FLAGS_GET = 'task/template/history/flags/GET';
export const getScheduledTaskHistoryFlags = createSingleAction<string, typeof SCHEDULED_TASK_HISTORY_FLAGS_GET>(
    SCHEDULED_TASK_HISTORY_FLAGS_GET,
);

export type ScheduledTaskActions =
    | ActionsReturnTypes<typeof requestScheduledTasks>
    | ActionsReturnTypes<typeof getTaskTemplate>
    | ActionsReturnTypes<typeof updateTaskTemplate>
    | ActionsReturnTypes<typeof deleteTaskTemplate>
    | ActionsReturnTypes<typeof getScheduledTaskHistory>
    | ActionsReturnTypes<typeof getStoreIdsByScheduledTask>
    | ReturnType<typeof getScheduledTaskHistoryFlags>;
