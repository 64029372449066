import { useMemo } from 'react';
import { Fade, Tooltip, Typography } from '@material-ui/core';
export const maxDisplayedLength = 30;

export const TooltipTitle = ({ title = '', maxLength = maxDisplayedLength}: {title?: string, maxLength?: number}) => {
    const safeTitle = useMemo(() => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + '...';
        } else {
            return title;
        }
    }, [title, maxLength]);

    return (
        <Tooltip title={title} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <Typography variant="body2" style={{ whiteSpace: "pre-wrap"}}> 
                {safeTitle}
            </Typography>
        </Tooltip>
    )
}
