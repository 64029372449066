import { client as appConfigClient, listFeatureFlags } from 'modules/root/app-configuration';
import { call, delay, put, takeEvery } from 'typed-redux-saga';
import { requestFeatureFlags } from './actions';
import { FeatureFlagState } from './model';
import { FEATURE_REQUEST_INTERVAL_MS } from './featureCommonConstants';

function* getFeatureFlags() {
    yield put(requestFeatureFlags.init());
}

function* requestFeatureFlagsInitWatcherSaga() {
    yield takeEvery(
        requestFeatureFlags.initType,
        function* () {
            try {
                const featureFlags: FeatureFlagState[] = yield call(listFeatureFlags, appConfigClient);
                yield put(requestFeatureFlags.success(featureFlags));
                yield* delay(FEATURE_REQUEST_INTERVAL_MS);
                yield put(requestFeatureFlags.init());
            } catch (error) {
                yield put(requestFeatureFlags.error({ error }));
            }
        },
    );
}

export const featureFlagsSagas = [
    getFeatureFlags,
    requestFeatureFlagsInitWatcherSaga,
];
