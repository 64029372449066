import axios, { AxiosError, AxiosResponseTransformer } from 'axios';
import type { ActionWithPayload } from 'utils/store/actionUtils';
import camelcaseKeys from 'camelcase-keys';
import { appSource, IMessage, workerSource } from 'api/model';
import { IErrorContract } from '../model/entity';
import { SerializableError } from '../utils/error/serializableError';
import { baseFilesCdnUrl, baseFilesUrl, filesFunctionKey } from 'utils/globals';

export function createAppMessage<P>(action: ActionWithPayload<P>): IMessage<P> {
    return {
        ...action,
        source: appSource,
    };
}

function tryConvertPayloadError(payload: any) {
    if (payload && payload.error) {
        return {
            ...payload,
            error: new SerializableError(payload.error),
        };
    }
    return payload;
}

export function createWorkerMessage<P>(action: ActionWithPayload<P>): IMessage<P> {
    const { type, payload } = action;
    return {
        type,
        payload: tryConvertPayloadError(payload),
        source: workerSource,
    };
}

export function workerSendMessage(message: IMessage<any>, transfer?: ArrayBuffer[]) {
    // Should be executed in WebWorker context only
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    postMessage(message, transfer);
}

export function tryGetErrorContractPayload(error: any): Partial<IErrorContract> {
    const axiosError: AxiosError = error;

    if (axiosError) {
        const objectData: Object = axiosError?.response?.data !== null  && typeof axiosError?.response?.data === 'object' 
            ? axiosError?.response?.data : {};
        const {
            code = undefined,
            message = undefined,
            description = undefined,
        } = convertToCamelCase(objectData) as Partial<IErrorContract>;

        return { code, message, description };
    }
    return {};
}

export const convertToCamelCase = (data: Object) => camelcaseKeys(data, { deep: true });
export const camelCaseResponseTransformer: AxiosResponseTransformer = <D = any>(data: D) => {
    if (data && typeof data === 'object') {
        return convertToCamelCase(data);
    }
    return data;
};

export const getFileUrl = (fileId: string) => `${baseFilesUrl}/attachments/${fileId}?code=${filesFunctionKey}`;
export const getThumbnailUrl = (fileId: string) => `${baseFilesUrl}/thumbnails/${fileId}?code=${filesFunctionKey}`;

export const getFileCdnUrl = (fileId: string, token: string) => `${baseFilesCdnUrl}/attachments/${fileId}?${token}`;
export const getThumbnailCdnUrl = (fileId: string, token: string) => `${baseFilesCdnUrl}/thumbnails/${fileId}?${token}`;

export const toDataURL = async (fileId: string, token: string) => {
    const response = await axios.get(getFileCdnUrl(fileId, token), { responseType: 'blob' })
        .then(({ data }) => {
            if (data instanceof Blob) {
            const objectURL = URL.createObjectURL(data);
            return objectURL;
            }
        })
        .catch((error) => {
            return '';
        });

    return {url: response}
}

export const getFaviconGoogleService = (domain: string) => `http://www.google.com/s2/favicons?domain=${domain}/&sz=128`;
