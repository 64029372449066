import { FieldMetaProps } from 'formik';
import { random } from 'lodash';

export function hasFormikError(meta: FieldMetaProps<any>) {
    return meta.touched && !!meta.error;
}

export function generateColor() {
    const hexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
    let code = "";
    for(let i=0; i<6; i++){
        code += hexArray[Math.floor(random(15))];
    }
    return `#${code}`
}
