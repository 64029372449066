import { SimpleTaskListHeader } from 'modules/task/taskList/components/SimpleTaskListHeader';
import { ButtonSwitchType } from 'modules/task/taskList/components/ViewSwitch/TypeViewSwithMap';
import React from 'react';
import { TasksFilterComponentProps } from '../TypeTaskFilterMap';
import { useSelector } from 'react-redux';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { ViewSwitch } from 'modules/task/taskList/components/ViewSwitch/ViewSwitch';
import { LocationTagFilter } from './locationTask/LocationTagFilter';
import { LocationDateRangeFilter } from './locationTask/LocationDateRangeFilter';
import { useTaskFilterStyles } from '../taskFilterStyles';
import { FlexBoxContainer } from './common/FlexBoxContainer';
import { LocationDownloadTable } from './locationTask/LocationDownloadTable';

export const LocationTreeFilter: React.FC<TasksFilterComponentProps> = ({ locationId }) => {
  const location = useSelector(selectLocationsByIds)[locationId];
  const classes = useTaskFilterStyles();

  return (
    <FlexBoxContainer flexDirection='row' flexWrap='wrap' alignItems='center' className={classes.locationContainer}>
      <SimpleTaskListHeader regionName={location?.name} showFullRegionName={false}/>
      <ViewSwitch type={ButtonSwitchType.ParentTasks} />
      <LocationTagFilter />
      <LocationDateRangeFilter />
      <LocationDownloadTable />
    </FlexBoxContainer>
  )
}
