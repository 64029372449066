import { requestLocationChildren, RequestLocationChildrenTypes } from 'api/location/locationActions';
import { Location } from 'api/location/locationModel';
import { ItemsById } from 'model/entity';
import { combineReducers } from 'redux';
import { itemsByCustomIds } from 'utils/helpers/itemsByIds';

const initialState = {
    locationsById: {},
    relationsById: {},
    locationsInProgress: [],
};

function locationsInProgress(
    state: Array<string> = initialState.locationsInProgress,
    action: RequestLocationChildrenTypes,
) {
    switch (action.type) {
        case requestLocationChildren.initType:
            return [...state, action.payload.locationId];
        case requestLocationChildren.successType:
            return state.filter(item => item !== action.payload.parentId);
        case requestLocationChildren.errorType:
            return state.filter(item => item !== action.payload.id);
        default:
            return state;
    }
}

function locationsById(
    state: ItemsById<Location> = initialState.locationsById,
    action: RequestLocationChildrenTypes,
) {
    if (action.type === requestLocationChildren.successType) {
        return {
            ...state,
            ...itemsByCustomIds(action.payload.children, 'locationId'),
        };
    } else {
        return state;
    }
}

function relationsById(
    state: Record<string, Array<string>> = initialState.relationsById,
    action: RequestLocationChildrenTypes,
) {
    if (action.type === requestLocationChildren.successType) {
        const { parentId, children } = action.payload;
        return {
            ...state,
            [parentId]: children.map(item => item.locationId),
        };
    } else {
        return state;
    }
}

export const location = combineReducers({
    locationsById,
    relationsById,
    locationsInProgress,
});
