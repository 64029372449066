import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export function ListLoadingPlaceholder() {
    return (
        <Box p="0 40px">
            {
                (new Array(8).fill(0).map((v, i) => (
                    <Box key={i} mb="5px">
                        <Skeleton
                            width="100%"
                            height="20px"
                        />
                    </Box>
                )))
            }
        </Box>
    );
}
