import React, { useEffect } from 'react';
import { useActions } from "utils/store/useActions";
import { requestStoreTasksForReviewCount } from 'api/task/taskReview/taskReviewActions';
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { selectTaskTagsFilter } from 'store/modules/task/taskTag/taskTagSelectors';
import { selectTaskFilterCreatedBySelected } from 'store/modules/task/taskFilter/taskFilterSelectors';

const TAKE_COUNT_INTERVAL_MS = 15 /*seconds*/ * 1000 /* millis */;

export const TasksToReviewPoller: React.FC = () => {
    const isMultiSiteManager = !useCurrentUser()?.isCompanyLocationStore;
    const canCreateTask = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const filterTagActive = useSelector(selectTaskTagsFilter);
    const filterCreatedByActive = useSelector(selectTaskFilterCreatedBySelected);
    const actions = useActions({
        getCount: requestStoreTasksForReviewCount.init,
    });

    useEffect(() => {
        let intervalId: ReturnType<typeof setTimeout>;

        if (isMultiSiteManager && canCreateTask) {
            actions.current.getCount({ tags: filterTagActive, createdBy: filterCreatedByActive});
            intervalId = setInterval(()=>{actions.current.getCount({ tags: filterTagActive, createdBy: filterCreatedByActive})}, TAKE_COUNT_INTERVAL_MS);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [actions, isMultiSiteManager, canCreateTask, filterTagActive, filterCreatedByActive]);

    return null;
};
