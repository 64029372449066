import React, { useMemo } from 'react';
import {
    Column,
    useFlexLayout,
    useTable,
} from 'react-table';
import { ColumnId } from './model';
import { useLoad } from 'modules/task/taskList/hooks/useLoad';
import { makeRowRenderer, makeSkeletons } from 'modules/shared/components/gridHelpers';
import { ScheduledTaskShort } from 'api/task/scheduledTask/scheduledTaskModel';
import {
    selectScheduledTasksFetching,
    selectScheduledTasksNextPageToken,
} from 'store/modules/task/scheduledTask/scheduledTaskSelectors';
import { requestScheduledTasks } from 'api/task/scheduledTask/scheduledTaskActions';
import { taskListResetData } from 'store/modules/task/taskListComponent/taskListComponentActions';
import { ColorStatusCell } from '../../components/sharedCells/ColorStatusCell';
import { CreatorCell } from '../../components/sharedCells/CreatorCell';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { DateCell } from '../../components/sharedCells/DateCell';
import { TitleCell } from './TitleCell';
import { VirtualScrollGrid } from 'modules/shared/components/VirtualScrollGrid';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { ActionsCell } from './ActionsCell';
import { EmptyState } from '../../components/EmptyState';
import { SpacerCell } from 'modules/shared/components/SpacerCell';
import { Trans } from 'react-i18next';

export type ScheduledTasksGridProps = {
    data: ScheduledTaskShort[];
    isFetching: boolean;
    height?: number;
}

const skeletons = makeSkeletons<ScheduledTaskShort>(10);
const baseSearchRequest = {};
const gridColumns: Column<ScheduledTaskShort>[] = [
    {
        id: ColumnId.Id,
        accessor: () => DisplayedTaskStatus.Default,
        Cell: ColorStatusCell,
        width: '7px',
    },
    {
        id: ColumnId.TaskName,
        Header: <Trans>Task</Trans>,
        accessor: 'title',
        Cell: TitleCell,
        width: 300,
    },
    {
        id: ColumnId.PublishDate,
        Header: <Trans>Publish Date</Trans>,
        accessor: 'nextOccurranceDate',
        Cell: DateCell,
        width: 100,
    },
    {
        id: ColumnId.Creator,
        Header: <Trans>Creator</Trans>,
        accessor: 'createdBy',
        Cell: CreatorCell,
        width: 160,
    },
];

export const ScheduledTasksGrid = ({ data, isFetching, height = 500 }: ScheduledTasksGridProps) => {
    const { preload, nextPageToken } = useLoad(
        selectScheduledTasksNextPageToken,
        selectScheduledTasksFetching,
        requestScheduledTasks.init,
        taskListResetData,
        baseSearchRequest,
    );
    const viewScheduledTaskEnabled = useFeatureState(FeatureFlags.ViewScheduledTask);
    const columns = useMemo(() => {
        const lastColumn: Column<ScheduledTaskShort> = viewScheduledTaskEnabled
            ? {
                id: 'actions',
                accessor: 'id',
                Cell: ActionsCell,
                width: '52px',
            }
            : {
                id: 'spacer',
                Cell: SpacerCell,
                width: 7,
            };

        return [...gridColumns, lastColumn];
    }, [viewScheduledTaskEnabled]);
    const showEmptyState = data.length === 0 && !isFetching;
    const showSkeletons = data.length === 0 && isFetching;
    const totalData = showSkeletons ? skeletons : data;
    const dataGrid = useTable<ScheduledTaskShort>(
        { columns, data: totalData },
        useFlexLayout,
    );

    return (
        <VirtualScrollGrid
            dataGrid={dataGrid}
            showEmptyState={showEmptyState}
            emptyState={<EmptyState />}
            height={height}
            nextPageToken={nextPageToken}
            preload={preload}
            customRowRenderer={makeRowRenderer}
        />
    );
};
