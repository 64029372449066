import React from 'react';
import { CellProps } from 'react-table';
import { IStoreTaskGridRowData } from '../model';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useCellTaskAction } from 'modules/task/taskList/hooks/useCellTaskAction';

export const ActionsCell: React.FC<CellProps<IStoreTaskGridRowData, string>> = props => {
    const { original: task } = props.row;
    const { id: userId = '', isCompanyLocationStore } = useCurrentUser() || {};
    const isTaskOwner = userId === task.createdBy;
    const canCreateDelete = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const isPermittedUser = canCreateDelete && (isTaskOwner) && !!isCompanyLocationStore;
    const { allItems, taskId } = useCellTaskAction(task, isPermittedUser, true);

    return (
        <ActionButton
            autoHide
            disabled={task.isSkeleton}
            entity={taskId}
            width={16}
            items={allItems}
        />
    );
};
