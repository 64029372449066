import React from 'react';
import { IChecklistItem } from 'api/task/taskModel';
import {SortEnd, SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import { Box, Fade, IconButton, List, ListItem, ListItemSecondaryAction, Switch, Tooltip, Typography } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { InlineEdit } from 'components/basicInputs/inlineEdit/InlineEdit';
import { CHECKLIST_ITEM_MAX_TITLE_LENGTH, checklistItemNameSchema } from '../form/validation';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SubjectIcon from '@material-ui/icons/Subject';
import { CloseOutlined } from '@material-ui/icons';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { FieldArray, useField } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { typeItemRequiresEnum } from '../checklist/TaskChecklist';

const DragHandle = SortableHandle(({isDisabledField = false}: { isDisabledField: boolean }) => 
  <IconButton aria-label="item drag & drop"color="primary" size="small" disabled={isDisabledField}>
      <DragIndicatorIcon fontSize="medium"/>
  </IconButton>);

const SortableItemForm = SortableElement(({name, isDisabledField = false, remove, onItemRequiredChange, onItemPhotoRequiredChange}: {name: string, isDisabledField?: boolean, remove: ()=> void, onItemRequiredChange: (item: typeItemRequiresEnum) => void, onItemPhotoRequiredChange:(event: React.ChangeEvent<HTMLInputElement>) => void }) => {
  const classes = useFormStyles();
  const isCustomItemEnabled = useFeatureState(FeatureFlags.tasksCustomChecklistOptions);
  const [ field ] = useField<IChecklistItem>(name);
  const { value, onChange } = field;

  return (
      <ListItem
          className={classes.listItemDrag}
          classes={{ container: classes.listItemRootDrag }}
      > 
        <DragHandle isDisabledField={isDisabledField}/>
        <Box className={classes.containerInLineEdit}>
            <InlineEdit
                disabled={isDisabledField}
                value={value.name}
                title={value.name}
                typographyClass={classes.subtaskItem}
                onChange={(newValue)=>onChange({ target: { name: `${name}.name`, value: newValue } })}
                validation={checklistItemNameSchema}
                showCounter
                maxLength={CHECKLIST_ITEM_MAX_TITLE_LENGTH}
            />
        </Box>
        <ListItemSecondaryAction classes={{ root: classes.listItemSecondaryActionsDrag }}>
            <Box className={classes.checkboxActions}>
                {isCustomItemEnabled ?
                    <Box display="flex">
                        <Tooltip title={<Trans>Require Text Submission for Approval</Trans>} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                            <IconButton aria-label="Require Text Submission for Approval"
                            onClick={()=> onItemRequiredChange(typeItemRequiresEnum.RequiresCustomResponse)}
                            color={value?.requiresCustomResponse ?"primary" :"default"} disabled={isDisabledField}>
                                <SubjectIcon fontSize="medium"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<Trans>Require File Submission for Approval</Trans>} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                            <IconButton aria-label={`${t('Require File Submission for Approval')}`}
                            onClick={()=>onItemRequiredChange(typeItemRequiresEnum.RequiresAttachment)} 
                                color={value?.requiresAttachment ?"primary" :"default"} disabled={isDisabledField}>
                                <AttachFileIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    :<>
                        <Switch
                            disabled={isDisabledField}
                            checked={value.requiresAttachment}
                            color="primary"
                            onChange={onItemPhotoRequiredChange}
                        />
                        <Typography variant="caption">
                            <Trans>File</Trans>
                        </Typography>
                </>}
                {!isDisabledField && (
                    <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={remove}
                    >
                        <CloseOutlined className={classes.listRemoveIcon} />
                    </IconButton>
                )}
            </Box>
        </ListItemSecondaryAction>
      </ListItem>
    )
});

const SortableMainContainer = SortableContainer(({children}: any) => 
    <List>{children}</List>
);

type DragCheckListProps = {
    fieldName: string;
    onItemRequiredChange: (index: number, typeItem: typeItemRequiresEnum) => void;
    onItemPhotoRequiredChange:(index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean
};
export const DragCheckList: React.FC<DragCheckListProps> = ({ fieldName, onItemRequiredChange, onItemPhotoRequiredChange, disabled = false }) => {
  const [ field ] = useField<Array<IChecklistItem>>(fieldName);
  const { value } = field;

  const onSortEnd = ({oldIndex, newIndex, move}: {oldIndex: number, newIndex: number, move: any}) => {
    move(oldIndex, newIndex)
  };

  return (
      <FieldArray 
        name={fieldName}
        render={({ move, remove }) => (
          <SortableMainContainer 
              onSortEnd={(e: SortEnd)=> onSortEnd({oldIndex: e.oldIndex, newIndex:e.newIndex, move})}
              useDragHandle
          >
              {value.map((d, index) => (
                <SortableItemForm 
                  key={uuidv4()} 
                  index={index} 
                  name={`${fieldName}.${index}`}
                  isDisabledField={disabled}
                  remove={() => remove(index)}
                  onItemRequiredChange={(item: typeItemRequiresEnum) => onItemRequiredChange(index, item)}
                  onItemPhotoRequiredChange={(event: React.ChangeEvent<HTMLInputElement>) => onItemPhotoRequiredChange(index, event)}
                />
              ))}
          </SortableMainContainer>
        )}/>
  );
}