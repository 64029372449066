import React, { useEffect, useMemo } from 'react';
import { Paper, TableContainer } from '@material-ui/core';
import { useActions } from "utils/store/useActions";
import { eventModalActiveId, getCalendarTasks, parentTaskByLocationModalActiveId, resetFilterLocationCalendar } from 'store/modules/task/calendarTask/calendarTaskActions';
import { TaskType } from './model';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { useFixedSizeListWidth } from 'modules/shared/hooks';
import { TaskCalendar } from 'modules/task/taskCalendar/TaskCalendar';
import { IEventCalendar } from 'modules/task/taskCalendar/model';
import { View } from 'react-big-calendar';

export interface ICalendarTasksProps {
    data: Array<IEventCalendar>;
    height: number;
    viewsCustom?: View[];
    activeViewCustom?: View;
}

export const CalendarTasks: React.FC<ICalendarTasksProps> = ({
    data,
    height,
    viewsCustom,
    activeViewCustom
}) => {
    const baseSearchRequest = useMemo(() => {
        return {
            dateStart: '',
            dateEnd: '',
        };
    }, []);
    const actions = useActions({
        getCalendarTasks: getCalendarTasks,
        setModalTaskId: storeTaskSetActiveId,
        setModalParentTaskId: parentTaskByLocationModalActiveId,
        setModalEventId: eventModalActiveId,
        resetFilterLocationCalendar
    });
    const {innerWidth} = window;
    const { containerRef } = useFixedSizeListWidth(innerWidth);

    useEffect(()=>{
        actions.current.resetFilterLocationCalendar();
        actions.current.getCalendarTasks(baseSearchRequest);
    }, [actions, baseSearchRequest]);

    const openSideBar = (event: IEventCalendar) => {
        if(event.storeTasksCount > 0){
            actions.current.setModalParentTaskId(event.id);
            return;
        }
        switch (event.taskType) {
            case TaskType.Task:
                actions.current.setModalTaskId({taskId: event.id});
                break;
            case TaskType.AutomationTask:
                actions.current.setModalTaskId({taskId: event.id, isAutomation: true});
                break;
            case TaskType.CalendarEvent:
                actions.current.setModalEventId(event.id);
                break;
            default:
                break;
        }
    }

    return (
        <TableContainer ref={containerRef} component={Paper}>
            <TaskCalendar
                data={data}
                openSideBar={openSideBar}
                height={height}
                viewsCustom={viewsCustom}
                activeViewCustom={activeViewCustom}
            />
        </TableContainer>
    );
};
