import { StyledFormPageChildrenWrapper, StyledFormPageContainer } from 'modules/shared/components/FormPageView';
import { useEffect } from 'react';
import { TaskActivity } from '../singleTask/components/taskActivity/TaskActivity';
import { ScheduledTaskDetails } from 'api/task/scheduledTask/scheduledTaskModel';
import { getScheduledTaskHistoryFlags } from 'api/task/scheduledTask/scheduledTaskActions';
import { useActions } from 'utils/store/useActions';

interface ScheduledTaskHistoryProps {
    taskTemplate: ScheduledTaskDetails,
    templateId: string
}

export const ScheduledTaskHistory = ({ taskTemplate, templateId }: ScheduledTaskHistoryProps) => {
    const actionsScheduledTaskHistory = useActions({
        getScheduledTaskHistory: getScheduledTaskHistoryFlags
    });

    useEffect(()=>{
        actionsScheduledTaskHistory.current.getScheduledTaskHistory(templateId);
    },[actionsScheduledTaskHistory, templateId]);

    if( !taskTemplate?.events?.length ){
        return null;
    }

    return (
        <StyledFormPageContainer>
            <StyledFormPageChildrenWrapper>
                <TaskActivity task={taskTemplate} hideConversationTab/>
            </StyledFormPageChildrenWrapper>
        </StyledFormPageContainer>
    )
}
