import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { TasksBulk } from './tasksBulkModel';
import { IResponseErrorPayload } from 'model/error';
import { IEntityError, IEntitySuccess } from 'model/entity';

const TASK_BULK_HISTORY = 'task/bulk/GET';
export const requestTasksBulkHistory = createApiActionsByName<
    string, TasksBulk, IResponseErrorPayload, typeof TASK_BULK_HISTORY
>(
    TASK_BULK_HISTORY,
);

const REQUEST_TASK_BULK_DELETE = 'task/bulk/DELETE';
export const requestDeleteTaskBulk = (
    createApiActionsByName<string, IEntitySuccess, IEntityError, typeof REQUEST_TASK_BULK_DELETE>(
        REQUEST_TASK_BULK_DELETE,
    )
);

export type TasksBulkApiActions =
    | ActionsReturnTypes<typeof requestTasksBulkHistory>
    | ActionsReturnTypes<typeof requestDeleteTaskBulk>;
