import { put, select, takeEvery } from 'typed-redux-saga';
import { requestParentTaskDetails } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { selectUserById } from 'store/modules/user/userSelectors';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { StoreTaskApiActions, requestApproveStoreTask, requestChecklistItemSetCompleted, requestDeclineStoreTask, requestSubmitStoreTask } from 'api/task/storeTask/storeTaskActions';
import { ItemsById } from 'model/entity';
import { requestTasksStatsByLocation } from 'api/task/statistics/statisticsActions';
import { selectTasksStatsLoadingByLocationIds, selectTasksStatsByLocationRangeDate, selectTasksStatsByLocationTags } from '../statistics/statisticsSelectors';
import { requestStoreTasksDelete } from 'api/task/parentTask/parentTaskActions';
import { selectActiveParentTaskId, selectParentChildrenTasks } from './taskListComponentSelectors';
import { buildLocationsTree } from 'modules/locationPicker/LocationPicker/helpers';
import { removeLeaf } from 'utils/helpers/trees';
import { taskListRemoveParentsChildren, taskListRemoveStore } from './taskListComponentActions';

function* parentTaskDetailsSuccessWatcher() {
    yield* takeEvery(
        requestParentTaskDetails.successType,
        function* (action: ReturnType<typeof requestParentTaskDetails.success>) {
            const userId = action.payload.createdBy;
            const user = yield select(selectUserById(userId));
            if (!user) {
                yield put(requestMultipleUsersInfo.init([userId]));
            }
        },
    );
}

function* parentTaskUpdateChecklistItemSuccessWatcher() {
    yield* takeEvery(
        requestChecklistItemSetCompleted.successType,
        function* (action: ReturnType<typeof requestChecklistItemSetCompleted.success>) {
            const { task: { locationId, checklistItems } } = action.payload;
            const countCompleted = checklistItems.reduce((acc, item) => acc + Number(item.completed), 0);
            const loadingById: ItemsById<boolean> = yield select(selectTasksStatsLoadingByLocationIds);
            const { endDate, startDate } = yield select(selectTasksStatsByLocationRangeDate);
            const { tags } = yield select(selectTasksStatsByLocationTags);

            if (!loadingById[locationId] && ( checklistItems.length - countCompleted ) <= 1) {
                yield put(requestTasksStatsByLocation.init({
                    locationId,
                    endDate,
                    startDate,
                    tags,
                }));
            }
        },
    );
}

function* parentTaskUpdateManageStoreTaskStatusSuccessWatcher() {
    yield* takeEvery(
        [   requestApproveStoreTask.successType,
            requestDeclineStoreTask.successType,
            requestSubmitStoreTask.successType
        ],
        function* (action: StoreTaskApiActions) {
            const { locationId } = action.payload;
            const loadingById: ItemsById<boolean> = yield select(selectTasksStatsLoadingByLocationIds);
            const { endDate, startDate } = yield select(selectTasksStatsByLocationRangeDate);
            const { tags } = yield select(selectTasksStatsByLocationTags);

            if (!loadingById[locationId] ) {
                yield put(requestTasksStatsByLocation.init({
                    locationId,
                    endDate,
                    startDate,
                    tags,
                }));
            }
        },
    );
}

function* storeTasksDeleteSuccessWatcher() {
    yield* takeEvery([ requestStoreTasksDelete.successType],
        function* (action: StoreTaskApiActions) {
            const { storeTaskId } = action.payload;
            const parentChildreTasks: ItemsById<string[]> = yield select(selectParentChildrenTasks);
            const rootTree: string = yield select(selectActiveParentTaskId);
            const rootNode = buildLocationsTree(rootTree, parentChildreTasks as Record<string, string[]>)
            const deletedParents = removeLeaf(rootNode, storeTaskId);

            yield* put(taskListRemoveStore(storeTaskId));
            yield* put(taskListRemoveParentsChildren({parent: deletedParents.deletedParentValue, childrenId: deletedParents.deletedNode?.value}));

            if(deletedParents.deletedParentValue !== rootTree){
                yield* put(requestParentTaskDetails.init(deletedParents.deletedParentValue));
            }
        },
    );
}

export const taskListComponentSagas = [
    parentTaskDetailsSuccessWatcher,
    parentTaskUpdateChecklistItemSuccessWatcher,
    parentTaskUpdateManageStoreTaskStatusSuccessWatcher,
    storeTasksDeleteSuccessWatcher,
];
