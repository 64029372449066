import { makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ITaskTag } from './model';
import { Close, Delete, Done, Edit } from '@material-ui/icons';
import { TagBoxColor } from './TagBoxColor';
import { Box, IconButton, Typography } from '@material-ui/core';
import { TagColorInput } from './TagColorInput';
import { useActions } from 'utils/store/useActions';
import { requestDeleteTaskTag } from 'api/task/tag/tagActions';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        padding: theme.spacing(0, 1) 
    },
    buttonAction: {
        padding: theme.spacing(.5)
    },
}));

type TagItemProp = ITaskTag & {
    onEditTag: () => void;
    onCloseTag: () => void;
    onDeleteTag: () => void;
    isUpdating: boolean;
    isSelected: boolean;
};

export const TagItem = ({id, name, color, onTagTaskClick, isSelected = false, isEditing = false, isDeleting = false, onEditTag, onDeleteTag, onCloseTag, isUpdating}: TagItemProp) => {
    const classes = useStyles();
    const actionsDeleteTag = useActions({ deleteTag: requestDeleteTaskTag.init });

    const handleClick = () => onTagTaskClick && onTagTaskClick({id, name, color});
    const handleDeleteTag = () => {
        actionsDeleteTag.current.deleteTag({id, isMock: id === 'mock-id'})
    }

    if(isDeleting) {
        return(
            <Box display="flex" flexDirection="row" alignItems="center" className={classes.item}>
                <Typography variant="body2"> 
                    <Trans>Are you sure that you want to delete the label?</Trans>
                </Typography>
                <Box display="flex" flexDirection="column">
                    <IconButton aria-label="Save" className={classes.buttonAction} onClick={handleDeleteTag}>
                        <Done fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Close" className={classes.buttonAction} onClick={onDeleteTag}>
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
        )
    }

    return (
        <ListItem
            classes={{ root: classes.item }}
            button
            selected={isSelected}
            >   
            {isEditing ? <TagColorInput tagData={{id, name, color}} onCloseTag={onCloseTag} isDisabled={isUpdating}/>
                : <>
                    <Box display="flex" flexDirection="row" width={"85%"} alignItems="center" onClick={handleClick}>
                        <TagBoxColor bgcolor={color}> 
                            {isSelected && <Close color="action" />}
                        </TagBoxColor>
                        <ListItemText primary={name}  onClick={handleClick}/>
                    </Box>
                    <Box display="flex" flexDirection="column" width={"7.5%"}>
                        <IconButton aria-label="Edit" className={classes.buttonAction} onClick={onEditTag} disabled={isUpdating}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="column" width={"7.5%"}>
                        <IconButton aria-label="Edit" className={classes.buttonAction} onClick={onDeleteTag} disabled={isUpdating}>
                            <Delete fontSize="small" />
                        </IconButton>
                    </Box>
                </>
            }
        </ListItem>
    );
}
