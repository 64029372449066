import { FormHelperText } from "@material-ui/core";

export type InputErrorProps = {
    error?: string;
}

export const InputError = ({ error }: InputErrorProps) => {
    return (
        <FormHelperText error>
            {error}
        </FormHelperText>
    );
};
