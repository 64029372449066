import React from 'react';
import { CellProps } from 'react-table';
import { UserInfo } from 'components/userInfo/UserInfo';
import { ISkeleton } from '../../model';
import { UserInfoSkeleton } from 'components/userInfo/UserInfoSkeleton';

type CreatorCellData = ISkeleton;

export const CreatorCell: React.FC<CellProps<CreatorCellData, string>> = ({ row, value }) => {
    const { isSkeleton } = row.original;

    return isSkeleton || !value
        ? <UserInfoSkeleton />
        : <UserInfo userId={value} />;
};
