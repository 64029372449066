import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { selectTaskGalleryUpdatingItems } from 'store/modules/task/taskGallery/taskGallerySelectors';
import { useTaskByLocationsHeaderStyles } from 'modules/task/taskList/views/TaskByLocationsView/hooks/useTaskByLocationsHeaderStyles';
import { StyledDotWrapper } from 'modules/task/taskList/components/SimpleTaskListHeader';
import { TaskGalleryModel } from 'api/task/taskGallery/taskGalleryModel';
import { DisplayStatus } from '../taskList/components/TaskHeaderDetails/DisplayStatus';
import { useTaskGalleryStyles } from './useTaskGalleryStyles';

export type TaskGalleryHeaderProps = {
    task: TaskGalleryModel;
}

export const TaskGalleryHeader = ({ task }: TaskGalleryHeaderProps) => {
    const taskByLocationHeaderClasses = useTaskByLocationsHeaderStyles([]);
    const classes = useTaskGalleryStyles();
    const isTaskUpdating = useSelector(selectTaskGalleryUpdatingItems)[task.id];
    const displayedStatus = isTaskUpdating?.status ?? task.displayedStatus;

    return (
        <div className={taskByLocationHeaderClasses.infoWrapper}>
            <div className={taskByLocationHeaderClasses.info}>
                <div className={taskByLocationHeaderClasses.titleWrapper}>
                    <Box className={taskByLocationHeaderClasses.headerTitleLocationContainer}>
                        <LocationOnOutlinedIcon className={classes.icon}/>
                        <Typography variant="h5" className={classes.locationName}>
                            {task.locationName}
                        </Typography>
                    </Box>
                    <Box className={taskByLocationHeaderClasses.headerTitleLocationContainer}>
                        <StyledDotWrapper>•</StyledDotWrapper>
                        <Typography variant="h6" className={classes.parentLocationName}>
                            {task.parentLocationName}
                        </Typography>
                        <DisplayStatus taskStatus={displayedStatus} showDivider={false}/>
                    </Box>
                </div>
            </div>
        </div>
    );
}
