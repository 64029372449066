import { IStoreState } from 'store/rootReducer';

export const selectParentTaskToRemove = (state: IStoreState): string | null => state.task.parentTask.parentTaskToRemove;
export const selectParentTasksFetching = (state: IStoreState) => state.task.parentTask.parentTasksFetching;
export const selectParentTasksRemoving = (
    (state: IStoreState): Array<string> => state.task.parentTask.removingParentTasks
);
export const selectParentTasksById = (state: IStoreState) => state.task.parentTask.parentTasksById;
export const selectParentTasksOrder = (state: IStoreState) => state.task.parentTask.parentTasksOrder;
export const selectParenttasksNextPageToken = (state: IStoreState) => state.task.parentTask.parentTasksNextPageToken;
export const selectParentTaskById = (state: IStoreState) => state.task.parentTask.parentTasksById;
