import React from 'react';
import { CellProps } from 'react-table';
import { IParentTaskGridRowData } from '../model';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useIsHigherOrSameLocation } from 'modules/hierarchy/hooks';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { useCellTaskAction } from 'modules/task/taskList/hooks/useCellTaskAction';


export const ActionsCell: React.FC<CellProps<IParentTaskGridRowData, string>> = props => {
    const { original: task } = props.row;
    const { rootLocationId: taskLocationId } = task;
    const { id: userId = '', companyLocationId: userCompanyLocationId = '' } = useCurrentUser() || {};
    const canCreateDelete = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const managerAccessEnabled = useFeatureState(FeatureFlags.ManagerAccessToTaskEditing);
    const isTaskOwner = userId === task.createdBy;
    const isManagerFromHigherLocation = useIsHigherOrSameLocation(userCompanyLocationId ?? '', taskLocationId)
        && managerAccessEnabled;
    const isPermittedUser = canCreateDelete && (isTaskOwner || isManagerFromHigherLocation);
    const { allItems, taskId } = useCellTaskAction(task, isPermittedUser);

    return (
        <ActionButton
            disabled={task.isSkeleton}
            entity={taskId}
            width={16}
            items={allItems}
            autoHide
        />
    );
};
