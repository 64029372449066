import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { selectUserPerformances } from 'store/modules/integration/integrationSelectors';
import { useSelector } from 'react-redux';
import {
    CardIntegration,
    GridIntegration,
    GridIntegrationChildren,
} from '../IntegrationStyles';

export const IntegrationPerformance = () => {
    const userPerformances = useSelector(selectUserPerformances);
    return (
        <Box mt={3}>
            <Typography variant='h3'>
                <Trans>Performance</Trans>
            </Typography>
            <GridIntegration>
                {userPerformances.map((prov) => (
                    <GridIntegrationChildren key={prov.id}>
                        <CardIntegration {...prov} />
                    </GridIntegrationChildren>
                ))}
            </GridIntegration>
        </Box>
    );
};
