import React, { useCallback, useState, useEffect } from 'react';
import { AuthClientEvent } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from './keycloak';
import { useActions } from 'utils/store/useActions';
import { tokensAuthSuccess, tokensRefreshSuccess } from 'api/auth/authActions';
import { useSelector } from 'react-redux';
import { selectAppLoading } from 'store/modules/auth/authSelectors';
import { selectFeatureFlags, selectFeatureFlagsLoading } from '../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../utils/featureFlags';
import { MaintenancePage } from '../maintenance/maintenancePage';
import { FeatureFlagState } from '../../store/modules/featureFlags/model';
import { tryGetLivingTokens } from './storage';
import { TokenStorage } from 'api/auth/authModel';

export type AuthProviderProps = {
    loadingComponent: JSX.Element;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, loadingComponent }) => {
    const appLoading = useSelector(selectAppLoading);
    const maintenanceFeature = useSelector(selectFeatureFlags)[FeatureFlags.MaintenanceEnabled] as FeatureFlagState;
    const isInMaintenance = maintenanceFeature?.enabled ?? false;
    const featureLoading = useSelector(selectFeatureFlagsLoading);
    const actions = useActions({
        onAuthSuccess: tokensAuthSuccess,
        onAuthRefreshSuccess: tokensRefreshSuccess,
    });
    const [ tokens, setTokens] = useState<TokenStorage>();
    const [ loadingTokens, loadingSetTokens] = useState<boolean>(false);

    useEffect(()=>{
        loadingSetTokens(true);
        tryGetLivingTokens().then((tokens: TokenStorage) =>{
            if(tokens.accessToken && tokens.refreshToken){
                setTokens({refreshToken: tokens.refreshToken ?? '', accessToken: tokens.accessToken ?? ''})
            }else if(tokens.accessTokenSAdmin && tokens.refreshTokenSAdmin){
                setTokens({refreshToken: tokens.refreshTokenSAdmin ?? '', accessToken: tokens.accessTokenSAdmin ?? ''})
            }
        }).finally(()=>loadingSetTokens(false))
    },[])

    const authEventHandler = useCallback((eventType: AuthClientEvent) => {
        const action = actions.current[eventType as keyof typeof actions.current] || undefined;
        action?.({
            idToken: keycloak.idToken!,
            refreshToken: keycloak.refreshToken!,
            accessToken: keycloak.token!,
        });
    }, [actions]);

    if ((featureLoading && appLoading )|| loadingTokens) {
        return loadingComponent;
    }
    else if (!isInMaintenance) {
        return (
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={
                    {
                        checkLoginIframe: false,
                        onLoad: 'login-required',
                        timeSkew: 10,
                        token: tokens?.accessToken || null,
                        refreshToken: tokens?.refreshToken || null
                    }
                }
                LoadingComponent={loadingComponent}
                onEvent={authEventHandler}
            >
                {appLoading
                    ? loadingComponent
                    : children}
            </ReactKeycloakProvider>
        );
    } else {
        return (
            <MaintenancePage/>
        );
    }
};
