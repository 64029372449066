import { Box } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

export const TagBoxColor = withStyles((theme: Theme) => ({
    root: {
        marginRight: theme.spacing(.5),
        height: 18,
        borderRadius: 3,
        width: 18,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: ({ bgcolor }: {bgcolor: string | null}) => bgcolor || '#f0f1f1',
        cursor: 'pointer',
        "& .MuiSvgIcon-root":{
            color: 'white'
        },
        boxShadow: '0px 4px 8px rgba(58, 58, 68, 0.16)'
    },
}))(Box);
