import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CastOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { SvgIconProps } from '@material-ui/core';

const useStreamIconStyles = makeStyles(() => ({
    root: {
        transform: 'rotate(180deg)',
    },
}));

export function StreamIcon({ className, ...props }: SvgIconProps) {
    const classes = useStreamIconStyles([]);

    return (
        <CastOutlined className={clsx(classes.root, className)} {...props}/>
    );
}
