import { makeStyles } from "@material-ui/core";
import { useField } from "formik";
import { OptionsDropdownCustom } from "modules/shared/components/OptionsDropdownCustom";
import { useCallback } from "react";
import { LabelCreator } from "./LabelCreator";
import { LabelImage } from "./LabelImage";
import { t } from "i18next";

const useStyles = makeStyles({
    select: {
        lineHeight: '24px',
    },
});

export type LabelsDropdownProps = {
    label: string;
    name: string;
    disabled?: boolean;
}

const UNSET_VALUE = 'not-set';

export const LabelsDropdown = ({ label, name, disabled }: LabelsDropdownProps) => {
    const classes = useStyles();
    const [field, , helpers] = useField(name);
    const { value } = field;

    const options = [
        { value: UNSET_VALUE, title: `${t('no label')}`, color: "transparent" },
        { value: "vm", title: `${t('VM')}`, color: "ADE792" },
        { value: "compliance", title: `${t('Compliance')}`, color: "A8D1D1" },
        { value: "inventory", title: `${t('Inventory')}`, color: "9EA1D4" },
        { value: "hr", title: `${t('HR')}`, color: "FD8A8A" },
        { value: "finance", title: `${t('Finance')}`, color: "86C8BC" }
    ];

    const onChange = useCallback((recurrence) => {
        helpers.setValue(recurrence);
    }, [helpers]);

    return (
        <OptionsDropdownCustom
            disabled={disabled}
            selectClasses={{ select: classes.select }}
            unsetValue={UNSET_VALUE}
            options={options}
            value={value}
            onValueChange={onChange}
            RenderPreview={LabelImage}
            RenderCreator={LabelCreator}

        />
    )
};