import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    ThemeProvider,
    Theme,
    useMediaQuery,
    Box, CircularProgress,
} from '@material-ui/core';
import { baseTheme } from 'modules/root/theme';
import { Breakpoints } from 'utils/styles/constants';
import { useFormStyles } from '../../modules/shared/components/formStyles';
import { t } from 'i18next';

export interface IConfirmationDialogProps {
    title?: string;
    agreeButtonText?: string | React.ReactNode;
    discardButtonText?: string | React.ReactNode;
    text: React.ReactNode;
    onAgree: () => void;
    onDiscard: () => void;
    open: boolean;
    theme?: Theme;
    isLoading?: boolean;
}

export function ConfirmationDialog({
    title = `${t('Are you sure?')}`,
    text,
    onAgree,
    onDiscard,
    agreeButtonText = `${t('Yes')}`,
    discardButtonText = `${t('No')}`,
    open,
    theme = baseTheme,
    isLoading = false,
}: IConfirmationDialogProps) {
    const fullscreen = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const formStyles = useFormStyles();
    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                onClose={onDiscard}
                fullScreen={fullscreen}
            >
                <DialogTitle>
                    <Typography variant="h2" component="span">
                        {title}
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    {text}
                </DialogContent>

                <DialogActions>
                    {isLoading && (
                        <Box width="100%" display="flex" overflow="hidden" justifyContent="center">
                            <CircularProgress className={formStyles.createTaskItem} />
                        </Box>
                    )}
                    {!isLoading && (
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Button onClick={onDiscard}>{discardButtonText}</Button>
                        <Button onClick={onAgree}>{agreeButtonText}</Button>
                    </Box>
                    )}
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}
