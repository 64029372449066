export const legacyKeepAliveTimeoutMs = 8 * 60 * 1000;
export const legacyKeepAliveShortTimeoutMs = 2 * 1000;
export const tokensCheckTimeoutMs = 5 * 1000;
export const tokensCheckShortTimeoutMs = 200;

export interface IUser {
    id: string;
    email: string;
    firstName: string;
    name: string;
    avatar: string;
    position: string;
}
