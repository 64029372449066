import { makeStyles, Theme } from '@material-ui/core/styles';
import { Breakpoints } from 'utils/styles/constants';

export const useTopMenuStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: theme.spacing(10 - 4),
        padding: theme.spacing(2, 3),
        backgroundColor: theme.palette.primary.dark,
    },
    companyImageContainer: {
        height: '100%',
        maxWidth: '70%',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            maxWidth: `calc(100% - 192px)`,
        },
    },
    companyImage: {
        maxHeight: '100%',
        maxWidth: '400px',
        cursor: 'pointer',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            objectFit: 'contain',
            width: '100%',
            height: '100%',
        },
    },
    iconColor: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    }
}));
