import { requestChecklistItemFileRemove, requestChecklistItemFileUpload } from 'api/task/storeTask/storeTaskActions';
import { StoreTaskAttachmentModel } from 'api/task/storeTask/storeTaskModel';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { FeatureFlags } from 'utils/featureFlags';
import { useActions } from "utils/store/useActions";
const getFileType = (fileName: string) => {
    const extension = fileName.split('.')?.pop()?.toLowerCase() ?? '';
    if(!fileName || !extension) return '';

    const ImagefileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'tiff', 'ico', 'webp', 'eps', 'psd', 'ai', 'jfif', 'jpe', 'jif', 'jfi', 'apng', 'avif', 'heic', 'heif', 'tga', 'tif', 'exif', 'raw', 'hdr', 'dng', 'cr2', 'nef', 'orf', 'arw', 'rw2'];

        if (ImagefileTypes?.includes(extension)) {
            return `image/${extension}`;
        }
    
    return "";
}


export function useDropHandler(taskId: string, checklistItemId: string) {
    const actions = useActions({ uploadFiles: requestChecklistItemFileUpload.init });
    const chunkUploadFeatureState = useSelector(selectFeatureFlags)[FeatureFlags.FilesChunkUpload];

    return useCallback((acceptedFiles: File[]) => {
        const formatFiles = acceptedFiles.map(f=>(new File([f], f.name,{ type: f.type ? f.type :  getFileType(f.name) })))
        actions.current.uploadFiles({
            taskId,
            checklistItemId,
            files: formatFiles,
            chunkUploadFeatureState,
        });
    }, [actions, taskId, checklistItemId, chunkUploadFeatureState]);
}

export function useRemoveHandler(taskId: string, checklistItemId: string) {
    const actions = useActions({ attachmentRemove: requestChecklistItemFileRemove.init });

    return useCallback((attachment: StoreTaskAttachmentModel) => {
        if (taskId) {
            actions.current.attachmentRemove({
                taskId,
                checklistItemId,
                ...attachment,
            });
        }
    }, [actions, taskId, checklistItemId]);
}
