import PeopleIcon from '@material-ui/icons/People';
import { t } from 'i18next';
import { EmptyState as SharedEmptyState } from 'modules/shared/components/EmptyState';

export const EmptyState = () => {
    return (
        <SharedEmptyState
            text={`${t('No users')}`}
            Icon={PeopleIcon}
        />
    )
};
