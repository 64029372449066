import { Box, IconButton } from '@material-ui/core';
import { SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const DragHandle = SortableHandle((props: {isDisabledDrag?: boolean}) => 
    <IconButton aria-label="item drag & drop"color="primary" size="small" disabled={props.isDisabledDrag}>
        <DragIndicatorIcon fontSize="medium"/>
    </IconButton>);

export const LaunchDragHandle= (props: {isDisabledDrag?: boolean}) => (
    <Box position="absolute" left={0} top={4} zIndex={9}>
        <DragHandle {...props}/>
    </Box>
);
