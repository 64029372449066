import { useSelector } from 'react-redux';
import { selectStoreTasksOrder, selectStoreTasksById } from 'store/modules/task/storeTask/storeTaskSelectors';
import { nonEmpty } from 'utils/helpers/collections';
import { IStoreTaskGridRowData } from './model';

export function useStoreTasksGridData() {
    const storeTasksOrder = useSelector(selectStoreTasksOrder);
    const storeTasksById = useSelector(selectStoreTasksById);

    const data: Array<IStoreTaskGridRowData> = (
        storeTasksOrder
            .map(id => storeTasksById[id])
            .filter(nonEmpty)
            .map(item => ({
                id: item.id,
                title: item.title,
                dueDate: item.dueDate,
                completionState: item.displayedStatus,
                createdBy: item.createdBy,
                approvable: item.isApproval,
                expire: item.isExpirable,
                tag: item.tag,
                prototypeId: item?.prototypeId
            }))
    );

    return data;
}
