import React, { useCallback } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import {
    initialTaskDeclineFromValues,
    taskDeclineValidationSchema,
    TaskDeclineFormValues,
} from './model';
import { PolymorphicButton } from 'components/button/PolymorphicButton';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { Required, useTaskDeclineStyles } from './styles';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { Breakpoints } from 'utils/styles/constants';
import { useActions } from "utils/store/useActions";
import { requestDeclineStoreTask } from 'api/task/storeTask/storeTaskActions';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export type TaskDeclineDialogProps = {
    taskId: string;
    onCancel: () => void;
    show: boolean;
};

export const TaskDeclineDialog: React.FC<TaskDeclineDialogProps> = ({ onCancel, show, taskId }) => {
    const classes = useTaskDeclineStyles();
    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const actions = useActions({ decline: requestDeclineStoreTask.init });
    const handleSubmitTaskDeclining = useCallback(
        (
            { reason }: TaskDeclineFormValues,
            { resetForm }: FormikHelpers<TaskDeclineFormValues>,
        ) => {
            actions.current.decline({ taskId, reason });
            resetForm();
            onCancel();
        },
        [actions, onCancel, taskId],
    );

    return (
        <Dialog
            open={show}
            classes={{ paper: classes.dialogPaper }}
            fullScreen={isMobile}
        >
            <Formik
                initialValues={initialTaskDeclineFromValues}
                validationSchema={taskDeclineValidationSchema}
                onSubmit={handleSubmitTaskDeclining}
            >
                {({ handleSubmit, handleReset }: FormikProps<TaskDeclineFormValues>) => (

                    <Form onSubmit={handleSubmit} onReset={handleReset}>
                        <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
                            <Trans>You are declining this task</Trans> <Required>*<Trans>required</Trans></Required>
                        </DialogTitle>
                        <DialogContent
                            classes={{
                                root: classes.dialogContentRoot,
                            }}
                        >
                            <TextInput
                                name="reason"
                                id="reason"
                                label={t('Let the store team know what changes are required for the task to be approved')}
                                separateLabel={false}
                                classes={{ inputRoot: classes.reasonInput }}
                                multiline
                            />
                        </DialogContent>
                        <DialogActions
                            classes={{
                                root: classes.dialogActionsRoot,
                                spacing: classes.dialogActionsSpacing,
                            }}
                        >
                            <PolymorphicButton
                                rounded
                                bodyColor="red"
                                type="reset"
                                onClick={onCancel}
                            >
                                <Trans>Cancel</Trans>
                            </PolymorphicButton>
                            <PolymorphicButton
                                rounded
                                type="submit"
                            >
                                <Trans>Submit</Trans>
                            </PolymorphicButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};
