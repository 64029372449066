import { makeStyles, Paper, TableContainer, Theme, useMediaQuery } from "@material-ui/core";
import { useMemo } from "react";
import { TableInstance } from "react-table";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useFixedSizeListWidth, usePaddingScrollFix, useScrollHandler } from "../hooks";
import { asBlock, GRID_ROW_HEIGHT, makeRowRenderer, toHeaderRow } from "./gridHelpers";
import { StyledTable, StyledTableBody, StyledTableBodyMobile, StyledTableHead } from "./StyledTable";
import { Breakpoints } from '../../../utils/styles/constants';

export type VirtualScrollGridProps<T extends {} = any> = {
    dataGrid: TableInstance<T>;
    height: number | string;
    showEmptyState: boolean;
    emptyState: React.ReactNode;
    nextPageToken: string | null;
    preload: (...args: any[]) => any;
    customRowRenderer: (dataGrid: TableInstance<T>) => ({index, style}: ListChildComponentProps<any>) => JSX.Element
}

const useScrollStyles = makeStyles((theme: Theme) => ({
    dynamicTable: {
        maxHeight: 'calc(100vh - 290px)',
        minHeight: 'calc(100vh - 290px)',
    },
}));

export const VirtualScrollGrid = ({
        dataGrid,
        height,
        showEmptyState,
        emptyState,
        nextPageToken,
        preload,
        customRowRenderer = makeRowRenderer,
    }: VirtualScrollGridProps) => {
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const classes = useScrollStyles([]);
    const tableProps = { ...asBlock, ...dataGrid.getTableProps() };
    const tableBodyProps = dataGrid.getTableBodyProps();
    const renderRow = useMemo(() => customRowRenderer(dataGrid), [customRowRenderer, dataGrid]);
    const { scrollContainerRef, listContainerRef, handleScroll } = useScrollHandler(nextPageToken, preload);
    const tableHeadScrollFix = usePaddingScrollFix(scrollContainerRef.current);
    const {innerWidth} = window;
    const { containerRef, width } = useFixedSizeListWidth(innerWidth);

    return (
        <TableContainer ref={containerRef} component={Paper}>
            <StyledTable {...tableProps}>
                <StyledTableHead {...asBlock} {...tableHeadScrollFix} width={width}>
                    {dataGrid.headerGroups.map(toHeaderRow)}
                </StyledTableHead>
                {!isMobile && (
                    <StyledTableBody {...asBlock} {...tableBodyProps} className={classes.dynamicTable}>
                        {showEmptyState && emptyState}
                        {!showEmptyState && (
                            <FixedSizeList
                                layout="vertical"
                                height={height}
                                itemCount={dataGrid.rows.length}
                                itemSize={GRID_ROW_HEIGHT}
                                width={width}
                                outerRef={scrollContainerRef}
                                innerRef={listContainerRef}
                                onScroll={handleScroll}
                                itemKey={(index: number) => dataGrid.rows[index]?.id ?? index}
                                className={classes.dynamicTable}
                            >
                                {renderRow}
                            </FixedSizeList>
                        )}
                    </StyledTableBody>
                )}
                {isMobile && (
                    <StyledTableBodyMobile {...asBlock} {...tableBodyProps}>
                        {showEmptyState && emptyState}
                        {!showEmptyState && (
                            <FixedSizeList
                                layout="vertical"
                                height={height}
                                itemCount={dataGrid.rows.length}
                                itemSize={GRID_ROW_HEIGHT}
                                width={width}
                                outerRef={scrollContainerRef}
                                innerRef={listContainerRef}
                                onScroll={handleScroll}
                                itemKey={(index: number) => dataGrid.rows[index]?.id ?? index}
                            >
                                {renderRow}
                            </FixedSizeList>
                        )}
                    </StyledTableBodyMobile>
                )}
            </StyledTable>
        </TableContainer>
    );
};
