import React from 'react';
import { browserHistory, Routes } from 'components/router/model';
import { CreateButton } from 'modules/shared/components/CreateButton';
import { Trans } from 'react-i18next';


export type CreateTaskButtonProps = {
    className?: string;
    showChildren?: boolean;
};

export function CreateTaskButton({ className, showChildren }: CreateTaskButtonProps) {
    return (
        <CreateButton
            className={className}
            onClick={() => browserHistory.push(Routes.AddTask)}
            showChildren={showChildren}
        >
            <Trans>Create new task</Trans>
        </CreateButton>
    );
}
