import { alpha, makeStyles, Theme } from '@material-ui/core/styles';
import { Breakpoints, GrayColor } from 'utils/styles/constants';

export const useFormStyles = makeStyles((theme: Theme) => ({
    formInputs: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        justifyContent: 'space-between',
        rowGap: theme.spacing(4),
        columnGap: theme.spacing(2.5),
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            gridRowGap: theme.spacing(2.5),
        },
    },
    titleInput: {
        gridColumn: '1/3',
        alignItems: 'stretch',
    },
    dateWrapper: {
        gridColumnStart: 1,
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            gridColumn: '1/3',
            alignItems: 'stretch',
        },
    },
    recurrenceWrapper: {
        gridColumnStart: 2,
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            gridColumn: '1/3',
            alignItems: 'stretch',
        },
    },
    descriptionInput: {
        gridColumn: '1/3',
        alignItems: 'stretch',
    },
    checklistWrapper: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gridColumn: '1/3',
    },
    userAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    userPosition: {
        color: GrayColor.Gray60,
    },
    createTaskItem: {
        float: 'right',
    },
    createButton: {
        float: 'right',
        marginTop: theme.spacing(3),
        padding: `9.5px ${theme.spacing(3)}px`,
    },
    subtaskItem: {
        width: '100%',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
    },
    listItem: {
        '&&': {
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: 'flex-start',
        },
    },
    listItemRoot: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    listItemSecondaryActions: {
        '&&': {
            position: 'initial',
            transform: 'none',
            top: 0,
        },
    },
    listItemDrag: {
        '&&': {
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: 'center',
        },
    },
    listItemRootDrag: {
        display: 'flex',
        border: `solid 1px ${theme.palette.primary.light}`,
        borderRadius: 8,
        alignItems: 'center',
        paddingRight: 0,
        paddingLeft: 8,
        marginTop: 8,
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
        gap: 8
    },
    listItemSecondaryActionsDrag: {
        alignItems: 'center',
    },
    containerInLineEdit: {
        width: '70%'
    },
    iconMarginLeft: {
        '&&': {
            padding: theme.spacing(0, 0, 0, 1),
        },
    },
    iconMarginRight: {
        '&&': {
            padding: theme.spacing(0, 1, 0, 0),
        },
    },
    listRemoveIcon: {
        padding: 0,
    },
    checklistInput: {
        flexGrow: 1,
    },
    locationTypeSelect: {
        marginBottom: theme.spacing(2),
    },
    assignTasksButton: {
        padding: theme.spacing(1, 2),

        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
    assignTaskLabel: {
        fontWeight: 500,
    },
    filesDivider: {
        backgroundColor: 'rgba(255,255,255,0.35) !important',
    },
    closeButton: {
        position: 'absolute',
        bottom: theme.spacing(2),
        padding: theme.spacing(1, 5),
    },
    multiSelectWrapper: {
        overflow: 'auto',
        marginBottom: theme.spacing(6),
    },
    checkboxActions: {
        display: 'flex',
        alignItems: 'center',
    },
    labelsDropdown: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2)
    },
    tagsBase: {
        position: "absolute",
        top: theme.spacing(5.5),
        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            top: theme.spacing(3.75),
        }
    },
    tags:{
        left: theme.spacing(29),
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            left: theme.spacing(21)
        },
        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            left: theme.spacing(18.25)
        },
    },
    tagsEdit:{
        left: theme.spacing(25),
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            left: theme.spacing(17)
        },
        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            left: theme.spacing(14.75)
        },
    },
    tagsClone:{
        left: theme.spacing(27.5),
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            left: theme.spacing(20)
        },
        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            left: theme.spacing(17.5)
        },
    },
    tagsTemplate:{
        left: theme.spacing(37.5),
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            left: theme.spacing(29.75)
        },
        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            left: theme.spacing(27)
        },
    },
    customChecklistOptions:{
        minWidth: theme.spacing(14),
        [theme.breakpoints.down(Breakpoints.MobileAdvanced)]:{
            minWidth: "auto"
        }
    },
    containerTemplateBulk: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: '0px',
        gridRowGap: '0px',
    },
    template: {
        gridArea: '1 / 1 / 2 / 2',
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            gridArea: '1 / 1 / 2 / 4',
        },
    },
    bulk: { 
        display: 'flex',
        alignItems: 'center',
        marginLeft: 40,
        gridArea: '1 / 2 / 2 / 4',
        [theme.breakpoints.down(Breakpoints.MobileAdvancedX)]: {
            marginLeft: 0,
            gridArea: '2 / 1 / 3 / 4',
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    }
}));
