import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { requestDeleteChannel } from 'api/getStream/getStreamActions';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { selectIsChannelDeleting } from 'store/modules/getStream/getStreamSelectors';
import { useActions } from "utils/store/useActions";
import { browserHistory, Routes } from 'components/router/model';
import { generatePath } from 'react-router-dom';
import i18n from 'i18n';

export function useDeleteConversation(conversationId?: string) {
    const isChannelDeleting = useSelector(selectIsChannelDeleting);
    const userCanDelete = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));
    const actions = useActions({ delete: requestDeleteChannel.init });

    const deleteConversation = useMemo(() => {
        if (!userCanDelete) {
            return null;
        }
        return () => {
            const isConfirmed = window.confirm(`${i18n.t('Are you sure you want to delete the conversation?')}`);
            if (isConfirmed && conversationId) {
                browserHistory.push(generatePath(Routes.Connect));
                actions.current.delete(conversationId);
            }
        };
    }, [actions, conversationId, userCanDelete]);

    return {
        deleteConversation,
        isChannelDeleting,
    };
}
