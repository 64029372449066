/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import { Paper } from '@material-ui/core';
import { FeedItem } from '@knocklabs/client';
import { KnockFeedContainer } from '@knocklabs/react-notification-feed';
import { useSnackbar } from 'notistack';
import { useNavigationHandler } from './hooks';
import { CloseButton } from './CloseButton';
import { NotificationCustomCell } from './NotificationCustomCell';

export type NotificationToastProps = {
    feedItem: FeedItem;
}

export const NotificationToast = React.forwardRef<HTMLDivElement, NotificationToastProps>(({ feedItem }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const onCloseClick = useCallback(
        () => closeSnackbar(),
        [closeSnackbar],
    );
    const onItemClick = useNavigationHandler(onCloseClick);

    return (
        <Paper ref={ref}>
            <KnockFeedContainer>
                <NotificationCustomCell
                    item={feedItem}
                    onItemClick={onItemClick}
                    archiveButton={
                        <CloseButton item={feedItem} onClick={onCloseClick}/>
                    }
                />
            </KnockFeedContainer>
        </Paper>
    );
});
