import { KNOCK_USER_AVATAR, knockUserAvatar, requestKnockUserToken } from 'api/knock/knockActions';
import jwtDecode from 'jwt-decode';
import {  takeLatest } from 'typed-redux-saga';
import { delay, put, select } from 'redux-saga/effects';
import { getSecondsToExpiration } from 'utils/helpers/dateHelper';
import { selectKnockUserToken } from './knockSelectors';
import { tokensCheckTimeoutMs } from 'store/modules/auth/authModel';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { selectUserById, selectUsersLoadingById } from '../user/userSelectors';
import { ItemsById } from 'model/entity';

const tokensCheckTimeoutSeconds = tokensCheckTimeoutMs / 1000;

function* tokenExpirationWatcher() {
    while (true) {
        const knockUserToken: ReturnType<typeof selectKnockUserToken> = yield select(selectKnockUserToken);

        if (knockUserToken) {
            const tokenPayload = jwtDecode<{ exp: number }>(knockUserToken);
            const seconds = getSecondsToExpiration(tokenPayload.exp);

            if (seconds !== null && seconds <= tokensCheckTimeoutSeconds) {
                yield put(requestKnockUserToken.init());
            }
        }

        yield delay(tokensCheckTimeoutMs);
    }
}
function* userAvatarProfileWatcher() {
    yield* takeLatest(
        KNOCK_USER_AVATAR,
        function* ({ payload }: ReturnType<typeof knockUserAvatar>) {
            const userId = payload;
            const user = yield select(selectUserById(userId));
            const loadingUserIds:ItemsById<boolean> = yield select(selectUsersLoadingById);
            if (!loadingUserIds[userId] && !user) {
                yield put(requestMultipleUsersInfo.init([userId]));
            }
        },
    );
}

export const knockSagas = [
    tokenExpirationWatcher,
    userAvatarProfileWatcher
];
