import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { baseTheme } from './theme';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { store } from 'store/configureStore';
import { Content } from './Content';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Router } from 'react-router';
import { browserHistory } from 'components/router/model';
import { SplashPage } from 'components/mainContent/SplashPage';
import { SnackbarCloseButton, ToastNotifier } from 'modules/toastMessages/ToastNotifier';
import { ConnectProvider } from 'modules/connect/context/ConnectContext';
import { LocationWatcher } from 'components/router/LocationWatcher';
import { IdleReminder } from './IdleReminder';
import { TasksToReviewPoller } from 'modules/task/taskReview/TasksToReviewPoller';
import { NotificationsProvider } from 'modules/notifications/NotificationsProvider';
import { NotificationsToaster } from 'modules/notifications/NotificationsToaster';
import { AuthProvider } from 'modules/auth/AuthProvider';
import { ThemeProviderCustom } from './ThemeProviderCustom';
import { ChatbotWrapper } from 'modules/demo-ai-chat-widget/ChatBotWidget';

export default function App() {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={baseTheme}>
                <Provider store={store}>
                    <AuthProvider loadingComponent={<SplashPage />}>
                        <NotificationsProvider>
                            <SnackbarProvider maxSnack={3} action={SnackbarCloseButton}>
                                <ConnectProvider>
                                    <Router history={browserHistory}>
                                        <ThemeProviderCustom>
                                            <Content />
                                            <ChatbotWrapper />
                                        </ThemeProviderCustom>
                                        <LocationWatcher />
                                        <IdleReminder />
                                        <TasksToReviewPoller />
                                    </Router>
                                </ConnectProvider>
                                <ToastNotifier />
                                <NotificationsToaster />
                            </SnackbarProvider>
                        </NotificationsProvider>
                    </AuthProvider>
                </Provider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}
