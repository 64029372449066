import { combineReducers } from 'redux';
import { auth } from 'store/modules/auth/authReducer';
import { knock } from 'store/modules/knock/knockReducer';
import { hierarchy } from 'store/modules/hierarchy/hierarchyReducer';
import { task } from 'store/modules/task/taskReducer';
import { apps } from 'store/modules/apps/appsReducer';
import { files } from 'store/modules/files/filesReducer';
import { appState } from 'store/modules/appState/appStateReducer';
import { user } from 'store/modules/user/userReducer';
import { company } from 'store/modules/company/companyReducers';
import { getStream } from 'store/modules/getStream/getStreamReducer';
import { location } from 'store/modules/location/locationReducer';
import { store } from 'store/modules/store/storeReducer';
import { featureFlags } from './modules/featureFlags/reducer';
import { launch } from 'store/modules/launch/launchReducer';
import { integration } from './modules/integration/integrationReducer';

export const rootReducer = combineReducers({
    featureFlags,
    apps,
    auth,
    knock,
    user,
    company,
    files,
    hierarchy,
    task,
    appState,
    getStream,
    location,
    store,
    launch,
    integration,
});

export type IStoreState = ReturnType<typeof rootReducer>;
export type ActionType = Parameters<typeof rootReducer>[1];
