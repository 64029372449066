import React, { ReactNode } from 'react';
import { Button, ButtonProps, Typography } from '@material-ui/core';
import { useButtonStyles } from 'utils/styles/buttons';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { Storefront as StorefrontIcon } from '@material-ui/icons';

export type LocationsButtonProps = {
    text: string | ReactNode;
    onClick: ButtonProps['onClick'];
}

export function LocationsButton({ text, onClick }: LocationsButtonProps) {
    const classes = useFormStyles([]);
    const buttonClasses = useButtonStyles([]);

    return (
        <Button
            onClick={onClick}
            className={buttonClasses.muiButton}
        >
            <StorefrontIcon />
            <Typography className={classes.assignTaskLabel}>
                {text}
            </Typography>
        </Button>
    );
}
