import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUsersByIds } from 'store/modules/user/userSelectors';
import { getUserName } from 'utils/helpers/user';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import logoPR from 'images/Logo/LogoPR.png';
import { USER_AUTOMATION } from 'modules/task/taskList/components/AutomationTaskGrid/utils/automationUser';
import { UserAvatarSkeleton } from './UserAvatarSkeleton';
import { AVATAR_SIZE, StyledAvatar, StyledBox } from './styles';
import { Fade, Tooltip } from '@material-ui/core';

export type UserAvatarInfoProps = {
    className?: string;
    userId: string;
    avatarSize?: number;
}

export const UserAvatarInfo: React.FC<UserAvatarInfoProps> = ({ className, userId, avatarSize = AVATAR_SIZE }) =>{
    const user = useSelector(selectUsersByIds)[userId];
    const fileStorageConfig = useSelector(getFilesConfig);
    const avatarUrl = useMemo(() => {
        return userId === USER_AUTOMATION ? logoPR : fileStorageConfig && user?.avatarId
            ? `${fileStorageConfig.avatarsBaseUrl}/${user.avatarId}`
            : '';
    }, [fileStorageConfig, user, userId]);

    if (!user) {
        return <UserAvatarSkeleton className={className} />;
    }

    return (
        <Tooltip title={getUserName(user)} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <StyledBox>
                <StyledAvatar
                    src={avatarUrl}
                    alt="avatar"
                    style={{
                        backgroundColor: 'white',
                        color:'darkblue',
                        width: avatarSize,
                        height: avatarSize
                    }}
                />
            </StyledBox>
        </Tooltip>
    );
}
