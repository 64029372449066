import { FC } from "react";
import { CalendarStoreFilter } from "./filters/CalendarStoreFilter";
import { CalendarParentFilter } from "./filters/CalendarParentFilter";
import { ParentTasksFilter } from "./filters/ParentTasksFilter";
import { StoreTasksFilter } from "./filters/StoreTasksFilter";
import { LocationTreeFilter } from "./filters/LocationTreeFilter";
import { ScheduledTasksFilter } from "./filters/ScheduledTasksFilter";

export enum TasksFilterType {
    ParentTasks = 'PARENT_TASKS',
    StoreTasks = 'STORE_TASKS',
    CalendarStoreTasks = 'CALENDAR_STORE_TASKS',
    CalendarParentTasks = 'CALENDAR_PARENT_TASKS',
    LocationTree = 'LOCATION_TREE',
    ScheduledTasks = 'SCHEDULED_TASKS'
};
export type TasksFilterComponentProps = {
    locationId: string;
    children?: React.ReactNode;
}
export type TasksFilterComponent = FC<TasksFilterComponentProps>;
export const TasksFilterComponents: Record<TasksFilterType, TasksFilterComponent> = {
    [TasksFilterType.ParentTasks]: ParentTasksFilter,
    [TasksFilterType.StoreTasks]: StoreTasksFilter,
    [TasksFilterType.CalendarStoreTasks]: CalendarStoreFilter,
    [TasksFilterType.CalendarParentTasks]: CalendarParentFilter,
    [TasksFilterType.LocationTree]: LocationTreeFilter,
    [TasksFilterType.ScheduledTasks]: ScheduledTasksFilter,
};
