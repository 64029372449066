import { Box } from '@material-ui/core'
import { t } from 'i18next';
import { ImportButton } from 'modules/shared/components/ImportButton';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { BukTasksPanel } from './BulkTasksPanel';
import { useSelector } from 'react-redux';
import { selectOpenSideBarTasksBulk } from 'store/modules/task/TasksBulk/tasksBulkSelectors';
import { useActions } from 'utils/store/useActions';
import { openSideOutTasksBulk } from 'store/modules/task/TasksBulk/tasksBulkActions';
import { useCallback } from 'react';

export const BulkTasks = () => {
  const isTasksBulkImportEnabled = useFeatureState(FeatureFlags.TasksBulkImport);
  const classes = useFormStyles();
  const isOpenSideBarTasksBulk = useSelector(selectOpenSideBarTasksBulk);
  const actions = useActions({
      openSideOut: openSideOutTasksBulk,
  });

  const handleOnCloseSideOut = useCallback((onOpen: boolean) => actions.current.openSideOut(onOpen), [actions])

  if(!isTasksBulkImportEnabled) return <></>;

  return (
    <Box className={classes.bulk}>
      <ImportButton 
        children={t('Bulk Actions')}
        onClick={()=>handleOnCloseSideOut(true)}
        showMobileVersion={false}
        sizeButton='medium'
      />
      <BukTasksPanel isOpened={isOpenSideBarTasksBulk} onClose={()=>handleOnCloseSideOut(false)}/>
    </Box>
  )
}
