import { delay, put, select, takeLatest } from 'typed-redux-saga';
import {
  LAUNCH_DELETE,
  LAUNCH_MODAL,
  LAUNCH_PAGE,
  launchModal,
  launchPage,
  requestAddLaunch,
  requestAddLaunchCover,
  requestDeleteLaunch,
  requestDeleteLaunchCover,
  requestGetLaunch,
  requestUpdateLaunch
} from 'api/launch/launchActions';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from '../featureFlags/model';
import { selectFeatureFlags } from '../featureFlags/selectors';
import { FeatureFlags } from 'utils/featureFlags';
import { Routes, browserHistory } from 'components/router/model';
import { selectLaunchLoading, selectLaunchModalDelete, selectLaunchs } from './launchSelectors';
import { selectUser } from '../auth/authSelectors';
import { UserDTO } from 'api/user/userModel';
import { Launchs, ModalDeleteLaunch } from 'api/launch/launchModel';
import { LAUNCH_COVER_ADD, launchCoverAddImage } from './launchActions';

function* requestLaunchWatcher() {
  yield* takeLatest(
    LAUNCH_PAGE,
    function* ({ payload }: ReturnType<typeof launchPage>) {
      if (!payload) {
        return;
      }
      const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
      const isLaunchPageEnabled = featureFlags[FeatureFlags.LaunchPage]?.enabled;
      if (!isLaunchPageEnabled) {
        browserHistory.push(Routes.Learn);
        return;
      }
      const isLoading = yield select(selectLaunchLoading);
      if (!isLoading) {
        yield* delay(500);
        yield put(requestGetLaunch.init(payload))
      }
    });
}

function* requestLaunchDeleteWatcher() {
  yield* takeLatest(
    LAUNCH_DELETE,
    function* () {
      const currentUser: UserDTO | null | undefined = yield* select(selectUser);
      const launchToDelete: ModalDeleteLaunch = yield* select(selectLaunchModalDelete);

      if (currentUser && launchToDelete.launch) {
        if (!launchToDelete.launch.isLaunchCover) {
          yield put(requestDeleteLaunch.init({ companyId: currentUser.companyId, launchId: launchToDelete.launch.id }))
        }else{
          yield put(requestDeleteLaunchCover.init(currentUser.companyId))
        }
      }
    });
}

function* requestLaunchAddWatcher() {
  yield* takeLatest(
    LAUNCH_MODAL,
    function* ({ payload }: ReturnType<typeof launchModal>) {
      const currentUser: UserDTO | null | undefined = yield* select(selectUser);
      if (!currentUser) { return }
      const featureFlags: ItemsById<FeatureFlagState> = yield* select(selectFeatureFlags);
      const isChunkUploadEnabled = featureFlags[FeatureFlags.FilesChunkUpload]?.enabled;

      if (!payload.launch.id) {
        const launchs: Launchs = yield* select(selectLaunchs)
        yield put(requestAddLaunch.init({ companyId: currentUser.companyId, launch: { ...payload.launch, index: launchs.length + 1 }, isChunkUploadEnabled, file: payload.file }))
        return
      }
      yield put(requestUpdateLaunch.init({ companyId: currentUser.companyId, launch: payload.launch, isChunkUploadEnabled, file: payload.file }))
    });
}

function* requestLaunchCoverAddWatcher() {
  yield* takeLatest(
    LAUNCH_COVER_ADD,
    function* ({ payload }: ReturnType<typeof launchCoverAddImage>) {
      const currentUser: UserDTO | null | undefined = yield* select(selectUser);
      if (!currentUser) { return }
      const featureFlags: ItemsById<FeatureFlagState> = yield* select(selectFeatureFlags);
      const isChunkUploadEnabled = featureFlags[FeatureFlags.FilesChunkUpload]?.enabled;

      yield put(requestAddLaunchCover.init({ companyId: currentUser.companyId, isChunkUploadEnabled, file: payload }))
    });
}

export const launchSagas = [
  requestLaunchWatcher,
  requestLaunchDeleteWatcher,
  requestLaunchAddWatcher,
  requestLaunchCoverAddWatcher,
];
