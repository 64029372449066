import { combineReducers } from "redux";
import { EditTaskCreatorApiActions, requestRegularTaskUpdateCreator, requestUsersCanReassignRegularTask, requestUsersCanReassignScheduledTask } from "api/task/editTaskCreator/editTaskCreatorActions";
import { EDIT_TASK_CREATOR_EDITION, EditTaskCreatorActions, TASK_CREATOR, TASK_CREATOR_TASK_TYPE } from "./editTaskCreatorActions";

const initialState = {
    userCanReassignIds: [],
    isEdition: false,
    isFetching: false,
    currentTaskCreator: '',
    taskCreatorTaskType: ''
};

function userCanReassignIds(
    state: string[] = initialState.userCanReassignIds,
    action: EditTaskCreatorApiActions,
): string[] {
    switch (action.type) {
        case requestUsersCanReassignRegularTask.successType:
        case requestUsersCanReassignScheduledTask.successType:
            return action.payload;
        case requestUsersCanReassignRegularTask.initType:
        case requestUsersCanReassignRegularTask.errorType:
        case requestUsersCanReassignScheduledTask.initType:
        case requestUsersCanReassignScheduledTask.errorType:
            return [];
        default:
            return state;
    }
}

function isEdition(
    state: boolean = initialState.isEdition,
    action: EditTaskCreatorActions,
): boolean {
    if( action.type === EDIT_TASK_CREATOR_EDITION){
        return action.payload;
    }
    return state;
}

function currentTaskCreator(
    state: string | undefined = initialState.currentTaskCreator,
    action: EditTaskCreatorActions,
): string | undefined {
    if( action.type === TASK_CREATOR){
        return action.payload;
    }
    return state;
}

function isFetching(
    state: boolean = initialState.isFetching,
    action: EditTaskCreatorApiActions,
): boolean {
    switch (action.type) {
        case requestRegularTaskUpdateCreator.successType:
        case requestRegularTaskUpdateCreator.errorType:
            return false;
        case requestRegularTaskUpdateCreator.initType:
            return true;
        default:
            return state;
    }
}

function taskCreatorTaskType(
    state: string  = initialState.currentTaskCreator,
    action: EditTaskCreatorActions,
): string {
    if( action.type === TASK_CREATOR_TASK_TYPE){
        return action.payload;
    }
    return state;
}

export const editTaskCreatorReducer = combineReducers({
    userCanReassignIds,
    isEdition,
    isFetching,
    currentTaskCreator,
    taskCreatorTaskType
});
