import { delay, put, select, takeLatest } from 'typed-redux-saga';
import {
    FilesActions,
    requestUploadFile,
    requestDownloadFile,
    requestFileStorageToken,
    requestUsersBulkUpload, UsersBulkUploadActions,
    requestDownloadTasksBulk,
} from 'api/files/filesActions';
import { addUploadedConversationFiles, addUploadedFiles, resetPendingConversationFiles, resetPendingFiles } from './filesActions';
import fileDownload from 'js-file-download';
import { selectFileStorageTokenExpiration } from './filesSelectors';
import { getSecondsToExpiration } from 'utils/helpers/dateHelper';
import { tokensCheckTimeoutMs } from '../auth/authModel';
import { openUserImportModal } from '../user/userActions';
import { showToastMessage } from '../appState/appStateActions';
import { ToastVariant } from '../appState/appStateModel';
import { TypeUploadHandler } from './filesHooks';

const tokensCheckTimeoutSeconds = tokensCheckTimeoutMs / 1000;

function* fileUploadSuccessSaga() {
    yield takeLatest(
        requestUploadFile.successType,
        function* (action: ReturnType<typeof requestUploadFile.success>) {
            if(action.payload.typeUploadHandler === TypeUploadHandler.TaskConversation){
                yield put(addUploadedConversationFiles(action.payload.uploadedFiles));
                yield put(resetPendingConversationFiles());
                return;
            }
            yield put(addUploadedFiles(action.payload.uploadedFiles));
            yield put(resetPendingFiles());
        },
    );
}

function fileDownloadSuccessSagaHandler(action: FilesActions) {
    if(action.type === requestDownloadFile.successType || action.type === requestDownloadTasksBulk.successType) {
        const { fileName, content } = action.payload;
        fileDownload(content, fileName);
    }
}

function* fileDownloadSuccesWatcherSaga() {
    yield takeLatest(
        requestDownloadFile.successType,
        fileDownloadSuccessSagaHandler,
    );
}

function* fileDownloadTaskBulkSuccesWatcherSaga() {
    yield takeLatest(
        requestDownloadTasksBulk.successType,
        fileDownloadSuccessSagaHandler,
    );
}
function* fileStorageTokenExpirationWatcher() {
    while (true) {
        const expiration: number | null = yield select(selectFileStorageTokenExpiration);

        if (expiration) {
            const seconds = getSecondsToExpiration(expiration);

            if (seconds !== null && seconds <= tokensCheckTimeoutSeconds) {
                yield put(requestFileStorageToken.init());
            }
        }

        yield delay(tokensCheckTimeoutMs);
    }
}

function* userBulkUploadFileWatcher() {
    yield takeLatest(
        requestUsersBulkUpload.successType,
        function* (action: UsersBulkUploadActions) {
            const message = 'User import process is running, you will be notified when the import is completed';
            yield put(openUserImportModal(false));
            yield put(showToastMessage({
                message,
                options: {
                    variant: ToastVariant.Success
                },
            }));
        },
    );
}

export const filesSagas = [
    fileUploadSuccessSaga,
    fileDownloadSuccesWatcherSaga,
    fileStorageTokenExpirationWatcher,
    userBulkUploadFileWatcher,
    fileDownloadTaskBulkSuccesWatcherSaga
];
