import { CloudUpload } from "@material-ui/icons";

export const CustomToolbar = () => (
    <div id="toolbar">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-list" value="ordered"/>
      <button className="ql-list" value="bullet"/>
      <select className="ql-align" />
      <select className="ql-color" />
      <button className="ql-link" />
      <button className="ql-uploadFile" > <CloudUpload color="primary"/></button>
    </div>
);