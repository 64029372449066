import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { AVATAR_SIZE, StyledBox, StyledInfoWrapper } from "./styles";

export type UserInfoSkeletonProps = {
    className?: string;
    showInformation?: boolean;
};

export const UserInfoSkeleton = ({ className, showInformation = true }: UserInfoSkeletonProps) => {
    return (
        <StyledBox className={className}>
            <Skeleton
                variant="circle"
                width={AVATAR_SIZE}
                height={AVATAR_SIZE}
            />
            {showInformation &&
                <StyledInfoWrapper>
                    <Skeleton width="95px" />
                    <Skeleton width="50px" />
                </StyledInfoWrapper>
            }
        </StyledBox>
    );
};
