import React from 'react';
import { BlueColor } from 'utils/styles/constants';
import {
    lighten,
    makeStyles,
    Box,
    LinearProgress,
    LinearProgressProps,
    Typography,
} from '@material-ui/core';

export type LinearProgressWithLabelProps = LinearProgressProps & {
    value: number;
};

const useBarStyles = makeStyles(() => ({
    barColorPrimary: {
        backgroundColor: BlueColor.BluePrimaryMain,
    },
    barColorSecondary: {
        backgroundColor: lighten(BlueColor.BluePrimaryMain, .6),
    },
}));

export const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = ({ value, ...rest }) => {
    const barClasses = useBarStyles();
    const finalValue = Math.round(Math.max(Math.min(value || 0, 100), 0));
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress
                    classes={{ ...barClasses }}
                    variant="determinate"
                    value={finalValue}
                    {...rest}
                />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${finalValue}%`}</Typography>
            </Box>
        </Box>
    );
};
