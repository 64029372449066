import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { UserDTO } from 'api/user/userModel';
import { useSelector } from 'react-redux';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { Skeleton } from './withSkeleton';
import { UserInfoSkeleton } from 'components/userInfo/UserInfoSkeleton';
import { UserInfoWithImage } from 'components/userInfo/UserInfoWithImage';
import { getUserName } from 'utils/helpers/user';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { generatePath, useHistory } from 'react-router';
import { BoxProps } from '@material-ui/core';
import { Routes } from 'components/router/model';

export const UserInfoCell: React.FC<CellProps<Skeleton<UserDTO>, string>> = ({ row: { original: user } }) => {
    const newProfileEnabled = useFeatureState(FeatureFlags.Admin2_UserProfile);
    const history = useHistory();

    const fileStorageConfig = useSelector(getFilesConfig);
    const avatarUrl = useMemo(() => {
        return fileStorageConfig && user?.avatarId
            ? `${fileStorageConfig.avatarsBaseUrl}/${user.avatarId}`
            : '';
    }, [fileStorageConfig, user]);

    const props = useMemo(() => {
        const boxProps: BoxProps = {};

        if (newProfileEnabled && !user.isSkeleton) {
            boxProps.style = { cursor: 'pointer' };
            boxProps.onClick = () => history.push(generatePath(Routes.EditUser, { userId: user.id! }))
        }

        return boxProps;
    }, [user, newProfileEnabled, history]);

    if (user.isSkeleton) {
        return <UserInfoSkeleton />;
    }

    return (
        <UserInfoWithImage
            avatarUrl={avatarUrl}
            title={getUserName(user as UserDTO)}
            subtitle={user.email!}
            firstName={user.firstName!}
            lastName={user.lastName!}
            {...props}
        />
    );
};
