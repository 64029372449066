import React from 'react';
import { CellProps } from 'react-table';
import { Skeleton as SkeletonComponent, SkeletonProps } from '@material-ui/lab';

export type Skeleton<T> = Partial<T> & {
    isSkeleton?: boolean;
}

export function withSkeleton<T extends CellProps<Skeleton<unknown>>>(
    WrappedComponent: React.ComponentType<T>,
    skeletonProps?: SkeletonProps,
) {
    return (props: T) => {
        if (props.row.original.isSkeleton) {
            return <SkeletonComponent {...skeletonProps} />;
        }

        return (
            <WrappedComponent {...props} />
        );
    };
}
