import { STORE_TASK_MODAL_SET_TASK_ID, StoreTaskActions } from 'store/modules/task/storeTask/storeTaskActions';
import { combineReducers } from 'redux';
import {
    CHECKLIST_ITEM_FILE_NOT_FOUND,
    RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE,
    requestApproveStoreTask,
    requestChecklistItemFileRemove, requestChecklistItemFileUpload,
    requestChecklistItemSetCompleted,
    requestDeclineStoreTask,
    requestIsApprovableByUser,
    requestStoreTaskGet,
    requestSubmitStoreTask,
    StoreTaskApiActions,
    requestStoreTaskGetParentIds,
    requestChecklistItemCustomResponseUpdate,
    requestChecklistItemCustomResponseDelete,
} from 'api/task/storeTask/storeTaskActions';
import { removeTaskChecklistFile } from 'store/modules/task/taskCommon/taskCommonHelpers';
import { ItemsById } from 'model/entity';
import { requestStoreTasks, TaskActions } from 'api/task/taskActions';
import { insertSavingOrder } from 'utils/helpers/collections';
import { TaskListComponentActions, TASK_LIST_RESET_DATA } from '../taskListComponent/taskListComponentActions';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { TaskDetailsActions } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { upsertVersionedStateItem } from './storeTaskHelpers';
import { StoreTaskAttachmentModel, StoreTaskFullModel, StoreTaskShort } from 'api/task/storeTask/storeTaskModel';
import { ParentTaskActions, requestDeleteParentTask } from 'api/task/parentTask/parentTaskActions';
import { IStoreTaskSetActiveId } from './storeTaskModel';

const initialState = {
    storeTaskModalActiveId: null,
    checklistItemsLoading: {},

    updatingStoreTasks: [],
    storeTasksFetching: false,
    storeTasksNextPageToken: null,

    /**
     * This store property is inconsistent as we don't know what type actually sits in there.
     * TODO: To avoid cases when we need to get detail but get short model heavy thoughtfull refactoring is required!
     * NOTE: Appeared to be an issue for concurrent requests when detail request ends
     * while short model requests are in progress
     */
    storeTasksById: {},

    storeTasksOrder: [],
    storeTaskAttachmentsRemoving: {},
    storeTaskAttachmentsUploading: {},
    storeTaskCustomResponseFetchingById: {},

    approvableByUserStoreTasks: {},

    // This is to only contain full store task model.
    storeTaskDetailsById: {},
    storeTaskParentIdsById: {},
    storeTaskParentIdsLoadingById: {},
};

export function storeTaskModalActiveId(
    state: IStoreTaskSetActiveId | null = initialState.storeTaskModalActiveId, action: StoreTaskActions,
): IStoreTaskSetActiveId | null {
    switch (action.type) {
        case STORE_TASK_MODAL_SET_TASK_ID:
            return action.payload;
        default:
            return state;
    }
}

export function checklistItemsLoading(
    state: Record<string, boolean> = initialState.checklistItemsLoading, action: StoreTaskApiActions,
): Record<string, boolean> {
    switch (action.type) {
        case requestChecklistItemSetCompleted.initType:
            return {
                ...state,
                [action.payload.checklistItemId]: true,
            };
        case RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE:
        case requestChecklistItemSetCompleted.errorType:
        case requestChecklistItemSetCompleted.successType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload.checklistItemId]: id, ...rest } = state;
            return rest;
        }
        default: return state;
    }
}

function storeTasksFetching(
    state: boolean = initialState.storeTasksFetching,
    action: TaskActions,
): boolean {
    if (action.type === requestStoreTasks.initType) {
        return true;
    } else if (action.type === requestStoreTasks.successType || action.type === requestStoreTasks.errorType) {
        return false;
    }

    return state;
}

function storeTasksNextPageToken(
    state: string | null = initialState.storeTasksNextPageToken,
    action: TaskActions,
): string | null {
    if (action.type === requestStoreTasks.successType) {
        return action.payload.nextPageToken;
    }
    return state;
}

function storeTasksById(
    state: ItemsById<StoreTaskShort | StoreTaskFullModel> = initialState.storeTasksById,
    action: TaskActions | TaskListComponentActions | TaskDetailsActions | StoreTaskApiActions,
): ItemsById<StoreTaskShort | StoreTaskFullModel> {
    switch (action.type) {
        case requestStoreTasks.successType:
            return { ...state, ...itemsByIds(action.payload.tasks) };
        case TASK_LIST_RESET_DATA:
            return initialState.storeTasksById;

        case requestChecklistItemSetCompleted.successType:
        case requestChecklistItemFileUpload.successType:
            return upsertVersionedStateItem(state, action.payload.task);

        case requestStoreTaskGet.successType:
        case requestChecklistItemFileRemove.successType:
        case requestSubmitStoreTask.successType:
        case requestApproveStoreTask.successType:
        case requestDeclineStoreTask.successType:
            return upsertVersionedStateItem(state, action.payload);
        case CHECKLIST_ITEM_FILE_NOT_FOUND:
            return removeTaskChecklistFile(state, action.payload);
        default:
            return state;
    }
}

function storeTaskDetailsById(
    state: ItemsById<StoreTaskFullModel> = initialState.storeTaskDetailsById,
    action: TaskActions | TaskListComponentActions | TaskDetailsActions | StoreTaskApiActions,
): ItemsById<StoreTaskFullModel> {
    switch (action.type) {
        case TASK_LIST_RESET_DATA:
            return initialState.storeTaskDetailsById;

        case requestChecklistItemSetCompleted.successType:
        case requestChecklistItemFileUpload.successType:
            return upsertVersionedStateItem(state, action.payload.task);

        case requestStoreTaskGet.successType:
        case requestChecklistItemFileRemove.successType:
        case requestSubmitStoreTask.successType:
        case requestApproveStoreTask.successType:
        case requestDeclineStoreTask.successType:
            return upsertVersionedStateItem(state, action.payload);
        case CHECKLIST_ITEM_FILE_NOT_FOUND:
            return removeTaskChecklistFile(state, action.payload);
        case requestChecklistItemCustomResponseUpdate.successType:
            return { ...state, [action.payload.taskId]:action.payload.task };
        case requestChecklistItemCustomResponseDelete.successType:
            const task = {...state[action.payload.taskId]} as StoreTaskFullModel;
            const listItems = task?.checklistItems?.map((item: any)=>(item.id === action.payload.checklistItemId ? {...item, customResponse: ''}: item) )
            const newTask = {
                ...task,
                checklistItems: listItems
            } as StoreTaskFullModel
            return { ...state, [action.payload.taskId]: newTask};
        default:
            return state;
    }
}

function storeTasksOrder(
    state: Array<string> = initialState.storeTasksOrder,
    action: TaskActions | TaskListComponentActions | ParentTaskActions,
): Array<string> {
    switch (action.type) {
        case requestStoreTasks.successType:
            return insertSavingOrder(state, action.payload.tasks.map((item: { id: any; }) => item.id));
        case TASK_LIST_RESET_DATA:
            return initialState.storeTasksOrder;
        case requestDeleteParentTask.successType:
            return action.payload?.isFromStoreTask 
                ? state.filter(id => id !== action.payload.id)
                : state;
        default:
            return state;
    }
}

function storeTaskAttachmentsRemoving(
    state: Record<string, boolean> = initialState.storeTaskAttachmentsRemoving,
    action: StoreTaskApiActions,
): Record<string, boolean> {
    switch (action.type) {
        case requestChecklistItemFileRemove.initType:
            return {
                ...state,
                [action.payload.fileId]: true,
            };
        case requestChecklistItemFileRemove.errorType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload.fileId]: removedRecord, ...updatedState } = state;
            return updatedState;
        }
        default:
            return state;
    }
}

function storeTaskAttachmentsUploading(
    state: Record<string, Array<StoreTaskAttachmentModel>> = initialState.storeTaskAttachmentsUploading,
    action: StoreTaskApiActions,
): Record<string, Array<StoreTaskAttachmentModel>> {
    switch (action.type) {
        case requestChecklistItemFileUpload.initType:
            return {
                ...state,
                [action.payload.checklistItemId]: [
                    ...(state[action.payload.checklistItemId] || []),
                    ...action.payload.files.map(file => ({
                        fileId: file.name,
                        name: file.name,
                        hasThumbnail: false,
                    })),
                ],
            };

        case requestChecklistItemFileUpload.successType:
        case requestChecklistItemFileUpload.errorType:
            return {
                ...state,
                [action.payload.checklistItemId]: (state[action.payload.checklistItemId] || []).filter(
                    file => !action.payload.fileNames.includes(file.fileId),
                ),
            };
        default:
            return state;
    }
}

function updatingStoreTasks(
    state: Array<string> = initialState.updatingStoreTasks,
    action: StoreTaskApiActions,
): Array<string> {
    switch (action.type) {
        case requestSubmitStoreTask.initType:
        case requestApproveStoreTask.initType:
        case requestDeclineStoreTask.initType:
            return [...state, typeof action.payload === 'string' ? action.payload : action.payload.taskId];
        case requestSubmitStoreTask.successType:
        case requestSubmitStoreTask.errorType:
        case requestApproveStoreTask.successType:
        case requestApproveStoreTask.errorType:
        case requestDeclineStoreTask.successType:
        case requestDeclineStoreTask.errorType:
        case requestStoreTaskGet.successType:
            return state.filter(id => id !== action.payload.id);
        default: return state;
    }
}

function approvableByUserStoreTasks(
    state: Record<string, boolean> = initialState.approvableByUserStoreTasks,
    action: StoreTaskApiActions,
): Record<string, boolean> {
    switch (action.type) {
        case requestIsApprovableByUser.successType:
            return { ...state, [action.payload.id]: action.payload.userCanApprove };
        case requestIsApprovableByUser.errorType:
            return { ...state, [action.payload.id]: false };
        default: return state;
    }
}

function storeTaskParentIdsById(
    state: Record<string, string[] | undefined> = initialState.storeTaskParentIdsById,
    action: StoreTaskApiActions,
): Record<string, string[] | undefined> {
    switch (action.type) {
        case requestStoreTaskGetParentIds.successType:
            return { ...state, [action.payload.id]: action.payload.parentIds };
        default:
            return state;
    }
}

function storeTaskParentIdsLoadingById(
    state: Record<string, boolean | undefined> = initialState.storeTaskParentIdsLoadingById,
    action: StoreTaskApiActions,
): Record<string, boolean | undefined> {
    switch (action.type) {
        case requestStoreTaskGetParentIds.initType:
            return { ...state, [action.payload]: true };
        case requestStoreTaskGetParentIds.successType:
        case requestStoreTaskGetParentIds.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function storeTaskCustomResponseFetchingById(
    state: Record<string, boolean | undefined> = initialState.storeTaskCustomResponseFetchingById,
    action: StoreTaskApiActions,
): Record<string, boolean | undefined> {
    switch (action.type) {
        case requestChecklistItemCustomResponseUpdate.initType:
        case requestChecklistItemCustomResponseDelete.initType:
            return { ...state, [action.payload.checklistItemId]: true };
        case requestChecklistItemCustomResponseUpdate.successType:
        case requestChecklistItemCustomResponseUpdate.errorType:
        case requestChecklistItemCustomResponseDelete.successType:
        case requestChecklistItemCustomResponseDelete.errorType:
            return { ...state, [action.payload.checklistItemId]: false };
        default:
            return state;
    }
}

export const storeTaskReducer = combineReducers({
    storeTaskModalActiveId,
    checklistItemsLoading,
    storeTasksFetching,
    storeTasksNextPageToken,
    storeTasksById,
    storeTasksOrder,
    storeTaskAttachmentsRemoving,
    storeTaskAttachmentsUploading,
    updatingStoreTasks,
    approvableByUserStoreTasks,
    storeTaskDetailsById,
    storeTaskParentIdsById,
    storeTaskParentIdsLoadingById,
    storeTaskCustomResponseFetchingById
});
