import intersection from 'lodash/intersection';

export function insertSavingOrder<T>(
    initial: Array<T>,
    newItems: Array<T>,
): Array<T> {
    if (initial.length === 0) {
        return [...newItems];
    }
    const commonItems = intersection(initial, newItems);
    if (commonItems.length === 0) {
        return [...initial, ...newItems];
    }
    if (commonItems.length === newItems.length) {
        return [...initial];
    }

    const result: Array<T> = [];
    const initialMutable = [...initial];
    const newMutable = [...newItems];
    for (let i = 0; i < commonItems.length; i++) {
        const commonItem = commonItems[i];
        while (initialMutable.length && initialMutable[0] !== commonItem) {
            result.push(initialMutable.shift() as T);
        }
        initialMutable.shift();
        while (newMutable.length && newMutable[0] !== commonItem) {
            result.push(newMutable.shift() as T);
        }
        newMutable.shift();
        result.push(commonItem);
    }

    return [...result, ...initialMutable, ...newMutable];
}

export function nonEmpty<TItem>(item: TItem | null | undefined): item is TItem {
    return item !== null && item !== undefined;
}
