import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { PropsWithChildren, useMemo } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

export function Scrollbar({ children, ...rest }: PropsWithChildren<ScrollBarProps>) {
    const options: ScrollBarProps['options'] = useMemo(() => ({
        swipeEasing: true,
        ...(rest.options ?? {}),
    }), [rest.options]);

    return (
        <PerfectScrollbar options={options} {...rest}>
            { children }
        </PerfectScrollbar>
    );
}
