import React from 'react';
import { Box, makeStyles, styled, Theme, Typography } from '@material-ui/core';
import { Breakpoints, UtilColors } from 'utils/styles/constants';

export const StyledFormPageContainer = styled(Box)(({ theme }) => ({
    width: '95%',
    margin: '0 auto',
    maxWidth: theme.spacing(103),
    paddingTop: theme.spacing(3),

    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        paddingTop: theme.spacing(1.5),
    },
}));

export const StyledFormPageChildrenWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    boxSizing: 'border-box',
    borderRadius: theme.spacing(2.5),
    padding: theme.spacing(4.75, 12),
    marginBottom: theme.spacing(9),
    backgroundColor: UtilColors.White,

    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        padding: theme.spacing(4.75, 4),
    },

    [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
        padding: theme.spacing(3, 1.5),
    },
}));

const useStyles = makeStyles((theme: Theme) => ({
    topMostEnvelopeWrapper: {
        paddingTop: 0,
        width: '100%',
    },
    actionBarWrapper: {
        paddingBottom: theme.spacing(3),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            paddingBottom: theme.spacing(1.5),
        },
    },
    pageTitle: {
        marginBottom: theme.spacing(3),
    },
}));

export type FormPageViewProps = {
    title?: string | React.ReactNode;
    subtitle?: React.ReactNode;
    actionBar?: React.ReactNode;
    actionbarTopmost?: boolean;
}

export const FormPageView: React.FC<FormPageViewProps> = ({
    actionBar,
    title,
    subtitle,
    children,
    actionbarTopmost= false,
}) => {
    const classes = useStyles();
    if (!actionbarTopmost) {
        return (
            <StyledFormPageContainer>
                {actionBar && (
                    <Box className={classes.actionBarWrapper}>
                        {actionBar}
                    </Box>
                )}
                <StyledFormPageChildrenWrapper>
                    { title && 
                        (<Typography
                            variant="h3"
                            className={classes.pageTitle}
                        >
                            {title}
                            {subtitle}
                        </Typography>)
                    }
                    <Box>
                        {children}
                    </Box>
                </StyledFormPageChildrenWrapper>
            </StyledFormPageContainer>
        );
    } else {
        return (
            <Box className={classes.topMostEnvelopeWrapper}>
                {actionBar && (
                    <Box className={classes.actionBarWrapper}>
                        {actionBar}
                    </Box>
                )}
                <StyledFormPageContainer>
                    <StyledFormPageChildrenWrapper>
                        <Typography
                            variant="h3"
                            className={classes.pageTitle}
                        >
                            {title}
                            {subtitle}
                        </Typography>
                        <Box>
                            {children}
                        </Box>
                    </StyledFormPageChildrenWrapper>
                </StyledFormPageContainer>
            </Box>
        );
    }
};
