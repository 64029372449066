import React from 'react';
import clsx from 'clsx';
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';
import { Counter } from '../../../Counter';
import { HideConversationButton } from '../conversationsPreviewButtons/HideConversationButton';

const useStyles = makeStyles(() => ({
    chatPreviewRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '9px 40px',
        boxSizing: 'border-box',
        backgroundColor: UtilColors.Transparent,
        transition: 'background-color 0.3s',
    },
    chatSelected: {
        backgroundColor: BlueColor.Pale,
    },
    innerGroup: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    title: {
        marginLeft: '3px',
        width: '135px',
        lineHeight: '17px',
        fontWeight: 500,
        color: GrayColor.GrayBlue,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    titleUnread: {
        fontWeight: 700,
        color: GrayColor.GrayBlack,
    },
    avatar: {
        width: '30px',
        height: '30px',
    },
    avatarInner: {
        backgroundColor: BlueColor.Dark,
        color:'white',
        width: '30px',
        textAlign: 'center',
        lineHeight: '30px',
    },
}));

export interface IDirectMessagePreviewProps {
    cid: string,
    image: string,
    title: string,
    isOnline: boolean,
    unreadCount: number,
    isSelected: boolean,
    onSelect: () => void,
    userId: string,
}

export function DirectMessagePreview({
    image,
    title,
    // isOnline,
    unreadCount,
    isSelected,
    onSelect,
    userId,
}: IDirectMessagePreviewProps) {
    const classes = useStyles();
    const wrapperClass = clsx(classes.chatPreviewRoot, {
        [classes.chatSelected]: isSelected,
    });
    const titleClass = clsx(classes.title, {
        [classes.titleUnread]: unreadCount > 0,
    });
    return (
        <Box
            className={wrapperClass}
            onClick={onSelect}
            title={title}
        >
            <Box className={classes.innerGroup}>
                <Avatar
                    className={classes.avatar}
                    variant="circular"
                    alt={title}
                    src={image}
                >
                    <div className={classes.avatarInner}>
                        {title?.substring(0, 1)?.toUpperCase()}{title?.split(/\s+/)[1]?.substring(0, 1)?.toUpperCase()}
                    </div>
                </Avatar>
                <Typography className={titleClass} variant="body2">
                    {title}
                </Typography>
                {isSelected && (
                    <HideConversationButton />
                )}
            </Box>
            <Counter count={unreadCount} />
        </Box>
    );
}
