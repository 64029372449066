import { lighten, makeStyles, Theme } from '@material-ui/core';
import { Breakpoints, GreenColor, orangeColor, UtilColors } from 'utils/styles/constants';

export const useTaskHeaderDetailsStyles = makeStyles((theme: Theme) => ({
    detailsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        color: UtilColors.TextSecondary,
    },
    dateDetails: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        display: 'flex',

        '& > * + *': {
            marginLeft: theme.spacing(0.5),
        },

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '14px',
            lineHeight: '21px',

            '& b': {
                fontWeight: 700,
            },
        },
    },
    detailsCompleted: {
        color: GreenColor.Green2,
    },
    detailsOverdue: {
        color: UtilColors.Red2,
    },
    detailsExpired: {
        color: orangeColor,
    },
    detailsCompleteSubmitedOverdue: {
        color: orangeColor,
    },
    detailsDefault: {
        color: lighten(UtilColors.Black, 0.5),
    },
    approvalMessage: {
        display: 'flex',
        alignItems: 'center',

        '& > * + *': {
            marginLeft: theme.spacing(0.5),
        },
    },
}));
