import React, { useMemo, useCallback } from 'react';
import { Avatar, Box, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { IChannelFile } from './model';

export const ChannelFile = ({ file, downloadFile } : { file: IChannelFile, downloadFile: (url: string, fileName: string) => Promise<void> }) => {
    const classes = useStyles();
    const primary = useMemo(()=>(
        <Box display="flex">
            <Box mr={1}>
                <Avatar src={file.thumb_url ?? file.file_url} variant="rounded"/>
            </Box>
            <Box display="flex" alignItems="center">
                <Typography className={classes.primaryText} variant="body2">{file.name ?? 'Unknown'}</Typography>
            </Box>
        </Box>
    ),[classes, file]);

    const handleDownloadFile = useCallback( async () => {
        await downloadFile(file.file_url ?? '', file.name ?? '')
    }, [file, downloadFile]);

    return (
        <ListItem button onClick={handleDownloadFile}>
            <ListItemText
                primary={primary}
            />
        </ListItem>
    );
}
