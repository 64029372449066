import React from 'react';
import {
    ListItem,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { ChannelRoleMemberActions } from './ChannelRoleMemberActions';

const useStyles = makeStyles((theme: Theme) => ({
    primaryText: {
        marginRight: theme.spacing(2),
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "85%",
        overflow: "hidden",
    },
}));

export interface IChannelRoleMemberProps {
    id: string;
    name: string;
}

export function ChannelRoleMember({
        id,
        name,
    }: IChannelRoleMemberProps) {
    const classes = useStyles();
    const primary = <Typography className={classes.primaryText}>{name}</Typography>;

    return (
        <ListItem>
            <ListItemText
                primary={primary}
            />

            <ChannelRoleMemberActions roleId={id} name={name}/>
        </ListItem>
    );
}
