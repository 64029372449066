import React, { useRef } from 'react';
import {
    Avatar,
    Box,
    Fade,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    Tooltip,
} from '@material-ui/core';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { BlueColor, UtilColors } from 'utils/styles/constants';
import { ArrowDropDown } from '@material-ui/icons';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { UserMenuDropdown } from '../UserMenuDropdown';
import { getUserName } from 'utils/helpers/user';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        boxSizing: 'border-box',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    avatar: {
        height: theme.spacing(6.25),
        width: theme.spacing(6.25),
        border: `1px solid ${UtilColors.White}`,
        boxSizing: 'border-box',
    },
    avatarInner: {
        backgroundColor: 'white',
        color:BlueColor.Dark,
        width: theme.spacing(6.25),
        textAlign: 'center',
        lineHeight: theme.spacing(6.25),
    },
    typography: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    chevron: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}));

export function LeftMenuUser({ showTooltip = false }: {showTooltip?: boolean}) {
    const classes = useStyles();
    const user = useCurrentUser();
    const { isOpened, onOpen, onClose } = useOpenedState();
    const ref = useRef<any>();

    if (!user) {
        return null;
    }

    return (
        <>
            <Box className={classes.root}>
                <ListItem button onClick={onOpen}>
                    <ListItemAvatar>
                        <Tooltip title={showTooltip ? getUserName(user) : ''} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                            <Avatar
                                className={classes.avatar}
                                src={user?.avatar}
                                alt="avatar"
                            >
                                    <div className={classes.avatarInner}>
                                        {user?.firstName?.substring(0, 1)?.toUpperCase()}{user?.lastName?.substring(0, 1)?.toUpperCase()}
                                    </div>
                            </Avatar>
                        </Tooltip>
                    </ListItemAvatar>
                    <ListItemText
                        primary={getUserName(user)}
                        primaryTypographyProps={{
                            className: classes.typography,
                        }}
                        secondary={user?.employeeTitle}
                        secondaryTypographyProps={{
                            className: classes.typography,
                        }}
                    />
                    <ArrowDropDown
                        ref={ref}
                        className={classes.chevron}
                    />
                </ListItem>
            </Box>

            <UserMenuDropdown
                isOpened={isOpened}
                onClose={onClose}
                anchorRef={ref.current}
            />
        </>
    );
}
