import React from 'react';
import { SettingsGridAction } from './reducer';

export type SettingsGridContextValue = {
    dispatch: React.Dispatch<SettingsGridAction>;
    disabled?: boolean;
};

export const SettingsGridContext = React.createContext<SettingsGridContextValue | null>(null);
export const SettingsGridProvider = SettingsGridContext.Provider;
