import { makeStyles, Theme } from '@material-ui/core';

export const thumbnailHeight = 130;
export const thumbnaiCommentslHeight = 100;
const thubmnailBorderColor = 'rgba(0, 0, 0, 0.5)';

export const useStyles = makeStyles((theme: Theme) => ({
    thumbnailPlaceholder: {
        display: 'flex',
        width: thumbnailHeight,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.grey[800],
    },
    image: {
        height: '100%',
        maxWidth: '100%',
    },
    imageBar: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bottom: 0,
        left: 0,
        right: 0,
        background: thubmnailBorderColor,
    },
    actionBar: {
        position: 'absolute',
        top: 0,
        right: 0,
        background: thubmnailBorderColor,
    },
    thumbnail: {
        position: 'relative',
        height: ({fallbackImageHeigth}: {fallbackImageHeigth: number}) => fallbackImageHeigth,
        color: theme.palette.primary.contrastText,
        border: `1px solid ${thubmnailBorderColor}`,
        cursor: 'pointer',
    },
    title: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: `0 ${theme.spacing(1)}px`,
    },
}));
