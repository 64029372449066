import { Box, createStyles, LinearProgress, Theme, Typography, withStyles } from '@material-ui/core';
import type { TasksStatistics } from 'api/task/statistics/statisticsModel';

const StyledLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: theme.spacing(1.5),
            borderRadius: theme.spacing(0.75),
        },
        colorPrimary: {
            backgroundColor: '#758E69',
        },
        bar: {
            borderRadius: theme.spacing(0.75),
            backgroundColor: '#9DBD8E',
        },
    }),
)(LinearProgress);

export type TaskCompletionProps = {
    stats: TasksStatistics;
};

export const TaskCompletion = ({ stats }: TaskCompletionProps) => {
    const { total, completed } = stats;
    const progress = completed / total * 100;

    return (
        <Box>
            <Typography>
                <span>{`${completed} of ${total}`} </span>
            </Typography>
            <StyledLinearProgress variant="determinate" value={progress} />
        </Box>
    );
};

export type TaskCompletionSeparateArgsProps = {
    total: number;
    completed: number;
}

export const TaskCompletionPercentage = ({total, completed}: TaskCompletionSeparateArgsProps) => {
    const progress = Math.trunc((total === 0 || completed === 0) ? 0 : completed / total * 100);
    return (
        <Box>
            <Typography>
                {progress}%
            </Typography>
        </Box>
    );
}
