import { combineReducers } from "redux";
import { TaskTemplateType } from "api/task/taskTemplate/taskTemplateModel";
import { TASK_TEMPLATE_GET_RESET, TaskTemplateApiActions, requestCreateTaskTemplate, requestDeleteTaskTemplate, requestGetTaskTemplate, requestTaskTemplates, requestUpdateTaskTemplate } from "api/task/taskTemplate/taskTemplateActions";
import { taskTemplateToCreatTask } from "modules/task/addTask/model";
import { CHECK_LOCATIONS_TEMPLATE, SHOW_MODAL_EDIT_TEMPLATE, TaskTemplateActions } from "./taskTemplateActions";
import { ModalEditionTemplate } from "./taskTemplateModel";

const initialState = {
    taskTemplates: [],
    taskTemplateFetching: false,
    taskTemplateUpdating: false,
    taskTemplate: {},
    modalEditionTemplate: {isOpened: false, taskTemplate: null}
};

function taskTemplates(
    state: TaskTemplateType[] = initialState.taskTemplates,
    action: TaskTemplateApiActions
): Array<TaskTemplateType> {
    switch (action.type) {
        case requestTaskTemplates.successType:
            return action.payload;
        case requestDeleteTaskTemplate.successType:
            const filterTemplate = state.filter(
                (template) => action.payload.id !== template.id
            );
            return filterTemplate;
        case requestDeleteTaskTemplate.errorType:
            return state;
        default:
            return state;
    }
}

function taskTemplateFetching(
    state = initialState.taskTemplateFetching,
    action: TaskTemplateApiActions
): boolean {
    switch (action.type) {
        case requestDeleteTaskTemplate.initType:
        case requestTaskTemplates.initType:
        case requestGetTaskTemplate.initType:
        case requestUpdateTaskTemplate.initType:
        case requestCreateTaskTemplate.initType:
            return true;
        case requestDeleteTaskTemplate.successType:
        case requestDeleteTaskTemplate.errorType:
        case requestTaskTemplates.successType:
        case requestTaskTemplates.errorType:
        case requestGetTaskTemplate.successType:
        case requestGetTaskTemplate.errorType:
        case requestUpdateTaskTemplate.successType:
        case requestUpdateTaskTemplate.errorType:
        case requestCreateTaskTemplate.successType:
        case requestCreateTaskTemplate.errorType:
            return false;
        default:
            return state;
    }
}

function taskTemplate(
    state = initialState.taskTemplate,
    action: TaskTemplateApiActions | TaskTemplateActions
): TaskTemplateType | {} {
    switch (action.type) {
        case CHECK_LOCATIONS_TEMPLATE:{
            const adapter = taskTemplateToCreatTask(action.payload);
            return adapter;
        }
        case requestGetTaskTemplate.errorType:
            return state;
        case TASK_TEMPLATE_GET_RESET:
            return {}
        default:
            return state;
    }
}

export function modalEditionTemplate(
    state: ModalEditionTemplate = initialState.modalEditionTemplate, action: TaskTemplateActions,
): ModalEditionTemplate {
    if (action.type === SHOW_MODAL_EDIT_TEMPLATE){
        return action.payload;
    }
    return state;
}

export const taskTemplateReducer = combineReducers({
    taskTemplates,
    taskTemplateFetching,
    taskTemplate,
    modalEditionTemplate
});
