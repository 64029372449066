import { Box } from '@material-ui/core'
import { CropperModal } from './CropperModal'
import { LaunchCoverImage } from './LaunchCoverImage'

export const LaunchCover = () => {
  return (
    <Box width="100%" height="100%">
      <LaunchCoverImage />
      <CropperModal />
    </Box>
  )
}
