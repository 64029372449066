import * as yup from "yup";

export interface IViewDateRange {
    startDate: string;
    endDate: string;
}

export const viewDateRangeSchema = yup.object({
    startDate: yup.string().nullable().notRequired(),
    endDate: yup.string().nullable().notRequired(),
});
