import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import Picker from '@emoji-mart/react';

import { MessageInputControlButton } from './MessageInputControls';

export type MessageInputEmojiPickerProps = {
    small?: boolean;
    onSelectEmoji: (emoji: string) => void;
    className?: string;
};

export const EmojiPicker: React.FC<MessageInputEmojiPickerProps> = ({ small, onSelectEmoji, className }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);

  useEffect(() => {
    if (!popperElement || !referenceElement) return;

    const handlePointerDown = (e: PointerEvent) => {
      const target = e.target as HTMLElement;

      if (popperElement.contains(target) || referenceElement.contains(target)) return;

      setEmojiPickerIsOpen(false);
    };

    window.addEventListener('pointerdown', handlePointerDown);
    return () => window.removeEventListener('pointerdown', handlePointerDown);
  }, [referenceElement, popperElement]);

  return (
    <>
      {emojiPickerIsOpen && (
        <div
          className='str-chat__message-textarea-emoji-picker-container'
          style={styles.popper}
          {...attributes.popper}
          ref={setPopperElement}
        >
          <Picker
            theme='light'
            data={async () => (await import('@emoji-mart/data')).default}
            onEmojiSelect={(e: { native: string }) => {
                onSelectEmoji(e.native)
            }}
          />
        </div>
      )}
      <div className={className}>
        <MessageInputControlButton
          type='emoji'
          onClick={() => setEmojiPickerIsOpen((open) => !open)}
          ref={setReferenceElement}
        />
      </div>
    </>
  );
};
