import React from 'react';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { getFilesLoadingState, getPendingFilesQuantity } from 'store/modules/files/filesSelectors';
import { useUploadButtonStyles } from './styles';
import { useUploadHandler } from 'store/modules/files/filesHooks';
import { Trans } from 'react-i18next';

export function AttachmentsUploadButton() {
    const isLoading = useSelector(getFilesLoadingState);
    const pendingFilesQuantity = useSelector(getPendingFilesQuantity);
    const classes = useUploadButtonStyles([]);
    const handleUploadFilesClick = useUploadHandler();

    if (isLoading || !pendingFilesQuantity) {
        return null;
    }

    return (
        <Box
            className={classes.uploadSection}
            display="flex"
            justifyContent="flex-end"
        >
            <Button
                variant="outlined"
                startIcon={<PublishOutlinedIcon/>}
                className={classes.uploadButton}
                onClick={handleUploadFilesClick}
            >
                <Trans>Upload</Trans>
            </Button>
        </Box>
    );
}
