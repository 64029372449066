import { IStoreState } from 'store/rootReducer';

export const selectUser = (state: IStoreState) => {
    return state.auth.selfId === null ? null : state.user.usersByIds[state.auth.selfId];
};
export const selectSelfId = (state: IStoreState) => state.auth.selfId;
export const selectAppLoading = (state: IStoreState) => state.auth.loading;
export const selectAppLoadingLoginAs = (state: IStoreState) => state.auth.loadingLoginAs;
export const selectUserToken = (state: IStoreState) => state.auth.userToken;

