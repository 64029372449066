import { ChatContextValue } from 'stream-chat-react';
import React from 'react';
import { Box } from '@material-ui/core';
import { SelectChatMessage } from './SelectChatMessage';

export interface IChatSearchPanelProps {
    chatContext: ChatContextValue;
}

export const SearchChatPanel = ({ chatContext }: IChatSearchPanelProps) => {
    return (
        <Box>
            <SelectChatMessage
                channel={chatContext.channel}
                client={chatContext.client}
            />
        </Box>
    );
};
