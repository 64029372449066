import { requestIncompleteTasksCount, requestIncompleteUserTasksCount, TaskCommonActions } from 'api/task/common/taskCommonActions';
import { combineReducers } from 'redux';

const initialState = {
    incompleteTasksCount: 0,
    incompleteUserTasksCount: 0
};

function incompleteTasksCount(
    state = initialState.incompleteTasksCount,
    action: TaskCommonActions,
): number {
    if (action.type === requestIncompleteTasksCount.successType) {
        return action.payload;
    }
    return state;
}

function incompleteUserTasksCount(
    state = initialState.incompleteUserTasksCount,
    action: TaskCommonActions,
): number {
    if (action.type === requestIncompleteUserTasksCount.successType) {
        return action.payload;
    }
    return state;
}

export const taskCommonReducer = combineReducers({
    incompleteTasksCount,
    incompleteUserTasksCount
});
