export enum ColumnId {
    Id = "id",
    UserInfo = "userInfo",
    Role = "role",
    Location = "location",
    Selector = "selector",
    Actions = "actions"
}

export type UserListFilter = {
    fullName?: string;
    UserState?: string;
    CompanyLocationIds?: Array<string> | null;
    RoleIds?: Array<string> | null;
    CompanyId?: string;
    Force?: boolean;
}
