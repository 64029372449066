import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { CancelButton, DeleteButton, DialogDescription, StyledDialogActions, TitleWrapper } from 'modules/task/taskList/views/ParentTasksView/RemoveParentTaskConfirmation'
import React from 'react'
import { Trans } from 'react-i18next'

type ModalToDeleteProps = {
  title: string | React.ReactNode;
  onCancel: () => void;
  onDelete: () => void;
  isFetching: boolean;
}

export const ModalToDelete: React.FC<ModalToDeleteProps> = ({ title, onCancel, onDelete, isFetching }) => {
  return (
      <Dialog open>
          <DialogTitle disableTypography>
              <TitleWrapper>
                  {title}
              </TitleWrapper>
          </DialogTitle>
          <DialogContent>
              <DialogDescription>
                  <Trans>You will not be able to undo this. Do you want to proceed?</Trans>
              </DialogDescription>
          </DialogContent>
          <StyledDialogActions>
              <CancelButton onClick={onCancel} disabled={isFetching}><Trans>Cancel</Trans></CancelButton>
              <DeleteButton onClick={onDelete} disabled={isFetching}><Trans>Delete</Trans></DeleteButton>
              {isFetching && (
                  <CircularProgress size={'1rem'} />
              )}
          </StyledDialogActions>
      </Dialog>
  );
}
