import { LocationFilter } from 'modules/task/taskList/views/CalendarTasksView/TaskLocation/LocationFilter';
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';

export const CalendarLocationFilter = () => {
  const canAccessTask = useSelector(selectHasAppAccess(AppName.Tasks));

  if(!canAccessTask) return <></>;

  return (
    <LocationFilter />
  )
}
