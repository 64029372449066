import React from 'react';
import { Check, Error as ErrorIcon } from '@material-ui/icons';
import { InputAdornment, SvgIconProps } from '@material-ui/core';

export type InputEndAdornmentProps<T> = {
    value: T;
    hasError?: boolean;
    fontSize?: SvgIconProps['fontSize'];
}

export function InputEndAdornment<ValueType = any>({
    value,
    hasError,
    fontSize,
}: InputEndAdornmentProps<ValueType>): JSX.Element | null {
    if (hasError) {
        return (
            <InputAdornment position="end">
                <ErrorIcon fontSize={fontSize} />
            </InputAdornment>
        );
    }

    if (value) {
        return (
            <InputAdornment position="end">
                <Check fontSize={fontSize} />
            </InputAdornment>
        );
    }

    return null;
}
