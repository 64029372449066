import React from 'react';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { ViewDetailsButton } from './ViewDetailsButton';
import { ChannelDetails } from './ChannelDetails';
import { useChatContext } from 'stream-chat-react';

export function ViewDetailsAction() {
    const { isOpened, onOpen, onClose } = useOpenedState(false);
    const chatContext = useChatContext();
    const channel = chatContext.channel;

    return (
        <>
            <ViewDetailsButton onClick={onOpen}/>

            {
                channel && (
                    <ChannelDetails
                        open={isOpened}
                        onClose={onClose}
                        channel={channel}
                    />
                )
            }
        </>
    );
}
