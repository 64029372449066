export type CookieOptions = Partial<{
    path: string;
    domain: string;
    maxAge: number;
    expires: Date;
    secure: boolean;
    samesite: SameSite;
}>;

export type SameSite = 'lax' | 'strict' | 'none';
export type KnownAttributeValueType = Date | number | string | boolean;

const toAttributeValue = (name: string, value: KnownAttributeValueType) => {
    let attrValue: string | undefined = undefined;

    if (value instanceof Date) {
        attrValue = value.toUTCString();
    } else if (typeof value !== 'boolean') {
        attrValue = value.toString();
    }

    return typeof attrValue !== 'undefined'
        ? `${name}=${attrValue}`
        : name;
};

export const setCookie = (name: string, value: string, options: CookieOptions = {}): void => {
    const cookieValue = `${name}=${encodeURIComponent(value)}`;
    const attributes: string[] = [cookieValue];
    const { maxAge, ...other } = options;

    if (maxAge) {
        attributes.push(toAttributeValue('max-age', maxAge));
    }

    Object.keys(other).forEach(attr => {
        const attrValue = (other as Record<string, KnownAttributeValueType>)[attr];
        attributes.push(toAttributeValue(attr, attrValue));
    });

    document.cookie = attributes.join('; ');
};
