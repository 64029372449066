import { useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { GrayColor } from 'utils/styles/constants';
import { ITaskTag } from './model';
import { TagItem } from './TagItem';
import { NewTagItem } from './NewTagItem';
import { useSelector } from 'react-redux';
import { selectTasksTagsFetching, selectTaskTags, selectTaskTagsUpdating } from 'store/modules/task/taskTag/taskTagSelectors';
import { Trans } from 'react-i18next';

const TitleTagTask = withStyles((theme: Theme) => ({
    root: {
        color: GrayColor.Gray9E,
        textTransform: "uppercase",
        fontSize: theme.typography.body2.fontSize,
        margin: theme.spacing(1, 1, 0, 1)
    },
}))(Typography);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        minWidth: 240,
        maxWidth: 240,
        backgroundColor: theme.palette.background.paper,
    },
    containerList: {
        maxHeight: theme.spacing(40),
        overflowY: 'auto',
        paddingBottom: 0
    },
    btnCleanTag: {
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    }
}));
type TagListProp = {
    tagActive: string | null;
    onTagChange: (tag: ITaskTag) => void;
    onTagClean: () => void;
    valueForm: ITaskTag | null;
}

export const Taglist = ({ tagActive, onTagChange, onTagClean, valueForm }: TagListProp) => {
    const tagList = useSelector(selectTaskTags);
    const tagListFetching = useSelector(selectTasksTagsFetching);
    const isUpdating = useSelector(selectTaskTagsUpdating);
    const listRef = useRef<null | HTMLElement>(null)
    const [tags, setTags] = useState<ITaskTag[]>([]);
    const classes = useStyles();


    const handleTagTaskChange = useCallback(({id, name, color}: ITaskTag) => {
        onTagChange({id, name, color});
    },[onTagChange]);

    const handleCleanSelected = useCallback(() => {
        onTagClean();
        setTags((prevTagTasks: ITaskTag[]) => prevTagTasks.map((tag: ITaskTag) => ({...tag})))
    },[onTagClean]);

    const checkSelectedTag = useCallback((storeTags: ITaskTag[] ) => {
        const { id, name, color } = valueForm || {};
        const findTag = storeTags.find(tag => tag.id === id);

        if (findTag) {
            if(color !== findTag.color || name !== findTag.name){
                handleTagTaskChange(findTag);
            }
        } else {
            handleCleanSelected();
        }
    },[valueForm, handleTagTaskChange, handleCleanSelected]);

    useEffect(()=>{
        if(valueForm){ checkSelectedTag (tagList)}
        setTags(tagList.map(tag=>({...tag, isEditing: false, isDeleting: false})));
    }, [tagList, valueForm, checkSelectedTag])

    const handlerEditTag = useCallback((editTag: ITaskTag)=>{
        setTags((tags: ITaskTag[]) => tags.map((tag: ITaskTag) => ({...tag, isEditing: tag.id === editTag.id})));
    },[setTags]);

    const handlerDeleteTag = useCallback((editTag: ITaskTag)=>{
            setTags((tags: ITaskTag[]) => tags.map((tag: ITaskTag) => ({...tag, isDeleting: tag.id === editTag.id && !tag.isDeleting})));
        },[setTags]);
    
    const handlerCloseTag = useCallback(()=>{
        setTags((tags: ITaskTag[]) => tags.map((tag: ITaskTag) => ({...tag, isEditing: false})));
    },[setTags]);

    const handleNewTag = useCallback(()=>{
        listRef.current?.scrollTo(0, 0);
    },[listRef])

    return (
        <Box className={classes.root}>
            <Box display="flex" justifyContent="space-between">
                <TitleTagTask><Trans>Change Tag</Trans></TitleTagTask>
                { !!tagActive &&
                    <Button onClick={handleCleanSelected} className={classes.btnCleanTag}><Trans>Clear</Trans></Button>
                }
            </Box>
            {tagListFetching ? 
                <Box width="100%" display="flex" overflow="hidden" justifyContent="center" mt={2} mb={2}>
                    <CircularProgress size="24px" />
                </Box>
                : <><List component="nav" aria-label="tag list" className={classes.containerList} ref={listRef}>
                    { tags.map((tagItem: ITaskTag) => 
                        <TagItem key={tagItem.id} {...tagItem} 
                            isSelected={tagActive === tagItem.id}
                            onTagTaskClick={handleTagTaskChange}
                            onEditTag={() => handlerEditTag(tagItem)}
                            onDeleteTag={() => handlerDeleteTag(tagItem)}
                            onCloseTag={handlerCloseTag}
                            isUpdating={isUpdating}
                        /> )}
                    </List>
                    <NewTagItem onNewTag={handleNewTag}/>
                </>
            }
        </Box>
    );
}
