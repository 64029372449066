import {SortEnd, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FieldArray, useField } from 'formik';
import { Launch } from 'api/launch/launchModel';
import { LaunchItem } from '../launchItem/LaunchItem';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { useSelector } from 'react-redux';
import { selectLaunchReorderFetching } from 'store/modules/launch/launchSelectors';

const SortableItem = SortableElement((props: { value: Launch, canCreateDelete: boolean, showDrag: boolean, isDisabledDrag: boolean }) => (
  <LaunchItem {...props}/>
));

const SortableList = SortableContainer(({ items, canCreateDelete }: { items: Launch[], canCreateDelete: boolean }) => {
  const isLaunchPageReorderEnabled = useFeatureState(FeatureFlags.LaunchPageReorder);
  const isLaunchReorderFetching = useSelector(selectLaunchReorderFetching);

  return (
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      {items.map((value: Launch, index: any) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value}
          canCreateDelete={canCreateDelete}
          showDrag={isLaunchPageReorderEnabled}
          isDisabledDrag={isLaunchReorderFetching}
        />
      ))}
    </div>
  );
});

export const DragLaunch = ({ fieldName, canCreateDelete }: {fieldName: string, canCreateDelete: boolean} ) => {
  const [ field ] = useField<Array<Launch>>(fieldName);
  const { value } = field;

  const onSortEnd = (sortEndEvent: SortEnd, move: any, form: any) => {
    move(sortEndEvent.oldIndex, sortEndEvent.newIndex);
  };

  return (
    <FieldArray 
      name={fieldName}
      render={({ move, form }) => (
        <SortableList
          axis="xy"
          useDragHandle
          transitionDuration={100}
          items={value}
          onSortEnd={(sortEndEvent: SortEnd, newIndex) => onSortEnd(sortEndEvent, move, form)}
          canCreateDelete={canCreateDelete}
        />
      )}
    />
  );
}
