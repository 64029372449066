import { StoreTaskApiActions, requestStoreTaskGet } from 'api/task/storeTask/storeTaskActions';
import { requestAddCommentReaction, requestCreateConversation, requestDeleteCommentReaction, requestDeleteTaskComment, requestEditComment, requestSuggestionList, requestTaskConversationsByTask } from 'api/task/taskConversation/taskConversationActions';
import { ItemsById } from 'model/entity';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { selectUserById, selectUsersIds, selectUsersLoadingById } from 'store/modules/user/userSelectors';
import { put, select, takeEvery } from 'typed-redux-saga';
import { FeatureFlags } from 'utils/featureFlags';
import { difference } from 'lodash';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { selectCommentById, selectStoreTaskConversationById, selectSuggestionIds, selectTaskConversationStore, selectTaskPrototypeIdConversation } from './taskConversationSelectors';
import { UserDTO } from 'api/user/userModel';
import { SHOW_TEXT_EDITOR, isActiveTextEditor, resetConversations, setSuggestionsUsersConversation, storeFilterConversation, suggestionsConversation, taskPrototypeIdConversation, updateReactionConversation } from './taskConversationActions';
import { getTaskPrototype } from 'api/task/prototype/prototypeActions';
import { ConversationType, ConversationsStoreTaskType, StoreTaskConversationType } from 'api/task/taskConversation/taskConversationModel';
import { addUploadedConversationFiles, resetPendingConversationFiles, resetUploadedConversationFiles } from 'store/modules/files/filesActions';
import { UploadedFile } from 'store/modules/files/filesModel';

function* getSuggestionsReset() {
  yield* takeEvery([requestStoreTaskGet.initType, getTaskPrototype.initType],
      function* () {
        const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
        const isTaskConversationsEnabled = featureFlags[FeatureFlags.taskConversations]?.enabled;

        if(!isTaskConversationsEnabled) return;
        yield put(isActiveTextEditor(''));
        yield* put(resetConversations());
      },
  );
}

function* getSuggestionsByStoreLocation() {
  yield* takeEvery([ requestStoreTaskGet.successType],
      function* (action: StoreTaskApiActions) {
        const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
        const isTaskConversationsEnabled = featureFlags[FeatureFlags.taskConversations]?.enabled;
        const { locationId, prototypeId } = action.payload;

        if(!isTaskConversationsEnabled || !prototypeId) return;

        yield* put(storeFilterConversation(locationId));
        yield* put(taskPrototypeIdConversation(prototypeId));
        yield* put(requestTaskConversationsByTask.init(prototypeId));
        yield* put(setSuggestionsUsersConversation(true));
        yield* put(requestSuggestionList.init(locationId));
      },
  );
}

function* usersSuggestionWatcher() {
  yield takeEvery(
      requestSuggestionList.successType,
      function* ({ payload }: ReturnType<typeof requestSuggestionList.success>) {
          const userIds = payload;
          const loadedUserIds: any = yield* select(selectUsersIds);
          const loadingUserIds: any = yield* select(selectUsersLoadingById);
          const missingUserIds: any = difference(userIds, loadedUserIds).filter((userId: any) => !loadingUserIds[userId]);

          if (missingUserIds.length > 0) {
              yield* put(requestMultipleUsersInfo.init(missingUserIds));
          }else{
            yield* put(setSuggestionsUsersConversation(false));
          }
      },
  );
}

function* suggestionConversationWatcher() {
  yield takeEvery(
      requestMultipleUsersInfo.successType,
      function* (_: ReturnType<typeof requestMultipleUsersInfo.success>) {
          const suggestionsIds: string[] = yield* select(selectSuggestionIds);

          const suggestionsList: UserDTO[] = []
          for (const suggestionIs of suggestionsIds) {
              const user: UserDTO | undefined = yield* select(selectUserById(suggestionIs));
              if(user){
                suggestionsList.push(user)
              }
          }

          yield* put(suggestionsConversation(suggestionsList));
          yield* put(setSuggestionsUsersConversation(false));
      },
  );
}

function* getConversationWatcher() {
  yield* takeEvery([requestCreateConversation.successType, requestDeleteTaskComment.successType],
      function* ({payload}: ReturnType<typeof requestCreateConversation.success | typeof requestDeleteTaskComment.success>) {
        const taskPrototypeId: string = yield* select(selectTaskPrototypeIdConversation);

        if(taskPrototypeId){
          yield* put(requestTaskConversationsByTask.init(taskPrototypeId));
        }
      },
  );
}

export function* getTaskPrototypeDetailsWatcher() {
  yield takeEvery(
      getTaskPrototype.successType,
      function* ({ payload }: ReturnType<typeof getTaskPrototype.success>) {
          yield* put(taskPrototypeIdConversation(payload.id));
          yield put(requestTaskConversationsByTask.init(payload.id));
      },
  );
}

function* reactionCommentWatcher() {
  yield* takeEvery([requestAddCommentReaction.successType, requestDeleteCommentReaction.successType, requestEditComment.successType],
      function* ({ payload }: ReturnType<typeof requestAddCommentReaction.success | typeof requestDeleteCommentReaction.success>) {
        const locationTaskConversationById: string = yield select(selectTaskConversationStore);
        const storeTaskConversationById: ItemsById<StoreTaskConversationType> = yield select(selectStoreTaskConversationById);
        const comments: ConversationsStoreTaskType | undefined = storeTaskConversationById[locationTaskConversationById]?.comments;
        const locationId: string | undefined = storeTaskConversationById[locationTaskConversationById]?.locationId;

        if(!locationId || !comments) return;

        const findComment = comments.findIndex((c)=>c.id === payload.id);

        if(findComment < 0) return;

        comments[findComment] = payload;
        yield put(updateReactionConversation({locationId, comments: comments }));
        yield put(resetPendingConversationFiles());
        yield put(resetUploadedConversationFiles());
        yield put(isActiveTextEditor(''));
      },
  );
}

export function* editCommentWatcher() {
  yield takeEvery(
      SHOW_TEXT_EDITOR,
      function* ({ payload }: ReturnType<typeof isActiveTextEditor>) {
        const comments: ItemsById<ConversationType> = yield select(selectCommentById);
        const comment = comments[payload];

        if(comment?.attachments){
          const formatAttachments: UploadedFile[] = comment.attachments.map(file =>(
            {
              fileId: file.fileId,
              name: file.name,
              hasThumbnail: file.hasThumbnail,
              size: 10
            }))
          yield* put(addUploadedConversationFiles(formatAttachments))
        }
      },
  );
}

export const taskConversationSagas = [
  getSuggestionsReset,
  getSuggestionsByStoreLocation,
  usersSuggestionWatcher,
  suggestionConversationWatcher,
  getConversationWatcher,
  getTaskPrototypeDetailsWatcher,
  reactionCommentWatcher,
  editCommentWatcher
];
