import React from 'react';
import { CellProps } from 'react-table';
import { styled, Tooltip, Typography } from '@material-ui/core';
import { UserDTO } from 'api/user/userModel';
import { Skeleton, withSkeleton } from './withSkeleton';
import { Trans } from 'react-i18next';

const StyledTypography = styled(Typography)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

export const TextCell: React.FC<CellProps<Skeleton<UserDTO>, string>> = ({ value: text }) => {
    if (!text) {
        return (
            <StyledTypography variant='body2'><Trans>N/A</Trans></StyledTypography>
        );
    }

    return (
        <Tooltip title={text} placement="bottom-start">
            <StyledTypography variant='body2'>{text}</StyledTypography>
        </Tooltip>
    );
};

export const TextCellWithSkeleton = withSkeleton(TextCell, {
    width: 70,
});
