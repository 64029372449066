import { Notification } from 'api/knock/knockModel';

export type NotificationRowData = {
    notificationType: Notification,
    email: boolean;
    inApp: boolean;
    sms: boolean;
    smsOptionAvailable: boolean | undefined;
}

export enum ColumnId {
    NotificationType = 'notification-type',
    Email = 'email',
    InApp = 'in-app',
    SMS = 'sms',
}

export const columnIdToPropMap: Record<ColumnId, keyof NotificationRowData> = {
    [ColumnId.NotificationType]: 'notificationType',
    [ColumnId.InApp]: 'inApp',
    [ColumnId.Email]: 'email',
    [ColumnId.SMS]: 'sms',
};
