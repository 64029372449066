import axios from 'axios';
import { baseCoreApi, baseKeycloakApi } from 'api/baseApi';
import { legacyAppUrl } from 'utils/globals';
import { LocalStorageKey, tryGetLivingToken } from 'modules/auth/storage';

class AuthApi {

    public getAccessToken(): Promise<string | null> {
        return tryGetLivingToken(LocalStorageKey.AccessToken);
    }

    public async legacyLogin(accessToken: string, refreshToken: string, isImpersonated: boolean) {
        await AuthApi.legacyAuthRequest('LoginTokenRest', {
            accessToken, refreshToken, isImpersonated
        });
    }

    public async legacyKeepAlive(accessToken: string, refreshToken: string, isImpersonated: boolean) {
        await AuthApi.legacyAuthRequest('RefreshTokenRest', {
            accessToken, refreshToken, isImpersonated
        });
    }

    public async loginAs(userId: string) {
        const { data: dataCore }: any = await AuthApi.coreAuthRequest('login-as', userId);
        const reponseKeycloak = await AuthApi.keycloakRefreshTokenRequest(new URLSearchParams({
            client_id: 'web-front-react',
            grant_type:'refresh_token',
            refresh_token: dataCore?.refreshToken
        }))
        return reponseKeycloak
    }

    public async legacyLogout() {
        await AuthApi.legacyAuthRequest('LogoutRest');
    }

    private static async legacyAuthRequest(path: string, params: Record<string, string | boolean> = {}) {
        await axios.post(`${legacyAppUrl}/Users/${path}`, params, {
            withCredentials: true,
        });
    }
    private static async coreAuthRequest(path: string, userId: string, params: Record<string, string | boolean> = {}) {
        return await baseCoreApi.post(`/users/${path}/${userId}`, params)
    }
    private static async keycloakRefreshTokenRequest(params: any) {
        return await baseKeycloakApi.post(`/openid-connect/token`, params)
    }
}

export const authApi = new AuthApi();
