import React, {useMemo} from 'react';
import clsx from 'clsx';
import orderBy from 'lodash/orderBy';
import {
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Typography,
} from '@material-ui/core';
import { IPickItemProps } from './types';
import { usePickItemStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectLocationsLoading } from 'store/modules/location/locationSelectors';
import { GreenColor } from 'utils/styles/constants';
import { TooltipTimezone } from './TooltipTimezone';

function renderSubItems(item: IPickItemProps) {
    return (
        <PickItem
            nested
            key={item.id}
            {...item}
        />
    );
}

export function PickItem({
    id,
    title,
    items = [],
    selected,
    setSelected,
    expanded,
    setExpanded,
    storesCount,
    isStore,
    countByMainLocations = new Map(),
    nested = false,
    timeZoneId
}: IPickItemProps) {
    const locationsFetching = useSelector(selectLocationsLoading);
    const classes = usePickItemStyles();
    const chevronClasses = clsx(classes.chevronButton, {
        opened: expanded,
        hidden: items.length === 0,
    });
    const accordionId = `accordion-${id}`;
    const showLoader = locationsFetching && !isStore && !items.length && storesCount > 0;
    const control = (
        <Checkbox
            disabled={!isStore && !storesCount}
            color="primary"
            checked={selected}
            onClickCapture={e => {
                e.preventDefault();
                e.stopPropagation();
                setSelected(id, !selected);
            }}
        />
    );

    const countStoreSelected = useMemo(()=>{
        if(!countByMainLocations.has(id) || countByMainLocations.get(id) === 0) return <></>;
        return <Typography className={classes.title} style={{color: GreenColor.Green2, marginLeft: '4px'}}> ({countByMainLocations.get(id)}) store{(countByMainLocations.get(id) || 0) > 1 ? 's': ''} selected</Typography>
    },[countByMainLocations, id, classes])
    
    const label = (
        <Box display="flex" justifyContent="center" alignItems="center" style={{gap:4}}>
            <Typography className={classes.title}>
                {title}
                {!isStore && (
                    ` (${storesCount} stores)`
                )}
            </Typography>
            <TooltipTimezone isStore={isStore} timeZoneId={timeZoneId} isSelected={selected}/>
            {!isStore && countStoreSelected}
        </Box>
    );

    return (
        <Accordion
            expanded={items.length > 0 ? expanded : false}
            elevation={nested ? 0 : 1}
            classes={{
                root: classes.accordionRoot,
                expanded: classes.accordionRootExpanded,
            }}
        >
            <AccordionSummary
                id={accordionId}
                classes={{
                    content: classes.accordionSummaryContent,
                    expanded: classes.accordionSummaryContentEpanded,
                }}
                onClick={() => setExpanded(id, !expanded)}
            >
                <FormControlLabel
                    control={control}
                    label={label}
                    htmlFor={`#${accordionId}`}
                />

                {showLoader ? (
                    <CircularProgress size="24px"/>
                ) : (
                    <IconButton className={chevronClasses}>
                        <ExpandMoreIcon/>
                    </IconButton>
                )}
            </AccordionSummary>

            {items.length > 0 && (
                <AccordionDetails
                    classes={{
                        root: classes.accordionDetailsRoot,
                    }}
                >
                    <Box className={classes.itemsWrapper}>
                        {orderBy(items, 'title', 'asc').map(renderSubItems)}
                    </Box>
                </AccordionDetails>
            )}
        </Accordion>
    );
}
