import * as yup from "yup";
import i18n from "i18n";
import { validateFileFormat } from "utils/helpers/fileHelpers";

const allowedFormats: string[] = ['jpg', 'png', 'jpeg'];

export const launchSchema = yup.object().shape({
    caption: yup.string().required(`${i18n.t("Name is a required field")}`),
    target: yup
        .string()
        .url(`${i18n.t("The URL provided is not valid")}`)
        .required(`${i18n.t("URL is a required field")}`),
    index: yup.string().notRequired(),
    thumbnail: yup.mixed()
        .notRequired()
        .test("file_format", `${i18n.t('Uploaded file has unsupported format.')}`, 
            value => !value || (value && validateFileFormat(value, allowedFormats))
        ),
    id: yup.string().notRequired(),
});
