import React, { useCallback, useMemo } from 'react';
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { useField } from 'formik';
import { BaseInput } from '../BaseInput';
import { IInputProps, ITextInputClasses } from '../model';
import { InputError } from '../InputError';
import { Autocomplete } from '@material-ui/lab';
import { t } from 'i18next';
import clsx from 'clsx';
import { useSelectStyles } from './selectStyles';

type KeyType = string | number;

type ExtractKeyType<T> = {
    [Prop in keyof T]: T[Prop] extends KeyType ? T[Prop] : never;
}

export type AutocompleteDropdownProps<T> = IInputProps & {
    options: T[];
    keyProp: keyof ExtractKeyType<T>;
    valueProp: keyof ExtractKeyType<T>;
    classes?: ITextInputClasses;
    disabled?: boolean;
    disableClearable?: boolean;
    placeholder?: string;
}

export const useTaskCreatorStyles = makeStyles((theme: Theme) => ({
    test: {paddingTop: '5px!important', paddingBottom: '5px!important'},
}));
export function AutocompleteDropdown<T>({
    name,
    label,
    id = name,
    options,
    classes = {},
    keyProp,
    valueProp,
    disabled,
    disableClearable = false,
    placeholder = ''
}: AutocompleteDropdownProps<T>) {
    const [field, meta] = useField<string | null>(name);
    const { value, onChange } = field;
    const classesAutocomplete = useSelectStyles([]);

    const boundValue = useMemo(() => {
        const option = options.find((option) => option[keyProp] === value as unknown);

        return option || null;
    }, [options, value, keyProp]);

    const onValueChange = useCallback((_: React.ChangeEvent<{}>, selected: T | null) => {
        const newValue = selected
            ? selected[keyProp]
            : null;
        onChange({ target: { name, value: newValue } });
    }, [onChange, name, keyProp]);

    return (
        <BaseInput
            id={id}
            label={label}
            classes={classes}
        >
            <Autocomplete
                id={id}
                value={boundValue}
                onChange={onValueChange}
                options={options}
                getOptionLabel={(option) => option[valueProp] as unknown as string}
                renderInput={(params) => <TextField {...params} placeholder={placeholder} variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    classes: {root: clsx(params.InputProps.className, classesAutocomplete.autoCompleteInputPadding)}
                }}
                />}
                size='small'
                disabled={disabled}
                blurOnSelect="touch"
                noOptionsText={`${t('No options')}`}
                disableClearable={disableClearable}
            />
            {meta.error && (
                <InputError error={meta.error} />
            )}
        </BaseInput>
    );
}
