import React, { useCallback, useEffect, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import {
    Box,
    Button,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Channel } from 'stream-chat';
import { getUserLabel, renderOption, useQueryUsers, User } from 'modules/connect/messenger/hooks/useQueryUsers';
import { useChatContext } from 'stream-chat-react';
import { useActions } from "utils/store/useActions";
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { requestCreateDirectMessageChat } from 'api/getStream/getStreamActions';
import { selectIsDirectMessagingChatCreating } from 'store/modules/getStream/getStreamSelectors';
import { useSelector } from 'react-redux';
import { Sidebar } from 'components/sidebar/Sidebar';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

export interface ICreateDirectMessageDialogProps {
    isOpened: boolean;
    onClose: () => void;
    conversations: Array<Channel>;
}

function getMembersIds(conversation: Channel): Array<string> {
    return Object.keys(
        conversation.state?.members ?? {},
    );
}

export function CreateDirectMessageDialog({
    isOpened,
    onClose,
    conversations = [],
}: ICreateDirectMessageDialogProps) {
    const classes = useStyles();
    const { client } = useChatContext();
    const isChatCreating = useSelector(selectIsDirectMessagingChatCreating);
    const [isLoading, setIsLoading] = useState(false);

    const actions = useActions({
        showMessage: showToastMessage,
        requestCreateDM: requestCreateDirectMessageChat.init,
    });

    const directMessagesUsersIdList = useMemo(
        () => uniq(conversations.flatMap(getMembersIds)),
        [conversations],
    );

    const isOptionDisabled = useCallback(
        (user: User) => directMessagesUsersIdList.some(id => user.id === id),
        [directMessagesUsersIdList],
    );

    const {
        selectedUsers,
        foundUsers,
        renderInput,
        handleSelectorChange,
        setFoundUsers,
        setSelectedUsers,
    } = useQueryUsers(client);

    const handleOkClick = useCallback(() => {
        if (selectedUsers.length === 1) {
            actions.current.requestCreateDM(selectedUsers[0].id);
            onClose();
        }
    }, [actions, onClose, selectedUsers]);

    useEffect(() => {
        setIsLoading(previousState => {
            if (previousState && !isChatCreating) {
                onClose();
                setSelectedUsers([]);
                setFoundUsers([]);
            }
            return isChatCreating;
        });
    }, [isChatCreating, onClose, setFoundUsers, setSelectedUsers]);

    const handleClose = useCallback(() => {
        setFoundUsers([]);
        setSelectedUsers([]);
        onClose();
    }, [onClose, setFoundUsers, setSelectedUsers]);

    return (
        <Sidebar
            open={isOpened}
            onClose={handleClose}
            title={<Trans>Create direct message</Trans>}
        >
            <Box>
                <Autocomplete
                    disabled={isLoading}
                    autoHighlight
                    options={foundUsers}
                    getOptionLabel={getUserLabel}
                    renderOption={renderOption}
                    renderInput={renderInput}
                    onChange={handleSelectorChange}
                    noOptionsText={<Trans>No variants for current input</Trans>}
                    getOptionDisabled={isOptionDisabled}
                    onClose={() => setFoundUsers([])}
                />
            </Box>

            <Box className={classes.buttons}>
                <Button
                    disabled={selectedUsers.length === 0 || isLoading}
                    onClick={handleOkClick}
                >
                    <Trans>OK</Trans>
                </Button>
            </Box>
        </Sidebar>
    );
}
