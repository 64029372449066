import { UserResponse } from "stream-chat";
import { DefaultStreamChatGenerics, renderText, RenderTextOptions } from "stream-chat-react";

export const RenderText = <
    StreamChatGenerics extends DefaultStreamChatGenerics = any
>(
    text?: string,
    mentioned_users?: UserResponse<StreamChatGenerics>[],
    options?: RenderTextOptions,
) => {
    const textFormat = text?.replaceAll('<br>', '');
    return renderText(textFormat, mentioned_users, options )
}
