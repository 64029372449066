import React, { useMemo, useState } from 'react';
import { Box, Card } from '@material-ui/core';
import { Channel, StreamChat } from 'stream-chat';
import { CollapseBox } from './CollapseBox';
import { InviteDialog } from './InviteDialog';
import { InviteTabs } from './InviteTabs';
import { InviteTab } from './model';
import { useInvite, useUserRoles } from './hooks';
import { useQueryUsers } from 'modules/connect/messenger/hooks/useQueryUsers';
import { SelectUsers } from './SelectUsers';
import { SelectRoles } from './SelectRoles';
import { Formik } from 'formik';

export interface IInviteActionContainerProps {
    isDialogOpened: boolean;
    onDialogClose: () => void;
    client: StreamChat;
    channel: Channel;
}

export function InviteActionContainer(props: IInviteActionContainerProps) {
    const [tab, setTab] = useState(InviteTab.Individual);
    const rolesHelper = useUserRoles();
    const memberRolesAll = rolesHelper.chatRoleIds;

    const memberRoles = useMemo(() => {
        return memberRolesAll[props.channel.id ?? ''] ?? [];
    }, [memberRolesAll, props.channel.id]);

    const initialValues = {roles: memberRoles};
    const usersHelper = useQueryUsers(props.client);
    const invite = useInvite(tab, rolesHelper, usersHelper, props.channel.id as string, props.onDialogClose);

    return (
        <InviteDialog
            isOpened={props.isDialogOpened}
            onClose={props.onDialogClose}
            inviteDisabled={invite.isDisabled}
            onInvite={invite.make}
        >
            <InviteTabs currentTab={tab} setCurrentTab={setTab}/>
            <Card>
                <Box width="80vw" p={3}>
                    <CollapseBox show={tab === InviteTab.Individual}>
                        <SelectUsers helpers={usersHelper}/>
                    </CollapseBox>
                    <CollapseBox show={tab === InviteTab.UserRoles}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={() => {}}
                            validate={(values) => {
                                rolesHelper.selected = values.roles ?? [];
                            }}
                            validateOnChange={true}
                        >
                            <SelectRoles roles={rolesHelper}/>
                        </Formik>
                    </CollapseBox>
                </Box>
            </Card>
        </InviteDialog>
    );
}
