import React from 'react';
import omit from 'lodash/omit';
import { CreateCSSProperties } from '@material-ui/styles';
import { Button, ButtonProps, styled, lighten, darken } from '@material-ui/core';
import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';

enum PolymorphicButtonPropNames {
    Rounded = 'rounded',
    FontWeight = 'fontWeight',
    TextColor = 'textColor',
    BodyColor = 'bodyColor',
    VerticalPadding = 'verticalPadding',
    HorizontalPadding = 'horizontalPadding',
    BorderColor = 'borderColor',
}

enum PolymorphicButtonBodyColor {
    Blue = 'blue',
    Red = 'red',
    White = 'white',
    Gray = 'gray',
    DarkGray = 'dark-gray',
    DarkBlue = 'dark-blue',
}

type PolymorphicButtonBodyColorType = (
    `${PolymorphicButtonBodyColor.Blue}` |
    `${PolymorphicButtonBodyColor.Red}` |
    `${PolymorphicButtonBodyColor.White}` |
    `${PolymorphicButtonBodyColor.Gray}` |
    `${PolymorphicButtonBodyColor.DarkGray}` |
    `${PolymorphicButtonBodyColor.DarkBlue}`
);

enum PolymorphicButtonTextColor {
    White = 'white',
    DarkGray = 'dark-gray',
    Blue = 'blue',
}

enum PolymorphicButtonBorderColor {
    None = 'none',
    LightBlue = 'light-blue',
}

const bodyColorMap: Record<PolymorphicButtonBodyColor, string> = {
    [PolymorphicButtonBodyColor.Blue]: BlueColor.BluePrimaryMain,
    [PolymorphicButtonBodyColor.Red]: UtilColors.Red3,
    [PolymorphicButtonBodyColor.White]: UtilColors.White,
    [PolymorphicButtonBodyColor.Gray]: GrayColor.GrayE0,
    [PolymorphicButtonBodyColor.DarkGray]: GrayColor.Gray87,
    [PolymorphicButtonBodyColor.DarkBlue]: BlueColor.Dark,
};

const textColorMap: Record<PolymorphicButtonTextColor, string> = {
    [PolymorphicButtonTextColor.Blue]: BlueColor.BluePrimaryMain,
    [PolymorphicButtonTextColor.White]: UtilColors.White,
    [PolymorphicButtonTextColor.DarkGray]: UtilColors.TextPrimary,
};

const borderColorMap: Record<PolymorphicButtonBorderColor, string> = {
    [PolymorphicButtonBorderColor.None]: 'none',
    [PolymorphicButtonBorderColor.LightBlue]: lighten(BlueColor.BluePrimaryMain, 0.5),
};

const customPolymorphicButtonProps = [
    PolymorphicButtonPropNames.Rounded,
    PolymorphicButtonPropNames.FontWeight,
    PolymorphicButtonPropNames.TextColor,
    PolymorphicButtonPropNames.BodyColor,
    PolymorphicButtonPropNames.VerticalPadding,
    PolymorphicButtonPropNames.HorizontalPadding,
    PolymorphicButtonPropNames.BorderColor,
];

export type PolymorphicButtonProps = {
    [PolymorphicButtonPropNames.Rounded]?: boolean;
    [PolymorphicButtonPropNames.FontWeight]?: 500 | 700;
    [PolymorphicButtonPropNames.TextColor]?: PolymorphicButtonTextColor;
    [PolymorphicButtonPropNames.BodyColor]?: PolymorphicButtonBodyColor | PolymorphicButtonBodyColorType;
    [PolymorphicButtonPropNames.VerticalPadding]?: 6 | 8;
    [PolymorphicButtonPropNames.HorizontalPadding]?: 16 | 22;
    [PolymorphicButtonPropNames.BorderColor]?: 'none' | 'light-blue';
};

type StyledButtonProps = PolymorphicButtonProps & ButtonProps;

function passButtonProps(props: StyledButtonProps) {
    const buttonProps: ButtonProps = omit(props, customPolymorphicButtonProps);
    return <Button {...buttonProps}/>;
}

const createPolymorphicButton = styled(passButtonProps);

const typography: CreateCSSProperties<PolymorphicButtonProps> = {
    '&, & *': {
        font: `'Inter', 'Arial', sans-serif`,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        fontWeight: ({ fontWeight }: PolymorphicButtonProps) => fontWeight || 500,
        textTransform: 'uppercase',
    },
};

const boxModel: CreateCSSProperties<PolymorphicButtonProps> = {
    boxSizing: 'border-box',
    borderRadius: ({ rounded }: PolymorphicButtonProps) => rounded ? '24px' : '4px',
    padding: (props: PolymorphicButtonProps) => `${props.verticalPadding || 8}px ${props.horizontalPadding || 16}px`,
    border: (props: PolymorphicButtonProps) => props.borderColor === 'none' ? 'none' : `1px solid ${borderColorMap[props.borderColor || PolymorphicButtonBorderColor.None]}`,
};

const colors: CreateCSSProperties<PolymorphicButtonProps> = {
    color: (props: PolymorphicButtonProps) => textColorMap[props.textColor || PolymorphicButtonTextColor.White],
    background: (props: PolymorphicButtonProps) => bodyColorMap[props.bodyColor || PolymorphicButtonBodyColor.Blue],
    '&:hover': {
        background: (props: PolymorphicButtonProps) => darken(
            bodyColorMap[props.bodyColor || PolymorphicButtonBodyColor.Blue],
            0.1,
        ),
    },
};

export const PolymorphicButton = createPolymorphicButton({
    ...typography,
    ...boxModel,
    ...colors,
});
