import { useSelector } from 'react-redux';
import { selectUserListOrder, selectUsersByIds } from 'store/modules/user/userSelectors';
import { nonEmpty } from 'utils/helpers/collections';
import { UserListFilter } from './model';

export function useUsersGridData(filter: UserListFilter) {
    const usersOrder = useSelector(selectUserListOrder);
    const usersById = useSelector(selectUsersByIds);

    return usersOrder
        .map(id => usersById[id])
        .filter(nonEmpty)
        .filter((x) => {
            switch (filter.UserState) {
                case '1':
                    return x.isDeleted;
                case '0':
                    return !x.isDeleted;
                default:
                    return true;
            }
        });
}
