import { Box, IconButton } from '@material-ui/core';
import { useGetThumbnailUrl } from 'modules/task/attachments/components/AttachmentThumbnail/AttachmentThumbnail';
import { useCallback, useMemo } from 'react'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import { useLaunchCoverStyles } from './styles';
import { useActions } from 'utils/store/useActions';
import { launchDeleteModalOpen } from 'api/launch/launchActions';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import clsx from 'clsx';
import { openLaunchCoverEditor } from 'store/modules/launch/launchActions';

export const LaunchImg = ({ launchPageCover }: { launchPageCover: string }) => {
  const classes = useLaunchCoverStyles();
  const launchCoverImageUrlGetter = useGetThumbnailUrl();
  const launchurl = useMemo(() => (launchCoverImageUrlGetter(launchPageCover)), [launchPageCover, launchCoverImageUrlGetter]);
  const actions = useActions({ openModalDeleteLaunch: launchDeleteModalOpen, openLaunchCoverEditor: openLaunchCoverEditor });
  const canCreateDelete = useSelector(selectHasAppAccess(AppName.AdminCompanyProfile));

  const handleLaunchImgDelete = useCallback(() => {
    actions.current.openModalDeleteLaunch({ launch: { id: 'isLaunchCover', caption: 'Cover photo', isLaunchCover: true }, isOpen: true })
  }, [actions]);

  const launchCoverImgEditHandler = useCallback(() => actions.current.openLaunchCoverEditor(true), [actions]);

  return (
    <Box position="relative" width="100%" height="100%" className={classes.launchCoverImg} >
      <Box className={classes.launchCoverImg} style={{backgroundImage: `url(${launchurl})` }}/>
      {canCreateDelete &&
        <>
          <IconButton className={clsx(classes.iconAction, classes.editIcon)} onClick={launchCoverImgEditHandler} >
            <EditIcon color="primary" fontSize="medium" />
          </IconButton>
          <IconButton className={clsx(classes.iconAction, classes.deleteIcon)} onClick={handleLaunchImgDelete} >
            <DeleteIcon color="primary" fontSize="medium" />
          </IconButton>
        </>
      }
    </Box>
  )
}
