import React, { useCallback, useMemo } from 'react';
import {
    Column, TableInstance,
    useFlexLayout,
    useTable,
} from 'react-table';
import { ColumnId, UserListFilter } from './model';
import { asBlock, makeSkeletons, toTableCell } from 'modules/shared/components/gridHelpers';
import { VirtualScrollGrid } from 'modules/shared/components/VirtualScrollGrid';
import { UserDTO } from 'api/user/userModel';
import { EmptyState } from './EmptyState';
import { UserInfoCell } from './UserInfoCell';
import { TextCellWithSkeleton } from './TextCell';
import {
    selectUserListLoading,
    selectUserListNextPageToken,
    selectUsersChecked, selectUsersCheckedOnlyArray
} from 'store/modules/user/userSelectors';
import { requestUserList } from 'api/user/userActions';
import { useLoad } from 'modules/task/taskList/hooks/useLoad';
import { resetUserList, setUsersCheckedItems } from 'store/modules/user/userActions';
import { Skeleton } from './withSkeleton';
import { SpacerCell } from 'modules/shared/components/SpacerCell';
import { getUsersCheckboxCell } from './UsersCheckboxCell';
import { useCheckedItemsStore } from '../../../utils/hooks/useCheckdItems';
import { Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ListChildComponentProps } from 'react-window';
import { StyledSelectedTableRow, StyledTableRow, TABLE_HEAD_HEIGHT } from '../../shared/components/StyledTable';
import { selectFeatureFlags } from '../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../utils/featureFlags';
import { ActionsCell } from './ActionCell';
import { selectUser } from 'store/modules/auth/authSelectors';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useElementHeight } from 'modules/shared/hooks';
import { Trans } from 'react-i18next';

const useCellCheckboxStyles = makeStyles(() => ({
    iconCell: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    checkbox: {
        justifyContent: 'flex-start',
        '& svg': {
            fontSize: 21,
        },
    },
    checkboxChecked: {
        color: '#2db67d',
    },
}));

export type UsersGridProps = {
    data: UserDTO[];
    filter?: UserListFilter;
    isFetching: boolean;
}

const skeletons = makeSkeletons<UserDTO>(15);
const gridColumns: Column<Skeleton<UserDTO>>[] = [
    {
        id: ColumnId.Id,
        Cell: SpacerCell,
        width: 0,
    },
    {
        id: ColumnId.UserInfo,
        Header: <Trans>User</Trans>,
        accessor: 'firstName',
        Cell: UserInfoCell,
        width: 200,
    },
    {
        id: ColumnId.Role,
        Header: <Trans>Role</Trans>,
        accessor: 'role',
        Cell: TextCellWithSkeleton,
        width: 100,
    },
    {
        id: ColumnId.Location,
        Header: <Trans>Location</Trans>,
        accessor: 'companyLocationName',
        Cell: TextCellWithSkeleton,
        width: 100,
    },
];

const spacerColumn: Column<Skeleton<UserDTO>> = {
    id: 'spacer',
    Cell: SpacerCell,
    width: 7,
}

const actionColumn: Column<Skeleton<UserDTO>> = {
    id: ColumnId.Actions,
    accessor: 'id',
    Cell: ActionsCell,
    defaultCanSort: false,
    disableSortBy: true,
    width: '52px',
}

export const UsersGrid = ({ data, filter, isFetching }: UsersGridProps) => {
    const baseSearchRequest = useMemo(() => ({
        ...filter
    }), [filter]);

    const checkboxStyle = useCellCheckboxStyles();
    const isActivateEnabled = useSelector(selectFeatureFlags)[FeatureFlags.ActivateUsersBulk];
    const isDeactivateEnabled = useSelector(selectFeatureFlags)[FeatureFlags.DeactivateUsersBulk];
    const isLoginAsEnabled = useFeatureState(FeatureFlags.UserImpersonation);
    const bothFeatureEnabled = isActivateEnabled?.enabled && isDeactivateEnabled?.enabled;
    const allUsersFilterIsSet = (filter?.UserState ?? '0') === '2';
    const currentUser = useSelector(selectUser);
    const isSuperAdmin = currentUser?.isSuperAdmin ?? false;
    const [containerRef, containerHeight] = useElementHeight();

    const { preload, nextPageToken } = useLoad(
        selectUserListNextPageToken,
        selectUserListLoading,
        requestUserList.init,
        resetUserList,
        baseSearchRequest,
    );

    const showEmptyState = data.length === 0 && !isFetching;
    const showSkeletons = data.length === 0 && isFetching;
    const totalData = showSkeletons ? skeletons : data;
    const checkItemsActions = useCheckedItemsStore(
        data,
        selectUsersChecked,
        setUsersCheckedItems,
    );
    const checkboxCell = useMemo(() => {
        return getUsersCheckboxCell(
            checkItemsActions.checkedItems,
            checkItemsActions.onCheck,
            checkItemsActions.checkedAll,
            checkItemsActions.onCheckAll,
            checkboxStyle,
            false,
            row => (row as TableInstance).row?.original?.id ?? '',
            [],
            row => row,
        );
    }, [checkItemsActions, checkboxStyle]);
    const usersSelected = useSelector(selectUsersCheckedOnlyArray);
    const columnsListExtended = useMemo(() => {
        const baseGridColumns = isSuperAdmin && isLoginAsEnabled ? [...gridColumns, actionColumn] : [...gridColumns, spacerColumn];
        if (allUsersFilterIsSet) {
            return baseGridColumns;
        }
        else if (bothFeatureEnabled) {
            return [checkboxCell, ...baseGridColumns];
        } else {
            return baseGridColumns;
        }
    }, [checkboxCell, bothFeatureEnabled, allUsersFilterIsSet, isSuperAdmin, isLoginAsEnabled])
    const dataGrid = useTable<Skeleton<UserDTO>>(
        {
            columns: columnsListExtended,
            data: totalData
        },
        useFlexLayout,
    );

    const makeRowCheckedRenderer = useCallback((dataGrid: TableInstance<any>) =>
        ({index, style}: ListChildComponentProps) => {
            const row = dataGrid.rows[index];

            dataGrid.prepareRow(row);
            const rowProps = row.getRowProps({style, key: row.original.id});
            if (!usersSelected?.includes(row.original.id)) {
                return (
                    <StyledTableRow {...asBlock} {...rowProps}>
                        {row.cells.map(toTableCell)}
                    </StyledTableRow>
                );
            } else {
                return (
                    <StyledSelectedTableRow {...asBlock} {...rowProps}>
                        {row.cells.map(toTableCell)}
                    </StyledSelectedTableRow>
                );
            }
        }, [usersSelected]);

    return (
        /* @ts-ignore - ref is actually presented as property of this container */
        <Box ref={containerRef}>
            <VirtualScrollGrid
                dataGrid={dataGrid}
                showEmptyState={showEmptyState}
                emptyState={<EmptyState />}
                height={containerHeight - TABLE_HEAD_HEIGHT}
                nextPageToken={nextPageToken}
                preload={preload}
                customRowRenderer={makeRowCheckedRenderer}
            />
        </Box>
    );
};
