import React from 'react';
import { IItemComponent } from 'components/basicInputs/selectInput/model';
import { Box, Typography } from '@material-ui/core';
import { WatcherType } from 'api/task/watcher/watcherModel';

export function WatcherItem({ item: user }: IItemComponent<WatcherType>) {
    return (
        <Box display="flex" alignItems="center">
            <Typography variant="caption">
                {user.fullName}
            </Typography>
        </Box>
    );
}
