import { List } from "@material-ui/core";
import { AttachmentsGallery } from "modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery";
import { useAttachments } from "store/modules/files/filesHooks";
import { getPendingConversationFiles, getUploadedConversationFiles } from "store/modules/files/filesSelectors";
import { PendingAttachmentItem } from "./PendingAttachmentItem";
import { thumbnaiCommentslHeight } from "modules/task/attachments/components/AttachmentThumbnail/styles";
import { useActions } from "utils/store/useActions";
import { removeUploadedConversationFile } from "store/modules/files/filesActions";
import { useCallback } from "react";

export const AttachmentsList = () => {
    const { pendingFiles, uploadedFiles } = useAttachments(getPendingConversationFiles, getUploadedConversationFiles);
    const actions = useActions({ removeUploadedFile: removeUploadedConversationFile });
    const handleRemoveFile = useCallback((attachment: {fileId: string;name: string;hasThumbnail: boolean; }) => {
        actions.current.removeUploadedFile(attachment.fileId);
    }, [actions]);

    return (
        <List dense>
            {uploadedFiles.length > 0 &&  <AttachmentsGallery 
                attachments={uploadedFiles} 
                onDeleteHandler={handleRemoveFile}
                fallbackImageHeigth={thumbnaiCommentslHeight}/> }
            {pendingFiles.map(attach => <PendingAttachmentItem key={attach.localKey} {...attach} />)}
        </List>
    );
}
