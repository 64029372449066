import * as t from 'io-ts';
import { attachmentSchema } from '../prototype/prototypeModel';
import { fromEnum } from 'utils/helpers/fromEnum';

export const tasksConversationSchema = t.type({
    text: t.string,
    replyTo: t.union([t.undefined, t.string]),
    attachments: t.array(attachmentSchema),
    mentionedUsers: t.array(t.string),
    storeTaskId: t.string
});

export const suggestionListSchema = t.array(t.string);

export type CreateTasksConversationType = t.TypeOf<typeof tasksConversationSchema>;
export type SuggestionListType = t.TypeOf<typeof suggestionListSchema>;

export enum ReactionEnum {
    Smile = 'Smile',
    Check = 'Check',
    Like = 'Like',
    Thumbsup = 'Thumbsup'
}

const reactionType = fromEnum('Reaction', ReactionEnum);

const reactionSchema = t.type({
    id: t.string,
    reactionType: reactionType,
    createdBy: t.string,
    createdAt: t.string,
    canEdit: t.boolean
});

export const conversationSchema = t.type({
    attachments: t.array(attachmentSchema),
    text: t.string,
    createdAt: t.string,
    createdBy: t.string,
    id: t.string,
    canEdit: t.boolean,
    isModified: t.boolean,
    reactions: t.array(reactionSchema),
    mentionedUsers: t.array(t.string)
});
const storeTaskConversationsSchema = t.type({
    locationId: t.string,
    locationName: t.string,
    storeTaskId: t.string,
    comments: t.array(conversationSchema)
});
export const conversationsStoreTaskSchema = t.array(conversationSchema);
export const conversationsTaskSchema = t.array(storeTaskConversationsSchema);
export type ConversationType = t.TypeOf<typeof conversationSchema>;
export type ConversationsStoreTaskType = t.TypeOf<typeof conversationsStoreTaskSchema>;
export type StoreTaskConversationType = t.TypeOf<typeof storeTaskConversationsSchema>;
export type StoreTaskConversationsType = t.TypeOf<typeof conversationsTaskSchema>;

export interface ReactionAdd {
    storeTaskId: string;
    commentId: string;
    reactionType: ReactionEnum;
};
export interface ReactionDelete {
    storeTaskId: string;
    reactionId: string;
};

export const editCommentSchema = t.type({
    text: t.string,
    replyTo: t.union([t.undefined, t.string]),
    attachments: t.array(attachmentSchema),
    mentionedUsers: t.array(t.string),
    commentId: t.string
});

export type EditCommentType = t.TypeOf<typeof editCommentSchema>;