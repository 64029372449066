import { Close, Done, DoneAll, ThumbDownOutlined } from '@material-ui/icons';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { ForwardMailIcon } from 'components/icons/ForwardMailIcon';
import { GreenColor, orangeColor, UtilColors } from 'utils/styles/constants';

export interface ISkeleton {
    isSkeleton?: boolean;
}

export interface IHasDueDate {
    dueDate: string;
}

export interface IHasCreator {
    createdBy: string;
}

export interface IHasApprovable {
    approvable?: boolean;
}

export interface IHasExpirable {
    expire?: boolean;
}

export interface IHasCompletionState {
    completionState?: DisplayedTaskStatus;
}

export interface IHasSubmittedTasksCount {
    submittedStoreTasks: number;
}

export interface ICreatedAt {
    createdAt: string;
}
export type CompletionState = 'default' | 'completed' | 'overdue';

export const colorMap: Record<DisplayedTaskStatus, string> = {
    [DisplayedTaskStatus.Completed]: GreenColor.Green2,
    [DisplayedTaskStatus.CompletedOverdue]: orangeColor,
    [DisplayedTaskStatus.Declined]: UtilColors.Red2,
    [DisplayedTaskStatus.Overdue]: UtilColors.Red2,
    [DisplayedTaskStatus.Submitted]: UtilColors.Transparent,
    [DisplayedTaskStatus.SubmittedOverdue]: orangeColor,
    [DisplayedTaskStatus.Default]: UtilColors.Transparent,
    [DisplayedTaskStatus.Expired]: orangeColor,
};

export const colorMapDisabled: Record<DisplayedTaskStatus, string> = {
    [DisplayedTaskStatus.Completed]: GreenColor.Green2,
    [DisplayedTaskStatus.CompletedOverdue]: GreenColor.Green2,
    [DisplayedTaskStatus.Declined]: UtilColors.Red2,
    [DisplayedTaskStatus.Overdue]: UtilColors.Red2,
    [DisplayedTaskStatus.Submitted]: UtilColors.Transparent,
    [DisplayedTaskStatus.SubmittedOverdue]: UtilColors.Transparent,
    [DisplayedTaskStatus.Default]: UtilColors.Transparent,
    [DisplayedTaskStatus.Expired]: orangeColor,
};

export const fontColorMap: Record<DisplayedTaskStatus, string> = {
    [DisplayedTaskStatus.Completed]: GreenColor.Green2,
    [DisplayedTaskStatus.CompletedOverdue]: orangeColor,
    [DisplayedTaskStatus.Declined]: UtilColors.Red2,
    [DisplayedTaskStatus.Overdue]: UtilColors.Red2,
    [DisplayedTaskStatus.Submitted]: UtilColors.TextSecondary,
    [DisplayedTaskStatus.SubmittedOverdue]: orangeColor,
    [DisplayedTaskStatus.Default]: UtilColors.TextSecondary,
    [DisplayedTaskStatus.Expired]: orangeColor,
};

export const fontColorMapDisabled: Record<DisplayedTaskStatus, string> = {
    [DisplayedTaskStatus.Completed]: GreenColor.Green2,
    [DisplayedTaskStatus.CompletedOverdue]: GreenColor.Green2,
    [DisplayedTaskStatus.Declined]: UtilColors.Red2,
    [DisplayedTaskStatus.Overdue]: UtilColors.Red2,
    [DisplayedTaskStatus.Submitted]: UtilColors.TextSecondary,
    [DisplayedTaskStatus.SubmittedOverdue]: UtilColors.TextSecondary,
    [DisplayedTaskStatus.Default]: UtilColors.TextSecondary,
    [DisplayedTaskStatus.Expired]: UtilColors.Red2,
};

type IconType = typeof Done | typeof Close | typeof ThumbDownOutlined | typeof ForwardMailIcon
export const storeLevelIconMap: Record<DisplayedTaskStatus, IconType> = {
    [DisplayedTaskStatus.Completed]: Done,
    [DisplayedTaskStatus.Declined]: ThumbDownOutlined,
    [DisplayedTaskStatus.Submitted]: ForwardMailIcon,
    [DisplayedTaskStatus.Overdue]: Close,
    [DisplayedTaskStatus.CompletedOverdue]: Close,
    [DisplayedTaskStatus.SubmittedOverdue]: Close,
    [DisplayedTaskStatus.Default]: Close,
    [DisplayedTaskStatus.Expired]: Close,
};

export const parentLevelIconMap: Record<DisplayedTaskStatus, IconType> = {
    [DisplayedTaskStatus.Completed]: DoneAll,
    [DisplayedTaskStatus.Declined]: Close,
    [DisplayedTaskStatus.Submitted]: Close,
    [DisplayedTaskStatus.Overdue]: Close,
    [DisplayedTaskStatus.SubmittedOverdue]: Close,
    [DisplayedTaskStatus.CompletedOverdue]: Close,
    [DisplayedTaskStatus.Default]: Close,
    [DisplayedTaskStatus.Expired]: Close,
};

export const textMap: Record<DisplayedTaskStatus, string> = {
    [DisplayedTaskStatus.Overdue]: 'Overdue',
    [DisplayedTaskStatus.Declined]: 'Declined',
    [DisplayedTaskStatus.Completed]: 'Completed',
    [DisplayedTaskStatus.Default]: 'Incomplete',
    [DisplayedTaskStatus.CompletedOverdue]: 'Completed Overdue',
    [DisplayedTaskStatus.SubmittedOverdue]: 'Submitted Overdue',
    [DisplayedTaskStatus.Submitted]: 'Submitted',
    [DisplayedTaskStatus.Expired]: 'Expired',
};

export const textMapDisabledOverdue: Record<DisplayedTaskStatus, string> = {
    [DisplayedTaskStatus.Overdue]: 'Overdue',
    [DisplayedTaskStatus.Declined]: 'Declined',
    [DisplayedTaskStatus.Completed]: 'Completed',
    [DisplayedTaskStatus.Default]: 'Incomplete',
    [DisplayedTaskStatus.CompletedOverdue]: 'Completed',
    [DisplayedTaskStatus.SubmittedOverdue]: 'Submitted',
    [DisplayedTaskStatus.Submitted]: 'Submitted',
    [DisplayedTaskStatus.Expired]: 'Expired',
};
