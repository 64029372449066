import React from 'react';
import { CellProps } from 'react-table';
import { Box } from '@material-ui/core';

export const SpacerCell: React.FC<CellProps<any, any>> = () => {
    return (
        <Box
            width="7px"
            height="52px"
        />
    );
};
