import { combineReducers } from 'redux';
import { IToastMessage } from './appStateModel';
import { APP_SET_LEFT_MENU_MINIMIZED, APP_SHOW_TOAST_MESSAGE, AppStateActions } from './appStateActions';

const initialState = {
    toastMessagesHistory: [],
    leftMenuOpened: false,
};

function toastMessagesHistory(
    state: Array<IToastMessage> = initialState.toastMessagesHistory,
    action: AppStateActions,
): Array<IToastMessage> {
    if (action.type === APP_SHOW_TOAST_MESSAGE) {
        return state.concat(action.payload);
    }
    return state;
}

function leftMenuOpened(state = initialState.leftMenuOpened, action: AppStateActions): boolean {
    switch (action.type) {
        case APP_SET_LEFT_MENU_MINIMIZED:
            return action.payload;
        default:
            return state;
    }
}

export const appState = combineReducers({
    leftMenuOpened,
    toastMessagesHistory,
});
