import React from 'react';
import data, { EmojiMartData, Emoji as EmojiI } from '@emoji-mart/data';

type EmojiProps = {
    id: string;
    size?: number;
    onClick?: (emojiId: string) => void;
    tooltip?: boolean;
    color?: string;
};

export const Emoji: React.FC<EmojiProps> = ({ id, size = 20, onClick, tooltip, color }) => {
    const emoji: EmojiI = (data as EmojiMartData)?.emojis[id];

    if (!emoji) {
        return <span>No emoji found</span>;
    }


    const handleClick = () => {
        if (onClick) {
            onClick(id);
        }
    };

    return (
        <span
            style={{
                fontSize: size,
                lineHeight: `${size}px`,
                cursor: onClick ? 'pointer' : 'default',
                color: color ? color : undefined,
            }}
            title={tooltip ? emoji.name : undefined}
            onClick={handleClick}
        >
            {emoji.skins[0].native}
        </span>
    );
};
