import { Avatar, Box, makeStyles, Theme } from '@material-ui/core';
import { useField } from 'formik';
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { openAvatarEditor } from 'store/modules/user/userActions';
import { BlueColor } from 'utils/styles/constants';
import { Trans } from 'react-i18next';
import { CropperModal, CropperModalProps } from './CropperModal';
import { getFilesConfig } from 'store/modules/files/filesSelectors';

const useStyles = makeStyles((theme: Theme) => ({
    formBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '10px',
    },
    formBoxInner: {
        width: '100px',
        height: '100px',
    },
    avatar: {
        width: '100px',
        height: '100px',
        backgroundColor: 'white',
        color: 'darkblue',
    },
    avatarInner: {
        backgroundColor: BlueColor.Dark,
        color:'white',
        width:'100px',
        textAlign: 'center',
        lineHeight:'100px',
    },
    formBoxOverlay: {
        opacity: 0,
        textAlign: 'center',
        lineHeight: '100px',
        height: '100px',
        width: '100px',
        cursor: 'pointer',
        transition: '.3s ease',
        backgroundColor: 'black',
        color: 'white',
        borderRadius: '50%',
        zIndex: 1000,
        marginTop: '-100px',
        '&:hover': {
            opacity: 0.7,
        },
    },
}));
type CropperImageProps = CropperModalProps & {
    name: string,
    alt: string,
    defaultImage: string,
}

export const CropperImage = ({ name, alt, defaultImage, textPreview, title, onSaveImage, isLoading }: CropperImageProps) => {
    const [field] = useField(name);
    const { value } = field;
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileStorageConfig = useSelector(getFilesConfig);
    const imageUrl = useMemo(() => (
        fileStorageConfig && value
            ? `${fileStorageConfig.fileStorageBaseUrl}/${value}`
            : defaultImage
    ), [fileStorageConfig, value, defaultImage]);
    const avatarEditHandler = useCallback(() => {
        dispatch(openAvatarEditor(true));
    }, [dispatch]);

    return (
        <>
            <Box className={classes.formBox}>
                <Box className={classes.formBoxInner} onClick={avatarEditHandler}>
                    <Avatar className={classes.avatar}
                        src={imageUrl}
                        alt={alt}
                    />
                    <div className={classes.formBoxOverlay}><Trans>Change</Trans></div>
                </Box>
            </Box>
            <CropperModal textPreview={textPreview} title={title} onSaveImage={onSaveImage} isLoading={isLoading}/>
        </>
    )
}