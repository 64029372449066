import { TaskTaglistMultiselect } from 'modules/shared/components/TaskTaglistMultiselect'
import { TasksFilterContext } from 'modules/task/taskList/context/taskFilterContext';
import React, { useContext } from 'react'

export const TasksTagFilter = () => {
  const {
    valuesFiltersTasks,
    handleFilterTag,
    showFilterTag
  } = useContext(TasksFilterContext);

  if (!showFilterTag) return <></>;

  return (
    <TaskTaglistMultiselect
      onTagsValuesChange={handleFilterTag}
      initialTagSelected={valuesFiltersTasks.tags}
    />
  )
}
