import React, { useCallback } from 'react';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { IconButton, ListItem, ListItemSecondaryAction } from '@material-ui/core';
import { useDownloadFile } from 'store/modules/files/filesHooks';
import { resolveType } from 'utils/helpers/fileHelpers';
import { AttachmentItemAvatar, AttachmentItemMeta, DownloadAction } from './attachmentItemElements';
import { useFormikContext } from 'formik';
import { TaskPrototype } from 'api/task/prototype/prototypeModel';

export type AttachmentItemEditProps = {
    fileId: string;
    name: string;
    size?: number;
}

export function AttachmentItemEdit({ fileId, name, size }: AttachmentItemEditProps) {
    const { values, setFieldValue } = useFormikContext<Partial<TaskPrototype>>();
    const handleDownloadClick = useDownloadFile(fileId, name);
    const handleRemoveClick = useCallback(() => {
        const attachments = (values?.attachments ?? []).filter(a => a.fileId !== fileId);
        setFieldValue('attachments', attachments);
    }, [fileId, values, setFieldValue]);

    return (
        <ListItem>
            <AttachmentItemAvatar />
            <AttachmentItemMeta
                title={name}
                size={size}
                type={resolveType(name)}
            />

            <ListItemSecondaryAction>
                {fileId && <DownloadAction handleDownloadClick={handleDownloadClick} />}

                <IconButton color="primary" onClick={handleRemoveClick}>
                    <ClearOutlinedIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
