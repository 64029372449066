import React, { useCallback, useState } from 'react';
import {
    Box,
    Button, Checkbox,
    darken,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, lighten,
    styled,
    Typography
} from '@material-ui/core';
import { GrayColor, UtilColors } from '../../../utils/styles/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIdRequireConfirmation, selectUsersByIds } from '../../../store/modules/user/userSelectors';
import { requestConfirmUserReactivation } from '../../../store/modules/user/userActions';
import { UserFormData } from './model';
import { requestMultipleUsersInfo } from '../../../api/user/userActions';
import { useParams } from 'react-router';
import { UserDTO } from '../../../api/user/userModel';
import { Trans } from 'react-i18next';

type UserActivationDeactivationModalProps = {
    user: UserFormData;
}

const UserTitleWrapper = styled(Typography)({
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',

    '& strong': {
        fontWeight: 700,
    },
});

const UserDialogDescription = styled(Typography)({
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
});

const StyledDialogActions = styled(DialogActions)({
    padding: '12px',
});

const DeleteButton = styled(Button)({
    backgroundColor: UtilColors.Red3,
    padding: '6px 16px',
    marginLeft: '16px',
    borderRadius: '4px',
    '&:hover': {
        backgroundColor: darken(UtilColors.Red3, 0.05),
    },
    '& span': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: UtilColors.White,
    },
});

const CancelButton = styled(Button)({
    backgroundColor: GrayColor.GrayE0,
    padding: '6px 16px',
    borderRadius: '4px',
    '&:hover': {
        backgroundColor: darken(GrayColor.GrayE0, 0.05),
    },
    '& span': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: lighten(UtilColors.Black, 0.22),
    },
});

const UserStatusConfirmModal: React.FC<UserActivationDeactivationModalProps> = ({ user }) => {
    const dispatch = useDispatch();
    const { userId } = useParams<{ userId: string }>();
    const userRaw = useSelector(selectUsersByIds)[userId];
    const [mailConfirmation, setMailConfirmation] = useState(false);
    const handleClickNo = useCallback(() => {
        dispatch(requestConfirmUserReactivation(null));
    }, [dispatch]);
    const handleClickYes = useCallback(() => {
        const changedUser = {
            ...userRaw,
            isDeleted: false,
            meta: {
                sendNotification: mailConfirmation,
            }
        } as UserDTO;
        dispatch(requestMultipleUsersInfo.success({ requestedIds: [userId], users: [changedUser] }));
        dispatch(requestConfirmUserReactivation(null));
    }, [dispatch, userRaw, userId, mailConfirmation]);
    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <UserTitleWrapper>
                    <Trans>Reactivating</Trans> <strong>{user.firstName} {user.lastName}</strong> <Trans>confirmation</Trans>
                </UserTitleWrapper>
            </DialogTitle>
            <DialogContent>
                <UserDialogDescription>
                    <Trans>Are you sure you want to reactivate</Trans> <strong>{user.firstName} {user.lastName}</strong> ?
                </UserDialogDescription>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    marginLeft={'-10px'}
                >
                    <Checkbox
                        disabled={false}
                        color="primary"
                        checked={mailConfirmation}
                        onClickCapture={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setMailConfirmation(!mailConfirmation);
                        }}
                    />
                    <UserDialogDescription><Trans>Send email confirmation</Trans></UserDialogDescription>
                </Box>
            </DialogContent>
            <StyledDialogActions>
                <CancelButton onClick={handleClickNo}><Trans>Cancel</Trans></CancelButton>
                <DeleteButton onClick={handleClickYes}><Trans>Reactivate</Trans></DeleteButton>
            </StyledDialogActions>
        </Dialog>
    );
};

export const UserStatusChangeConfirmation: React.FC = () => {
    const user = useSelector(selectUserIdRequireConfirmation);

    if (!user) {
        return null;
    }
    return (
        <UserStatusConfirmModal user={user}/>
    );
};
