import React, { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import { UserEditForm } from './UserEditForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserRoles, selectUsersByIds, selectUsersUpdatingById } from 'store/modules/user/userSelectors';
import { PageLoader } from 'components/router/PageLoader';
import { LanguageCode, UserDTO, UserEditDTO } from 'api/user/userModel';
import { requestUserInfos } from 'store/modules/user/userActions';
import { requestUserUpdate } from 'api/user/userActions';
import { UserFormData } from './model';
import { FlexBox } from 'modules/shared/components/FlexBox';
import { NavButton } from 'modules/shared/components/NavButton';
import { adminSubRoutes, Routes, UsersBetaAppName } from 'components/router/model';
import { LegacyRouteState } from 'modules/legacyWrappers/createLegacyPage';
import { UserStatusChangeConfirmation } from './UserStatusConfirmation';
import { requestCompanyRoles } from '../../../api/core/actions';
import { EmbeddedSubRoutesTopMenu } from '../../../components/router/EmbeddedSubroutesTopMenu';
import { Box } from '@material-ui/core';
import { Trans } from 'react-i18next';

const createFormData = (user: UserDTO): UserFormData => {
    return {
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email ?? '',
        companyLocationId: user.companyLocationId,
        roleId: user.roleId,
        employeeTitle: user.employeeTitle ?? '',
        phoneNumber: user.phoneNumber ?? '',
        isDeleted: user.isDeleted ?? false,
        language: user.language ?? LanguageCode.en,
    }
};

export function EditUserPage() {
    const dispatch = useDispatch();
    const { userId } = useParams<{ userId: string }>();
    const user = useSelector(selectUsersByIds)[userId];
    const roles = useSelector(selectUserRoles);
    const currentRole = roles.find(x => user?.roleId === x.roleId);
    const isUpdating = useSelector(selectUsersUpdatingById)[userId];
    const history = useHistory<LegacyRouteState>();

    useEffect(() => {
        if (!currentRole) {
            dispatch(requestCompanyRoles.init(user?.companyId ?? ''));
        }
    }, [dispatch, currentRole, user?.companyId]);

    useEffect(() => {
        dispatch(requestUserInfos([userId]));
    }, [dispatch, userId]);

    const formData = useMemo(() => {
        return user ? createFormData(user) : null;
    }, [user]);

    const submitHandler = useCallback((data: UserEditDTO) => {
        const payload = {
            id: userId,
            ...data,
            isDeleted: user?.isDeleted,
            meta: user?.meta,
            phoneNumber: user?.phoneNumber ?? '',
        };
        dispatch(requestUserUpdate.init(payload));
    }, [dispatch, user?.isDeleted, user?.meta, user?.phoneNumber, userId]);

    const navigateLegacyUserForm = useCallback(() => {
        history.push(
            generatePath(Routes.EditUserLegacy, { userId }),
            { params: { id: userId } },
        );
    }, [history, userId]);

    if (!formData) {
        return <PageLoader />;
    }

    return (
        <FormPageView
            title={<Trans>Edit User</Trans>}
            actionBar={
                <Box>
                    <EmbeddedSubRoutesTopMenu
                        apps={adminSubRoutes}
                        path={Routes.Admin}
                        currentAppName={UsersBetaAppName}
                    />
                    <FlexBox justifyContent='space-between' marginTop='5px'>
                        <BackButton><Trans>Back to Users</Trans></BackButton>
                        <NavButton onClick={navigateLegacyUserForm}><Trans>Edit User Rights</Trans></NavButton>
                    </FlexBox>
                </Box>
            }
            actionbarTopmost={true}
        >
            <UserEditForm
                initialValues={formData}
                isInProgress={isUpdating}
                onSubmit={submitHandler}
            />
            <UserStatusChangeConfirmation/>
        </FormPageView>
    );
}
