import { TaskTemplateDetailsType } from "api/task/taskTemplate/taskTemplateModel";
import { createSingleAction } from "utils/store/actionUtils";
import { ModalEditionTemplate } from "./taskTemplateModel";

export const CHECK_LOCATIONS_TEMPLATE = 'task/template/CHECK_LOCATIONS_TEMPLATE';
export const checkLocationsTemplate = createSingleAction<TaskTemplateDetailsType, typeof CHECK_LOCATIONS_TEMPLATE>(
  CHECK_LOCATIONS_TEMPLATE,
);

export const SHOW_MODAL_EDIT_TEMPLATE = 'task/template/SHOW_MODAL_EDIT_TEMPLATE';
export const setShowModalEditTemplate = createSingleAction<ModalEditionTemplate, typeof SHOW_MODAL_EDIT_TEMPLATE>(
  SHOW_MODAL_EDIT_TEMPLATE,
);

export const CONFIRMATION_EDIT_TEMPLATE = 'task/template/CONFIRMATION_EDIT_TEMPLATE';
export const confirmationEditTemplate = createSingleAction<boolean, typeof CONFIRMATION_EDIT_TEMPLATE>(
  CONFIRMATION_EDIT_TEMPLATE,
);

export type TaskTemplateActions =
    | ReturnType<typeof checkLocationsTemplate>
    | ReturnType<typeof setShowModalEditTemplate>
    | ReturnType<typeof confirmationEditTemplate>;
    