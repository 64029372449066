import { useMemo } from "react";
import { ChatContextValue } from "stream-chat-react";
import { getDirectMessageUserId } from "./useDirectMessageUser";
import i18n from "i18n";

export function useDirectMessageHeadingUser({ client, channel }: ChatContextValue) {
    return useMemo(() => {
        const userId = getDirectMessageUserId(client, channel);
        const user = userId ? channel?.state.members[userId].user : null;

        return {
            userId,
            name: user?.name ?? `${i18n.t('Unknown User')}`,
            isOnline: user?.online ?? false,
            image: (user?.image as unknown as string) ?? undefined,
        };
    }, [client, channel]);
}
