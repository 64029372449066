import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';

import {
    requestCreateTask,
    requestTaskChangeStatus,
    requestTaskDelete,
    requestTaskDetails,
    requestTasksGet,
    requestTaskUpdate,
    requestUpdateChecklistItem,
    TaskActions,
} from 'api/task/taskActions';
import { TaskDetailsType, TaskType } from 'api/task/taskModel';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { ItemsById } from 'model/entity';
import { taskListComponentReducer } from 'store/modules/task/taskListComponent/taskListComponentReducer';
import { storeTaskReducer } from 'store/modules/task/storeTask/storeTaskReducer';
import { parentTaskReducer } from './parentTask/parentTaskReducer';
import { taskCommonReducer } from './taskCommon/taskCommonReducer';
import { taskReviewReducer } from './taskReview/taskReviewReducer';
import { statisticsReducer } from './statistics/statisticsReducer';
import { prototypesReducer } from './prototypes/prototypesReducer';
import { storeTasksModalReducer } from './storeTasksModal/storeTasksModalReducer';
import { scheduledTaskReducer } from './scheduledTask/scheduledTaskReducer';
import { taskTagReducer } from './taskTag/taskTagReducer';
import { watcherReducer } from './watcher/watcherReducer';
import { taskTemplateReducer } from './taskTemplate/taskTemplateReducer';
import { taskFilterReducer } from './taskFilter/taskFilterReducer';
import { taskGalleryReducer } from './taskGallery/taskGalleryReducer';
import { automationTaskReducer } from './automationTask/automationTaskReducer';
import { calendarReducer } from './calendarTask/calendarTaskReducer';
import { taskConversationReducer } from './taskConversation/taskConversationReducer';
import { editTaskCreatorReducer } from './editTaskCreator/editTaskCreatorReducer';
import { tasksBulkReducer } from './TasksBulk/tasksBulkReducer';
import { editTaskReducer } from './editTask/editTaskReducer';
import { calendarFilterReducer } from './calendarFilter/calendarFilterTaskReducer';

const initialState = {
    taskCreating: false,
    tasksFetching: true,
    taskDetailsFetching: true,
    taskUpdating: false,
    tasks: {},
    taskDetails: null,
    taskChanges: {},
    taskStatusUpdating: false,
    tasksOrder: [],
    pendingChecklistItems: [],
};

function updateTask(state: ItemsById<TaskType>, id: string, updatedPart: Partial<TaskType>): ItemsById<TaskType> {
    const stateItem = state[id];

    if (stateItem) {
        return {
            ...state,
            [id]: { ...stateItem, ...updatedPart },
        };
    }

    return state;
}

function tasksById(state: ItemsById<TaskType> = initialState.tasks, action: TaskActions): ItemsById<TaskType> {
    switch (action.type) {
        case requestTasksGet.successType:
            return itemsByIds(action.payload);
        case requestTaskChangeStatus.successType:
            return updateTask(state, action.payload.task.id, action.payload.task);
        case requestTaskDelete.errorType:
        case requestTaskChangeStatus.errorType:
            return updateTask(state, action.payload.id, { updating: false });
        case requestTaskDelete.initType:
        case requestTaskChangeStatus.initType:
            return updateTask(state, action.payload.id, { updating: true });
        case requestTaskDelete.successType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload.id]: deletedTask, ...tasks } = state;
            return tasks;
        }
        default:
            return state;
    }
}

function tasksOrder(state: Array<string> = initialState.tasksOrder, action: TaskActions): Array<string> {
    switch (action.type) {
        case requestTasksGet.successType:
            return action.payload.map(({ id }) => id);
        case requestTaskDelete.successType:
            return state.filter(id => id !== action.payload.id);
        default:
            return state;
    }
}

function updateTaskChecklist(
    state: TaskDetailsType | null,
    itemId: string,
    isCompleted: boolean,
): TaskDetailsType | null {
    if (!state) {
        return state;
    }
    return {
        ...state, checklist: state.checklist.map(item => {
            if (item.id !== itemId) {
                return item;
            }
            return {
                ...item,
                isCompleted,
            };
        }),
    };
}

function taskDetails(
    state: TaskDetailsType | null = initialState.taskDetails,
    action: TaskActions,
): TaskDetailsType | null {
    switch (action.type) {
        case requestTaskDetails.successType:
            return action.payload;
        case requestTaskDetails.initType:
        case requestTaskDetails.errorType:
            return null;
        case requestTaskChangeStatus.successType:
            return state ? { ...state, status: action.payload.task.status } : state;
        case requestUpdateChecklistItem.successType:
            return updateTaskChecklist(
                state,
                action.payload.checkListItemId,
                action.payload.isCompleted,
            );
        case requestTaskUpdate.successType:
            return action.payload.task;
        default:
            return state;
    }
}

function taskCreating(state = initialState.taskCreating, action: TaskActions): boolean {
    switch (action.type) {
        case requestCreateTask.initType:
            return true;
        case requestCreateTask.errorType:
        case requestCreateTask.successType:
            return false;
        default:
            return state;
    }
}

function tasksFetching(state = initialState.tasksFetching, action: TaskActions): boolean {
    switch (action.type) {
        case requestTasksGet.initType:
            return true;
        case requestTasksGet.successType:
        case requestTasksGet.errorType:
            return false;
        default:
            return state;
    }
}

function taskDetailsFetching(state = initialState.taskDetailsFetching, action: TaskActions): boolean {
    switch (action.type) {
        case requestTaskDetails.initType:
            return true;
        case requestTaskDetails.successType:
        case requestTaskDetails.errorType:
            return false;
        default:
            return state;
    }
}

function taskUpdating(state = initialState.taskUpdating, action: TaskActions): boolean {
    switch (action.type) {
        case requestTaskUpdate.initType:
            return true;
        case requestTaskUpdate.successType:
        case requestTaskUpdate.errorType:
            return false;
        default:
            return state;
    }
}

function taskStatusUpdating(state = initialState.taskStatusUpdating, action: TaskActions): boolean {
    switch (action.type) {
        case requestTaskChangeStatus.initType:
            return true;
        case requestTaskChangeStatus.successType:
        case requestTaskChangeStatus.errorType:
            return false;
        default:
            return state;
    }
}

function pendingChecklistItems(
    state: string[] = initialState.pendingChecklistItems,
    action: TaskActions,
): Array<string> {
    switch (action.type) {
        case requestUpdateChecklistItem.initType:
            return uniq(state.concat(action.payload.checkListItemId));
        case requestUpdateChecklistItem.successType:
        case requestUpdateChecklistItem.errorType:
            return state.filter(id => id !== action.payload.checkListItemId);
        default:
            return state;
    }
}

export const task = combineReducers({
    tasksById,
    tasksOrder,
    taskDetails,
    taskCreating,
    tasksFetching,
    taskDetailsFetching,
    taskUpdating,
    taskStatusUpdating,
    pendingChecklistItems,
    taskListComponent: taskListComponentReducer,
    storeTask: storeTaskReducer,
    storeTasksModal: storeTasksModalReducer,
    parentTask: parentTaskReducer,
    common: taskCommonReducer,
    taskReview: taskReviewReducer,
    statistics: statisticsReducer,
    prototypes: prototypesReducer,
    scheduledTasks: scheduledTaskReducer,
    taskTags: taskTagReducer,
    watchers: watcherReducer,
    taskTemplates: taskTemplateReducer,
    taskFilter: taskFilterReducer,
    taskGallery: taskGalleryReducer,
    automationTask: automationTaskReducer,
    calendar: calendarReducer,
    taskConversation: taskConversationReducer,
    editTaskCreator: editTaskCreatorReducer,
    tasksBulk: tasksBulkReducer,
    ediTask: editTaskReducer,
    calendarFilter: calendarFilterReducer
});
