import React, { PropsWithChildren } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';

type AddClickHanlder = (
    ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) |
    (() => void)
);

export interface ISidebarSectionProps {
    title: string | React.ReactNode;
    onAddClick: AddClickHanlder;
    allowAdd: boolean;
}

const useStyles = makeStyles(() => ({
    sectionRoot: {
        boxSizing: 'border-box',
        padding: '40px 0 24px',
        width: '100%',
        height: 'auto',
    },
    sectionHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 25px 0 40px',
    },
    sectionTitle: {},
}));

export function SidebarSection({
    title,
    onAddClick,
    children,
    allowAdd,
}: PropsWithChildren<ISidebarSectionProps>) {
    const classes = useStyles();
    const visibility = allowAdd ? 'visible' : 'hidden';
    return (
        <Box className={classes.sectionRoot}>
            <Box className={classes.sectionHeading}>
                <Typography className={classes.sectionTitle}>
                    {title}
                </Typography>
                <IconButton onClick={onAddClick} style={{ visibility }}>
                    <AddIcon/>
                </IconButton>
            </Box>

            <Box>
                {children}
            </Box>
        </Box>
    );
}
