import { IEntityError } from 'model/entity';
import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import {
    SearchStoresRequest,
    Stores
} from './storeModel';

const REQUEST_SEARCH_STORES_GET = 'request/store/SEARCH_STORES_GET';
const RESPONSE_SEARCH_STORES_GET_SUCCESS = 'response/store/SEARCH_STORES_GET_SUCCESS';
const RESPONSE_SEARCH_STORES_GET_ERROR = 'response/store/SEARCH_STORES_GET_ERROR';

export const requestSearchStores = (
    createApiActions<SearchStoresRequest, Stores, IEntityError,
        typeof REQUEST_SEARCH_STORES_GET,
        typeof RESPONSE_SEARCH_STORES_GET_SUCCESS,
        typeof RESPONSE_SEARCH_STORES_GET_ERROR
    >(
        REQUEST_SEARCH_STORES_GET,
        RESPONSE_SEARCH_STORES_GET_SUCCESS,
        RESPONSE_SEARCH_STORES_GET_ERROR,
    )
);
export const SEARCH_RESET = 'store/SEARCH_RESET';
export const resetSearchStores = createSingleAction<void, typeof SEARCH_RESET>(
    SEARCH_RESET,
);

export type SearchStoresActions = 
        ActionsReturnTypes<typeof requestSearchStores>
    | ReturnType<typeof resetSearchStores>;
