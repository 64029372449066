import React from 'react'
import { TasksFilterComponents, TasksFilterType } from './TypeTaskFilterMap'

type TaskFilterContainerProps = {
  taskFiltertype: TasksFilterType;
  locationId: string;
  children?: React.ReactNode;
}

export const TaskFilterContainer: React.FC<TaskFilterContainerProps> = ({ taskFiltertype, locationId, children }) => {
  const TasksFilterTypeShow = TasksFilterComponents[taskFiltertype];

  return (
    <TasksFilterTypeShow locationId={locationId} >
      {children}
    </TasksFilterTypeShow>
  )
}
