import { Box, Button, Typography } from '@material-ui/core';
import { SingleSelect } from 'components/basicInputs/selectInput/SingleSelect';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { sidekickSchema } from './model';

export const SettingSidekick = () => {
    const initialFormValues = useMemo(
        () => ({
            formality: 'casual-id',
            personality: 'humorous-id',
            fallbackMechanism: 'decline-to-answer-id',
        }),
        []
    );

    return (
        <Box mt={3}>
            <Formik
                initialValues={initialFormValues}
                validationSchema={sidekickSchema}
                onSubmit={() => {}}
            >
                {(props) => <SidekickForm />}
            </Formik>
            <Box
                mt={3}
                maxWidth={300}
                display='flex'
                flexDirection='column'
                style={{ gap: 8 }}
            >
                <Button variant='outlined' color='primary' disabled>
                    <Trans>Data Download</Trans>
                </Button>
            </Box>
        </Box>
    );
};

const SidekickForm = () => (
    <form
        onSubmit={() => {}}
        data-testid='sidekickFormorm'
        style={{ maxWidth: 500 }}
    >
        <Typography variant='h5'>
            <Trans>Tone & style</Trans>
        </Typography>
        <Box mt={1} display='flex' flexDirection='column' style={{ gap: 8 }}>
            <SingleSelect
                options={[
                    {
                        value: 'casual-id',
                        title: 'Casual',
                    },
                ]}
                unsetValue={''}
                onValueChange={() => {}}
                label={`${t('Formality')}`}
                keyProp='value'
                valueProp='title'
                name='formality'
                disabled
            />
            <SingleSelect
                options={[
                    {
                        value: 'humorous-id',
                        title: 'Humorous',
                    },
                ]}
                unsetValue={''}
                onValueChange={() => {}}
                label={`${t('Personality')}`}
                keyProp='value'
                valueProp='title'
                name='personality'
                disabled
            />
        </Box>
        <Typography variant='h5' style={{ marginTop: 8 }}>
            <Trans>User Interaction</Trans>
        </Typography>
        <Box mt={1} display='flex' flexDirection='column' style={{ gap: 8 }}>
            <SingleSelect
                options={[
                    {
                        value: 'decline-to-answer-id',
                        title: 'Decline to Answer',
                    },
                ]}
                unsetValue={''}
                onValueChange={() => {}}
                label={`${t('Fallback Mechanism')}`}
                keyProp='value'
                valueProp='title'
                name='fallbackMechanism'
                disabled
            />
        </Box>
    </form>
);
