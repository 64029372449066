import { UserEditDTO, UserProfileDTO } from "api/user/userModel";
import { t } from "i18next";
import * as yup from "yup";

export type UserFormData = Omit<UserEditDTO, 'id'>

yup.addMethod(yup.mixed, 'optional', function optional() {
    return this.transform((value) => {
        return !value ? undefined : value;
    });
});

const phoneNumberSchema = yup.string().optional()
    .matches(/^\+\d{0,15}$/, `${t('Invalid phone number format')}`);

const userProfileFields = {
    firstName: yup.string()
        .required(`${t('First Name is required')}`),
    lastName: yup.string()
        .required(`${t('Last Name is required')}`),
    email: yup.string()
        .email()
        .required(`${t('Email is required')}`),
    phoneNumber: phoneNumberSchema,
    language: yup.string()
        .required(`${t('Language is required')}`),
};

export const userProfileSchema = yup.object()
    .shape<UserProfileDTO>(userProfileFields)
    .required();

export const userEditSchema = yup.object().shape<UserFormData>({
    ...userProfileFields,
    employeeTitle: yup.string(),
    companyLocationId: yup.string()
        .nullable()
        .required(`${t('Company Location is required')}`),
    roleId: yup.string()
        .nullable()
        .required(`${t('User Role is required')}`),
});

export const BIRTH_DATE_FORMAT = 'YYYY-MM-DD';
