import React, { useEffect } from 'react';
import { Sidebar } from 'components/sidebar/Sidebar';
import { IChannelDetailsProps } from './model';
import { getChannelName } from '../../../../../helpers';
import { ChannelDetailsContent } from './ChannelDetailsContent';
import { useDispatch } from 'react-redux';
import { requestChatRoles } from '../../../../../../../../api/getStream/getStreamActions';

export function ChannelDetailsSidebar({
    open = false,
    onClose,
    channel,
}: IChannelDetailsProps) {
    const title = getChannelName(channel);
    const dispatch = useDispatch();
    useEffect(() => {
        if (open) {
            dispatch(requestChatRoles.init(channel?.id ?? ''));
        }
    }, [dispatch, channel, open]);
    return (
        <Sidebar
            onClose={onClose}
            open={open}
            title={title}
        >
            <ChannelDetailsContent channel={channel}/>
        </Sidebar>
    );
}
