import { makeStyles, Theme } from "@material-ui/core";
import { Breakpoints, UtilColors } from "utils/styles/constants";

export const cropperImageStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '95%',
        margin: '0 auto',
        maxWidth: theme.spacing(103),
        paddingTop: theme.spacing(3),

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            paddingTop: theme.spacing(1.5),
        },
    },
    circular: {
        float: 'right',
    },
    actionBarWrapper: {
        paddingBottom: theme.spacing(3),
        margin: '15px',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            paddingBottom: theme.spacing(1.5),
        },
    },
    childrenWrapper: {
        boxSizing: 'border-box',
        borderRadius: theme.spacing(2.5),
        padding: theme.spacing(4.75, 12),
        marginBottom: theme.spacing(9),
        backgroundColor: UtilColors.White,

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            padding: theme.spacing(4.75, 4),
        },

        [theme.breakpoints.down(Breakpoints.MobileMedium)]: {
            padding: theme.spacing(3, 1.5),
        },
    },
    pageTitle: {
        marginBottom: theme.spacing(3),
    },
    boxInner1: {
        maxWidth: '500px',
        overflowX: 'hidden',
        minWidth: '25vw',
    },
    dialogBox: {
        overflowX: 'hidden',
    },
}));