import { OptionsObject, SnackbarMessage } from 'notistack';

export interface IToastMessage {
    message: SnackbarMessage;
    options?: OptionsObject;
}

export enum ToastVariant {
    Default = 'default',
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}
