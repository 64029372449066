import { useSelector } from 'react-redux';
import { nonEmpty } from 'utils/helpers/collections';
import { IAutomationTaskGridRowData } from './model';
import { selectAutomationTasksById, selectAutomationTasksOrder } from 'store/modules/task/automationTask/automationTaskSelectors';

export function useAutomationTasksGridData() {
    const automationTasksOrder = useSelector(selectAutomationTasksOrder);
    const automationTasksById = useSelector(selectAutomationTasksById);

    const data: Array<IAutomationTaskGridRowData> = (
        automationTasksOrder
            .map(id => automationTasksById[id])
            .filter(nonEmpty)
            .map(item => ({
                id: item.id,
                title: item.title,
                dueDate: item.dueDate,
                completionState: item.displayedStatus,
                prototypeId: item?.prototypeId || undefined
            }))
    );

    return data;
}
