import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { Formik } from "formik";
import { payloadLaunchsReorder } from "./utils";
import { Launchs } from 'api/launch/launchModel';
import { DragLaunch } from './DragLaunch';
import { AutoSaveFormik } from 'components/autoSaveFormik/autoSaveFormik';
import { useActions } from 'utils/store/useActions';
import { requestLaunchReorder } from 'api/launch/launchActions';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/modules/auth/authSelectors';
import { ResetLaunchReorderForm } from './ResetForm';

const FieldNameLaunchs = 'launchs';
const LaunchReorderForm = ({ canCreateDelete }: { canCreateDelete: boolean}) => (
    <Box>
        <DragLaunch
            fieldName={FieldNameLaunchs}
            canCreateDelete={canCreateDelete}
        />
    </Box>
)

export type LaunchReorderProps = {
    launchList: Launchs;
    canCreateDelete: boolean;
};

export const LaunchReorder: React.FC<LaunchReorderProps> = ({ launchList, canCreateDelete }) => {
    const actions = useActions({ updateReorder: requestLaunchReorder.init });
    const currentUser = useSelector(selectUser);
    const companyId = currentUser?.companyId;
    const initialFormValues = useMemo(
        () => ({
            launchs: launchList,
        }),
        [launchList]
    );

    const handleSubmit = useCallback((values: {launchs: Launchs})=> {
        actions.current.updateReorder({companyId: companyId as string, launchs: payloadLaunchsReorder(values.launchs)})
    }, [companyId, actions]);

    if(!companyId) return <></>;

    return (
        <Formik
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {() => (
                <form>
                    <AutoSaveFormik />
                    <LaunchReorderForm canCreateDelete={canCreateDelete}/>
                    <ResetLaunchReorderForm />
                </form>
            )}
        </Formik>
    );
};
