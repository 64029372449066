import React, { useCallback } from 'react';
import { CellProps } from 'react-table';
import { UserDTO } from 'api/user/userModel';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { IAction } from 'components/button/actionButton/model';
import { Person } from '@material-ui/icons';
import { useActions } from 'utils/store/useActions';
import { authLoginAs } from 'api/auth/authActions';
import { t } from 'i18next';

export const ActionsCell: React.FC<CellProps<UserDTO, string>> = props => {
    const { original: user } = props.row;
    const actions = useActions({
        onLoginAs: authLoginAs,
    });

    const handleLoginAs = useCallback(() => {
        actions.current.onLoginAs(user.id);
    }, [actions, user]);

    const items: Array<IAction<string>> = [
        {
            name: `${t('Login As')}`,
            action: handleLoginAs,
            isAvailable: true,
            Icon: Person,
        },
    ];

    return (
        <ActionButton
            autoHide
            disabled={false}
            entity={user.id}
            width={'auto'}
            items={items}
            marginLeft={-15}
        />
    );
};
