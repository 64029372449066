import { Box } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { TasksTemplate } from './TasksTemplate';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { BulkTasks } from '../bulkTasks/BulkTasks';

export const ContainerTasksTemplate = ({ isEditTemplate = false }) => {
  const isTasksTemplateEnabled = useFeatureState(FeatureFlags.TasksTemplate);
  const classes = useFormStyles();

  if(!isTasksTemplateEnabled || isEditTemplate) return <></>;

  return (
    <Box className={classes.titleInput}>
      <Box className={classes.containerTemplateBulk}>
        <Box className={classes.template}>
          <TasksTemplate />
        </Box>
        <BulkTasks />
      </Box>
    </Box>
  )
}
