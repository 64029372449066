import { generatePath, useParams } from 'react-router';
import { PageLoader } from 'components/router/PageLoader';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
    selectTaskPrototypesById,
    selectTaskPrototypesLoadingById,
    selectTaskPrototypesUpdatingById,
} from 'store/modules/task/prototypes/prototypesSelectors';
import { useActions } from 'utils/store/useActions';
import { getTaskPrototype } from 'api/task/prototype/prototypeActions';
import { useCallback, useEffect, useMemo } from 'react';
import { TaskPrototypeUpdate } from 'api/task/prototype/prototypeModel';
import { Formik } from 'formik';
import { createLocationCloneSchema, editCloneTaskValidationSchema, editTaskValidationSchema } from './validation';
import { EditTaskForm } from './EditTaskForm';
import { Routes } from 'components/router/model';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import { requestUserList } from 'api/user/userActions';
import { useQueryParams } from 'utils/hooks';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { selectTaskCreating } from 'store/modules/task/taskSelectors';
import { getPendingFiles } from 'store/modules/files/filesSelectors';
import { validateForms } from '../utils/validationPenddingFiles';
import { Trans } from 'react-i18next';
import { TaskConversation } from '../taskConversation/TaskConversation';
import { TaskCreator } from '../common/taskCreator/TaskCreator';
import { handleSaveTask } from 'store/modules/task/editTask/editTaskActions';
import { selectTaskTemplateFetching } from 'store/modules/task/taskTemplate/taskTemplateSelectors';
import { selectHierarchy } from 'store/modules/hierarchy/hierarchySelectors';
import { useLocationTreeUser } from '../locations/hook/useLocationTreeUser';

export function EditTaskPage() {
    const { prototypeId } = useParams<{ prototypeId: string }>();
    const taskPrototype = useSelector(selectTaskPrototypesById)[prototypeId];
    const isLoading = useSelector(selectTaskPrototypesLoadingById)[prototypeId];
    const isUpdating = useSelector(selectTaskPrototypesUpdatingById)[prototypeId];
    const taskCreating = useSelector(selectTaskCreating);
    const templateCreating = useSelector(selectTaskTemplateFetching);
    const pendingFiles = useSelector(getPendingFiles);
    const actions = useActions({
        getTaskPrototype: getTaskPrototype.init,
        saveEditionTask: handleSaveTask
    });
    const hierarchy = useSelector(selectHierarchy);
    const isPublishDateEnabled = useFeatureState(FeatureFlags.TaskCreatePublishDate);
    const { isClone } = useQueryParams<{ isClone: string }>();
    const locationsTreeTemplate = useLocationTreeUser(prototypeId);
    
    const onSubmit = useCallback((values: TaskPrototypeUpdate) => {
        actions.current.saveEditionTask(values)
    }, [actions]);

    const actionsUsers = useActions({
        getUsers: requestUserList.init,
    });
    
    useEffect(() => {
        actionsUsers.current.getUsers({"Force": true});
    }, [actionsUsers]);

    useEffect(() => {
        actions.current.getTaskPrototype({prototypeId, isClone: !!isClone});
    }, [actions, prototypeId, isClone]);
    
    const initValue = useMemo(() => {
        if(!taskPrototype) return undefined;
        return isClone ? {...taskPrototype, locationsFromTemplate: locationsTreeTemplate} : taskPrototype;
    }, [taskPrototype, isClone, locationsTreeTemplate]);

    const fullSchema = useMemo(() => {
        const locationSchema = isClone ? createLocationCloneSchema(hierarchy) : {};
        const formSchema = isPublishDateEnabled && isClone ? editCloneTaskValidationSchema : {};

        return yup.object({
            ...editTaskValidationSchema,
            ...formSchema,
            ...locationSchema,
        });
    }, [hierarchy, isClone, isPublishDateEnabled]);


    if (!initValue || isLoading) {
        return <PageLoader />;
    }

    return (
        <FormPageView
            actionBar={<BackButton route={generatePath(Routes.TaskList)}><Trans>Back to Tasks</Trans></BackButton>}
        >
            <Formik
                initialValues={initValue}
                validationSchema={fullSchema}
                onSubmit={onSubmit}
                validate={() => validateForms(pendingFiles.length)}
            >
                {props => (
                    <EditTaskForm
                        isUpdating={!!isUpdating || taskCreating || templateCreating}
                        handleSubmit={props.handleSubmit}
                        isClone={!!isClone}
                    />
                )}
            </Formik>
            {!isClone &&
                <> 
                    <TaskCreator />
                    <TaskConversation />
                </>
            }
        </FormPageView>
    );
}
