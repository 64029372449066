export class SerializableError {
    public message: string;
    public stack?: string;

    constructor(errorMessage: string | Error) {
        this.message = typeof errorMessage === 'object' ? errorMessage.message : errorMessage;
        const err = new Error();
        this.stack = err.stack;
    }
}
