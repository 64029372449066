import { Box, styled, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { orangeColor } from 'utils/styles/constants';
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { Trans } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const wrapExpire = Icon => props => (
    <Tooltip title={<Trans>Task Expires at Due Date</Trans>}>
        <Box {...props} display="flex" alignItems="center">
            <Icon size={props.size}/>
        </Box>
    </Tooltip>
);
const HourGalassIcon = ({ size = "xs"}: { size: SizeProp})=> <FontAwesomeIcon size={size} icon={faHourglassHalf} color={orangeColor} />

export const ExpireIcon = styled(wrapExpire(HourGalassIcon))({
    marginLeft: '9px',
    height: '14px',
    width: '14px',
});

export const ExpireHeaderIcon = styled(wrapExpire(HourGalassIcon))({
    height: '24px',
    width: '24px',
    marginLeft: '10px',
});
