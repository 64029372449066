import React, { useCallback } from 'react';
import { useField } from 'formik';
import { CloseOutlined } from '@material-ui/icons';
import {
    Box,
    Checkbox, Fade, FormHelperText,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction, Switch, Tooltip, Typography,
} from '@material-ui/core';
import { CHECKLIST_ITEM_MAX_TITLE_LENGTH, checklistItemNameSchema } from 'modules/task/common/form/validation';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { InlineEdit } from 'components/basicInputs/inlineEdit/InlineEdit';
import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import { AddChecklistItemControl } from 'modules/task/common/checklist/AddChecklistItemControl';
import { hasFormikError } from 'components/basicInputs/utils';
import { IChecklistItem } from 'api/task/taskModel';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SubjectIcon from '@material-ui/icons/Subject';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { DragCheckList } from '../dragList/DragCheckList';

export enum typeItemRequiresEnum {
    RequiresAttachment = 'requiresAttachment',
    RequiresCustomResponse = 'requiresCustomResponse'
};

export type TaskChecklistProps = {
    fieldName: string;
    disabled?: boolean;
}

export function TaskChecklist({ fieldName, disabled }: TaskChecklistProps) {
    const isCustomItemEnabled = useFeatureState(FeatureFlags.tasksCustomChecklistOptions);
    const isTaskChecklistDragDropEnabled = useFeatureState(FeatureFlags.TaskChecklistDragDrop);
    const classes = useFormStyles();
    const inputClasses = useInputsStyles([]);
    const [field, meta] = useField<Array<IChecklistItem>>(fieldName);
    const { value, onChange } = field;

    const onItemAdd = useCallback((itemName: string) => {
        onChange({
            target: {
                name: fieldName,
                value: value.concat({ 
                    name: itemName, 
                    requiresAttachment: false, 
                    requiresCustomResponse: false,
                })
            }
        });
    }, [onChange, fieldName, value]);

    const onItemRemove = useCallback((itemIndex: number) => {
        onChange({
            target: {
                name: fieldName,
                value: value.slice(0, itemIndex).concat(value.slice(itemIndex + 1, value.length)),
            }
        });
    }, [onChange, fieldName, value]);

    const onItemReplace = useCallback((index: number, newValue: string) => {
        const updatedItem: IChecklistItem = {
            ...value[index],
            name: newValue,
        };
        onChange({
            target: {
                name: fieldName,
                value: value.slice(0, index)
                    .concat(updatedItem)
                    .concat(value.slice(index + 1, value.length)),
            }
        });
    }, [fieldName, value, onChange]);

    const onItemPhotoRequiredChange = useCallback((index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { target: { checked } } = event;

        const updatedItem: IChecklistItem = {
            ...value[index],
            requiresAttachment: checked, 
        };

        onChange({
            target: {
                name: fieldName,
                value: value.slice(0, index)
                    .concat(updatedItem)
                    .concat(value.slice(index + 1, value.length)),
            }
        });
    }, [fieldName, value, onChange]);

    const onItemRequiredChange = useCallback((index: number, typeItem: typeItemRequiresEnum) => {
        const { requiresAttachment, requiresCustomResponse } = value[index];
        let newRequiresAttachment: boolean = false;
        let newRequiresCustomResponse: boolean = false;

        switch(typeItem) {
            case typeItemRequiresEnum.RequiresAttachment:
                newRequiresAttachment = !requiresAttachment;
                newRequiresCustomResponse = !newRequiresAttachment && Boolean(requiresCustomResponse);
                break;
            case typeItemRequiresEnum.RequiresCustomResponse:
                newRequiresCustomResponse = !requiresCustomResponse;
                newRequiresAttachment = !newRequiresCustomResponse && requiresAttachment;
                break;
            default:
                break;
        }

        const updatedItem: IChecklistItem = {
            ...value[index],
            requiresAttachment: newRequiresAttachment,
            requiresCustomResponse: newRequiresCustomResponse
        };

        onChange({
            target: {
                name: fieldName,
                value: value.slice(0, index)
                    .concat(updatedItem)
                    .concat(value.slice(index + 1, value.length)),
            }
        });
    }, [fieldName, value, onChange]);

    const error = Array.isArray(meta.error) ? (meta.error[0] as IChecklistItem).name : meta.error;

    return (
        <Box className={classes.checklistWrapper}>
            <label className={inputClasses.label}>
                <Trans>Checklist</Trans>
            </label>
            {hasFormikError(meta) && (
                <FormHelperText error>
                    {error}
                </FormHelperText>
            )}
            {isTaskChecklistDragDropEnabled ? 
                <DragCheckList
                    fieldName={fieldName}
                    onItemRequiredChange={onItemRequiredChange}
                    onItemPhotoRequiredChange={onItemPhotoRequiredChange}
                    disabled={disabled}
                />
                :<List>
                    {value.map((item, index) => (
                        <ListItem
                            className={classes.listItem}
                            key={item.name + index}
                            classes={{ container: classes.listItemRoot }}
                        >
                            <Checkbox className={classes.iconMarginRight} disabled />
                            <InlineEdit
                                disabled={disabled}
                                value={item.name}
                                title={item.name}
                                typographyClass={classes.subtaskItem}
                                onChange={onItemReplace.bind(null, index)}
                                validation={checklistItemNameSchema}
                                showCounter
                                maxLength={CHECKLIST_ITEM_MAX_TITLE_LENGTH}
                            />
                            <ListItemSecondaryAction className={classes.listItemSecondaryActions}>
                                <Box className={classes.checkboxActions}>
                                    {isCustomItemEnabled ?
                                        <Box display="flex">
                                            <Tooltip title={<Trans>Require Text Submission for Approval</Trans>} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                                                <IconButton aria-label="Require Text Submission for Approval" onClick={()=>onItemRequiredChange(index,typeItemRequiresEnum.RequiresCustomResponse)}
                                                color={value[index].requiresCustomResponse ?"primary" :"default"}>
                                                    <SubjectIcon fontSize="medium"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={<Trans>Require File Submission for Approval</Trans>} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                                                <IconButton aria-label={`${t('Require File Submission for Approval')}`} onClick={()=>onItemRequiredChange(index,typeItemRequiresEnum.RequiresAttachment)} 
                                                    color={value[index].requiresAttachment ?"primary" :"default"}>
                                                    <AttachFileIcon fontSize="medium" />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        :<>
                                            <Switch
                                                disabled={disabled}
                                                checked={item.requiresAttachment}
                                                color="primary"
                                                onChange={onItemPhotoRequiredChange.bind(null, index)}
                                            />
                                            <Typography variant="caption">
                                                <Trans>File</Trans>
                                            </Typography>
                                    </>}
                                    {!disabled && (
                                        <IconButton
                                            edge="end"
                                            aria-label="remove"
                                            onClick={onItemRemove.bind(null, index)}
                                        >
                                            <CloseOutlined className={classes.listRemoveIcon} />
                                        </IconButton>
                                    )}
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            }
            {!disabled && (
                <AddChecklistItemControl onItemAdd={onItemAdd} />
            )}
        </Box>
    );
}
