import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { IResponseErrorPayload } from 'model/error';
import { FeatureFlagState } from './model';

const REQUEST_FEATURE_FLAGS = 'feature-flags';

export const requestFeatureFlags = createApiActionsByName<
    void, FeatureFlagState[], IResponseErrorPayload, typeof REQUEST_FEATURE_FLAGS
>(
    REQUEST_FEATURE_FLAGS
);

export type FeatureFlagActions = ActionsReturnTypes<typeof requestFeatureFlags>;
