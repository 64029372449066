import React from 'react';
import clsx from 'clsx';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { AttachmentsPanel } from '../components/AttachmentsPanel';
import { useButtonStyles } from 'utils/styles/buttons';
import { useSelector } from 'react-redux';
import { selectTaskDetails } from 'store/modules/task/taskSelectors';
import { AttachmentsMode } from './model';
import { Trans } from 'react-i18next';

export const useAttachmentsViewStyles = makeStyles((theme: Theme) => ({
    buttons: {
        width: theme.spacing(20),
        whiteSpace: 'nowrap',
        padding: theme.spacing(1, 5),

        '& svg:not(.MuiCircularProgress-svg)': {
            marginRight: theme.spacing(1),
        },
    },
    labels: {
        fontWeight: 'bolder',
        fontSize: theme.spacing(1.75),
    },
}));

export function AttachmentsView() {
    const attachmentsCount = useSelector(selectTaskDetails)?.attachments?.length ?? 0;
    const { isOpened, onClose, onOpen } = useOpenedState();
    const classes = useAttachmentsViewStyles();
    const buttonClasses = useButtonStyles();
    const viewAttachmentsClasses = clsx(buttonClasses.darkButton, classes.buttons);
    return (
        <>
            <Box mb={2}>
                <Typography
                    variant="caption"
                    className={classes.labels}
                >
                    <Trans>Attachments</Trans>
                </Typography>
                <Button
                    onClick={onOpen}
                    className={viewAttachmentsClasses}
                >
                    <FileCopyIcon/>
                    <Trans>View files</Trans>{Boolean(attachmentsCount) && ` (${attachmentsCount})`}
                </Button>
            </Box>

            <AttachmentsPanel
                isOpened={isOpened}
                handleClose={onClose}
                mode={AttachmentsMode.View}
            />
        </>
    );
}
