import React, { useCallback, } from 'react';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { TaskGalleryFilter, TaskGalleryModel } from 'api/task/taskGallery/taskGalleryModel';
import { selectTaskGalleryFetching, selectTaskGalleryItems } from 'store/modules/task/taskGallery/taskGallerySelectors';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/store/useActions';
import { filterTaskGallery } from 'store/modules/task/taskGallery/taskGalleryActions';
import { useTaskGalleryStyles } from './useTaskGalleryStyles';

const getOptionLabel = ({ locationName }: TaskGalleryModel) => locationName;

export const TaskStoreSelect = () => {
    const classes = useTaskGalleryStyles();
    const taskGallery = useSelector(selectTaskGalleryItems);
    const isLoading = useSelector(selectTaskGalleryFetching);
    const actions = useActions({ filterTask: filterTaskGallery });

    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        const adornment = (
            <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
            </React.Fragment>
        );
        return (
            <TextField
                {...params}
                label={<Trans>Search by Store</Trans>}
                placeholder={`${t('Enter the store name')}...`}
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: adornment,
                }}
            />
        );
    }, [isLoading]);

    const onChangeHandler = useCallback((_: React.ChangeEvent<{}>, value: TaskGalleryModel | null) => {
        actions.current.filterTask({ status: !value ? TaskGalleryFilter.Reset : TaskGalleryFilter.Active, task: value });
    }, [actions]);

    return (
        <Box className={classes.containerSelectStore}>
            <Autocomplete
                disabled={isLoading}
                autoHighlight
                options={taskGallery}
                getOptionLabel={getOptionLabel}
                renderInput={renderInput}
                noOptionsText={<Trans>No store found</Trans>}
                onChange={onChangeHandler}
                className={classes.selectStore}
                size="small"
            />
        </Box>
    );
};
