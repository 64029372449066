import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useTaskHeaderDetailsStyles } from 'modules/task/taskList/components/TaskHeaderDetails/useTaskHeaderDetailsStyles';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { Divider } from './Divider';
import { startCase, toLower } from 'lodash';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { Trans } from 'react-i18next';

type DisplayStatusProps = {
    taskStatus: DisplayedTaskStatus;
    showDivider?: boolean;
}

export const DisplayStatus = ({ taskStatus, showDivider = true }: DisplayStatusProps) => {
    const classes = useTaskHeaderDetailsStyles([]);
    const detailsClasses: Array<string> = [classes.dateDetails];
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isOverdueEnabled = isOverdueColumnsFeature?.enabled ?? false;
    const displayStatus = useMemo(() => {
        if (isOverdueEnabled) {
            return taskStatus
        } else {
            switch (taskStatus) {
                case DisplayedTaskStatus.CompletedOverdue:
                    return DisplayedTaskStatus.CompletedOverdue;
                case DisplayedTaskStatus.SubmittedOverdue:
                    return DisplayedTaskStatus.Submitted;
            }
        }
    }, [isOverdueEnabled, taskStatus]);

    switch (displayStatus) {
        case DisplayedTaskStatus.Completed:
            detailsClasses.push(classes.detailsCompleted);
            break;
        case DisplayedTaskStatus.Overdue:
            detailsClasses.push(classes.detailsOverdue);
            break;
    }

    const statusClasses = clsx({
        [classes.detailsCompleteSubmitedOverdue]:(
            displayStatus === DisplayedTaskStatus.CompletedOverdue
            || displayStatus === DisplayedTaskStatus.SubmittedOverdue
        ),
        [classes.detailsDefault]: (
            displayStatus === DisplayedTaskStatus.Default
            || displayStatus === DisplayedTaskStatus.Submitted
        ),
        [classes.detailsOverdue]: (
            displayStatus === DisplayedTaskStatus.Overdue
            || displayStatus === DisplayedTaskStatus.Declined
        ),
        [classes.detailsCompleted]: displayStatus === DisplayedTaskStatus.Completed,
        [classes.detailsExpired]: displayStatus === DisplayedTaskStatus.Expired,
    });

    return (
        <>
            <div className={statusClasses}>
            {!!displayStatus && <Trans>{startCase(toLower(displayStatus.replace('default', 'incomplete')))}</Trans> }
            </div>
            { showDivider && <Divider orientation="vertical" />}
        </>
    );
}
