import { useSelector } from 'react-redux';
import { useActions } from "utils/store/useActions";
import { useThrottledCallback } from 'utils/hooks/optimizedCallbacks';
import { useEffect, useMemo } from 'react';
import { TaskListSortBy } from 'store/modules/task/taskListComponent/taskListComponentModel';
import { ListSearchRequestBase } from "api/common/model";
import { ParentTasksSortBy } from 'api/task/parentTask/parentTaskModel';
import { StoreTasksSortBy } from 'api/task/storeTask/storeTaskModel';
import { IStoreState } from 'store/rootReducer';
import { ActionCreator } from 'redux';
import { selectTasksStatsByLocationRangeDate, selectTasksStatsByLocationTags } from 'store/modules/task/statistics/statisticsSelectors';

const columnNameToEnum: Record<string, StoreTasksSortBy | ParentTasksSortBy> = {
    'task': StoreTasksSortBy.Title,
    'due-date': StoreTasksSortBy.DueDate,
    'completion-status-store': StoreTasksSortBy.Status,
    'completion-status-parent': ParentTasksSortBy.Completed,
    'submitted-tasks': ParentTasksSortBy.Submitted,
};

type SortByOptions = Partial<Pick<ListSearchRequestBase, 'sortBy' | 'descending'>>;

function getSortByOptions(sortBy?: TaskListSortBy): SortByOptions {
    const sortByOptions: SortByOptions = {};
    if (sortBy !== 'not-set' && sortBy && columnNameToEnum[sortBy.id]) {
        sortByOptions.sortBy = columnNameToEnum[sortBy.id];
        sortByOptions.descending = sortBy.desc;
    }

    return sortByOptions;
}

type ActionCreatorWithPayload<T> = (payload: T) => { payload: T };

export function useLoad<T extends ListSearchRequestBase>(
    pageTokenSelector: (state: IStoreState) => string | null,
    taskListLoadingSelector: (state: IStoreState) => boolean,
    loadAction: ActionCreatorWithPayload<T>,
    resetAction: ActionCreator<any>,
    loadActionPayload: T,
    sortBy?: TaskListSortBy,
) {
    const nextPageToken = useSelector(pageTokenSelector);
    const taskListLoading = useSelector(taskListLoadingSelector);
    const rangeDate = useSelector(selectTasksStatsByLocationRangeDate)
    const tagsByLocation = useSelector(selectTasksStatsByLocationTags)
    const actions = useActions({ load: loadAction, reset: resetAction });
    const tags: string[] = useMemo(()=>(tagsByLocation?.length ?
        tagsByLocation
        : loadActionPayload.tags) ?? [], [tagsByLocation, loadActionPayload]);

    useEffect(() => {
        if (sortBy === 'not-set') {
            // abort request until "sort by" will not be set explicitly
            return;
        }
        actions.current.reset();
        actions.current.load({
            ...loadActionPayload,
            limit: 100,
            startDate: rangeDate.startDate,
            endDate: rangeDate.endDate,
            tags,
            nextPageToken: undefined,
            ...getSortByOptions(sortBy),
        });
    }, [sortBy, actions, loadActionPayload, rangeDate, tags]);

    const preload = useThrottledCallback(() => {
        if (!taskListLoading) {
            actions.current.load({
                ...loadActionPayload,
                nextPageToken: nextPageToken || undefined,
                limit: 100,
                startDate: rangeDate.startDate,
                endDate: rangeDate.endDate,
                tags,
                ...getSortByOptions(sortBy),
            });
        }
    }, 1000, [actions, loadActionPayload, nextPageToken, sortBy, taskListLoading, tags]);
    return { preload, nextPageToken };
}
