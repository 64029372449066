import { Box, Tooltip, useTheme } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { languageTypes } from "api/user/userModel";
import { PhoneNumberInput } from "components/basicInputs/phoneNumberInput/PhoneNumberInput";
import { AutocompleteDropdown } from "components/basicInputs/selectInput/AutocompleteDropdown";
import { TextInput } from "components/basicInputs/textInput/TextInput";
import { t } from "i18next";
import { useFormStyles } from "modules/shared/components/formStyles";
import React from 'react';
import { Trans } from "react-i18next";
import { FeatureFlags } from "utils/featureFlags";
import { useFeatureState } from "utils/hooks/useFeatureState";

export type CommonUserFormInputsProps = {
    disabled?: boolean;
    isAdminEdit: boolean;
}

export const CommonUserFormInputs = ({ disabled, isAdminEdit }: CommonUserFormInputsProps) => {
    const classes = useFormStyles();
    const theme = useTheme();
    const isLanguageEnabled = useFeatureState(FeatureFlags.Language);

    const mobilePhoneLabel = (
        <Box
            display="inline-flex"
            alignItems="center"
        >
            <Trans>Mobile Phone</Trans>
            <Tooltip title={<Trans>User will receive SMS notifications if enabled by your company</Trans>}>
                <HelpIcon
                    style={{
                        marginLeft: theme.spacing(0.3),
                        cursor: 'pointer',
                    }}
                    fontSize="small"
                />
            </Tooltip>
        </Box>
    );

    return (
        <>
            <TextInput
                name="firstName"
                label={`${t('First Name')}`}
                disabled={!isAdminEdit}
            />

            <TextInput
                name="lastName"
                label={`${t('Last Name')}`}
                disabled={!isAdminEdit}
            />

            <TextInput
                classes={{ root: classes.titleInput }}
                name="email"
                label={`${t('Email')}`}
                disabled={!isAdminEdit}
            />
            {!isAdminEdit && (
                <PhoneNumberInput
                    name="phoneNumber"
                    label={mobilePhoneLabel}
                    disabled={disabled}
                />
            )}
            {( isLanguageEnabled && !isAdminEdit ) && (
                <AutocompleteDropdown
                    classes={{ root: classes.titleInput }}
                    name="language"
                    label={`${t('Language')}`}
                    options={languageTypes}
                    keyProp='value'
                    valueProp='label'
                    disabled={disabled}
                    disableClearable
                />
            )}
        </>
    );
};
