import React, { useLayoutEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import { useConversationStyles } from './conversationStyles';
import { TypeUploadHandler, useDropHandler, useUploadHandler } from 'store/modules/files/filesHooks';
import { addPendingConversationFiles } from 'store/modules/files/filesActions';
import { AttachmentsList } from './AttachmentsList';
import { getPendingConversationFiles } from 'store/modules/files/filesSelectors';

type DropzoneTextEditorProps = {
    children: React.ReactNode;
}

export const DropzoneTextEditor: React.FC<DropzoneTextEditorProps> = ({ children }) => {
    const classes = useConversationStyles();
    const { getRootProps } = useDropzone({ onDrop: useDropHandler(()=>{},addPendingConversationFiles), noClick: true,noKeyboard: true, });

    return (
        <>
            <Paper className={classes.dropzoneWrapper} {...getRootProps()} elevation={0}>
                {children}
            </Paper>
            <FileUploadList />
        </>
    );
}

const FileUploadList = () => {
    const filesWrapperRef = useRef<HTMLDivElement | null>(null);
    const classes = useConversationStyles();
    const handleUploadFilesClick = useUploadHandler(getPendingConversationFiles, TypeUploadHandler.TaskConversation);
    const pendingFiles = useSelector(getPendingConversationFiles);

    useLayoutEffect(()=>{
        if(pendingFiles.length){
            handleUploadFilesClick();
        }
    },[pendingFiles, handleUploadFilesClick])

    return (
        <div ref={filesWrapperRef} className={classes.filesList}>
            <AttachmentsList/>
        </div>
    )
}