import React, { useCallback } from 'react';
import { CellProps } from 'react-table';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { IAction } from 'components/button/actionButton/model';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { Routes } from 'components/router/model';
import { ScheduledTaskShort } from 'api/task/scheduledTask/scheduledTaskModel';
import { ISkeleton } from '../../model';
import { useSetScheduledTask } from './context';
import { useIsHigherOrSameLocation } from 'modules/hierarchy/hooks';
import { t } from 'i18next';

export const ActionsCell: React.FC<CellProps<ScheduledTaskShort & ISkeleton, string>> = ({ row }) => {
    const { id: templateId, isSkeleton, createdBy, rootLocationId: taskLocationId = '' } = row.original;
    const { id: userId = '', companyLocationId: currentUserCompanyLocationId = '' } = useCurrentUser() || {};
    const history = useHistory();
    const canCreateDeleteTasks = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const taskEditEnabled = useFeatureState(FeatureFlags.EditScheduledTask);
    const taskDeleteEnabled = useFeatureState(FeatureFlags.DeleteScheduledTask);
    const managerAccessEnabled = useFeatureState(FeatureFlags.ManagerAccessToTaskEditing);
    const setScheduledTask = useSetScheduledTask();
    const isManagerFromHigherLocation = useIsHigherOrSameLocation(currentUserCompanyLocationId ?? '', taskLocationId)
        && managerAccessEnabled;
    const isTaskOwner = userId === createdBy;
    const isUserPermitted = canCreateDeleteTasks && (isTaskOwner || isManagerFromHigherLocation);
    const canEditTask = isUserPermitted && taskEditEnabled;
    const canDeleteTask = isUserPermitted && taskDeleteEnabled;

    const handleClick = useCallback(() => {
        history.push(generatePath(Routes.ScheduledTaskDetails, { uuid: templateId }));
    }, [history, templateId]);

    const handleDeleteClick = useCallback(() => {
        setScheduledTask?.(templateId);
    }, [setScheduledTask, templateId]);

    const items: Array<IAction<string>> = [
        {
            name: canEditTask ? `${t('Edit')}` : `${t('View')}`,
            action: handleClick,
            isAvailable: true,
            Icon: canEditTask ? Edit : Visibility,
        },
        {
            name: `${t('Stop')}`,
            action: handleDeleteClick,
            isAvailable: canDeleteTask,
            Icon: Delete,
        },
    ];

    return (
        <ActionButton
            disabled={isSkeleton}
            entity={templateId}
            width={13}
            items={items}
            autoHide
        />
    );
};
