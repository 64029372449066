import { IconButton } from '@material-ui/core'
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useLaunchCoverStyles } from './styles';
import { useActions } from 'utils/store/useActions';
import { useCallback } from 'react';
import { openLaunchCoverEditor } from 'store/modules/launch/launchActions';
import clsx from 'clsx';

export const EmptyLaunchCover = () => {
  const classes = useLaunchCoverStyles();
  const actions = useActions({openLaunchCoverEditor: openLaunchCoverEditor})
  const launchCoverImgEditHandler = useCallback(() => actions.current.openLaunchCoverEditor(true), [actions]);

  return (
    <IconButton onClick={launchCoverImgEditHandler} className={clsx(classes.emptyBtnImge, classes.launchCoverImg)} disableFocusRipple disableRipple>
      <CameraAltIcon className={classes.iconCameraAdd}/>
    </IconButton>
  )
}