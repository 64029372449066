import React from 'react';
import { CellProps } from 'react-table';
import { IParentTaskGridRowData } from '../model';

export const SubmittedTasksCell: React.FC<CellProps<IParentTaskGridRowData, number>> = ({
    value,
    row,
}) => {
    const isApproval = Boolean(row?.original?.approvable);
    return (
        <>
            { isApproval && row?.original?.submittedStoreTasks !== 0 ? value : '–' }
        </>
    );
};
