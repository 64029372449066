import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import {
    MultipleUsersRequest,
    MultipleUsersResonse,
    UserSearchRequest,
    UserSearchResponseDTO,
    UserDTO,
    UserEditDTO,
    UserLanguageUpdatePayload,
    UserLanguageResponseDTO,
} from 'api/user/userModel';
import { IEntityError, IMultyEntityError } from 'model/entity';
import { IResponseErrorPayload } from 'model/error';

const REQUEST_SELF_USER_INFO = `user/SELF_INFO`;

export const requestSelfUserInfo = createApiActionsByName<
    string, UserDTO, IEntityError, typeof REQUEST_SELF_USER_INFO
>(
    REQUEST_SELF_USER_INFO
);

const REQUEST_MULTIPLE_USERS_INFO = 'user/MULTIPLE_INFO';

export const requestMultipleUsersInfo = createApiActionsByName<
    MultipleUsersRequest, MultipleUsersResonse, IMultyEntityError, typeof REQUEST_MULTIPLE_USERS_INFO
>(
    REQUEST_MULTIPLE_USERS_INFO
);

const USER_GET_LIST = 'user/GET_LIST';

export const requestUserList = createApiActionsByName<
    UserSearchRequest, UserSearchResponseDTO, IResponseErrorPayload, typeof USER_GET_LIST
>(
    USER_GET_LIST
);

const USER_CREATE = 'user/CREATE';
export const requestUserCreate = createApiActionsByName<
    UserEditDTO, void, IResponseErrorPayload, typeof USER_CREATE
>(
    USER_CREATE
);

export type UserEditPayload = UserEditDTO & {
    id: string;
};

const USER_UPDATE = 'user/UPDATE';
export const requestUserUpdate = createApiActionsByName<
    UserEditPayload, UserDTO, IEntityError, typeof USER_UPDATE
>(
    USER_UPDATE
);

const USER_LANGUAGE_UPDATE = 'user_language/UPDATE';
export const requestUserLanguageUpdate = createApiActionsByName<
    UserLanguageUpdatePayload, UserLanguageResponseDTO, IEntityError, typeof USER_LANGUAGE_UPDATE
>(
    USER_LANGUAGE_UPDATE
);

export type UserStatusBulkChange = {
    activateDirection: boolean;
    ids: Array<string>;
    currentFilter: UserSearchRequest;
    notify: boolean;
};

const USER_BULK_STATUS_CHANGE = 'user_bulk/STATUS_CHANGE';
export const requestUserBulkStatusChange = createApiActionsByName<
    UserStatusBulkChange, UserSearchRequest, IEntityError, typeof USER_BULK_STATUS_CHANGE
>(USER_BULK_STATUS_CHANGE);

export type UserActions =
    | ActionsReturnTypes<typeof requestSelfUserInfo>
    | ActionsReturnTypes<typeof requestMultipleUsersInfo>
    | ActionsReturnTypes<typeof requestUserList>
    | ActionsReturnTypes<typeof requestUserCreate>
    | ActionsReturnTypes<typeof requestUserUpdate>
    | ActionsReturnTypes<typeof requestUserBulkStatusChange>
    | ActionsReturnTypes<typeof requestUserLanguageUpdate>;
