/* eslint-disable @typescript-eslint/naming-convention */
import { Accessor, Column, TableState, UseSortByColumnOptions } from 'react-table';
import { ActionsCell } from './cells/ActionsCell';
import { ColorStatusCell } from '../../../components/sharedCells/ColorStatusCell';
import { CompletionStatusCell } from './cells/CompletionStatusCell';
import { CreatorCell } from '../../../components/sharedCells/CreatorCell';
import { DueDateCell } from '../../../components/sharedCells/DueDateCell';
import { TaskNameCell, TaskNameMobileCell } from './cells/TaskNameCell';
import { IParentTaskGridRowData } from './model';
import { TaskListSortBy } from 'store/modules/task/taskListComponent/taskListComponentModel';
import { Theme, useMediaQuery } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { useMemo } from 'react';
import { SubmittedTasksCell } from './cells/SubmittedTasksCell';
import { t } from 'i18next';

type ParentTasksGridColumn = (
    Column<IParentTaskGridRowData>
    & Partial<UseSortByColumnOptions<IParentTaskGridRowData>>
);

enum ColumnId {
    Task = 'task',
    Status = 'completion-status-parent',
    ColorStatus = 'color-status',
    DueDate = 'due-date',
    SubmittedTasks = 'submitted-tasks',
    Creator = 'creator',
    Actions = 'actions',
}

export const taskListInitialSortBy: TaskListSortBy = {
    id: ColumnId.Status,
    desc: false,
};

export const initialSortOptions: Partial<TableState<IParentTaskGridRowData>> = {
    sortBy: [
        taskListInitialSortBy,
    ],
};

export const completionStatusAccessor: Accessor<IParentTaskGridRowData> = (originalRow: IParentTaskGridRowData) => {
    const {
        storeTasks,
        overdueStoreTasks,
        completedStoreTasks,
    } = originalRow;
    if (overdueStoreTasks > 0) {
        return -1;
    } else if(completedStoreTasks === storeTasks) {
        return 1;
    } else {
        return 0;
    }
};

export function useColumns(): Array<ParentTasksGridColumn> {
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );

    return useMemo(() => {
        const taskNameColumn: Array<ParentTasksGridColumn> = isMobile ? [{
            id: ColumnId.Task,
            Header: `${t('Task')}`,
            accessor: 'title',
            Cell: TaskNameMobileCell,
            defaultCanSort: true,
            width: 220,
        }] : [{
            id: ColumnId.Task,
            Header: `${t('Task')}`,
            accessor: 'title',
            Cell: TaskNameCell,
            defaultCanSort: true,
            width: 220,
        }];

        const statusColumn: Array<ParentTasksGridColumn> = isMobile ? [] : [
            {
                id: ColumnId.Status,
                Header: `${t('Completion Status')}`,
                Cell: CompletionStatusCell,
                accessor: 'completionState',
                defaultCanSort: true,
            },
        ];

        return [
            {
                id: ColumnId.ColorStatus,
                accessor: 'completionState',
                Cell: ColorStatusCell,
                defaultCanSort: false,
                disableSortBy: true,
                width: '7px',
            },
            ...taskNameColumn,
            {
                id: ColumnId.DueDate,
                Header: `${t('Due Date')}`,
                accessor: 'dueDate',
                Cell: DueDateCell,
                defaultCanSort: true,
                width: 100,
            },
            ...statusColumn,
            {
                id: ColumnId.SubmittedTasks,
                Header: `${t('Submitted')}`,
                accessor: 'submittedStoreTasks',
                Cell: SubmittedTasksCell,
                width: 100,
            },
            {
                id: ColumnId.Creator,
                Header: `${t('Creator')}`,
                accessor: row => row.createdBy,
                Cell: CreatorCell,
                defaultCanSort: false,
                disableSortBy: true,
                width: 160,
            },
            {
                id: ColumnId.Actions,
                accessor: 'id',
                Cell: ActionsCell,
                defaultCanSort: false,
                disableSortBy: true,
                width: '52px',
            },
        ];
    }, [isMobile]);
}
