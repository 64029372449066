import React, { useCallback, useEffect } from 'react';
import {
    useMediaQuery,
    Theme,
    Box,
    Divider,
    Backdrop,
} from '@material-ui/core';
import { ChannelsSectionContainer } from './sections/channels/ChannelsSectionContainer';
import clsx from 'clsx';
import { DirectMessagesSectionContainer } from './sections/directMessages/DirectMessagesSectionContainer';
import { Scrollbar } from 'components/containers/Scrollbar';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { Breakpoints, ZIndex } from 'utils/styles/constants';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { FloatingButton } from './FloatingButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectLeftMenuOpened } from 'store/modules/appState/appStateSelectors';
import { Channel } from 'stream-chat';
import { browserHistory, Routes } from 'components/router/model';
import { generatePath } from 'react-router-dom';
import { useConversations } from '../../hooks/useConversations';
import { useChatContext } from 'stream-chat-react';
import { requestChatRoles } from '../../../../../api/getStream/getStreamActions';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';

const otherRootClasses = [
    'str-chat',
    'str-chat-channel-list',
    'connect',
];

export function ChatSidebar() {
    const {
        channels,
        channelsReady,
        chats,
        chatsReady,
        totalUnreads,
    } = useConversations();
    const { channel } = useChatContext();
    const leftMenuOpened = useSelector(selectLeftMenuOpened);
    const { isOpened, onClose, onToggle } = useOpenedState(false);
    const sidebarRootClasses = clsx(...otherRootClasses, { 'opened': isOpened });
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const isCustomInputConectEnabled = useFeatureState(FeatureFlags.CustomInputConnect);

    const setActiveChannel = useCallback(({ cid }: Channel) => {
        browserHistory.push(generatePath(Routes.Connect, { cid }));
        if (cid.startsWith("team:")) {
            const channelId = cid?.replace("team:", "") ?? "";
            dispatch(requestChatRoles.init(channelId));
        }
    }, [dispatch]);

    useEffect(() => {
        onClose();
    }, [channel, onClose]);

    return (
        <>
            <Box className={sidebarRootClasses}>
                <Box className="chat-sidebar-wrapper">
                    <Scrollbar>
                        <Box>
                            <ChannelsSectionContainer
                                activeConversationCid={channel?.cid}
                                setActiveConversation={setActiveChannel}
                                conversations={channels}
                                showLoader={!channelsReady}
                                totalUnreads={totalUnreads}
                            />
                        </Box>

                        <Box p="0 40px">
                            <Divider />
                        </Box>

                        <Box>
                            <DirectMessagesSectionContainer
                                activeConversationCid={channel?.cid}
                                setActiveConversation={setActiveChannel}
                                conversations={chats}
                                showLoader={!chatsReady}
                                totalUnreads={totalUnreads}
                            />
                        </Box>
                    </Scrollbar>
                </Box>
            </Box>

            {isMobile && !leftMenuOpened && (
                <FloatingButton
                    color="primary"
                    onClick={onToggle}
                    seed={!!channel}
                    isCustomInputConectEnabled={isCustomInputConectEnabled}
                    isOpened={isOpened}
                >
                    {isOpened ? <MenuOpenIcon /> : <SpeakerNotesIcon />}
                </FloatingButton>
            )}

            {isMobile && <Backdrop open={isOpened} style={{ zIndex: ZIndex.Backdrop }} />}
        </>
    );
}
