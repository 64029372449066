import { combineReducers } from 'redux';
import { STORE_TASKS_MODAL_CLOSE, STORE_TASKS_MODAL_OPEN, StoreTasksModalAction } from './storeTasksModalActions';

export type StoreTasksModalState = {
    storeLocationId: string | null;
};

const initialState: StoreTasksModalState = {
    storeLocationId: null,
};

function storeLocationIdReducer(
    state = initialState.storeLocationId,
    action: StoreTasksModalAction
): string | null {
    switch (action.type) {
        case STORE_TASKS_MODAL_OPEN:
            return action.payload;
        case STORE_TASKS_MODAL_CLOSE:
            return null;
        default:
            return state;
    }
}

export const storeTasksModalReducer = combineReducers({
    storeLocationId: storeLocationIdReducer,
});
