import React from 'react';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { DeclineTaskButton } from '../buttons';
import { TaskDeclineDialog } from './TaskDeclineDialog';

export type TaskDeclineProps = {
    taskId: string;
    disabled?: boolean;
};

export const TaskDecline: React.FC<TaskDeclineProps> = ({ taskId, disabled }) => {
    const { isOpened, onOpen, onClose } = useOpenedState(false);

    return (
        <>
            <DeclineTaskButton onClick={onOpen} disabled={disabled}/>

            <TaskDeclineDialog
                taskId={taskId}
                onCancel={onClose}
                show={isOpened}
            />
        </>
    );
};
