import { TasksFilterContext } from 'modules/task/taskList/context/taskFilterContext';
import React, { useContext } from 'react'
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { CreatorFilter } from './CreatorFilter';
import { useRouteMatch } from 'react-router';
import { Routes } from 'components/router/model';

export const TaskCreatorFilter = () => {
  const isCreatorFilterEnabled = useFeatureState(FeatureFlags.CreatorFilter);
  const {
    valuesFiltersTasks,
    handleFilterCreatedBy,
  } = useContext(TasksFilterContext);
  const hideTaskCreator = useRouteMatch(Routes.MyTasks);

  if(!isCreatorFilterEnabled || hideTaskCreator) return <></>;

  return (
    <CreatorFilter
        onCreatorsValuesChange={handleFilterCreatedBy}
        initialCreatorsSelected={valuesFiltersTasks.createdBy}
    />
  )
}
