import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BlueColor, UtilColors } from 'utils/styles/constants';

const useNavButtonStyles = makeStyles((theme: Theme) => ({
    action: {
        color: theme.palette.primary.main,
        backgroundColor: BlueColor.Blue5,
        boxShadow: 'none',

        '&:hover': {
            backgroundColor: UtilColors.HoverColor,
            boxShadow: 'none',
        },
    },
    actionLink: {
        textDecoration: 'none',
    },
}));

const StyledButton: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            {...props}
        >
            {children}
        </Button>
    )
};

export type NavButtonProps = {
    route?: string;
    onClick?: ButtonProps['onClick'];
}

export const NavButton: React.FC<NavButtonProps> = ({ onClick, route, children }) => {
    const classes = useNavButtonStyles([]);

    if (!route) {
        return (
            <StyledButton
                className={classes.action}
                onClick={onClick}
            >
                {children}
            </StyledButton>
        );
    }

    return (
        <Link to={route} className={classes.actionLink}>
            <StyledButton className={classes.action}>
                {children}
            </StyledButton>
        </Link>
    );
}
