import React from 'react';
import { IconButton, styled } from '@material-ui/core';
import { CloseRounded, Fullscreen, FullscreenExit } from '@material-ui/icons';

const LightboxIconButton = styled(IconButton)(({ theme }) => ({
    color: '#fff',
    padding: theme.spacing(1),
}));

export type CustomControlsProps = {
    isFullscreen: boolean;
    closeLightboxHandler: () => void;
    fullscreenToggleHandler: () => void;
};

export const CustomControls = ({
    isFullscreen,
    closeLightboxHandler,
    fullscreenToggleHandler
}: CustomControlsProps) => {
    return (
        <div className="image-gallery-custom-controls">
            <LightboxIconButton onClick={fullscreenToggleHandler}>
                {isFullscreen
                    ? <FullscreenExit />
                    : <Fullscreen />}
            </LightboxIconButton>
            <LightboxIconButton onClick={closeLightboxHandler}>
                <CloseRounded />
            </LightboxIconButton>
        </div>
    );
};
