import { Link, LinkTypeMap, Tooltip } from '@material-ui/core';
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Routes } from 'components/router/model';
import { Trans } from 'react-i18next';
import { generatePath } from 'react-router';

export type TaskDirectLinkProps = DefaultComponentProps<LinkTypeMap<{}, "a">> & {
    taskId: string;
}

export const TaskDirectLink = ({ taskId, ...linkProps }: TaskDirectLinkProps) => {
    const path = generatePath(Routes.ViewTask, { uuid: taskId });

    return (
        <Tooltip title={<Trans>Open in new window</Trans>}>
            <Link
                href={path}
                target="_blank"
                {...linkProps}
            >
                <OpenInNewIcon fontSize="inherit" />
            </Link>
        </Tooltip>
    );
};
