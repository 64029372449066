import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from "utils/store/useActions";
import { Trans } from 'react-i18next';
import { selectTaskBulkToRemove, selectTaskBulkToRemoveFetching } from 'store/modules/task/TasksBulk/tasksBulkSelectors';
import { setTaskBulkToRemove } from 'store/modules/task/TasksBulk/tasksBulkActions';
import { requestDeleteTaskBulk } from 'api/task/tasksBulk/tasksBulkActions';
import { ModalToDelete } from 'modules/shared/components/ModalToDelete';

export const RemoveBulkTask: React.FC = () => {
    const commentToRemoveById = useSelector(selectTaskBulkToRemove);
    const actions = useActions({ setTaskBulkToRemove, deleteTaskComment: requestDeleteTaskBulk.init });
    const handleCancel = useCallback(() => actions.current.setTaskBulkToRemove(null), [actions]);
    const handleDelete = useCallback(() => {
        actions.current.deleteTaskComment(commentToRemoveById);
    }, [actions, commentToRemoveById]);
    const isFetching = useSelector(selectTaskBulkToRemoveFetching);

    if (!commentToRemoveById) {
        return null;
    }

    return (
        <ModalToDelete 
            title={<><Trans>Delete</Trans> <strong><Trans>this Bulk task</Trans>?</strong></>}
            onCancel={handleCancel}
            onDelete={handleDelete}
            isFetching={isFetching}
        />
    );
};
