import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { selectUserProvisioning } from 'store/modules/integration/integrationSelectors';
import { useSelector } from 'react-redux';
import {
    CardIntegration,
    GridIntegration,
    GridIntegrationChildren,
} from '../IntegrationStyles';

export const UserProvisioning = () => {
    const userProvisioning = useSelector(selectUserProvisioning);

    return (
        <Box>
            <Typography variant='h3'>
                <Trans>User Provisioning</Trans>
            </Typography>
            <GridIntegration>
                {userProvisioning.map((prov) => (
                    <GridIntegrationChildren key={prov.id}>
                        <CardIntegration {...prov} />
                    </GridIntegrationChildren>
                ))}
            </GridIntegration>
        </Box>
    );
};
