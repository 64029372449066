import { TaskTaglistMultiselect } from 'modules/shared/components/TaskTaglistMultiselect';
import { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { selectCalendarFilterTags } from 'store/modules/task/calendarFilter/calendarFilterSelectors';
import { calendarFilterTags } from 'store/modules/task/calendarFilter/calendarFilterTaskActions';
import { calendarFilter } from 'store/modules/task/calendarTask/calendarTaskActions';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';

export const CalendarTagFilter = () => {
  const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
  const canAccessTask = useSelector(selectHasAppAccess(AppName.Tasks));
  const tagsTasks = useSelector(selectCalendarFilterTags);
  const actions = useActions({ calendarFilter, calendarFilterTags });

  const handleFilterTag = useCallback((newValues: string[]) => {
    if(JSON.stringify(tagsTasks) !== JSON.stringify(newValues)){
      actions.current.calendarFilterTags(newValues)
    }
  },[tagsTasks, actions])

  if(!isTagEnabled || !canAccessTask) return <></>;

  return (
    <TaskTaglistMultiselect 
      onTagsValuesChange={handleFilterTag}
      initialTagSelected={tagsTasks}
    />
  )
}
