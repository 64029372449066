import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { GrayColor } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    editButton: {
        padding: theme.spacing(1, 1.5),
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        backgroundColor: theme.palette.primary.dark,
        '&:disabled': {
            color: GrayColor.Gray60,
        },

        '& svg': {
            marginRight: theme.spacing(0.5),
            color: theme.palette.getContrastText(theme.palette.primary.dark),
        },
    },
}));

export interface IEditButtonProps {
    onClick: () => void;
    disabled?: boolean;
}

export function EditButton({ onClick, disabled = false }: IEditButtonProps) {
    const classes = useStyles();
    return (
        <Button
            className={classes.editButton}
            onClick={onClick}
            disabled={disabled}
        >
            <EditIcon/>
            <Trans>Edit</Trans>
        </Button>
    );
}
