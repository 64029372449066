import React, { PropsWithChildren } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const useStyles = makeStyles(() => ({
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
}));

export interface IInviteDialogProps {
    isOpened: boolean;
    onClose: () => void;
    onInvite: () => void;
    inviteDisabled: boolean;
}

export function InviteDialog({
    isOpened,
    onClose,
    onInvite,
    inviteDisabled,
    children,
}: PropsWithChildren<IInviteDialogProps>) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge));
    const classes = useStyles();

    return (
        <Dialog open={isOpened} fullScreen={isMobile}>
            <DialogTitle>
                <Typography component="span" variant="h5">
                    <Trans>Invite members</Trans>
                </Typography>
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                <Box className={classes.actionButtons}>
                    <Button onClick={onClose}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button disabled={inviteDisabled} onClick={onInvite}>
                        <Trans>Invite</Trans>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
