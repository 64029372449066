import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import { useButtonsStyles } from './styles';
import { useHideActiveChat } from 'modules/connect/messenger/hooks/useHideActiveChat';

export function HideConversationButton() {
    const classes = useButtonsStyles();
    const { hideActiveChat } = useHideActiveChat();

    const handleHideClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        hideActiveChat();

        event.preventDefault();
        event.stopPropagation();
    }, [hideActiveChat]);

    return (
        <IconButton
            className={classes.root}
            onClickCapture={handleHideClick}
        >
            <VisibilityOffIcon />
        </IconButton>
    );
}
