import React, { useEffect, useMemo } from 'react';
import { makeRowRenderer, makeSkeletons } from 'modules/shared/components/gridHelpers';
import { initialSortingRules, useColumns } from './columns';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { taskListCheckRequest } from 'api/task/taskActions';
import { useLoad } from 'modules/task/taskList/hooks/useLoad';
import { storeTaskListSetSorting, taskListResetData } from 'store/modules/task/taskListComponent/taskListComponentActions';
import { useActions } from "utils/store/useActions";
import { selectStoreTasksSortBy } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { useSelector } from 'react-redux';
import { VirtualScrollGrid } from 'modules/shared/components/VirtualScrollGrid';
import { EmptyState } from 'modules/task/taskList/components/EmptyState';
import { t } from 'i18next';
import { IAutomationTaskGridRowData } from './model';
import { selectAutomationTasksFetching, selectAutomationtasksNextPageToken } from 'store/modules/task/automationTask/automationTaskSelectors';
import { useAutomationTasksGridData } from './useAutomationTasksGridData';
import { AutomationTaskSearchRequest, AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';

export interface IAutomationTasksGridBaseProps {
    filter?: AutomationTaskStatusFilter;
    filterTag?: Array<string>;
    searchTerm?: string;
    height?: number;
}

interface IAutomationTasksGridProps extends IAutomationTasksGridBaseProps {
    isFetching: boolean;
}
const skeletons = makeSkeletons<IAutomationTaskGridRowData>(20);

export const AutomationTasksBaseGrid: React.FC<IAutomationTasksGridProps> = ({
    filter,
    filterTag,
    searchTerm,
    isFetching,
    height = 500,
}) => {
    const data = useAutomationTasksGridData();
    const baseSearchRequest: AutomationTaskSearchRequest = useMemo(() => {
        return {
            filter,
            tags: filterTag,
            searchTerm,
            tasksViewMode: TasksViewModeMyTasks
        };
    }, [filter, filterTag, searchTerm]);
    const automationTasksSortBy = useSelector(selectStoreTasksSortBy);
    const { preload, nextPageToken } = useLoad(
        selectAutomationtasksNextPageToken,
        selectAutomationTasksFetching,
        taskListCheckRequest,
        taskListResetData,
        baseSearchRequest,
        automationTasksSortBy,
    );
    const columns = useColumns();
    const actions = useActions({ setSorting: storeTaskListSetSorting });
    const showEmptyState = data.length === 0 && !isFetching;
    const showSkeletons = data.length === 0 && isFetching;
    const totalData = showSkeletons ? skeletons : data;
    const dataGrid = useTable<IAutomationTaskGridRowData>(
        {
            columns,
            data: totalData,
            manualSortBy: true,
            disableMultiSort: true,
            initialState: {sortBy: [ {
                id: automationTasksSortBy !== 'not-set' ? automationTasksSortBy?.id as string : initialSortingRules[0].id,
                desc: automationTasksSortBy !== 'not-set' ? automationTasksSortBy?.desc : initialSortingRules[0].desc,
            }]},
        },
        useFlexLayout,
        useSortBy,
    );

    const { state: { sortBy: sortByArray } } = dataGrid;
    useEffect(() => {
        if (sortByArray.length === 0) {
            dataGrid.setSortBy(initialSortingRules);
            return;
        } 
        const sortBy = sortByArray[0];
        actions.current.setSorting(sortBy);
    }, [actions, dataGrid, sortByArray]);

    return (
        <VirtualScrollGrid
            dataGrid={dataGrid}
            showEmptyState={showEmptyState}
            emptyState={<EmptyState text={`${t('Please check your search, or adjust task filters as necessary')}.`}/>}
            height={height}
            nextPageToken={nextPageToken}
            preload={preload}
            customRowRenderer={makeRowRenderer}
        />
    );
};
