import { Checkbox } from '@material-ui/core';
import { useCallback, useContext } from 'react';
import { CellProps } from "react-table";
import { NotificationRowData, ColumnId, columnIdToPropMap } from './model';
import { updateRow } from './reducer';
import { SettingsGridContext } from './SettingsGridProvider';

export type NotificationTypeCellProps = CellProps<NotificationRowData, boolean>;

export const CheckboxCell = ({ value, column, row }: NotificationTypeCellProps) => {
    const { dispatch, disabled: gridDisabled } = useContext(SettingsGridContext) || {};
    const onChangeHandler = useCallback((_: React.ChangeEvent, checked: boolean) => {
        const prop = columnIdToPropMap[column.id as ColumnId];

        dispatch?.(updateRow({
            ...row.original,
            [prop]: checked,
        }));
    }, [column, row, dispatch]);

    const checkboxDisabled = column.id === ColumnId.SMS && !row.original.smsOptionAvailable;

    return (
        <Checkbox
            checked={value}
            color="primary"
            onChange={onChangeHandler}
            disabled={gridDisabled || checkboxDisabled}
        />
    );
};
