import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
    Box,
    Button,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { Formik, useField } from 'formik';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { CheckboxInput } from 'components/basicInputs/checkboxInput/CheckboxInput';
import { useActions } from "utils/store/useActions";
import { requestAddChannelImage, requestCreateChannel, resetAddChannelImage } from 'api/getStream/getStreamActions';
import { useSelector } from 'react-redux';
import { selectAddChannelImageId, selectIsChannelCreating, selectIsChannelImagenUpdating } from 'store/modules/getStream/getStreamSelectors';
import { selectUser } from 'store/modules/auth/authSelectors';
import { Sidebar } from 'components/sidebar/Sidebar';
import { selectUserRoles } from '../../../../../../../store/modules/user/userSelectors';
import { SingleSelect } from '../../../../../../../components/basicInputs/selectInput/SingleSelect';
import { selectFeatureFlags } from '../../../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../../../utils/featureFlags';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import { useConversations } from 'modules/connect/messenger/hooks/useConversations';
import { PendingFile } from 'store/modules/files/filesModel';
import { CropperImage } from 'components/CropperImage/CropperImage';
import channelDefaultImage from 'images/channel-default-image.png';

const useStyles = makeStyles((theme: Theme) => ({
    buttons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1),
    },
    description: {
        minHeight: theme.spacing(10),
    },
    colorBase: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
}));

export interface ICreateChannelDialogProps {
    isOpened: boolean;
    onClose: () => void;
}

const dialogSchema = yup.object({
    channelName: (
        yup
            .string()
            .trim()
            .required()
            .min(3)
            .max(120)
            .label(`${i18n.t('Channel name')}`)
    ),
    description: (
        yup
            .string()
            .trim()
            .notRequired()
            .max(500)
            .label(`${i18n.t('Description')}`)
    ),
    frozen: (
        yup
            .boolean()
            .notRequired()
            .label(`${i18n.t('Freeze Channel')}`)
    ),
    chatType: (
        yup
            .string()
            .notRequired()
    ),
    chatRole: (
        yup
            .string()
            .when("chatType", {
                is: values => (values ?? '') === 'role',
                then: yup.string().required(),
            })
    ),
    imageId: (
        yup
            .string()
            .notRequired()
    )
});

export function CreateChannelDialog({ isOpened, onClose }: ICreateChannelDialogProps) {
    const user = useSelector(selectUser);
    const classes = useStyles();
    const isRoleChatFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.SingleRoleStreamChats];
    const actions = useActions({ 
        requestCreateChannel: requestCreateChannel.init,
        resetChannelImage: resetAddChannelImage
    });
    const { channels  } = useConversations();

    const roles = useSelector(selectUserRoles);
    const currentUser = useSelector(selectUser);
    const companyId = currentUser?.companyId;
    const companyRoles = useMemo(() => roles.filter(x => x.companyId === companyId),
        [roles, companyId]);
    const roleList = useMemo(() => {
        return companyRoles?.map(x => {
            return {
                value: x.roleId,
                title: x.description,
            };
        });
    }, [companyRoles]);
    const [currentRole, setCurrentRole] = useState('');
    const channelsNames = useMemo(() => channels.map((ch)=> ch.data?.name?.toLowerCase() ?? ''),[channels]);

    const chatTypes = [
        {
            value: 'personal',
            title: `${i18n.t('Personal')}`
        },
        {
            value: 'role',
            title: `${i18n.t('Role')}`
        },
        {
            value: 'tenant',
            title: `${i18n.t('Common Tenant')}`
        },
    ];
    const [currentChatType, setCurrentChatType] = useState('');

    const isLoading = useSelector(selectIsChannelCreating);
    const handleChannelCreate = useCallback(({ channelName, description, frozen, imageId }, { resetForm, setFieldError }) => {
        if(channelsNames.includes(channelName.trim().toLowerCase())){
            setFieldError("channelName", `${i18n.t('Channel name already exists')}.`);
            return;
        }
        actions.current.requestCreateChannel({
            name: channelName.trim(),
            description: description?.trim() || '',
            members: ([user?.id ?? ''].filter(item => !!item)),
            frozen,
            type: currentChatType,
            singleRoleId: currentRole,
            imageId
        });
        onClose();
        resetForm();
    }, [actions, onClose, user, currentChatType, currentRole, channelsNames]);

    useEffect(() => {
        if(isOpened){
            actions.current.resetChannelImage();
        }
    }, [isOpened, actions])

    return (
        <Formik
            initialValues={{ channelName: '', description: '', chatType: 'personal', imageId: '' }}
            onSubmit={handleChannelCreate}
            validationSchema={dialogSchema}
        >
            {({ handleSubmit, handleReset, resetForm }) => (
                <Sidebar
                    open={isOpened}
                    onClose={() => {
                        resetForm();
                        onClose();
                    }}
                    title={<Trans>Create a channel</Trans>}
                >
                    <form
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        autoComplete="off"
                    >
                        <ChannelImage name="imageId"/>
                        <Box>
                            <TextInput
                                label={`${t('Channel name')}`}
                                id="channelName"
                                name="channelName"
                                classes={{
                                    label: classes.colorBase,
                                }}
                            />
                        </Box>

                        <Box mt={1}>
                            <TextInput
                                classes={{
                                    input: classes.description,
                                    label: classes.colorBase,
                                }}
                                label={`${t('Description (optional)')}`}
                                id="description"
                                name="description"
                                multiline
                            />
                        </Box>
                        {isRoleChatFeatureEnabled && (
                        <Box mt={1}>
                            <SingleSelect
                                options={chatTypes}
                                unsetValue={'personal'}
                                onValueChange={(val) => {
                                    setCurrentChatType((val ?? '') as string);
                                }}
                                name='chatType'
                                label={`${t('Chat type')}`}
                                keyProp='value'
                                valueProp='title'
                            />
                        </Box>
                        )}
                        { isRoleChatFeatureEnabled && currentChatType === 'role' && (
                            <Box mt={1}>
                                <SingleSelect
                                    options={roleList}
                                    unsetValue={''}
                                    onValueChange={(val) => {
                                        const valStr = (val ?? '') as string;
                                        setCurrentRole(valStr);
                                    }}
                                    label={`${t('Role')}`}
                                    keyProp='value'
                                    valueProp='title'
                                    name='chatRole'
                                />
                            </Box>
                        )}
                        <Box mt={1}>
                            <CheckboxInput
                                label={`${t('Freeze Channel')}`}
                                id="frozen"
                                name="frozen"
                            />
                        </Box>

                        <Box className={classes.buttons}>
                            <Button
                                type="submit"
                                disabled={isLoading}
                            >
                                <Trans>OK</Trans>
                            </Button>
                        </Box>
                    </form>
                </Sidebar>
            )}
        </Formik>
    );
}

const ChannelImage = ({name}: {name: string}) => {
    const isConnectChannelImageEnabled = useSelector(selectFeatureFlags)[FeatureFlags.connectChannelImage];
    const addChannelImageId = useSelector(selectAddChannelImageId);
    const actions = useActions({ addChannelImage: requestAddChannelImage.init });
    const isChannelImagenUpdating = useSelector(selectIsChannelImagenUpdating);
    const [field] = useField(name);
    const { onChange } = field;

    const handleSaveImage = (pendingFile: PendingFile) => {
        actions.current.addChannelImage(pendingFile);
    }

    useEffect(()=>{
        if(addChannelImageId){
            onChange({ target: { name, value: addChannelImageId } })
        }
    },[addChannelImageId, onChange, name]);

    if(!isConnectChannelImageEnabled) return <></>;


    return (
        <CropperImage
            name={name}
            alt={`${t('Channel Image')}`}
            defaultImage={channelDefaultImage}
            textPreview={`${t('Channel image preview')}`}
            title={`${t('Replace channel image')}`}
            onSaveImage={handleSaveImage}
            isLoading={isChannelImagenUpdating}
        />
    )
}