import React from 'react';
import { Box, Fade, Switch, Tooltip, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

type ToggleExpiredDateProps = {
    isClone: boolean;
    value: boolean | undefined;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleExpiredDate: React.FC<ToggleExpiredDateProps>  = ({isClone, value, handleChange })=>{
    if(isClone) {
        return <>
            <Switch
                edge="start"
                name="isExpirable"
                color="primary"
                checked={value}
                onChange={handleChange}    
            />
            <Typography variant="caption">
                <Trans>Expire at Due Date</Trans>
            </Typography>
        </>
    }
    return <>
        <Tooltip title={<Trans>Expiration Settings Not Available in Edit Task</Trans>} placement="bottom-start"
            TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
            <Box>
                <Switch
                    edge="start"
                    name="isExpirable"
                    color="primary"
                    checked={value}
                    onChange={handleChange}  
                    disabled    
                />
                <Typography variant="caption">
                    <Trans>Expire at Due Date</Trans>
                </Typography>
            </Box>
        </Tooltip>
    </>
}
