import { IResponseErrorPayload } from 'model/error';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { ReviewTaskList } from './taskReviewModel';

const TASK_LIST_FOR_REVIEW = 'task/review/GET_LIST';
export const requestStoreTasksForReview = createApiActionsByName<
    { tags: Array<string>, parentTaskId?: string, createdBy: Array<string> }, ReviewTaskList, IResponseErrorPayload, typeof TASK_LIST_FOR_REVIEW
>(
    TASK_LIST_FOR_REVIEW,
);

export const TASK_LIST_COUNT_FOR_REVIEW = 'task/review/GET_COUNT';
export const requestStoreTasksForReviewCount = createApiActionsByName<
    { tags: Array<string>,createdBy: Array<string>}, number, IResponseErrorPayload, typeof TASK_LIST_COUNT_FOR_REVIEW
>(
    TASK_LIST_COUNT_FOR_REVIEW,
);

export type TaskReviewApiActions =
    | ActionsReturnTypes<typeof requestStoreTasksForReview>
    | ActionsReturnTypes<typeof requestStoreTasksForReviewCount>;
