import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';

const REQUEST_LEGACY_NOTIFICATIONS_COUNT = 'request/legacy/NOTIFICATIONS_COUNT';
const RESPONSE_LEGACY_NOTIFICATIONS_COUNT_SUCCESS = 'response/legacy/NOTIFICATIONS_COUNT_SUCCESS';
const RESPONSE_LEGACY_NOTIFICATIONS_COUNT_ERROR = 'response/legacy/NOTIFICATIONS_COUNT_ERROR';

export const requestNotificationsCount = (
    createApiActions<void, number, any,
        typeof REQUEST_LEGACY_NOTIFICATIONS_COUNT,
        typeof RESPONSE_LEGACY_NOTIFICATIONS_COUNT_SUCCESS,
        typeof RESPONSE_LEGACY_NOTIFICATIONS_COUNT_ERROR
    >(
        REQUEST_LEGACY_NOTIFICATIONS_COUNT,
        RESPONSE_LEGACY_NOTIFICATIONS_COUNT_SUCCESS,
        RESPONSE_LEGACY_NOTIFICATIONS_COUNT_ERROR,
    )
);

export type LegacyRequestActions = ActionsReturnTypes<typeof requestNotificationsCount>
