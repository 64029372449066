import { createTheme, Theme } from '@material-ui/core/styles';
import { BlueColor, UtilColors } from 'utils/styles/constants';
import { Overrides } from '@material-ui/core/styles/overrides';
import { AutocompleteClassKey } from '@material-ui/lab';
import { CSSProperties } from 'react';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

export const fontFamily = `'Inter', 'Arial', serif`;

export interface IAppOverrides extends Overrides, MuiPickersOverrides {
    MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>>;
}

export const initialTheme = createTheme({
    palette: {
        text: {
            primary: UtilColors.Black,
            disabled: UtilColors.TextDisabled,
        },
        primary: {
            dark: BlueColor.Dark,
            main: BlueColor.Blue70,
            light: BlueColor.Blue50,
            contrastText: BlueColor.Blue0,
        },
        secondary:{
            dark: BlueColor.Dark,
            main: BlueColor.Blue70,
            light: UtilColors.IconActive,
            contrastText: BlueColor.Blue0,
        }
    },
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        h1: {
            fontSize: 32,
        },
        h2: {
            fontSize: 28,
            fontWeight: 700,
        },
        h3: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: 24,
            letterSpacing: 0.5,
        },
        h4: {
            fontSize: 34,
            fontWeight: 'bold',
            lineHeight: 1.28,
        },
        h5: {
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: 1.5,
        },
        h6: {
            fontSize: 16,
        },
        subtitle1: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '24px',
        },
        subtitle2: {
            letterSpacing: 1,
        },
        body1: {
            fontSize: 16,
            fontFamily,
            letterSpacing: '0.022em',
            fontWeight: 500,
        },
        body2: {
            fontSize: 14,
            fontFamily,
        },
        caption: {
            fontSize: 12,
            fontFamily,
        },
    },
});

const denseInputPadding = '3.5px';

export const getOverrides = (theme: Theme): IAppOverrides => ({
    MuiPickersToolbar: {
        toolbar: {
            backgroundColor: UtilColors.HoverColor,
        },
    },
    MuiPickersDay: {
        daySelected: {
            backgroundColor: UtilColors.HoverColor,
        },
    },
    MuiCheckbox: {
        root: {
            padding: theme.spacing(1),
        },
    },
    MuiButton: {
        root: {
            fontFamily,
            margin: 0,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: theme.spacing(3),
            padding: theme.spacing(0.5, 2),
            boxShadow: 'none',

            '&:hover': {
                backgroundColor: `${UtilColors.HoverColor}`,
                color: `${theme.palette.primary.contrastText}`,
            },
        },
        containedPrimary: {
            color: theme.palette.primary.contrastText,
            border: 'none',
        },
        containedSecondary: {
            color: UtilColors.White,
            backgroundColors: '#FF5450',
        },
        label: {
            fontSize: 16,
            textTransform: 'none',
        },
        textSizeSmall: {
            '& $label': {
                fontSize: 14,
            },
            padding: theme.spacing(0.5, 2),
        },
    },
    MuiButtonBase: {
        root: {
            fontFamily,
            fontSize: '1rem',
        },
    },
    MuiSvgIcon: {
        root: {
            fontSize: 24,
        },
        fontSizeSmall: {
            fontSize: 16,
        },
    },
    MuiOutlinedInput: {
        root: {
            '&:hover $notchedOutline, &$focused $notchedOutline': {
                borderColor: BlueColor.Blue80,
            },

            '&$error $notchedOutline': {
                borderColor: BlueColor.Blue30,
            },
        },
        notchedOutline: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: BlueColor.Blue30,

            '&:active': {
                borderWidth: 2,
            },
        },
        input: {
            padding: theme.spacing(1, 1.5),
        },
        inputMarginDense: {
            // Do not move top and bottom values here, because MUI will override them with own padding-top(bottom)
            padding: theme.spacing(1),
            paddingTop: denseInputPadding,
            paddingBottom: denseInputPadding,
        },
    },
    MuiSelect: {
        selectMenu: {
            height: theme.spacing(3),
            padding: theme.spacing(1, 1.5),
        },
    },
    MuiInputBase: {
        root: {
            color: BlueColor.Blue50,
        },
        input: {
            color: BlueColor.Blue100,
            fontWeight: 'normal',
            height: theme.spacing(3),
        },
        inputMarginDense: {
            fontSize: 14,
            height: '21px',
        },
    },
    MuiRadio: {
        colorPrimary: {
            '&$checked > *': {
                color: BlueColor.Blue70,
            },
        },
    },
    MuiFormHelperText: {
        contained: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
});

export const baseTheme: Theme = ((theme: Theme) => {
    return createTheme({
        ...theme,
        overrides: getOverrides(theme),
    });
})(initialTheme);

export const newTextColorTheme = ((theme: Theme) => {
    return createTheme({
        ...theme,
        palette: {
            ...theme.palette,
            text: {
                ...theme.palette.text,
                primary: UtilColors.TextPrimary,
            },
        },
    });
})(baseTheme);
