import { useRef, useCallback } from 'react';
import { ListOnScrollProps } from 'react-window';
import { GRID_ROW_HEIGHT } from '../components/gridHelpers';

export function useScrollHandler(nextPageToken: string | null, preload: (...args: any[]) => any) {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const listContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback((props: ListOnScrollProps) => {
        if (!nextPageToken) {
            return;
        }

        const scrollContainer = scrollContainerRef.current;
        const { scrollOffset, scrollDirection } = props;
        if (!scrollContainer || scrollDirection !== 'forward') {
            return;
        }

        const { offsetHeight, scrollHeight } = scrollContainer;
        const scrollToTheEnd = scrollHeight - scrollOffset - offsetHeight;

        if (nextPageToken && scrollToTheEnd < GRID_ROW_HEIGHT * 30) {
            preload();
        }
    }, [nextPageToken, preload]);

    return { scrollContainerRef, listContainerRef, handleScroll };
}
