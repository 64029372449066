import React from 'react';
import { Channel } from 'stream-chat';
import { Group as GroupIcon } from '@material-ui/icons';
import { Box, List } from '@material-ui/core';
import { ChannelDetailsSection } from './ChannelDetailsSection';
import { IAccordionSection, IChannelFile } from './model';
import { Scrollbar } from 'components/containers/Scrollbar';
import { useStyles } from './styles';
import { ChannelFile } from './ChannelFile';
import { useScrollData } from '../hook/useScrollData';
import { Trans } from 'react-i18next';

export interface IChannelFilesProps extends IAccordionSection {
    channel: Channel;
}

export const ChannelFiles = ({ channel, ...rest }: IChannelFilesProps) => {
    const classes = useStyles();
    const {
        dataByChannel,
        onScroll,
        isMultiplo,
        downloadFile
    } = useScrollData(channel);

    return (
        <>
            <ChannelDetailsSection
                title={<><Trans>Files</Trans> ({isMultiplo(dataByChannel.length, 50)})</>}
                Icon={GroupIcon}
                {...rest}
            >
                <Box display="flex" flexDirection="column" width="100%">
                    <Scrollbar
                        style={{maxHeight: '400px'}}
                        onScrollY={(container) =>{
                            onScroll(container.scrollTop, container.scrollHeight, container.clientHeight)
                        }}>
                            <List className={classes.list}>
                                {
                                    dataByChannel?.map((file: IChannelFile) => <ChannelFile key={file.id} file={file} downloadFile={downloadFile} />)
                                }
                            </List>
                    </Scrollbar>
                </Box>
            </ChannelDetailsSection>
        </>
    );
}
