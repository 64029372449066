import React, { useEffect } from 'react';
import { PageLoader } from 'components/router/PageLoader';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { ListPageLayout } from 'modules/shared/components/ListPageLayout';
import { Paper, styled } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { UserProvisioning } from './Integrations/UserProvisioning';
import { useActions } from 'utils/store/useActions';
import { getIntegrations } from 'store/modules/integration/integrationActions';
import { IntegrationPerformance } from './Integrations/IntegrationPerformance';
import { IntegrationAISidekick } from './Integrations/IntegrationAISidekick';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: '100%',

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        marginTop: theme.spacing(1),
    },
}));

export const IntegrationsPage = () => {
    const currentUser = useCurrentUser();
    const actions = useActions({
        getIntegrations,
    });

    useEffect(() => {
        actions.current.getIntegrations();
    }, [actions]);

    if (!currentUser)
        return (
            <ListPageLayout width={'100%'}>
                <PageLoader />
            </ListPageLayout>
        );

    return (
        <ListPageLayout width={'100%'}>
            <StyledPaper>
                <UserProvisioning />
                <IntegrationPerformance />
                <IntegrationAISidekick />
            </StyledPaper>
        </ListPageLayout>
    );
};
