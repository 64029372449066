import { useState, useCallback, useEffect } from 'react';
import { Channel } from 'stream-chat';

import { IChannelFile } from '../ChannelDetails/model';
import { searchFiles } from 'modules/connect/messenger/helpers';
import { createWorkerMessage, workerSendMessage } from 'api/helpers';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { t } from 'i18next';

export const useScrollData = ( channel: Channel ) => {
    const [ dataByChannel, setDataByChannel ] = useState<IChannelFile[]>([]);
    const [ isGettingData, setIsGettingData ] = useState(false);
    const [currPage, setCurrPage] = useState<string>('');
    const [prevPage, setPrevPage] = useState<string>('0');
    const [wasLastList, setWasLastList] = useState(false);
    
    const onScroll = (scrollTop: number, scrollHeight: number, clientHeight: number) => {
        if (scrollTop + clientHeight === scrollHeight) {
            setCurrPage(dataByChannel[dataByChannel.length - 1].id);
        }
    };

    const fetchData = useCallback( async () => {
        setIsGettingData(true);
        const responseAttachments = await searchFiles(channel, dataByChannel.length);
        if (!responseAttachments.length) {
            setIsGettingData(false);
            setWasLastList(true);
            return;
        }
        setPrevPage(currPage);
        setDataByChannel(responseAttachments);
        setWasLastList(false);
        setIsGettingData(false);
    },[dataByChannel, channel, currPage]);

    useEffect(() => {
        if (!wasLastList && prevPage !== currPage && !isGettingData) {
            fetchData();
        }
    }, [currPage, wasLastList, prevPage, isGettingData, fetchData]);

    const isMultiplo = (size: number, multipleBase = 100) => (size > 0 && size % multipleBase === 0 ? `+${size}` : `${size}`);

    const errorMessage = (fileName: string) => {
        workerSendMessage(createWorkerMessage(showToastMessage({
            message: `${t('Error downloading file')} "${fileName}"`,
            options: { variant: ToastVariant.Error, autoHideDuration: 10000 },
        })))
    }

    const downloadFile = async (url: string, fileName: string) => {
        try {
            const a = document.createElement("a");
            a.href = await toDataURL(url);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            errorMessage(fileName);
        }
    }

    const toDataURL = (url: string) => {
        return fetch(url)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                return URL.createObjectURL(blob);
            });
    }

    return ({
        dataByChannel,
        onScroll,
        isMultiplo,
        downloadFile
    })
}
