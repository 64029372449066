import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { BlueColor, UtilColors } from 'utils/styles/constants';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: UtilColors.ShadowLight,
    },
    wrapper: {
        display: 'flex',
        width: '315px',
        color: BlueColor.Blue70,
    },
    text: {
        fontFamily: `'Inter', 'Arial', sans-serif`,
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'center',
    },
}));

export type MessageInputPlaceholderProps = {
    text: string;
    icon?: React.ReactNode;
}

export function MessageInputPlaceholder({ text, icon }: MessageInputPlaceholderProps) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>
                {icon}
                <Typography component="span" className={classes.text}>
                    {text}
                </Typography>
            </Box>
        </Box>
    );
}
