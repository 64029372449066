import i18n from "i18n";

const unitSize = 1024;
const formats = ['B', 'KB', 'MB', 'GB', 'TB', 'PT'];

function adjustNominal(nominal: number): number | string {
    return Math.round(nominal) === nominal ? nominal : nominal.toFixed(1);
}

export function formatSize(bytes: number): string {
    let formatIndex = 0;
    let nominal = bytes;
    while(nominal >= unitSize) {
        nominal /= unitSize;
        formatIndex++;
    }
    return `${adjustNominal(nominal)}${formats[formatIndex]}`;
}

export function resolveType(fileName: string, mimeType?: string): string {
    if (fileName?.indexOf('.')) {
        return fileName.slice(fileName.lastIndexOf('.') + 1);
    }
    if (mimeType?.indexOf('/')) {
        return mimeType.split('/')[1];
    }
    return 'bin';
}

export function getFileKey(file: File): string {
    return `${file.name};${file.type};${file.lastModified};${file.size}`;
}

const maxFileSizeMb = 30 * unitSize * unitSize;
const overallFilesMaxSizeMb = 100 * unitSize * unitSize;

export function validateFiles(files: File[], maxFileSize: number = maxFileSizeMb): string | null {
    const sizes = files.map(file => file.size);
    if (sizes.some(size => size > maxFileSize)) {
        return `${i18n.t('Max size for a single file is 30MB')}`;
    }
    if (sizes.reduce((size, sum) => sum + size, 0) > overallFilesMaxSizeMb) {
        return `${i18n.t('Max size for all files is 100MB')}`;
    }
    return null;
}

export function validateFileFormat(file: File, allowedFormats: string[] ): boolean {
    if (allowedFormats.length === 0) {
        return true;
    }
    const fileType = file.type;
    for (const format of allowedFormats) {
        if (fileType?.toLowerCase().includes(format?.toLowerCase())) {
            return true;
        }
    }

    return false;
}