import React, { ReactNode } from 'react';
import { StyledAvatar, StyledBox, StyledTitle, StyledInfoWrapper, StyledSubtitle, AVATAR_SIZE } from './styles';
import { BoxProps, Tooltip } from '@material-ui/core';
import { BlueColor } from '../../utils/styles/constants';

export type UserInfoWithImageProps = BoxProps & {
    avatarUrl: string;
    title: string;
    subtitle: string;
    firstName: string;
    lastName: string;
    showInformation?: boolean;
    children?: ReactNode;
};

export const UserInfoWithImage = ({ avatarUrl, title, subtitle, firstName, lastName, showInformation = true, children, ...props }: UserInfoWithImageProps) => {
    return (
        <StyledBox {...props}>
            <Tooltip title={title} arrow placement='top'>
                <StyledAvatar
                    src={avatarUrl}
                    alt="avatar"
                    style={{
                        backgroundColor: 'white',
                        color:'darkblue',
                    }}
                >
                    <div style={{
                        backgroundColor: BlueColor.Dark,
                        color:'white',
                        width:AVATAR_SIZE,
                        textAlign: 'center',
                        lineHeight:AVATAR_SIZE,
                    }}>
                        {firstName.substring(0, 1).toUpperCase()}{lastName.substring(0, 1).toUpperCase()}
                    </div>
                </StyledAvatar>
            </Tooltip>
            {showInformation &&
                <StyledInfoWrapper>
                    {children || null}
                    <Tooltip title={title}>
                        <StyledTitle>{title}</StyledTitle>
                    </Tooltip>
                    <Tooltip title={subtitle}>
                        <StyledSubtitle variant="caption">{subtitle}</StyledSubtitle>
                    </Tooltip>
                </StyledInfoWrapper>
            }
        </StyledBox>
    );
};
