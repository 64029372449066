import React from 'react';
import { ListItem, ListItemSecondaryAction, CircularProgress, ListItemAvatar, Avatar, Typography, ListItemText } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { BlueColor } from 'utils/styles/constants';

export const PendingAttachmentItem: React.FC<{title: string;}> = ({ title, }) => (
    <ListItem>
        <ListItemAvatar>
            <Avatar style={{width: 30, height: 30, backgroundColor: BlueColor.Dark}} color='primary'>
                <DescriptionOutlinedIcon fontSize='small'/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            title={title}
            primary={<Typography variant='body1' color='primary'>{title}</Typography>}
        />

        <ListItemSecondaryAction>
            <CircularProgress  color='primary' size='18px'/>
        </ListItemSecondaryAction>
    </ListItem>
);
