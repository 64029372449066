import { MutableRefObject, useEffect, useRef, useState } from "react";

export const useElementHeight = <T extends HTMLElement>(): [MutableRefObject<T | undefined>, number] => {
    const containerRef = useRef<T>();
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            setHeight(container.clientHeight);
        }
    }, [containerRef]);

    return [containerRef, height];
};
