import { combineReducers } from 'redux';
import {
    requestKnockUserToken,
    KnockActions,
    requestRoleSettings,
    updateRoleSettings
} from 'api/knock/knockActions';
import { RoleSettingsDTO } from 'api/knock/knockModel';

export type Lookup<T> = Record<string, T | undefined>;

const updateLookupState = <T>(state: Lookup<T>, id: string, value: T): Lookup<T> => {
    return {
        ...state,
        [id]: value,
    };
}

export type KnockState = {
    userToken: string | null;
    roleSettingsById: Lookup<RoleSettingsDTO>;
    roleSettingsLoadingById: Lookup<boolean>;
    roleSettingsUpdatingById: Lookup<boolean>;
};

const initialState: KnockState = {
    userToken: null,
    roleSettingsById: {},
    roleSettingsLoadingById: {},
    roleSettingsUpdatingById: {},
};

function userToken(state = initialState.userToken, action: KnockActions): string | null {
    switch (action.type) {
        case requestKnockUserToken.successType:
            return action.payload;
        default:
            return state;
    }
}

function roleSettingsById(state = initialState.roleSettingsById, action: KnockActions): Lookup<RoleSettingsDTO> {
    switch (action.type) {
        case requestRoleSettings.successType:
            const { roleId, settings } = action.payload;
            return updateLookupState(state, roleId, settings);
        default:
            return state;
    }
}

function roleSettingsLoadingById(
    state = initialState.roleSettingsLoadingById,
    action: KnockActions,
): Lookup<boolean> {
    switch (action.type) {
        case requestRoleSettings.initType:
            return updateLookupState(state, action.payload, true);
        case requestRoleSettings.successType:
            return updateLookupState(state, action.payload.roleId, false);
        case requestRoleSettings.errorType:
            return updateLookupState(state, action.payload.id, false);
        default:
            return state;
    }
}

function roleSettingsUpdatingById(
    state = initialState.roleSettingsUpdatingById,
    action: KnockActions,
): Lookup<boolean> {
    switch (action.type) {
        case updateRoleSettings.initType:
            return updateLookupState(state, action.payload.roleId, true);
        case updateRoleSettings.successType:
            return updateLookupState(state, action.payload.roleId, false);
        case updateRoleSettings.errorType:
            return updateLookupState(state, action.payload.id, false);
        default:
            return state;
    }
}

export const knock = combineReducers({
    userToken,
    roleSettingsById,
    roleSettingsLoadingById,
    roleSettingsUpdatingById,
});
