import { createSingleAction } from 'utils/store/actionUtils';

export const STORE_TASKS_MODAL_OPEN = 'store-tasks/MODAL_OPEN';
export const STORE_TASKS_MODAL_CLOSE = 'store-tasks/MODAL_CLOSE';

export const storeTasksModalOpen = createSingleAction<string, typeof STORE_TASKS_MODAL_OPEN>(
    STORE_TASKS_MODAL_OPEN,
);

export const storeTasksModalClose = createSingleAction<void, typeof STORE_TASKS_MODAL_CLOSE>(
    STORE_TASKS_MODAL_CLOSE,
);

export type StoreTasksModalAction =
    | ReturnType<typeof storeTasksModalOpen>
    | ReturnType<typeof storeTasksModalClose>;
