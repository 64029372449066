import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useLaunchStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectLaunchs, selectLaunchLoading } from 'store/modules/launch/launchSelectors';
import { LaunchAdd } from './launchItem/LaunchNew';
import { LaunchModal } from './launchItem/LaunchModal';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useActions } from 'utils/store/useActions';
import { launchModalOpen } from 'api/launch/launchActions';
import { SkeletonItem } from './launchItem/SkeletonItem';
import { ModalDeleteLaunch } from './launchItem/ModalDeleteLaunch';
import { LaunchReorder } from './launchReorder/LaunchReorder';

export const LaunchList = () => {
  const classes = useLaunchStyles();
  const launchs = useSelector(selectLaunchs);
  const isLoading = useSelector(selectLaunchLoading);
  const canCreateDelete = useSelector(selectHasAppAccess(AppName.AdminCompanyProfile));
  const actions = useActions({ openModal: launchModalOpen });

  const handleAdd = useCallback(() => {
    actions.current.openModal({ launch: undefined, isOpen: true })
  }, [actions]);

  if (isLoading) {
    return (<SkeletonItem items={3} />)
  }

  return (
    <Box className={classes.containerLaunch}>
      <LaunchReorder launchList={launchs} canCreateDelete={canCreateDelete}/>
      {canCreateDelete &&
        <>
          <LaunchAdd onAdd={handleAdd} />
          <LaunchModal />
          <ModalDeleteLaunch />
        </>
      }
    </Box>
  )
}