import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { AppRouter } from 'components/router/AppRouter';
import { LeftMenu } from 'modules/root/LeftMenu/LeftMenu';
import { TopMenu } from 'modules/root/TopMenu/TopMenu';
import { isProduction } from 'utils/globals';
import { useSelector } from 'react-redux';
import { selectSelfId } from 'store/modules/auth/authSelectors';
import { TopProgress } from './TopProgress';
import ReactGA from 'react-ga';
import { Breakpoints, UtilColors } from 'utils/styles/constants';

export const useContentStyles = makeStyles((theme: Theme) => ({
    bodyLayout: {
        height: '100%',
        width: '100%',
        background: UtilColors.Background,
    },
    content: {
        width: 'auto',
        height: '100%',
    },
    routerWrapper: {
        height: `calc(100% - 80px)`,
        overflow: 'auto',

        [theme.breakpoints.up(Breakpoints.Tablet)]: {
            height: '100%',
        },
    },
}));

export function Content() {
    const classes = useContentStyles([]);
    const selfUserId = useSelector(selectSelfId);

    useEffect(() => {
        if (selfUserId && isProduction) {
            ReactGA.set({
                userId: selfUserId,
            });
        }
    }, [selfUserId]);

    return (
        <>
            <TopProgress />
            <Box className={classes.bodyLayout}>
                <LeftMenu />
                <Box className={classes.content}>
                    <TopMenu />
                    <Box className={classes.routerWrapper}>
                        <AppRouter />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
