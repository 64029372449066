import React from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { selectUserCompany } from 'store/modules/company/companySelector';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { getLogoUrl } from 'utils/helpers/url';
import { Routes } from 'components/router/model';
import { generatePath, Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        boxSizing: 'border-box',
        width: '100%',
        padding: theme.spacing(2, 3),
        height: theme.spacing(9),
    },
    logo: {
        objectFit: 'contain',
        height: '100%',
        width: '100%',
        cursor: 'pointer'
    },
}));

export function LeftMenuLogo() {
    const classes = useStyles();
    const filesConfig = useSelector(getFilesConfig);
    const company = useSelector(selectUserCompany);
    const logoUrl = getLogoUrl(filesConfig, company);

    return (
        <Box className={classes.wrapper}>
            {logoUrl && 
                <Link
                    to={{
                        pathname: generatePath(Routes.Welcome),
                    }}
                >
                    <img src={logoUrl} className={classes.logo} alt="company logo"/>
                </Link>
            }
        </Box>
    );
}
