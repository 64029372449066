import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import React from 'react';
import { CheckedItems, OnCheck } from '../../../utils/hooks/useCheckdItems';
import { Skeleton } from '../../users/user-list/withSkeleton';
import { UserDTO } from '../../../api/user/userModel';

export interface ICheckboxClasses {
    iconCell: string;
    checkbox: string;
    checkboxChecked?: string;
}

export interface ICheckBoxCellPureProps {
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    classes: ICheckboxClasses,
    customClasses?: Array<string>,
    rowClassName?: string;
    rowId: string;
    row: Skeleton<UserDTO>
}

export const CheckBoxCellPure = ({
                                     customClasses = [],
                                     classes,
                                     checkedItems,
                                     onCheck,
                                     rowClassName,
                                     rowId,
                                     row,
                                 }: ICheckBoxCellPureProps) => {
    const name = `row_${rowId}`;

    return (
        <div className={clsx(...customClasses, rowClassName, classes.iconCell, classes.checkbox)}>
            <Checkbox
                color="primary"
                checked={checkedItems[rowId] ?? false}
                onChange={() => onCheck(!(checkedItems[rowId] ?? false), rowId)}
                classes={{ checked: classes.checkboxChecked }}
                name={name}
                inputProps={{ 'aria-label': name }}
            />
        </div>
    );
};

export interface ICheckBoxTitlePureProps {
    classes: ICheckboxClasses,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
}

export const CheckBoxTitlePure = ({
                                      classes,
                                      checkedAll,
                                      onCheckAll,
                                  }: ICheckBoxTitlePureProps) => {
    return (
        <Checkbox
            color="primary"
            checked={checkedAll}
            onChange={() => onCheckAll(!checkedAll)}
            classes={{ checked: classes.checkbox }}
        />
    );
};
