import React, { useEffect, useMemo } from 'react';
import { makeRowRenderer, makeSkeletons } from 'modules/shared/components/gridHelpers';
import { IStoreTaskGridRowData } from './model';
import { initialSortingRules, useColumns } from './columns';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { taskListCheckRequest } from 'api/task/taskActions';
import { selectStoreTasksNextPageToken, selectStoreTasksFetching } from 'store/modules/task/storeTask/storeTaskSelectors';
import { useLoad } from 'modules/task/taskList/hooks/useLoad';
import { storeTaskListSetSorting, taskListResetData } from 'store/modules/task/taskListComponent/taskListComponentActions';
import { useActions } from "utils/store/useActions";
import { StoreTaskStatusFilter } from 'api/task/storeTask/storeTaskModel';
import { selectStoreTasksSortBy } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { useSelector } from 'react-redux';
import { VirtualScrollGrid } from 'modules/shared/components/VirtualScrollGrid';
import { EmptyState } from 'modules/task/taskList/components/EmptyState';
import { t } from 'i18next';

export interface IStoreTasksGridProps {
    locationId: string;
    filter?: StoreTaskStatusFilter;
    filterTag?: Array<string>;
    filterCreatedBy?: Array<string>;
    searchTerm?: string;
    data: Array<IStoreTaskGridRowData>;
    isFetching: boolean;
    height?: number;
    tasksViewMode?: string;
}

const skeletons = makeSkeletons<IStoreTaskGridRowData>(20);

export const StoreTasksGrid: React.FC<IStoreTasksGridProps> = ({
    locationId,
    filter,
    filterTag,
    filterCreatedBy,
    searchTerm,
    data,
    isFetching,
    height = 500,
    tasksViewMode
}) => {
    const baseSearchRequest = useMemo(() => {
        return {
            locationId,
            filter,
            tags: filterTag,
            searchTerm,
            tasksViewMode,
            createdBy: filterCreatedBy
        };
    }, [locationId, filter, filterTag, searchTerm, tasksViewMode, filterCreatedBy]);
    const storeTasksSortBy = useSelector(selectStoreTasksSortBy);
    const { preload, nextPageToken } = useLoad(
        selectStoreTasksNextPageToken,
        selectStoreTasksFetching,
        taskListCheckRequest,
        taskListResetData,
        baseSearchRequest,
        storeTasksSortBy,
    );
    const columns = useColumns();
    const actions = useActions({ setSorting: storeTaskListSetSorting });
    const showEmptyState = data.length === 0 && !isFetching;
    const showSkeletons = data.length === 0 && isFetching;
    const totalData = showSkeletons ? skeletons : data;
    const dataGrid = useTable<IStoreTaskGridRowData>(
        {
            columns,
            data: totalData,
            manualSortBy: true,
            disableMultiSort: true,
            initialState: {sortBy: [ {
                id: storeTasksSortBy !== 'not-set' ? storeTasksSortBy?.id as string : initialSortingRules[0].id,
                desc: storeTasksSortBy !== 'not-set' ? storeTasksSortBy?.desc : initialSortingRules[0].desc,
            }]},
        },
        useFlexLayout,
        useSortBy,
    );

    const { state: { sortBy: sortByArray } } = dataGrid;
    useEffect(() => {
        if (sortByArray.length === 0) {
            dataGrid.setSortBy(initialSortingRules);
            return;
        } 
        const sortBy = sortByArray[0];
        actions.current.setSorting(sortBy);
    }, [actions, dataGrid, sortByArray]);

    return (
        <VirtualScrollGrid
            dataGrid={dataGrid}
            showEmptyState={showEmptyState}
            emptyState={<EmptyState text={`${t('Please check your search, or adjust task filters as necessary')}.`}/>}
            height={height}
            nextPageToken={nextPageToken}
            preload={preload}
            customRowRenderer={makeRowRenderer}
        />
    );
};
