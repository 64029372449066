import { Card, CardContent } from '@material-ui/core'
import { useLaunchStyles } from '../styles';
import { ReactNode } from 'react';

export const LaunchLayoutItem = ({ children }: { children: ReactNode }) => {
  const classes = useLaunchStyles();

  return (
    <Card className={classes.card} elevation={2}>
      <CardContent style={{ position: 'relative' }} >
        {children}
      </CardContent>
    </Card>
  )
}
