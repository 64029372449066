export enum ColumnId {
    Location = "locationId",
    Stats = "stats",
    StatsInTime = "statsInTime",
    Overdue = "overdue",
    SubmittedOverdue = "submittedOverdue",
    CompletedOverdue = "completedOverdue",
    Expired = "expired",
    Name = "name"
}
