/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWrapperStyles } from 'modules/legacyWrappers/wrapperStyles';
import { legacyAppUrl } from 'utils/globals';
import { useActions } from "utils/store/useActions";
import { navigateTo, notifyLegacyPageLoaded } from 'store/modules/appState/appStateActions';
import { useLocation } from 'react-router';
import { Box } from '@material-ui/core';
import { LegacyPageLoader } from './LegacyPageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { adminSubRoutes, Routes, UsersBetaAppPath, LegacyRoutes } from '../../components/router/model';
import { EmbeddedSubRoutesTopMenu } from '../../components/router/EmbeddedSubroutesTopMenu';
import { selectUserCompany } from 'store/modules/company/companySelector';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { selectUserToken } from 'store/modules/auth/authSelectors';
import i18n from 'i18n';
import { updateTreeHierarchy } from 'store/modules/hierarchy/hierarchyActions';
import { LegacyEventIframe } from 'utils/helpers/legacyEventIframe';

export type LegacyRouteState = {
    params?: Record<string, string>;
}

const stateParamsToQuery = (params: Record<string, string>) => {
    return new URLSearchParams(params).toString();
};

export function createLegacyPage(relativePath: string, showTopMenuAdmin: boolean = false, currentAppName: string = '') {
    return function LegacyPage() {
        const dispatch = useDispatch();
        const classes = useWrapperStyles([]);

        const iframeRef = useRef<null | HTMLIFrameElement>(null);
        const [isLoaded, setIsLoaded] = useState<boolean>(false);
        const [frameAddress, setFrameAddress] = useState<string>('');
        const actions = useActions({ notifyLegacyPageLoaded, updateTreeHierarchy });
        const companyInformation = useSelector(selectUserCompany);
        const userToken = useSelector(selectUserToken);
        const isNewCompanyProfileEnabled = useFeatureState(FeatureFlags.NewCompanyProfile);
        const isLanguageEnabled = useFeatureState(FeatureFlags.Language);
        const { key: locationKey, state } = useLocation<LegacyRouteState>();
        const getColor = useMemo(() => (isNewCompanyProfileEnabled && relativePath.includes(LegacyRoutes.AnalyticsSubPath) ? `color=${companyInformation?.primaryColor?.replace('#','')}` : ''), [isNewCompanyProfileEnabled])
        const activeLanguage = useMemo(() => (isLanguageEnabled ? `lan=${i18n.language}` : ''), [isLanguageEnabled]);
        const pageLink = useMemo(() => {
            let relativePathClone = relativePath;
            if(relativePath === LegacyRoutes.Library || relativePath === LegacyRoutes.ContentLibrary){
                return `${legacyAppUrl}/${relativePath}?${activeLanguage}#${userToken}`
            }
            const url = `${legacyAppUrl}/${relativePathClone}`;
            const { params } = state || {};

            return params
                ? `${url}?${stateParamsToQuery(params)}${activeLanguage ? `&${activeLanguage}` : ''}`
                : `${url}?${activeLanguage}${getColor ? `&${getColor}` : ''}`;
        }, [state]);

        const handleIframeLoad = useCallback(() => {
            setIsLoaded(true);
            const currentFrameAddress = iframeRef?.current?.src;
            if (currentFrameAddress === frameAddress && frameAddress.includes('/Admin/Edit_User?id=')) {
                dispatch(navigateTo(UsersBetaAppPath));
            }
            setFrameAddress(currentFrameAddress ?? '');
        }, [setIsLoaded, iframeRef, frameAddress, setFrameAddress]);

        useEffect(() => setIsLoaded(false), [pageLink]);

        useEffect(() => {
            if (iframeRef.current) {
                iframeRef.current.addEventListener('load', handleIframeLoad);
                window.addEventListener('message', listenMessageFromIframe);
                return () => {
                    iframeRef.current?.removeEventListener('load', handleIframeLoad);
                    window.removeEventListener('message', listenMessageFromIframe);
                };
            }
            return () => void 0;
        }, [iframeRef.current, handleIframeLoad]);

        useEffect(() => {
            if (isLoaded) {
                actions.current.notifyLegacyPageLoaded(relativePath);
            }
        }, [isLoaded, relativePath, actions]);

        useEffect(() => {
            const iframe = iframeRef.current;
            if (iframe && isLoaded) {
                iframe.contentWindow?.postMessage({ type: LegacyEventIframe.UPDATE_TOKEN, userToken }, legacyAppUrl);
            }
        }, [userToken, isLoaded]);

        const iframe = useMemo(() => (
            <iframe
                ref={iframeRef}
                className={classes.root}
                src={pageLink}
                allow="fullscreen"
                allowFullScreen
            />
        ), [classes, pageLink, locationKey]);

        const listenMessageFromIframe = useCallback((event: MessageEvent) => {
            event.preventDefault();
            event.stopPropagation();
            const { data } = event;
            switch (data.type) {
                case LegacyEventIframe.UPDATE_TREE:
                    actions.current.updateTreeHierarchy();
                    break;
                case LegacyEventIframe.ERROR_AUTHENTICATION:
                    window.location.reload();
                    break;
                default:
                    break;
            }
        }, [actions]);

        if (showTopMenuAdmin) {
            return (
                <Box height={'98%'} overflow={'hidden'}>
                    <EmbeddedSubRoutesTopMenu
                        apps={adminSubRoutes}
                        path={Routes.Admin}
                        currentAppName={currentAppName}
                    />
                    <Box className={classes.iframeWrapper}>
                        {iframe}

                        {!isLoaded && <LegacyPageLoader />}
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box className={classes.iframeWrapper}>
                    {iframe}

                    {!isLoaded && <LegacyPageLoader/>}
                </Box>
            );
        }
    };
}
