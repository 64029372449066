import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Paper } from '@material-ui/core';
import { useDropzoneStyles } from './styles';
import { CloudUpload } from '@material-ui/icons';
import { Trans } from 'react-i18next';

export enum EDropzoneVariant {
    Light = 'light',
    Dark = 'dark',
}

export interface IDropzoneProps {
    onDrop?: DropzoneOptions['onDrop'];
    variant?: EDropzoneVariant;
}

export function Dropzone({ onDrop, variant = EDropzoneVariant.Dark }: IDropzoneProps) {
    const classes = useDropzoneStyles([]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const className = variant === EDropzoneVariant.Dark ? classes.dropzoneWrapper : classes.lightDropzoneWrapper;

    return (
        <Paper className={className} {...getRootProps()}>
            <input {...getInputProps()}/>
            {variant === EDropzoneVariant.Light && (
                <CloudUpload color="primary"/>
            )}
            <p><Trans>Drag files here, or click to select files</Trans></p>
        </Paper>
    );
}
