import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { fromEnum } from 'utils/helpers/fromEnum';
import { resolveStoreTaskDisplayedStatus } from 'utils/helpers/taskHelpers';
import { ParentTaskShort } from '../parentTask/parentTaskModel';
import { StoreTaskShort } from '../storeTask/storeTaskModel';
import { TaskStatus } from '../taskModel';

export enum DisplayedTaskStatus {
    Completed = 'completed',
    CompletedOverdue = 'completed overdue',
    Declined = 'declined',
    Submitted = 'submitted',
    SubmittedOverdue = 'submitted overdue',
    Overdue = 'overdue',
    Expired = 'expired',
    Default = 'default', // Incomplete
}

export const taskFlagsSchema = t.type({
    isCompleted: t.boolean,
    isOverdue: t.boolean,
    isExpired: t.union([t.boolean, t.undefined]),
    isExpirable: t.union([t.boolean, t.undefined]),
    isCompletedOverdue: t.union([t.boolean, t.undefined]),
    isSubmittedOverdue: t.union([t.boolean, t.undefined]),
});

export const taskStatusSchema = t.type({
    isOverdue: t.boolean,
    isExpired: t.union([t.boolean, t.undefined]),
    isExpirable: t.union([t.boolean, t.undefined]),
    status: fromEnum('TaskStatus', TaskStatus),
    isCompletedOverdue: t.union([t.boolean, t.undefined]),
    isSubmittedOverdue: t.union([t.boolean, t.undefined]),
});

export const locationTaskStatus = tPromise.createType(
    (param): { displayedStatus: DisplayedTaskStatus } => {
        const value = param as t.TypeOf<typeof taskFlagsSchema>;
        
        let displayed = DisplayedTaskStatus.Default;
        switch (true) {
            case value.isCompleted:
                displayed = value.isCompletedOverdue ? DisplayedTaskStatus.CompletedOverdue : DisplayedTaskStatus.Completed
                break;
            case value.isSubmittedOverdue:
                displayed = DisplayedTaskStatus.SubmittedOverdue
                break;
            case value.isExpired:
                displayed = DisplayedTaskStatus.Expired
                break;
            case value.isOverdue:
                displayed = DisplayedTaskStatus.Overdue
                break;
        }

        return {
            displayedStatus: displayed
        };
    },
    () => undefined,
);

export const storeTaskStatus = tPromise.createType((param): { displayedStatus: DisplayedTaskStatus } => {
    const value = param as t.TypeOf<typeof taskStatusSchema>;
    return {
        displayedStatus: resolveStoreTaskDisplayedStatus(value),
    };
},
    () => undefined,
);

export const taskShortSchemaBase = t.type({
    id: t.string,
    title: t.string,
    dueDate: t.string,
    createdBy: t.string,
    createdAt: t.string,
    prototypeId: t.union([t.undefined, t.string]),
});

export type TaskListShortItem = { createdBy: string } & (ParentTaskShort | StoreTaskShort);

export const MAX_TASK_LIST_REQUEST_LIMIT = 100;

export const submittedTasksSchemaType = tPromise.createType(
    (encoded): { submittedStoreTasks: number } => {
        const count = encoded ? Number(Reflect.get(encoded as {}, 'submitted')) || 0 : 0;
        return { submittedStoreTasks: count };
    },
    () => undefined,
);
