import React, { useCallback } from "react";
import { FormControl, MenuItem, Select, SelectProps } from '@material-ui/core';
import { FilterOption } from "../../task/taskList/components/model";
import { IItemComponent } from 'components/basicInputs/selectInput/model';

export type OptionsDropdownCustomProps<T extends string> = {
    className?: string;
    selectClasses?: SelectProps['classes'];
    unsetValue: T | string;
    options: FilterOption<T>[];
    value?: T;
    onValueChange: (newValue?: T) => void;
    disabled?: boolean;
    RenderPreview: React.FC<IItemComponent<T>>;
    RenderCreator: React.FC;
};

export function OptionsDropdownCustom<T extends string>({
    className,
    selectClasses,
    options,
    unsetValue,
    value,
    onValueChange,
    disabled,
    RenderPreview,
    RenderCreator
}: OptionsDropdownCustomProps<T>) {
    const valueChangeHandler = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const taskStatus = e.target.value === unsetValue
            ? undefined
            : e.target.value as T;

        onValueChange(taskStatus);
    }, [unsetValue, onValueChange]);

    return (
        <FormControl className={className}>
            <Select
                disabled={disabled}
                classes={selectClasses}
                value={value ?? unsetValue}
                onChange={valueChangeHandler}
                variant="outlined"
            >
                {options.map((option, idx) => {
                    const item: T = option as unknown as T;
                    let index = idx+option.value;
                    return <MenuItem
                        key={index}
                        value={option.value}
                    >
                        <RenderPreview item={item} />
                    </MenuItem>
                })}
                <RenderCreator  />    
            </Select>
        </FormControl>
    );
}
