import { Notification, RoleSettingsDTO } from 'api/knock/knockModel';
import { NotificationRowData } from './model';

export const settingsToRowData = (roleSettings: RoleSettingsDTO): NotificationRowData[] => {
    return Object
        .entries(roleSettings)
        .map(([notificationType, settings]) => ({
            notificationType: notificationType as Notification,
            ...settings,
        }));
};

export const rowDataToSettings = (rowData: NotificationRowData[]): RoleSettingsDTO => {
    return rowData.reduce((acc: RoleSettingsDTO, item: NotificationRowData) => {
        const { notificationType, ...settings } = item;
        acc[notificationType] = settings;
        return acc;
    }, {} as RoleSettingsDTO);
};
