import React, { useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import { debounce } from "lodash";

export const AutoSaveFormik: React.FC<{ debounceMs?: number }> = ({ debounceMs = 500 }) => {
  const formik = useFormikContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(debounce(formik.submitForm, debounceMs), [
    formik.submitForm,
    debounceMs
  ]);

  useEffect(() => {
    formik.dirty && debouncedSubmit();
  }, [debouncedSubmit, formik.dirty, formik.values]);

  return <></>;
};
