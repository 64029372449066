import { FeedItem } from '@knocklabs/client';
import { CheckmarkCircle, useKnockFeed } from '@knocklabs/react-notification-feed';
import React, { MouseEvent, useCallback } from 'react';

export type CloseButtonProps = {
    item: FeedItem;
    onClick?: () => void;
}

export const CloseButton = ({ item, onClick }: CloseButtonProps) => {
    const { colorMode, feedClient } = useKnockFeed();

    const innerOnClick = useCallback((e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        feedClient.markAsSeen(item);
        onClick?.();
    }, [feedClient, item, onClick]);

    return (
        <button
            onClick={innerOnClick}
            className={`rnf-archive-notification-btn rnf-archive-notification-btn--${colorMode}`}
        >
            <CheckmarkCircle/>
        </button>
    );
};
