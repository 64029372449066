import React, { useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useActions } from "utils/store/useActions";
import { authLogout } from 'api/auth/authActions';
import { IDLE_REMINDER_AUTOLOGOUT_SECONDS, IDLE_REMINDER_COUNTDOWN_SECONDS } from 'utils/globals';
import { useClientIdleTimer } from 'utils/hooks/useClientIdleTimer';
import { Trans } from 'react-i18next';

const FULLSCREEN_BREAKPOINT = 625;

const buttonsCommon = (theme: Theme) => ({
    font: `'Inter', sans-serif`,
    fontWeight: 500,
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(3) + 'px',
    letterSpacing: '0.4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: theme.spacing(0.5),
    backgroundColor: 'unset !important',
    padding: theme.spacing(0.75, 2),
});

const useStyles = makeStyles((theme: Theme) => ({
    dialogPaper: {
        [theme.breakpoints.up(FULLSCREEN_BREAKPOINT)]: {
            width: theme.spacing(75),
            height: theme.spacing(23),
        },
        borderRadius: theme.spacing(0.5),
    },
    dialogTitle: {
        fontFamily: `'Inter', sans-serif`,
        fontSize: theme.spacing(2.5),
        lineHeight: theme.spacing(4) + 'px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        color: 'rgba(0,0,0,0.78)',
    },
    dialogText: {
        font: `'Inter', sans-serif`,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: theme.spacing(2),
        lineHeight: theme.spacing(3) + 'px',
        letterSpacing: '0.15px',
    },
    dialogContent: {
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    dialogActions: {
        paddingBottom: theme.spacing(1.5),
        boxSizing: 'border-box',
    },
    primaryButton: {
        ...(buttonsCommon(theme)),
        color: `#007DFF !important`,
        borderColor: '#007DFF',
        marginRight: theme.spacing(0.5),
        '&:hover': {
            color: `#007DFF !important`,
            backgroundColor: 'unset !important',
        },
    },
    secondaryButton: {
        ...(buttonsCommon(theme)),
        color: `#FF505F !important`,
        borderColor: '#FF505F',
        '&:hover': {
            color: `#FF505F !important`,
            backgroundColor: 'unset !important',
        },
    },
}));

const IDLE_REMINDER_THRESHOLD_SECONDS = IDLE_REMINDER_AUTOLOGOUT_SECONDS - IDLE_REMINDER_COUNTDOWN_SECONDS;

export function IdleReminder() {
    const classes = useStyles();
    const isFullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(FULLSCREEN_BREAKPOINT));
    const actions = useActions({ logout: authLogout });
    const idleSeconds = useClientIdleTimer();
    const remainingSeconds = IDLE_REMINDER_AUTOLOGOUT_SECONDS - idleSeconds;
    const showReminder = idleSeconds > IDLE_REMINDER_THRESHOLD_SECONDS;

    useEffect(() => {
        if (remainingSeconds <= 0) {
            actions.current.logout();
        }
    }, [actions, remainingSeconds]);

    if (!showReminder) {
        return null;
    }

    return (
        <Dialog
            open={showReminder}
            fullScreen={isFullScreen}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogTitle>
                <Typography component="span" className={classes.dialogTitle}>
                    <Trans>Auto Logout</Trans>
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography component="span" className={classes.dialogText}>
                    <Trans  i18nKey="textToremainingSeconds" remainingSeconds={remainingSeconds}>Due to inactivity you will be logged out in <strong>{remainingSeconds}</strong> seconds</Trans>
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    className={classes.primaryButton}
                    color="primary"
                >
                    <Trans>Continue</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
