import { IStoreState } from 'store/rootReducer';

export const selectUserProvisioning = (state: IStoreState) =>
    state.integration.getIntegrations.userProvisioning;
export const selectUserPerformances = (state: IStoreState) =>
    state.integration.getIntegrations.performance;
export const selectUserAISidekick = (state: IStoreState) =>
    state.integration.getIntegrations.AISidekick;

export const selectIntegrationFetching = (state: IStoreState) =>
    state.integration.integrationFetching;
