import { IStoreState } from 'store/rootReducer';

export const selectLaunchLoading = (state: IStoreState) => state.launch.launchsFetching;
export const selectLaunchs = (state: IStoreState) => state.launch.launchs;
export const selectLaunchUpdatingById = (state: IStoreState) => state.launch.launchUpdatingById;
export const selectLaunchAddFetching = (state: IStoreState) => state.launch.launchAddFetching;
export const selectLaunchModalForm = (state: IStoreState) => state.launch.launchModalForm;
export const selectLaunchModalDelete = (state: IStoreState) => state.launch.launchModalDelete;
export const selectLaunchCoverFetching = (state: IStoreState) => state.launch.launchCoverFetching;
export const selectLaunchCoverEditorOpened = (state: IStoreState) => state.launch.launchCoverEditorOpened;
export const selectLaunchPageCoverImage = (state: IStoreState) => state.launch.launchPageCoverImage;
export const selectLaunchReorderFetching = (state: IStoreState) => state.launch.launchReorderFetching;
export const selectLaunchReorderRestoreData = (state: IStoreState) => state.launch.launchReorderRestoreData;
