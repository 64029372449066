import { Message, useChannelStateContext } from 'stream-chat-react';
import { Close } from '@material-ui/icons';
import { Paper, makeStyles, Theme, styled, ButtonBase } from '@material-ui/core';
import { StreamChatType } from 'modules/connect/messenger/components/MessageInput/hooks/types';
import { useWorkspaceController } from '../WorkspaceController';
import { TeamMessage } from './TeamMessage';
import { Breakpoints } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const StyledPaper = styled(Paper)(({ theme }) => ({
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      position: 'absolute',
      height: '100%',
      right: 0,
      zIndex: 10,
      maxWidth: '600px',
      [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        width: '100%',
        maxWidth: '100%'
      },
  }));

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '100%',
    borderBottom: '1px solid #0000001a',
    alignItems: 'center',
    display: 'flex',
    gap: '20px',
    height: '62px',
    justifyContent: 'space-between',
    minHeight: 62,
    padding: '0 20px',
    paddingInlineEnd: 10,
    '& .title': {
      fontWeight: theme.typography.fontWeightBold,
    }
  },
  pinnedMessagesList: {
    paddingTop: 20,
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    '& .str-chat__message-simple__actions,& .str-chat__message-team-pin-indicator,& .str-chat__message-team-actions': {
      display: 'none'
    },
    '& .pinned-message': {
      marginLeft: 0.5
    },
    '& .str-chat__message-team-meta': {
      flexDirection: 'row',
      alignItems: 'center',
      '& time': {
        marginBottom: 0
      },
      '&__time-jump': {
        display: 'flex',
        gap: '4px',
        justifyContent: 'center',
        alignItems: 'center',
        '&--jump': {
          cursor: 'pointer'
        }
      }
    },
    '& .str-chat__message-team': {
      position: 'relative',
      display: 'flex',
      padding: '5px 20px',
    },
    '& .str-chat__message-team-author':{
      paddingRight: '4px'
    }
  }
}));

export const PinnedMessageList = () => {
  const { pinnedMessageListOpen, togglePinnedMessageListOpen } = useWorkspaceController();
  const { channel } = useChannelStateContext<StreamChatType>();
  const classes = useStyles();

  if (!pinnedMessageListOpen) return null;

  return (
    <StyledPaper>
        <div className={classes.header}>
          <div className='title'><Trans>Pins</Trans></div>
          <ButtonBase onClick={togglePinnedMessageListOpen}>
              <Close/>
          </ButtonBase>
        </div>
        <div className={classes.pinnedMessagesList}>
          {channel.state.pinnedMessages.map((message) => (
            <Message
              groupStyles={['single']}
              Message={TeamMessage}
              key={message.id}
              message={message}
            />
          ))}
        </div>
    </StyledPaper>
  );
};
