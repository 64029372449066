import { Button, Fade, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { DefaultTasksViewMode } from 'api/task/taskFilter/taskFilterModel';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { t } from 'i18next';
import { useStylesViewSwtich } from './useStylesViewSwitch';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/store/useActions';
import { taskFilterTab } from 'store/modules/task/taskFilter/taskFilterActions';
import { selectAppsListByName } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { TasksCounterBadge } from 'modules/root/LeftMenu/badgeWrappers';

const routTaskList = '/tasks/list';
export const TasksButton = ({ ...res}) => {
    const classes = useStylesViewSwtich();
    const actions = useActions({
        updateTaskFilterTab: taskFilterTab
    });
    const canAccessTask = useSelector(selectAppsListByName)[AppName.Tasks];
    const btnClass = clsx(res.className, classes.button);
    const restProps = {...res, className: btnClass}

    const handleFilterTasks = () => {
        actions.current.updateTaskFilterTab(DefaultTasksViewMode);
    }

    if(!canAccessTask){
        return <></>
    }

    return (
        <Button
            component={NavLink}
            to={routTaskList}
            onClick={handleFilterTasks}
            {...restProps}
        >
            <Tooltip title={`${t('Task By List')}`} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <ListAltIcon />
            </Tooltip>
            <TasksCounterBadge isFromSwitchTab />
        </Button>
    );
};
