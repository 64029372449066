import { getFaviconGoogleService } from 'api/helpers';
import { useGetThumbnailUrl } from 'modules/task/attachments/components/AttachmentThumbnail/AttachmentThumbnail';
import { useRef } from 'react'

type FaviconThumbanailProps = {
  caption: string,
  target: string,
  thumbnail: string,
}
export const FaviconThumbanail = (props: FaviconThumbanailProps) => {
  const ref = useRef<HTMLImageElement>(null);
  const imageSrc = useGetThumbnailUrl()(props?.thumbnail);
  const handleError = () => {
      if (ref.current) {
          ref.current.src = getFaviconGoogleService(props.target);
      }
  };
  return (
      <img
        width={80}
        height={80}
        ref={ref}
        alt={props.caption}
        src={imageSrc}
        style={{
          filter: 'brightness(110%) contrast(90%)',
          borderRadius: '25%'
        }}
        onError={handleError}
        role="presentation"
      />
  );
}
