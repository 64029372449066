import React, { useCallback, useEffect, useMemo } from 'react';
import { TaskListPageLayout } from 'modules/task/taskList/components/TaskListPageLayout';
import { TaskByLocationsHeader } from 'modules/task/taskList/views/TaskByLocationsView/TaskByLocationsHeader';
import { useActions } from "utils/store/useActions";
import { requestParentTaskDetailsCombined } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { useSelector } from 'react-redux';
import {
    selectChildrenTasks,
    selectParentChildrenTasks,
} from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { HeaderGroup, Row, useExpanded, useSortBy, useTable } from 'react-table';
import {
    TaskByLocationCellsIds,
    useTasksByLocationsColumns,
} from 'modules/task/taskList/views/TaskByLocationsView/hooks/useTasksByLocationsColumns';
import { ParentTaskChildModel, ParentTaskDetailsModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { useTaskByLocationStyles } from 'modules/task/taskList/hooks/useTaskByLocationStyles';
import {
    TaskByLocationCellRenderer,
    TaskByLocationHeaderCellRenderer,
} from 'modules/task/taskList/views/TaskByLocationsView/cells/TaskByLocationCellRenderer';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { StyledTable, StyledTableBody, StyledTableHead } from 'modules/shared/components/StyledTable';
import { Paper, TableContainer, TableRow } from '@material-ui/core';
import { selectParentTaskById } from 'store/modules/task/parentTask/parentTaskSelectors';
import { useQueryParams } from 'utils/hooks';
import { validate } from 'uuid';
import { TaskQueryParams } from 'modules/task/common/model';
import { StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { nonEmpty } from 'utils/helpers/collections';
import { ItemsById } from 'model/entity';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { RemoveTaskByLocationConfirmation } from './RemoveTaskByLocationConfirmation';

export type ChildTaskWithNested = (ParentTaskDetailsModel | ParentTaskChildModel | StoreTaskFullModel) & {
    isSkeleton?: boolean;
}

function makeSkeletons(parentId: string, count: number): Array<Partial<ChildTaskWithNested>> {
    return new Array(count).fill(0).map((value, index) => ({
        id: `${parentId}_${index}`,
        isSkeleton: true,
    }));
}

function getRows(
    id: string,
    isParentTask: boolean,
    parentChildTasks: ItemsById<string[]>,
    childrenTasksByIds: ItemsById<ParentTaskDetailsModel | ParentTaskChildModel | StoreTaskFullModel>,
): ChildTaskWithNested[] {
    const childTasks = parentChildTasks[id];

    if (childTasks) {
        return childTasks
            .map(childTask => childrenTasksByIds[childTask])
            .filter(nonEmpty);
    }

    return isParentTask
        ? makeSkeletons(id, 3) as ChildTaskWithNested[]
        : [];
}

const initialSortBy = [{ id: TaskByLocationCellsIds.Status, desc: false }];

export type TaskByLocationsViewProps = {
    parentTaskId: string;
}

export function TaskByLocationsView({ parentTaskId }: TaskByLocationsViewProps) {
    const classes = useTaskByLocationStyles([]);
    const {
        openTask: openTaskId,
        expand: expandRows,
    } = useQueryParams<TaskQueryParams>();
    const autoExpandEnabled = useFeatureState(FeatureFlags.AutoExpandLocationView);
    const parentTask = useSelector(selectParentTaskById)[parentTaskId];

    const actions = useActions({
        taskDetailsCombined: requestParentTaskDetailsCombined.init,
        setModalTaskId: storeTaskSetActiveId,
    });

    useEffect(() => {
        actions.current.taskDetailsCombined(parentTaskId);
    }, [actions, parentTaskId]);

    useEffect(() => {
        if (openTaskId && validate(openTaskId)) {
            actions.current.setModalTaskId({taskId: openTaskId});
        }
    }, [actions, openTaskId]);

    const childrenTasksByIds = useSelector(selectChildrenTasks);
    const parentChildTasks = useSelector(selectParentChildrenTasks);
    const columns = useTasksByLocationsColumns(parentTask?.isApproval);

    const data = useMemo(() => {
        return getRows(parentTaskId, true, parentChildTasks, childrenTasksByIds);
    }, [parentTaskId, parentChildTasks, childrenTasksByIds]);

    const getSubRows = useCallback(({ id, ...rest }: ChildTaskWithNested) => {
        return getRows(id, rest.hasOwnProperty('storeTasks'), parentChildTasks, childrenTasksByIds);
    }, [parentChildTasks, childrenTasksByIds]);

    const { rows, prepareRow, getTableProps, getTableBodyProps, ...dataGrid } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: initialSortBy,
            },
            autoResetSortBy: false,
            getSubRows,
            autoResetExpanded: false,
        },
        useSortBy,
        useExpanded,
    );

    const { state: { sortBy: sortByArray } } = dataGrid;
    useEffect(() => {
        if (sortByArray.length === 0) {
            dataGrid.setSortBy(initialSortBy);
        }
    }, [dataGrid, sortByArray]);

    useEffect(() => {
        if (autoExpandEnabled && expandRows) {
            dataGrid.toggleAllRowsExpanded(true);
        }
        // TODO: dataGrid is changed on every render so we can't include it into the dependency list
        // need to figure out how to make it stable and still be able to expand all rows
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoExpandEnabled, expandRows])

    const renderHeaderRow = useCallback((headerGroup: HeaderGroup<any>) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        return (
            <TableRow {...headerGroupProps}>
                {headerGroup.headers.map(
                    header => (
                        <TaskByLocationHeaderCellRenderer
                            key={header.id}
                            classes={classes}
                            header={header}
                        />
                    ),
                )}
            </TableRow>
        );
    }, [classes]);

    const renderRow = useCallback((row: Row<ParentTaskChildModel>) => {
        prepareRow(row);

        return (
            <TableRow className={classes.bodyRow} {...row.getRowProps()}>
                {row.cells.map((cell, i) => (
                    <TaskByLocationCellRenderer
                        key={i}
                        classes={classes}
                        cell={cell}
                    />
                ))}
            </TableRow>
        );
    }, [prepareRow, classes]);

    return (
        <TaskListPageLayout header={<TaskByLocationsHeader />}>
            <TableContainer component={Paper}>
                <StyledTable className={classes.table} {...getTableProps()}>
                    <StyledTableHead>
                        {dataGrid.headerGroups.map(renderHeaderRow)}
                    </StyledTableHead>
                    <StyledTableBody {...getTableBodyProps()}>
                        {rows.map(row => renderRow(row))}
                    </StyledTableBody>
                </StyledTable>
            </TableContainer>
            <StoreTaskView />
            <RemoveTaskByLocationConfirmation />
        </TaskListPageLayout>
    );
}
