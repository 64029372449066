import { useTaskGalleryStyles } from './useTaskGalleryStyles'
import { Box, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TaskSection } from '../singleTask/components/TaskSection';

export const TaskGallerySkeleton = () => {
  const classes = useTaskGalleryStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
          <Skeleton width="200px"  animation="wave"/>
          <TaskSection mt={2} sectionTitle={ <Skeleton width="90px" animation="wave" />}>
            <Box ml={5}>
              <Skeleton height={130} width="130px" animation="wave"/>
            </Box>
          </TaskSection>
      </CardContent>
  </Card>
  )
}
