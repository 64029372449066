import React from 'react'
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';
import { CreatorFilter } from '../common/CreatorFilter';
import { useSelector } from 'react-redux';
import { calendarFilterCreator } from 'store/modules/task/calendarFilter/calendarFilterTaskActions';
import { selectCalendarFilterCreatedBy } from 'store/modules/task/calendarFilter/calendarFilterSelectors';

export const CalendarCreatorFilter = () => {
  const isCreatorFilterEnabled = useFeatureState(FeatureFlags.CreatorFilter);
  const actions = useActions({ calendarFilterCreator });
  const initCalendarFilterCreatredBy = useSelector(selectCalendarFilterCreatedBy);
  const handleCreatedBy = (creators: string[]) => actions.current.calendarFilterCreator(creators);

  if(!isCreatorFilterEnabled) return <></>;

  return (
    <CreatorFilter
        onCreatorsValuesChange={handleCreatedBy}
        initialCreatorsSelected={initCalendarFilterCreatredBy}
    />
  )
}
