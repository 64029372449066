import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppName } from 'store/modules/apps/appsModel';
import { selectAppListLoading, selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { Routes } from '../model';
import { PageLoader } from '../PageLoader';

export function withPermissionsCheck<TProps = {}>(app: AppName) {
    return (
        (WrappedComponent: React.FC<TProps>) =>
            (props: TProps) => {
                const appsLoading = useSelector(selectAppListLoading);
                const hasAccess = useSelector(selectHasAppAccess(app));

                if (appsLoading) {
                    return <PageLoader/>;
                }
                if (!hasAccess) {
                    return <Redirect to={Routes.Forbidden}/>;
                }
                return <WrappedComponent {...props}/>;
            }
    );
}
