import React from 'react';
import { MessageInput, useChatContext } from 'stream-chat-react';
import { useDirectMessageUser } from '../../hooks/useDirectMessageUser';
import { MessageInputPlaceholder } from '../placeholders/MessageInputPlaceholder';
import { t } from 'i18next';

export function DirectMessageInput() {
    const chatContext = useChatContext();
    const user = useDirectMessageUser(chatContext);

    if (user?.isDeleted) {
        return (
            <MessageInputPlaceholder
                text={`${t('This user has been deactivated and cannot be messaged')}`}
            />
        )
    }

    return (
        <MessageInput focus maxRows={5} grow mentionQueryParams={{filters: {deactivated_at:{ $exists: false}} }}/>
    );
}
