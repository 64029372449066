import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectLaunchReorderRestoreData } from 'store/modules/launch/launchSelectors';

export const ResetLaunchReorderForm = () => {
  const isLaunchReorderRestoreData = useSelector(selectLaunchReorderRestoreData);
  const { resetForm } = useFormikContext();

  useEffect(()=>{
    if(isLaunchReorderRestoreData){
      resetForm();
    }
  }, [isLaunchReorderRestoreData, resetForm]);

  return (
    <></>
  )
}
