import React, { useCallback, } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { UserRoleDTO } from 'api/core/model';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type RoleSelectProps = {
    roles: UserRoleDTO[];
    isLoading: boolean;
    onRoleChange?: (role: UserRoleDTO | null) => void;
}

const getOptionLabel = ({ description }: UserRoleDTO) => description;

export const RoleSelect = ({ isLoading, roles, onRoleChange }: RoleSelectProps) => {
    const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
        const adornment = (
            <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
            </React.Fragment>
        );
        return (
            <TextField
                {...params}
                label={<Trans>User Role</Trans>}
                placeholder={`${t('Please, enter the role to filter role list below')}...`}
                variant="outlined"
                margin="dense"
                fullWidth
                InputProps={{
                    ...params.InputProps,
                    endAdornment: adornment,
                }}
            />
        );
    }, [isLoading]);

    const onChangeHandler = useCallback((_: React.ChangeEvent<{}>, value: UserRoleDTO | null) => {
        onRoleChange?.(value);
    }, [onRoleChange]);

    return (
        <Autocomplete
            disabled={isLoading}
            autoHighlight
            options={roles}
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
            noOptionsText={<Trans>No roles found</Trans>}
            onChange={onChangeHandler}
        />
    );
};
