import React from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { Breakpoints } from 'utils/styles/constants';
import { ResetStoresButton } from './components/ResetStoresButton';
import { useLocationPickerModalStyles } from './styles';
import { LocationPickerModalProps } from './types';
import { renderTitleText } from './helpers';
import { useButtonStyles } from 'utils/styles/buttons';
import { Scrollbar } from 'components/containers/Scrollbar';
import { SearchStore } from '../LocationPicker/SearchsStore';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

export function LocationPickerModal({
    children,
    storesSelected,
    open,
    onSave,
    onCancel,
    onReset,
    onSelectStore = () => {},
    selectedStores = [],
    showSearchByStore = false
}: LocationPickerModalProps) {
    const isTasksSearchByStoreEnabled = useFeatureState(FeatureFlags.TasksSearchByStore);
    const classes = useLocationPickerModalStyles();
    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, classes.fixedButtons, 'primary', 'dark');
    const cancelButtonClasses = clsx(buttonClasses.muiRoundedButton, classes.fixedButtons, 'dark');
    const fullScreen = useMediaBefore(Breakpoints.MobileLarge);
    const dialogTitleClasses = clsx(classes.dialogTitle, {
        [classes.dialogTitleSelected]: storesSelected > 0,
    });
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullScreen={fullScreen}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogTitle className={dialogTitleClasses} disableTypography>
                <Box>
                    {storesSelected > 0 && onReset && <ResetStoresButton onClick={onReset} />}
                    <Typography className={classes.dialogTitleTypography} component="span">
                        {renderTitleText(storesSelected)}
                    </Typography>
                </Box>
                {isTasksSearchByStoreEnabled && showSearchByStore &&
                    <SearchStore
                        onSelectStore={onSelectStore}
                        selectedStores={selectedStores}
                    />
                }
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Scrollbar>
                    <Box>
                        {children}
                    </Box>
                </Scrollbar>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button className={cancelButtonClasses} onClick={onCancel}>Cancel</Button>
                {onSave && <Button className={saveButtonClasses} onClick={onSave}>Save</Button>}
            </DialogActions>
        </Dialog>
    );
}
