import { Location } from "api/hierarchy/hierarchyModel";
import { ItemsById } from "model/entity";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectLocationsByIds } from "store/modules/hierarchy/hierarchySelectors";

export const isLocationAncestor = (locationId: string, parentLocationId: string, locationsById: ItemsById<Location>) => {
    let location: Location | undefined = locationsById[locationId];

    while (location) {
        if (location.parentLocationId === parentLocationId) {
            return true;
        }

        location = location.parentLocationId
            ? locationsById[location.parentLocationId]
            : undefined;
    }

    return false;
};

export const useIsHigherOrSameLocation = (locationId: string, lowerLocationId: string) => {
    const locationsById = useSelector(selectLocationsByIds)

    return useMemo(() => {
        return locationId === lowerLocationId
            || isLocationAncestor(lowerLocationId, locationId, locationsById);
    }, [lowerLocationId, locationId, locationsById]);
};
