import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { AttachmentsPanel } from 'modules/task/attachments/components/AttachmentsPanel';
import { useSelector } from 'react-redux';
import { getPendingFilesQuantity } from 'store/modules/files/filesSelectors';
import { AttachmentsButton, resolveButtonText } from '../components/AttachmentsButton/AttachmentsButton';
import { t } from 'i18next';
import { useFormikContext } from 'formik';
import { TaskPrototype } from 'api/task/prototype/prototypeModel';
import { AttachmentsMode } from './model';

export function AttachmentsCreate({ labelText }: { labelText?: string}) {
    const { isOpened, onClose, onOpen } = useOpenedState();
    const formikContext = useFormikContext<Partial<TaskPrototype>>();

    const attachmentsCount = formikContext.values.attachments?.length ?? 0;
    const pendingFilesCount = useSelector(getPendingFilesQuantity);
    const totalQuantity = attachmentsCount + pendingFilesCount;
    const buttonText = useMemo(() => resolveButtonText(`${t('Add Attachment')}`, totalQuantity), [totalQuantity]);


    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            gridColumn="1/3"
            alignItems="start"
        >
            <AttachmentsButton
                buttonText={buttonText}
                handleOpenAttachmentsPanel={onOpen}
                labelText={labelText}
            />

            <AttachmentsPanel
                handleClose={onClose}
                isOpened={isOpened}
                mode={AttachmentsMode.Edit}
            />
        </Box>
    );
}
