import React, { useMemo } from 'react';
import { Box, makeStyles, Theme, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { BlueColor, Breakpoints } from 'utils/styles/constants';
import { InviteAction } from './chatActions/InviteAction';
import { ViewDetailsAction } from './chatActions/ViewDetailsAction';
import { DeleteAction } from './chatActions/DeleteAction';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { SearchChatPanel } from '../SearchChannel/SearchChatPanel';
import { ChatContextValue } from 'stream-chat-react';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { PinAction } from './chatActions/PinAction/PinAction';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexGrow: 1,
        [theme.breakpoints.down(Breakpoints.DesktopMedium)]: {
            justifyContent: 'center',
        },
    },
    rootMobile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '140px',
        flexDirection: 'column',
        flexGrow: 1,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',

        [theme.breakpoints.down(Breakpoints.DesktopMedium)]: {
            justifyContent: 'center'
        }
    },
    title:{
        padding: '4px 10px',
        borderRadius: '15px',
        backgroundColor: BlueColor.Blue5,
        [theme.breakpoints.down(1553)]: {
            marginBottom: theme.spacing(1),
        },
    },
    channelTitle: {
        fontFamily: `'Inter', 'Arial', sans-serif`,
        fontSize: '18px',
        lineHeight: '22px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: BlueColor.Blue70,
        backgroundColor: '#EBF2FF',

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 'calc(40vw - 210px)',

        cursor: 'help',

        [theme.breakpoints.down(1553)]: {
            maxWidth: 'calc(60vw - 210px)',
        },
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            maxWidth: 'calc(80vw - 110px)',
            fontSize: '16px',
            lineHeight: '20px',
        },
        [theme.breakpoints.between(Breakpoints.MobileLarge, Breakpoints.Tablet)]: {
            maxWidth: 'calc(45vw - 160px)',
        },
    },
    actions: {
        display: 'inline-block',
        width: '185px',

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            width: '110px',
            '& button': {
                padding: theme.spacing(0.75),
            },
        },
    },
}));

export interface IChannelHeaderProps {
    chatContext: ChatContextValue;
}

export function ChannelHeader({ chatContext }: IChannelHeaderProps) {
    const classes = useStyles();
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const isChatSearchEnabled = useSelector(selectFeatureFlags)[FeatureFlags.ChatSearchEnabled]?.enabled ?? false;
    const classToUse = useMemo(() => {
        return isMobile ? classes.rootMobile : classes.root;
    }, [isMobile, classes])
    const channel = chatContext?.channel;
    const channelName = channel?.data?.name ?? `${t('Unknown channel')}`;
    const { isOpened, onOpen, onClose } = useOpenedState();
    return (
        <Box className={classToUse}>
            <Box className={classes.titleWrapper}>
                <Box className={classes.title}>
                    <Tooltip
                        title={channelName}
                        open={isOpened}
                        onClose={onClose}
                    >
                        <Typography className={classes.channelTitle} onClick={onOpen}>
                            # {channelName}
                        </Typography>
                    </Tooltip>
                </Box>
                {isChatSearchEnabled && (
                    <Box ml={1}>
                        <SearchChatPanel chatContext={chatContext}/>
                    </Box>
                )}
            </Box>
            <Box className={classes.actions}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <PinAction/>
                    <InviteAction/>
                    <ViewDetailsAction/>
                    <DeleteAction/>
                </Box>
            </Box>
        </Box>
    );
}
