import { createSingleAction } from 'utils/store/actionUtils';
import { PendingFile } from '../files/filesModel';

export const OPEN_LAUNCH_COVER_EDITOR = 'OPEN_LAUNCH_COVER_EDITOR';
export const openLaunchCoverEditor = createSingleAction<boolean, typeof OPEN_LAUNCH_COVER_EDITOR>(OPEN_LAUNCH_COVER_EDITOR);

export const LAUNCH_COVER_ADD = `LAUNCH_COVER_ADD`;
export const launchCoverAddImage = createSingleAction<PendingFile, typeof LAUNCH_COVER_ADD>(LAUNCH_COVER_ADD);

export type LaunchCoverActions =
    | ReturnType<typeof openLaunchCoverEditor>
    | ReturnType<typeof launchCoverAddImage>;
