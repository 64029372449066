import React, { useContext } from "react";

export type SetScheduledTask = (id: string | null) => void;

const SetScheduledTaskContext = React.createContext<SetScheduledTask | null>(null);

export const SetScheduledTaskProvider = SetScheduledTaskContext.Provider;

export const useSetScheduledTask = () => {
    return useContext(SetScheduledTaskContext);
};
