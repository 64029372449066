import React from 'react';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { PageLoader } from 'components/router/PageLoader';
import { ThemeProvider } from '@material-ui/core';
import { newTextColorTheme } from 'modules/root/theme';
import { ScheduledTasksView } from './views/ScheduledTasksView/ScheduledTasksView';

export const TaskListScheduledPage = () => {
    const user = useCurrentUser();

    if (!user?.id) {
        return (
            <PageLoader />
        );
    }

    return (
        <ThemeProvider theme={newTextColorTheme}>
            <ScheduledTasksView />
        </ThemeProvider>
    );
};
