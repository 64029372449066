import React, { useCallback, useMemo } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { useField } from 'formik';
import { BaseInput } from '../BaseInput';
import { IInputProps, ITextInputClasses } from '../model';
import { InputError } from '../InputError';

type KeyType = string | number;

type ExtractKeyType<T> = {
    [Prop in keyof T]: T[Prop] extends KeyType ? T[Prop] : never;
}

export interface ISingleSelectProps<T> extends IInputProps {
    options: T[];
    keyProp: keyof ExtractKeyType<T>;
    valueProp: keyof ExtractKeyType<T>;
    classes?: ITextInputClasses;
    onValueChange: (newValue?: T | string) => void;
    unsetValue: T | string;
    disabled?: boolean;
}

export function SingleSelect<T>({
    name,
    label,
    id = name,
    options,
    classes = {},
    keyProp,
    valueProp,
    onValueChange,
    unsetValue,
    disabled = false
}: ISingleSelectProps<T>) {
    const [field, meta] = useField(name);
    const { value, onChange } = field;
    const items = useMemo(
        () => renderItems(options, keyProp, valueProp),
        [options, keyProp, valueProp],
    );
    const valueChangeHandler = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const taskStatus = e.target.value === unsetValue
            ? undefined
            : e.target.value as T;

        onValueChange(taskStatus);
    }, [unsetValue, onValueChange]);

    return (
        <BaseInput
            id={id}
            label={label}
            classes={classes}
        >
            <Select
                id={id}
                name={name}
                variant="outlined"
                value={value || ''}
                onChange={(val) => {
                    onChange(val);
                    valueChangeHandler(val);
                }}
                disabled={disabled}
            >
                {items}
            </Select>
            {meta.error && (
                <InputError error={meta.error} />
            )}
        </BaseInput>
    );
}

function renderItems<T>(options: T[], keyProp: keyof ExtractKeyType<T>, valueProp: keyof ExtractKeyType<T>) {
    return options.map((option) => {
        const key: KeyType = option[keyProp] as unknown as KeyType;
        return (
            <MenuItem key={key} value={key}>
                {option[valueProp]}
            </MenuItem>
        );
    });
}
