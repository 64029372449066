import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '@material-ui/icons';
import { Box, Theme, makeStyles, useTheme } from '@material-ui/core';
import { t } from 'i18next';
import { ActionButton } from 'components/button/actionButton/ActionButton';
import { IAction } from 'components/button/actionButton/model';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { IEventCalendar } from './model';
import { TaskType } from '../taskList/views/CalendarTasksView/calendar/model';
import { UtilColors } from 'utils/styles/constants';
import { Routes } from 'components/router/model';
import { useActions } from 'utils/store/useActions';
import { resetEventCalendarDetail, setCalendarEventToRemove } from 'store/modules/task/calendarTask/calendarTaskActions';

const useActionsCellCalendarStyles = makeStyles((theme: Theme) => ({
    moreIcon: {
        color: UtilColors.White,
    },
    container: ({ backgroundColor }: { backgroundColor: string }) => ({
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: '0',
        backgroundColor: backgroundColor,
        borderRadius: 4
    })
}));

export const ActionsCellCalendar: React.FC<{event: IEventCalendar}> = ({ event }) => {
    const canCreateDeleteTasks = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const history = useHistory();
    const actions = useActions({ setCalendarEventToRemove, resetEventById: resetEventCalendarDetail });
    const theme = useTheme();
    const backgroundColor = event?.tag ? event.tag.color : theme.palette.primary.dark;
    const classes = useActionsCellCalendarStyles({ backgroundColor });

    const handleEditClick = useCallback(() => {
        if(event.prototypeId){
            actions.current.resetEventById();
            history.push(generatePath(Routes.EditCalendarEvent, { prototypeId: event.prototypeId}));
        }
    }, [history, event, actions]);

    const handleDeleteClick = useCallback(() => {
        actions.current.setCalendarEventToRemove(event.id)
    }, [actions, event]);

    if(!canCreateDeleteTasks || event.taskType !== TaskType.CalendarEvent) return <></>;

    const items: Array<IAction<string>> = [
        {
            name: `${t('Edit')}`,
            action: handleEditClick,
            isAvailable: 'canEdit' in event ? !!event?.canEdit : false,
            Icon: Edit,
        },
        {
            name: `${t('Delete')}`,
            action: handleDeleteClick,
            isAvailable: true,
            Icon: Delete,
        },
    ];

    return (
        <Box className={classes.container}>
            <ActionButton
                disabled={false}
                entity={'eventId'}
                width='auto'
                items={items}
                autoHide
                classes={classes}
                marginLeft={-20}
            />
        </Box>
    );
};
