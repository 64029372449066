import React, { useEffect } from 'react';
import { useActions } from "utils/store/useActions";
import { useSelector } from 'react-redux';
import { generatePath, Redirect, useParams } from 'react-router';
import { PageLoader } from 'components/router/PageLoader';
import { Routes } from 'components/router/model';
import { requestStoreTaskGetParentIds } from 'api/task/storeTask/storeTaskActions';
import {
    selectStoreTaskParentIdsById,
    selectStoreTaskParentIdsLoadingById,
} from 'store/modules/task/storeTask/storeTaskSelectors';
import { useQueryParams } from 'utils/hooks';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

export function ViewTaskPage() {
    const { uuid: taskId } = useParams<{ uuid: string }>();
    const { parent: isParent } = useQueryParams<{ parent: string | undefined }>()
    const autoExpandEnabled = useFeatureState(FeatureFlags.AutoExpandLocationView);
    const parentIds = useSelector(selectStoreTaskParentIdsById)[taskId];
    const isLoading = useSelector(selectStoreTaskParentIdsLoadingById)[taskId];
    const actions = useActions({
        fetchParentIds: requestStoreTaskGetParentIds.init,
    });

    useEffect(() => {
        if (!parentIds && !isLoading) {
            actions.current.fetchParentIds(taskId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!parentIds || isLoading) {
        return <PageLoader />;
    }

    const topmostParentId = parentIds[parentIds.length - 1];
    const parentSearch = autoExpandEnabled ? '?expand=true' : undefined;
    const taskSearch = `?openTask=${taskId}`;

    return (
        <Redirect
            to={{
                pathname: generatePath(Routes.TaskList, { uuid: topmostParentId }),
                search: isParent ? parentSearch : taskSearch,
            }}
        />
    );
}
