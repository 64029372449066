import { requestSuggestionList} from 'api/task/taskConversation/taskConversationActions';
import { selectUsersIds, selectUsersLoadingById } from 'store/modules/user/userSelectors';
import { put, select, takeEvery } from 'typed-redux-saga';
import { difference } from 'lodash';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { requestRegularTaskUpdateCreator, requestScheduledTaskUpdateCreator, requestUsersCanReassignRegularTask, requestUsersCanReassignScheduledTask } from 'api/task/editTaskCreator/editTaskCreatorActions';
import { getTaskPrototype } from 'api/task/prototype/prototypeActions';
import { ItemsById } from 'model/entity';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { FeatureFlagState } from 'store/modules/featureFlags/model';
import { FeatureFlags } from 'utils/featureFlags';
import { UPDATE_TASK_CREATOR, changeEdition, currentTaskCreator, taskCreatorTaskType, updateTaskCreator } from './editTaskCreatorActions';
import { getTaskTemplate } from 'api/task/scheduledTask/scheduledTaskActions';
import { TaskType } from 'modules/task/taskList/views/CalendarTasksView/calendar/model';
import { selectEditTaskCreatorTaskType } from './editTaskCreatorSelectors';

function* addInitValueEditTaskCreatorSaga() {
  yield* takeEvery([getTaskPrototype.successType, getTaskTemplate.successType],
      function* ({payload}: ReturnType<typeof getTaskPrototype.success| typeof getTaskTemplate.success>) {
        const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
        const isTasksReassignCreatorEnabled = featureFlags[FeatureFlags.TasksReassignCreator]?.enabled;

        if(!isTasksReassignCreatorEnabled) return;

        yield* put(changeEdition(false));
        yield* put(currentTaskCreator(payload?.createdBy))

        const userIds = [payload.createdBy];
        const loadedUserIds: any = yield* select(selectUsersIds);
        const loadingUserIds: any = yield* select(selectUsersLoadingById);
        const missingUserIds: any = difference(userIds, loadedUserIds).filter((userId: any) => !loadingUserIds[userId]);

        if (missingUserIds.length > 0) {
            yield* put(requestMultipleUsersInfo.init(missingUserIds));
        }
      },
  );
}

function* getUsersReassigRegularTaskSaga() {
  yield* takeEvery( getTaskPrototype.successType,
      function* ({payload}: ReturnType<typeof getTaskPrototype.success>) {
        const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
        const isTasksReassignCreatorEnabled = featureFlags[FeatureFlags.TasksReassignCreator]?.enabled;

        if(!isTasksReassignCreatorEnabled) return;
        yield* put(requestUsersCanReassignRegularTask.init(payload.id));
        yield* put(taskCreatorTaskType(TaskType.Task))
      },
  );
}

function* getUsersReassigScheduledTaskSaga() {
  yield* takeEvery( getTaskTemplate.successType,
      function* ({payload}: ReturnType<typeof getTaskTemplate.success>) {
        const featureFlags: ItemsById<FeatureFlagState> = yield select(selectFeatureFlags);
        const isTasksReassignCreatorEnabled = featureFlags[FeatureFlags.TasksReassignCreator]?.enabled;

        if(!isTasksReassignCreatorEnabled) return;
        yield* put(requestUsersCanReassignScheduledTask.init(payload.id));
        yield* put(taskCreatorTaskType(TaskType.ScheduledTask))
      },
  );
}

function* usersReassignWatcher() {
  yield takeEvery(
      requestUsersCanReassignRegularTask.successType,
      function* ({ payload }: ReturnType<typeof requestSuggestionList.success>) {
          const userIds = payload;
          const loadedUserIds: any = yield* select(selectUsersIds);
          const loadingUserIds: any = yield* select(selectUsersLoadingById);
          const missingUserIds: any = difference(userIds, loadedUserIds).filter((userId: any) => !loadingUserIds[userId]);

          if (missingUserIds.length > 0) {
              yield* put(requestMultipleUsersInfo.init(missingUserIds));
          }
      },
  );
}

function* updateRegularTaskReassignWatcher() {
  yield takeEvery(
      [requestRegularTaskUpdateCreator.successType, requestScheduledTaskUpdateCreator.successType],
      function* ({ payload }: ReturnType<typeof requestRegularTaskUpdateCreator.success | typeof requestScheduledTaskUpdateCreator.success>) {
        yield* put(changeEdition(false));
        yield* put(currentTaskCreator(payload.targetUserId));

        const userIds = [payload.targetUserId];
        const loadedUserIds: any = yield* select(selectUsersIds);
        const loadingUserIds: any = yield* select(selectUsersLoadingById);
        const missingUserIds: any = difference(userIds, loadedUserIds).filter((userId: any) => !loadingUserIds[userId]);
        if (missingUserIds.length > 0) {
            yield* put(requestMultipleUsersInfo.init(missingUserIds));
        }
      },
  );
}

function* updateTaskCreatorSaga() {
  yield* takeEvery( UPDATE_TASK_CREATOR,
      function* ({payload}: ReturnType<typeof updateTaskCreator>) {
        const taskCreatorTaskType: string = yield* select(selectEditTaskCreatorTaskType);
        if(taskCreatorTaskType === TaskType.Task){
          yield* put(requestRegularTaskUpdateCreator.init(payload));
          return;
        }
        yield* put(requestScheduledTaskUpdateCreator.init(payload));
      },
  );
}


export const editTaskCreatorSagas = [
  addInitValueEditTaskCreatorSaga,
  usersReassignWatcher,
  updateRegularTaskReassignWatcher,
  getUsersReassigRegularTaskSaga,
  getUsersReassigScheduledTaskSaga,
  updateTaskCreatorSaga
];
