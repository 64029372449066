import { put, select, takeLatest } from 'typed-redux-saga';
import { TASK_GALLERY_MODAL_CLOSE, TASK_GALLERY_MODAL_OPEN, parentTaskGalleryModalReset } from './taskGalleryActions';
import { selectParentTaskGallery, selectTaskGalleryFetching } from './taskGallerySelectors';
import { requestParentTaskDetails } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { selectParentTaskById } from '../parentTask/parentTaskSelectors';
import { requestTaskGallery } from 'api/task/taskGallery/taskGalleryActions';

function* taskGallerySlideoutOpenedWatcher() {
    yield takeLatest(
        TASK_GALLERY_MODAL_OPEN,
        function* () {
            const parentTaskId: string = yield* select(selectParentTaskGallery);
            const tasks = yield* select(selectParentTaskById);
            const taskGalerryFetching = yield* select(selectTaskGalleryFetching);
            if(parentTaskId && !tasks[parentTaskId]){
                yield* put(requestParentTaskDetails.init(parentTaskId));
            }
            if(!taskGalerryFetching){
                yield* put(requestTaskGallery.init(parentTaskId));
            }
        },
    );
}

function* taskGallerySlideoutClosedWatcher() {
    yield takeLatest(
        TASK_GALLERY_MODAL_CLOSE,
        function* () {
            yield put(parentTaskGalleryModalReset());
        },
    );
}

export const taskGallerySagas = [
    taskGallerySlideoutOpenedWatcher,
    taskGallerySlideoutClosedWatcher
];
