import { Trans } from 'react-i18next'
import { StyledLayoutBodyColumn } from '../WelcomePageLayout'
import { Box, Typography } from '@material-ui/core'
import { LaunchList } from './LaunchList'
import { useLaunchStyles } from './styles'
import { useActions } from 'utils/store/useActions'
import { launchPage } from 'api/launch/launchActions'
import { useLayoutEffect } from 'react'
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import launch from 'images/rocket.png';

export const Launch = () => {
  const classes = useLaunchStyles();
  const actions = useActions({ getLaunch: launchPage });
  const currentUser = useCurrentUser();

  useLayoutEffect(() => {
    actions.current.getLaunch(currentUser?.companyId)
  }, [actions, currentUser])

  return (
  <StyledLayoutBodyColumn>
    <Box display="flex" gridGap={8} alignItems="center">
      <img src={launch} width={32} height={32} alt="launch-header"/>
      <Typography variant="subtitle1" className={classes.titleSection}><Trans>Launch</Trans></Typography>
    </Box>
    <Box className={classes.containerLaunchList}>
      <LaunchList />
    </Box>
  </StyledLayoutBodyColumn>
)
  }
