import React, { useCallback } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useField } from 'formik';
import { BaseInput } from 'components/basicInputs/BaseInput';
import { IInputProps, ITextInputClasses } from 'components/basicInputs/model';
import { hasFormikError } from 'components/basicInputs/utils';
import { InputError } from '../InputError';

import PhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import './styles.css';

interface PhoneNumberInputProps extends Omit<IInputProps, 'label'>,
    Pick<TextFieldProps, 'size' | 'multiline' | 'variant' | 'onKeyDown' | 'onBlur' | 'autoFocus' | 'disabled'> {
    classes?: ITextInputClasses;
    inputProps?: TextFieldProps['inputProps'],
    label: React.ReactNode,
    separateLabel?: boolean,
    showAdornment?: boolean,
}

export function PhoneNumberInput({
    name,
    id = name,
    label,
    separateLabel = true,
    classes: customClasses = {},
    disabled,
}: PhoneNumberInputProps) {
    const [field, meta] = useField(name);
    const hasError = hasFormikError(meta);

    const { onChange, onBlur, value } = field;

    const onChangeHandler = useCallback((newValue) => {
        onChange({ target: { name, value: `+${newValue}` } });
    }, [onChange, name]);

    return (
        <BaseInput
            id={id}
            label={label}
            classes={customClasses}
            separateLabel={separateLabel}
        >
            <PhoneInput
                preferredCountries={['us', 'au']}
                country={'us'}
                component={TextField}
                inputProps={{
                    id: id,
                    name: name,
                    variant: 'outlined',
                    label: null,
                }}
                value={value}
                onBlur={onBlur}
                onChange={onChangeHandler}
                disabled={disabled}
            />
            {hasError && (
                <InputError error={meta.error} />
            )}
        </BaseInput>
    );
}
