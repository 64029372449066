import { Box, Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { useLaunchStyles } from '../styles';
import { Formik } from 'formik';
import { launchSchema } from './validation';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { Breakpoints } from 'utils/styles/constants';
import { t } from 'i18next';
import { FormActions } from 'modules/shared/components/FormActions';
import { useActions } from 'utils/store/useActions';
import { useSelector } from 'react-redux';
import { launchModal, launchModalOpen } from 'api/launch/launchActions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LaunchModalType } from 'api/launch/launchModel';
import { selectLaunchAddFetching, selectLaunchModalForm } from 'store/modules/launch/launchSelectors';
import { PendingFile } from 'store/modules/files/filesModel';
import FileInput from 'modules/shared/components/FileInput';
import { Close as CloseIcon } from '@material-ui/icons';
import { LaunchItem } from './LaunchItem';

type LaunchModalAddProps = {
    value?: LaunchModalType
}

export const LaunchModal = ({ value }: LaunchModalAddProps) => {
    const classes = useLaunchStyles();
    const fullscreen = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const actions = useActions({ launchModal: launchModal, closeModal: launchModalOpen });
    const isFetching = useSelector(selectLaunchAddFetching);
    const launchModalData = useSelector(selectLaunchModalForm);
    const [attachments, setAttachments] = useState<PendingFile[]>([]);

    const onAttachmentRemove = useCallback(() => {
        setAttachments([]);
    }, []);

    useEffect(() => {
        if (!launchModalData?.isOpen) {
            onAttachmentRemove();
        }
    }, [launchModalData, onAttachmentRemove]);

    const onSubmit = (values: LaunchModalType) => {
        actions.current.launchModal({ launch: values, file: attachments })
    };

    const handleCloseModal = () => actions.current.closeModal({ launch: undefined, isOpen: false });

    const initialValues: LaunchModalType = useMemo(() => ({
        caption: launchModalData?.launch?.caption ?? "",
        target: launchModalData?.launch?.target ?? "",
        index: launchModalData?.launch?.index ?? undefined,
        thumbnail: launchModalData?.launch?.thumbnail ?? undefined,
        id: launchModalData?.launch?.id ?? undefined
    }), [launchModalData])

    const handleCancel = () => !isFetching && handleCloseModal();

    const onAttachmentChange = useCallback((files: File[]) => {
        const file = files[0];
        if (file) {
            setAttachments([{
                title: file.name,
                localKey: '',
                localFile: file,
            }]);
        }
    }, []);

    return (
        <Dialog
            open={launchModalData.isOpen}
            fullScreen={fullscreen}
            maxWidth="lg">
            <DialogTitle disableTypography>
                <Typography>
                    {value ? <Trans>Edit shortcut</Trans> : <Trans>Add shortcut</Trans>}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ width: fullscreen ? 'auto' : 400 }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={launchSchema}
                    onSubmit={onSubmit}
                >
                    {({ isValid, dirty, handleSubmit, handleChange, values: valuesForm }) => (
                        <form onSubmit={handleSubmit}>
                            <Box>
                                <TextInput
                                    name="caption"
                                    label={`${t('Name')}`}
                                    disabled={isFetching}
                                />
                                <TextInput
                                    classes={{ root: classes.textInput }}
                                    name="target"
                                    label={`${t('URL')}`}
                                    disabled={isFetching}
                                />
                                {isValid && valuesForm.target && !attachments.length && !valuesForm?.thumbnail &&
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={2} flexDirection="column">
                                        <Typography variant="body2"><Trans>Preview icon</Trans></Typography>
                                        <LaunchItem 
                                            value={{
                                                id:'preview-favicon',
                                                caption: valuesForm.caption,
                                                target: valuesForm.target,
                                                index: -1,
                                                thumbnail:undefined 
                                            }}
                                        />
                                    </Box>
                                }
                                <Box position="relative">
                                    {(valuesForm?.thumbnail) 
                                        ?
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={2} flexDirection="column" position="relative">
                                            <Typography variant="body2"><Trans>Preview icon</Trans></Typography>
                                            <LaunchItem 
                                                value={{
                                                    id:'preview-favicon-thumbnail',
                                                    caption: valuesForm.caption,
                                                    target: valuesForm.target,
                                                    index: -2,
                                                    thumbnail: valuesForm?.thumbnail 
                                                }}
                                            />
                                            {valuesForm?.thumbnail && 
                                            <IconButton onClick={() => { handleChange({ target: { name: 'thumbnail', value: undefined } }) }} 
                                                style={{ padding: 0, marginLeft: 16, position: "absolute", transform: 'translate(75px)', top: 0}} size="small">
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                            }
                                        </Box>
                                        :
                                        <>
                                            <FileInput
                                                attachments={attachments}
                                                onAttachmentChange={onAttachmentChange}
                                                onAttachmentRemove={onAttachmentRemove}
                                                multiple={false}
                                                isLoading={isFetching}
                                                maxSizeMb={15}
                                                fileTypes={['.jpg', '.png', '.jpeg']}
                                                rejectMessage={null}
                                                text={`${t('Upload Custom Icon')}`}
                                            />
                                            {
                                                (attachments.length > 0 && !isFetching) &&
                                                <IconButton onClick={onAttachmentRemove} style={{ position: 'absolute', top: 2, right: 0, padding: 0 }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            }
                                        </>
                                    }
                                </Box>
                            </Box>
                            <Box className={classes.formActions}>
                                <FormActions isInProgress={isFetching} onClickCancel={handleCancel} disabled={!isValid || isFetching || (!dirty && attachments.length === 0)} />
                            </Box>
                        </form>
                    )}
                </Formik>

            </DialogContent>
        </Dialog>
    )
}