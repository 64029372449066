import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { PageLoader } from 'components/router/PageLoader';
import { WelcomePageLayout } from './WelcomePageLayout';
import { t } from 'i18next';
import { Launch } from './launch/Launch';
import { Notification } from './notification/Notification';
import { LaunchCover } from './launchCover/LaunchCover';
import { CalendarHome } from './calendar/CalendarHome';

export const WelcomePage = () => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return (
            <PageLoader />
        );
    }


    return (
        <WelcomePageLayout
            header={`${t('Welcome')}, ${currentUser?.firstName}!`}
            launchCover={<LaunchCover />}
        >
            <Launch />
            <CalendarHome />
            <Notification />
        </WelcomePageLayout>
    );
};
