import React from 'react';
import { EmptyFeed, FilterStatus, KnockFeedContainer, NotificationFeed } from '@knocklabs/react-notification-feed';
import { useKnockFeedProviderState, useNavigationHandler } from './hooks';
import { PageLoader } from 'components/router/PageLoader';
import { NotificationCustomCell } from './NotificationCustomCell';

export const NotificationsPage = () => {
    const notificationClickHandler = useNavigationHandler();
    const knockFeed = useKnockFeedProviderState();

    if (!knockFeed) {
        return <PageLoader/>;
    }
    return (
        <KnockFeedContainer>
            <NotificationFeed
                EmptyComponent={<EmptyFeed />}
                onNotificationClick={notificationClickHandler}
                initialFilterStatus={FilterStatus.Unread}
                renderItem={({ item, ...props }) => (
                    <NotificationCustomCell
                        {...props}
                        key={item.id}
                        item={item}                            
                    />)
                }
            />
        </KnockFeedContainer>
    );
};
