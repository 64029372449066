import { Skeleton } from '@material-ui/lab';
import { AVATAR_SIZE, StyledBox } from "./styles";
import { UserInfoSkeletonProps } from './UserInfoSkeleton';

export const UserAvatarSkeleton = ({ className }: UserInfoSkeletonProps) => {
  return (
    <StyledBox className={className}>
        <Skeleton
            variant="circle"
            width={AVATAR_SIZE}
            height={AVATAR_SIZE}
        />
    </StyledBox>
  );
};
