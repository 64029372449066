import React from 'react';
import { CellProps } from 'react-table';
import { Box } from '@material-ui/core';
import { CompletionState } from '../../views/ParentTasksView/grid/model';
import { colorMap, colorMapDisabled, IHasCompletionState } from '../../model';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';

export const ColorStatusCell: React.FC<CellProps<IHasCompletionState, CompletionState>> = ({ value = 'default' }) => {
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isOverdueEnabled = isOverdueColumnsFeature?.enabled ?? false;
    return (
        <Box
            width="7px"
            height="52px"
            bgcolor={isOverdueEnabled ? colorMap[value] : colorMapDisabled[value]}
        />
    );
};
