import React, { useCallback } from 'react';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { requestSubmitStoreTask } from 'api/task/storeTask/storeTaskActions';
import { useActions } from "utils/store/useActions";
import { SubmitTaskButton } from './buttons';
import { StickyPanel } from './StickyPanel';
import { useSelector } from 'react-redux';
import { selectUpdatingStoreTasks } from 'store/modules/task/storeTask/storeTaskSelectors';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { Breakpoints } from 'utils/styles/constants';
import { t } from 'i18next';

export type TaskSubmitProps = {
    task: StoreTaskFullModel | undefined;
};

const statusesForSubmit = [
    DisplayedTaskStatus.Declined,
    DisplayedTaskStatus.Default,
    DisplayedTaskStatus.Overdue,
];

export const TaskSubmit: React.FC<TaskSubmitProps> = ({ task }) => {
    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const buttonText = isMobile ? `${t('Submit')}` : `${t('Submit for approval')}`;
    const updatingStoreTasks = useSelector(selectUpdatingStoreTasks);
    const actions = useActions({
        submitTask: requestSubmitStoreTask.init,
    });
    const handleSubmitTask = useCallback(() => actions.current.submitTask(task?.id || ''), [actions, task?.id]);
    if (!task || !task.isApproval) {
        return null;
    }
    if (!statusesForSubmit.some(status => status === task.displayedStatus)) {
        return null;
    }
    const enabled = (
        (task.checklistItems?.every(item => item.completed) || false)
        && !updatingStoreTasks.some(id => id === task.id)
    );

    if (enabled) {
        return (
            <StickyPanel>
                <SubmitTaskButton onClick={handleSubmitTask}>
                    {buttonText}
                </SubmitTaskButton>
            </StickyPanel>
        );
    }

    return (
        <StickyPanel>
            <SubmitTaskButton disabled>
                {buttonText}
            </SubmitTaskButton>
        </StickyPanel>
    );
};
