import React from 'react';
import { LandingPage } from './landingPage/LandingPage';
import { Messenger } from './messenger/Messenger';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { useSelector } from 'react-redux';
import { AppName } from 'store/modules/apps/appsModel';
import { useParams } from 'react-router-dom';

export function ConnectPage() {
    const hasConnectPermissions = useSelector(selectHasAppAccess(AppName.Connect));
    const { cid: channelId } = useParams<{ cid?: string }>();

    if (hasConnectPermissions) {
        return <Messenger channelId={channelId} />;
    }

    return (
        <LandingPage />
    );
}
