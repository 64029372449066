import { useCallback, useState } from 'react';

export function useOpenedState(defaultState = false) {
    const [isOpened, setIsOpened] = useState(defaultState);

    const onOpen = useCallback(() => {
        setIsOpened(true);
    }, [setIsOpened]);

    const onClose = useCallback(() => {
        setIsOpened(false);
    }, [setIsOpened]);

    const onToggle = () => setIsOpened(!isOpened);

    return {
        isOpened,
        onOpen,
        onClose,
        onToggle,
    };
}
