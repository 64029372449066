import { LAUNCH_MODAL_DELETE_OPEN, LAUNCH_MODAL_OPEN, LaunchActions, requestAddLaunch, requestAddLaunchCover, requestDeleteLaunch, requestDeleteLaunchCover, requestGetLaunch, requestLaunchReorder, requestUpdateLaunch } from 'api/launch/launchActions';
import { Launchs, ModalDeleteLaunch, ModalFormLaunch } from 'api/launch/launchModel';
import { combineReducers } from 'redux';
import { LaunchCoverActions, OPEN_LAUNCH_COVER_EDITOR } from './launchActions';
import { RequestCompanyActions, requestCompanyColor } from 'api/company/companyActions';

const initialState = {
    launchs: [],
    launchsFetching: false,
    launchUpdatingById: {},
    launchAddFetching: false,
    launchModalForm: { launch: undefined,isOpen: false},
    launchModalDelete: { isOpen: false},
    launchPageCoverImage: null,

    launchCoverEditorOpened: false,
    launchCoverUploadingExplicit: false,
    launchCoverFetching: false,
    launchReorderFetching: false,
    launchReorderRestoreData: false,
};

function launchs(
    state: Launchs = initialState.launchs,
    action: LaunchActions,
): Launchs {
    switch (action.type) {
        case requestGetLaunch.successType:
            return action.payload.sort((a, b) => a.index - b.index);
        case requestGetLaunch.errorType:
            return [];
        case requestDeleteLaunch.successType:
            return state.filter(l => l.id !== action.payload.id);
        case requestAddLaunch.successType: {
            const addNewLaunch = [...state, action.payload]
            return addNewLaunch;
        }
        case requestUpdateLaunch.successType:{
            const updateLaunch = state.findIndex(l => l.id === action.payload.id);
            const launchs = [...state];
            launchs[updateLaunch] = action.payload
            return launchs;
        }
        case requestLaunchReorder.successType: {
            const currentLaunchs = [...state];
            const newLaunchOrder: Launchs = []
            for (const launch of action.payload) {
                const aux = currentLaunchs.find((l)=>l.id === launch.linkId);
                if(aux){
                    newLaunchOrder.push(aux);
                }
            }
            return newLaunchOrder;
        }
        case requestLaunchReorder.errorType:
            return state.sort((a, b) => a.index - b.index);
        default:
            return state;
    }
}

function launchsFetching(
    state: boolean = initialState.launchsFetching,
    action: LaunchActions,
): boolean {
    switch (action.type) {
        case requestGetLaunch.initType:
            return true;
        case requestGetLaunch.successType:
        case requestGetLaunch.errorType:
            return false;
        default:
            return state;
    }
}

function launchUpdatingById(
    state: Record<string, boolean | undefined> = initialState.launchUpdatingById,
    action: LaunchActions,
): Record<string, boolean | undefined> {
    switch (action.type) {
        case requestDeleteLaunch.initType:
            return { ...state, [action.payload.launchId]: true };
        case requestDeleteLaunch.successType:
        case requestDeleteLaunch.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function launchAddFetching(
    state: boolean = initialState.launchAddFetching,
    action: LaunchActions,
): boolean {
    switch (action.type) {
        case requestAddLaunch.initType:
        case requestUpdateLaunch.initType:
            return true;
        case requestAddLaunch.successType:
        case requestAddLaunch.errorType:
        case requestUpdateLaunch.successType:
        case requestUpdateLaunch.errorType:
            return false;
        default:
            return state;
    }
}

function launchModalForm(
    state: ModalFormLaunch = initialState.launchModalForm,
    action: LaunchActions,
): ModalFormLaunch {
    switch (action.type) {
        case LAUNCH_MODAL_OPEN: 
            return action.payload;
        case requestAddLaunch.successType:
        case requestUpdateLaunch.successType:
            return initialState.launchModalForm;
        default:
            return state;
    }
}

function launchModalDelete(
    state: ModalDeleteLaunch = initialState.launchModalDelete,
    action: LaunchActions,
): ModalDeleteLaunch {
    switch (action.type) {
        case LAUNCH_MODAL_DELETE_OPEN: 
            return action.payload;
        case requestDeleteLaunch.successType:
        case requestDeleteLaunchCover.successType:
            return {isOpen: false};
        default:
            return state;
    }
}

function launchCoverEditorOpened(
    state: boolean = initialState.launchCoverEditorOpened,
    action: LaunchCoverActions | LaunchActions,
): boolean {
    switch (action.type) {
        case OPEN_LAUNCH_COVER_EDITOR:
            return action.payload;
        case requestAddLaunchCover.successType:
            return false;
        default:
            return state;
    }
}

function launchCoverFetching(
    state: boolean = initialState.launchCoverFetching,
    action: LaunchActions,
): boolean {
    switch (action.type) {
        case requestAddLaunchCover.initType: 
        case requestDeleteLaunchCover.initType:
            return true;
        case requestAddLaunchCover.errorType:
        case requestAddLaunchCover.successType:
        case requestDeleteLaunchCover.errorType:
        case requestDeleteLaunchCover.successType:
            return false;
        default:
            return state;
    }
}
function launchPageCoverImage(
    state: string | null = initialState.launchPageCoverImage, action: RequestCompanyActions | LaunchActions,
): string | null  {
    switch (action.type) {
        case requestCompanyColor.successType: {
            const { launchPageCover } = action.payload;
            return launchPageCover || null;
        }
        case requestAddLaunchCover.successType: 
            return action.payload;
        case requestDeleteLaunchCover.successType: {
            return null;
        }
        default:
            return state;
    }
}

function launchReorderFetching(
    state: boolean = initialState.launchReorderFetching,
    action: LaunchActions,
): boolean {
    switch (action.type) {
        case requestLaunchReorder.initType:
            return true;
        case requestLaunchReorder.successType:
        case requestLaunchReorder.errorType:
            return false;
        default:
            return state;
    }
}

function launchReorderRestoreData(
    state: boolean = initialState.launchReorderRestoreData,
    action: LaunchActions,
): boolean {
    switch (action.type) {
        case requestLaunchReorder.errorType:
            return true;
        case requestLaunchReorder.successType:
        case requestLaunchReorder.initType:
            return false;
        default:
            return state;
    }
}

export const launch = combineReducers({
    launchs,
    launchsFetching,
    launchUpdatingById,
    launchAddFetching,
    launchModalForm,
    launchModalDelete,
    launchCoverEditorOpened,
    launchCoverFetching,
    launchPageCoverImage,
    launchReorderFetching,
    launchReorderRestoreData
});
