type ChangeHandler<TValue> = (v: TValue) => void;

export function createObservable<TValue>(initialValue: TValue): Observable<TValue> {
    const handlers: Array<ChangeHandler<TValue>> = [];
    let innerValue: TValue = initialValue;

    return {
        get value(): TValue {
            return innerValue;
        },
        set value(val: TValue) {
            innerValue = val;
            handlers.forEach(notify => notify(innerValue));
        },
        onChange(handler: ChangeHandler<TValue>) {
            handlers.push(handler);
            return () => {
                const index = handlers.findIndex(item => item === handler);
                if (index >= 0) {
                    handlers.splice(index, 1);
                }
            };
        },
        dispose() {
            innerValue = initialValue;
            handlers.length = 0;
        },
    };
}

export type Observable<TValue> = {
    value: TValue;
    onChange: (handler: ChangeHandler<TValue>) => (() => void);
    dispose: () => void;
}
