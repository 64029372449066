import { useMediaQuery, Theme } from '@material-ui/core';
import { TABLE_HEAD_HEIGHT } from 'modules/shared/components/StyledTable';
import { useEffect, useRef, useState } from 'react';
import { Breakpoints } from 'utils/styles/constants';

function calculateGridHeight(layoutPageHeader: HTMLDivElement, hasHeader: boolean) {
    const PADDINGS = window.innerWidth > Breakpoints.MobileLarge ? 48 : 0;
    const HEADER_HEIGHT = 80;
    const result = window.innerHeight - layoutPageHeader.clientHeight - TABLE_HEAD_HEIGHT - PADDINGS;
    return hasHeader ? result - HEADER_HEIGHT : result;
}

export function useFixedSizeListHeight() {
    const layoutHeaderRef = useRef<HTMLDivElement>(null);
    const [gridHeight, setGridHeight] = useState(500);
    const hasHeader = useMediaQuery((theme: Theme) => theme.breakpoints.down(Breakpoints.Tablet));

    useEffect(() => {
        if (layoutHeaderRef.current) {
            const height = calculateGridHeight(layoutHeaderRef.current, hasHeader);
            setGridHeight(height);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutHeaderRef.current, hasHeader]);

    return { gridHeight, layoutHeaderRef };
}
