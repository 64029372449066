import { useEffect, useMemo, useState } from "react";
import debounce from 'lodash/debounce';

export const useDebouncedValue = <T = any>(value: T, timeout: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const debouncedSetter = useMemo(() => debounce(setDebouncedValue, timeout), [timeout]);

    useEffect(() => {
        debouncedSetter(value);
    }, [value, debouncedSetter])

    return debouncedValue;
};
