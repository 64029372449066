import { Tab, Tabs } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../store/modules/featureFlags/selectors';
import { useAppPermissions } from '../../store/modules/apps/appsHooks';
import { useHistory } from 'react-router';
import { EmbeddedSubRoute } from './model';
import { useLegacySubRoutesStyles } from './shared/useLegacySubRoutesStyles';
import { isCurrentApp } from './shared/useIsCurrentApp';
import kebabCase from 'lodash/kebabCase';
import { t as tI18n} from 'i18next';

interface EmbeddedSubRoutesTopMenuProps {
    path: string;
    apps: Array<EmbeddedSubRoute>;
    currentAppName?: string;
}

export function EmbeddedSubRoutesTopMenu({ path, apps, currentAppName = '' }: EmbeddedSubRoutesTopMenuProps) {
    const classes = useLegacySubRoutesStyles([]);
    const featureFlags = useSelector(selectFeatureFlags);
    const checkPermissions = useAppPermissions();
    const history = useHistory();
    const activeApps = apps
        .filter(app => app.appName ? checkPermissions(app.appName) : true)
        .filter(app => app.featureFlag ? featureFlags[app.featureFlag]?.enabled : true);

    const currentApp = currentAppName
        ? activeApps.find(app => app.name === currentAppName)
        : activeApps.find(app => isCurrentApp(path, app.name, history));

    const handleChange = useCallback((event, name: string) => {
        const newPath = `${path}/${kebabCase(name)}`;
        if (newPath !== history.location.pathname) {
            history.push(newPath);
        }
    }, [history, path]);

    return (
        <Tabs
            value={currentApp?.name || ''}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            classes={{
                root: classes.tabsRoot,
            }}
        >
            {activeApps.map(app => (
                <Tab
                    key={app.name}
                    label={tI18n(`menu.subRoutes.${app.name}`)}
                    value={app.name}
                />
            ))}
        </Tabs>
    );
}
