import { t } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';

export function renderTitleText(storesSelected: number) {
    if (typeof storesSelected !== 'number' || storesSelected <= 0) {
        return <Trans>Select Stores or Locations</Trans>;
    }
    const textStoreSelected = t('textTostoresSelected', { count: storesSelected })

    return (
        <><strong>{storesSelected}</strong> {`${textStoreSelected}`}</>
    );
}
