import { FeedItem, GenericData, ContentBlock } from '@knocklabs/client';
import { Routes } from 'components/router/model';
import { generatePath } from 'react-router-dom';
import { ConnectNotificationPayload, TaskNotificationPayload } from './model';

export interface IFeedItemVerified<T = GenericData> extends FeedItem {
    data: T;
}

export const isTaskNotification = (item: FeedItem): item is IFeedItemVerified<TaskNotificationPayload> => {
    return !!((item as FeedItem<TaskNotificationPayload>).data?.taskId);
};

export const isConnectNotification = (item: FeedItem): item is IFeedItemVerified<ConnectNotificationPayload> => {
    return !!((item as FeedItem<ConnectNotificationPayload>).data?.channelId);
};

export const isKnownNotificationType = (item: FeedItem) => {
    return [isTaskNotification, isConnectNotification].some(check => check(item));
};

export const getItemNavigationPath = (item: FeedItem): string | null => {
    if (isTaskNotification(item)) {
        return generatePath(Routes.ViewTask, { uuid: item.data.taskId });
    } else if (isConnectNotification(item)) {
        const { channelId, channelType } = item.data;

        return generatePath(Routes.Connect, { cid: `${channelType}:${channelId}` });
    }

    return null;
};

export const getNotificationPath = (actionUrl?: ContentBlock): string | null => {
    if(!actionUrl) return null;
    const { rendered } = actionUrl;
    if(rendered.includes(window.location.origin) || window.location.hostname.includes("localhost")){
        return rendered.replace(/^.*\/\/[^\\/]+/, '')
    }
    return rendered;
};
