import { Badge } from '@material-ui/core';
import { FeedStoreState } from '@knocklabs/client';
import React, { useLayoutEffect } from 'react';
import { useKnockFeedProviderState } from './hooks';

const unreadCountSelector = (state: FeedStoreState) => state.metadata.unread_count;

export const UnreadNotificationsBadge: React.FC = ({ children }) => {
    const knock = useKnockFeedProviderState();
    const unreadCount = knock?.useFeedStore(unreadCountSelector);

    useLayoutEffect(()=> {
        if(knock){
            knock?.feedClient.fetch();
        }
    }, [knock]);

    return (
        <Badge
            badgeContent={unreadCount}
            max={99}
            color="error"
        >
            {children}
        </Badge>
    );
};
