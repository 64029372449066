import { GreenColor, UtilColors } from 'utils/styles/constants';

export type statusIntegrationKeys = 'active' | 'error' | 'disabled';
export type IntegationBase = {
    name: string;
    id: string;
    status: statusIntegrationKeys;
    imageUrl: string;
};

export type Integrations = {
    userProvisioning: IntegationBase[];
    performance: IntegationBase[];
    AISidekick: IntegationBase[];
};

export type InitialStateType = {
    integrations: Integrations;
    integrationsFetching: boolean;
};

export const integrationsMock: Integrations = {
    userProvisioning: [
        {
            name: 'Microsoft Entra ID',
            id: 'microsoft-entra-id-mock',
            status: 'active',
            imageUrl: '',
        },
    ],
    performance: [
        {
            name: 'Shopify',
            id: 'shopify-mock',
            status: 'disabled',
            imageUrl: '',
        },
    ],
    AISidekick: [
        {
            name: 'Drive',
            id: 'drive-mock',
            status: 'active',
            imageUrl: '',
        },
        {
            name: 'Dropbox',
            id: 'dropbox-mock',
            status: 'error',
            imageUrl: '',
        },
        {
            name: 'Single Fle',
            id: 'single-fle-mock',
            status: 'error',
            imageUrl: '',
        },
    ],
};

export const colorStatusDictionary: Record<statusIntegrationKeys, string> = {
    active: GreenColor.Green2,
    error: UtilColors.Red,
    disabled: UtilColors.TextDisabled,
};
