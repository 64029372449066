import { combineReducers } from 'redux';
import { TASK_REVIEW_MODAL_BY_PARENT_TASK, TASK_REVIEW_MODAL_BY_PARENT_TASK_RESET, TASK_REVIEW_MODAL_CLOSE, TASK_REVIEW_MODAL_OPEN, TaskReviewAction } from './taskReviewActions';
import {
    requestStoreTasksForReview,
    requestStoreTasksForReviewCount,
    TaskReviewApiActions,
} from 'api/task/taskReview/taskReviewActions';
import {
    requestApproveStoreTask,
    requestDeclineStoreTask,
    StoreTaskApiActions,
} from 'api/task/storeTask/storeTaskActions';
import { TaskReviewModel } from 'api/task/taskReview/taskReviewModel';
export const TITLEDEFAULT = 'Tasks Needing Approval';

export type ParentTaksReview = { title: string, taskId?: string };
export type TaskReviewState = {
    isModalOpen: boolean;
    isLoading: boolean;
    isCountLoading: boolean;
    items: TaskReviewModel[];
    updatingItems: Record<string, boolean | undefined>;
    counter: number;
    parentTask: ParentTaksReview;
};

const initialState: TaskReviewState = {
    isModalOpen: false,
    isLoading: false,
    isCountLoading: false,
    items: [],
    updatingItems: {},
    counter: 0,
    parentTask: { title: TITLEDEFAULT, taskId: undefined  }
};

function isModalOpenReducer(state = initialState.isModalOpen, action: TaskReviewAction): boolean {
    switch (action.type) {
        case TASK_REVIEW_MODAL_OPEN:
            return true;
        case TASK_REVIEW_MODAL_CLOSE:
            return false;
        default:
            return state;
    }
}

function modalParentTaskReducer(state = initialState.parentTask, action: TaskReviewAction | TaskReviewApiActions): ParentTaksReview {
    switch (action.type) {
        case TASK_REVIEW_MODAL_BY_PARENT_TASK:
            return action.payload;
        case TASK_REVIEW_MODAL_BY_PARENT_TASK_RESET:
            return { title: TITLEDEFAULT, taskId: undefined  };
        default:
            return state;
    }
}

function isLoadingReducer(state = initialState.isLoading, action: TaskReviewApiActions): boolean {
    switch (action.type) {
        case requestStoreTasksForReview.initType:
            return true;
        case requestStoreTasksForReview.successType:
        case requestStoreTasksForReview.errorType:
            return false;
        default:
            return state;
    }
}

function isCountLoadingReducer(state = initialState.isCountLoading, action: TaskReviewApiActions): boolean {
    switch (action.type) {
        case requestStoreTasksForReviewCount.initType:
            return true;
        case requestStoreTasksForReviewCount.successType:
        case requestStoreTasksForReviewCount.errorType:
            return false;
        default:
            return state;
    }
}

function itemsReducer(
    state = initialState.items,
    action: TaskReviewApiActions | StoreTaskApiActions,
): TaskReviewModel[] {
    switch (action.type) {
        case requestStoreTasksForReview.successType:
            return action.payload;
        case requestApproveStoreTask.successType:
        case requestDeclineStoreTask.successType:
            return state.filter(task => task.id !== action.payload.id);
        case requestStoreTasksForReview.errorType:
            return []
        default:
            return state;
    }
}

function updatingItemsReducer(
    state = initialState.updatingItems,
    action: StoreTaskApiActions,
): Record<string, boolean | undefined> {
    switch (action.type) {
        case requestApproveStoreTask.initType:
            return { ...state, [action.payload]: true };
        case requestDeclineStoreTask.initType:
            return { ...state, [action.payload.taskId]: true };
        case requestApproveStoreTask.successType:
        case requestApproveStoreTask.errorType:
        case requestDeclineStoreTask.successType:
        case requestDeclineStoreTask.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function counterReducer(
    state = initialState.counter,
    action: TaskReviewApiActions | StoreTaskApiActions,
): number {
    switch (action.type) {
        case requestStoreTasksForReview.successType:
            return action.payload.length;
        case requestStoreTasksForReviewCount.successType:
            return action.payload;
        case requestApproveStoreTask.successType:
        case requestDeclineStoreTask.successType:
            return state - 1;
        default:
            return state;
    }
}

export const taskReviewReducer = combineReducers({
    isModalOpen: isModalOpenReducer,
    isLoading: isLoadingReducer,
    isCountLoading: isCountLoadingReducer,
    items: itemsReducer,
    updatingItems: updatingItemsReducer,
    counter: counterReducer,
    parentTask: modalParentTaskReducer
});
