import React, { useMemo } from 'react';
import { StoreTaskActivityEvent } from 'api/task/storeTask/storeTaskModel';
import { checkTaskDeclineEvent, checkTaskEditEvent } from './model';
import { TaskDeclinedEventBody } from './TaskDeclinedEventBody';
import { TaskEditedEventBody } from './TaskEditedEventBody';

export type TaskActivityEventBodyProps = {
    event: StoreTaskActivityEvent;
}

export const TaskActivityEventBody = ({ event }: TaskActivityEventBodyProps) => {
    return useMemo(() => {
        if (checkTaskDeclineEvent(event) && event.reason) {
            return <TaskDeclinedEventBody reason={event.reason} />;
        }

        if (checkTaskEditEvent(event) && event.fieldChanges?.length) {
            return <TaskEditedEventBody changes={event.fieldChanges} />;
        }

        return null;
    }, [event]);
};
