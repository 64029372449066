import React from 'react';
import { Box, Checkbox, List, ListItem, Typography } from '@material-ui/core';
import { StoreTaskChecklistModel } from 'api/task/storeTask/storeTaskModel';
import { StoreTaskReadonlyChecklistFiles } from './StoreTaskReadonlyChecklistFiles';
import { useStoreTaskChecklistStyles } from './StoreTaskChecklist';
import { StoreTaskReadonlyChecklistCustomInput } from './StoreTaskReadonlyChecklistCustomInput';
import { StoreTaskChecklistAvatarCompleted } from './StoreTaskChecklistAvatarCompleted';

interface IStoreTaskReadonlyChecklistProps {
    checklistItems: StoreTaskChecklistModel[],
}

export function StoreTaskReadonlyChecklist({ checklistItems }: IStoreTaskReadonlyChecklistProps) {
    const classes = useStoreTaskChecklistStyles([]);

    return (
        <List dense>
            {checklistItems.map((checklistItem: StoreTaskChecklistModel) => (
                <ListItem className={classes.listItem} key={checklistItem.id}>
                    <Box display="flex" alignItems="center">
                        <StoreTaskChecklistAvatarCompleted checklistItem={checklistItem} />
                        <Checkbox
                            color="primary"
                            checked={checklistItem.completed}
                            disabled
                        />
                        <Typography variant="body2">
                            {checklistItem.name}
                        </Typography>
                    </Box>
                    <StoreTaskReadonlyChecklistFiles attachments={checklistItem.attachments}/>
                    {checklistItem.requiresCustomResponse && 
                            <StoreTaskReadonlyChecklistCustomInput customResponse={checklistItem.customResponse} /> }
                </ListItem>
            ))}
        </List>
    );
}
