import React from 'react';
import { Redirect } from 'react-router';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { PageLoader } from 'components/router/PageLoader';
import { Routes } from 'components/router/model';
import { LocationsTreeView } from './views/LocationsTreeView/LocatonsTreeView';
import { ThemeProvider } from '@material-ui/core';
import { newTextColorTheme } from 'modules/root/theme';
import { StoreTasksSlideout } from '../taskList/views/LocationsTreeView/StoreTasksSlideout';
import { StoreTaskView } from '../singleTask/storeTaskView/StoreTaskView';

export const TaskListByLocationPage = () => {
    const {
        id: userId,
        isCompanyLocationStore: isStoreEmployee
    } = useCurrentUser() || {};

    if (!userId) {
        return (
            <PageLoader />
        );
    }

    if (isStoreEmployee === true) {
        return (
            <Redirect to={Routes.NotFound} />
        );
    }

    return (
        <ThemeProvider theme={newTextColorTheme}>
            <LocationsTreeView />
            <StoreTasksSlideout />
            <StoreTaskView />
        </ThemeProvider>
    );
};
