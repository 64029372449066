import { requestSearchStores, SearchStoresActions, SEARCH_RESET } from 'api/store/storeActions';
import { Stores } from 'api/store/storeModel';
import { combineReducers } from 'redux';

const initialState = {
    stores: [],
    storesFetching: false,
};

function stores(
    state: Stores = initialState.stores,
    action: SearchStoresActions,
): Stores {
    switch (action.type) {
        case requestSearchStores.successType:
            return [...action.payload];
        case SEARCH_RESET:
        case requestSearchStores.errorType:
            return [];
        default:
            return state;
    }
}

function storesFetching(
    state: boolean = initialState.storesFetching,
    action: SearchStoresActions,
): boolean {
    switch (action.type) {
        case requestSearchStores.initType:
            return true;
        case requestSearchStores.successType:
        case requestSearchStores.errorType:
            return false;
        default:
            return state;
    }
}
export const store = combineReducers({
    stores,
    storesFetching,
});
