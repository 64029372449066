

import { useSelector } from 'react-redux';
import { Box, Card, CardContent } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Breakpoints } from 'utils/styles/constants';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { useActions } from "utils/store/useActions";
import { TaskStatus } from 'api/task/taskModel';
import { requestApproveStoreTask } from 'api/task/storeTask/storeTaskActions';
import { TaskGalleryModel } from 'api/task/taskGallery/taskGalleryModel';
import { TaskSection } from '../singleTask/components/TaskSection';
import { StoreTaskReadonlyChecklistFiles } from '../singleTask/components/StoreTaskReadonlyChecklistFiles';
import { TaskDecline } from '../singleTask/components/taskDecline/TaskDecline';
import { ApproveTaskButton } from '../singleTask/components/buttons';
import { selectTaskGalleryUpdatingItems } from 'store/modules/task/taskGallery/taskGallerySelectors';
import { useTaskGalleryStyles } from './useTaskGalleryStyles';
import { TaskGalleryHeader } from './TaskGalleryHeader';

export type TaskGalleryItemProps = {
    task: TaskGalleryModel;
};

export function TaskGalleryItem({ task }: TaskGalleryItemProps) {
    const classes = useTaskGalleryStyles();
    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const actions = useActions({ approve: requestApproveStoreTask.init });
    const isTaskUpdating = useSelector(selectTaskGalleryUpdatingItems)[task.id];
    const handleApproveClick = () => actions.current.approve(task.id);
    const showActionBtn = task.status === TaskStatus.Submitted && !isTaskUpdating?.status;

    return (
        <Card className={classes.card}>
            <CardContent>
                <TaskGalleryHeader 
                    task={task}
                />
                <TaskSection mt={2}
                    sectionTitle={ !task.attachments.length
                        ? <Trans>No Media</Trans>
                        : `${t('attachmentCount', { count: task.attachments.length })}`}
                    >
                    <StoreTaskReadonlyChecklistFiles attachments={task.attachments}/>
                </TaskSection>
                {
                    showActionBtn  &&
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent={isMobile ? 'center' : 'flex-end'}
                        alignItems="center"
                    >
                        <TaskDecline taskId={task.id} disabled={isTaskUpdating?.isLoading} />
                        <Box pr="25px" />
                        <ApproveTaskButton onClick={handleApproveClick} disabled={isTaskUpdating?.isLoading} />
                    </Box>
                }
            </CardContent>
        </Card>
    );
}
