import { useMemo } from 'react';
import {
    TaskLocationCell,
    TaskLocationMobileCell,
} from 'modules/task/taskList/views/TaskByLocationsView/cells/TaskLocationCell';
import { ColorStatusChildCell } from 'modules/task/taskList/views/TaskByLocationsView/cells/ColorStatusChildCell';
import { Column } from 'react-table';
import { ChildLocationTask, ParentTaskChildModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { TaskByLocationStatus } from 'modules/task/taskList/views/TaskByLocationsView/cells/TaskByLocationStatus';
import { Theme, useMediaQuery } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { sortByStatus } from '../helpers/sorting';
import { SubmittedCell } from '../cells/SubmittedCell';
import { TaskStoreDeleteCell } from '../cells/TaskDeleteLocationCell';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { t } from 'i18next';

export enum TaskByLocationCellsIds {
    Color = 'color-status',
    Location = 'location',
    Status = 'completion-status',
    SubmittedTasks = 'submitted-tasks',
    DeleteLocation = "deleteTaskStore",
}

const deleteLocationColumn: Column<ParentTaskChildModel> = {
    id: TaskByLocationCellsIds.DeleteLocation,
    Header: 'delete',
    Cell: TaskStoreDeleteCell,
    width: 50,
    defaultCanSort: true,
}

export function useTasksByLocationsColumns(
    requiresApproval: boolean | undefined,
): Array<Column<ParentTaskChildModel>> {
    const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const istasksDeleteStoreTaskEnabled = useFeatureState(FeatureFlags.tasksDeleteStoreTask);
    const canCreateDelete = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));

    return useMemo((): Array<Column<ParentTaskChildModel>> => {
        const getSubmittedColumn: (width: string) => Column<ParentTaskChildModel> = width => ({
            id: TaskByLocationCellsIds.SubmittedTasks,
            Header: `${t('Submitted')}`,
            Cell: SubmittedCell,
            accessor: (original: ParentTaskChildModel) => {
                if (typeof (original as ChildLocationTask).submittedStoreTasks === 'number') {
                    return (original as ChildLocationTask).submittedStoreTasks;
                }
                return undefined;
            },
            width,
        });

        const baseDataCells: Array<Column<ParentTaskChildModel>> = (isMobile ? [
            {
                id: TaskByLocationCellsIds.Location,
                Header: `${t('Location')}`,
                Cell: TaskLocationMobileCell,
                accessor: 'locationName',
                defaultCanSort: true,
            },
            requiresApproval ? getSubmittedColumn('70px') : null,
        ] : [
            {
                id: TaskByLocationCellsIds.Location,
                Header: `${t('Location')}`,
                Cell: TaskLocationCell,
                accessor: 'locationName',
                defaultCanSort: true,
            },
            requiresApproval ? getSubmittedColumn('140px') : null,
            {
                id: TaskByLocationCellsIds.Status,
                Header: `${t('Completion Status')}`,
                accessor: 'displayedStatus',
                sortType: sortByStatus,
                Cell: TaskByLocationStatus,
                defaultCanSort: true,
                width: 250,
            },
        ]).filter(item => item !== null) as Array<Column<ParentTaskChildModel>>;

        const dataCells: Array<Column<ParentTaskChildModel>> = istasksDeleteStoreTaskEnabled && canCreateDelete 
            ? [...baseDataCells, deleteLocationColumn] : baseDataCells;

        return [
            {
                id: TaskByLocationCellsIds.Color,
                Cell: ColorStatusChildCell,
                accessor: 'displayedStatus',
                defaultCanSort: false,
                disableSortBy: true,
                width: 7,
            },
            ...dataCells,
        ];

    }, [requiresApproval, isMobile, istasksDeleteStoreTaskEnabled, canCreateDelete]);
}
