import React, { useCallback } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { InviteTab } from './model';
import { Trans } from 'react-i18next';

export interface IInviteTabsProps {
    currentTab: InviteTab;
    setCurrentTab: React.Dispatch<React.SetStateAction<InviteTab>>;
}

export function InviteTabs({
    currentTab,
    setCurrentTab,
}: IInviteTabsProps) {
    const handleChangeTab = useCallback((e: any, value: InviteTab) => {
        setCurrentTab(value);
    }, [setCurrentTab]);

    return (
        <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
        >
            <Tab
                value={InviteTab.Individual}
                label={<Trans>Individual</Trans>}
                id={InviteTab.Individual}
                wrapped
            />
            <Tab
                value={InviteTab.UserRoles}
                label={<Trans>User Roles</Trans>}
                id={InviteTab.UserRoles}
                wrapped
            />
        </Tabs>
    );
}
