import { TaskTaglistMultiselect } from 'modules/shared/components/TaskTaglistMultiselect';
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { resetTasksStatistics, setTaskTags } from 'store/modules/task/statistics/statisticsActions';
import { selectTasksStatsByLocationTags } from 'store/modules/task/statistics/statisticsSelectors';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';

export const LocationTagFilter = () => {
  const isTasksLocationViewTagFilterEnabled = useFeatureState(FeatureFlags.TasksLocationViewTagFilter);
  const tagFilter = useSelector(selectTasksStatsByLocationTags);
  const actions = useActions({
    resetStatistics: resetTasksStatistics,
    setTags: setTaskTags,
  });

  const handleFilterTagChange = useCallback(
    (newFilterTag: string[]) => {
      if (JSON.stringify(tagFilter) !== JSON.stringify(newFilterTag)) {
        actions.current.resetStatistics();
        actions.current.setTags(newFilterTag)
      }
    },
    [actions, tagFilter]
  );

  if (!isTasksLocationViewTagFilterEnabled) return <></>;

  return (
    <TaskTaglistMultiselect
      onTagsValuesChange={handleFilterTagChange}
    />
  )
}
