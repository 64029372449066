import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { MessageInput, useChatContext } from 'stream-chat-react';
import { useSelector } from 'react-redux';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { MessageInputPlaceholder } from '../placeholders/MessageInputPlaceholder';
import { t } from 'i18next';

export function TeamMessageInput() {
    const { channel } = useChatContext();
    const userCanUseFrozenChannel = useSelector(selectHasAppAccess(AppName.ConnectCreateDelete));
    const isFrozenChannel = !!channel?.data?.frozen;

    if (isFrozenChannel && !userCanUseFrozenChannel) {
        return (
            <MessageInputPlaceholder
                icon={<InfoIcon />}
                text={`${t('This channel is frozen and only intended for moderated announcements')}`}
            />
        );
    }

    return (
        <MessageInput focus maxRows={5} grow mentionQueryParams={{filters: {deactivated_at:{ $exists: false}} }}/>
    );
}
