import { useCallback } from 'react';
import { storeTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskActions';
import { useActions } from "utils/store/useActions";

export function useStoreTaskSidebar(taskId: string, isAutomation?: boolean) {
    const actions = useActions({
        open: storeTaskSetActiveId,
    });

    const openStoreTaskSidebar = useCallback(() => {
        actions.current.open({taskId, isAutomation});
    }, [actions, taskId, isAutomation]);

    return { openStoreTaskSidebar };
}
