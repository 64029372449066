import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    GridSize,
    Paper,
    styled,
} from '@material-ui/core';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import {
    colorStatusDictionary,
    IntegationBase,
} from 'store/modules/integration/integrationModel';
import { Breakpoints } from 'utils/styles/constants';
import LogoShopify from 'images/logo-shopify.png';
import LogoEntraID from 'images/logo-microsoft-entra-id.png';
import LogoDrive from 'images/logo-drive.png';
import LogoDropbox from 'images/logo-dropbox.png';
import LogoFileUpload from 'images/logo-file-upload.png';

type LogoKeys =
    | 'shopify-mock'
    | 'microsoft-entra-id-mock'
    | 'drive-mock'
    | 'dropbox-mock'
    | 'single-fle-mock';

const logosDictionary: Record<LogoKeys, string> = {
    'shopify-mock': LogoShopify,
    'microsoft-entra-id-mock': LogoEntraID,
    'drive-mock': LogoDrive,
    'dropbox-mock': LogoDropbox,
    'single-fle-mock': LogoFileUpload,
};

export const StyledGridPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        marginTop: theme.spacing(1),
    },
}));

type GridIntegrationBaseProps = {
    children: ReactNode;
};

export const GridIntegration: React.FC<GridIntegrationBaseProps> = ({
    children,
}) => (
    <Container style={{ marginTop: 4, maxWidth: '100%' }}>
        <Grid container spacing={3} alignItems='stretch'>
            {children}
        </Grid>
    </Container>
);

export const GridIntegrationChildren: React.FC<
    GridIntegrationBaseProps & {
        gridProp?: {
            xs?: boolean | GridSize;
            sm?: boolean | GridSize;
            md?: boolean | GridSize;
        };
    }
> = ({ children, gridProp = { xs: 12, sm: 6 } }) => (
    <Grid item {...gridProp}>
        {children}
    </Grid>
);

export const CardIntegration: React.FC<IntegationBase> = (props) => (
    <Card
        variant='outlined'
        style={{
            maxWidth: 300,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
        }}
    >
        <CardContent>
            <CardMedia
                src={logosDictionary[props.id as LogoKeys]}
                image={logosDictionary[props.id as LogoKeys]}
                style={{ width: 230, height: 80, backgroundSize: 'contain' }}
                title={`${props.name}`}
            />
            <Box
                width={15}
                height={15}
                position='absolute'
                right={5}
                top={5}
                style={{
                    backgroundColor: colorStatusDictionary[props.status],
                    borderRadius: '50%',
                }}
            />
        </CardContent>
        <CardActions>
            <Button
                variant='contained'
                color='primary'
                aria-label={`${t('Connect')}`}
                component='span'
                style={{
                    marginRight: 4,
                    maxWidth: 180,
                    marginLeft: 'auto',
                }}
                disabled
                size='medium'
            >
                <Trans>Connect</Trans>
            </Button>
        </CardActions>
    </Card>
);

export const CardIntegrationAISidekick: React.FC<IntegationBase> = (props) => (
    <Card
        variant='outlined'
        style={{
            maxWidth: 200,
        }}
    >
        <CardContent
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                gap: 4,
            }}
        >
            <CardMedia
                src={logosDictionary[props.id as LogoKeys]}
                image={logosDictionary[props.id as LogoKeys]}
                style={{ width: 180, height: 80, backgroundSize: 'contain' }}
                title={`${props.name}`}
            />
            <Box
                width={15}
                height={15}
                style={{
                    backgroundColor: colorStatusDictionary[props.status],
                    borderRadius: '50%',
                }}
            />
        </CardContent>
    </Card>
);
