import React, { useCallback } from 'react';
import { Drawer, Theme, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/store/useActions';
import { Breakpoints } from 'utils/styles/constants';
import { TaskByLocationsView } from '../TaskByLocationsView/TaskByLocationsView';
import { selectParentTaskByLocationModalActiveId } from 'store/modules/task/calendarTask/calendarSelectors';
import { parentTaskByLocationModalActiveId } from 'store/modules/task/calendarTask/calendarTaskActions';

const useTaskSidebarStyles = makeStyles((theme: Theme) => ({
  sidebarPaper: {
      width: theme.spacing(117),
      maxWidth: '100vw',

      [theme.breakpoints.down(Breakpoints.Tablet)]: {
          minWidth: '100vw',
          width: '100vw',
      },
  },
}));

export function TaskByLocationsSideOut() {
    const actions = useActions({ setModalParentTaskId: parentTaskByLocationModalActiveId });
    const parentTaskId = useSelector(selectParentTaskByLocationModalActiveId);

    const onModalClose = useCallback(() => {
        actions.current.setModalParentTaskId(null);
    }, [actions]);
    const classes = useTaskSidebarStyles();

    return (
      <Drawer
          anchor="right"
          open={!!parentTaskId}
          onClose={onModalClose}
          classes={{ paper: classes.sidebarPaper }}
      >
        <>
          {parentTaskId && 
            <TaskByLocationsView parentTaskId={parentTaskId} />
          }
        </>
      </Drawer>
    )
}
