import React, { useCallback } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useTaskByLocationsHeaderStyles } from 'modules/task/taskList/views/TaskByLocationsView/hooks/useTaskByLocationsHeaderStyles';
import { TaskHeaderDetails } from 'modules/task/taskList/components/TaskHeaderDetails/TaskHeaderDetails';
import { Box } from '@material-ui/core';
import { StyledDotWrapper } from 'modules/task/taskList/components/SimpleTaskListHeader';
import { useSelector } from 'react-redux';
import { selectCalendarEventDetail } from 'store/modules/task/calendarTask/calendarSelectors';
import { EventLocationSection } from './EventLocationSection';
import { TagBoxColorTooltip } from 'modules/task/common/taglist/TagBoxColorTooltip';

export const EventHeader: React.FC = () => {
    const event = useSelector(selectCalendarEventDetail);
    const taskByLocationHeaderClasses = useTaskByLocationsHeaderStyles([]);

    const renderTitle = useCallback(() => {
        return event?.title
            ? (
                <h2 className={taskByLocationHeaderClasses.title}>
                    {event?.title}
                </h2>
            )
            : <Skeleton width={160} />
    }, [event, taskByLocationHeaderClasses]);

    const renderDetails = useCallback(() => {
        return event?.eventEnd
            ? (
                <TaskHeaderDetails
                    dueDate={event?.eventEnd}
                    createdBy={event?.createdBy}
                />
            )
            : <Skeleton width={50} />
    }, [event]);

    return (
        <header>
            <div className={taskByLocationHeaderClasses.infoWrapper} style={{marginTop: -4}}>
                <div className={taskByLocationHeaderClasses.info}>
                    <div className={taskByLocationHeaderClasses.titleWrapper}>
                        {event ? (
                            <>
                                <Box className={taskByLocationHeaderClasses.headerTitleLocationContainer}>
                                    {renderTitle()}
                                </Box>
                                <Box className={taskByLocationHeaderClasses.headerTitleLocationContainer}>
                                    <StyledDotWrapper>•</StyledDotWrapper>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        {event?.tag && (<Box display="flex" alignItems="center" ml={.5} mr={.5}><TagBoxColorTooltip tag={event?.tag} /></Box>)}
                                        <EventLocationSection storeLocationIds={event.locations ?? []} />
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <Skeleton height={32} width={250} />
                        )}
                    </div>
                    {renderDetails()}
                </div>
            </div>
        </header>
    );
}
