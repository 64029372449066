import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

export const convertToStringType = tPromise.extendType(
    t.union([t.string, t.null]),
    encoded => encoded === null ? '' : encoded,
    value => value,
);

export const nullableBooleanType = t.union([t.undefined, t.null, t.boolean]);

export const nullableStringType = t.union([t.null, t.string]);

export const requiredStringType = t.string;

export type DataType = (
    typeof convertToStringType
    | typeof nullableStringType
    | typeof requiredStringType
    | typeof nullableBooleanType
);
