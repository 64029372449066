import { Logger, LogLevel } from 'stream-chat';
import { ILogger } from './model';

type StreamChatLoggerCreator = (logger: ILogger) => Logger;

export const createStreamChatLogger: StreamChatLoggerCreator = (logger: ILogger) => (
    logLevel: LogLevel,
    message: string,
    extraData?: Record<string, unknown>,
) => {
    switch(logLevel) {
        case 'error':
            logger.reportError(new Error(`[Stream Chat | ERROR]: ${message}`), extraData);
            break;
        case 'warn':
        case 'info':
        default:
            return;
    }
};
