import React from 'react';
import { Fade, Tooltip } from '@material-ui/core';
import { TaskTagType } from 'api/task/tag/taskTagModel';
import { TagBoxColor } from './TagBoxColor';

export type TagSelectedProps = {
    tag: TaskTagType
}

export const TagBoxColorTooltip: React.FC<TagSelectedProps> = ({ tag }) => (
    <Tooltip title={tag.name} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
        <TagBoxColor bgcolor={tag.color}/>
    </Tooltip>
);
