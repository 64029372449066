import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Paper, TableContainer, TableCell, TableRow } from '@material-ui/core';
import { NotificationConnect, NotificationTasks, RoleSettingsDTO } from 'api/knock/knockModel';
import {
    StyledTable,
    StyledTableBody,
    StyledTableHead,
} from 'modules/shared/components/StyledTable';
import { Column, HeaderGroup, Row, useTable } from 'react-table';
import { NotificationTypeCell } from './NotificationTypeCell';
import { CheckboxCell } from './CheckboxCell';
import { ColumnId, columnIdToPropMap, NotificationRowData } from './model';
import { reducer, reset } from './reducer';
import { SettingsGridContextValue, SettingsGridProvider } from './SettingsGridProvider';
import { rowDataToSettings, settingsToRowData } from './helpers';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export type SettingsGridProps = {
    roleSettings: RoleSettingsDTO;
    onRoleSettingsChange?: (roleSettings: RoleSettingsDTO) => void;
    disabled?: boolean;
}

const columns: Column[] = [
    {
        id: ColumnId.NotificationType,
        Header: <Trans>Notification</Trans>,
        accessor: columnIdToPropMap[ColumnId.NotificationType],
        Cell: NotificationTypeCell,
    },
    {
        id: ColumnId.InApp,
        Header: <Trans>In App</Trans>,
        accessor: columnIdToPropMap[ColumnId.InApp],
        Cell: CheckboxCell,
        width: 100,
    },
    {
        id: ColumnId.Email,
        Header: <Trans>Email</Trans>,
        accessor: columnIdToPropMap[ColumnId.Email],
        Cell: CheckboxCell,
        width: 100,
    },
    {
        id: ColumnId.SMS,
        Header: <Trans>SMS</Trans>,
        accessor: columnIdToPropMap[ColumnId.SMS],
        Cell: CheckboxCell,
        width: 100,
    },
];

const headerRowRenderer = (headerGroup: HeaderGroup, sectionName: string) => {
    let first = true;
    const firstHeader = headerGroup.headers.length > 0 ? headerGroup.headers[0] : null;
    return (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
            <TableCell {...firstHeader?.getHeaderProps()}>
                {sectionName}
            </TableCell>
            {headerGroup.headers.filter(x => {if(first) {
                first = false;
                return false;
            }
            return true;
            }).map(header => (
                <TableCell {...header.getHeaderProps()}>
                    {
                        header.render('Header')
                    }
                </TableCell>
            ))}
        </TableRow>
    );
};

const bodyRowRenderer = (row: Row) => {
    return (
        <TableRow {...row.getRowProps()}>
            {row.cells.map(cell => (
                <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                </TableCell>
            ))}
        </TableRow>
    );
};

export const SettingsGrid = ({ roleSettings, disabled, onRoleSettingsChange }: SettingsGridProps) => {
    const [data, dispatch] = useReducer(reducer, roleSettings, settingsToRowData);
    const { rows, prepareRow, getTableProps, getTableBodyProps, headerGroups } = useTable({
        columns,
        data,
    });

    const renderRow = useCallback((row: Row) => {
        prepareRow(row);

        return bodyRowRenderer(row);
    }, [prepareRow]);

    useEffect(() => {
        dispatch(reset(roleSettings));
    }, [roleSettings]);

    useEffect(() => {
        onRoleSettingsChange?.(rowDataToSettings(data));
    }, [onRoleSettingsChange, data]);

    const context = useMemo<SettingsGridContextValue>(() => ({
        dispatch,
        disabled,
    }), [disabled]);

    const rowsTasks = rows.filter(x => NotificationTasks.includes((x.original as NotificationRowData).notificationType));
    const userTasks = rows.filter(x => NotificationConnect.includes((x.original as NotificationRowData).notificationType));

    return (
        <SettingsGridProvider value={context}>
            <TableContainer component={Paper}>
                <StyledTable {...getTableProps()}>
                    <StyledTableHead>
                        {headerGroups.map(x => headerRowRenderer(x, `${t('Tasks Notifications')}`))}
                    </StyledTableHead>
                    <StyledTableBody {...getTableBodyProps()}>
                        {rowsTasks.map(renderRow)}
                    </StyledTableBody>
                    <StyledTableHead>
                        {headerGroups.map(x => headerRowRenderer(x, `${t('Connect Notifications')}`))}
                    </StyledTableHead>
                    <StyledTableBody {...getTableBodyProps()}>
                        {userTasks.map(renderRow)}
                    </StyledTableBody>
                </StyledTable>
            </TableContainer>
        </SettingsGridProvider>
    );
};
