import React from 'react';
import { useTaskHeaderDetailsStyles } from 'modules/task/taskList/components/TaskHeaderDetails/useTaskHeaderDetailsStyles';
import { TaskHeaderUserDetails } from './TaskHeaderUserDetails';
import { Divider } from './Divider';
import { formatDateLocalized, shortDateFormat } from 'utils/helpers/dateHelper';
import { Trans } from 'react-i18next';

export type TaskHeaderSubmissionDetailsProps = {
    submittedBy: string;
    submissionDate: string;
}

export function TaskHeaderSubmissionDetails({
    submittedBy,
    submissionDate,
}: TaskHeaderSubmissionDetailsProps) {
    const classes = useTaskHeaderDetailsStyles([]);

    return (
        <div className={classes.detailsWrapper}>
            <TaskHeaderUserDetails label={<Trans>Submitted By</Trans>} userId={submittedBy} />
            <Divider orientation="vertical" />
            <b>{formatDateLocalized(submissionDate, shortDateFormat)}</b>
        </div>
    );
}
