import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
    counter: {
        ...theme.typography.body2,
        fontWeight: 700,
        background: UtilColors.Red,
        height: '18px',
        minWidth: '18px',
        borderRadius: '6px',
        color: UtilColors.White,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '0 5px',
    },
    counterHidden: {
        opacity: 0,
    },
}));

export interface ICounterProps {
    count: number;
}

export function Counter({ count }: ICounterProps) {
    const isHidden = count === 0;
    const value = count > 99 ? '+99' : `${count}`;
    const classes = useStyles();
    const counterClass = clsx(classes.counter, {
        [classes.counterHidden]: isHidden,
    });
    return (
        <div className={counterClass}>
            {value}
        </div>
    );
}
