import { ItemsById } from 'model/entity';
import { combineReducers } from 'redux';
import { TaskPrototype } from 'api/task/prototype/prototypeModel';
import { TaskPrototypeActions, getTaskPrototype, updateTaskPrototype } from 'api/task/prototype/prototypeActions';

export type PrototypeStoreState = {
    prototypesById: ItemsById<TaskPrototype>;
    loadingById: ItemsById<boolean>;
    updatingById: ItemsById<boolean>;
}

const initialState: PrototypeStoreState = {
    prototypesById: {},
    loadingById: {},
    updatingById: {},
};

function prototypesById(
    state: ItemsById<TaskPrototype> = initialState.prototypesById,
    action: TaskPrototypeActions,
): ItemsById<TaskPrototype> {
    switch (action.type) {
        case getTaskPrototype.successType:
        case updateTaskPrototype.successType:
            const { id } = action.payload;
            return { ...state, [id]: action.payload };
        default:
            return state;
    }
}

function loadingById(
    state: ItemsById<boolean> = initialState.loadingById,
    action: TaskPrototypeActions,
): ItemsById<boolean> {
    switch (action.type) {
        case getTaskPrototype.initType:
            return { ...state, [action.payload.prototypeId]: true };
        case getTaskPrototype.successType:
        case getTaskPrototype.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function updatingById(
    state: ItemsById<boolean> = initialState.updatingById,
    action: TaskPrototypeActions,
): ItemsById<boolean> {
    switch (action.type) {
        case updateTaskPrototype.initType:
            return { ...state, [action.payload.id]: true };
        case updateTaskPrototype.successType:
        case updateTaskPrototype.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

export const prototypesReducer = combineReducers({
    prototypesById,
    loadingById,
    updatingById,
});
