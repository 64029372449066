import React, { useMemo } from 'react';
import {
    makeStyles,
    withStyles,
    Avatar,
    Badge,
    Box,
    createStyles,
    Typography, useMediaQuery, Theme,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { BlueColor, Breakpoints, GrayColor, GreenColor } from 'utils/styles/constants';
import { HideAction } from './chatActions/HideAction';
import { ChatContextValue } from 'stream-chat-react';
import { useDirectMessageHeadingUser } from '../../hooks/useDirectMessageHeadingUser';
import { useSelector } from "react-redux";
import { selectUsersByIds, selectUsersLoadingById } from "store/modules/user/userSelectors";
import { SearchChatPanel } from '../SearchChannel/SearchChatPanel';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { PinAction } from './chatActions/PinAction/PinAction';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down(Breakpoints.DesktopSmall)]: {
            justifyContent: 'center',
            gap: theme.spacing(1)
        },
    },
    rootMobile: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '145px',
        flexDirection: 'column',
    },
    headingWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    headingWrapperMobile: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '5px',
    },
    avatar: {
        width: '48px',
        height: '48px',
    },
    avatarInner: {
        backgroundColor: BlueColor.Dark,
        color:'white',
        width: '48px',
        textAlign: 'center',
        lineHeight: '48px',
    },
    badge: {
        height: '12px',
        width: '12px',
    },
    headingMeta: {
        marginLeft: '6px',
        height: '48px',
        boxSizing: 'border-box',
        padding: '2px 0 4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    name: {
        display: 'flex',
        fontFamily: `'Inter', 'Arial', sans-serif`,
        fontSize: '19px',
        lineHeight: '26px',
        fontStyle: 'normal',
        fontWeight: 700,
        color: GrayColor.GrayBlack,
        verticalAlign: 'middle',
    },
    status: {
        fontFamily: `'Inter', 'Arial', sans-serif`,
        fontSize: '12px',
        lineHeight: '17px',
        fontStyle: 'normal',
        fontWeight: 500,
        color: GrayColor.GrayBlue,
    },
}));

const badgeSize = '12px';
// eslint-disable-next-line @typescript-eslint/naming-convention
const OnlineBadge = withStyles(() => createStyles({
    badge: {
        transform: 'none !important',
        minHeight: badgeSize,
        height: badgeSize,
        maxHeight: badgeSize,
        minWidth: badgeSize,
        width: badgeSize,
        maxWidth: badgeSize,
        backgroundColor: GreenColor.Green60,
        color: GreenColor.Green60,

        '&.MuiBadge-invisible': {
            opacity: 0,
        },
    },
}))(Badge);

export interface IDirectMessageHeaderProps {
    chatContext: ChatContextValue;
}

export function DirectMessageHeader({ chatContext }: IDirectMessageHeaderProps) {
    const classes = useStyles();
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const rootClass = useMemo(() => {
        return isMobile ? classes.rootMobile : classes.root;
    }, [isMobile, classes]);
    const headingWrapperClass = useMemo(() => {
        return isMobile ? classes.headingWrapperMobile : classes.headingWrapper;
    }, [isMobile, classes]);
    const isChatSearchEnabled = useSelector(selectFeatureFlags)[FeatureFlags.ChatSearchEnabled]?.enabled ?? false;
    const { userId, name, image, isOnline } = useDirectMessageHeadingUser(chatContext);
    const userLoading = !!useSelector(selectUsersLoadingById)[userId || ''];
    const user = useSelector(selectUsersByIds)[userId || ''];

    const showUserDeactivated = user?.isDeleted;
    const status = useMemo(() => {
        if (userLoading) {
            return `${t('Loading')}...`;
        }

        if (showUserDeactivated) {
            return `${t('Deactivated')}`;
        }

        return isOnline ? `${t('Online')}` : `${t('Offline')}`;
    }, [userLoading, isOnline, showUserDeactivated]);

    return (
        <Box className={rootClass}>
            <Box className={headingWrapperClass}>
                <OnlineBadge
                    invisible={!isOnline}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Avatar
                        className={classes.avatar}
                        variant="circular"
                        src={image}
                    >
                        <div className={classes.avatarInner}>
                            {user?.firstName?.substring(0, 1)?.toUpperCase()}{user?.lastName?.substring(0, 1)?.toUpperCase()}
                        </div>
                    </Avatar>
                </OnlineBadge>
                <Box className={classes.headingMeta}>
                    <Typography className={classes.name}>
                        {name}
                        {!userLoading && showUserDeactivated && (
                            <BlockIcon />
                        )}
                    </Typography>
                    <Typography className={classes.status}>
                        {status}
                    </Typography>
                </Box>
            </Box>
            {isChatSearchEnabled && (
                <Box>
                    <SearchChatPanel chatContext={chatContext}/>
                </Box>
            )}
            <Box>
                <PinAction/>
                <HideAction />
            </Box>
        </Box>
    );
}
