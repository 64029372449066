import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Avatar, Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';
import { Counter } from '../../../Counter';
import channelDefaultImage from 'images/channel-default-image.png';
import { selectFeatureFlags } from 'store/modules/featureFlags/selectors';
import { useSelector } from 'react-redux';
import { FeatureFlags } from 'utils/featureFlags';

const TOOLTIP_DELAY_MS = 500;

const useStyles = makeStyles(() => ({
    channelPreviewRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '12px 40px',
        boxSizing: 'border-box',
        backgroundColor: UtilColors.Transparent,
        transition: 'background-color 0.3s',
        position: 'relative',
    },
    channelSelected: {
        backgroundColor: BlueColor.Pale,
    },
    channelNameContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        position: 'relative'
    },
    title: {
        maxWidth: '95%',
        lineHeight: '17px',
        fontWeight: 500,
        color: GrayColor.GrayBlue,
        wordBreak: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    titleUnread: {
        fontWeight: 700,
        color: GrayColor.GrayBlack,
    },
    avatar: {
        '& img':{
            width: '90%',
            height: '90%',
        }
    },
    counterContainer: {position:'absolute',
        top: '-10px',
        left: '27px'
    }
}));

export interface IChannelPreviewProps {
    id: string,
    title: string;
    unreadCound: number;
    isSelected: boolean;
    onSelect: () => void;
    channelImage?: string;
}

export function ChannelPreview({
    id,
    title,
    unreadCound,
    isSelected,
    onSelect,
    channelImage
}: IChannelPreviewProps) {
    const classes = useStyles();
    const isConnectChannelImageEnabled = useSelector(selectFeatureFlags)[FeatureFlags.connectChannelImage];
    const wrapperClass = clsx(classes.channelPreviewRoot, {
        [classes.channelSelected]: isSelected,
    });
    const titleClass = clsx(classes.title, {
        [classes.titleUnread]: unreadCound > 0,
    });
    const channelImageURL = useMemo(() => (channelImage ?? channelDefaultImage ), [channelImage]);

    return (
        <Box className={wrapperClass} onClick={onSelect}>
            <Tooltip
                title={title}
                placement="top"
                enterDelay={TOOLTIP_DELAY_MS}
                enterNextDelay={TOOLTIP_DELAY_MS}
            >
                <Box className={classes.channelNameContainer}>
                    { isConnectChannelImageEnabled &&
                        <>
                            <Avatar
                                className={classes.avatar}
                                variant="circular"
                                src={channelImageURL}
                            />
                            { !isSelected && <Box className={classes.counterContainer}><Counter count={unreadCound}/> </Box>}
                        </>
                    }
                    <Typography
                        className={titleClass}
                        variant="body2"
                    >
                        # {title}
                    </Typography>
                </Box>
            </Tooltip>
            {/* 
                // comment out this code for this https://progressretail.atlassian.net/browse/PL-9420
                { isSelected && (
                <DeleteConversationButton
                    conversationId={id}
                />
            )} */}
            { !isConnectChannelImageEnabled && 
                <>
                    { !isSelected &&<Counter count={unreadCound}/> }
                </>
            }
        </Box>
    );
}
