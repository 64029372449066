import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Sidebar } from 'components/sidebar/Sidebar';
import { Dropzone } from 'modules/task/attachments/components/Dropzone';
import { getFilesLoadingState } from 'store/modules/files/filesSelectors';
import { useAttachmentsPanelStyles } from './styles';
import { AttachmentsList } from '../AttachmentsList';
import { AttachmentsEmptyState } from '../AttachmentsEmptyState';
import { useDropHandler } from 'store/modules/files/filesHooks';
import { scrollDown } from 'utils/helpers/domHelpers';
import { AttachmentsUploadButton } from '../AttachmentsUploadButton';
import { AttachmentsMode } from '../../Attachments/model';
import { AttachmentsListEdit, AttachmentsListView } from '../AttachmentsList/AttachmentsList';
import { AttachmentsLoader } from './AttachmentsLoader';
import { Trans } from 'react-i18next';

export interface IAttachmentsPanelProps {
    isOpened: boolean;
    handleClose: () => void;
    mode?: AttachmentsMode;
}

function renderAttachments(
    isLoading: boolean,
    mode: AttachmentsMode,
) {
    if (isLoading) {
        return <AttachmentsLoader/>;
    }
    switch (mode) {
        case AttachmentsMode.Create:
            return <AttachmentsList/>;

        case AttachmentsMode.View:
            return <AttachmentsListView/>;

        case AttachmentsMode.Edit:
            return <AttachmentsListEdit/>;
        default:
            return <AttachmentsEmptyState/>;
    }
}

export function AttachmentsPanel({
    isOpened,
    handleClose,
    mode = AttachmentsMode.Create,
}: IAttachmentsPanelProps) {
    const filesWrapperRef = useRef<HTMLDivElement | null>(null);
    const afterDrop = useCallback(() => scrollDown(filesWrapperRef.current), [filesWrapperRef]);
    const dropHandler = useDropHandler(afterDrop);
    const isLoading = useSelector(getFilesLoadingState);
    const isViewMode = mode === AttachmentsMode.View;
    const classes = useAttachmentsPanelStyles([]);
    const filesListClasses = clsx(classes.filesList, {
        [classes.fileListHeightView]: isViewMode,
        [classes.fileListHeight]: !isViewMode,
    });

    useEffect(() => {
        setTimeout(() => scrollDown(filesWrapperRef.current), 300);
    });

    return (
        <Sidebar open={isOpened} onClose={handleClose}
            title={<Trans>Attachments</Trans>}
        >
            <Box
                height="100%" display="flex"
                flexDirection="column" justifyContent="space-between"
                mt="10px"
            >
                <div ref={filesWrapperRef} className={filesListClasses}>
                    { renderAttachments(isLoading, mode) }
                    { !isViewMode && <AttachmentsUploadButton/> }
                </div>
                { !isViewMode && <Dropzone onDrop={dropHandler}/> }
            </Box>
        </Sidebar>
    );
}
