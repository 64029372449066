import React from 'react'
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { AddComment } from './taskConversationForm/AddComment';
import { Box } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { StoreFilterConversation } from './StoreFilterConversation';
import { useSelector } from 'react-redux';
import { selectStoreTaskModalActiveId } from 'store/modules/task/storeTask/storeTaskSelectors';
import { IStoreTaskSetActiveId } from 'store/modules/task/storeTask/storeTaskModel';
import { Conversations } from './Conversations';
import { RemoveComment } from './common/RemoveComment';
import { selectTaskConversationStore } from 'store/modules/task/taskConversation/taskConversationSelectors';

export const TaskConversation: React.FC = () => {
    const istaskConversationsEnabled = useFeatureState(FeatureFlags.taskConversations);
    const storeTaskId: null | IStoreTaskSetActiveId  = useSelector(selectStoreTaskModalActiveId);
    const filterStoreTaskId  = useSelector(selectTaskConversationStore);
    
    if(!istaskConversationsEnabled) return <></>;

    return (
      <Box>
          {!storeTaskId && 
            <>
              <Trans>Conversation</Trans> 
              <StoreFilterConversation />
            </>
          }
          <AddComment />
          <Conversations storeTaskId={filterStoreTaskId} />
          <RemoveComment />
      </Box>
    )
}
