import React, { useEffect, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { Divider, List, makeStyles } from '@material-ui/core';
import { useAttachments } from 'store/modules/files/filesHooks';
import { AttachmentItem, PendingAttachmentItem } from '../AttachmentItem';
import { selectTaskDetails } from 'store/modules/task/taskSelectors';
import { useSelector } from 'react-redux';
import { AttachmentItemView } from '../AttachmentItem/AttachmentItemView';
import { useFormikContext } from 'formik';
import { AttachmentsEmptyState } from '../AttachmentsEmptyState';
import { useActions } from "utils/store/useActions";
import { resetUploadedFiles } from 'store/modules/files/filesActions';
import { AttachmentItemEdit } from '../AttachmentItem/AttachmentItemEdit';
import { TaskPrototype } from 'api/task/prototype/prototypeModel';

const useStyles = makeStyles(() => ({
    divider: {
        backgroundColor: 'rgba(255, 255, 255, 0.65) !important',
    },
}));

export function AttachmentsList() {
    const classes = useStyles([]);
    const { pendingFiles, uploadedFiles } = useAttachments();
    const isDividerNeeded = !!pendingFiles.length && !!uploadedFiles.length;

    if (!pendingFiles.length && !uploadedFiles.length) {
        return <AttachmentsEmptyState />;
    }

    return (
        <List dense>
            {uploadedFiles.map(attach => <AttachmentItem key={attach.fileId} {...attach} />)}
            {isDividerNeeded && <Divider className={classes.divider} />}
            {pendingFiles.map(attach => <PendingAttachmentItem key={attach.localKey} {...attach} />)}
        </List>
    );
}

export function AttachmentsListView() {
    const items = useSelector(selectTaskDetails)?.attachments ?? [];

    if (!items.length) {
        return <AttachmentsEmptyState />;
    }

    return (
        <List dense>
            {
                items.map(item => (
                    <AttachmentItemView
                        title={item.name}
                        uuid={item.fileId}
                        key={item.fileId}
                    />
                ))
            }
        </List>
    );
}

export function AttachmentsListEdit() {
    const classes = useStyles([]);
    const actions = useActions({ resetUploadedFiles });
    const { values, setFieldValue } = useFormikContext<Partial<TaskPrototype>>();
    const { uploadedFiles, pendingFiles } = useAttachments();
    const taskAttachments = useMemo(
        () => values?.attachments ?? [],
        [values?.attachments],
    );

    useEffect(() => {
        if (uploadedFiles.length) {
            const attachments = uniqBy(taskAttachments.concat(uploadedFiles), 'fileId');
            actions.current.resetUploadedFiles();
            setFieldValue('attachments', attachments);
        }
    }, [actions, setFieldValue, taskAttachments, uploadedFiles]);

    if (!taskAttachments.length && !pendingFiles.length) {
        return <AttachmentsEmptyState />;
    }

    const isDividerNeeded = !!taskAttachments.length && !!pendingFiles.length;

    return (
        <List dense>
            {taskAttachments.map(attachment => (
                <AttachmentItemEdit key={attachment.fileId} {...attachment} />
            ))}
            {isDividerNeeded && <Divider className={classes.divider} />}
            {pendingFiles.map(attach => <PendingAttachmentItem key={attach.localKey} {...attach} />)}
        </List>
    );
}
