import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import { IEntityError, IEntitySuccess } from 'model/entity';
import { Launch, LaunchCover, LaunchDeleteRequest, LaunchModalForm, LaunchRequestType, Launchs, LaunchsReorder, ModalDeleteLaunch, ModalFormLaunch } from './launchModel';
import { FeatureFlagState } from 'store/modules/featureFlags/model';

export const LAUNCH_PAGE = `launch/page`;

export const launchPage = createSingleAction<string | undefined, typeof LAUNCH_PAGE>(
    LAUNCH_PAGE
);

const REQUEST_LAUNCH_GET = 'request/launch/GET';

export const requestGetLaunch = (
    createApiActionsByName<string, Launchs, IEntityError, typeof REQUEST_LAUNCH_GET>(REQUEST_LAUNCH_GET)
);

const LAUNCH_UPLOAD = 'request/launch/FILE_UPLOAD';
export const requestLaunchFileUpload = createApiActionsByName<{ companyId?: string, file: File, chunkUploadFeatureState?: FeatureFlagState },
    any,
    any,
    typeof LAUNCH_UPLOAD
>(LAUNCH_UPLOAD);

export const LAUNCH_DELETE = `launch/delete`;
export const launchDelete = createSingleAction<void, typeof LAUNCH_DELETE>(LAUNCH_DELETE);

export const LAUNCH_MODAL_DELETE_OPEN = `launch/delete/OPEN`;
export const launchDeleteModalOpen = createSingleAction<ModalDeleteLaunch, typeof LAUNCH_MODAL_DELETE_OPEN>(LAUNCH_MODAL_DELETE_OPEN);

const REQUEST_LAUNCH_DELETE = 'request/launch/DELETE';
const RESPONSE_LAUNCH_DELETE_SUCCESS = 'response/launch/DELETE_SUCCESS';
const RESPONSE_LAUNCH_DELETE_ERROR = 'response/launch/DELETE_ERROR';

export const requestDeleteLaunch = (
    createApiActions<LaunchDeleteRequest, IEntitySuccess, IEntityError,
        typeof REQUEST_LAUNCH_DELETE,
        typeof RESPONSE_LAUNCH_DELETE_SUCCESS,
        typeof RESPONSE_LAUNCH_DELETE_ERROR
    >(
        REQUEST_LAUNCH_DELETE,
        RESPONSE_LAUNCH_DELETE_SUCCESS,
        RESPONSE_LAUNCH_DELETE_ERROR,
    )
);

export const LAUNCH_MODAL = `launch/modal`;
export const launchModal = createSingleAction<LaunchModalForm, typeof LAUNCH_MODAL>(LAUNCH_MODAL);

export const LAUNCH_MODAL_OPEN = `launch/modal/OPEN`;
export const launchModalOpen = createSingleAction<ModalFormLaunch, typeof LAUNCH_MODAL_OPEN>(LAUNCH_MODAL_OPEN);

const REQUEST_LAUNCH_ADD = 'request/launch/ADD';
const RESPONSE_LAUNCH_ADD_SUCCESS = 'response/launch/ADD_SUCCESS';
const RESPONSE_LAUNCH_ADD_ERROR = 'response/launch/ADD_ERROR';

export const requestAddLaunch = (
    createApiActions<LaunchRequestType, Launch, IEntityError,
        typeof REQUEST_LAUNCH_ADD,
        typeof RESPONSE_LAUNCH_ADD_SUCCESS,
        typeof RESPONSE_LAUNCH_ADD_ERROR
    >(
        REQUEST_LAUNCH_ADD,
        RESPONSE_LAUNCH_ADD_SUCCESS,
        RESPONSE_LAUNCH_ADD_ERROR,
    )
);
const REQUEST_LAUNCH_UPDATE = 'request/launch/UPDATE';
const RESPONSE_LAUNCH_UPDATE_SUCCESS = 'response/launch/UPDATE_SUCCESS';
const RESPONSE_LAUNCH_UPDATE_ERROR = 'response/launch/UPDATE_ERROR';

export const requestUpdateLaunch = (
    createApiActions<LaunchRequestType, Launch, IEntityError,
        typeof REQUEST_LAUNCH_UPDATE,
        typeof RESPONSE_LAUNCH_UPDATE_SUCCESS,
        typeof RESPONSE_LAUNCH_UPDATE_ERROR
    >(
        REQUEST_LAUNCH_UPDATE,
        RESPONSE_LAUNCH_UPDATE_SUCCESS,
        RESPONSE_LAUNCH_UPDATE_ERROR,
    )
);

const REQUEST_LAUNCH_COVER_ADD = 'request/launchCover/ADD';
const RESPONSE_LAUNCH_COVER_ADD_SUCCESS = 'response/launchCover/ADD_SUCCESS';
const RESPONSE_LAUNCH_COVER_ADD_ERROR = 'response/launchCover/ADD_ERROR';

export const requestAddLaunchCover = (
    createApiActions<LaunchCover, string, IEntityError,
        typeof REQUEST_LAUNCH_COVER_ADD,
        typeof RESPONSE_LAUNCH_COVER_ADD_SUCCESS,
        typeof RESPONSE_LAUNCH_COVER_ADD_ERROR
    >(
        REQUEST_LAUNCH_COVER_ADD,
        RESPONSE_LAUNCH_COVER_ADD_SUCCESS,
        RESPONSE_LAUNCH_COVER_ADD_ERROR,
    )
);


const REQUEST_LAUNCH_COVER_DELETE = 'request/launchCover/DELETE';
const RESPONSE_LAUNCH_COVER_DELETE_SUCCESS = 'response/launchCover/DELETE_SUCCESS';
const RESPONSE_LAUNCH_COVER_DELETE_ERROR = 'response/launchCover/DELETE_ERROR';

export const requestDeleteLaunchCover = (
    createApiActions<string, IEntitySuccess, IEntityError,
        typeof REQUEST_LAUNCH_COVER_DELETE,
        typeof RESPONSE_LAUNCH_COVER_DELETE_SUCCESS,
        typeof RESPONSE_LAUNCH_COVER_DELETE_ERROR
    >(
        REQUEST_LAUNCH_COVER_DELETE,
        RESPONSE_LAUNCH_COVER_DELETE_SUCCESS,
        RESPONSE_LAUNCH_COVER_DELETE_ERROR,
    )
);

export const REQUEST_LAUNCH_REORDER = 'request/task/conversation/REQUEST_LAUNCH_REORDER';
export const requestLaunchReorder = (
    createApiActionsByName<{companyId: string, launchs: LaunchsReorder[]}, LaunchsReorder[], IEntityError, typeof REQUEST_LAUNCH_REORDER>(
        REQUEST_LAUNCH_REORDER,
    )
);

export type LaunchActions =
    | ReturnType<typeof launchPage>
    | ReturnType<typeof launchDelete>
    | ReturnType<typeof launchModal>
    | ReturnType<typeof launchModalOpen>
    | ReturnType<typeof launchDeleteModalOpen>
    | ActionsReturnTypes<typeof requestGetLaunch>
    | ActionsReturnTypes<typeof requestLaunchFileUpload>
    | ActionsReturnTypes<typeof requestDeleteLaunch>
    | ActionsReturnTypes<typeof requestAddLaunch>
    | ActionsReturnTypes<typeof requestUpdateLaunch>
    | ActionsReturnTypes<typeof requestAddLaunchCover>
    | ActionsReturnTypes<typeof requestDeleteLaunchCover>
    | ActionsReturnTypes<typeof requestLaunchReorder>;
