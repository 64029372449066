import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    darken,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    lighten,
    styled,
    Typography,
} from '@material-ui/core';
import { requestDeleteParentTask } from 'api/task/parentTask/parentTaskActions';
import { setParentTaskToRemove } from 'store/modules/task/parentTask/parentTaskActions';
import { selectParentTaskToRemove, selectParentTasksById } from 'store/modules/task/parentTask/parentTaskSelectors';
import { useActions } from "utils/store/useActions";
import { GrayColor, UtilColors } from 'utils/styles/constants';
import { ParentTaskShort } from 'api/task/parentTask/parentTaskModel';
import { selectStoreTasksById } from 'store/modules/task/storeTask/storeTaskSelectors';
import { StoreTaskFullModel, StoreTaskShort } from 'api/task/storeTask/storeTaskModel';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

type ModalProps = {
    task: ParentTaskShort | null;
    storeTask: StoreTaskShort | StoreTaskFullModel | null;
}

export const TitleWrapper = styled(Typography)({
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',

    '& strong': {
        fontWeight: 700,
    },
});

export const DialogDescription = styled(Typography)({
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontWeight: 400,
});

export const DeleteButton = styled(Button)({
    backgroundColor: UtilColors.Red3,
    padding: '6px 16px',
    marginLeft: '16px',
    borderRadius: '4px',
    '&:hover': {
        backgroundColor: darken(UtilColors.Red3, 0.05),
    },
    '& span': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: UtilColors.White,
    },
});

export const CancelButton = styled(Button)({
    backgroundColor: GrayColor.GrayE0,
    padding: '6px 16px',
    borderRadius: '4px',
    '&:hover': {
        backgroundColor: darken(GrayColor.GrayE0, 0.05),
    },
    '& span': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: lighten(UtilColors.Black, 0.22),
    },
});

export const StyledDialogActions = styled(DialogActions)({
    padding: '12px',
});

const Modal: React.FC<ModalProps> = ({ task, storeTask }) => {
    const actions = useActions({ setParentTaskToRemove, requestDeleteTask: requestDeleteParentTask.init });
    const handleClickNo = useCallback(() => actions.current.setParentTaskToRemove(null), [actions]);
    const handleClickYes = useCallback(() => {
        actions.current.requestDeleteTask({
            taskId: task?.id || storeTask?.id || '',
            prototypeId: task?.prototypeId || storeTask?.prototypeId || '',
            isFromStoreTask: !!!task
        });
    }, [actions, task, storeTask]);

    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <TitleWrapper>
                    <Trans>Delete</Trans> <strong>{task?.title || storeTask?.title}</strong> {task?.storeTasks &&  <>{t('for_all', {count: task?.storeTasks ?? 0})} {task?.storeTasks} {t('store', {count: task?.storeTasks ?? 0})}</>}
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <DialogDescription>
                    <Trans>You will not be able to undo this. Do you want to proceed?</Trans>
                </DialogDescription>
            </DialogContent>
            <StyledDialogActions>
                <CancelButton onClick={handleClickNo}><Trans>Cancel</Trans></CancelButton>
                <DeleteButton onClick={handleClickYes}><Trans>Delete</Trans></DeleteButton>
            </StyledDialogActions>
        </Dialog>
    );
};

type RemoveParentTaskConfimationProps = {
    isFromStoreTask?: boolean;
}

export const RemoveParentTaskConfimation: React.FC<RemoveParentTaskConfimationProps> = ({isFromStoreTask = false}) => {
    const parentTaskToRemove = useSelector(selectParentTaskToRemove);
    const taskToRemove = useSelector(selectParentTasksById)[parentTaskToRemove || ''];
    const storeTaskToRemove = useSelector(selectStoreTasksById)[parentTaskToRemove || ''];

    if ((!taskToRemove && !isFromStoreTask) || (!storeTaskToRemove && isFromStoreTask)) {
        return null;
    }

    return (
        <Modal 
            task={taskToRemove || null}
            storeTask={storeTaskToRemove || null}
        />
    );
};
