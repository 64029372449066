import { useEffect, useState } from 'react';
import { useConnect } from 'modules/connect/context/ConnectContext';

export function useConversations() {
    const { connectService } = useConnect() || {};
    const { conversationsService } = connectService || {};

    const [channels, setChannels] = useState(conversationsService?.channels.value || []);
    const [channelsReady, setChannelsReady] = useState(conversationsService?.channelsInitialized.value || false);
    const [chats, setChats] = useState(conversationsService?.chats.value || []);
    const [chatsReady, setChatsReady] = useState(conversationsService?.chatsInitialized.value || false);
    const [totalUnreads, setTotalUnreads] = useState(conversationsService?.totalUnreadCount.value || 0);

    useEffect(() => {
        const unsubscribeActions = [
            conversationsService?.channels.onChange(setChannels),
            conversationsService?.chats.onChange(setChats),
            conversationsService?.totalUnreadCount.onChange(setTotalUnreads),
            conversationsService?.channelsInitialized.onChange(setChannelsReady),
            conversationsService?.chatsInitialized.onChange(setChatsReady),
        ];
        return () => {
            unsubscribeActions.forEach(unsubscribe => unsubscribe?.());
        };
    }, [conversationsService]);

    return {
        conversationsService,
        channels,
        channelsReady,
        chats,
        chatsReady,
        totalUnreads,
    };
}
