import { makeStyles, Theme } from '@material-ui/core';

import { Breakpoints, GrayColor, UtilColors } from 'utils/styles/constants';

export const useResetStoresButtonStyles = makeStyles((theme: Theme) => ({
    resetButton: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(2),
    },
}));

export const useLocationPickerModalStyles = makeStyles((theme: Theme) => ({
    dialogPaper: {
        borderRadius: 0,
        backgroundColor: GrayColor.GrayE3,

        [theme.breakpoints.up(Breakpoints.MobileLarge)]: {
            height: theme.spacing(112.5),
            maxHeight: '95vh',
            width: theme.spacing(104),
            maxWidth: '95vw',
        },
    },
    dialogTitle: {
        backgroundColor: UtilColors.White,
        boxSizing: 'border-box',
        padding: theme.spacing(1.5, 2.5, 1.5, 1.5),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: 'background-color 0.3s',
    },
    dialogTitleSelected: {
        backgroundColor: UtilColors.Background,
    },
    dialogTitleTypography: {
        fontFamily: `'Open Sans', sans-serif`,
        fontSize: theme.spacing(3),
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: theme.spacing(4) + 'px',
        letterSpacing: '0.15px',
        textAlign: 'left',

        '& > strong': {
            fontWeight: 700,
        },
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(0, 2, 3),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& > *': {
            marginLeft: theme.spacing(3),
        },
    },
    fixedButtons: {
        boxSizing: 'border-box',
        width: theme.spacing(13.5),
        height: theme.spacing(5),
        boxShadow: `0px 4px 8px rgba(58, 58, 68, 0.16)`,

        '& > span': {
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'uppercase',
        },
    },
}));
