import { StoreTaskFullModel } from "api/task/storeTask/storeTaskModel";

export const calculateTaskProgress = (task: StoreTaskFullModel) => {
    const allItems = task.checklistItems || [];
    const completedItems = allItems.filter(({ completed }) => completed);

    return !allItems.length
        ? 0
        : completedItems.length / allItems.length * 100;
};
