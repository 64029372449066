import React from 'react';
import { HeaderGroup, Cell, TableInstance, Row } from 'react-table';
import { TableCellProps, TableSortLabel, TableSortLabelProps } from '@material-ui/core';
import { ListChildComponentProps } from 'react-window';
import { StyledTableCell, StyledTableRow } from 'modules/shared/components/StyledTable';
import { getScrollbarWidth } from 'utils/helpers/domHelpers';
import { ISkeleton } from '../../task/taskList/model';
import { IEntity } from 'model/entity';

type AsBlock = Pick<TableCellProps, 'component'>

// used to build table using flex layout
export const asBlock: AsBlock = { component: 'div' };

export function getSortingProps(column: HeaderGroup<any>) {
    return column.getSortByToggleProps?.() || {};
}

export function renderSortLabel(column: HeaderGroup<any>) {
    const { canSort, isSorted, isSortedDesc } = column;
    if (!canSort) {
        return null;
    }
    let direction: TableSortLabelProps['direction'];
    if (isSorted) {
        // intended invertion
        direction = isSortedDesc ? 'asc' : 'desc';
    }
    return (
        <TableSortLabel
            active={isSorted}
            direction={direction}
            title=""
        />
    );
}

export const toHeaderCell = (column: HeaderGroup<any>) => {
    const sortingProps = getSortingProps(column);
    const headerProps = column.getHeaderProps(sortingProps);
    return (
        <StyledTableCell
            {...asBlock}
            {...headerProps}
        >
            {column.render('Header')}
            {renderSortLabel(column)}
        </StyledTableCell>
    );
};

export const toHeaderRow = (headerGroup: HeaderGroup<any>) => (
    <StyledTableRow {...asBlock} {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(toHeaderCell)}
    </StyledTableRow>
);

export const toTableRow = (row: Row<any>) => (
    <StyledTableRow {...asBlock} {...row.getRowProps()}>
        {row.cells.map(toTableCell)}
    </StyledTableRow>
);

export const toTableCell = (cell: Cell<any, any>) => (
    <StyledTableCell {...asBlock} {...cell.getCellProps()}>
        {cell.render('Cell')}
    </StyledTableCell>
);

export const makeRowRenderer = (
    (dataGrid: TableInstance<any>) =>
        ({ index, style }: ListChildComponentProps) => {
            const row = dataGrid.rows[index];

            dataGrid.prepareRow(row);
            const rowProps = row.getRowProps({ style, key: row.original.id });
            return (
                <StyledTableRow {...asBlock} {...rowProps}>
                    {row.cells.map(toTableCell)}
                </StyledTableRow>
            );
        }
);

export const GRID_ROW_HEIGHT = 52;

export const SCROLLBAR_WIDTH = getScrollbarWidth();

export function makeSkeletons<T extends ISkeleton & IEntity>(count: number): Array<T> {
    return (
        new Array(count).fill(null).map((v, i) => ({
            id: `${i}`,
            isSkeleton: true,
        })) as unknown as Array<T>
    );
}
