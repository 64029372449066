import { ButtonBase, Typography } from '@material-ui/core';
import { useTaskByLocationStyles } from '../../hooks/useTaskByLocationStyles';
import { Location } from "api/hierarchy/hierarchyModel";
import { KeyboardArrowDown, KeyboardArrowLeft } from "@material-ui/icons";

export type ExpandableLocationProps = {
    location: Location;
    isExpanded: boolean;
    toggleExpanded: () => void;
};

export const ExpandableLocation = ({ location, isExpanded, toggleExpanded }: ExpandableLocationProps) => {
    const classes = useTaskByLocationStyles();

    return (
        <>
            <ButtonBase
                className={classes.expandButton}
                onClick={toggleExpanded}
            >
                {isExpanded
                    ? <KeyboardArrowDown />
                    : <KeyboardArrowLeft />}
            </ButtonBase>
            <Typography>{location.name}</Typography>
        </>
    );
};
