import { useCallback, useMemo } from "react";
import { Formik, FormikProps, useField } from "formik";
import { useSelector } from "react-redux";
import { Box, Theme, Typography, makeStyles } from "@material-ui/core";
import { getFilesConfig } from "store/modules/files/filesSelectors";
import { getLogoUrl } from "utils/helpers/url";
import { FormActions } from "modules/shared/components/FormActions";
import { selectUserCompany, selectUserCompanyLogoFetching } from "store/modules/company/companySelector";
import { useActions } from "utils/store/useActions";
import { requestUpdateCompanyLogo } from "api/company/companyActions";
import { companyLogoSchema } from "./validation";
import { FileInput } from "components/basicInputs/fileInput/FileInput";
import { CompanyLogoType } from "api/company/companyModel";
import { useFeatureState } from "utils/hooks/useFeatureState";
import { FeatureFlags } from "utils/featureFlags";
import { useCurrentUser } from "utils/hooks/useCurrentUser";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        objectFit: "contain",
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.primary.dark
    },
}));

const logoIdFieldName: string = "logoId";
const newLogoFieldName: string = "newLogo";
export type CompanyLogoProps = FormikProps<any> & {
    isDisabled?: boolean;
    logoFetching: boolean;
}

const CompanyLogo = ({ isDisabled, logoFetching, handleSubmit }: CompanyLogoProps) => {
    const classesLogo = useStyles();
    const filesConfig = useSelector(getFilesConfig);
    const [logoIdField] = useField(logoIdFieldName);
    const { value: logoIdValue } = logoIdField;
    const logoUrl = getLogoUrl(filesConfig, { logoId: logoIdValue });
    const isEditCompanyLogoEnabled = useFeatureState(FeatureFlags.EditCompanyLogo);

    return (
        <form onSubmit={handleSubmit}>
            <Box my={1}>
                <Box width={"80%"} minWidth={300}>
                    {logoUrl && <img src={logoUrl} className={classesLogo.logo} />}
                </Box>
            </Box>
            { isEditCompanyLogoEnabled && 
                <>
                    <Box>
                        <FileInput name={newLogoFieldName} accept="image/*"/>
                        <Typography variant="body2"><Trans>The best aspect ratio for the logo image is 4:1 (for example 400x100px)</Trans></Typography>
                        <Typography variant="body2" color="error"><Trans>Accepts <b>jpg</b> or <b>png</b> formats only</Trans></Typography>
                    </Box>
                    <FormActions isInProgress={logoFetching} disabled={isDisabled}/>
                </>
            }
        </form>
    );
};

interface ICompanyLogo {
    logoId: string | undefined;
    newLogo: File | null;
    tenantId: string | undefined;
};

export const CompanyLogoForm = () => {
    const logoFetching = useSelector(selectUserCompanyLogoFetching);
    const companyInformation = useSelector(selectUserCompany);
    const currentUser = useCurrentUser();

    const actions = useActions({
        updateCompanyLogo: requestUpdateCompanyLogo.init,
    });
    const initialFormValues = useMemo(() => ({
        logoId: companyInformation?.logoId,
        newLogo: null,
        tenantId: currentUser?.companyId
    } as ICompanyLogo), [companyInformation, currentUser]);

    const handleSubmit = useCallback((dataForm: ICompanyLogo) => {
        const payload = {
            file: dataForm.newLogo,
            tenantId: dataForm.tenantId
        } as CompanyLogoType;
        actions.current.updateCompanyLogo(payload)
    }, [actions]);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={companyLogoSchema}
            onSubmit={handleSubmit}
        >
            {props => (
                <CompanyLogo
                    isDisabled={!props.isValid}
                    logoFetching={logoFetching}
                    {...props}
                />
            )}
        </Formik>
    )
}
