/* eslint-disable react/display-name */
import React from 'react';
import { generatePath, Redirect, Route, Switch } from 'react-router';
import { AddTaskPage } from 'modules/task/addTask/AddTaskPage';
import { environmentName } from 'utils/globals';
import { Environment } from 'model/environment';
import { createLegacyPage } from 'modules/legacyWrappers/createLegacyPage';
import {
    adminSubRoutes,
    analyticsSubRoutes,
    IRouteConfiguration,
    learnSubRoutes,
    LegacyRoutes,
    Routes,
    superAdminSubRoutes, UsersBetaAppName,
} from 'components/router/model';
import { TaskListPage } from 'modules/task/taskList/TaskListPage';
import { TaskListByLocationPage } from 'modules/task/taskList/TaskListByLocationPage';
import { createEmbeddedSubRoutes } from 'components/router/EmbeddedSubRoutes';
import { AppName } from 'store/modules/apps/appsModel';
import { NotFoundErrorPage } from 'modules/errorPages/NotFoundErrorPage';
import { ConnectPage } from 'modules/connect/ConnectPage';
import { mapRoutes } from 'components/router/mapRoutes';
import { ForbiddenErrorPage } from 'modules/errorPages/ForbiddenErrorPage';
import { ViewTaskPage } from 'modules/task/viewTask/ViewTaskPage';
import { NotificationsPage } from 'modules/notifications/NotificationsPage';
import { FeatureFlags } from 'utils/featureFlags';
import { EditTaskPage } from 'modules/task/editTask/EditTaskPage';
import { TaskListScheduledPage } from 'modules/task/taskList/TaskListScheduledPage';
import { EditTaskTemplatePage } from 'modules/task/editTaskTemplate/EditTaskTemplatePage';
import { AddUserPage } from 'modules/users/user-profile/AddUserPage';
import { EditUserPage } from 'modules/users/user-profile/EditUserPage';
import { UserProfilePage } from 'modules/users/user-profile/UserProfilePage';
import { WelcomePage } from 'modules/welcome/WelcomePage';
import { TaskListCalendarPage } from 'modules/task/taskList/TaskListCalendarPage';
import { AddEventPage } from 'modules/task/addEvent/AddEventPage';
import { EditEventPage } from 'modules/task/editEvent/EditEventPage';

export const routes: Array<IRouteConfiguration> = [
    {
        component: TaskListPage,
        exact: false,
        path: Routes.TaskList,
    },
    {
        component: TaskListByLocationPage,
        exact: false,
        path: Routes.TaskListByLocation,
        appName: AppName.Tasks,
    },
    {
        component: EditTaskTemplatePage,
        path: Routes.ScheduledTaskDetails,
        appName: AppName.TasksCreateDelete,
        featureFlag: FeatureFlags.ViewScheduledTask,
    },
    {
        component: TaskListScheduledPage,
        exact: false,
        path: Routes.TaskListScheduled,
        appName: AppName.TasksCreateDelete,
        featureFlag: FeatureFlags.ScheduledTasks,
    },
    {
        component: TaskListPage,
        exact: false,
        path: Routes.MyTasks,
    },
    {
        component: AddEventPage,
        path: Routes.AddCalendarEvent,
        appName: AppName.TasksCreateDelete,
        featureFlag: FeatureFlags.CalendarEvents,
    },
    {
        component: EditEventPage,
        path: Routes.EditCalendarEvent,
        appName: AppName.TasksCreateDelete,
        featureFlag: FeatureFlags.CalendarEvents,
    },
    {
        component: TaskListCalendarPage,
        exact: false,
        path: Routes.TaskListCalendar,
        featureFlag: FeatureFlags.TasksCalendarView,
    },
    {
        component: AddTaskPage,
        path: Routes.AddTask,
        appName: AppName.TasksCreateDelete,
    },
    {
        component: EditTaskPage,
        path: Routes.EditTask,
        appName: AppName.TasksCreateDelete,
    },
    {
        component: ViewTaskPage,
        path: Routes.ViewTask,
        appName: AppName.Tasks,
    },
    {
        component: ConnectPage,
        path: Routes.Connect,
        withMain: false,
    },
    {
        withMain: false,
        path: Routes.Learn,
        component: createEmbeddedSubRoutes(Routes.Learn, learnSubRoutes),
    },
    {
        legacy: true,
        component: createLegacyPage('Knowledge/Library'),
        appName: AppName.Library,
        path: Routes.Library,
    },
    {
        legacy: true,
        path: Routes.Profile,
        component: createLegacyPage('Home/EditProfile'),
    },
    {
        path: Routes.Analytics,
        appName: AppName.Analytics,
        withMain: false,
        component: createEmbeddedSubRoutes(Routes.Analytics, analyticsSubRoutes),
    },
    {
        path: Routes.Admin,
        appName: AppName.Admin,
        withMain: false,
        component: createEmbeddedSubRoutes(Routes.Admin, adminSubRoutes),
    },
    {
        path: Routes.SuperAdmin,
        appName: AppName.SuperAdmin,
        withMain: false,
        component: createEmbeddedSubRoutes(Routes.SuperAdmin, superAdminSubRoutes),
    },
    {
        path: Routes.Notifications,
        withMain: false,
        component: createLegacyPage(LegacyRoutes.UsersNotifications),
        featureFlag: FeatureFlags.LegacyNotificationFeed,
    },
    {
        component: NotificationsPage,
        path: Routes.NotificationFeed,
    },
    {
        component: UserProfilePage,
        path: Routes.ProfileNew,
        featureFlag: FeatureFlags.Admin2_UserProfile,
    },
    {
        component: AddUserPage,
        path: Routes.AddUser,
        appName: AppName.AdminUsers,
        featureFlag: FeatureFlags.Admin2_UserProfile,
    },
    {
        legacy: true,
        path: Routes.EditUserLegacy,
        appName: AppName.AdminUsers,
        featureFlag: FeatureFlags.Admin2_UserProfile,
        component: createLegacyPage('Admin/Edit_User', true, UsersBetaAppName)
    },
    {
        component: EditUserPage,
        path: Routes.EditUser,
        appName: AppName.AdminUsers,
        featureFlag: FeatureFlags.Admin2_UserProfile,
    },
    {
        component: WelcomePage,
        path: Routes.Welcome,
    },
];

if (environmentName === Environment.Local) {
    const routesMap = {} as Record<string, IRouteConfiguration>;

    routes.forEach(route => {
        if (Reflect.ownKeys(routesMap).includes(route.path)) {
            throw new Error(`Route with path ${route.path} is duplicated`);
        }
        routesMap[route.path] = route;
    });
}

export function AppRouter() {
    return (
        <Switch>
            <Route exact path={Routes.Root}>
                <Redirect to={generatePath(Routes.Welcome)} />
            </Route>

            {routes.map(mapRoutes)}

            <Route exact path={Routes.Tasks}>
                <Redirect to={generatePath(Routes.TaskList)} />
            </Route>

            <Route exact path={Routes.NotFound}>
                <NotFoundErrorPage />
            </Route>

            <Route exact path={Routes.Forbidden}>
                <ForbiddenErrorPage />
            </Route>

            <Route component={NotFoundErrorPage} />
        </Switch>
    );
}
