import React, { useMemo } from 'react';
import { ListItemAvatar, Avatar, Typography, ListItemText, IconButton } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useAttachmentStyles } from './styles';
import { AttachmentInfo } from './AttachmentInfo';

export function AttachmentItemAvatar() {
    const classes = useAttachmentStyles([]);
    return (
        <ListItemAvatar>
            <Avatar className={classes.fileAvatar}>
                <DescriptionOutlinedIcon />
            </Avatar>
        </ListItemAvatar>
    );
}

export interface IMetaProps {
    title: string;
    type?: string;
    size?: number;
}

export function AttachmentItemMeta({ title, type, size }: IMetaProps) {
    const classes = useAttachmentStyles();

    const primary = useMemo(() => (
        <Typography className={classes.textPrimary}>{title}</Typography>
    ), [classes.textPrimary, title]);

    const secondary = useMemo(() => (
        <AttachmentInfo type={type} size={size} />
    ), [type, size]);

    return (
        <ListItemText
            title={title}
            primary={primary}
            secondary={secondary}
        />
    );
}

export interface IDownloadActionProps {
    handleDownloadClick: () => void;
}
export function DownloadAction({ handleDownloadClick }: IDownloadActionProps) {
    return (
        <IconButton color="primary" onClick={handleDownloadClick}>
            <GetAppOutlinedIcon />
        </IconButton>
    );
}
