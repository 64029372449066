import { useCallback } from 'react';

import { FeedItem } from '@knocklabs/client';
import { browserHistory } from 'components/router/model';
import { KnockFeedProviderState, useKnockFeed } from '@knocklabs/react-notification-feed';
import { getItemNavigationPath, getNotificationPath, isKnownNotificationType } from './helpers';

const ACTION_URL_BLOCK_NAME = 'action_url';

export const useNavigationHandler = (onBeforeNavigation?: () => void) => {
    return useCallback((feedItem: FeedItem) => {
        if (isKnownNotificationType(feedItem)) {
            const navPath = getItemNavigationPath(feedItem);

            if (navPath) {
                onBeforeNavigation?.();
                browserHistory.push(navPath);
            }
        } else {
            const actionUrl = feedItem.blocks.find(({ name }) => name === ACTION_URL_BLOCK_NAME);
            const redirectUrl = getNotificationPath(actionUrl);
            
            if (redirectUrl) {
                browserHistory.push(redirectUrl);
            }
        }
    }, [onBeforeNavigation]);
};

// useKnockFeed can actually return null so we wrap this hook for type safety
export const useKnockFeedProviderState = (): KnockFeedProviderState | null => useKnockFeed();
