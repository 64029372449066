import React, { useCallback } from 'react';
import { object as objectSchemaConstructor } from 'yup';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import { transformFormValues } from 'utils/helpers/formSubmit';
import { AddChecklistItemInput } from 'modules/task/common/checklist/AddChecklistItemInput';
import { checklistItemNameSchema } from '../form/validation';

const itemValidationSchema = objectSchemaConstructor({
    item: checklistItemNameSchema.optional(),
});

export interface IAddChecklistItemControlProps {
    onItemAdd: (title: string) => void;
}

export function AddChecklistItemControl({
    onItemAdd,
}: IAddChecklistItemControlProps) {
    const handleItemSubmit = useCallback((values, { resetForm }) => {
        transformFormValues(values);
        if (values.item) {
            onItemAdd(values.item);
        }
        resetForm();
    }, [onItemAdd]);

    return (
        <Box minHeight={56}>
            <Formik
                initialValues={{ item: '' }}
                validationSchema={itemValidationSchema}
                onSubmit={handleItemSubmit}
            >
                {AddChecklistItemInput}
            </Formik>
        </Box>
    );
}
