import React, { useCallback, useEffect, useMemo } from 'react';
import { MultiSelect } from 'components/basicInputs/selectInput/MultiSelect';
import { WatcherItem } from "./WatcherItem";
import { WatcherImage } from "./WatcherImage";
import { useSelector } from "react-redux";
import { selectWatchers } from "store/modules/task/watcher/watcherSelectors";
import { useActions } from "utils/store/useActions";
import { requestWatchers } from "api/task/watcher/watcherActions";
import { WatcherType } from "api/task/watcher/watcherModel";
import { selectUsersByIds } from 'store/modules/user/userSelectors';
import { ItemsById } from 'model/entity';
import { UserDTO } from 'api/user/userModel';
import { useFormikContext } from 'formik';
import { TaskPrototype } from 'api/task/prototype/prototypeModel';
import { WatcherTooltip } from './WatcherTooltip';

export type WatchersDropdownProps = {
    label: string;
    name: string;
    disabled?: boolean;
    cretedBy: string;
}

export const WatchersDropdown = ({ label, name, disabled, cretedBy }: WatchersDropdownProps) => {
    const watcherIds: string[] = useSelector(selectWatchers);
    const usersByIds: ItemsById<UserDTO>  = useSelector(selectUsersByIds);
    const { values } = useFormikContext<ItemsById<TaskPrototype>>();
    const actions = useActions({
        getWatchers: requestWatchers.init,
    });
    const locations = useMemo(()=>values?.locationIds ?? values?.locations ?? [],[values]);

    useEffect(()=>{
        actions.current.getWatchers({ userId: cretedBy , locationsId: locations as string[]});
    },[actions, cretedBy, locations])

    const watchers: Array<WatcherType> = useMemo(()=>
        watcherIds.map(id => ({
            id,
            avatarId: usersByIds[id]?.avatarId || null,
            fullName: `${usersByIds[id]?.firstName} ${usersByIds[id]?.lastName}`,
            firstName: usersByIds[id]?.firstName || '',
            lastName: usersByIds[id]?.lastName || '',
        })
    ),[watcherIds, usersByIds])

    const nameGetter = useCallback(x => (x as WatcherType).fullName, []);

    return (
        <MultiSelect
            disabled={disabled}
            name={name}
            label={<WatcherTooltip label={label}/>}
            options={watchers}
            getName={nameGetter}
            RenderPreview={WatcherImage}
            RenderValue={WatcherItem}
            users
        />
    )
};
