import * as t from 'io-ts';
import { fromEnum } from 'utils/helpers/fromEnum';

export const knockUserTokenResponseSchema = t.type({
    knockUserToken: t.string,
});

export type KnockUserTokenResponse = t.TypeOf<typeof knockUserTokenResponseSchema>;

export enum Notification {
    TaskCreated = 'TaskCreated',
    TaskSubmitted = 'TaskSubmitted',
    TaskApproved = 'TaskApproved',
    TaskDeclined = 'TaskDeclined',
    UserMessaged = 'UserMessaged',
    UserMentioned = 'UserMentioned',
    FrozenChannel = 'FrozenChannel',
    UnreadChannelMessages = 'UnreadChannelMessages',
    WeeklyTaskReport = 'WeeklyTaskReport',
    TaskReminder = 'TaskReminder',
    TaskCommentReaction = 'TaskCommentReaction',
    TaskCommentMention = 'TaskCommentMention',
    TaskComment = 'TaskComment',
}

export const NotificationTasks = [
    Notification.TaskCreated,
    Notification.TaskSubmitted,
    Notification.TaskApproved,
    Notification.TaskDeclined,
    Notification.WeeklyTaskReport,
    Notification.TaskReminder,
    Notification.TaskCommentReaction,
    Notification.TaskCommentMention,
    Notification.TaskComment
]

export const NotificationConnect = [
    Notification.UserMessaged,
    Notification.UserMentioned,
    Notification.FrozenChannel,
    Notification.UnreadChannelMessages,
]

export const channelSettingsSchema = t.type({
    email: t.boolean,
    inApp: t.boolean,
    sms: t.boolean,
    smsOptionAvailable: t.union([t.undefined, t.boolean]),
});

export type ChannelSettingsDTO = t.TypeOf<typeof channelSettingsSchema>;

export const roleSettingsSchema = t.record(
    fromEnum('Notification', Notification),
    channelSettingsSchema
);

export type RoleSettingsDTO = t.TypeOf<typeof roleSettingsSchema>;

export type RoleSettings = {
    roleId: string;
    settings: RoleSettingsDTO;
}
