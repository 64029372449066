import React, { useMemo } from 'react';
import { ChannelsSection } from './ChannelsSection';
import { ChannelPreview } from './ChannelPreview';
import { ChannelListPlaceholder } from '../../../placeholders/ChannelListPlaceHolder';
import { Box, Collapse, Fade } from '@material-ui/core';
import { ListLoadingPlaceholder } from '../../../placeholders/ListLoadingPlaceholder';
import { countChannelUnreads } from 'modules/connect/messenger/utils/channels';
import { ISectionContainerProps } from '../../types';
import { Trans } from 'react-i18next';

const animationTimeoutMs = 500;

export function ChannelsSectionContainer({
    conversations,
    activeConversationCid,
    setActiveConversation,
    showLoader,
}: ISectionContainerProps) {
    const showEmptyPlaceholder = !showLoader && conversations.length === 0;
    const showChannelList = !showLoader && conversations.length > 0;

    const channelList = useMemo(
        () => conversations.map(channel => {
            const isSelected = activeConversationCid === channel.cid;
            return (
                <ChannelPreview
                    key={channel.cid}
                    id={channel.id as string}
                    title={channel.data?.name ?? ''}
                    isSelected={isSelected}
                    onSelect={() => setActiveConversation(channel)}
                    unreadCound={isSelected ? 0 : countChannelUnreads(channel)}
                    channelImage={channel?.data?.image as string ?? undefined}
                />
            );
        }),
        [activeConversationCid, conversations, setActiveConversation],
    );

    return (
        <ChannelsSection>
            <Collapse in={showLoader} timeout={animationTimeoutMs}>
                <Fade in={showLoader} timeout={animationTimeoutMs}>
                    <Box>
                        <ListLoadingPlaceholder/>
                    </Box>
                </Fade>
            </Collapse>

            <Collapse in={showEmptyPlaceholder} timeout={animationTimeoutMs}>
                <Fade in={showEmptyPlaceholder} timeout={animationTimeoutMs}>
                    <Box>
                        <ChannelListPlaceholder>
                            <Trans>You have no channels yet</Trans>
                        </ChannelListPlaceholder>
                    </Box>
                </Fade>
            </Collapse>

            <Collapse in={showChannelList} timeout={animationTimeoutMs}>
                <Fade in={showChannelList} timeout={animationTimeoutMs}>
                    <Box>
                        { channelList }
                    </Box>
                </Fade>
            </Collapse>
        </ChannelsSection>
    );
}
