import { IEntityError } from 'model/entity';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { LocationTasksStatistics } from './statisticsModel';

const TASKS_STATISTICS_BY_LOCATION = 'tasks/statistics/byLocation';
export const requestTasksStatsByLocation = createApiActionsByName<
    {locationId: string, endDate?:string, startDate?: string, tags: string[]}, LocationTasksStatistics, IEntityError, typeof TASKS_STATISTICS_BY_LOCATION
>(
    TASKS_STATISTICS_BY_LOCATION,
);

export type TasksStatsByLocationActions = ActionsReturnTypes<typeof requestTasksStatsByLocation> 
