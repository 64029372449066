import { IStoreState } from 'store/rootReducer';

export const selectCalendarTasksById = (state: IStoreState) => state.task.calendar.calendarTasksById;
export const selectCalendarTasksOrder = (state: IStoreState) => state.task.calendar.calendarTasksOrder;
export const selectCalendarTasksScheduledOrder = (state: IStoreState) => state.task.calendar.calendarTasksScheduledOrder;
export const selectCalendarTasksScheduledById = (state: IStoreState) => state.task.calendar.calendarTasksScheduledById;
export const selectCalendarTasksAutomatedOrder = (state: IStoreState) => state.task.calendar.calendarTasksAutomatedOrder;
export const selectCalendarTasksAutomatedById = (state: IStoreState) => state.task.calendar.calendarTasksAutomatedById;
export const selectCalendarEventById = (state: IStoreState) => state.task.calendar.calendarEventById;
export const selectCalendarEventOrder = (state: IStoreState) => state.task.calendar.calendarEventOrder;
export const selectCalendarComponentActiveView = (state: IStoreState) => state.task.calendar.calendarComponentActiveView;
export const selectCalendarComponentOnNavigate= (state: IStoreState) => state.task.calendar.calendarComponentOnNavigate;
export const selectParentTaskByLocationModalActiveId = (state: IStoreState) => state.task.calendar.parentTaskByLocationModalActiveId;
export const selectCalendarTasksFetching = (state: IStoreState) => state.task.calendar.calendarTasksFetching;
export const selectCalendarLocationIdsSelected = (state: IStoreState) => state.task.calendar.locationIdsSelected;
export const selectCalendarEventCreating =  (state: IStoreState) => state.task.calendar.calendarEventCreating;

export const selectCalendarEventLoadingById = (state: IStoreState) => state.task.calendar.loadingById;
export const selectCalendarEventUpdatingById = (state: IStoreState) => state.task.calendar.updatingById;
export const selectCalendarEventToRemove = (state: IStoreState) => state.task.calendar.eventToRemove;
export const selectCalendarEventToRemoveFetching = (state: IStoreState) => state.task.calendar.eventToRemoveFetching;
export const selectEventModalActiveId = (state: IStoreState) => state.task.calendar.eventModalActiveId;
export const selectCalendarEventDetail = (state: IStoreState) => state.task.calendar.calendarEventDetail;

export const selectCalendarFilter = (state: IStoreState) => state.task.calendar.calendarFilter;
