import { Location, LocationTypes } from "api/hierarchy/hierarchyModel";
import { TasksStatistics } from "api/task/statistics/statisticsModel";
import { ItemsById } from "model/entity";
import { useMemo } from "react";

// Produces predicate to determine whether location is store or a parent which has store at some lower level
export const storeLocationPredicateFactory = (locationsByParentId: ItemsById<Location[]>) => {
    const storeLocationPredicate = ({ locationType, id }: Location) => {
        return locationType === LocationTypes.Store
            || locationsByParentId[id]?.some(storeLocationPredicate)
    };

    return storeLocationPredicate;
};

// produces memoised predicate
export const useStoreLocationPredicate = (locationsByParentId: ItemsById<Location[]>) => {
    return useMemo(() => {
        return storeLocationPredicateFactory(locationsByParentId)
    }, [locationsByParentId]);
};

export const addPropertiesToSorting = (locationsByParentId: Location[], tasksStatistics:ItemsById<TasksStatistics>) => (
    locationsByParentId.map((loc: Location)=>{
        const taskStaticsByLocation = tasksStatistics[loc.id]
        return {
            ...loc,
            ...taskStaticsByLocation,
            progress: Math.trunc((taskStaticsByLocation?.total === 0 || taskStaticsByLocation?.completed === 0) ? 0 : ((taskStaticsByLocation?.completed || 0)- (taskStaticsByLocation?.completedOverdue ?? 0)) / (taskStaticsByLocation?.total || 0) * 100),
            completation: (taskStaticsByLocation?.completed || 0) / (taskStaticsByLocation?.total || 0) * 100
        }
    })
);
