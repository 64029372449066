// https://github.com/Hassenamri005/chatbot-widget-ui/blob/master/src/components/ChatBotWidget/ChatBotWidget.tsx
// slightly modified version of the module from the original project
import { useTheme } from "@material-ui/core/styles";
import { authApi } from "api/auth/authApi";
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "store/modules/auth/authSelectors";
import { logger } from "utils/logger";
import "./style.css";
import { t } from "i18next";
import { Tooltip } from "@material-ui/core";

interface ChatWidgetIOProps {
  chatbotName?: string;
  isTypingMessage?: string;
  IncommingErrMsg?: string;
  inputMsgPlaceholder?: string;
  chatIcon?: any;
}

export const ChatbotWrapper = () => {
  const currentUser = useSelector(selectUser);
  const demoCompanyId = process.env.REACT_APP_DEMO_COMPANY_ID as string;
  const shouldShowChatbot = currentUser?.companyId === demoCompanyId;
  return (
    shouldShowChatbot ? (
      <ChatBotWidget />
    ) : <></>
  )
}

const ChatBotWidget = ({
  chatbotName = "AI Sidekick",
  isTypingMessage = `${t('Thinking')}...`,
  IncommingErrMsg = `${t('Oops! Something went wrong. Please try again')}.`,
  inputMsgPlaceholder = `${t('Message your AI Sidekick')}...`,
  chatIcon = ChatIcon(),
}: ChatWidgetIOProps) => {
  const chat_api_base_url = process.env.REACT_APP_BASE_AI_URL as string;
  const theme = useTheme();
  const [userMessage, setUserMessage] = useState<any>("");
  const [messages, setMessages] = useState<any>([]);
  const [typing, setTyping] = useState<any>(false);
  const chatInputRef = useRef<any>(null);
  const chatboxRef = useRef<any>(null);

  const handleChat = useCallback(async () => {
    const trimmedMessage = userMessage.trim();
    if (!trimmedMessage) return;
  
    setUserMessage("");
  
    setMessages((prevMessages: any) => {
      const outgoingChat = (
        <li key={`outgoing-${prevMessages.length}`} className="chat outgoing">
          <p style={{ background: theme?.palette.primary.dark }}>{trimmedMessage}</p>
        </li>
      );
      return [...prevMessages, outgoingChat];
    });
    
    try {
      setTyping(true);
  
      const API_URL = `${chat_api_base_url}/chat_message`;
      const token = await authApi.getAccessToken();
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message: {
            content: trimmedMessage,
          },
        }),
      };
  
      const response = await fetch(API_URL, requestOptions);
      const data = await response.json();
      const botResponse = data.bot_response.trim();
  
      simulateTyping(botResponse, (typedMessage) => {
        setMessages((prevMessages: any) => {
          const incomingChat = (
            <li key={`incoming-${prevMessages.length}`} className="chat incoming">
              <span className="material-symbols-outlined">robot_2</span>
              <p>{typedMessage}</p>
            </li>
          );
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage?.props?.className?.includes("incoming")) {
            return [...prevMessages.slice(0, -1), incomingChat];
          }
          return [...prevMessages, incomingChat];
        });
      });
    } catch (error) {
      logger.reportError(error, "Failed to fetch bot response");
      setMessages((prevMessages: any) => {
        const errorChat = (
          <li key={`error-${prevMessages.length}`} className="chat incoming error">
            <p>{IncommingErrMsg}</p>
          </li>
        );
        return [...prevMessages, errorChat];
      });
    } finally {
      setTyping(false);
    }
  }, [userMessage, theme, chat_api_base_url, IncommingErrMsg]);

  const simulateTyping = (message: string, callback: (typedMessage: string) => void) => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < message.length) {
        callback(message.slice(0, index + 1));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 10);
  };

  const handleInputChange = (event: any) => {
    setUserMessage(event.target.value);
    chatInputRef.current.style.height = `${chatInputRef.current.scrollHeight}px`;
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey && window.innerWidth > 800) {
      event.preventDefault();
      handleChat();
    }
  };

  const toggleChatbot = () => {
    document.body.classList.toggle("show-chatbot");
    const elements = document.getElementsByClassName('chatbot-toggler');
    for (const element of elements) {
      if(element.classList.contains('opened')){
        element.classList.remove('opened');
      }else{
        element.classList.add('opened');
      }
    }
  };

  useEffect(() => {
    // Scroll to bottom of chatbox when messages change
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  }, [messages]);

  return (
    <div
      className="chatbot-container"
      style={{
        background: theme?.palette.primary.dark,
        backgroundColor: theme?.palette.primary.dark,
      }}
    >
      <Tooltip title={`${t('Chat')} AI Sidekick`}>
        <button
          className="chatbot-toggler"
          onClick={toggleChatbot}
          style={{ background: theme?.palette.primary.dark }}
        >
          <span className="material-symbols-rounded">{chatIcon}</span>
          <span className="material-symbols-outlined">Close</span>
        </button>
      </Tooltip>
      <div className="chatbot">
        <header style={{ background: theme?.palette.primary.dark }}>
          <h2>{chatbotName}</h2>
        </header>
        <ul className="chatbox" ref={chatboxRef}>
          {messages}
          {typing && (
            <li key={`typing-${messages.length}`} className="chat incoming">
              <p>{isTypingMessage}</p>
            </li>
          )}
        </ul>
        <div className="chat-input">
          <textarea
            ref={chatInputRef}
            placeholder={inputMsgPlaceholder}
            spellCheck="false"
            required
            value={userMessage}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            maxLength={500}
          />
          <button
            id="send-btn"
            className="material-symbols-outlined"
            onClick={handleChat}
            style={{
              color: theme?.palette.primary.dark,
              background: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            send
          </button>
        </div>
      </div>
    </div>
  );
};

const ChatIcon = () => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={18}
        height={18}
        fill="#fff"
        stroke="#fff"
        viewBox="0 0 58 58"
      >
        <path
          d="M53 3.293H5c-2.722 0-5 2.278-5 5v33c0 2.722 2.278 5 5 5h27.681l-4.439-5.161a1 1 0 1 1 1.517-1.304l4.998 5.811L43 54.707v-8.414h10c2.722 0 5-2.278 5-5v-33c0-2.722-2.278-5-5-5z"
          style={{
            fill: "#fff",
          }}
        />
        <circle
          cx={15}
          cy={24.799}
          r={3}
          style={{
            fill: "#fff",
          }}
        />
        <circle
          cx={29}
          cy={24.799}
          r={3}
          style={{
            fill: "#fff",
          }}
        />
        <circle
          cx={43}
          cy={24.799}
          r={3}
          style={{
            fill: "#fff",
          }}
        />
      </svg>
  );
};