import { makeStyles, Theme } from '@material-ui/core';

export const usePickItemStyles = makeStyles((theme: Theme) => ({
    title: {
        fontFamily: 'Roboto',
        fontSize: theme.spacing(1.875),
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: theme.spacing(2.875) + 'px',
        letterSpacing: '0.14px',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center'
    },
    accordionRoot: {
        '&:before': {
            display: 'none',
        },
    },
    accordionRootExpanded: {
        margin: '0 !important',
    },
    accordionSummaryContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
    },
    accordionSummaryContentEpanded: {
        minHeight: '48px !important',
        margin: '0 !important',
    },
    accordionDetailsRoot: {
        padding: 0,
    },
    itemsWrapper: {
        boxSizing: 'border-box',
        width: '100%',
        paddingLeft: theme.spacing(2),
    },
    chevronButton: {
        padding: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: theme.spacing(3),
        height: theme.spacing(3),
        transform: 'rotate(90deg)',
        transition: 'transform 0.3s',

        '&.opened': {
            transform: 'rotate(0deg)',
        },

        '&.hidden': {
            visibility: 'hidden',
        },
    },
    actionBtnDelete: {
        position: 'absolute',
        right: theme.spacing(2)
    }
}));
