import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export function PageLoader() {
    return (
        <Box display="flex" height="100%"
            alignItems="center" justifyContent="center"
        >
            <CircularProgress/>
        </Box>
    );
}
