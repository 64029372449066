import React from 'react';
import { Trans } from 'react-i18next';

export type SingleLocationBodyProps = {
    location?: string;
}

export function SingleLocationBody({ location }: SingleLocationBodyProps) {
    return (
        <label>
            {location ?? <Trans>Current store</Trans>}
        </label>
    );
}
