import React from 'react';
import { CheckCircle, IndeterminateCheckBox } from '@material-ui/icons';
import {
    useMediaQuery,
    darken,
    styled,
    Box,
    Fab,
    Typography,
    Zoom,
    Theme,
    ButtonProps,
} from '@material-ui/core';
import { BlueColor, Breakpoints, UtilColors } from 'utils/styles/constants';

const StyledFab = styled(Fab)(({ theme }) => ({
    background: BlueColor.BluePrimaryMain,
    color: UtilColors.White,
    textDecoration: 'none',

    '&:hover': {
        background: darken(BlueColor.BluePrimaryMain, 0.05),
    },

    '& svg + *': {
        marginLeft: theme.spacing(1.5),
    },
}));

const StyledFabContainer = styled(Box)({
    bottom: `calc(env(safe-area-inset-bottom, 0px) + 30px)`, // ios-navbar fix
    right: 50,
});

export type ActivateDeactivateButtonProps = {
    className?: string;
    onClick?: ButtonProps['onClick'];
    buttonVariant: ActivateDeactivateVariant;
    count?: number;
};

export enum ActivateDeactivateVariant {
    Activate,
    Deactivate,
}

export const ActivateDeactivateButton: React.FC<ActivateDeactivateButtonProps> = (
    {
        children,
        onClick,
        className ,
        buttonVariant,
        count,
    }) => {
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );

    return (
        <StyledFabContainer className={className}>
            <Zoom in timeout={500}>
                <StyledFab
                    variant="extended"
                    onClick={onClick}
                >
                    {buttonVariant === ActivateDeactivateVariant.Activate && (
                        <>
                            <CheckCircle/>
                            {isMobile && (
                                <Typography>
                                    {count}
                                </Typography>
                            )}
                        </>
                    )}
                    {buttonVariant === ActivateDeactivateVariant.Deactivate && (
                        <>
                            <IndeterminateCheckBox/>
                            {isMobile && (
                                <Typography>
                                    {count}
                                </Typography>
                            )}
                        </>
                    )}
                    {!isMobile && (
                        <Typography>
                            {children}
                        </Typography>
                    )}
                </StyledFab>
            </Zoom>
        </StyledFabContainer>
    );
}
