export class LeafNode<T> {
    public value: T;
    public children: Array<LeafNode<T>>;
    public parent: LeafNode<T> | null;

    constructor(
        value: T,
        parent: LeafNode<T> | null,
        children: Array<LeafNode<T>> = [],
    ) {
        this.value = value;
        this.parent = parent;
        this.children = children;
    }

    removeChild(childToRemove: LeafNode<T>) {
        this.children = this.children.filter(child => child !== childToRemove);
    }
}

export function getTreeRoot<T>(leaveNode: LeafNode<T>): LeafNode<T> {
    let currentNode = leaveNode;
    while(currentNode.parent !== null) {
        currentNode = currentNode.parent;
    }
    return currentNode;
}

export function findNode<T>(node: LeafNode<T>, predicate: (v: T) => boolean): LeafNode<T> | null {
    if (!node) {
        return null;
    }
    const { value, children } = node;

    if (predicate(value)) {
        return node;
    }
    if (!children?.length) {
        return null;
    }
    for(let i = 0; i < children.length; i++) {
        const child = children[i];
        const found: LeafNode<T> | null = findNode(child, predicate);
        if (found) {
            return found;
        }
    }
    return null;
}

export function getAllChildren<T>(node: LeafNode<T>): Array<LeafNode<T>> {
    if (!node || !node.children || !node.children.length) {
        return [];
    }
    return [
        ...node.children,
        ...node.children.flatMap(item => getAllChildren(item)),
    ];
}

export function getTopChildren<T>(
    node: LeafNode<T>,
    predicate: (v: T) => boolean,
): Array<LeafNode<T>> {
    if (node) {
        if (predicate(node.value)) {
            return [node];
        }
        if (node.children && node.children.length) {
            return node.children.flatMap(child => getTopChildren(child, predicate));
        }
    }
    return [];
}

function compareDefault<T>(a: T, b: T) {
    return a === b;
}

export function fromTopChildren<T>(
    node: LeafNode<T>,
    topChildren: Array<T>,
    compare: ((a: T, b: T) => boolean) = compareDefault,
): Array<LeafNode<T>> {
    const root = getTreeRoot(node);
    const makePredicate = (a: T) => (b: T) => compare(a, b);
    const nodes = (
        topChildren
            .map(item => findNode(root, makePredicate(item)))
            .filter(item => item !== null)
    ) as Array<LeafNode<T>>;
    return [
        ...nodes,
        ...nodes.flatMap(item => item.children),
    ];
}

export function getAllParents<T>(node: LeafNode<T>): Array<LeafNode<T>> {
    const parents: Array<LeafNode<T>> = [];
    let currentNode: LeafNode<T> | null = node.parent;
    while(currentNode !== null) {
        parents.push(currentNode);
        currentNode = currentNode.parent;
    }
    return parents;
}

interface RemovalResult<T> {
    deletedNode: LeafNode<T> | null;
    deletedParentValue: T | null;
}

export function removeLeaf<T>(tree: LeafNode<T> | null, leafId: T): RemovalResult<T> {
    const result: RemovalResult<T> = {
        deletedNode: null,
        deletedParentValue: null,
    };
    
    function removeNode(node: LeafNode<T> | null, id: T): boolean {
        if (!node) {
            return false;
        }
    
        const isMatch = node.value === id;
        if (isMatch) {
            const parent = node.parent;
            if (parent) {
                parent.removeChild(node);
                result.deletedNode = node;
                result.deletedParentValue = parent.value;
        
                // The deleted leaf was the last one and the parent has only one child, delete also all the children of the parent.
                if (parent.children.length === 0 && parent !== tree) {
                removeNode(tree, parent.value);
                }
            } else {
                // No parent, means we are eliminating the root
                tree = null;
            }
        } else {
            for (const child of node.children) {
                if (removeNode(child, id)) {
                return true;
                }
            }
        }
    
        return isMatch;
    }
    
    removeNode(tree, leafId);
    return result;
}