import React from 'react';
import { CircularProgress, IconButton, ListItem, makeStyles, Theme } from '@material-ui/core';
import { StoreTaskAttachmentModel } from 'api/task/storeTask/storeTaskModel';
import { Attachment, Delete } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { BlueColor } from 'utils/styles/constants';

const useAttachmentPlainItemStyles = makeStyles((theme: Theme) => ({
    icon: {
        color: theme.palette.primary.main,
    },
    link: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '&, &:visited': {
            color: BlueColor.Dark2,
            margin: theme.spacing(0, 1),
            textDecoration: 'none',
        },
    },
}));

interface IAttachmentPlainItemProps {
    onRemove?: (attachment: StoreTaskAttachmentModel) => void;
    loading?: boolean;
    className?: string;
    attachment: StoreTaskAttachmentModel;
    disabled?: boolean;
}

export function AttachmentPlainItem({
    attachment,
    loading,
    onRemove,
    className,
    disabled,
}: IAttachmentPlainItemProps) {
    const classes = useAttachmentPlainItemStyles([]);
    const filesConfig = useSelector(getFilesConfig);
    const url = `${filesConfig?.fileStorageBaseUrl}/${attachment.fileId}`;

    return (
        <ListItem className={className}>
            {loading ? (
                <CircularProgress size={24} className={classes.icon}/>
            ) : (
                <Attachment className={classes.icon}/>
            )}
            <a
                className={classes.link}
                href={loading ? '#' : url}
                title={attachment.name}
            >
                {attachment.name}
            </a>
            {!loading && onRemove && (
                <IconButton
                    onClick={disabled ? undefined : onRemove.bind(null, attachment)}
                    disabled={disabled}
                    disableRipple={disabled}
                    disableFocusRipple={disabled}
                    disableTouchRipple={disabled}
                >
                    <Delete/>
                </IconButton>
            )}
        </ListItem>
    );
}
