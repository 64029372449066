import { IItemComponent } from '../../../../../../../components/basicInputs/selectInput/model';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { UserRoleDTOExtended } from '../../../../../../../api/core/model';

export function RoleInviteItem({ item: role }: IItemComponent<UserRoleDTOExtended>) {
    return (
        <Box display="flex" alignItems="center">
            <Typography variant="caption">
                {role.description}
            </Typography>
        </Box>
    );
}
