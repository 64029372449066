import { useSelector } from 'react-redux';
import { selectUserColorFetching } from 'store/modules/company/companySelector';
import { EmptyLaunchCover } from './EmptyLaunchCover';
import { SkeletonLaunchCover } from './SkeletonLaunchCover';
import { LaunchImg } from './LaunchImg';
import { selectLaunchPageCoverImage } from 'store/modules/launch/launchSelectors';

export const LaunchCoverImage = () => {
  const colorFetching = useSelector(selectUserColorFetching);
  const launchPageCoverImage = useSelector(selectLaunchPageCoverImage);

  if (colorFetching) {
    return <SkeletonLaunchCover />
  }

  return (
    <>
      {launchPageCoverImage ?
        ( <LaunchImg launchPageCover={launchPageCoverImage}/>)
        : (<EmptyLaunchCover />)
      }
    </>
  )
}