import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { IResponseErrorPayload } from 'model/error';

const REQUEST_WATCHERS_GET = 'request/watchers/GET';
const RESPONSE_WATCHERS_GET_SUCCESS = 'response/watchers/GET_SUCCESS';
const RESPONSE_WATCHERS_GET_ERROR = 'response/watchers/GET_ERROR';

export const requestWatchers = createApiActions<{locationsId: Array<string> | null, userId: string}, Array<string>, IResponseErrorPayload, typeof REQUEST_WATCHERS_GET,
    typeof RESPONSE_WATCHERS_GET_SUCCESS, typeof RESPONSE_WATCHERS_GET_ERROR
>(
    REQUEST_WATCHERS_GET, RESPONSE_WATCHERS_GET_SUCCESS, RESPONSE_WATCHERS_GET_ERROR,
);

export type WatcherActions = (
    | ActionsReturnTypes<typeof requestWatchers>
);
