import { StyledLayoutBodyColumn } from '../WelcomePageLayout';
import { Box, Typography } from '@material-ui/core';
import calendar from 'images/calendar.png';
import { CalendarTasks } from 'modules/task/taskList/views/CalendarTasksView/calendar/CalendarTasks';
import { useCalendarTasksData } from 'modules/task/taskList/views/CalendarTasksView/calendar/useCalendarTasksData';
import { Views } from 'react-big-calendar';
import { Trans } from 'react-i18next';
import { StoreTaskView } from 'modules/task/singleTask/storeTaskView/StoreTaskView';
import { TaskByLocationsSideOut } from 'modules/task/taskList/views/CalendarTasksView/TaskByLocationsSideOut';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { EventSideOut } from 'modules/task/taskCalendar/eventSideOut/EventSideOut';

export const CalendarHome = () => {
    const data = useCalendarTasksData();
    const isLaunchPageShowCalendarEnabled = useFeatureState(FeatureFlags.LaunchPageShowCalendar);

    if(!isLaunchPageShowCalendarEnabled) return <></>;

    return(
        <StyledLayoutBodyColumn>
          <Box display="flex" gridGap={8} alignItems="center">
            <img src={calendar} width={32} height={32} alt="notification-header"/>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}><Trans>Calendar</Trans></Typography>
          </Box>
          <Box style={{ overflow: 'auto' }} mt={2} className="home-page-calendar">
              <CalendarTasks
                  data={data}
                  height={500}
                  viewsCustom={[Views.WEEK]}
                  activeViewCustom={Views.WEEK}
              />
          </Box>
          <StoreTaskView />
          <TaskByLocationsSideOut />
          <EventSideOut />
        </StyledLayoutBodyColumn>
    )
}
