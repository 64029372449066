import React from 'react';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import { useTaskHeaderDetailsStyles } from 'modules/task/taskList/components/TaskHeaderDetails/useTaskHeaderDetailsStyles';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { TaskHeaderUserDetails } from './TaskHeaderUserDetails';
import { Divider } from './Divider';
import { formatDateLocalized, shortDateFormat } from 'utils/helpers/dateHelper';
import { Trans } from 'react-i18next';
import { DisplayStatus } from './DisplayStatus';

interface ITaskHeaderDetailsProps {
    taskStatus?: DisplayedTaskStatus;
    createdBy?: string;
    dueDate?: string;
}

export function TaskHeaderDetails({
    taskStatus,
    createdBy,
    dueDate,
}: ITaskHeaderDetailsProps) {
    const classes = useTaskHeaderDetailsStyles([]);
    const detailsClasses: Array<string> = [classes.dateDetails];

    return (
        <div className={classes.detailsWrapper}>
            { taskStatus && <DisplayStatus taskStatus={taskStatus} /> }
            <div className={clsx(detailsClasses)}>
                <span><Trans>Due</Trans></span>
                {dueDate ? (
                    <b>{formatDateLocalized(dueDate, shortDateFormat)}</b>
                ) : (
                    <Skeleton width="90px" />
                )}
            </div>
            <Divider orientation="vertical" />
            <TaskHeaderUserDetails label={<Trans>Creator</Trans>} userId={createdBy || ''} />
        </div>
    );
}
