import React from 'react';
import { validate as validateUUID } from 'uuid';
import { Redirect, useParams } from 'react-router';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { PageLoader } from 'components/router/PageLoader';
import { Routes } from 'components/router/model';
import { TaskByLocationsView } from './views/TaskByLocationsView';
import { StoreTasksView } from './views/StoreTasksView';
import { GlobalTasksView } from './views/ParentTasksView';
import { ThemeProvider } from '@material-ui/core';
import { newTextColorTheme } from 'modules/root/theme';
import { TasksFilterProvider } from './context/taskFilterContext';

export const TaskListPage = () => {
    const currentUser = useCurrentUser();
    const isStore = currentUser?.isCompanyLocationStore;
    const { uuid } = useParams<{ uuid?: string }>();

    if (!currentUser) {
        return (
            <PageLoader />
        );
    }

    return (
        <ThemeProvider theme={newTextColorTheme}>
            {uuid ? (
                <>
                    {(!validateUUID(uuid) || isStore === true) ? (
                        <Redirect to={Routes.NotFound} />
                    ) : (
                        <TaskByLocationsView parentTaskId={uuid} />
                    )}
                </>
            ) : (
                <TasksFilterProvider isStore={isStore}>
                    {isStore === true ? (
                        <StoreTasksView locationId={currentUser.companyLocationId ?? ''} />
                    ) : (
                        <GlobalTasksView />
                    )}
                </TasksFilterProvider>
            )}
        </ThemeProvider>
    );
};
