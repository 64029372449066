import React, { useLayoutEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { OptionsDropdown } from 'modules/shared/components/OptionsDropdown';
import { useConversationStyles } from './common/conversationStyles';
import { allTasksSyntheticValue } from '../taskList/components/model';
import { useActions } from 'utils/store/useActions';
import { storeFilterConversation } from 'store/modules/task/taskConversation/taskConversationActions';
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors';
import { selectTaskPrototypesById } from 'store/modules/task/prototypes/prototypesSelectors';
import { selectTaskConversationStore } from 'store/modules/task/taskConversation/taskConversationSelectors';

export const StoreFilterConversation = () => {
  const { prototypeId } = useParams<{ prototypeId: string }>();
  const taskPrototype = useSelector(selectTaskPrototypesById)[prototypeId];
  const actions = useActions({ updateStoreFilter: storeFilterConversation});
  const locationsById = useSelector(selectLocationsByIds);
  const options = useMemo(()=> (
      taskPrototype?.locationIds.map(location=>(
        {value:locationsById[location]?.id as string, title: locationsById[location]?.name as string}
      )) ?? []
    ), [locationsById, taskPrototype]);
  const storeFilterValue = useSelector(selectTaskConversationStore);
  const classes = useConversationStyles();
  const handleSetFilterStatus = (newValue?: string)=> {
    if(newValue){
      actions.current.updateStoreFilter(newValue)
    }
  };

  useLayoutEffect(()=>{
    if (options?.length && options[0].value) {
      actions.current.updateStoreFilter(options[0].value)
    }
  },[options, actions]);

  if (!taskPrototype && !options?.length) {
    return <></>;
  }


  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Trans>Store:</Trans>
      <OptionsDropdown
          className={classes.storeFilter}
          selectClasses={{ select: classes.select }}
          unsetValue={allTasksSyntheticValue}
          options={options}
          value={storeFilterValue}
          onValueChange={handleSetFilterStatus}
      />
    </Box>
  )
}
