import { Button, Fade, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import TimerIcon from '@material-ui/icons/Timer';
import { Routes } from 'components/router/model';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { AppName } from 'store/modules/apps/appsModel';
import { selectAppsListByName } from 'store/modules/apps/appsSelector';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { t } from 'i18next';
import { useStylesViewSwtich } from './useStylesViewSwitch';
import { NavLink } from 'react-router-dom';

export const ScheduledTasksButton = ({ ...res}) => {
    const classes = useStylesViewSwtich();
    const scheduledTasksEnabled = useFeatureState(FeatureFlags.ScheduledTasks);
    const createEditTask = useSelector(selectAppsListByName)[AppName.TasksCreateDelete];
    const btnClass = clsx(res.className,classes.button,{
        [classes.active]: useRouteMatch(Routes.TaskListScheduled),
    });

    if (!scheduledTasksEnabled || !createEditTask) {
        return <></>;
    }

    const restProps = {...res, className: btnClass};

    return (
        <Button
            component={NavLink}
            to={Routes.TaskListScheduled}
            variant="text"
            {...restProps}
        >
            <Tooltip title={`${t('Scheduled')}`} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <TimerIcon />
            </Tooltip>
        </Button>
    );
};
