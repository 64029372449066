import { LaunchLayoutItem } from './LaunchLayoutItem';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

export const SkeletonItem = ({ items = 1 }: { items?: number }) => {
    const itemsList = new Array(items).fill('');
    return (
        <Box mt={2} display="flex" flexDirection="row" gridGap={8}>
            {itemsList.map((_) => (
                <LaunchLayoutItem key={uuidv4()}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Skeleton
                            animation="wave"
                            height={80}
                            width={80}
                            variant="rect"
                            style={{
                                borderRadius: '25%'
                            }}
                        />
                    </Box>
                </LaunchLayoutItem>
            ))}
        </Box>
    )
}