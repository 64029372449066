import { IdType, Row, SortByFn } from 'react-table';
import { ChildLocationTask, ParentTaskChildModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';

export const ROW_A_LARGER = 1;
export const ROW_B_LARGER = -1;

const statusSortMap: Record<DisplayedTaskStatus, number> = {
    [DisplayedTaskStatus.Submitted]: 0,
    [DisplayedTaskStatus.SubmittedOverdue]: 1,
    [DisplayedTaskStatus.Declined]: 2,
    [DisplayedTaskStatus.Overdue]: 3,
    [DisplayedTaskStatus.Default]: 4,
    [DisplayedTaskStatus.Completed]: 5,
    [DisplayedTaskStatus.CompletedOverdue]: 6,
    [DisplayedTaskStatus.Expired]: 7
};

export type ComparisonResult = typeof ROW_A_LARGER | typeof ROW_B_LARGER;

function toCheckObject(row: Row<ParentTaskChildModel>) {
    return {
        isSkeleton: Reflect.get(row.original, 'isSkeleton') || false,
        isStore: Reflect.has(row.original, 'status'),
    };
}

function countPercentage(partial = 0, overall = 0) {
    const percentage = partial / overall * 100;
    return Number.isFinite(percentage) ? percentage : 0;
}

function toCompletionPercentage(row: Row<ParentTaskChildModel>) {
    const {
        completed: completedTasksCount = 0,
        storeTasks: overallTasksCount = 0,
    } = row.original as ChildLocationTask;
    return countPercentage(completedTasksCount, overallTasksCount);
}

function toOverduePercentage(row: Row<ParentTaskChildModel>) {
    const {
        overdue: overdueTasksCount = 0,
        storeTasks: overallTasksCount = 0,
    } = row.original as ChildLocationTask;
    return countPercentage(overdueTasksCount, overallTasksCount);
}

function toStatusNumber(row: Row<ParentTaskChildModel>) {
    return statusSortMap[row.original.displayedStatus] || 0;
}

function toOverallTaskCount(row: Row<ParentTaskChildModel>): number {
    const { storeTasks: overallTasksCount = 0 } = row.original as ChildLocationTask;
    return overallTasksCount;
}

function resolveResult(result: ComparisonResult, invert: boolean): ComparisonResult {
    return invert ? (result * -1) as ComparisonResult : result;
}

export const sortByStatus: SortByFn<ParentTaskChildModel> = (
    rowA: Row<ParentTaskChildModel>,
    rowB: Row<ParentTaskChildModel>,
    columnId: IdType<ParentTaskChildModel>,
    desc?: boolean | undefined,
): ComparisonResult => {
    if (rowA.depth !== rowB.depth) return resolveResult(rowA.depth < rowB.depth ? ROW_A_LARGER : ROW_B_LARGER, !!desc);
    const rows = [rowA, rowB];
    const [checkA, checkB] = rows.map(toCheckObject);

    if (checkA.isSkeleton && checkB.isSkeleton) return ROW_A_LARGER;
    if (checkA.isSkeleton !== checkB.isSkeleton) return checkA.isSkeleton ? ROW_B_LARGER : ROW_A_LARGER;
    if (checkA.isStore !== checkB.isStore) return resolveResult(checkA.isStore ? ROW_B_LARGER : ROW_A_LARGER, !!desc);

    if (checkA.isStore) {
        const [statusNumA, statusNumB] = rows.map(toStatusNumber);
        return statusNumA < statusNumB ? ROW_B_LARGER : ROW_A_LARGER;
    } else {
        const [overduePercentageA, overduePercentageB] = rows.map(toOverduePercentage);
        if (overduePercentageA === overduePercentageB) {
            const [completionPercentageA, completionPercentageB] = rows.map(toCompletionPercentage);
            if (completionPercentageA === completionPercentageB) {
                const [tasksA, tasksB] = rows.map(toOverallTaskCount);
                return tasksA < tasksB ? ROW_B_LARGER : ROW_A_LARGER;
            }
            return completionPercentageA < completionPercentageB ? ROW_B_LARGER : ROW_A_LARGER;
        }
        return overduePercentageA < overduePercentageB ? ROW_A_LARGER : ROW_B_LARGER;
    }
};
