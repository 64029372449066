import { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ITaskTemplate } from './model';
import { Close, Delete, Done, Edit } from '@material-ui/icons';
import { Box, IconButton } from '@material-ui/core';
import { useActions } from 'utils/store/useActions';
import { requestDeleteTaskTemplate, requestGetTaskTemplate, resetGetTaskTemplate } from 'api/task/taskTemplate/taskTemplateActions';
import { TooltipTitle } from './TooltipTitle';

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        padding: theme.spacing(0, 1) 
    },
    buttonAction: {
        padding: theme.spacing(.5)
    },
}));

type TaskTemplateItemProp = ITaskTemplate & {
    onDeleteTaskTemplate: (id: string) => void;
    isDeleting?: boolean;
    isDisabled?: boolean;
};

export const TaskTemplateItem = ({id, title, isDeleting = false, isDisabled = false, onDeleteTaskTemplate}: TaskTemplateItemProp) => {
    const classes = useStyles();
    const actionsTemplate = useActions({
        deleteTemplate: requestDeleteTaskTemplate.init,
        getTemplate: requestGetTaskTemplate.init,
        resetTemplate: resetGetTaskTemplate
    });

    const deleteTaskTemplate = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        actionsTemplate.current.deleteTemplate({id})
    }, [actionsTemplate, id])

    const handleDeleteTaskTemplate = useCallback((e: React.MouseEvent<HTMLButtonElement>)=>{
        e.stopPropagation();
        onDeleteTaskTemplate(id)
    }, [onDeleteTaskTemplate, id])

    const handleEditTaskTemplate = useCallback((e: React.MouseEvent<HTMLButtonElement> )=>{
        e.stopPropagation();
        actionsTemplate.current.resetTemplate();
        actionsTemplate.current.getTemplate({id, isEditTemplate: true});
    }, [id, actionsTemplate])
    
    if(isDeleting) {
        return(
            <Box display="flex" flexDirection="row" alignItems="center" maxWidth={274} width="100%" justifyContent="space-between">
                <Box>
                    <TooltipTitle title={title} />
                </Box>
                <Box display="flex" flexDirection="column">
                    <IconButton aria-label="Save" className={classes.buttonAction} onClick={deleteTaskTemplate}>
                        <Done fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Close" className={classes.buttonAction} onClick={handleDeleteTaskTemplate}>
                        <Close fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
        )
    }

    return (
        <Box display="flex" width="100%" maxWidth={274}>
            <Box display="flex" flexDirection="row" width={"85%"} alignItems="center">
                <TooltipTitle title={title}/>
            </Box>
            <Box display="flex" flexDirection="column" width={"7.5%"}>
                <IconButton aria-label="Edit" className={classes.buttonAction} onClick={handleEditTaskTemplate} disabled={isDisabled}>
                    <Edit fontSize="small" />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" width={"7.5%"}>
                <IconButton aria-label="Edit" className={classes.buttonAction} onClick={handleDeleteTaskTemplate} disabled={isDisabled}>
                    <Delete fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
}
