import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import {
    IChecklistItemChangeStatus,
    IChecklistItemUploadFiles,
    IChecklistItemFileOperation,
    StoreTaskFullModel,
    IChecklistItemChangeStatusSuccess,
    StoreTaskAttachmentModel,
    IChecklistItemUploadFilesError,
    IChecklistItemUploadFilesSuccess,
    IStoreTaskApprovableResult,
    IStoreTaskDeclineRequest,
    IStoreTaskParentIdsResult,
    IChecklistItemCustomResponse,
    IChecklistItemCustomSuccess,
} from 'api/task/storeTask/storeTaskModel';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { IDescribedEntityError, IEntityError } from 'model/entity';

const STORE_TASK_GET = 'store_task/GET';
export const requestStoreTaskGet = createApiActionsByName<string, StoreTaskFullModel, any, typeof STORE_TASK_GET>(
    STORE_TASK_GET,
);

const STORE_AUTOMATION_TASK_GET = 'store_automation_task/GET';
export const requestStoreAutomationTaskGet = createApiActionsByName<string, StoreTaskFullModel, any, typeof STORE_AUTOMATION_TASK_GET>(
    STORE_AUTOMATION_TASK_GET,
);

const STORE_TASK_GET_PARENT_IDS = 'store_task/GET_PARENT_IDS';
export const requestStoreTaskGetParentIds = createApiActionsByName<
string, IStoreTaskParentIdsResult, IEntityError, typeof STORE_TASK_GET_PARENT_IDS
>(
    STORE_TASK_GET_PARENT_IDS,
);

const CHECKLIST_ITEM_SET_COMPLETED = 'checklist_item/SET_COMPLETED';
export const requestChecklistItemSetCompleted = createApiActionsByName<IChecklistItemChangeStatus,
IChecklistItemChangeStatusSuccess, IDescribedEntityError & IChecklistItemChangeStatus,
    typeof CHECKLIST_ITEM_SET_COMPLETED
>(
    CHECKLIST_ITEM_SET_COMPLETED,
);

const CHECKLIST_ITEM_CUSTOM_RESPONSE_UPDATE = 'checklist_item/CUSTOM_RESPONSE';
export const requestChecklistItemCustomResponseUpdate = createApiActionsByName<IChecklistItemCustomResponse,
IChecklistItemCustomSuccess,
IDescribedEntityError & IChecklistItemFileOperation,
    typeof CHECKLIST_ITEM_CUSTOM_RESPONSE_UPDATE
>(
    CHECKLIST_ITEM_CUSTOM_RESPONSE_UPDATE,
);

const CHECKLIST_ITEM_CUSTOM_RESPONSE_DELETE = 'checklist_item/CUSTOM_RESPONSE_DELETE';
export const requestChecklistItemCustomResponseDelete = createApiActionsByName<IChecklistItemCustomResponse,
IChecklistItemFileOperation,
IDescribedEntityError & IChecklistItemFileOperation,
    typeof CHECKLIST_ITEM_CUSTOM_RESPONSE_DELETE
>(
    CHECKLIST_ITEM_CUSTOM_RESPONSE_DELETE,
);

const CHECKLIST_ITEM_FILE_UPLOAD = 'checklist_item/FILE_UPLOAD';
export const requestChecklistItemFileUpload = createApiActionsByName<IChecklistItemUploadFiles,
IChecklistItemUploadFilesSuccess,
IDescribedEntityError & IChecklistItemUploadFilesError,
    typeof CHECKLIST_ITEM_FILE_UPLOAD
>(
    CHECKLIST_ITEM_FILE_UPLOAD,
);

const CHECKLIST_ITEM_FILE_REMOVE = 'checklist_item/FILE_REMOVE';
export const requestChecklistItemFileRemove = createApiActionsByName<
IChecklistItemFileOperation & StoreTaskAttachmentModel, StoreTaskFullModel,
IDescribedEntityError & IChecklistItemFileOperation & StoreTaskAttachmentModel,
    typeof CHECKLIST_ITEM_FILE_REMOVE
>(
    CHECKLIST_ITEM_FILE_REMOVE,
);

export const CHECKLIST_ITEM_FILE_NOT_FOUND = 'checklist_item/FILE_REMOVE_NOT_FOUND';
export const checklistItemFilteRemoveNotFound = (
    createSingleAction<IChecklistItemFileOperation & StoreTaskAttachmentModel, typeof CHECKLIST_ITEM_FILE_NOT_FOUND>(
        CHECKLIST_ITEM_FILE_NOT_FOUND,
    )
);

const REQUEST_STORE_TASK_SUBMIT = 'request/task/store/SUBMIT';
type StoreTaskSubmitConstType = typeof REQUEST_STORE_TASK_SUBMIT;
export const requestSubmitStoreTask = (
    createApiActionsByName<string, StoreTaskFullModel, IEntityError, StoreTaskSubmitConstType>(
        REQUEST_STORE_TASK_SUBMIT,
    )
);

const REQUEST_STORE_TASK_APPROVE = 'request/task/store/APPROVE';
type StoreTaskApproveConstType = typeof REQUEST_STORE_TASK_APPROVE;
export const requestApproveStoreTask = (
    createApiActionsByName<string, StoreTaskFullModel, IEntityError, StoreTaskApproveConstType>(
        REQUEST_STORE_TASK_APPROVE,
    )
);

const REQUEST_STORE_TASK_DECLINE = 'request/task/store/DECLINE';
type StoreTaskDeclineConstType = typeof REQUEST_STORE_TASK_DECLINE;
export const requestDeclineStoreTask = (
    createApiActionsByName<IStoreTaskDeclineRequest, StoreTaskFullModel, IEntityError, StoreTaskDeclineConstType>(
        REQUEST_STORE_TASK_DECLINE,
    )
);

const REQUEST_CAN_USER_APPROVE_STORE_TASK = 'request/task/store/IS_APPROVABLE_BY_USER';
type CanApproveConstType = typeof REQUEST_CAN_USER_APPROVE_STORE_TASK;
export const requestIsApprovableByUser = (
    createApiActionsByName<string, IStoreTaskApprovableResult, IEntityError, CanApproveConstType>(
        REQUEST_CAN_USER_APPROVE_STORE_TASK,
    )
);

export const RESPONSE_TASK_IN_ALREADY_DESIRED_STATE = 'response/task/store/IN_ALREADY_DESIRED_STATE';
export const taskInAlreadyDesiredState = createSingleAction<{
    taskId: string;
    state: string;
}, typeof RESPONSE_TASK_IN_ALREADY_DESIRED_STATE>(
    RESPONSE_TASK_IN_ALREADY_DESIRED_STATE,
);

export const RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE = (
    'response/task/store/CHECKLIST_ITEM/IN_ALREADY_DESIRED_STATE'
);
export const taskChecklistItemInAlreadyDesiredState = createSingleAction<IChecklistItemChangeStatus,
    typeof RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE>(
    RESPONSE_TASK_CHECKLIST_ITEM_IN_ALREADY_DESIRED_STATE,
);

export type StoreTaskApiActions = (
    ActionsReturnTypes<typeof requestStoreTaskGet>
    | ActionsReturnTypes<typeof requestStoreTaskGetParentIds>
    | ActionsReturnTypes<typeof requestChecklistItemSetCompleted>
    | ReturnType<typeof taskChecklistItemInAlreadyDesiredState>
    | ActionsReturnTypes<typeof requestChecklistItemFileUpload>
    | ActionsReturnTypes<typeof requestChecklistItemFileRemove>
    | ReturnType<typeof checklistItemFilteRemoveNotFound>
    | ActionsReturnTypes<typeof requestSubmitStoreTask>
    | ActionsReturnTypes<typeof requestApproveStoreTask>
    | ActionsReturnTypes<typeof requestDeclineStoreTask>
    | ActionsReturnTypes<typeof requestIsApprovableByUser>
    | ActionsReturnTypes<typeof requestChecklistItemCustomResponseUpdate>
    | ActionsReturnTypes<typeof requestChecklistItemCustomResponseDelete>
    | ActionsReturnTypes<typeof requestStoreAutomationTaskGet>
);
