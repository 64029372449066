export enum StreamChatEvents {
    // #region Channel Events
    ChannelDeleted = 'channel.deleted',
    ChannelHidden = 'channel.hidden',
    ChannelTruncated = 'channel.truncated',
    ChannelUpdated = 'channel.updated',
    ChannelVisible = 'channel.visible',
    // #endregion Channel Events

    // #region Message Events
    MessageNew = 'message.new',
    MessageDeleted = 'message.deleted',
    MessageRead = 'message.read',
    // #endregion Message Events

    // #region Notification Events
    NotificationAddedToChannel = 'notification.added_to_channel',
    NotificationMessageNew = 'notification.message_new',
    NotificationChannelDeleted = 'notification.channel_deleted',
    NotificationRemovedFromChannel = 'notification.removed_from_channel',
    NotificationMarkRead = 'notification.mark_read',
    NotificationInvited = 'notification.invited',
    NotificationInviteAccepted = 'notification.invite_accepted',
    // #endregion Notification Events

    // #region Other Events
    ConnectionRecovered = 'connection.recovered',
    UserPrecenseChanged = 'user.presence.changed',
    // #endregion Other Events
}
