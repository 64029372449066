import { createSingleAction } from 'utils/store/actionUtils';
import { ParentTaksReview } from './taskReviewReducer';

export const TASK_REVIEW_MODAL_OPEN = 'task/review/MODAL_OPEN';
export const TASK_REVIEW_MODAL_CLOSE = 'task/review/MODAL_CLOSE';

export const taskReviewModalOpen = createSingleAction<void, typeof TASK_REVIEW_MODAL_OPEN>(
    TASK_REVIEW_MODAL_OPEN,
);

export const taskReviewModalClose = createSingleAction<void, typeof TASK_REVIEW_MODAL_CLOSE>(
    TASK_REVIEW_MODAL_CLOSE,
);

export const TASK_REVIEW_MODAL_BY_PARENT_TASK = 'task/review/MODAL_BY_PARENT_TASK';
export const TASK_REVIEW_MODAL_BY_PARENT_TASK_RESET = 'task/review/MODAL_BY_PARENT_TASK_RESET';

export const parentTaskReviewModal = createSingleAction<ParentTaksReview, typeof TASK_REVIEW_MODAL_BY_PARENT_TASK>(
    TASK_REVIEW_MODAL_BY_PARENT_TASK,
);

export const parentTaskReviewModalReset = createSingleAction<void, typeof TASK_REVIEW_MODAL_BY_PARENT_TASK_RESET>(
    TASK_REVIEW_MODAL_BY_PARENT_TASK_RESET,
);

export type TaskReviewAction =
    | ReturnType<typeof taskReviewModalOpen>
    | ReturnType<typeof taskReviewModalClose>
    | ReturnType<typeof parentTaskReviewModal>
    | ReturnType<typeof parentTaskReviewModalReset>;
