import { FC } from "react";
import { ScheduledTasksButton } from "./ScheduledTasksButton";
import { LocationButton } from "./LocationButton";
import { MyTasksButton } from "./MyTasksButton";
import { TasksButton } from "./TasksButton";
import { CalendarTasksButton } from "./CalendarTasksButton";

const ParentTasksBtnSwitch =({ ...res}) => <>
    <CalendarTasksButton  {...res}/>
    <TasksButton {...res}/>
    <LocationButton {...res} />
    <MyTasksButton {...res} />
    <ScheduledTasksButton {...res} />
</>

const StoreTasksBtnSwitch = ({ ...res}) => <>
    <CalendarTasksButton  {...res}/>
    <TasksButton {...res} />
    <MyTasksButton  {...res}/>
    <ScheduledTasksButton {...res} />
</>;

const NoAccessTaskPageBtnSwitch = ({ ...res}) => <>
    <CalendarTasksButton  {...res}/>
    <MyTasksButton  {...res}/>
</>;

export enum ButtonSwitchType {
    ParentTasks = 'PARENT_TASKS',
    StoreTasks = 'STORE_TASKS',
    NoAccessTaskPage = 'NO_ACCESS_TASK_PAGE',
};

type ButtonSwitchCProps = {
    className?: string;
}

export type ButtonSwitchComponent = FC<ButtonSwitchCProps>;
export const ButtonSwitchComponents: Record<ButtonSwitchType, ButtonSwitchComponent> = {
    [ButtonSwitchType.ParentTasks]: ParentTasksBtnSwitch,
    [ButtonSwitchType.StoreTasks]: StoreTasksBtnSwitch,
    [ButtonSwitchType.NoAccessTaskPage]: NoAccessTaskPageBtnSwitch,
};
