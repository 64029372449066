import { useSelector } from 'react-redux';
import {
    selectScheduledTasksById,
    selectScheduledTasksOrder,
} from 'store/modules/task/scheduledTask/scheduledTaskSelectors';
import { nonEmpty } from 'utils/helpers/collections';

export function useScheduledTasksGridData() {
    const tasksOrder = useSelector(selectScheduledTasksOrder);
    const tasksById = useSelector(selectScheduledTasksById);

    return tasksOrder
        .map(id => tasksById[id])
        .filter(nonEmpty);
}
