import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import { selectToastMessagesHistory } from 'store/modules/appState/appStateSelectors';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export function SnackbarCloseButton({ snackbarKey }: { snackbarKey : SnackbarKey}) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)} >
            <CloseIcon style={{color:'white'}}/>
        </IconButton>
    );
}

export function ToastNotifier() {
    const { enqueueSnackbar } = useSnackbar();
    const messagesHistory = useSelector(selectToastMessagesHistory);

    useEffect(() => {
        if (messagesHistory.length) {
            const { message, options } = messagesHistory[messagesHistory.length - 1];
            enqueueSnackbar(message, options);
        }
    }, [enqueueSnackbar, messagesHistory]);

    return null;
}
