import { CircularProgress, Typography } from "@material-ui/core";
import BlockIcon from '@material-ui/icons/Block';
import { useSelector } from "react-redux";
import { selectUsersByIds, selectUsersLoadingById } from "store/modules/user/userSelectors";

export type AutocompleteUserProps = {
    id: string;
    name: string;
}

export const AutocompleteUser = ({ id, name }: AutocompleteUserProps) => {
    const userLoading = useSelector(selectUsersLoadingById)[id];
    const user = useSelector(selectUsersByIds)[id];

    const activityIcon = user?.isDeleted
        ? <BlockIcon />
        : null;

    return (
        <>
            <Typography style={{ marginRight: '0.3em' }}>{name}</Typography>
            {userLoading
                ? <CircularProgress size="16px" />
                : activityIcon}
        </>
    )
};
