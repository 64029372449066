import { TaskPrototypeUpdate } from "api/task/prototype/prototypeModel";
import { createSingleAction } from "utils/store/actionUtils";
import { EditTaskType } from "./editTaskModel";

export const SAVE_TASK_BY_TYPE = 'task/edition-task/SAVE_TASK_BY_TYPE';
export const handleSaveTask = createSingleAction<TaskPrototypeUpdate, typeof SAVE_TASK_BY_TYPE>(
  SAVE_TASK_BY_TYPE,
);

export const SET_EDIT_TASK_TYPE = 'task/edition-task/SET_EDIT_TASK_TYPE';
export const setEditTaskType = createSingleAction<EditTaskType, typeof SET_EDIT_TASK_TYPE>(
  SET_EDIT_TASK_TYPE,
);

export type EditTaskActions =
    | ReturnType<typeof handleSaveTask>
    | ReturnType<typeof setEditTaskType>;
