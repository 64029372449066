import { combineReducers } from "redux";
import { EditTaskType } from "./editTaskModel";
import { EditTaskActions, SET_EDIT_TASK_TYPE } from "./editTaskActions";

const initialState = {
  editTaskType: null
};

function editTaskType(
  state: EditTaskType | null = initialState.editTaskType,
  action: EditTaskActions
): EditTaskType | null {
  if(action.type === SET_EDIT_TASK_TYPE){
      return action.payload
  }
  return state;
}
export const editTaskReducer = combineReducers({
  editTaskType
});
