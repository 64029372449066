import React, { FC as FunctionalComponent, PropsWithChildren, useMemo } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { IAccordionSection } from './model';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme: Theme) => ({
    accordion: {
        backgroundColor: 'transparent',
        border: `1px solid #777`,
    },
    accordionColor: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
    },
    title: {
        marginLeft: theme.spacing(1),
    },
}));

const transitionPorps: Partial<TransitionProps> = {
    timeout: 200,
};

export interface IChannelDetailsSectionProps extends IAccordionSection {
    Icon: FunctionalComponent<any>,
    title: string | React.ReactNode,
}

export function ChannelDetailsSection({
    Icon,
    title,
    children,
    expandable = true,
    expandedByDefault = false,
}: PropsWithChildren<IChannelDetailsSectionProps>) {
    const classes = useStyles();
    const expandIcon = useMemo(() => {
        return expandable
            ? <ExpandMoreIcon />
            : null;
    }, [expandable]);

    return (
        <Accordion
            className={classes.accordion}
            TransitionProps={transitionPorps}
            defaultExpanded={expandedByDefault}
            expanded={expandable ? undefined : false}
        >
            <AccordionSummary className={classes.accordionColor} expandIcon={expandIcon}>
                <Box display="flex" alignItems="center">
                    <Icon />
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                </Box>
            </AccordionSummary>
            {expandable && (
                <AccordionDetails className={classes.accordionColor}>
                    {children}
                </AccordionDetails>
            )}
        </Accordion>
    );
}
