import React, { useCallback } from "react";
import { FormControl, MenuItem, Select, SelectProps } from '@material-ui/core';
import { FilterOption } from "../../task/taskList/components/model";

export type OptionsDropdownProps<T extends string> = {
    className?: string;
    selectClasses?: SelectProps['classes'];
    unsetValue: T | string;
    options: FilterOption<T>[];
    value?: T;
    onValueChange: (newValue?: T) => void;
    disabled?: boolean;
};

export function OptionsDropdown<T extends string>({
    className,
    selectClasses,
    options,
    unsetValue,
    value,
    onValueChange,
    disabled,
}: OptionsDropdownProps<T>) {
    const valueChangeHandler = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const taskStatus = e.target.value === unsetValue
            ? undefined
            : e.target.value as T;

        onValueChange(taskStatus);
    }, [unsetValue, onValueChange]);

    return (
        <FormControl className={className}>
            <Select
                disabled={disabled}
                classes={selectClasses}
                value={value ?? unsetValue}
                onChange={valueChangeHandler}
                variant="outlined"
            >
                {options.map((option, idx) => (
                    <MenuItem
                        key={idx}
                        value={option.value}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
