import { generatePath, useParams } from 'react-router';
import { PageLoader } from 'components/router/PageLoader';
import { Routes } from 'components/router/model';
import { Formik } from 'formik';
import { EditTaskTemplateForm } from './EditTaskTemplateForm';
import { scheduledTaskValidationSchema } from './validation';
import { useCallback, useEffect } from 'react';
import { ScheduledTaskDetails } from 'api/task/scheduledTask/scheduledTaskModel';
import { useSelector } from 'react-redux';
import { getTaskTemplate, updateTaskTemplate } from 'api/task/scheduledTask/scheduledTaskActions';
import { useActions } from 'utils/store/useActions';
import {
    selectStoresByScheduledTaskFetching,
    selectTaskTemplatesById,
    selectTaskTemplatesLoadingById,
    selectTaskTemplatesUpdatingById,
} from 'store/modules/task/scheduledTask/scheduledTaskSelectors';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { AppName } from 'store/modules/apps/appsModel';
import { selectAppsListByName } from 'store/modules/apps/appsSelector';
import { useIsHigherOrSameLocation } from 'modules/hierarchy/hooks';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import { ScheduledTaskHistory } from '../common/ScheduledTaskHistory';
import { validateForms } from '../utils/validationPenddingFiles';
import { getPendingFiles } from 'store/modules/files/filesSelectors';
import { Trans } from 'react-i18next';
import { TaskCreator } from '../common/taskCreator/TaskCreator';

export function EditTaskTemplatePage() {
    const { uuid: templateId } = useParams<{ uuid: string }>();
    const { id: userId, companyLocationId: currentUserCompanyLocationId = '' } = useCurrentUser() || {};
    const canCreateDeleteTasks = useSelector(selectAppsListByName)[AppName.TasksCreateDelete];
    const taskEditEnabled = useFeatureState(FeatureFlags.EditScheduledTask);
    const managerAccessEnabled = useFeatureState(FeatureFlags.ManagerAccessToTaskEditing);
    const isScheduledTaskHistoryEnabled = useFeatureState(FeatureFlags.ScheduledTaskHistory);
    const taskTemplate = useSelector(selectTaskTemplatesById)[templateId];
    const isLoading = useSelector(selectTaskTemplatesLoadingById)[templateId];
    const isTasksSByStoreLoading = useSelector(selectStoresByScheduledTaskFetching);
    const isUpdating = useSelector(selectTaskTemplatesUpdatingById)[templateId];
    const pendingFiles = useSelector(getPendingFiles);
    const { rootLocationId: taskLocationId = '' } = taskTemplate || {};
    const isManagerFromHigherLocation = useIsHigherOrSameLocation(currentUserCompanyLocationId ?? '', taskLocationId)
        && managerAccessEnabled;
    const actions = useActions({
        getTaskTemplate: getTaskTemplate.init,
        updateTaskTemplate: updateTaskTemplate.init
    });
    const onSubmit = useCallback((values: ScheduledTaskDetails) => {
        actions.current.updateTaskTemplate(values);
    }, [actions]);

    useEffect(() => {
        actions.current.getTaskTemplate(templateId);
    }, [actions, templateId]);

    if (!taskTemplate || isLoading || isTasksSByStoreLoading) {
        return <PageLoader />;
    }

    const isTaskOwner = userId === taskTemplate.createdBy;
    const isUserPermitted = canCreateDeleteTasks && (isTaskOwner || isManagerFromHigherLocation);
    const canEditTask = isUserPermitted && taskEditEnabled;

    return (
        <>
            <FormPageView
                actionBar={<BackButton route={generatePath(Routes.TaskListScheduled)}><Trans>Back to Tasks</Trans></BackButton>}
            >
                <Formik
                    initialValues={taskTemplate}
                    validationSchema={scheduledTaskValidationSchema}
                    onSubmit={onSubmit}
                    validate={() => validateForms(pendingFiles.length)}
                >
                    {props => (
                        <EditTaskTemplateForm
                            isDisabled={!canEditTask}
                            isUpdating={!!isUpdating}
                            handleSubmit={props.handleSubmit}
                        />)
                    }
                </Formik>
                <TaskCreator isDisabled={!canEditTask} />
            </FormPageView>
            {isScheduledTaskHistoryEnabled && 
                (
                    <ScheduledTaskHistory taskTemplate={taskTemplate} templateId={templateId}/>
                )
            }
        </>
    );
}
