import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useFormStyles } from 'modules/shared/components/formStyles';
import { CompanyLogoForm } from './CompanyLogoForm';
import { TabsButton } from 'components/tabsButton/TabsButton';
import { CompanyColorForm } from './CompanyColorForm';
import i18n from 'i18n';

export type CompanyFormProps = {
  isDisabled?: boolean;
}
enum NameTab {
  Color = 'Color',
  Logo = 'Logo'
}
export const CompanyTab = () => {
  const classes = useFormStyles([]);
  const useTabMemo = useMemo(()=>([
    {name: NameTab.Logo, children: <CompanyLogoForm />, label: i18n.t('Logo') },
    {name: NameTab.Color, children: <CompanyColorForm />, label: i18n.t('Color') }
  ]), []);


  return (
      <Box className={classes.formInputs}>
        <Box className={classes.titleInput }>
          <TabsButton 
            tabs={useTabMemo}
            isReRenderChildren
          />
        </Box>
      </Box>
  );
}
