import ReactCrop, { Crop } from 'react-image-crop';
import { useField } from 'formik';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useUploadStyles } from 'modules/shared/components/styles/uploadStyles';

export interface IFormFieldProps {
    name: string;
    label?: string;
    outerLabel?: string;
    id?: string;
    className?: string;
    disabled?: boolean;
    textPreview?: ReactNode,
    previewStyles?: React.CSSProperties,
    acceptFile?: string | string[],
    isCenterBox?: boolean
}

interface IImageUploadWithCropProps extends IFormFieldProps {
    initialCrop?: Crop;
    maxFileSizeMB?: number;
}

const bytesInMb = 1024 * 1024;

export function ImageEditor({
    name,
    initialCrop = { unit: '%', width: 100, aspect: 1 },
    maxFileSizeMB = 10,
    textPreview = <Trans>Avatar Preview</Trans>,
    previewStyles = { border: '1px solid black', width: '100px', height: '100px', borderRadius: '50%'},
    acceptFile = 'image/*',
    isCenterBox = false
}: IImageUploadWithCropProps) {
    const classes = useUploadStyles();
    const [field, meta, helper] = useField(name);
    const [sourceImg, setSourceImg] = useState(field.value);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [crop, setCrop] = useState<Crop>(initialCrop);
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
    const maxFileSize = maxFileSizeMB * bytesInMb;

    const onDrop = useCallback((uploadedFiles: File[]) => {
        uploadedFiles.forEach(file => {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSourceImg(reader.result));
            reader.readAsDataURL(file);
        });
    }, [setSourceImg]);

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, [imgRef]);

    useEffect(() => {
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        if (completedCrop && image && canvas) {
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext('2d');

            const pixelRatio = window.devicePixelRatio;

            canvas.width = (completedCrop?.width ?? 0) * pixelRatio;
            canvas.height = (completedCrop?.height ?? 0) * pixelRatio;

            if (ctx) {
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(
                    image,
                    (completedCrop?.x ?? 0) * scaleX,
                    (completedCrop?.y ?? 0) * scaleY,
                    (completedCrop?.width ?? 0) * scaleX,
                    (completedCrop?.height ?? 0) * scaleY,
                    0,
                    0,
                    (completedCrop?.width ?? 0),
                    completedCrop?.height ?? 0,
                );
            }
        }
    }, [completedCrop]);

    const {
        getRootProps,
        getInputProps,
        isDragReject,
        fileRejections,
        isFocused,
        isDragAccept,
    } = useDropzone({
        accept: acceptFile,
        onDrop,
        noDrag: false,
        multiple: false,
        maxSize: maxFileSize,
        noDragEventsBubbling: true,
    });

    useEffect(() => {
        if (previewCanvasRef.current && completedCrop) {
            const imageBase64 = previewCanvasRef.current.toDataURL('image/jpeg');
            if (field.value !== imageBase64) {
                helper.setValue(imageBase64);
            }
        }
    }, [previewCanvasRef, completedCrop, helper, field.value]);
    const isFileTooLarge = fileRejections.length > 0 && fileRejections.some(file => file.file.size > maxFileSize);
    const hasError = Boolean(meta.error && meta.touched) || isDragReject || isFileTooLarge;
    const errorText = (() => {
        if (isFileTooLarge) return t('File is too large.');
        if (isDragReject) return t('Please upload image file.');
        return meta.error;
        })();
    const classForDropzone = useMemo(() => {
        if (isDragAccept) {
            return classes.fileDropzoneOver;
        }
        if (isDragReject) {
            return classes.fileDropzoneError;
        }
        return classes.fileDropzone;
    }, [isDragAccept, isDragReject, classes]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems={ isCenterBox ? "center" : '' }
        >
            {!sourceImg && (
                <div {...getRootProps({isFocused, isDragAccept, isDragReject })} className={classForDropzone}>
                    <div className={classes.uploadHeader}>
                        <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                        <Typography
                            className={classes.uploadHeaderTitle}
                            color="primary"
                            variant="subtitle2"
                        >
                            <Trans>Upload Image or use Drag & Drop</Trans>
                        </Typography>
                    </div>
                    <input {...getInputProps() }/>
                    {hasError && (
                        <Typography
                            className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                            {errorText}
                        </Typography>
                    )}
                </div>
            )}
            {sourceImg && (
                <ReactCrop
                    src={sourceImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={c => setCrop(c)}
                    onComplete={c => setCompletedCrop(c)}
                    crossorigin="anonymous"
                    style={{
                        maxWidth: 600,
                    }}
                />
            )}
            {completedCrop && (
            <Box
                className={classes.previewEnvelope}>
                <Box margin={'4px'} mb={1}>{textPreview}</Box>
                <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={previewStyles}
                />
            </Box>
            )}
        </Box>
    );
}
