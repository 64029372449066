import { IStoreState } from 'store/rootReducer';

export const selectScheduledTasksFetching = (state: IStoreState) => state.task.scheduledTasks.scheduledTasksFetching;
export const selectScheduledTasksNextPageToken = (state: IStoreState) => state.task.scheduledTasks.scheduledTasksNextPageToken;
export const selectScheduledTasksById = (state: IStoreState) => state.task.scheduledTasks.scheduledTasksById;
export const selectScheduledTasksOrder = (state: IStoreState) => state.task.scheduledTasks.scheduledTasksOrder;
export const selectTaskTemplatesById = (state: IStoreState) => state.task.scheduledTasks.templatesById;
export const selectTaskTemplatesLoadingById = (state: IStoreState) => state.task.scheduledTasks.templatesLoadingById;
export const selectTaskTemplatesUpdatingById = (state: IStoreState) => state.task.scheduledTasks.templatesUpdatingById;
export const selectStoresByScheduledTaskFetching = (state: IStoreState) => state.task.scheduledTasks.storesByScheduledTaskFetching;
