import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import {
    BuildOutlined,
    FolderOpenOutlined,
    FormatListBulletedOutlined,
    PieChartOutlined,
    Settings,
    NotificationsOutlined,
} from '@material-ui/icons';
import { BookOpenIcon, StreamIcon } from 'icons';
import { routeNames, Routes } from 'components/router/model';
import { AppName } from 'store/modules/apps/appsModel';
import { LeftMenuItem } from 'modules/root/LeftMenu/LeftMenuItem';
import { ConnectNotificationsCounter, TasksCounterBadge, NotificationsCounter, MyTasksCounterBadge } from './badgeWrappers';
import { FeatureFlags } from 'utils/featureFlags';
import { UnreadNotificationsBadge } from 'modules/notifications/UnreadNotificationsBadge';
import { generatePath } from 'react-router-dom';

export interface ILeftMenuItem {
    text: string;
    to: string;
    Icon: React.FC<SvgIconProps>;
    IconWrapper?: React.FC;
    Extra?: React.FC;
    appName?: AppName;
    featureFlag?: FeatureFlags;
}

const leftMenuTopItemsMyTasks: Array<ILeftMenuItem> = [
    {
        text: routeNames[Routes.Tasks],
        to: generatePath(Routes.TaskListCalendar),
        Icon: FormatListBulletedOutlined,
        IconWrapper: MyTasksCounterBadge,
        featureFlag: FeatureFlags.TasksAutomationMyTasks,
        appName: AppName.Tasks,

    }
]
export const leftMenuTopItems: Array<ILeftMenuItem> = [
    // TODO restore when Today page is completed
    /*{
        text: routeNames[Routes.Today],
        to: Routes.Today,
        Icon: WbSunnyOutlined,
    },*/
    {
        text: routeNames[Routes.Tasks],
        to: Routes.TaskListCalendar,
        Icon: FormatListBulletedOutlined,
        IconWrapper: TasksCounterBadge,
        appName: AppName.Tasks,
    },
    {
        text: routeNames[Routes.Connect],
        to: generatePath(Routes.Connect),
        Icon: StreamIcon,
        IconWrapper: ConnectNotificationsCounter,
    },
    {
        text: routeNames[Routes.Learn],
        to: Routes.Learn,
        Icon: BookOpenIcon,
    },
    {
        text: routeNames[Routes.Analytics],
        to: Routes.Analytics,
        appName: AppName.Analytics,
        Icon: PieChartOutlined,
    },
    {
        text: routeNames[Routes.Library],
        appName: AppName.Library,
        to: Routes.Library,
        Icon: FolderOpenOutlined,
    },
];

export const leftMenuBottomItems: Array<ILeftMenuItem> = [
    {
        text: routeNames[Routes.Admin],
        to: Routes.Admin,
        Icon: Settings,
        appName: AppName.Admin,
    },
    {
        text: routeNames[Routes.SuperAdmin],
        to: Routes.SuperAdmin,
        Icon: BuildOutlined,
        appName: AppName.SuperAdmin,
    },
    {
        text: routeNames[Routes.Notifications],
        to: Routes.Notifications,
        Icon: NotificationsOutlined,
        IconWrapper: NotificationsCounter,
        featureFlag: FeatureFlags.LegacyNotificationFeed,
    },
    {
        text: routeNames[Routes.NotificationFeed],
        to: Routes.NotificationFeed,
        Icon: NotificationsOutlined,
        IconWrapper: UnreadNotificationsBadge,
    },
];

export const leftMenuItemsRender = (
    items: Array<ILeftMenuItem>,
    hasAppAccess: (appName: AppName) => boolean,
    isFeatureEnabled: (featureFlag: FeatureFlags) => boolean,
    showTooltip: boolean,
) => (
    items
        .filter(item => item.appName ? hasAppAccess(item.appName) : true)
        .filter(item => item.featureFlag ? isFeatureEnabled(item.featureFlag) : true)
        .map(item => (
            <LeftMenuItem key={item.to} item={item} showTooltip={showTooltip}/>
        ))
);

export const leftMenuTopItemsMyTasksRender = (
    hasAppAccess: (appName: AppName) => boolean,
    isFeatureEnabled: (featureFlag: FeatureFlags) => boolean,
    showTooltip: boolean,
) => (
    leftMenuTopItemsMyTasks
        .filter(item => item.appName ? !hasAppAccess(item.appName) : true)
        .filter(item => item.featureFlag ? isFeatureEnabled(item.featureFlag) : true)
        .map(item => (
            <LeftMenuItem key={item.to} item={item} showTooltip={showTooltip}/>
        ))
);