import LabelIcon from '@material-ui/icons/Label';

export type LabelImageProps = {
    item: any;
}

export function LabelImage({ item }: LabelImageProps) {

    return (
        <div style={{ display: "flex", textTransform: "uppercase" }}>
            <LabelIcon style={{ color: item.color }} />
            {item.title}
        </div>

    );
}
