import { ParentTaskActions, requestDeleteParentTask } from 'api/task/parentTask/parentTaskActions';
import { ParentTaskShort } from 'api/task/parentTask/parentTaskModel';
import { TaskDetailsActions, requestParentTaskDetails } from 'api/task/parentTaskDetails/parentTaskDetailsActions';
import { ParentTaskDetailsModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { TaskActions, requestParentTasks } from 'api/task/taskActions';
import { ItemsById } from 'model/entity';
import { combineReducers } from 'redux';
import { insertSavingOrder } from 'utils/helpers/collections';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { TaskListComponentActions, TASK_LIST_RESET_DATA } from '../taskListComponent/taskListComponentActions';
import { SetParentTaskToRemoveActionType, SET_PARENT_TASK_TO_REMOVE_ID } from './parentTaskActions';

const initialState = {
    parentTaskToRemove: null,
    removingParentTasks: [],

    parentTasksFetching: false,
    parentTasksNextPageToken: null,
    parentTasksById: {},
    parentTasksOrder: [],
};

export function parentTaskToRemove(
    state = initialState.parentTaskToRemove,
    action: SetParentTaskToRemoveActionType | ParentTaskActions,
): string | null {
    if (action.type === SET_PARENT_TASK_TO_REMOVE_ID) {
        return action.payload;
    }
    if (action.type === requestDeleteParentTask.initType) {
        return null;
    }
    return state;
}

export function removingParentTasks(
    state = initialState.removingParentTasks,
    action: ParentTaskActions,
): Array<string> {
    if (action.type === requestDeleteParentTask.initType) {
        return [...state, action.payload.taskId];
    } else if (
        action.type === requestDeleteParentTask.successType
        || action.type === requestDeleteParentTask.errorType
    ) {
        return !action.payload?.isFromStoreTask ? state.filter(id => id !== action.payload.id) : state;
    }
    return state;
}

function parentTasksFetching(
    state: boolean = initialState.parentTasksFetching,
    action: TaskActions,
): boolean {
    if (action.type === requestParentTasks.initType) {
        return true;
    } else if (action.type === requestParentTasks.successType || action.type === requestParentTasks.errorType) {
        return false;
    }

    return state;
}

function parentTasksNextPageToken(
    state: string | null = initialState.parentTasksNextPageToken,
    action: TaskActions,
): string | null {
    if (action.type === requestParentTasks.successType) {
        return action.payload.nextPageToken;
    }
    return state;
}

function parentTasksById(
    state: ItemsById<ParentTaskShort | ParentTaskDetailsModel> = initialState.parentTasksById,
    action: TaskActions | TaskListComponentActions | TaskDetailsActions | ParentTaskActions,
): ItemsById<ParentTaskShort | ParentTaskDetailsModel> {
    switch (action.type) {
        case requestParentTaskDetails.successType:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        case requestParentTaskDetails.initType:
            return {
                ...state,
                [action.payload]: undefined,
            };

        case requestParentTasks.successType:
            return { ...state, ...itemsByIds(action.payload.tasks) };
        case TASK_LIST_RESET_DATA:
            return initialState.parentTasksById;
        case requestDeleteParentTask.successType:
            return { ...state, [action.payload.id]: undefined };
        default:
            return state;
    }
}

function parentTasksOrder(
    state: Array<string> = initialState.parentTasksOrder,
    action: TaskActions | TaskListComponentActions | ParentTaskActions,
): Array<string> {
    switch (action.type) {
        case requestParentTasks.successType:
            return insertSavingOrder(state, action.payload.tasks.map((item: { id: any; }) => item.id));
        case TASK_LIST_RESET_DATA:
            return initialState.parentTasksOrder;
        case requestDeleteParentTask.successType:
            return state.filter(id => id !== action.payload.id);
        default:
            return state;
    }
}

export const parentTaskReducer = combineReducers({
    parentTaskToRemove,
    removingParentTasks,
    parentTasksFetching,
    parentTasksNextPageToken,
    parentTasksOrder,
    parentTasksById,
});
