import { IStoreState } from 'store/rootReducer';

export const selectTaskReviewIsModalOpen = (state: IStoreState) => state.task.taskReview.isModalOpen;
export const selectTaskReviewIsLoading = (state: IStoreState) => state.task.taskReview.isLoading;
export const selectTaskReviewIsCountLoading = (state: IStoreState) => state.task.taskReview.isCountLoading;
export const selectTaskReviewItems = (state: IStoreState) => state.task.taskReview.items;
export const selectTaskReviewUpdatingItems = (state: IStoreState) => state.task.taskReview.updatingItems;
export const selectTaskReviewCounter = (state: IStoreState) => state.task.taskReview.counter;
export const selectTaskReviewModalTitle = (state: IStoreState) => state.task.taskReview.parentTask.title;
export const selectParentTaskReview = (state: IStoreState) => state.task.taskReview.parentTask;
