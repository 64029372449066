import { Avatar, Box, styled, Typography } from "@material-ui/core";
import { CreateCSSProperties } from "@material-ui/core/styles/withStyles";
import { Breakpoints, UtilColors } from "utils/styles/constants";

export const AVATAR_SIZE = 34;

const commonTypography: CreateCSSProperties<{}> = {
    font: `'Inter', 'Arial', sans-serif`,
    fontWeight: 400,
    fontStyle: 'normal',
};

const ellipsis: CreateCSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

export const StyledAvatar = styled(Avatar)({
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
});

export const StyledTitle = styled(Typography)(({ theme }) => ({
    ...commonTypography,
    fontSize: '14px',
    lineHeight: '20.02px',
    letterSpacing: '0.15px',
    color: UtilColors.TextPrimary,
    ...ellipsis,

    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        fontSize: '13px',
        lineHeight: '18.59px',
    },

    '.task-header-creator &': {
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            lineHeight: '15.73px',
        },
    },
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
    ...commonTypography,
    ...ellipsis,
    fontSize: '12px',
    lineHeight: '19.92px',
    letterSpacing: '0.4px',
    color: UtilColors.TextSecondary,

    '.task-header-creator &': {
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontSize: '10px',
            lineHeight: '12.1px',
        },
    },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    width: '100%',
}));

export const StyledInfoWrapper = styled(Box)({
    display: 'flex',
    flexFlow: 'column',
    minWidth: 0,
});
