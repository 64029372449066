import { Box, styled } from '@material-ui/core';

export const StickyPanel = styled(Box)({
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    padding: '0 33px 37px 33px',
    display: 'flex',
    justifyContent: 'flex-end',
    pointerEvents: 'none',
});
