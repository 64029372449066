import React from 'react';
import { Channel } from 'stream-chat';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Description as DescriptionIcon } from '@material-ui/icons';
import { getChannelDescription } from '../../../../../helpers';
import { ChannelDetailsSection } from './ChannelDetailsSection';
import { IAccordionSection } from './model';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    description: {
        wordBreak: 'break-word',

        '& p': {
            margin: 0,
            padding: '0 0 6px',

            '&:empty': {
                padding: '10px 0',
            },
        },
    },
}));

export interface IChannelDescription extends IAccordionSection {
    channel: Channel
}

export function ChannelDescription({ channel, ...rest }: IChannelDescription) {
    const classes = useStyles();
    const description = getChannelDescription(channel);
    return (
        <ChannelDetailsSection
            title={<Trans>Description</Trans>}
            Icon={DescriptionIcon}
            {...rest}
        >
            <Typography className={classes.description} component="div">
                {renderDescription(description)}
            </Typography>
        </ChannelDetailsSection>
    );
}

function renderDescription(description: string) {
    return (
        description
            .split(/\r?\n/i)
            .map((chunk: string, index: number) => (
                <p key={index}>{chunk}</p>
            ))
    );
}
