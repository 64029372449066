import React, { RefObject } from 'react';
import { Box, styled } from '@material-ui/core';
import { CreateTaskButton } from './CreateTaskButton';
import { Breakpoints } from 'utils/styles/constants';

const StyledTaskListPageWrapper = styled(Box)(() => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
}));

const StyledLayoutHeader = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    padding: theme.spacing(3.25, 4, 2.5),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: theme.spacing(1, 1, 2),
    },
}));

const StyledLayoutBody = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(3, 4),

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: 0,
    },
}));

export interface ITaskListPageLayoutProps {
    header: React.ReactNode;
    showCreateButton?: boolean;
    layoutHeaderRef?: RefObject<HTMLDivElement>;
    createButton?: React.ReactNode;
}

export const TaskListPageLayout: React.FC<React.PropsWithChildren<ITaskListPageLayoutProps>> = ({
    header,
    children,
    showCreateButton,
    layoutHeaderRef,
    createButton = undefined
}) => (
    <StyledTaskListPageWrapper>
        {/* @ts-ignore - ref is actually presented as property of this container */}
        <StyledLayoutHeader ref={layoutHeaderRef}>
            {header}
        </StyledLayoutHeader>

        <StyledLayoutBody>
            {children}
        </StyledLayoutBody>

        {(showCreateButton && !createButton) && (
            <CreateTaskButton />
        )}
        {(showCreateButton && createButton) && (
            <>{createButton}</>
        )}
    </StyledTaskListPageWrapper>
);
