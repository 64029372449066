import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserAISidekick } from 'store/modules/integration/integrationSelectors';
import {
    CardIntegrationAISidekick,
    GridIntegration,
    GridIntegrationChildren,
} from '../IntegrationStyles';

export const IntegrationAISidekick = () => {
    const userAISidekick = useSelector(selectUserAISidekick);
    return (
        <Box mt={3}>
            <Typography variant='h3'>
                <Trans>AI Sidekick</Trans>
            </Typography>
            <GridIntegration>
                {userAISidekick.map((prov) => (
                    <GridIntegrationChildren
                        gridProp={{ sm: 3, md: 2 }}
                        key={prov.id}
                    >
                        <CardIntegrationAISidekick {...prov} />
                    </GridIntegrationChildren>
                ))}
            </GridIntegration>
        </Box>
    );
};
