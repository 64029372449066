import { makeStyles, Theme } from '@material-ui/core/styles';
import { BlueColor, UtilColors } from 'utils/styles/constants';

export const useDropzoneStyles = makeStyles((theme: Theme) => ({
    dropzoneWrapper: {
        borderRadius: theme.spacing(3),
        padding: theme.spacing(3),
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        border: `2px dashed ${theme.palette.getContrastText(theme.palette.primary.dark)}`,
        outline: 'none',
        position: 'relative',
    },
    lightDropzoneWrapper: {
        boxSizing: 'border-box',
        maxWidth: theme.spacing(44.125),
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3, 2.5),
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: UtilColors.White,
        color: BlueColor.BluePrimaryMain,
        border: `1px dashed ${BlueColor.BluePrimaryMain}`,
        outline: 'none',
        boxShadow: 'none',

        '& > svg': {
            color: BlueColor.BluePrimaryMain,
            marginRight: theme.spacing(1),
        },
        '& > p': {
            margin: 0,
        },
    },
}));
