import { Theme, makeStyles } from "@material-ui/core";
import { Breakpoints } from "utils/styles/constants";

export const useLaunchCoverStyles = makeStyles((theme: Theme) => ({
  circular: {
    float: 'right',
  },
  emptyBtnImge: {
    border: `${theme.spacing(.5)}px dotted`,
    borderColor: theme.palette.primary.dark,
  },
  iconCameraAdd: {
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(7)
  },
  iconAction: {
    position: "absolute",
    margin: theme.spacing(1.5),
    padding: 0
  },
  editIcon: {
    top: 0,
    right: 35,
  },
  deleteIcon: {
    top: 0,
    right: 5,
  },
  modalCropperTitle: {
    marginBottom: theme.spacing(3),
  },
  boxInner1: {
    overflowX: 'hidden',
    width: '65vw',
    maxWidth: 1280,
    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
      width: '100vw'
    },
  },
  dialogBox: {
    overflowX: 'hidden',
  },
  launchCoverImg: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: 220,
    borderRadius: 4,
  }
}));