import { Box, Switch as SwitchMaterial, Typography } from '@material-ui/core'
import { InputError } from 'components/basicInputs/InputError';
import { IInputProps } from 'components/basicInputs/model';
import { hasFormikError } from 'components/basicInputs/utils';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useField } from 'formik';
import { ReactElement } from 'react';

interface ISwitchProps extends IInputProps {
  showTooltip?: boolean,
  titleTooltip: ReactElement,
  bodyTooltip: ReactElement,
  disabled?: boolean
}

export const Switch = (props: ISwitchProps) => {
  const [field, meta] = useField(props.name);
  const hasError = hasFormikError(meta);
  const { onChange, value } = field;

  return (
    <Box display="flex" alignItems="center">
      <SwitchMaterial
        checked={value}
        edge="start"
        name={props.name}
        color="primary"
        onChange={onChange}
        disabled={props.disabled}
      />
      {props.showTooltip ?
        <Tooltip label={props.label} title={props.titleTooltip} bodyTooltip={props.bodyTooltip} />
        : <Typography variant="caption">{props.label}</Typography>
      }
      {hasError && (
        <Box display="flex" justifyContent="space-between">
          <InputError error={meta.error} />
        </Box>
      )}
    </Box>
  )
}
