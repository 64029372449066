import React from 'react';
import { CellProps } from 'react-table';
import { UserInfoSkeleton } from 'components/userInfo/UserInfoSkeleton';
import { ISkeleton } from 'modules/task/taskList/model';
import { UserInfoWithImage } from 'components/userInfo/UserInfoWithImage';
import logo from 'images/Logo/LogoPR.png';
import { getUserName, getUserRole } from 'utils/helpers/user';
import { automationUser } from '../utils/automationUser';

type CreatorCellData = ISkeleton;

export const CreatorCell: React.FC<CellProps<CreatorCellData, string>> = ({ row }) => {
    const { isSkeleton } = row.original;

    return isSkeleton 
        ? <UserInfoSkeleton />
        : <UserInfoWithImage
            avatarUrl={logo}
            title={getUserName(automationUser)}
            subtitle={getUserRole(automationUser)}
            lastName={automationUser.lastName!}
            firstName={automationUser.firstName!}
        />;
};
