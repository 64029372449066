import React, { useCallback, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ColorInput } from 'components/basicInputs/colorInput/ColorInput';
import { Formik, FormikProps } from 'formik';
import { companyValidationSchema, validateForms } from './validation';
import { useSelector } from 'react-redux';
import { selectUserColorFetching, selectUserCompany } from 'store/modules/company/companySelector';
import { BlueColor, UtilColors } from 'utils/styles/constants';
import { CompanyColorType, CompanyType } from 'api/company/companyModel';
import { useActions } from 'utils/store/useActions';
import { requestUpdateCompanyColor } from 'api/company/companyActions';
import { FormActions } from 'modules/shared/components/FormActions';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const primaryColorFieldName: string = 'primaryColor';
const secondaryColorFieldName: string = 'secondaryColor';
const COLOR_MAX_LENGTH: number = 7;

export const CompanyColorForm = () => {
    const colorFetching = useSelector(selectUserColorFetching);
    const companyInformation = useSelector(selectUserCompany);
    const actions = useActions({
        updateCompanyColor: requestUpdateCompanyColor.init,
    });
    const initialFormValues = useMemo(() => ({
        ...companyInformation,
        primaryColor: companyInformation?.primaryColor || BlueColor.Dark,
        secondaryColor: companyInformation?.secondaryColor || UtilColors.IconActive
    } as CompanyType), [companyInformation]);

    const handleSubmit = useCallback((dataForm: CompanyType) => {
        const payload: CompanyColorType = {
            companyId: dataForm.id,
            primaryColor: dataForm.primaryColor as string,
            secondaryColor: dataForm.secondaryColor as string,
            launchPageCover: dataForm.launchPageCover as string,
        }
        actions.current.updateCompanyColor(payload)
    }, [actions]);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={companyValidationSchema}
            onSubmit={handleSubmit}
            validate={(val) => validateForms(val)}
        >
            {props => (
                <CompanyColor
                    isDisabled={!props.isValid}
                    colorFetching={colorFetching}
                    {...props}
                />
            )}
        </Formik>
    )
}

export type CompanyColorProps = FormikProps<any> & {
    isDisabled?: boolean;
    colorFetching: boolean;
}
const CompanyColor = ({ isDisabled, colorFetching, handleSubmit }: CompanyColorProps) => (
    <form onSubmit={handleSubmit}>
        <Box mt={2} mb={2.5}>
            <Typography variant='body1'>
                <Trans>Brand colors</Trans>
            </Typography>
            <Typography variant='body2' style={{ marginTop: 8 }}>
                <Trans>These colors will populate under "Brand colors" in all color pickers</Trans>
            </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2.5}>
            <ColorInput
                id='primary-color-input'
                name={primaryColorFieldName}
                maxLength={COLOR_MAX_LENGTH}
                showCounter
                label={`${t('Primary color')}`}
                isDisabled={colorFetching}
            />
            <ColorInput
                id='secondary-color-input'
                name={secondaryColorFieldName}
                maxLength={COLOR_MAX_LENGTH}
                showCounter
                label={`${t('Secondary color')}`}
                isDisabled={colorFetching}
            />
        </Box>
        <FormActions isInProgress={colorFetching} disabled={isDisabled}/>
    </form>
)
