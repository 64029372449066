import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import { t } from 'i18next'
import React from 'react'
import { faFilter} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useTaskFilterStyles } from '../../taskFilterStyles';

type FilterButtonProps = {
    onChange: () => void;
    checked: boolean;
}
export const FilterButton: React.FC<FilterButtonProps> = ({ onChange, checked }) => {
  const classes = useTaskFilterStyles();

  return (
    <Tooltip title={`${t('Filter')}`}>
        <IconButton onClick={onChange} size='small' className={clsx(classes.buttonFilter, {'active': checked})}>
            <FontAwesomeIcon icon={faFilter} size='1x'/>
          </IconButton>
    </Tooltip>
  )
}
