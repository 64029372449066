import * as yup from 'yup';
import {
    attachmentSchema,
    descriptionSchema,
    dateSchema,
    titleSchema,
    tagSchema,
    TASK_DATE_FORMAT_INIT
} from '../common/form/validation';
import i18n from 'i18n';
import { formatDateCustom } from 'utils/helpers/dateHelper';

export const calendarEventSchema = {
    title: titleSchema,
    eventStart: dateSchema.label('Start Date').min(formatDateCustom(new Date(), TASK_DATE_FORMAT_INIT), `${i18n.t('Start Date could not be earlier than today')}`),
    eventEnd: dateSchema.label('End Date').min(yup.ref('eventStart'), `${i18n.t('End Date field must be not earlier than Start Date')}`),
    description: descriptionSchema,
    attachments: yup.array().of(attachmentSchema),
    tag: tagSchema,
};
