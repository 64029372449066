import React from 'react';
import { Button, ButtonProps, darken, styled } from '@material-ui/core';
import { BlueColor, GrayColor, UtilColors } from 'utils/styles/constants';
import { Block, Done } from '@material-ui/icons';
import { CreateCSSProperties } from '@material-ui/styles';
import { Trans } from 'react-i18next';

const buttonBox: CreateCSSProperties<{}> = {
    pointerEvents: 'all',
    padding: '8px 22px',
    borderRadius: '4px',
    boxShadow: `
        0px 1px 5px 0px rgba(0, 0, 0, 0.12),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.2)
    `,
};

const buttonText: CreateCSSProperties<{}> = {
    '& span': {
        fontSize: '15px',
        lineHeight: '26px',
        letterSpacing: '0.46px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: UtilColors.White,
    },
};

const ApproveComponent: React.FC<ButtonProps> = props => (
    <Button {...props} startIcon={<Done/>}>
        <Trans>Approve</Trans>
    </Button>
);

export const ApproveTaskButton = styled(ApproveComponent)({
    ...buttonBox,
    ...buttonText,
    backgroundColor: BlueColor.BluePrimaryMain,
    '&:hover': {
        backgroundColor: darken(BlueColor.BluePrimaryMain, 0.05),
    },
    '&:disabled': {
        backgroundColor: `${BlueColor.BluePrimaryMain} !important`,
        opacity: 0.5,
        pointerEvents: 'all',
        cursor: 'not-allowed',
    },
});

const DeclineComponent: React.FC<ButtonProps> = props => (
    <Button {...props} startIcon={<Block/>}>
        <Trans>Decline</Trans>
    </Button>
);

export const DeclineTaskButton = styled(DeclineComponent)({
    ...buttonBox,
    ...buttonText,
    backgroundColor: UtilColors.Red3,
    '&:hover': {
        backgroundColor: darken(UtilColors.Red3, 0.05),
    },
    '&:disabled': {
        backgroundColor: `${UtilColors.Red3} !important`,
        opacity: 0.5,
        pointerEvents: 'all',
        cursor: 'not-allowed',
    },
});

export const SubmitTaskButton = styled(props => <Button {...props}/>)({
    pointerEvents: 'all',
    padding: '8px 16px',
    borderRadius: '24px',
    backgroundColor: BlueColor.BluePrimaryMain,
    boxShadow: `
        0px 1px 18px 0px rgba(0, 0, 0, 0.12),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 3px 5px -1px rgba(0, 0, 0, 0.2)
    `,

    '&:hover': {
        backgroundColor: darken(BlueColor.BluePrimaryMain, 0.05),
    },

    '&:disabled': {
        position: 'relative',
        cursor: 'not-allowed',
        pointerEvents: 'all',
        backgroundColor: GrayColor.GrayE3,
        '& span': {
            color: darken(GrayColor.GrayE3, 0.10),
        },

        '&:hover': {
            backgroundColor: GrayColor.GrayE3,
        },
    },

    '& span': {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
        textTransform: 'uppercase',
        color: UtilColors.White,
    },
});
