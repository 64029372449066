import * as t from 'io-ts';
import { nullableStringType } from 'model/dataBasicTypes';
import {
    locationTaskStatus,
    submittedTasksSchemaType,
    taskShortSchemaBase,
} from '../common/taskCommonModel';
import { ListSearchRequestBase } from "../../common/model";
import { taskTagSchema } from '../tag/taskTagModel';
import { IResponseSuccessPayload } from 'model/success';
import { IResponseErrorPayload } from 'model/error';

export type ParentTaskDeleteRequest = {
    taskId: string;
    prototypeId: string;
    isFromStoreTask?: boolean;
}

export enum ParentTaskStatusFilter {
    Incomplete = 'incomplete',
    Expired = 'expired',
    Completed = 'completed',
    Overdue = 'overdue',
    Submitted = 'submitted',
    SubmittedOverdue = 'submittedOverdue',
    CompletedOverdue = 'completedOverdue',
}

export enum ParentTasksSortBy {
    Title = 'title',
    DueDate = 'dueDate',
    Completed = 'completed',
    Submitted = 'submitted',
    CreatedBy = 'createdBy',
}

export type ParentTaskSearchRequest = ListSearchRequestBase<ParentTasksSortBy> & {
    dueDateFrom?: string;
    dueDateTo?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    title?: string;
    description?: string;
    locationName?: string;
    createdBy?: Array<string>;
    isOverdue?: boolean;
    isCompleted?: boolean;
    filter?: ParentTaskStatusFilter;
    tags?: Array<string>;
    searchTerm?: string;
    tasksViewMode?: string;
};

export const parentTaskShortSchema = t.intersection([
    taskShortSchemaBase,
    t.type({
        prototypeId: t.union([t.undefined, t.string]),
        rootLocationId: t.union([t.undefined, t.string]),
        storeTasks: t.number,
        completed: t.number,
        overdue: t.number,
        expired: t.number,
        submittedOverdue: t.number,
        completedOverdue: t.number,
        isApproval: t.union([t.undefined, t.boolean]),
        isExpired: t.union([t.undefined, t.boolean]),
        isExpirable: t.union([t.undefined, t.boolean]),
        tag: t.union([t.null, taskTagSchema]),
    }),
    submittedTasksSchemaType,
    locationTaskStatus,
]);

export type ParentTaskShort = t.TypeOf<typeof parentTaskShortSchema>;

export const parentTaskSearchResponseSchema = t.type({
    nextPageToken: nullableStringType,
    tasks: t.array(parentTaskShortSchema),
});

export type ParentTaskSearchResponse = t.TypeOf<typeof parentTaskSearchResponseSchema>;

export const storeTasksDeleteSchema = t.type({
    storeTaskId: t.string,
});

export type StoreTasksDeleteType = t.TypeOf<typeof storeTasksDeleteSchema>;
export type StoreTasksDeleteSuccess = StoreTasksDeleteType & IResponseSuccessPayload;
export type StoreTasksDeleteError = IResponseErrorPayload & StoreTasksDeleteType;
