import React from 'react';
import { CellProps } from 'react-table';
import { Box, styled, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    fontColorMap,
    storeLevelIconMap,
    IHasCompletionState,
    ISkeleton,
    textMap,
    textMapDisabledOverdue, fontColorMapDisabled
} from 'modules/task/taskList/model';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../../../utils/featureFlags';

type CompletionsStatusCellProps = ISkeleton & IHasCompletionState;

const StyledText = styled(Typography)({
    font: `'Inter', 'Arial', sans-serif`,
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.15px',
});

export const CompletionStatusCell: React.FC<CellProps<CompletionsStatusCellProps, any>> = ({ row }) => {
    const { isSkeleton, completionState } = row.original;
    const isOverdueColumnsFeature = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isOverdueEnabled = isOverdueColumnsFeature?.enabled ?? false;
    const mapSrc = isOverdueEnabled ? textMap : textMapDisabledOverdue;
    const mapFont = isOverdueEnabled ? fontColorMap : fontColorMapDisabled;
    const text = mapSrc[completionState || DisplayedTaskStatus.Default];
    const color = mapFont[completionState || DisplayedTaskStatus.Default];
    const Icon = storeLevelIconMap[completionState || DisplayedTaskStatus.Default];

    if (isSkeleton) {
        return (
            <Skeleton width="75px" height="20px"/>
        );
    }

    return (
        <Box
            color={color}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
        >
            <Icon/>
            <Box pr="13px"/>
            <StyledText>
                {text}
            </StyledText>
        </Box>
    );
};
