import { combineReducers } from "redux";
import { OPEN_SIDE_OUT_TASKS_BULK, SET_TASK_BULK_TO_REMOVE_ID, TasksBulkActions } from "./tasksBulkActions";
import { TasksBulkApiActions, requestDeleteTaskBulk, requestTasksBulkHistory } from "api/task/tasksBulk/tasksBulkActions";
import { TasksBulk } from "api/task/tasksBulk/tasksBulkModel";

const initialState = {
    openSideBarTasksBulk: false,
    tasksBulkHistory: [],
    tasksBulkHistoryFetching: false,
    taskBulkToRemove: null,
    taskBulkToRemoveFetching: false,
};

function openSideBarTasksBulk(
    state: boolean = initialState.openSideBarTasksBulk,
    action: TasksBulkActions,
): boolean {
    if(action.type === OPEN_SIDE_OUT_TASKS_BULK){
        return action.payload;
    }
    return state;
}

function tasksBulkHistory(
    state: TasksBulk = initialState.tasksBulkHistory,
    action: TasksBulkApiActions,
): TasksBulk {
    switch (action.type) {
        case requestTasksBulkHistory.successType:
            return action.payload;
        case requestTasksBulkHistory.initType:
        case requestTasksBulkHistory.errorType:
            return [];
        default:
            return state;
    }
}

function tasksBulkHistoryFetching(
    state: boolean = initialState.tasksBulkHistoryFetching,
    action: TasksBulkApiActions,
): boolean {
    switch (action.type) {
        case requestTasksBulkHistory.successType:
        case requestTasksBulkHistory.errorType:
            return false;
        case requestTasksBulkHistory.initType:
            return true;
        default:
            return state;
    }
}
export function taskBulkToRemove(
    state = initialState.taskBulkToRemove,
    action: TasksBulkActions | TasksBulkApiActions,
): string | null {
    switch (action.type) {
        case SET_TASK_BULK_TO_REMOVE_ID:
            return action.payload;
        case requestDeleteTaskBulk.successType:
            return null;
        default:
            return state;
    }
}

function taskBulkToRemoveFetching(
    state = initialState.taskBulkToRemoveFetching,
    action: TasksBulkApiActions
): boolean {
    switch (action.type) {
        case requestDeleteTaskBulk.initType:
            return true;
        case requestDeleteTaskBulk.errorType:
        case requestDeleteTaskBulk.successType:
            return false;
        default:
            return state;
    }
}

export const tasksBulkReducer = combineReducers({
    openSideBarTasksBulk,
    tasksBulkHistory,
    tasksBulkHistoryFetching,
    taskBulkToRemove,
    taskBulkToRemoveFetching
});
