import { UserListFilter } from './model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { selectUser } from '../../../store/modules/auth/authSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocations } from '../../../store/modules/hierarchy/hierarchySelectors';
import { Formik } from 'formik';
import { MultiSelect } from '../../../components/basicInputs/selectInput/MultiSelect';
import { Location } from '../../../api/hierarchy/hierarchyModel';
import { LocationItem } from './LocationItem';
import { OptionsDropdown } from '../../shared/components/OptionsDropdown';
import { selectAllCompanies } from '../../../store/modules/company/companySelector';
import { requestHierarchy } from '../../../api/hierarchy/hierarchyActions';
import { makeStyles } from '@material-ui/core/styles';
import { BlueColor, Breakpoints } from '../../../utils/styles/constants';
import { UserRoleFilterPanel } from './UserRoleFilterSubpanel';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export type CompanyLocationFilterPanelProps = {
    filter: UserListFilter;
    onFilterChange: (filter: UserListFilter) => void;
}

const useActiveFilterStyles = makeStyles((theme: Theme) => ({
    filterCompanies: {
        minWidth: '240px',
        marginRight: '5px',
    },
    filterCompaniesMobile: {
        width: '25vw',
        marginRight: '0px',
    },
    companyEnvelope: {
        flexFlow: 'column',
        display: 'flex',
    },
    locationsEnvelope: {
        flex: '1',
    },
    locationsEnvelopeMobile: {
        flex: '1',
        marginRight: '5px',
    },
    rowEnvelope: {
        flexFlow: 'row',
        display: 'flex',
        minWidth: '40vw',
    },
    rowEnvelopeMobile: {
        flexFlow: 'row-reverse',
        justifyContent: 'space-between',
        display: 'flex',
        minWidth: '40vw',
    },
    topEnvelope: {
        display: 'flex',
    },
    topEnvelopeMobile: {
        display: 'flex',
        flexFlow: 'column',
    },
    label: {
        color: BlueColor.Dark,
        marginBottom: '2px',
    },
}));

export const CompanyLocationFilterPanel = ({ filter, onFilterChange }: CompanyLocationFilterPanelProps) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectUser);
    const isSuperAdmin = currentUser?.isSuperAdmin ?? false;
    const classes = useActiveFilterStyles([]);
    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );
    const envelopeStyle = useMemo(() => {
        return isMobile ? classes.rowEnvelopeMobile : classes.rowEnvelope;
    }, [isMobile, classes]);
    const topEnvelopeStyle = useMemo(() => {
        return isMobile ? classes.topEnvelopeMobile : classes.topEnvelope;
    }, [isMobile, classes]);
    const filterCompanieStyle = useMemo(() => {
        return isMobile ? classes.filterCompaniesMobile : classes.filterCompanies;
    }, [isMobile, classes]);
    const locationsEnvelopeStyle = useMemo(() => {
        return isMobile ? classes.locationsEnvelopeMobile : classes.locationsEnvelope;
    }, [isMobile, classes])
    const locations = useSelector(selectLocations);
    const [locationFilter, setLocationFilter] = useState(filter.CompanyLocationIds);
    const [companyFilter, setCompanyFilter] = useState(currentUser?.companyId ?? '');
    const initialValues = {location_ids: locationFilter || [], company_id: null};
    const companiesAll = useSelector(selectAllCompanies);

    const options = useMemo(() => companiesAll.map(x => { return { value: x.id, title: x.name }; }), [companiesAll]);
    const companyLocations = useMemo(() => locations.filter(x => x.companyId === companyFilter),
        [locations, companyFilter]);
    const companyId = useMemo(() => { return currentUser?.companyId ?? '';}, [currentUser]);
    useEffect(() => {
        if (isSuperAdmin) {
            dispatch(requestHierarchy.init(companyId));
            if (companyId) {
                onFilterChange({
                    CompanyId: companyId ?? '',
                });
            }
        } else {
            onFilterChange({
                Force: true,
            });
        }
    }, [dispatch, companyId, onFilterChange, isSuperAdmin]);
    const companySelectedHandler = useCallback((val: string | undefined) => {
        if (val) {
            dispatch(requestHierarchy.init(val));
            setLocationFilter(null);
            setCompanyFilter(val ?? '');
            onFilterChange({
                ...filter,
                CompanyId: val,
            });
        }
    }, [setLocationFilter, setCompanyFilter, onFilterChange, filter, dispatch]);

    const locationsSelectedHandler = useCallback((values) => {
        if (isSuperAdmin && !(filter?.CompanyId ?? false)) {
            return;
        }
        setLocationFilter(values.location_ids);
        onFilterChange({
            ...filter,
            CompanyLocationIds: values.location_ids || null,
        });
    }, [setLocationFilter, onFilterChange, filter, isSuperAdmin]);

    return (
        <Box className={topEnvelopeStyle}>
            {isSuperAdmin && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={() => {}}
                    validateOnChange={true}
                    validate={(values) => {
                        locationsSelectedHandler(values);
                    }}
                >
                    <form onSubmit={() => {}} className={envelopeStyle}>
                        <Box className={classes.companyEnvelope}>
                            <label
                                className={classes.label}
                                htmlFor="location_ids"><Trans>Company</Trans></label>
                            <OptionsDropdown
                                options={options}
                                onValueChange={(val) => companySelectedHandler(val)}
                                unsetValue={'2'}
                                value={companyFilter}
                                className={filterCompanieStyle}
                            />
                        </Box>
                        <Box className={locationsEnvelopeStyle}>
                            <MultiSelect
                                name={'location_ids'}
                                label={`${t('Location')}`}
                                options={companyLocations}
                                getName={(x) => { return (x as Location).name; }}
                                RenderPreview={LocationItem}
                                RenderValue={LocationItem}
                            />
                        </Box>
                    </form>
                </Formik>
            )}
            {!isSuperAdmin && (
                <Formik
                    initialValues={initialValues}
                    onSubmit={() => {}}
                    validateOnChange={true}
                    validate={(values) => {
                        locationsSelectedHandler(values);
                    }}
                >
                    <form onSubmit={() => {}} className={classes.rowEnvelope}>
                        <Box width={'100%'}>
                            <MultiSelect
                                name={'location_ids'}
                                label={`${t('Location')}`}
                                options={locations}
                                getName={(x) => { return (x as Location).name; }}
                                RenderPreview={LocationItem}
                                RenderValue={LocationItem}
                            />
                        </Box>
                    </form>
                </Formik>
            )}
            <UserRoleFilterPanel
                filter={filter}
                onFilterChange={onFilterChange}
            />
        </Box>
    )
}
