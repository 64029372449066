import { useMemo } from 'react';
import { hasVerticalScrollbar } from 'utils/helpers/domHelpers';
import { SCROLLBAR_WIDTH } from '../components/gridHelpers';

export function usePaddingScrollFix(scrollContainer: HTMLDivElement | null) {
    return useMemo(() => {
        if (scrollContainer && hasVerticalScrollbar(scrollContainer)) {
            return {
                style: {
                    paddingRight: `${SCROLLBAR_WIDTH}px`,
                },
            };
        }
        return {};
    }, [scrollContainer]);
}
