import React, { ReactNode } from 'react';
import {
    Box, Divider, Drawer, IconButton, styled, Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Breakpoints, UtilColors } from 'utils/styles/constants';
import { makeStyles, Theme } from '@material-ui/core/styles';

export type TaskSlideoutProps = {
    open: boolean;
    onClose: () => void;
    header: ReactNode;
    flex?: boolean;
    hasDisabledContainer?: boolean,
    textDisabledContainer?: string | ReactNode;
    minWidthSideout?: number | string;
}
const StyledContainerDisabled = styled(Box)(({ theme }) => ({
    zIndex: 1,
    backgroundColor: UtilColors.TextDisabled,
    opacity: '0.7',
    width: theme.spacing(114.5),
    maxWidth: '100vw',
    height: '100%',
    position: 'absolute',
    left: -theme.spacing(4),
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        left: 0,
        width: '100vw',
    },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    height: '100vh',
    padding: theme.spacing(3, 4),
    position: 'relative',

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: theme.spacing(1, 0),
    },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(3.5),
    left: theme.spacing(1.5),
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: 0,

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        top: theme.spacing(1.5),
        left: theme.spacing(1),
    },
}));

const StyledContentContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    position: 'relative',

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: theme.spacing(2),
    },
}));

const StyledHeaderContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 0, 1.5, 2.5),

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        padding: theme.spacing(0, 1, 1.5, 4),
    },
}));

const useTaskSidebarStyles = makeStyles((theme: Theme) => ({
    sidebarPaper: {
        width: (props: { minWidthSideout?: number | string}) => props.minWidthSideout ? props.minWidthSideout : theme.spacing(117),
        maxWidth: '100vw',

        [theme.breakpoints.down(Breakpoints.Tablet)]: {
            minWidth: '100vw',
            width: '100vw',
        },
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    flexContent: {
        flex: '1 1 auto',
    },
}));

export const TaskSlideout: React.FC<TaskSlideoutProps> = ({
    open,
    onClose,
    children,
    header,
    flex,
    hasDisabledContainer,
    textDisabledContainer,
    minWidthSideout
}) => {
    const classes = useTaskSidebarStyles({minWidthSideout});

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            classes={{ paper: classes.sidebarPaper }}
        >
            <StyledContainer className={flex ? classes.flexContainer : undefined}>
                <StyledIconButton onClick={onClose}>
                    <ArrowBack />
                </StyledIconButton>

                <StyledHeaderContainer>
                    {header}
                </StyledHeaderContainer>

                <Divider />

                <StyledContentContainer className={flex ? classes.flexContent : undefined}>
                    {hasDisabledContainer &&
                    <StyledContainerDisabled> 
                        <Typography variant='h4'>{textDisabledContainer}</Typography>
                    </StyledContainerDisabled>
                    }
                    {children}
                </StyledContentContainer>
            </StyledContainer>
        </Drawer>
    );
};
