import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    IconButton,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useTopMenuStyles } from 'modules/root/TopMenu/TopMenuStyles';
import { getFilesConfig } from 'store/modules/files/filesSelectors';
import { selectUserCompany } from 'store/modules/company/companySelector';
import { Breakpoints } from 'utils/styles/constants';
import { useActions } from "utils/store/useActions";
import { appSetLeftMenuOpened } from 'store/modules/appState/appStateActions';
import { ConnectNotificationsCounter } from '../LeftMenu/badgeWrappers';
import { getLogoUrl } from 'utils/helpers/url';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { Routes } from 'components/router/model';

export function TopMenu() {
    const classes = useTopMenuStyles([]);
    const filesConfig = useSelector(getFilesConfig);
    const company = useSelector(selectUserCompany);
    const actions = useActions({
        setLeftMenuOpened: appSetLeftMenuOpened,
    });
    const openLeftMenu = useCallback(() => {
        actions.current.setLeftMenuOpened(true);
    }, [actions]);

    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(Breakpoints.MobileLarge),
        { noSsr: true },
    );

    const isDesktop = useMediaQuery(
        (theme: Theme) => theme.breakpoints.up(Breakpoints.Tablet),
        { noSsr: true },
    );

    const companyLogoLink = useMemo(() => getLogoUrl(filesConfig, company), [company, filesConfig]);

    if (isDesktop) {
        return null;
    }

    return (
        <Box className={classes.root}>
            {isMobile && (
                <ConnectNotificationsCounter>
                    <IconButton size="medium" onClick={openLeftMenu}>
                        <MenuIcon className={classes.iconColor}/>
                    </IconButton>
                </ConnectNotificationsCounter>
            )}

            <Box className={classes.companyImageContainer}>
                {companyLogoLink && (
                    <Link
                        to={{
                            pathname: generatePath(Routes.Welcome),
                        }}
                    >
                        <img className={classes.companyImage} src={companyLogoLink} alt="company logo"/>
                    </Link>
                )}
            </Box>

            <Box width="48px" height="48px"/>
        </Box>
    );
}
