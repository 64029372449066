import React, { PropsWithChildren } from 'react'
import { EventProps } from 'react-big-calendar';
import { IEventCalendar } from './model';
import { Box, Theme, Typography, makeStyles, useTheme } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { ActionsCellCalendar } from './ActionsCellCalendar';
import { Breakpoints } from 'utils/styles/constants';

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ backgroundColor }: { backgroundColor: string }) => ({
      backgroundColor: backgroundColor,
      borderRadius: 4,
      position: 'relative'
  }),
  box: {
    padding: '2px 5px',
    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
      padding: '0px 5px', 
    }
  }
}));

export const EventCalendar = (props: PropsWithChildren<EventProps<IEventCalendar>>) => {
  const theme = useTheme();
  const backgroundColor = props.event?.tag ? props.event.tag.color : theme.palette.primary.dark;
  const colorText = theme.palette.getContrastText(backgroundColor);
  const classes = useStyles({ backgroundColor });

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.container}>
      <Box display="flex" alignItems="center" className={classes.box}>
        { props.event.storeTasksCount > 0 &&
          <Box mr={.5} display="flex" alignItems="center">
            <LocationOnIcon fontSize='small' style={{marginRight: 2, color: colorText}}/>
            <Typography style={{color: colorText, fontSize: 14, lineHeight: 1.43}}>{props.event.storeTasksCount}</Typography>
            <Box ml={.5} style={{color: colorText}}>|</Box>
          </Box>
        }
        <Typography style={{color: colorText, textTransform: 'capitalize'}} variant="body2">{props.title}</Typography>
      </Box>
      <ActionsCellCalendar event={props.event}/>
    </Box>
  );
}
