import { combineReducers } from 'redux';
import { CompaniesResponseItem, CompanyType } from 'api/company/companyModel';
import {
    RequestCompaniesActions,
    requestCompaniesInfo,
    RequestCompanyActions,
    requestCompanyColor,
    requestCompanyInfo,
    requestUpdateCompanyColor,
    requestUpdateCompanyLogo,
} from 'api/company/companyActions';
import { BlueColor } from 'utils/styles/constants';

const initialState = {
    company: null,
    allCompanies: [],
    userCompanyFetching: true,
    userColorFetching: false,
    userCompanyLogoFetching: false,
};

function userCompany(
    state: CompanyType | null = initialState.company, action: RequestCompanyActions,
): CompanyType | null {
    switch (action.type) {
        case requestCompanyInfo.initType:
            return null;
        case requestCompanyInfo.successType:
            return { ...action.payload, ...state };
        case requestCompanyColor.successType:
        case requestUpdateCompanyColor.successType: {
            const { primaryColor, secondaryColor, launchPageCover} = action.payload;
            return {
                ...state as CompanyType,
                primaryColor: !primaryColor ? BlueColor.Dark : primaryColor,
                secondaryColor: !secondaryColor ? BlueColor.Blue50 : secondaryColor,
                launchPageCover: launchPageCover
            };
        }
        case requestUpdateCompanyLogo.successType:
            const { logoId } = action.payload;
            return {
                ...state as CompanyType,
                logoId
            };
        default:
            return state;
    }
}

function companies(
    state: CompaniesResponseItem[] = initialState.allCompanies, action: RequestCompaniesActions,
): CompaniesResponseItem[] {
    switch (action.type) {
        case requestCompaniesInfo.successType:
            return action.payload;
        default:
            return state;
    }
}

function userCompanyFetching(
    state: boolean = initialState.userCompanyFetching, action: RequestCompanyActions,
): boolean {
    switch (action.type) {
        case requestCompanyInfo.initType:
        case requestCompanyColor.initType:
            return true;
        case requestCompanyInfo.successType:
        case requestCompanyInfo.errorType:
        case requestCompanyColor.successType:
        case requestCompanyColor.errorType:
            return false;
        default:
            return state;
    }
}

function userColorFetching(
    state: boolean = initialState.userColorFetching, action: RequestCompanyActions,
): boolean {
    switch (action.type) {
        case requestUpdateCompanyColor.initType:
            return true;
        case requestUpdateCompanyColor.successType:
        case requestUpdateCompanyColor.errorType:
            return false;
        default:
            return state;
    }
}


function userCompanyLogoFetching(
    state: boolean = initialState.userCompanyLogoFetching, action: RequestCompanyActions,
): boolean {
    switch (action.type) {
        case requestUpdateCompanyLogo.initType:
            return true;
        case requestUpdateCompanyLogo.successType:
        case requestUpdateCompanyLogo.errorType:
            return false;
        default:
            return state;
    }
}

export const company = combineReducers({
    userCompany,
    userCompanyFetching,
    companies,
    userColorFetching,
    userCompanyLogoFetching
});
