import { useCallback } from 'react';
import { Box, Button, CircularProgress, Dialog, Theme, Typography, useMediaQuery } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';
import { Formik } from 'formik';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Breakpoints } from 'utils/styles/constants';
import { useButtonStyles } from 'utils/styles/buttons';
import { PendingFile } from 'store/modules/files/filesModel';
import { selectLaunchCoverEditorOpened, selectLaunchCoverFetching } from 'store/modules/launch/launchSelectors';
import { launchCoverAddImage, openLaunchCoverEditor } from 'store/modules/launch/launchActions';
import { useActions } from 'utils/store/useActions';
import { useLaunchCoverStyles } from './styles';
import { ImageEditor } from 'components/CropperImage/ImageEditor';

export interface ILaunchCoverFormData {
    launchCover: string;
}

export const CropperModal = () => {
    const fullscreen = useMediaQuery((t: Theme) => t.breakpoints.down(Breakpoints.MobileLarge));
    const actions = useActions({ openModal: openLaunchCoverEditor, addImage: launchCoverAddImage});
    const isOpen = useSelector(selectLaunchCoverEditorOpened);
    const isUploadingLaunchCoverImg = useSelector(selectLaunchCoverFetching);
    const buttonClasses = useButtonStyles();
    const classes = useLaunchCoverStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');
    const isInProgress = isUploadingLaunchCoverImg;

    const handleCancel = useCallback(() => {
        actions.current.openModal(false);
    }, [actions])

    const handleSave = useCallback(async (data: ILaunchCoverFormData) => {
        const binaryImage = await fetch(data.launchCover);
        const blob = await binaryImage.blob();
        const file = new File([blob], 'avatar.jpeg', {type: 'image/jpeg'});
        const pendingFile = {
            localFile: file,
            localKey: '',
            title: 'avatar.jpeg',
        } as PendingFile;
        actions.current.addImage(pendingFile)
    }, [actions]);

    return (
        <Dialog
            open={isOpen}
            onClose={() => {}}
            fullScreen={fullscreen}
            maxWidth="lg"
        >
            <Box className={classes.dialogBox}>
                <Box margin={3}>
                    <Typography
                        variant="h3"
                        className={classes.modalCropperTitle}
                    >
                        <Trans>Replace Cover Photo</Trans>
                    </Typography>
                </Box>
                <Formik
                    initialValues={{launchCover: ''}}
                    onSubmit={handleSave}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Box className={classes.boxInner1}>
                                <Box>
                                    <ImageEditor 
                                        name={`${t('launchCover')}`}
                                        initialCrop={{ unit: '%', width: 100, aspect: 4 }}
                                        textPreview={<Trans>Launch cover image Preview</Trans>}
                                        previewStyles={{
                                            border: '1px solid black',
                                            width: '100%',
                                            height: '220px',
                                        }}
                                        acceptFile={['.png', '.jpg', '.jpeg']}
                                        isCenterBox={true}
                                    />
                                </Box>
                                <Box display="flex" alignItems="center" flexWrap="wrap">
                                    <Typography
                                        variant="body2"
                                        style={{marginLeft: 24}}
                                    >
                                        <Trans>Recommended Aspect Ratio</Trans>: 10 x 1
                                    </Typography>
                                    <Box marginLeft="auto">
                                        <Box margin={3}>
                                            <Button
                                                className={buttonClasses.muiRoundedButton}
                                                onClick={handleCancel}
                                                disabled={isInProgress}
                                            ><Trans>Cancel</Trans></Button>
                                            {isInProgress && (
                                                <CircularProgress className={classes.circular} />
                                            )}
                                            {!isInProgress && (
                                                <Button
                                                    className={saveButtonClasses}
                                                    type={'submit'}
                                                    style={{ marginLeft: '15px' }}
                                                    disabled={isInProgress || !props.isValid || !props.dirty}
                                                ><Trans>Save</Trans></Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>
    );
}
