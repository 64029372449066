import React, { useEffect, useCallback, useMemo } from 'react'
import { Box, Typography } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { selectUsersByIds } from "store/modules/user/userSelectors";
import { useActions } from "utils/store/useActions";
import { useTaskFilterStyles } from '../../taskFilterStyles';
import { creatorFilterSchema } from './validation';
import { requestGetTasksFilterCreators } from 'api/task/taskFilter/taskFilterActions';
import { selectTasksCreatorsFilter, selectTasksCreatorsFilterFetching } from 'store/modules/task/taskFilter/taskFilterSelectors';
import { MultiSelect } from 'components/basicInputs/selectInput/MultiSelect';
import { IItemComponent } from 'components/basicInputs/selectInput/model';
import { nonEmpty } from 'utils/helpers/collections';
import { addFilterTaskCreatedBy } from 'store/modules/task/taskFilter/taskFilterActions';

type CreatorType = {id: string, fullName: string};

const CreatorFilterForm: React.FC<FormikProps<any>& { isDisabled: boolean; }> = ({ handleSubmit, isDisabled }) => {
    const creatorsIds: string[] = useSelector(selectTasksCreatorsFilter);
    const userByIds = useSelector(selectUsersByIds);
    const classes = useTaskFilterStyles();
    const users: CreatorType[] = useMemo(()=> creatorsIds.map((id: string) => {
        const user = userByIds[id];
        if(!user) return null;
        return { id: user.id as string, fullName: `${user.firstName } ${user?.lastName}`}
    }).filter(nonEmpty), [creatorsIds, userByIds]);

    return (
        <form onSubmit={handleSubmit} data-testid="edit-creator-form">
            <Box width={'100%'}>
                <MultiSelect
                    name={'createdBy'}
                    label=""
                    placeholder={`${t('Creator')}`}
                    id="createdBy"
                    options={users}
                    getName={(x) => { return x.fullName; }}
                    RenderPreview={CreatorItem}
                    RenderValue={CreatorItem}
                    showValuesSelected={false}
                    separateLabel={false}
                    disabled={isDisabled}
                    classes={{ root: classes.autocompleteCreatorFilterTest }}
                />
            </Box>
        </form>
    )
};

type CreatorFilterprops = {
    onCreatorsValuesChange: (newValues: string[]) => void;
    initialCreatorsSelected?: string[];
};
export const CreatorFilter: React.FC<CreatorFilterprops> = ({ onCreatorsValuesChange, initialCreatorsSelected }) => {
    const classes = useTaskFilterStyles();
    const fetching = useSelector(selectTasksCreatorsFilterFetching);
    const initialFormValues = useMemo(
        () => ({ createdBy: initialCreatorsSelected}),
        [initialCreatorsSelected]
    );
    const actions = useActions({ 
        getCreators: requestGetTasksFilterCreators.init,
        addFilterTaskCreatedBy
    });

    const onSubmit = useCallback((newValue) => {
        actions.current.addFilterTaskCreatedBy(newValue.createdBy);
        onCreatorsValuesChange(newValue.createdBy)
    }, [onCreatorsValuesChange, actions]);

    const validate = (values: any) => {
        onSubmit(values);
        return {};
    }

    useEffect(() => {
        actions.current.getCreators()
    },[actions]);

    return (
        <Box className={classes.autocompleteCreatorFilter}>
        <Formik
            initialValues={initialFormValues}
            validationSchema={creatorFilterSchema}
            onSubmit={onSubmit}
            validate={validate}
            enableReinitialize
        >
            {(props) => <CreatorFilterForm {...props} isDisabled={fetching}/>}
        </Formik>
        </Box>
    );
};

const CreatorItem: React.FC<IItemComponent<CreatorType>> = ({ item: creator }) =>{
    return (
        <Box display="flex" alignItems="center">
            <Typography variant="caption">
                {creator.fullName}
            </Typography>
        </Box>
    );
}
