import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Breakpoints } from 'utils/styles/constants';
import { useButtonStyles } from 'utils/styles/buttons';
import clsx from 'clsx';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export type DialogTemplateProps = {
    open: boolean;
    onContinue?: () => void;
    onCancel: () => void;
    title?: string;
    bodyText?: string;
    hideBodyText?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    dialogActions: {
        padding: theme.spacing(0, 2, 3),
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '& > *': {
            marginLeft: theme.spacing(3),
        },
    },
    fixedButtons: {
        boxSizing: 'border-box',
        width: theme.spacing(13.5),
        height: theme.spacing(5),
        boxShadow: `0px 4px 8px rgba(58, 58, 68, 0.16)`,
        '& > span': {
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'uppercase',
        },
    },
}));

export const DialogTemplate = ({ open, onContinue, onCancel, title = `${t('You have unsaved changes')}`, bodyText = `${t('Are you sure you want to continue?')}`, hideBodyText = false }: DialogTemplateProps) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, classes.fixedButtons, 'primary', 'dark');
    const cancelButtonClasses = clsx(buttonClasses.muiRoundedButton, classes.fixedButtons, 'dark');
    const fullScreen = useMediaBefore(Breakpoints.MobileLarge);

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullScreen={fullScreen}
        >
            <DialogTitle disableTypography>
                <Box>
                    <Typography variant='h5' component="span">
                        {title} 
                    </Typography>
                </Box>
            </DialogTitle>

            { !hideBodyText && 
                <DialogContent>
                    <Typography variant='body2' component="span">
                        {bodyText}
                    </Typography>
                </DialogContent>
            }

            <DialogActions className={classes.dialogActions}>
                <Button className={cancelButtonClasses} onClick={onCancel}><Trans>No</Trans></Button>
                <Button className={saveButtonClasses} onClick={onContinue}><Trans>Yes</Trans></Button>
            </DialogActions>
        </Dialog>
    );
}
