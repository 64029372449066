import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { useActions } from "utils/store/useActions";
import { Trans } from 'react-i18next';
import { selectCommentToRemove, selectCommentToRemoveFetching } from 'store/modules/task/taskConversation/taskConversationSelectors';
import { CancelButton, DeleteButton, DialogDescription, StyledDialogActions, TitleWrapper } from 'modules/task/taskList/views/ParentTasksView/RemoveParentTaskConfirmation';
import { setTaskCommentToRemove } from 'store/modules/task/taskConversation/taskConversationActions';
import { requestDeleteTaskComment } from 'api/task/taskConversation/taskConversationActions';

type RemoveCommentProps = {
    commentId: string;
}

const ModalComment: React.FC<RemoveCommentProps> = ({ commentId }) => {
    const actions = useActions({ setTaskCommentToRemove, deleteTaskComment: requestDeleteTaskComment.init });
    const handleClickNo = useCallback(() => actions.current.setTaskCommentToRemove(null), [actions]);
    const handleClickYes = useCallback(() => {
        actions.current.deleteTaskComment( commentId );
    }, [actions, commentId]);
    const isFetching = useSelector(selectCommentToRemoveFetching);

    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <TitleWrapper>
                    <Trans>Delete</Trans> <strong><Trans>this comment</Trans>?</strong>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <DialogDescription>
                    <Trans>You will not be able to undo this. Do you want to proceed?</Trans>
                </DialogDescription>
            </DialogContent>
            <StyledDialogActions>
                <CancelButton onClick={handleClickNo} disabled={isFetching}><Trans>Cancel</Trans></CancelButton>
                <DeleteButton onClick={handleClickYes} disabled={isFetching}><Trans>Delete</Trans></DeleteButton>
                {isFetching && (
                    <CircularProgress size={'1rem'} />
                )}
            </StyledDialogActions>
        </Dialog>
    );
};

export const RemoveComment: React.FC = () => {
    const commentToRemoveById = useSelector(selectCommentToRemove);

    if (!commentToRemoveById) {
        return null;
    }

    return (
        <ModalComment 
            commentId={commentToRemoveById}
        />
    );
};
