import { CellProps } from "react-table";
import { Notification } from 'api/knock/knockModel';
import { NotificationRowData } from './model';
import React, { ReactNode } from "react";
import { Box, Fade, Tooltip, Typography } from "@material-ui/core";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Trans } from "react-i18next";

export type NotificationTypeCellProps = CellProps<NotificationRowData, Notification>;

const TooltipBase = ({title}: {title: ReactNode}) => (
    <Tooltip title={<>{title}.</>}
        arrow placement='top' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
        <ContactSupportIcon
            color="inherit"
            fontSize="small"
        />
    </Tooltip>
)

const WeeklyTaskReportTooltip = () => (
    <Box display="flex" alignItems="center">
        <Typography variant="body2" style={{marginRight: 4}}><Trans>Weekly Task Report</Trans></Typography>
        <TooltipBase title={<Trans>Sends location-based task report at 8AM local time on Mondays for previous 7 days</Trans>} />
    </Box>
)


const TaskReminderTooltip = () => (
    <Box display="flex" alignItems="center">
        <Typography variant="body2" style={{marginRight: 4}}><Trans>Task Reminder</Trans></Typography>
        <TooltipBase title={<Trans>Sends Task Reminder Notification for Incomplete Tasks 12 & 6 Hours Before Due</Trans>} />
    </Box>
)

const notificationToLabelMap: Record<Notification, string | undefined | React.ReactElement> = {
    [Notification.TaskCreated]: <Trans>Task Created</Trans>,
    [Notification.TaskSubmitted]: <Trans>Task Submitted</Trans>,
    [Notification.TaskApproved]: <Trans>Task Approved</Trans>,
    [Notification.TaskDeclined]: <Trans>Task Declined</Trans>,
    [Notification.UserMentioned]: <Trans>User Mentioned</Trans>,
    [Notification.UserMessaged]: <Trans>User Messaged</Trans>,
    [Notification.FrozenChannel]: <Trans>Frozen Channel</Trans>,
    [Notification.UnreadChannelMessages]: <Trans>Unread Channel Messages</Trans>,
    [Notification.WeeklyTaskReport]: <WeeklyTaskReportTooltip />,
    [Notification.TaskReminder]: <TaskReminderTooltip />,
    [Notification.TaskComment]: <Trans>Task Comment</Trans>,
    [Notification.TaskCommentReaction]: <Trans>Task Comment Reaction</Trans>,
    [Notification.TaskCommentMention]: <Trans>Task Comment Mention</Trans>,
};

export const NotificationTypeCell = ({ value }: NotificationTypeCellProps) => {
    return notificationToLabelMap[value];
};
