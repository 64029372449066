import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PendingFile } from '../../store/modules/files/filesModel';
import React from 'react';

export interface IFilesConfigDTO {
    fileStorageBaseUrl: string;
    companyLogosBaseUrl: string;
    avatarsBaseUrl: string;
    thumbnailsBaseUrl: string;
    maxFileSize: number;
    supportedMimeTypes: Record<string, string[]>;
}

export const fileUploadResultSchema = t.type({
    fileId: t.string,
    hasThumbnail: t.boolean,
});

export const fileStorageTokenDTOSchema = t.type({
    token: t.string,
    expireAt: t.number,
});

export type FileStorageTokenDTO = t.TypeOf<typeof fileStorageTokenDTOSchema>;

export const batchFileUploadResultSchema = t.array(fileUploadResultSchema);

export const supportedMimeTypesType = t.record(
    t.string,
    t.array(t.string),
);

export const supportedFileTypesType = tPromise.createType(
    (encoded): { supportedFileTypes: string[] } => {
        const { supportedMimeTypes } = encoded as IFilesConfigDTO;
        const fileTypes: string[] = [];

        Object.keys(supportedMimeTypes).forEach(mimeType => fileTypes.push(...supportedMimeTypes[mimeType]));

        return { supportedFileTypes: Array.from(new Set(fileTypes)) };
    },
    () => undefined,
);

export const fileConfigSchema = t.intersection([
    t.type({
        maxFileSize: t.number,
        fileStorageBaseUrl: t.string,
        companyLogosBaseUrl: t.string,
        avatarsBaseUrl: t.string,
        thumbnailsBaseUrl: t.string,
        supportedMimeTypes: supportedMimeTypesType,
    }),
    supportedFileTypesType,
]);

export type IFilesConfig = t.TypeOf<typeof fileConfigSchema>;
export type FileUploadResult = t.TypeOf<typeof fileUploadResultSchema>;

export type FileDownloadRequest = {
    fileId: string;
    fileName: string;
}

export type FileDownloadResponse = FileDownloadRequest & {
    content: ArrayBuffer;
}

export type UserImportRequestModel = {
    localFiles: Array<PendingFile>;
    emailSend: boolean;
    bulkTypeAction: IUserBulkAction['type'];
}

export interface IUserBulkAction {
    type: 'Deactivate' | 'Import';
    templateUrl: string;
    label: React.ReactNode;
}

export enum UserBulkAction {
    Deactivate = 'Deactivate',
    Import = 'Import',
    DeactivatePath = 'deactivate',
    ImportPath = 'import',
    DeactivateBulkPath = 'deactivate-bulk',
    ImportTemplate = 'BulkUserImportTemplate.csv',
    DeactivateTemplate = 'BulkUserDeactivateTemplate.csv'
}

export type requestFileByFileStorageResponse = {
    fileId: string,
    objectURL: string
}