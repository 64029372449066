import { environmentName } from 'utils/globals';
import { Environment } from 'model/environment';
import { createSingleAction } from 'utils/store/actionUtils';
import { IResponseErrorPayload } from 'model/error';

export const requestActionsPrefix = 'request';
export const responseActionsPrefix = 'response';

export function createApiActions<RequestType,
    SuccessType,
    ErrorType extends IResponseErrorPayload,
    RequestActionType extends string,
    SuccessActionType extends string,
    ErrorActionType extends string,
>(
    requestType: RequestActionType,
    successType: SuccessActionType,
    errorType: ErrorActionType,
) {

    if (environmentName === Environment.Local) {
        if (!successType.startsWith('response')) {
            throw new Error(`Action ${successType} is success api action but doesn't starts with 'response'`);
        }

        if (!errorType.startsWith('response')) {
            throw new Error(`Action ${errorType} is success api action but doesn't starts with 'response'`);

        }

        if (!successType.endsWith('_SUCCESS')) {
            throw new Error(`Action ${successType} is success api action but doesn't ends with '_SUCCESS'`);
        }

        if (!errorType.endsWith('_ERROR')) {
            throw new Error(`Action ${errorType} is error api action but doesn't ends with _ERROR`);
        }
    }

    return {
        init: createSingleAction<RequestType, RequestActionType>(requestType),
        initType: requestType,
        success: createSingleAction<SuccessType, SuccessActionType>(successType),
        successType: successType,
        error: createSingleAction<ErrorType, ErrorActionType>(errorType),
        errorType: errorType,
    };
}

export function createApiActionsByName<
    RequestType,
    SuccessType,
    ErrorType extends IResponseErrorPayload,
    ActionName extends string,
>(actionName: ActionName) {
    const requestAction = `${requestActionsPrefix}/${actionName}` as const;
    const responseSuccessAction = `${responseActionsPrefix}/${actionName}_SUCCESS` as const;
    const responseErrorAction = `${responseActionsPrefix}/${actionName}_ERROR` as const;

    return createApiActions<RequestType, SuccessType, ErrorType,
        typeof requestAction, typeof responseSuccessAction, typeof responseErrorAction
    >(
        requestAction, responseSuccessAction, responseErrorAction,
    );
}
