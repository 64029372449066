import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { IBaseInputClasses } from 'components/basicInputs/model';
import { TooltipLabel } from './tooltipLabel/TooltipLabel';

export interface IBaseInputProps {
    classes?: IBaseInputClasses;
    id?: string;
    label: string | React.ReactNode;
    children: React.ReactNode,
    separateLabel?: boolean,
    showTooltipLabel?: boolean,
    tooltipTitle?: string
}

export function BaseInput({
    id, label, children, separateLabel = true, classes: { label: labelClass = '', root: rootClass = '' } = {}, showTooltipLabel = false, tooltipTitle
}: IBaseInputProps) {
    const classes = useInputsStyles([]);

    return (
        <Box className={clsx(classes.root, rootClass)}>
            {separateLabel && (
                <TooltipLabel label={label} showTooltipLabel={showTooltipLabel} tooltipTitle={tooltipTitle}>
                    <label className={clsx(classes.label, labelClass)} htmlFor={id}>
                        {label}
                    </label>
                </TooltipLabel>
            )}
            {children}
        </Box>
    );
}
