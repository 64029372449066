import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { NavButton } from './NavButton';

export type BackButtonProps = {
    route?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ route, children }) => {
    const history = useHistory();

    return (
        <NavButton route={route} onClick={history.goBack}>
            <ChevronLeft />
            <Typography>
                {children}
            </Typography>
        </NavButton>
    );
}
