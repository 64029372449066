import { useCallback, useMemo } from "react";
import { CellProps } from "react-table";
import { Box, IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Location } from "api/hierarchy/hierarchyModel";
import { useSelector } from "react-redux";
import { useActions } from "utils/store/useActions";
import { selectParentTaskDetailsLoading, selectStoreTasksDeleteLoading } from "store/modules/task/taskListComponent/taskListComponentSelectors";
import { ChildTaskWithNested } from "../TaskByLocationsView";
import { setParentTaskToRemove } from "store/modules/task/parentTask/parentTaskActions";
import { setStoreTaskToRemove } from "store/modules/task/taskListComponent/taskListComponentActions";

export type TaskCounterCellProps = CellProps<Location, string>;

export const TaskStoreDeleteCell = ({ row }: CellProps<ChildTaskWithNested>) => {
    const { original: task } = row;
    const actionStoreTasksDelete = useActions({ 
        setParentTaskToRemove,
        setStoreTaskToRemove,
    });
    const isTaskLoading = useSelector(selectParentTaskDetailsLoading)[task.id];
    const isStoreTasksDeleteLoading = useSelector(selectStoreTasksDeleteLoading)[task.id];

    const onDeleteLocation = useCallback(() => {
        actionStoreTasksDelete.current.setStoreTaskToRemove(task.id);
    }, [task, actionStoreTasksDelete])

    const status = useMemo(()=>
        Reflect.has(task, 'completed')
            ? <></>
            : <IconButton aria-label="delete" onClick={onDeleteLocation}><DeleteOutlineIcon/> </IconButton>
    ,[task, onDeleteLocation]);

    if (task.isSkeleton || isTaskLoading || isStoreTasksDeleteLoading) {
        return <Skeleton width={50} />;
    }

    return (
        <Box>
            {status}
        </Box>
    );
};
