import React, { useCallback, useMemo, useEffect } from 'react';
import { AddTaskForm } from 'modules/task/addTask/AddTaskForm';
import { Formik } from 'formik';
import * as yup from 'yup';
import { addTaskSchema, createLocationSchema, createTaskToPayload, ICreateTask } from 'modules/task/addTask/model';
import { transformFormValues } from 'utils/helpers/formSubmit';
import { useActions } from "utils/store/useActions";
import { requestCreateTask } from 'api/task/taskActions';
import { useSelector } from 'react-redux';
import { getPendingFiles, getUploadedFiles } from 'store/modules/files/filesSelectors';
import { selectHierarchy } from 'store/modules/hierarchy/hierarchySelectors';
import { TASK_DATE_FORMAT } from '../common/form/validation';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { Routes } from 'components/router/model';
import { generatePath } from 'react-router';
import { formatDateCustom } from 'utils/helpers/dateHelper';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import { requestUserList } from 'api/user/userActions';
import { selectSelfId } from 'store/modules/auth/authSelectors';
import { requestCreateTaskTemplate, requestUpdateTaskTemplate } from 'api/task/taskTemplate/taskTemplateActions';
import { selectTaskTemplate } from 'store/modules/task/taskTemplate/taskTemplateSelectors';
import { useQueryParams } from 'utils/hooks';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { ModalToEditTemplate } from '../common/taskstemplate/ModalToEditTemplate';

function validateForms(pendingFilesCount: number) {
    if (pendingFilesCount > 0) {
        return { attachments: `${i18n.t('To attach files please click on Upload button')}` };
    }
    return {};
}

export function AddTaskPage() {
    const isPublishDateEnabled = useFeatureState(FeatureFlags.TaskCreatePublishDate);
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const uploadedFiles = useSelector(getUploadedFiles);
    const pendingFiles = useSelector(getPendingFiles);
    const hierarchy = useSelector(selectHierarchy);
    const selfId = useSelector(selectSelfId);
    const taskTemplate = useSelector(selectTaskTemplate);
    const initialFormValues = useMemo(() => {
        let valueToInitForm = {};
        if(Object.keys(taskTemplate).length > 0){
            valueToInitForm = {
                ...taskTemplate,
                publishDate: formatDateCustom(new Date(), TASK_DATE_FORMAT),
                dueDate: formatDateCustom(new Date(), TASK_DATE_FORMAT),
            }
        }else{
            valueToInitForm = {
                title: '',
                description: '',
                subtasks: [],
                publishDate: formatDateCustom(new Date(), TASK_DATE_FORMAT),
                isExpirable: false,
                recurrence: undefined,
                dueDate: '',
                locations: [],
                attachments: [],
                watchers: [],
                label: '',
                tag: null,
                createdBy: selfId ?? '',
                locationsFromTemplate: [],
                sendReminder: false
            }
        }
        return valueToInitForm as ICreateTask;
    }, [selfId, taskTemplate]);
    const { isEditTemplate, id: idTemplate } = useQueryParams<{ isEditTemplate: string, id: string }>();

    const actions = useActions({
        requestCreateTask: requestCreateTask.init,
        requestCreateTaskTemplate: requestCreateTaskTemplate.init,
        requestUpdateTaskTemplate: requestUpdateTaskTemplate.init,
    });
    
    const actionsUsers = useActions({
        getUsers: requestUserList.init,
    });
    useEffect(() => {
        actionsUsers.current.getUsers({"Force": true});
    }, [actionsUsers, isTagEnabled]);
    
    
    const onSubmit = useCallback((values: ICreateTask) => {
        transformFormValues(values);
        
        if (uploadedFiles.length > 0) {
            values.attachments = [...uploadedFiles];
        }
        if(isEditTemplate && idTemplate){
            actions.current.requestUpdateTaskTemplate({...createTaskToPayload(values, true, idTemplate), id: idTemplate });
        }else if(values.isNewTemplate){
            actions.current.requestCreateTaskTemplate(createTaskToPayload(values, true));
        }else{
            actions.current.requestCreateTask(createTaskToPayload(values));
        }
    }, [actions, uploadedFiles, isEditTemplate, idTemplate]);

    const fullSchema = useMemo(() => {
        const locationSchema = createLocationSchema(hierarchy);
        const publishDateSchema = isPublishDateEnabled ?
            {
                publishDate: addTaskSchema.publishDate
                    .min(new Date(), `${t('Publish Date could not be earlier than today')}`),
                dueDate: addTaskSchema.dueDate
                    .min(yup.ref('publishDate'), `${t('Due Date field must be not earlier than Publish Date')}`),
            }
            : null;

        return yup.object({
            ...addTaskSchema,
            ...locationSchema,
            ...publishDateSchema,
        });
    }, [hierarchy, isPublishDateEnabled]);

    return (
        <FormPageView
            actionBar={<BackButton route={generatePath(Routes.TaskList)}><Trans>Back to Tasks</Trans></BackButton>}
        >
            <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                validationSchema={fullSchema}
                validate={() => validateForms(pendingFiles.length)}
                onSubmit={onSubmit}
            >
                {props => <AddTaskForm isEditTemplate={Boolean(isEditTemplate)} {...props} />}
            </Formik>
            <ModalToEditTemplate />
        </FormPageView>
    );
}
