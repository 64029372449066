import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Theme, TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Breakpoints } from 'utils/styles/constants';
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue';
import { selectParentTasksFetching } from 'store/modules/task/parentTask/parentTaskSelectors';
import CloseIcon from '@material-ui/icons/Close';
import { t } from 'i18next';

const useFilterTagStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        display:'flex',
        "& form": {
            display: "flex"
        },
        [theme.breakpoints.up(Breakpoints.Tablet)]: {
            maxWidth: 227,
        },  
    },
}));
type SearchTasksprops = {
    onSearchTaskValuesChange: (newValues: string) => void;
};

export const SearchTasks = ({onSearchTaskValuesChange}: SearchTasksprops) => {
    const classes = useFilterTagStyles([]);
    const [ searchTerm, setSearchTerm ] = useState<string>('');
    const [ canSearch, setCanSearch ] = useState<boolean>(false);
    const debouncedSearchTerm = useDebouncedValue(searchTerm, 1000);
    const parentTasksFetching = useSelector(selectParentTasksFetching);

    useEffect(() => {
        if(debouncedSearchTerm.length > 0 || canSearch){
            onSearchTaskValuesChange(debouncedSearchTerm);
            setCanSearch(true);
        }
    }, [debouncedSearchTerm, onSearchTaskValuesChange, canSearch]);

    const handleInputChange = useCallback((event: any) => {
        setSearchTerm(event.target.value)
    }, []);

    const adornment = useMemo(()=>(
        <>
            {searchTerm.length > 0 ? 
            <IconButton onClick={()=>{setSearchTerm(''); onSearchTaskValuesChange('')}}>
                <CloseIcon color="inherit" fontSize='small' /> 
            </IconButton>: <></>}
        </>
    ),[searchTerm, onSearchTaskValuesChange]);

    return (
        <Box className={classes.container}>
            <TextField
                disabled={parentTasksFetching}
                value={searchTerm}
                onChange={handleInputChange}
                variant="outlined"
                placeholder={`${t('Search task')}`}
                aria-describedby={`${t('search task')}`}
                fullWidth
                InputProps={{
                    endAdornment: adornment,
                }}
            />
        </Box>
    );
}
