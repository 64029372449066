import { call, put, take } from 'typed-redux-saga';
import { Action } from 'redux';
import { requestActionsPrefix } from 'utils/api/apiActionUtils';
import { ActionWithPayload } from 'utils/store/actionUtils';
import { INIT_API_SUCCESS } from 'api/apiActions';
import { createWebWorkerListener, createWorkerConnection, postMessageWebWorker } from 'store/apiCommunication/workerListener';
import { isTest } from 'utils/globals';
import { createAppMessage } from 'api/helpers';

function* webWorkerEmitter() {
    const pendingActions = [];
    if (!isTest) {
        for(;;) {
            const action: ActionWithPayload<any> = yield take(
                ({ type }: Action) => type.startsWith(requestActionsPrefix) || type === INIT_API_SUCCESS,
            );
            if (action.type === INIT_API_SUCCESS) {
                break;
            } else {
                pendingActions.push(action);
            }
        }

        pendingActions.forEach(pendingAction => {
            postMessageWebWorker(createAppMessage(pendingAction));
        });
    }

    for(;;) {
        const requestAction: ActionWithPayload<any> = yield take(
            (action: Action) => action.type.startsWith(requestActionsPrefix),
        );
        postMessageWebWorker(createAppMessage(requestAction));
    }
}

function* webWorkerListener() {
    createWorkerConnection();
    const channel = yield* call(createWebWorkerListener);
    for(;;) {
        const message = yield* take(channel);
        yield put(message);
    }
}

export const apiCommunicationSagas = [
    webWorkerListener,
    webWorkerEmitter,
];
