import React from 'react';
import { formatDateLocalized, shortDateFormat } from 'utils/helpers/dateHelper';
import { IDateTimeDisplay } from './model';
import { StyledDateTimeWrapper } from './styles';

export const DateDisplay: React.FC<IDateTimeDisplay> = ({ dateTime }) => {
    return (
        <StyledDateTimeWrapper textcolor="primary">
            {formatDateLocalized(dateTime, shortDateFormat)}
        </StyledDateTimeWrapper>
    );
};
