import React, { useRef } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
    Box,
    ButtonBase,
    Typography,
    makeStyles,
    Theme,
    Avatar,
} from '@material-ui/core';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { BlueColor, Breakpoints } from 'utils/styles/constants';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { UserMenuDropdown } from './UserMenuDropdown';
import { getUserName } from 'utils/helpers/user';

const useUserMenuStyles = makeStyles((theme: Theme) => ({
    profileDetails: {
        display: 'flex',
        alignItems: 'center',

        '& > * + *': {
            marginLeft: theme.spacing(2),
        },

        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            width: '220px',
            marginTop: '32px',
            marginBottom: '40px',
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
        },
    },
    userName: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            fontFamily: `'Infer', 'Arial', sans-serif`,
            fontWeight: 400,
            fontSize: '16px',
            maxWidth: '134px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: '15px',
        },
    },
    icon: {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
            width: '18px',
            height: '18px',
        },
    },
    avatar: {
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
    avatarInner: {
        backgroundColor: BlueColor.Dark,
        color:'white',
        width: theme.spacing(6),
        textAlign: 'center',
        lineHeight: theme.spacing(6),
    },
}));

export function UserMenu() {
    const classes = useUserMenuStyles();
    const user = useCurrentUser();
    const { isOpened, onOpen, onClose } = useOpenedState();
    const buttonRef = useRef<HTMLButtonElement>(null);

    if (!user) {
        return null;
    }

    return (
        <>
            <Box className={classes.profileDetails}>
                <ButtonBase ref={buttonRef} onClick={onOpen}>
                    <Typography className={classes.userName}>
                        {getUserName(user)}
                    </Typography>
                    {isOpened ? (
                        <ExpandLess className={classes.icon} />
                    ) : (
                        <ExpandMore className={classes.icon} />
                    )}
                </ButtonBase>
                <Avatar className={classes.avatar} src={user?.avatar} >
                    <div className={classes.avatarInner}>
                        {user?.firstName?.substring(0, 1)?.toUpperCase()}{user?.lastName?.substring(0, 1)?.toUpperCase()}
                    </div>
                </Avatar>
            </Box>

            <UserMenuDropdown
                isOpened={isOpened}
                onClose={onClose}
                anchorRef={buttonRef.current}
            />
        </>
    );
}
