import { UserDTO } from '../../../api/user/userModel';
import { CheckedItems, OnCheck } from '../../../utils/hooks/useCheckdItems';
import React from 'react';
import { CheckBoxCellPure, CheckBoxTitlePure, ICheckboxClasses } from 'modules/shared/components/CheckBoxCells';
import { Skeleton } from './withSkeleton';
import { Column } from 'react-table';

export function getUsersCheckboxCell(
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
    classes: ICheckboxClasses,
    isMobile: boolean,
    getId: (row: Skeleton<UserDTO>) => string,
    customClasses: Array<string> = [],
    rowF: (row: Skeleton<UserDTO>) => Skeleton<UserDTO>,
): Column<Skeleton<UserDTO>> {
    return {
        id: 'checkbox',
        Header: function CheckboxCellHeader() {
            return (
                <CheckBoxTitlePure
                    checkedAll={checkedAll}
                    onCheckAll={onCheckAll}
                    classes={classes}
                />
            );
        },
        Cell: function CheckboxCell(row: Skeleton<UserDTO>) {
            return (
                <CheckBoxCellPure
                    customClasses={customClasses}
                    classes={classes}
                    checkedItems={checkedItems}
                    onCheck={onCheck}
                    rowClassName={''}
                    rowId={getId(row)}
                    row={row}
                />
            );
        },
        width: isMobile ? '40px' : '60px',
    }
}
