import { combineReducers } from "redux";
import { COMMENT_ID_MODAL, SET_TASK_COMMENT_TO_REMOVE_ID, SHOW_TEXT_EDITOR, STORE_FILTER_CONVERSATION, SUGGESTIONS, SUGGESTIONS_USERS, TASK_PROTOTYPE_ID_CONVERSATION, TaskConversationActions, UPDATE_REACTION_CONVERSATION } from "./taskConversationActions";
import { TaskConversationApiActions, requestCreateConversation, requestDeleteTaskComment, requestEditComment, requestSuggestionList, requestTaskConversationsByTask } from "api/task/taskConversation/taskConversationActions";
import { ConversationType, StoreTaskConversationType, SuggestionListType } from "api/task/taskConversation/taskConversationModel";
import { UserDTO } from "api/user/userModel";
import { orderBy } from "lodash";
import { ItemsById } from "model/entity";
import { itemsByCustomIds, itemsByIds } from "utils/helpers/itemsByIds";

const initialState = {
    isActiveTextEditor: '',
    taskConversationStore: '',
    taskPrototypeIdConversation: '',
    taskConversationCreating: false,
    commentById: {},
    storeTaskConversationById: {},
    loadingById: {},
    updatingById: {},

    suggestionIds: [],
    suggestions: [],

    commentToRemove: null,
    commentToRemoveFetching: false,
    commentModalActiveId: null,
    commentUpdating: false,
    suggestionsFetching: false
};

function commentById(
    state: ItemsById<ConversationType> = initialState.commentById,
    action: TaskConversationApiActions | TaskConversationActions,
): ItemsById<ConversationType> {
    switch (action.type) {
        case requestTaskConversationsByTask.successType: {
            let comentById: ItemsById<ConversationType> = {};
            for (const conversation of action.payload) {
                comentById = {...comentById, ...itemsByIds(conversation.comments)};
            } 
            return comentById;
        }
        case UPDATE_REACTION_CONVERSATION: 
            return {...state, ...itemsByIds(action.payload.comments)};
        default:
            return state;
    }
}

function storeTaskConversationById(
    state: ItemsById<StoreTaskConversationType> = initialState.storeTaskConversationById,
    action: TaskConversationApiActions | TaskConversationActions,
): ItemsById<StoreTaskConversationType> {
    switch (action.type) {
        case requestTaskConversationsByTask.successType: {
            const locationTaskById: ItemsById<StoreTaskConversationType> = itemsByCustomIds(action.payload, 'locationId');
            for (const id of Object.keys(locationTaskById)) {
                if(locationTaskById[id]?.hasOwnProperty('comments')) {
                    locationTaskById[id]!.comments = orderBy(locationTaskById[id]?.comments, 'createdAt', 'desc')
                }
            }
            return locationTaskById;
        }
        case UPDATE_REACTION_CONVERSATION:{
            const newState = {...state};
            newState[action.payload.locationId]!.comments = action.payload.comments;
            return newState;
        }
        default:
            return state;
    }
}

function taskConversationStore(
    state: string = initialState.taskConversationStore,
    action: TaskConversationActions,
): string {
    if(action.type === STORE_FILTER_CONVERSATION){
        return action.payload;
    }
    return state;
}

function taskPrototypeIdConversation(
    state: string = initialState.taskPrototypeIdConversation,
    action: TaskConversationActions,
): string {
    if(action.type === TASK_PROTOTYPE_ID_CONVERSATION){
        return action.payload;
    }
    return state;
}

function taskConversationCreating(state = initialState.taskConversationCreating, action: TaskConversationApiActions): boolean {
    switch (action.type) {
        case requestCreateConversation.initType:
            return true;
        case requestCreateConversation.errorType:
        case requestCreateConversation.successType:
            return false;
        default:
            return state;
    }
}

function isActiveTextEditor(
    state: string = initialState.isActiveTextEditor,
    action: TaskConversationActions | TaskConversationApiActions
): string {
    switch (action.type) {
        case SHOW_TEXT_EDITOR:
            return action.payload;
        case requestCreateConversation.successType:
            return '';
        default:
            return state;
    }
}

function suggestionIds(
    state: SuggestionListType = initialState.suggestionIds,
    action: TaskConversationApiActions,
): SuggestionListType {
    switch (action.type) {
        case requestSuggestionList.successType:
            return action.payload;
        case requestSuggestionList.initType:
        case requestSuggestionList.errorType:
            return [];
        default:
            return state;
    }
}

function suggestions(
    state: UserDTO[] = initialState.suggestions,
    action: TaskConversationActions,
): UserDTO[] {
    if (action.type === SUGGESTIONS) {
        return action.payload;
    }
    return state;
}

function suggestionsFetching(
    state: boolean = initialState.suggestionsFetching,
    action: TaskConversationActions,
): boolean {
    if(action.type === SUGGESTIONS_USERS){
        return action.payload;
    }
    return state;
}

export function commentToRemove(
    state = initialState.commentToRemove,
    action: TaskConversationActions | TaskConversationApiActions,
): string | null {
    switch (action.type) {
        case SET_TASK_COMMENT_TO_REMOVE_ID:
            return action.payload;
        case requestDeleteTaskComment.successType:
            return null;
        default:
            return state;
    }
}

function commentToRemoveFetching(
    state = initialState.commentToRemoveFetching,
    action: TaskConversationApiActions
): boolean {
    switch (action.type) {
        case requestDeleteTaskComment.initType:
            return true;
        case requestDeleteTaskComment.errorType:
        case requestDeleteTaskComment.successType:
            return false;
        default:
            return state;
    }
}

export function commentModalActiveId(
    state: string | null = initialState.commentModalActiveId, action: TaskConversationActions,
): string | null {
    if (action.type === COMMENT_ID_MODAL){
        return action.payload;
    }
    return state;
}

function commentUpdating(
    state = initialState.commentUpdating,
    action: TaskConversationApiActions
): boolean {
    switch (action.type) {
        case requestEditComment.initType:
            return true;
        case requestEditComment.errorType:
        case requestEditComment.successType:
            return false;
        default:
            return state;
    }
}

export const taskConversationReducer = combineReducers({
    taskConversationStore,
    taskConversationCreating,
    isActiveTextEditor,
    suggestionIds,
    suggestions,
    commentToRemove,
    commentToRemoveFetching,
    commentModalActiveId,
    storeTaskConversationById,
    taskPrototypeIdConversation,
    commentById,
    commentUpdating,
    suggestionsFetching
});
