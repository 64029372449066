import React from 'react';
import { LinearProgressWithLabel } from 'components/userFeedback/LinearProgressWithLabel';
import { TaskSection } from './TaskSection';
import { Trans } from 'react-i18next';

export type TaskPercentageInlineProps = {
    completedItems?: number;
    totalItems?: number;
}

export const TaskPercentageInline: React.FC<TaskPercentageInlineProps> = ({ completedItems = 0, totalItems = 0 }) => {
    const fraction = completedItems / totalItems;
    const percentage = Number.isFinite(fraction) ? fraction * 100 : 0;
    return (
        <TaskSection sectionTitle={<Trans>Checklist Completion</Trans>}>
            <LinearProgressWithLabel value={percentage}/>
        </TaskSection>
    );
};
