import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useTaskFilterStyles } from '../../taskFilterStyles';
import { FlexBox } from 'modules/shared/components/FlexBox';
import { startDownloadDataStatistics } from 'store/modules/task/statistics/statisticsActions';
import { useActions } from 'utils/store/useActions';
import { useSelector } from 'react-redux';
import { selectDownloadingCSVStatistics, selectTasksStatsLoadingByLocationIds } from 'store/modules/task/statistics/statisticsSelectors';
import { FeatureFlags } from 'utils/featureFlags';
import { t } from 'i18next';
import { ItemsById } from 'model/entity';
import { useFeatureState } from 'utils/hooks/useFeatureState';

export const LocationDownloadTable = () => {
    const classes = useTaskFilterStyles();
    const actions = useActions({ startDownloadDataStatistics });
    const isDownloadingCSV = useSelector(selectDownloadingCSVStatistics);
    const isTasksLocationViewDownloadReportEnabled = useFeatureState(FeatureFlags.TasksLocationViewDownloadReport);
    const loadingById: ItemsById<boolean> = useSelector(selectTasksStatsLoadingByLocationIds);
    const isLoadingById = useMemo(() =>  Object.keys(loadingById).every((k) => !loadingById[k]), [loadingById])

    const handleDownloadCSV = useCallback(() => actions.current.startDownloadDataStatistics(),[actions]);

    if(!isTasksLocationViewDownloadReportEnabled) return <></>;

    return (
        <FlexBox className={classes.locationDownloadTableContainer}>
            <Tooltip title={`${t('Download visible task data by location')}`} arrow>
                <Button color="primary" onClick={handleDownloadCSV} variant='outlined' className={classes.locationDownloadTable} disabled={isDownloadingCSV || !isLoadingById}>
                    <GetAppOutlinedIcon />
                    {isDownloadingCSV && <CircularProgress size={16}/>}
                </Button>
            </Tooltip>
        </FlexBox>
    )
}
