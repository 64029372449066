import React, { useMemo } from 'react';
import { useInputsStyles } from 'components/basicInputs/inputsStyles';
import { Trans } from 'react-i18next';

export type LocationsLabelProps = {
    count?: number;
}

export function LocationsLabel({ count }: LocationsLabelProps) {
    const inputClasses = useInputsStyles([]);
    const label = useMemo(() => {
        return count !== undefined
            ? <Trans i18nKey="textToAssignedStores" count={count}>Assigned Stores ({{count}})</Trans>
            : <Trans>Assigned Store</Trans>;
    }, [count]);

    return (
        <label className={inputClasses.label}>
            {label}
        </label>
    );
}
