import { IStoreState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const selectTasksById = (state: IStoreState) => state.task.tasksById;
export const selectTasksOrder = (state: IStoreState) => state.task.tasksOrder;

export function useSelectTasks() {
    const tasksById = useSelector(selectTasksById);
    const tasksOrder = useSelector(selectTasksOrder);

    return useMemo(() => tasksOrder.map(id => tasksById[id]).filter(task => !!task), [tasksById, tasksOrder]);
}

export const selectTaskCreating = (state: IStoreState) => state.task.taskCreating;
export const selectTasksFetching = (state: IStoreState) => state.task.tasksFetching;
export const selectTaskDetailsFetching = (state: IStoreState) => state.task.taskDetailsFetching;
export const selectTaskDetails = (state: IStoreState) => state.task.taskDetails;
export const selectTaskStatusUpdating = (state: IStoreState) => state.task.taskStatusUpdating;
export const selectPendingChecklistItems = (state: IStoreState) => state.task.pendingChecklistItems;
export const selectTaskUpdating = (state: IStoreState) => state.task.taskUpdating;
