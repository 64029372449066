import { IEntity, ItemsById } from 'model/entity';

export function itemsByIds<Input extends IEntity>(array: Array<Input>): ItemsById<Input> {
    return array.reduce((acc, item) => ({
        ...acc,
        [item.id]: item,
    }), {});
}

type StringOnly<T> = {
    [K in keyof T as T[K] extends string ? K : never]: T[K];
};

export function itemsByCustomIds<TEntity extends object>(
    entities: Array<TEntity>,
    fieldName: keyof StringOnly<TEntity>,
): ItemsById<TEntity> {
    const getter = (item: TEntity): string => item[fieldName] as unknown as string;

    return entities.reduce((acc, item) => ({
        ...acc,
        [getter(item)]: item,
    }), {});
}
