import React from 'react';
import { ChatInner } from './ChatInner';
import { useConnect } from '../context/ConnectContext';
import { PageLoader } from 'components/router/PageLoader';
import { MessengerStyles } from './styles';
import { Chat, Streami18n } from 'stream-chat-react';
import i18n from 'i18n';
import { LanguageCode } from 'api/user/userModel';
import { TranslationLanguages } from 'stream-chat';

export type MessengerProps = {
    channelId?: string;
};

export function Messenger({ channelId }: MessengerProps) {
    const { connectService } = useConnect() || {};

    const client = connectService?.clientService?.client ?? null;
    const isClientReady = connectService?.isInitialized;
    const i18nInstance = new Streami18n({ language: (i18n.language ?? LanguageCode.en) as TranslationLanguages });

    return (
        <MessengerStyles>
            {client && isClientReady
                ? (
                    <Chat client={client} theme="connect" i18nInstance={i18nInstance}>
                        <ChatInner channelId={channelId} />
                    </Chat>
                )
                : <PageLoader />}
        </MessengerStyles>
    );
}
