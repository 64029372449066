
import React from 'react';
import { Box, Fade, Tooltip } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

type TooltipLabelProps = {
    label: string | React.ReactNode,
    showTooltipLabel?: boolean,
    children: React.ReactNode,
    tooltipTitle?: string
}

export const TooltipLabel = React.forwardRef<HTMLDivElement, TooltipLabelProps>(({ label, children, showTooltipLabel = false, tooltipTitle = ''}, ref) =>
    (
        <Box display="flex">
            {children}
            {showTooltipLabel && 
                <Tooltip title={tooltipTitle} ref={ref} arrow TransitionComponent={Fade}>
                    <ContactSupportIcon
                        color="inherit"
                        fontSize="small"
                    />
                </Tooltip>
            }
        </Box>
    )
)
