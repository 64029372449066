import { useState, useEffect } from 'react'
import { ParentTaskStatusFilter } from 'api/task/parentTask/parentTaskModel';
import { StoreTaskStatusFilter } from 'api/task/storeTask/storeTaskModel';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';
import { requestUpdateTaskFilter } from 'api/task/taskFilter/taskFilterActions';
import { DefaultSortByParentTask, DefaultSortByStoreTask, DefaultValuesFilter, TasksFilterType, TasksViewModeMyTasks } from 'api/task/taskFilter/taskFilterModel';
import { useSelector } from 'react-redux';
import { selectTaskFilter, selectTasksFilterFetching } from 'store/modules/task/taskFilter/taskFilterSelectors';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';
import { parentTaskListSetSorting, storeTaskListSetSorting } from 'store/modules/task/taskListComponent/taskListComponentActions';
import { taskListInitialSortBy } from '../views/ParentTasksView/grid/columns';
import { initialSortingRules } from '../views/StoreTasksView/grid/columns';

export const useFiltersTasks = (isStore: boolean | null | undefined, isFirstRender: boolean) => {
    const isTagEnabled = useFeatureState(FeatureFlags.TaskTags);
    const isTasksFilterPersistenceEnabled = useFeatureState(FeatureFlags.TasksFilterPersistence);
    const valuesFiltersTasksStore: TasksFilterType = useSelector(selectTaskFilter);
    const defaultTaskFilterFetching = useSelector(selectTasksFilterFetching);
    const actions = useActions({
        updateFilter: requestUpdateTaskFilter.init,
        setSortingParent: parentTaskListSetSorting,
        setSortingStore: storeTaskListSetSorting,
    });
    const [canRendered, setCanRendered] = useState<boolean>(false);
    const [valuesFiltersTasks, setValuesFiltersTasks ] = useState<TasksFilterType>(DefaultValuesFilter);
    
    useEffect(() => {
        if (!defaultTaskFilterFetching && !isFirstRender && !canRendered && isTasksFilterPersistenceEnabled) {
            setValuesFiltersTasks(valuesFiltersTasksStore);
            setCanRendered(true);
        }
    }, [isFirstRender, defaultTaskFilterFetching, canRendered, isTasksFilterPersistenceEnabled, valuesFiltersTasksStore]);

    useEffect(()=>{
        if(!isTasksFilterPersistenceEnabled && !canRendered && !isFirstRender) {
            const sortColums = isStore ? DefaultSortByStoreTask : DefaultSortByParentTask;
            setValuesFiltersTasks({ ...DefaultValuesFilter, sortBy: sortColums });
            actions.current.updateFilter( { taskFilter: { ...DefaultValuesFilter, sortBy: sortColums }, hasFlagEnabled: isTasksFilterPersistenceEnabled } );
            actions.current.setSortingParent(taskListInitialSortBy);
            actions.current.setSortingStore(initialSortingRules[0]);
            setCanRendered(true);
        }
    }, [isTasksFilterPersistenceEnabled, actions, canRendered, isFirstRender, isStore])

    const showFilterTag = isTagEnabled && canRendered;

    const handleSetFilterStatus = (newValue?: ParentTaskStatusFilter | StoreTaskStatusFilter | AutomationTaskStatusFilter) => {
        actions.current.updateFilter( { taskFilter: { ...valuesFiltersTasks, filter: newValue }, hasFlagEnabled: isTasksFilterPersistenceEnabled } );
        setValuesFiltersTasks((prev)=>({...prev, filter: newValue }))
    }

    const handleFilterTag = (newValues: string[]) => {
        if(JSON.stringify(valuesFiltersTasks.tags) !== JSON.stringify(newValues)){
            actions.current.updateFilter( {taskFilter: { ...valuesFiltersTasks, tags: newValues }, hasFlagEnabled: isTasksFilterPersistenceEnabled });
            setValuesFiltersTasks((prev)=>({...prev, tags: newValues }))
        }
    }

    const handleFilterAutomationTasks = (newValue?: string) => {
        let filterStatus = valuesFiltersTasks.filter;
        if(newValue === TasksViewModeMyTasks && valuesFiltersTasks.filter && !Object.values(AutomationTaskStatusFilter).some((v) => v === valuesFiltersTasks.filter)) {
            filterStatus = undefined;
        }
        actions.current.updateFilter( { taskFilter: { ...valuesFiltersTasks, tasksViewMode: newValue, filter: filterStatus}, hasFlagEnabled: isTasksFilterPersistenceEnabled } );
        setValuesFiltersTasks((prev)=>({...prev, tasksViewMode: newValue , filter: filterStatus}))
    }

    const handleFilterCreatedBy = (newValues: string[]) => {
        if(JSON.stringify(valuesFiltersTasks.createdBy) !== JSON.stringify(newValues)){
            actions.current.updateFilter( {taskFilter: { ...valuesFiltersTasks, createdBy: newValues }, hasFlagEnabled: isTasksFilterPersistenceEnabled });
            setValuesFiltersTasks((prev)=>({...prev, createdBy: newValues }))
        }
    }

    return {
        valuesFiltersTasks,
        defaultTaskFilterFetching,
        handleFilterTag,
        handleSetFilterStatus,
        showFilterTag,
        showTableTask: canRendered,
        actions,
        handleFilterAutomationTasks,
        handleFilterCreatedBy
    }
}
