import { reportError, reportMessage } from 'utils/logger/sentry';
import { environmentName, isTest } from 'utils/globals';
import { Environment } from 'model/environment';
import { ILogger } from './model';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyTestLogger = () => {};

const localLogError = isTest ? emptyTestLogger : (...args: Array<any>) => console.error(...args);
const localLogMessage = isTest ? emptyTestLogger : (...args: Array<any>) => console.info(...args);

export const logger: ILogger = environmentName === Environment.Local ? {
    reportError: localLogError,
    reportMessage: localLogMessage,
} : {
    reportError,
    reportMessage,
};
