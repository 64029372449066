import React from 'react';
import { ButtonGroup } from '@material-ui/core';
import { ButtonSwitchComponents, ButtonSwitchType } from './TypeViewSwithMap';
import { useStylesViewSwtich } from './useStylesViewSwitch';

export type ViewSwitchProps = {
    type: ButtonSwitchType;
}

export const ViewSwitch = ({ type }: ViewSwitchProps) => {
    const classes = useStylesViewSwtich();
    const ButtonTypeShow = ButtonSwitchComponents[type];
    
    return (
        <ButtonGroup className={classes.container}>
            <ButtonTypeShow />
        </ButtonGroup>
    )
};