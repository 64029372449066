import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export function AttachmentsLoader() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={2}
        >
            <CircularProgress/>
        </Box>
    );
}
