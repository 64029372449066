import React from 'react';
import moment from 'moment';
import { Box, styled } from '@material-ui/core';
import { CellProps } from 'react-table';
import { Skeleton } from '@material-ui/lab';
import { ISkeleton } from '../../model';
import { Breakpoints, UtilColors } from 'utils/styles/constants';
import { formatDateLocalized, shortDateFormat } from 'utils/helpers/dateHelper';
import { t } from 'i18next';

export function formatDate(input: string) {
    const now = moment.now();
    if (moment(input).isSame(now, 'days')) {
        return `${t('Today')}`;
    }
    if (moment(input).subtract(1, 'day').isSame(now, 'days')) {
        return `${t('Tomorrow')}`;
    }
    if (moment(input).add(1, 'day').isSame(now, 'days')) {
        return `${t('Yesterday')}`;
    }
    return formatDateLocalized(input, shortDateFormat);
}

type DateCellData = ISkeleton;

export const StyledDateCellWrapper = styled(Box)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
    fontFamily: `'Inter', 'Arial', sans-serif`,

    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        fontSize: '13px',
        lineHeight: '18.6px',
    },
}));

export const DateCell: React.FC<CellProps<DateCellData, string>> = ({ row, value }) => {
    if (row.original.isSkeleton) {
        return <Skeleton width="75px" />;
    }

    return (
        <StyledDateCellWrapper color={UtilColors.TextPrimary}>
            {formatDate(value)}
        </StyledDateCellWrapper>
    );
};
