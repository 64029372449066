import { requestRemoveChannelMembers } from 'api/getStream/getStreamActions';
import { useCallback } from 'react';
import { Channel } from 'stream-chat';
import { useActions } from "utils/store/useActions";

export function useRemoveUserFromChannel(userId: string, channel?: Channel) {
    const actions = useActions({
        removeUserFromChannel: requestRemoveChannelMembers.init,
    });
    const handleRemoveUserFromChannel = useCallback(() => {
        if (channel?.id) {
            actions.current.removeUserFromChannel({
                channelId: channel.id,
                members: [userId],
            });
        }
    }, [actions, channel, userId]);
    return handleRemoveUserFromChannel;
}
