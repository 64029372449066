import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import jwtDecode from 'jwt-decode';
import {
    tokensAuthSuccess,
    tokensRefreshSuccess,
    AUTH_SUCCESS,
    TOKENS_REFRESH_SUCCESS,
    AUTH_LOGOUT,
    AUTH_LOGIN_AS,
    authLoginAs,
    activeToken,
} from 'api/auth/authActions';
import { requestSelfUserInfo } from 'api/user/userActions';
import { requestAppsGet } from 'api/apps/appsActions';
import { requestFileConfig, requestFileStorageToken } from 'api/files/filesActions';
import { requestKnockUserToken } from 'api/knock/knockActions';
import { setCookie } from 'utils/helpers/cookies';
import { keycloak } from 'modules/auth/keycloak';
import { ITokenUserInfo } from 'api/auth/authModel';
import { eraseTokens, eraseTokensSAdmin, storeTokens, storeTokensLoginAs, tryGetLivingTokens } from 'modules/auth/storage';
import { authApi } from 'api/auth/authApi';
import { selectUser } from './authSelectors';
import { browserHistory, Routes } from '../../../components/router/model';

function* authSuccessWatcher() {
    yield* takeLatest(
        AUTH_SUCCESS,
        function* ({ payload: tokens }: ReturnType<typeof tokensAuthSuccess>) {
            const { accessToken, idToken, refreshToken } = tokens;
            const { userId } = jwtDecode<ITokenUserInfo>(idToken);
            const { accessTokenSAdmin, refreshTokenSAdmin } = yield tryGetLivingTokens();
            yield call(storeTokens, tokens);
            const isImpersonated = Boolean(accessTokenSAdmin && refreshTokenSAdmin);
            yield call(authApi.legacyLogin, accessToken, refreshToken, isImpersonated );

            yield put(requestSelfUserInfo.init(userId));
            yield put(requestAppsGet.init());
            yield put(requestFileConfig.init());
            yield put(requestKnockUserToken.init());
            yield put(requestFileStorageToken.init());
            yield put(activeToken(tokens.accessToken))
            setCookie('filestorage.token', tokens.accessToken, {
                domain: `.${window.location.hostname}`,
                path: '/',
                secure: true,
            });
        });
}

function* refreshTokenWatcher() {
    yield* takeEvery(
        TOKENS_REFRESH_SUCCESS,
        function* ({ payload: tokens }: ReturnType<typeof tokensRefreshSuccess>) {
            const { accessToken, refreshToken } = tokens;

            yield call(storeTokens, tokens);
            const { accessTokenSAdmin, refreshTokenSAdmin } = yield tryGetLivingTokens();
            const isImpersonated = Boolean(accessTokenSAdmin && refreshTokenSAdmin);
            yield call(authApi.legacyKeepAlive, accessToken, refreshToken, isImpersonated);
            yield put(activeToken(tokens.accessToken))

            setCookie('filestorage.token', tokens.accessToken, {
                domain: `.${window.location.hostname}`,
                path: '/',
                secure: true,
            });
        },
    );
}
function authSignOutSaga({accessTokenSAdmin, refreshTokenSAdmin, isSuperAdmin}: { accessTokenSAdmin: any, refreshTokenSAdmin: any, isSuperAdmin: boolean }) {
    if(!isSuperAdmin && accessTokenSAdmin && refreshTokenSAdmin){
        window.location.reload();
    }else{
        const currentUrl = window.location.origin;
        keycloak.logout({
            redirectUri: currentUrl,
        }).then(()=>{
            keycloak.clearToken();
            browserHistory.replace(Routes.Root)
        });
    }
}

function* authSignOutWatcher() {
    yield* takeLatest(
        AUTH_LOGOUT,
        function* () {
            const currentUser = yield* select(selectUser);
            if(currentUser?.isSuperAdmin) yield call(eraseTokensSAdmin);
            yield call(eraseTokens);
            const { accessTokenSAdmin, refreshTokenSAdmin } = yield tryGetLivingTokens();
            yield call(authApi.legacyLogout);
            yield call(authSignOutSaga, {accessTokenSAdmin, refreshTokenSAdmin, isSuperAdmin: currentUser?.isSuperAdmin ?? false});
        },
    );
}

function* authLoginAsWatcher() {
    yield* takeLatest(
        AUTH_LOGIN_AS,
        function* ({ payload: userId }: ReturnType<typeof authLoginAs>) {
            const { data } = yield call(authApi.loginAs, userId);
            const { accessToken, refreshToken } = yield tryGetLivingTokens();
            yield call(storeTokens, data)
            yield call(storeTokensLoginAs, { accessToken, refreshToken });
        });
}

export const authSagas = [
    authSuccessWatcher,
    refreshTokenWatcher,
    authSignOutWatcher,
    authLoginAsWatcher
];
