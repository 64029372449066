import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Menu, MenuProps, MenuItem, Divider } from '@material-ui/core';
import { useSignOut } from 'utils/hooks/useSignOut';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { Routes } from 'components/router/model';
import { Trans } from 'react-i18next';

export interface IUserMenuDropdownProps {
    isOpened: boolean;
    onClose: () => void;
    anchorRef: MenuProps['anchorEl'];
}

export function UserMenuDropdown({
    isOpened,
    onClose,
    anchorRef,
}: IUserMenuDropdownProps) {
    const newProfileEnabled = useFeatureState(FeatureFlags.Admin2_UserProfile);
    const handleSignOutClick = useSignOut();
    const profilePath = newProfileEnabled
        ? generatePath(Routes.ProfileNew)
        : generatePath(Routes.Profile)

    return (
        <Menu
            open={isOpened}
            onClose={onClose}
            anchorEl={anchorRef}
            onClick={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            getContentAnchorEl={null}
        >
            <MenuItem
                component={Link}
                to={profilePath}
            >
                <Trans>My Profile</Trans>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleSignOutClick}>
                <Trans>Sign out</Trans>
            </MenuItem>
        </Menu>
    );
}
