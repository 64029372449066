import { createApiActions, createApiActionsByName } from 'utils/api/apiActionUtils';
import { PendingFile, UploadedFile } from 'store/modules/files/filesModel';
import { ActionsReturnTypes } from 'utils/store/actionUtils';
import {
    FileDownloadRequest,
    FileDownloadResponse,
    FileStorageTokenDTO,
    IFilesConfig,
    IUserBulkAction,
    UserImportRequestModel,
    requestFileByFileStorageResponse
} from './filesModel';
import { IEntityError } from 'model/entity';
import { IResponseErrorPayload } from 'model/error';
import { TypeUploadHandler } from 'store/modules/files/filesHooks';

const REQUEST_FILE_STORAGE_TOKEN = 'fileStorage/TOKEN';

export const requestFileStorageToken = createApiActionsByName<
    void, FileStorageTokenDTO, IResponseErrorPayload, typeof REQUEST_FILE_STORAGE_TOKEN
>(REQUEST_FILE_STORAGE_TOKEN);

const REQUEST_FILE_UPLOAD = 'file/UPLOAD';

export const requestUploadFile = createApiActionsByName<{pendingFiles: PendingFile[], typeUploadHandler: TypeUploadHandler} , {uploadedFiles: UploadedFile[], typeUploadHandler: TypeUploadHandler}, any, typeof REQUEST_FILE_UPLOAD>(
    REQUEST_FILE_UPLOAD
);

const REQUEST_FILE_DOWNLOAD = 'file/DOWNLOAD';

export const requestDownloadFile = createApiActionsByName<
    FileDownloadRequest, FileDownloadResponse, IEntityError, typeof REQUEST_FILE_DOWNLOAD
>(
    REQUEST_FILE_DOWNLOAD
);

const REQUEST_AVATAR_UPLOAD = 'file/AVATAR_UPLOAD';

export const requestUploadAvatar = createApiActionsByName<PendingFile, UploadedFile, any, typeof REQUEST_AVATAR_UPLOAD>(
    REQUEST_AVATAR_UPLOAD
);

const REQUEST_FILE_CONFIG = 'file/CONFIG';

export const requestFileConfig = createApiActionsByName<void, IFilesConfig, any, typeof REQUEST_FILE_CONFIG>(
    REQUEST_FILE_CONFIG,
);

export const DOWNLOAD_USER_IMPORT_TEMPLATE = 'DOWNLOAD_USER_IMPORT_TEMPLATE';

export const requestUserBulkImportTemplate = createApiActionsByName<IUserBulkAction['type'],
    FileDownloadResponse,
    IEntityError,
    typeof DOWNLOAD_USER_IMPORT_TEMPLATE>(DOWNLOAD_USER_IMPORT_TEMPLATE);

const DOWNLOAD_TASKS_BULK = 'DOWNLOAD_TASKS_BULK';
export const requestDownloadTasksBulk = (
    createApiActionsByName<FileDownloadRequest, FileDownloadResponse, IEntityError, typeof DOWNLOAD_TASKS_BULK>(
        DOWNLOAD_TASKS_BULK,
    )
);

export const REQUEST_USERS_BULK_UPLOAD = 'file/USERS_BULK_UPLOAD';

export const requestUsersBulkUpload = createApiActionsByName<UserImportRequestModel, boolean, any, typeof REQUEST_USERS_BULK_UPLOAD>(
    REQUEST_USERS_BULK_UPLOAD
);

const REQUEST_FILE_BY_FILE_STORAGE = 'request/file/GET';
const REQUEST_FILE_BY_FILE_STORAGE_SUCCESS = 'response/file/GET_SUCCESS';
const REQUEST_FILE_BY_FILE_STORAGE_ERROR = 'response/file/GET_ERROR';

export const requestFileByFileStorage = (
    createApiActions<string, requestFileByFileStorageResponse, IEntityError,
        typeof REQUEST_FILE_BY_FILE_STORAGE,
        typeof REQUEST_FILE_BY_FILE_STORAGE_SUCCESS,
        typeof REQUEST_FILE_BY_FILE_STORAGE_ERROR
    >(
        REQUEST_FILE_BY_FILE_STORAGE,
        REQUEST_FILE_BY_FILE_STORAGE_SUCCESS,
        REQUEST_FILE_BY_FILE_STORAGE_ERROR,
    )
);

export const REQUEST_TASKS_BULK_UPLOAD = 'file/TASKS_BULK_UPLOAD';

export const requestTasksBulkUpload = createApiActionsByName<PendingFile, boolean, any, typeof REQUEST_TASKS_BULK_UPLOAD>(
    REQUEST_TASKS_BULK_UPLOAD
);

export type FilesActions =
    | ActionsReturnTypes<typeof requestUploadFile>
    | ActionsReturnTypes<typeof requestDownloadFile>
    | ActionsReturnTypes<typeof requestFileStorageToken>
    | ActionsReturnTypes<typeof requestFileByFileStorage>
    | ActionsReturnTypes<typeof requestDownloadTasksBulk>;

export type FilesConfigActions = ActionsReturnTypes<typeof requestFileConfig>;

export type AvatarFileActions =
    | ActionsReturnTypes<typeof requestUploadAvatar>;

export type UserBulkTemplateDownloadActions =
    | ActionsReturnTypes<typeof requestUserBulkImportTemplate>;

export type UsersBulkUploadActions =
    | ActionsReturnTypes<typeof requestUsersBulkUpload>;

export type TasksBulkUploadActions =
    | ActionsReturnTypes<typeof requestTasksBulkUpload>;
