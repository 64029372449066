import { Box, TextField } from '@material-ui/core'
import { UserInfo } from 'components/userInfo/UserInfo';
import { t } from 'i18next';
import React, { useCallback, useMemo } from 'react'
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { TextConversationEditor } from '../common/TextConversationEditor';
import { Formik } from 'formik';
import { addTaskConversationSchema, getMentions } from './validation';
import { useActions } from 'utils/store/useActions';
import { requestCreateConversation } from 'api/task/taskConversation/taskConversationActions';
import { useSelector } from 'react-redux';
import { selectIsActiveTextEditor, selectStoreTaskConversationById, selectSuggestionsFetching, selectTaskConversationCreating, selectTaskConversationStore } from 'store/modules/task/taskConversation/taskConversationSelectors';
import { isActiveTextEditor } from 'store/modules/task/taskConversation/taskConversationActions';
import { getUploadedConversationFiles } from 'store/modules/files/filesSelectors';
import { resetPendingConversationFiles, resetUploadedConversationFiles } from 'store/modules/files/filesActions';

export const AddComment: React.FC  = () => {
  const currentUser = useCurrentUser();
  const storeFilterValue = useSelector(selectTaskConversationStore);
  const activeTextEditor = useSelector(selectIsActiveTextEditor);
  const isSuggestionsFetching = useSelector(selectSuggestionsFetching);
  const actions = useActions({
      handleShowTextEditor: isActiveTextEditor,
      resetPendigFiles: resetPendingConversationFiles,
      resetUploadedFiles: resetUploadedConversationFiles
  });

  if(!currentUser || !storeFilterValue ) return <></>;

  const onChangeActiveTextEditor = (type: string) => {
      actions.current.resetPendigFiles();
      actions.current.resetUploadedFiles();
      actions.current.handleShowTextEditor(type)
  };

  return (
    <Box display="flex" flexDirection="row" mt={2}>
        <Box width={50}>
          <UserInfo userId={currentUser.id} showInformation={false}/>
        </Box>
        {activeTextEditor === 'add' ? 
          <AddConversationForm onCancel={onChangeActiveTextEditor}/>
          : <Box width="100%">
              <TextField
                  id="create-comment"
                  name="create-comment"
                  onClick={()=>onChangeActiveTextEditor('add')}
                  value=""
                  variant="outlined"
                  placeholder={`${t('Add a comment')}...`}
                  fullWidth
                  disabled={isSuggestionsFetching}
              />
          </Box>
        }
    </Box>
  )
}

type AddConversationFormProps = {
  onCancel: (type: string)=> void,
}
const AddConversationForm: React.FC<AddConversationFormProps> = ({onCancel}) => {
  const filterStoreTaskId = useSelector(selectTaskConversationStore);
  const storeTaskConversations = useSelector(selectStoreTaskConversationById)[filterStoreTaskId];
  const taskConversationCreating = useSelector(selectTaskConversationCreating);
  const uploadedFiles = useSelector(getUploadedConversationFiles);
  const initialFormValues = useMemo(() => ({
    text: '',
    mentionedUsers: [],
    storeTaskId: '',
    attachments: []
  }),[]);
  const actions = useActions({
    addTaskConversation: requestCreateConversation.init,
  });

  const onSubmit = useCallback((values) => {
    if (uploadedFiles.length > 0) {
        values.attachments = [...uploadedFiles];
    }
  
    const mentions = getMentions(values.text);
    actions.current.addTaskConversation({...values, mentionedUsers: mentions, storeTaskId: storeTaskConversations?.storeTaskId});
  },[actions, uploadedFiles, storeTaskConversations]);
  return(
    <Formik
        initialValues={initialFormValues}
        validationSchema={addTaskConversationSchema}
        onSubmit={onSubmit}
    >
        {props => (
            <TextConversationEditor isUpdating={taskConversationCreating} onCancel={() => onCancel('')} {...props}/>
        )}
    </Formik>
  )
}
