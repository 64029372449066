import React, { useEffect, useState } from 'react';
import { FormHelperText } from '@material-ui/core';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { LocationPicker } from 'modules/locationPicker/LocationPicker/LocationPicker';
import { useField } from 'formik';
import { hasFormikError } from 'components/basicInputs/utils';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { LocationsButton } from 'modules/task/locations/components/LocationsButton';
import { SingleLocationBody } from './components/SingleLocationBody';
import { LocationsSection } from './components/LocationsSection';
import { resetSearchStores } from 'api/store/storeActions';
import { useActions } from 'utils/store/useActions';
import { Stores } from 'api/store/storeModel';
import { Trans } from 'react-i18next';

export type TaskLocationsCreateProps = {
    fieldName: string;
    fieldNameFromTemplate: string;
}

export function TaskLocationsCreate({ fieldName, fieldNameFromTemplate }: TaskLocationsCreateProps) {
    const currentUser = useCurrentUser();
    const { isOpened, onClose, onOpen } = useOpenedState();
    const [storesCount, setStoresCount] = useState(0);
    const [field, meta, helpers] = useField<string[]>(fieldName);
    const [fieldFromTemplate] = useField<Stores>(fieldNameFromTemplate);
    const { value: valueFromTemplate } = fieldFromTemplate;
    const isStore = currentUser?.isCompanyLocationStore === true;
    const actionsSearchStore = useActions({
        resetSearchStores: resetSearchStores
    });

    useEffect(()=>{
        if(!isOpened){
            actionsSearchStore.current.resetSearchStores();
        }
    },[isOpened, actionsSearchStore])

    useEffect(() => {
        if (currentUser && isStore && ![...field.value].includes(currentUser.companyLocationId ?? '')) {
            helpers.setValue([currentUser.companyLocationId ?? '']);
        }
    }, [currentUser, field.value, helpers, isStore]);

    return (
        <LocationsSection count={isStore ? undefined : storesCount}>
            {isStore && <SingleLocationBody location={currentUser?.companyLocationName ?? ''} />}
            {!isStore && (<>
                <LocationsButton text={<Trans>Select Stores</Trans>} onClick={onOpen} />

                {hasFormikError(meta) && (
                    <FormHelperText error>
                        {meta.error}
                    </FormHelperText>
                )}

                <LocationPicker
                    initialSelectedLocations={field.value || []}
                    isOpened={isOpened}
                    onClose={onClose}
                    onSave={(locations, count) => {
                        setStoresCount(count);
                        helpers.setValue(locations);
                    }}
                    showSearchByStore
                    valueFromTemplate={valueFromTemplate}
                />
            </>)}
        </LocationsSection>
    );
}
