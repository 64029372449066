import { styled, Typography } from "@material-ui/core";
import { CreateCSSProperties } from "@material-ui/styles";
import { Breakpoints } from "utils/styles/constants";

const commonLocationNameStyles: CreateCSSProperties<{}> = {
    letterSpacing: '0.15px',
    fontWeight: 400,
    fontStyle: 'normal',
};

export const LocationName = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '20.02px',
    ...commonLocationNameStyles,

    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        fontSize: '13px',
        lineHeight: '15.73px',
        ...commonLocationNameStyles,
    },
}));
