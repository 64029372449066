import React, { useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import { ChatContent } from './components/ChatContent';
import { ChatSidebar } from './components/ChatSidebar';
import { useConversations } from './hooks/useConversations';
import { WorkspaceController } from './components/ChatHeader/chatActions/PinAction/WorkspaceController';

export type ChatInnerProps = {
    channelId?: string;
}

export function ChatInner({ channelId }: ChatInnerProps) {
    const { chats, chatsReady, channels, channelsReady, conversationsService } = useConversations();
    const { setActiveChannel } = useChatContext();

    useEffect(() => {
        if (conversationsService && channelsReady && chatsReady) {
            const channel = channelId
                ? [...chats, ...channels].find(({ cid }) => cid === channelId)
                : null;

            if (channel) {
                conversationsService.setActiveConversation(channel);
                setActiveChannel(channel);
            } else if (channelId) {
                // try to restore conversation in case it was previously hidden.
                // once it is restored, channels or chats dependency will change and above code will set it active
                conversationsService.refreshOrGetConversation(channelId);
            } else {
                setActiveChannel(undefined);
            }
        }
    }, [conversationsService, chats, chatsReady, channels, channelsReady, channelId, setActiveChannel]);

    return (
        <WorkspaceController>
            <ChatSidebar />
            <ChatContent />
        </WorkspaceController>
    );
}
