import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { AttachmentsPanel } from '../components/AttachmentsPanel';
import { useSelector } from 'react-redux';
import { AttachmentsMode } from './model';
import { useFormikContext } from 'formik';
import { getPendingFilesQuantity } from 'store/modules/files/filesSelectors';
import { AttachmentsButton, resolveButtonText } from '../components/AttachmentsButton/AttachmentsButton';
import { TaskPrototype } from 'api/task/prototype/prototypeModel';
import { t } from 'i18next';

export type AttachmentsEditProps = {
    disabled?: boolean;
    labelText?: string;
}

export function AttachmentsEdit({ disabled, labelText }: AttachmentsEditProps) {
    const { isOpened, onClose, onOpen } = useOpenedState();
    const formikContext = useFormikContext<Partial<TaskPrototype>>();

    const attachmentsCount = formikContext.values.attachments?.length ?? 0;
    const pendingFilesCount = useSelector(getPendingFilesQuantity);
    const totalQuantity = attachmentsCount + pendingFilesCount;
    const buttonText = useMemo(() => resolveButtonText(`${t('Edit Attachments')}`, totalQuantity), [totalQuantity]);

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                gridColumn="1/3"
                alignItems="start"
            >
                <AttachmentsButton
                    disabled={disabled}
                    buttonText={buttonText}
                    handleOpenAttachmentsPanel={onOpen}
                    labelText={labelText}
                />
            </Box>

            <AttachmentsPanel
                isOpened={isOpened}
                handleClose={onClose}
                mode={AttachmentsMode.Edit}
            />
        </>
    );
}
