import React from 'react';
import { styled, Box, Typography, lighten, SvgIcon } from '@material-ui/core';
import { StoreTaskActivityEvent } from 'api/task/storeTask/storeTaskModel';
import { GrayColor, GreenColor, orangeColor, UtilColors } from 'utils/styles/constants';
import { UserInfo } from 'components/userInfo/UserInfo';
import { DateDisplay } from 'components/dateTime/DateDisplay';
import { TimeDisplay } from 'components/dateTime/TImeDisplay';
import { Block, HelpOutline } from '@material-ui/icons';
import { ForwardMailIcon } from 'components/icons/ForwardMailIcon';
import { SquareCheckIcon } from 'components/icons/SquareCheckIcon';
import { SquareXmarkIcon } from 'components/icons/SquareXmarkIcon';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { TaskActivityEventBody } from './TaskActivityEventBody';
import {
    TASK_APPROVED,
    TASK_COMPLETED,
    TASK_CREATED,
    TASK_DECLINED,
    TASK_EDITED,
    TASK_INCOMPLETED,
    TASK_SCHEDULED_CREATE,
    TASK_SCHEDULED_EDITED,
    TASK_SUBMITTED,
} from './model';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const Card = styled(Box)({
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '428px',
    padding: '9.5px 8px 8px',
    borderRadius: '8px',
    border: `1px solid ${GrayColor.GrayE0}`,

    '&:not(:first-child)': {
        marginTop: '16px',
    },
});

const CardHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
});

const DateTimeSection = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
});

const StatusEnvelopeSection = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const Status = styled(Typography)({
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
    fontStyle: 'normal',
    font: `'Inter', 'Arial', sans-serif`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

export type TaskActivityEventProps = {
    event: StoreTaskActivityEvent;
}

const grayColor = lighten(UtilColors.Black, .5);
const overdueColor = orangeColor;

const textMap: Record<string, string> = {
    [TASK_APPROVED]: 'Completed',
    [TASK_DECLINED]: 'Declined',
    [TASK_SUBMITTED]: 'Submitted',
    [TASK_CREATED]: 'Created',
    [TASK_EDITED]: 'Edited',
    [TASK_COMPLETED]: 'Completed',
    [TASK_INCOMPLETED]: 'Incomplete',
    [TASK_SCHEDULED_EDITED]: 'Edit',
    [TASK_SCHEDULED_CREATE]: 'Create',
};

const eventColorMap: Record<string, string> = {
    [TASK_APPROVED]: GreenColor.Green2,
    [TASK_DECLINED]: UtilColors.Red2,
};

const eventIconMap: Record<string, typeof SvgIcon> = {
    [TASK_APPROVED]: SquareCheckIcon,
    [TASK_DECLINED]: Block,
    [TASK_SUBMITTED]: ForwardMailIcon,
    [TASK_CREATED]: EmailOutlined,
    [TASK_EDITED]: RateReviewIcon,
    [TASK_COMPLETED]: SquareCheckIcon,
    [TASK_INCOMPLETED]: SquareXmarkIcon,
    [TASK_SCHEDULED_EDITED]: RateReviewIcon,
    [TASK_SCHEDULED_CREATE]: EmailOutlined
};

export const TaskActivityEvent: React.FC<TaskActivityEventProps> = ({ event }) => {
    const isOverdueFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const color = eventColorMap[event.eventType] || grayColor;
    const text = `${t(textMap[event.eventType])}` || `${t('Unknown event')}`;
    const Icon = eventIconMap[event.eventType] || HelpOutline;
    return (
        <Card>
            <CardHeader>
                { event.userId && <UserInfo userId={event.userId} />}

                <DateTimeSection>
                    <DateDisplay dateTime={event.createdOn} />
                    <TimeDisplay dateTime={event.createdOn} />
                </DateTimeSection>
            </CardHeader>
            <StatusEnvelopeSection>
                <Status style={{ color }}>
                    <Icon />
                    <span style={{ marginLeft: '4px' }}>{text}</span>
                </Status>
                {event.isOverdue && isOverdueFeatureEnabled?.enabled && (
                    <Status style={
                        {
                            color: overdueColor,
                            fontSize: '15px'
                        }
                    }>
                        <Box>
                            <Trans>Overdue</Trans>
                        </Box>
                    </Status>
                )}
            </StatusEnvelopeSection>
            <TaskActivityEventBody event={event} />
        </Card>
    );
};
