import { Button, Fade, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { TaskStatusFilterTab } from 'api/task/taskFilter/taskFilterModel';
import { t } from 'i18next';
import { useStylesViewSwtich } from './useStylesViewSwitch';
import { useActions } from 'utils/store/useActions';
import { Routes } from 'components/router/model';
import { NavLink } from 'react-router-dom';
import { taskFilterTab } from 'store/modules/task/taskFilter/taskFilterActions';
import EventIcon from '@material-ui/icons/Event';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';

export const CalendarTasksButton = ({ ...res}) => {
    const classes = useStylesViewSwtich();
    const tasksCalendarViewEnabled = useFeatureState(FeatureFlags.TasksCalendarView);
    const btnClass = clsx(res.className, classes.button);
    const actions = useActions({
        updateTaskFilterTab: taskFilterTab
    });

    const restProps = {...res, className: btnClass}

    const handleUpdateTaskTab = () => {
        actions.current.updateTaskFilterTab(TaskStatusFilterTab.CalendarTask);
    }

    if (!tasksCalendarViewEnabled) {
        return <></>;
    }

    return (
        <Button
            component={NavLink}
            to={Routes.TaskListCalendar}
            onClick={handleUpdateTaskTab}
            {...restProps}
        >
            <Tooltip title={`${t('Calendar')}`} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <EventIcon />
            </Tooltip>
        </Button>
    );
};
