import { put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { browserHistory, Routes } from 'components/router/model';
import { requestTaskTemplates, requestCreateTaskTemplate, requestGetTaskTemplate, resetGetTaskTemplate, requestUpdateTaskTemplate } from 'api/task/taskTemplate/taskTemplateActions';
import { resetPendingFiles, resetUploadedFiles } from 'store/modules/files/filesActions';
import { generatePath } from 'react-router';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import i18n from 'i18n';
import { LocationTree } from 'api/hierarchy/hierarchyModel';
import { selectLocationsTree } from 'store/modules/hierarchy/hierarchySelectors';
import { differenceBy } from 'lodash';
import { CONFIRMATION_EDIT_TEMPLATE, checkLocationsTemplate, confirmationEditTemplate, setShowModalEditTemplate } from './taskTemplateActions';
import { selectModalEditionTemplate } from './taskTemplateSelectors';
import { ModalEditionTemplate } from './taskTemplateModel';

function* taskTemplateCreateSuccessSaga() {
    yield* takeLatest(
        requestCreateTaskTemplate.successType,
        function* (action: ReturnType<typeof requestCreateTaskTemplate.success>) {
            yield put(resetPendingFiles());
            yield put(resetUploadedFiles());
            yield put(showToastMessage({
                message: i18n.t('Task template successfully added'),
                options: {
                    variant: ToastVariant.Success
                },
            }))
            browserHistory.push(generatePath(Routes.TaskList));
        },
    );
}

function* getTaskTemplatesSuccessSaga() {
    yield* takeLatest(
        requestTaskTemplates.successType,
        function* (action: ReturnType<typeof requestTaskTemplates.success>) {
            yield put(resetGetTaskTemplate());
        },
    );
}

function* taskTemplateGetSuccessSaga() {
    yield* takeLatest(
        requestGetTaskTemplate.successType,
        function* (action: ReturnType<typeof requestGetTaskTemplate.success>) {
            const locationsTree: LocationTree[] = yield select(selectLocationsTree);
            const {locations} = action.payload;
            const locationDifference = differenceBy(locations, locationsTree, 'id');
            const cleanLocationsDifference = differenceBy(locations, locationDifference, 'id');

            if(action.payload.isEditTemplate){
                
                if(locationDifference.length){
                    yield put(confirmationEditTemplate(false));
                    yield put(setShowModalEditTemplate({isOpened: true, taskTemplate: {...action.payload, locations: cleanLocationsDifference}}));
                    return;
                }

                yield put(resetPendingFiles());
                yield put(resetUploadedFiles());
                yield put(checkLocationsTemplate({...action.payload, locations: cleanLocationsDifference}));
                browserHistory.push({pathname:generatePath(Routes.AddTask),search: `?isEditTemplate=true&id=${action.payload.id}`});
                return;
            }
            yield put(resetPendingFiles());
            yield put(resetUploadedFiles());

            yield put(checkLocationsTemplate({...action.payload, locations: cleanLocationsDifference}));
        },
    );
}

function* updatedScheduledTasksSuccess() {
    yield* takeLatest(
        requestUpdateTaskTemplate.successType,
        function* ( action : ReturnType<typeof requestUpdateTaskTemplate.success>) {
            yield put(resetGetTaskTemplate());
            yield put(resetPendingFiles());
            yield put(resetUploadedFiles());
            yield put(showToastMessage({
                message: i18n.t('Task template Updated'),
                options: {
                    variant: ToastVariant.Success
                },
            }))
            browserHistory.push(generatePath(Routes.TaskList));
        },
    );
}
function* allowEditionTemplateSaga() {
    yield* takeEvery([CONFIRMATION_EDIT_TEMPLATE],
        function* ({payload}: ReturnType<typeof confirmationEditTemplate>) {
            const modalEditionTemplate: ModalEditionTemplate = yield select(selectModalEditionTemplate);

            if(!modalEditionTemplate.taskTemplate || !payload) return;

            yield put(resetPendingFiles());
            yield put(resetUploadedFiles());
            yield put(checkLocationsTemplate(modalEditionTemplate.taskTemplate));
            yield put(setShowModalEditTemplate({isOpened: false, taskTemplate: null}));
            yield put(confirmationEditTemplate(false));
            browserHistory.push({pathname:generatePath(Routes.AddTask),search: `?isEditTemplate=true&id=${modalEditionTemplate.taskTemplate.id}`});
        },
    );
}

export const taskTemplateSagas = [
    getTaskTemplatesSuccessSaga,
    taskTemplateCreateSuccessSaga,
    taskTemplateGetSuccessSaga,
    updatedScheduledTasksSuccess,
    allowEditionTemplateSaga
];
