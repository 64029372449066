import { ItemsById } from 'model/entity';
import { combineReducers } from 'redux';
import { insertSavingOrder } from 'utils/helpers/collections';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { TaskListComponentActions, TASK_LIST_RESET_DATA } from '../taskListComponent/taskListComponentActions';
import { AutomationTaskApiActions, requestAutomationTasks } from 'api/task/automationTask/automationTaskActions';
import { AutomationTaskShort } from 'api/task/automationTask/automationTaskModel';

const initialState = {
    automationTasksFetching: false,
    automationTasksNextPageToken: null,
    automationTasksById: {},
    automationTasksOrder: [],
};

function automationTasksFetching(
    state: boolean = initialState.automationTasksFetching,
    action: AutomationTaskApiActions,
): boolean {
    if (action.type === requestAutomationTasks.initType) {
        return true;
    } else if (action.type === requestAutomationTasks.successType || action.type === requestAutomationTasks.errorType) {
        return false;
    }

    return state;
}

function automationTasksNextPageToken(
    state: string | null = initialState.automationTasksNextPageToken,
    action: AutomationTaskApiActions,
): string | null {
    if (action.type === requestAutomationTasks.successType) {
        return action.payload.nextPageToken;
    }
    return state;
}

function automationTasksById(
    state: ItemsById<AutomationTaskShort> = initialState.automationTasksById,
    action: AutomationTaskApiActions,
): ItemsById<AutomationTaskShort> {
    if( action.type === requestAutomationTasks.successType){
        return { ...state, ...itemsByIds(action.payload.tasks) };
    }
    return state;
}

function automationTasksOrder(
    state: Array<string> = initialState.automationTasksOrder,
    action: AutomationTaskApiActions | TaskListComponentActions,
): Array<string> {
    switch (action.type) {
        case requestAutomationTasks.successType:
            return insertSavingOrder(state, action.payload.tasks.map((item: { id: any; }) => item.id));
        case TASK_LIST_RESET_DATA:
            return initialState.automationTasksOrder;
        default:
            return state;
    }
}

export const automationTaskReducer = combineReducers({
    automationTasksFetching,
    automationTasksNextPageToken,
    automationTasksOrder,
    automationTasksById,
});
