import { IconButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { LaunchLayoutItem } from './LaunchLayoutItem';
import { useLaunchStyles } from '../styles';

export type LaunchNewProp = {
  onAdd: () => void
}
export const LaunchAdd = (props: LaunchNewProp) => {
  const classes = useLaunchStyles();
  return (
    <LaunchLayoutItem>
      <IconButton
        onClick={props.onAdd}
        className={classes.launchAddBtn}
      >
        <AddIcon fontSize="large" className={classes.iconLaunchAdd}/>
      </IconButton>
    </LaunchLayoutItem>
  )
}
