import { combineReducers } from "redux";
import { DefaultValuesFilter, TaskStatusFilterTab, TasksFilterType } from "api/task/taskFilter/taskFilterModel";
import { TaskFilterActions, getDefaultTaskFilter, requestGetTasksFilterCreators, requestUpdateTaskFilter } from "api/task/taskFilter/taskFilterActions";
import { TASK_CREATED_BY_FILTER_ACTIVE, TASK_FILTER_TAB, TaskFilterTab } from "./taskFilterActions";

const initialState = {
    tasksFilter: DefaultValuesFilter,
    taskFilterFetching: true,
    hasInitFilterFetching: false,
    activeTabTask: null,
    creatorsFilter: [],
    creatorFetching: false,
    taskFilterCreatedBySelected: []
};

function tasksFilter(
    state: TasksFilterType = initialState.tasksFilter,
    action: TaskFilterActions
): TasksFilterType {
    switch (action.type) {
        case getDefaultTaskFilter.successType:
        case requestUpdateTaskFilter.successType:
            return { ...state, ...action.payload };
        case getDefaultTaskFilter.errorType:
        case requestUpdateTaskFilter.errorType:
            return state;
        default:
            return state;
    }
}

function taskFilterFetching(
    state = initialState.taskFilterFetching,
    action: TaskFilterActions
): boolean {
    switch (action.type) {
        case requestUpdateTaskFilter.initType:
        case getDefaultTaskFilter.initType:
            return true;
        case requestUpdateTaskFilter.successType:
        case requestUpdateTaskFilter.errorType:
        case getDefaultTaskFilter.successType:
        case getDefaultTaskFilter.errorType:
            return false;
        default:
            return state;
    }
}

function activeTabTask(
    state: null | string = initialState.activeTabTask,
    action: TaskFilterActions | TaskFilterTab
): null | string {
    switch (action.type) {
        case getDefaultTaskFilter.successType:
            return action.payload.tasksViewMode ?? TaskStatusFilterTab.DefaultTasksViewMode
        case TASK_FILTER_TAB:
            return action.payload;
        default:
            return state;
    }
}

function hasInitFilterFetching(
    state = initialState.hasInitFilterFetching,
    action: TaskFilterActions
): boolean {
    switch (action.type) {
        case getDefaultTaskFilter.initType:
        case getDefaultTaskFilter.successType:
            return true;
        case getDefaultTaskFilter.errorType:
            return false;
        default:
            return state;
    }
}

function creatorsFilter(
    state = initialState.creatorsFilter,
    action: TaskFilterActions
): string[] {
    switch (action.type) {
        case requestGetTasksFilterCreators.successType:
            return action.payload;
        case requestGetTasksFilterCreators.errorType:
            return [];
        default:
            return state;
    }
}


function creatorFetching(
    state = initialState.creatorFetching,
    action: TaskFilterActions
): boolean {
    switch (action.type) {
        case requestGetTasksFilterCreators.initType:
            return true;
        case requestGetTasksFilterCreators.successType:
        case requestGetTasksFilterCreators.errorType:
            return false;
        default:
            return state;
    }
}

function taskFilterCreatedBySelected(
    state = initialState.taskFilterCreatedBySelected,
    action: TaskFilterTab
): Array<string> {
    if(action.type === TASK_CREATED_BY_FILTER_ACTIVE){
        return action.payload;
    }
    return state;
}

export const taskFilterReducer = combineReducers({
    tasksFilter,
    taskFilterFetching,
    hasInitFilterFetching,
    activeTabTask,
    creatorsFilter,
    creatorFetching,
    taskFilterCreatedBySelected
});
