import { createSingleAction } from 'utils/store/actionUtils';
import { PendingFile, UploadedFile } from './filesModel';

export const FILES_RESET_PENDING_FILES = 'files/SET_PENDING_FILES';
export const FILES_ADD_PENDING_FILES = 'files/ADD_PENDING_FILES';
export const FILES_REMOVE_PENDING_FILE = 'files/REMOVE_PENDING_FILE';
export const resetPendingFiles = (
    createSingleAction<void, typeof FILES_RESET_PENDING_FILES>(FILES_RESET_PENDING_FILES)
);
export const addPendingFiles = (
    createSingleAction<PendingFile[], typeof FILES_ADD_PENDING_FILES>(FILES_ADD_PENDING_FILES)
);
export const removePendingFile = (
    createSingleAction<string, typeof FILES_REMOVE_PENDING_FILE>(FILES_REMOVE_PENDING_FILE)
);
export type PendingFilesAction = (
    ReturnType<typeof addPendingFiles> |
    ReturnType<typeof resetPendingFiles> |
    ReturnType<typeof removePendingFile>
);

export const FILES_RESET_UPLOADED_FILES = 'files/RESET_UPLOADED_FILES';
export const FILES_ADD_UPLOADED_FILES = 'files/ADD_UPLOADED_FILES';
export const FILES_REMOVE_UPLOADED_FILE = 'files/REMOVE_UPLOADED_FILE';
export const resetUploadedFiles = (
    createSingleAction<void, typeof FILES_RESET_UPLOADED_FILES>(FILES_RESET_UPLOADED_FILES)
);
export const addUploadedFiles = (
    createSingleAction<UploadedFile[], typeof FILES_ADD_UPLOADED_FILES>(FILES_ADD_UPLOADED_FILES)
);
export const removeUploadedFile = (
    createSingleAction<string, typeof FILES_REMOVE_UPLOADED_FILE>(FILES_REMOVE_UPLOADED_FILE)
);
export type UploadedFilesAction = (
    ReturnType<typeof addUploadedFiles> |
    ReturnType<typeof resetUploadedFiles> |
    ReturnType<typeof removeUploadedFile>
);

export const FILES_USERS_BULK_RESET_PENDING_FILES = 'files/FILES_USERS_BULK_RESET_PENDING_FILES';
export const FILES_USERS_BULK_ADD_PENDING_FILES = 'files/FILES_USERS_BULK_ADD_PENDING_FILES';
export const resetPendingUsersImportFiles = (
    createSingleAction<void, typeof FILES_USERS_BULK_RESET_PENDING_FILES>(FILES_USERS_BULK_RESET_PENDING_FILES)
);
export const addPendingUsersImportFiles = (
    createSingleAction<PendingFile, typeof FILES_USERS_BULK_ADD_PENDING_FILES>(FILES_USERS_BULK_ADD_PENDING_FILES)
);
export type PendingUserBulkImportActions = (
    ReturnType<typeof resetPendingUsersImportFiles> |
    ReturnType<typeof addPendingUsersImportFiles>
);

export const FILES_RESET_PENDING_CONVERSATION_FILES = 'files/FILES_RESET_PENDING_CONVERSATION_FILES';
export const FILES_ADD_PENDING_CONVERSATION_FILES = 'files/FILES_ADD_PENDING_CONVERSATION_FILES';
export const FILES_REMOVE_PENDING_CONVERSATION_FILE = 'files/FILES_REMOVE_PENDING_CONVERSATION_FILE';
export const resetPendingConversationFiles = (
    createSingleAction<void, typeof FILES_RESET_PENDING_CONVERSATION_FILES>(FILES_RESET_PENDING_CONVERSATION_FILES)
);
export const addPendingConversationFiles = (
    createSingleAction<PendingFile[], typeof FILES_ADD_PENDING_CONVERSATION_FILES>(FILES_ADD_PENDING_CONVERSATION_FILES)
);
export const removePendingConversationFile = (
    createSingleAction<string, typeof FILES_REMOVE_PENDING_CONVERSATION_FILE>(FILES_REMOVE_PENDING_CONVERSATION_FILE)
);
export type PendingConversationActions = (
    ReturnType<typeof resetPendingConversationFiles>
    | ReturnType<typeof addPendingConversationFiles>
    | ReturnType<typeof removePendingConversationFile>
);

export const FILES_RESET_UPLOADED_CONVERSATION_FILES = 'files/RESET_UPLOADED_CONVERSATION_FILES';
export const FILES_ADD_UPLOADED_CONVERSATION_FILES = 'files/ADD_UPLOADED_CONVERSATION_FILES';
export const FILES_REMOVE_UPLOADED_CONVERSATION_FILE = 'files/REMOVE_UPLOADED_CONVERSATION_FILE';
export const resetUploadedConversationFiles = (
    createSingleAction<void, typeof FILES_RESET_UPLOADED_CONVERSATION_FILES>(FILES_RESET_UPLOADED_CONVERSATION_FILES)
);
export const addUploadedConversationFiles = (
    createSingleAction<UploadedFile[], typeof FILES_ADD_UPLOADED_CONVERSATION_FILES>(FILES_ADD_UPLOADED_CONVERSATION_FILES)
);
export const removeUploadedConversationFile = (
    createSingleAction<string, typeof FILES_REMOVE_UPLOADED_CONVERSATION_FILE>(FILES_REMOVE_UPLOADED_CONVERSATION_FILE)
);
export type UploadedConversationFilesAction = (
    ReturnType<typeof addUploadedConversationFiles> |
    ReturnType<typeof resetUploadedConversationFiles> |
    ReturnType<typeof removeUploadedConversationFile>
);