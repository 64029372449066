import * as t from 'io-ts';
interface IStoreSchema {
    id: string;
    isStore: boolean;
    name: string;
    parent: IStoreSchema | null;
}

const StoreSchema: t.Type<IStoreSchema> = t.recursion('StoreSchema', () =>
    t.type({
        id: t.string,
        isStore: t.boolean,
        name: t.string,
        parent: t.union([t.null, StoreSchema])
    })
)

export const StoresSchema = t.array(StoreSchema);

export type Store = t.TypeOf<typeof StoreSchema>;
export type Stores = t.TypeOf<typeof StoresSchema>;
export type SearchStoresRequest = {
    companyId: string;
    searchTerm: string;
}

