import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ForwardMailIcon: typeof SvgIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="-1 -2 20 20">
        <path
            d="M15.6797 0.320312H2.32031C1.42188 0.320312 0.679688 1.10156 0.679688 2V12C0.679688 12.8984 1.42188 13.6797 2.32031 13.6797H9.82031V12H2.32031V3.67969L9 7.82031L15.6797 3.67969V7.82031H17.3203V2C17.3203 1.10156 16.5781 0.320312 15.6797 0.320312ZM9 6.17969L2.32031 2H15.6797L9 6.17969ZM14.8203 9.5L18.1797 12.8203L14.8203 16.1797V13.6797H11.5V12H14.8203V9.5Z"
        />
    </SvgIcon>
);
