import { combineReducers } from 'redux';
import { ItemsById } from 'model/entity';
import { UserDTO } from 'api/user/userModel';
import {
    requestMultipleUsersInfo,
    requestSelfUserInfo,
    requestUserBulkStatusChange,
    requestUserCreate,
    requestUserList,
    requestUserUpdate,
    UserActions
} from 'api/user/userActions';
import { itemsByIds } from 'utils/helpers/itemsByIds';
import { LegacyRequestActions, requestNotificationsCount } from 'api/legacy/legacyActions';
import { GetStreamActions, requestStreamUserToken } from 'api/getStream/getStreamActions';
import { CoreActions, requestCompanyRoles, requestUserRoles } from 'api/core/actions';
import { UserRoleDTO } from 'api/core/model';
import { insertSavingOrder } from 'utils/helpers/collections';
import {
    AVATAR_EXPLICIT_UPDATE,
    AvatarActionsType,
    AvatarExplicitUpdateActions, BulkImportUsersSteps,
    BulkUserUpdateActions,
    CheckerActions, ImportActionsType,
    OPEN_AVATAR_EDITOR, OPEN_USER_IMPORT_MODAL,
    REQUEST_USER_REACTIVATION_CONFIRM,
    RESET_USER_LIST, SET_BULK_IMPORT_USERS_STEP,
    SET_BULK_USER_STATUS_CHANGE_CONFIRM,
    SET_USERS_CHECKED_ITEMS,
    UserActionType,
    UserSingleIdActionType
} from './userActions';
import { UserFormData } from '../../../modules/users/user-profile/model';
import { CheckedItems } from '../../../utils/hooks/useCheckdItems';

const initialState = {
    usersByIds: {},
    usersLoadingById: {},
    usersUpdatingById: {},
    userCreating: false,
    userRoles: [],
    userRolesLoading: false,
    notificationsCount: 0,
    getStreamUserToken: null,

    userListLoading: false,
    userListNextPageToken: null,
    userListOrder: [],
    userFormData: null,

    avatarEditorOpened: false,
    avatarUploadingExplicit: false,

    checkedUsers: {},
    bulkOpenStatusChangeConfirmOpen: '',
    bulkStatusUpdateInProgress: false,
    checkedUsersArray: [],

    bulkUserImportOpen: false,
    usersBulkImportStep: BulkImportUsersSteps.Initial,
};

function usersByIds(
    state: ItemsById<UserDTO> = initialState.usersByIds,
    action: UserActions,
): ItemsById<UserDTO> {
    switch (action.type) {
        case requestMultipleUsersInfo.successType:
        case requestUserList.successType:
            return {
                ...state,
                ...itemsByIds(action.payload.users),
            };
        case requestSelfUserInfo.successType:
        case requestUserUpdate.successType:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        default:
            return state;
    }
}

function reduceLoadingState(
    state: ItemsById<boolean> = initialState.usersLoadingById,
    userIds: string[],
    isLoading: boolean,
) {
    const newState = { ...state };
    userIds.forEach(userId => newState[userId] = isLoading);
    return newState;
}

function userIdRequireReactivationConfirmation(
    state: UserFormData | null = initialState.userFormData,
    action: UserSingleIdActionType
): UserFormData | null {
    switch (action.type) {
        case REQUEST_USER_REACTIVATION_CONFIRM:
            return action.payload;
        default:
            return state;
    }
}

function usersLoadingById(
    state: ItemsById<boolean> = initialState.usersLoadingById,
    action: UserActions
): ItemsById<boolean> {
    switch (action.type) {
        case requestSelfUserInfo.initType:
            return { ...state, [action.payload]: true };
        case requestSelfUserInfo.successType:
        case requestSelfUserInfo.errorType:
            return { ...state, [action.payload.id]: false };
        case requestMultipleUsersInfo.initType:
            return reduceLoadingState(state, action.payload, true);
        case requestMultipleUsersInfo.successType:
            return reduceLoadingState(state, action.payload.requestedIds, false);
        case requestMultipleUsersInfo.errorType:
            return reduceLoadingState(state, action.payload.ids, false);
        default:
            return state;
    }
}

function usersUpdatingById(
    state: ItemsById<boolean> = initialState.usersUpdatingById,
    action: UserActions
): ItemsById<boolean> {
    switch (action.type) {
        case requestUserUpdate.initType:
            return { ...state, [action.payload.id]: true };
        case requestUserUpdate.successType:
        case requestUserUpdate.errorType:
            return { ...state, [action.payload.id]: false };
        default:
            return state;
    }
}

function userRoles(state: UserRoleDTO[] = initialState.userRoles, action: CoreActions): UserRoleDTO[] {
    switch (action.type) {
        case requestUserRoles.successType:
        case requestCompanyRoles.successType:
            return action.payload;
        default:
            return state;
    }
}

function userRolesLoading(state: boolean = initialState.userRolesLoading, action: CoreActions): boolean {
    switch (action.type) {
        case requestUserRoles.initType:
        case requestCompanyRoles.initType:
            return true;
        case requestUserRoles.successType:
        case requestCompanyRoles.successType:
        case requestCompanyRoles.errorType:
        case requestUserRoles.errorType:
            return false;
        default:
            return state;
    }
}

function userCreating(state: boolean = initialState.userCreating, action: UserActions): boolean {
    switch (action.type) {
        case requestUserCreate.initType:
            return true;
        case requestUserCreate.successType:
        case requestUserCreate.errorType:
            return false;
        default:
            return state;
    }
}

function notificationsCount(
    state = initialState.notificationsCount,
    action: LegacyRequestActions,
): number {
    switch (action.type) {
        case requestNotificationsCount.successType:
            return action.payload;

        default:
            return state;
    }
}

function getStreamUserToken(
    state = initialState.getStreamUserToken,
    action: GetStreamActions,
): string | null {
    switch (action.type) {
        case requestStreamUserToken.successType:
            return action.payload;

        default:
            return state;
    }
}

function userListLoading(
    state: boolean = initialState.userListLoading,
    action: UserActions,
): boolean {
    switch (action.type) {
        case requestUserList.initType:
            return true;
        case requestUserList.successType:
        case requestUserList.errorType:
            return false;
        default:
            return state;
    }
}

function avatarUploadingExplicit(
    state: boolean = initialState.avatarUploadingExplicit,
    action: AvatarExplicitUpdateActions,
): boolean {
    switch (action.type) {
        case AVATAR_EXPLICIT_UPDATE:
            return action.payload;
        default:
            return state;
    }
}

function userListNextPageToken(
    state: string | null = initialState.userListNextPageToken,
    action: UserActions,
): string | null {
    if (action.type === requestUserList.successType) {
        return action.payload.nextPageToken;
    }
    return state;
}

function userListOrder(
    state: Array<string> = initialState.userListOrder,
    action: UserActions | UserActionType,
): Array<string> {
    switch (action.type) {
        case requestUserList.successType:
            return insertSavingOrder(state, action.payload.users.map(({ id }) => id));
        case RESET_USER_LIST:
            return initialState.userListOrder;
        default:
            return state;
    }
}

function avatarEditorOpened(
    state: boolean = initialState.avatarEditorOpened,
    action: AvatarActionsType,
): boolean {
    switch (action.type) {
        case OPEN_AVATAR_EDITOR:
            return action.payload;
        default:
            return state;
    }
}

function checkedUserItems(
    state: CheckedItems = initialState.checkedUsers,
    action: CheckerActions,
): CheckedItems {
    switch (action.type) {
        case SET_USERS_CHECKED_ITEMS:
            return action.payload;
        default:
            return state;
    }
}

function checkedUserItemsArray(
    state: Array<string> = initialState.checkedUsersArray,
    action: CheckerActions,
): Array<string> {
    switch (action.type) {
        case SET_USERS_CHECKED_ITEMS:
            let results = [];
            for (let a in action.payload) {
                if (action.payload[a]) {
                    results.push(a);
                }
            }
            return results;
        default:
            return state;
    }
}

function openedBulkUserStatusChangeConfirm(
    state: string = initialState.bulkOpenStatusChangeConfirmOpen,
    action: BulkUserUpdateActions,
): string {
    switch (action.type) {
        case (SET_BULK_USER_STATUS_CHANGE_CONFIRM):
            return action.payload;
        default:
            return state;
    }
}

function openedUserImportModal(
    state: boolean = initialState.bulkUserImportOpen,
    action: ImportActionsType,
): boolean {
    switch (action.type) {
        case (OPEN_USER_IMPORT_MODAL):
            return action.payload;
        default:
            return state;
    }
}

function bulkUsersImportStep(
    state: BulkImportUsersSteps = initialState.usersBulkImportStep,
    action: ImportActionsType,
): BulkImportUsersSteps {
    switch (action.type) {
        case (SET_BULK_IMPORT_USERS_STEP):
            return action.payload;
        case OPEN_USER_IMPORT_MODAL:
            return BulkImportUsersSteps.Initial;
        default:
            return state;
    }
}

function bulkUsersStatusUpdating(
    state: boolean = initialState.bulkStatusUpdateInProgress,
    action: UserActions,
): boolean {
    switch (action.type) {
        case requestUserBulkStatusChange.initType:
            return true;
        case requestUserBulkStatusChange.successType:
        case requestUserBulkStatusChange.errorType:
            return false;
        default:
            return state;
    }
}

export const user = combineReducers({
    usersByIds,
    usersLoadingById,
    usersUpdatingById,
    userRoles,
    userRolesLoading,
    notificationsCount,
    getStreamUserToken,
    userCreating,
    userListLoading,
    userListNextPageToken,
    userListOrder,
    userIdRequireReactivationConfirmation,
    avatarEditorOpened,
    avatarUploadingExplicit,
    checkedUserItems,
    openedBulkUserStatusChangeConfirm,
    bulkUsersStatusUpdating,
    checkedUserItemsArray,
    openedUserImportModal,
    bulkUsersImportStep,
});
