import { Fade, SvgIconTypeMap, Tooltip } from '@material-ui/core';
import React from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const TaskBulkHistoryTooltipCell: React.FC<{value: string, IconRender: OverridableComponent<SvgIconTypeMap>}> = ({ value, IconRender }) => {
  return (
    <Tooltip title={value} arrow placement='top' TransitionComponent={Fade}>
      <IconRender />
    </Tooltip>
  )
}
