import { createSingleAction } from 'utils/store/actionUtils';
import { IStoreTaskSetActiveId } from './storeTaskModel';

export const STORE_TASK_MODAL_SET_TASK_ID = 'task/store/MODAL_SET_TASK_ID';

export const storeTaskSetActiveId = createSingleAction<IStoreTaskSetActiveId | null, typeof STORE_TASK_MODAL_SET_TASK_ID>(
    STORE_TASK_MODAL_SET_TASK_ID,
);

export type StoreTaskActions = (
    ReturnType<typeof storeTaskSetActiveId>
);
