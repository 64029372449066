
import clsx from "clsx";
import { Trans } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { useConversationStyles } from "./conversationStyles";
import { useButtonStyles } from "utils/styles/buttons";

type FormActionsProps = {
    isInProgress?: boolean;
    onClickCancel: () => void;
};

export const TextConversationEditorActions = ({ isInProgress, onClickCancel }: FormActionsProps) => {
    const classes = useConversationStyles();
    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');

    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mt="25px"
        >
            {!isInProgress ? (
                <>
                    <Button
                        style={{ marginRight: '12px' }}
                        className={clsx(saveButtonClasses, classes.buttonActionTextEditor)}
                        type="submit"
                    >
                        <Trans>Save</Trans>
                    </Button>
                    <Button
                        className={clsx(buttonClasses.muiRoundedButton, classes.buttonActionTextEditor)}
                        onClick={onClickCancel}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                </>)
                :<CircularProgress className={classes.saveConversationProgress} />
            }
        </Box>
    );
};
