import { createSingleAction } from 'utils/store/actionUtils';
import { TaskListSortBy } from 'store/modules/task/taskListComponent/taskListComponentModel';

export const TASK_LIST_SET_PARENT_TASKS_SORTING = 'task_list/SET_PARENT_TASKS_SORTING';
export const parentTaskListSetSorting = createSingleAction<TaskListSortBy, typeof TASK_LIST_SET_PARENT_TASKS_SORTING>(
    TASK_LIST_SET_PARENT_TASKS_SORTING,
);

export const TASK_LIST_SET_STORE_TASKS_SORTING = 'task_list/SET_STORE_TASKS_SORTING';
export const storeTaskListSetSorting = createSingleAction<TaskListSortBy, typeof TASK_LIST_SET_STORE_TASKS_SORTING>(
    TASK_LIST_SET_STORE_TASKS_SORTING,
);

export const TASK_LIST_RESET_DATA = 'task_list/RESET_DATA';
export const taskListResetData = createSingleAction<void, typeof TASK_LIST_RESET_DATA>(
    TASK_LIST_RESET_DATA,
);

export const TASK_LIST_REMOVE_STORE = 'task_list/REMOVE_STORE';
export const taskListRemoveStore = createSingleAction<string, typeof TASK_LIST_REMOVE_STORE>(
    TASK_LIST_REMOVE_STORE,
);

export const TASK_LIST_REMOVE_PARENTS_CHILDREN = 'task_list/REMOVE_PARENTS_CHILDREN';
export const taskListRemoveParentsChildren = createSingleAction<{parent: string, childrenId: string }, typeof TASK_LIST_REMOVE_PARENTS_CHILDREN>(
    TASK_LIST_REMOVE_PARENTS_CHILDREN,
);

export const TASK_PARENT_ACTIVE = 'task_list/TASK_PARENT_ACTIVE';
export const taskParentActive = createSingleAction<string, typeof TASK_PARENT_ACTIVE>(
    TASK_PARENT_ACTIVE,
);
export const SET_STORE_TASK_TO_REMOVE_ID = 'SET_STORE_TASK_TO_REMOVE_ID';

export const setStoreTaskToRemove = (
    createSingleAction<string | null, typeof SET_STORE_TASK_TO_REMOVE_ID>(SET_STORE_TASK_TO_REMOVE_ID)
);

export type TaskListComponentActions =
    | ReturnType<typeof parentTaskListSetSorting>
    | ReturnType<typeof storeTaskListSetSorting>
    | ReturnType<typeof taskListResetData>
    | ReturnType<typeof taskListRemoveStore>
    | ReturnType<typeof taskListRemoveParentsChildren>
    | ReturnType<typeof taskParentActive>
    | ReturnType<typeof setStoreTaskToRemove>
