import React from 'react';
import { formatDateCustom, timeFormat } from 'utils/helpers/dateHelper';
import { IDateTimeDisplay } from './model';
import { StyledDateTimeWrapper } from './styles';

export const TimeDisplay: React.FC<IDateTimeDisplay> = ({ dateTime }) => {
    return (
        <StyledDateTimeWrapper textcolor="secondary">
            {formatDateCustom(dateTime, timeFormat)}
        </StyledDateTimeWrapper>
    );
};
