import {
    AppConfigurationClient,
    ConfigurationSetting,
    FeatureFlagValue,
    featureFlagPrefix,
} from "@azure/app-configuration";
import { appConfigConnectionString } from "utils/globals";

const featureConfigPrefix = ".appconfig.featureconfig/";

export const client: AppConfigurationClient = new AppConfigurationClient(appConfigConnectionString);

export const getSettingValue = <T>(setting: ConfigurationSetting) => {
    if (!setting.value) {
        throw new Error('missing configuration value')
    }

    return JSON.parse(setting.value) as T;
};

export const isFeatureFlag = (setting: ConfigurationSetting) => setting.key.startsWith(featureFlagPrefix);
export const isFeatureConfig = (setting: ConfigurationSetting) => setting.key.startsWith(featureConfigPrefix);

export const listFeatureFlags = async (appConfigClient: AppConfigurationClient) => {
    const featureFlags: { [key: string]: { enabled?: boolean, config?: unknown } | undefined } = {};

    for await (const setting of appConfigClient.listConfigurationSettings({ keyFilter: '.appconfig.feature*' })) {
        try {
            if (isFeatureFlag(setting)) {
                const flag = getSettingValue<FeatureFlagValue>(setting);
                if (flag?.id) {
                    featureFlags[flag.id] = {
                        ...featureFlags[flag.id],
                        enabled: flag.enabled,
                    };
                }
            } else if (isFeatureConfig(setting)) {
                const [, flagId] = setting.key.split(featureConfigPrefix);
                featureFlags[flagId] = {
                    ...featureFlags[flagId],
                    config: getSettingValue(setting),
                };
            }
        } catch { }
    }

    return Object.keys(featureFlags).map(featureFlag => ({
        id: featureFlag,
        enabled: !!featureFlags[featureFlag]?.enabled,
        config: featureFlags[featureFlag]?.config,
    }));
};
