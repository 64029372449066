import { RoleSettingsDTO } from 'api/knock/knockModel';
import { NotificationRowData } from './model';
import { settingsToRowData } from './helpers';

export const UPDATE_ROW = 'update-row';
export const RESET = 'reset';

export type UpdateRowAction = {
    type: typeof UPDATE_ROW,
    payload: NotificationRowData,
}

export type ResetAction = {
    type: typeof RESET,
    payload: RoleSettingsDTO,
}

export type SettingsGridAction =
    | UpdateRowAction
    | ResetAction;

export const updateRow = (row: NotificationRowData): UpdateRowAction => ({
    type: UPDATE_ROW,
    payload: row,
});

export const reset = (settings: RoleSettingsDTO): ResetAction => ({
    type: RESET,
    payload: settings,
});

export const reducer = (state: NotificationRowData[], action: SettingsGridAction): NotificationRowData[] => {
    switch (action.type) {
        case UPDATE_ROW:
            const rowData = action.payload;
            const idx = state.findIndex(item => item.notificationType === rowData.notificationType);
            return Object.assign(state.slice(), { [idx]: rowData });
        case RESET:
            return settingsToRowData(action.payload);
        default:
            return state;
    }
};
