import React, { Suspense, useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { Box, Button, Typography } from '@material-ui/core';
import { useConversationStyles } from "./conversationStyles";
import { ConversationType } from 'api/task/taskConversation/taskConversationModel';
import { AttachmentsGallery } from 'modules/task/attachments/components/AttachmentsGallery/AttachmentsGallery';
import { Trans } from 'react-i18next';
import { getUserName } from 'utils/helpers/user';
import { useSelector } from 'react-redux';
import { selectUsersByIds } from 'store/modules/user/userSelectors';
import { fullDateByBrowser } from 'utils/helpers/dateHelper';
import { useActions } from 'utils/store/useActions';
import { setTaskCommentToRemove } from 'store/modules/task/taskConversation/taskConversationActions';
import { ReactionComment } from './ReactionComment';
import { selectSuggestionsFetching } from 'store/modules/task/taskConversation/taskConversationSelectors';

type RichTextCommentProps = {
    conversation: ConversationType;
    onEdit: () => void;
    storeTaskId: string;
}

const RichTextComment: React.FC<RichTextCommentProps> = ({
    conversation, onEdit, storeTaskId
}) => {
    const isSuggestionsFetching = useSelector(selectSuggestionsFetching);
    const editorRef = useRef<ReactQuill | null>(null);
    const classes = useConversationStyles();
    const user = useSelector(selectUsersByIds)[conversation.createdBy];
    const actions = useActions({ setTaskCommentToRemove });

    useLayoutEffect(() => {
        focusEditor();
    },[])

    const focusEditor = (position = 0) => {
        setTimeout(() => {
            if (editorRef.current) {
                const quill = editorRef.current.getEditor();
                quill.setSelection(position, 0);
                editorRef.current.focus();
            }
        }, 0);
    };

    const dateCreateAt = useMemo(() => 
        fullDateByBrowser(conversation.createdAt)
        ,[conversation.createdAt]
    );

    const handleDeleteComment = useCallback(()=> actions.current.setTaskCommentToRemove(conversation.id), [conversation, actions]);

    return (
        <Box width="100%">
            <Box display="flex" alignItems="center" style={{gap:6}}>
                {user && <Typography variant="body2" style={{fontWeight: "bold"}}>{getUserName(user)}</Typography> }
                <Typography variant="body2" >{dateCreateAt}</Typography>
                {conversation.isModified && <Typography variant="body2" style={{fontWeight: "bold"}}><Trans>Edited</Trans></Typography>}
            </Box>
            <Suspense fallback={null}> 
                <ReactQuill
                    theme="snow"
                    value={conversation.text}
                    ref={editorRef}
                    modules={{ toolbar: false }}
                    formats={EditorFormats}
                    preserveWhitespace
                    className={`${classes.texCommentSingleMessage}`}
                    readOnly={true}
                />
                <AttachmentsGallery attachments={conversation.attachments}/>
            </Suspense>
            <Box display="flex" justifyContent="start" alignItems="center" mt={1} height={24}>
                {conversation.canEdit &&
                    <>
                        <Button onClick={onEdit} color='secondary' size='small' className={classes.actionsButtonComment} disabled={isSuggestionsFetching}><Trans>Edit</Trans></Button>
                        <Box ml={.5} mr={.5} style={{transform: 'translateY(-4px)'}}>.</Box>
                        <Button onClick={handleDeleteComment} color='secondary' size='small' className={classes.actionsButtonComment}><Trans>Delete</Trans></Button>
                        <Box ml={.5} mr={.5} style={{transform: 'translateY(-4px)'}}>.</Box>
                    </>
                }
                <ReactionComment conversation={conversation} storeTaskId={storeTaskId}/>
            </Box>  
        </Box>
    )
}

const EditorFormats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'mention',
    'color',
    'align'
];

export const TextComment = React.memo(RichTextComment);
