import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { requestStoreTasksDelete } from 'api/task/parentTask/parentTaskActions';
import { useActions } from "utils/store/useActions";
import { StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { Trans } from 'react-i18next';
import { selectActiveParentTaskId, selectChildrenTasks, selectStoreTaskToRemove } from 'store/modules/task/taskListComponent/taskListComponentSelectors';
import { ParentTaskChildModel, ParentTaskDetailsModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { CancelButton, DeleteButton, DialogDescription, StyledDialogActions, TitleWrapper } from '../ParentTasksView/RemoveParentTaskConfirmation';
import { selectParentTasksById } from 'store/modules/task/parentTask/parentTaskSelectors';
import { ParentTaskShort } from 'api/task/parentTask/parentTaskModel';
import { setStoreTaskToRemove } from 'store/modules/task/taskListComponent/taskListComponentActions';
import { t } from 'i18next';

type ModalProps = {
    parentTask: ParentTaskShort | ParentTaskDetailsModel;
    taskByLocation: ParentTaskDetailsModel | ParentTaskChildModel | StoreTaskFullModel
}

const Modal: React.FC<ModalProps> = ({ parentTask, taskByLocation }) => {
    const actions = useActions({ setStoreTaskToRemove, requestDeleteStoreTask: requestStoreTasksDelete.init });
    const handleClickNo = useCallback(() => actions.current.setStoreTaskToRemove(null), [actions]);
    const handleClickYes = useCallback(() => {
        actions.current.requestDeleteStoreTask(taskByLocation.id);
    }, [actions, taskByLocation]);

    return (
        <Dialog open>
            <DialogTitle disableTypography>
                <TitleWrapper>
                    <Trans>Delete this task</Trans> <strong>{parentTask?.title }</strong> <Trans>from the</Trans> <strong>{taskByLocation?.locationName }</strong> {t('store', {count: 0})}
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <DialogDescription>
                    <Trans>You will not be able to undo this. Do you want to proceed?</Trans>
                </DialogDescription>
            </DialogContent>
            <StyledDialogActions>
                <CancelButton onClick={handleClickNo}><Trans>Cancel</Trans></CancelButton>
                <DeleteButton onClick={handleClickYes}><Trans>Delete</Trans></DeleteButton>
            </StyledDialogActions>
        </Dialog>
    );
};

export const RemoveTaskByLocationConfirmation: React.FC = () => {
    const parentTaskId = useSelector(selectActiveParentTaskId)
    const storeTaskIdToRemoveByLocation = useSelector(selectStoreTaskToRemove);
    const parentTask = useSelector(selectParentTasksById)[parentTaskId || ''];
    const storeTaskToRemoveByLocation = useSelector(selectChildrenTasks)[storeTaskIdToRemoveByLocation || ''];

    if (!parentTask || !storeTaskToRemoveByLocation) {
        return null;
    }

    return (
        <Modal 
            parentTask={parentTask}
            taskByLocation={storeTaskToRemoveByLocation}
        />
    );
};
