import { IStoreState } from 'store/rootReducer';

export const selectStoreTasksSortBy = (state: IStoreState) => state.task.taskListComponent.storeTasksSortBy;
export const selectParentTasksSortBy = (state: IStoreState) => state.task.taskListComponent.parentTasksSortBy;
export const selectChildrenTasks = (state: IStoreState) => state.task.taskListComponent.childrenTasksById;
export const selectParentChildrenTasks = (state: IStoreState) => state.task.taskListComponent.parentChildrenTasks;
export const selectParentChildrenTasksLoading =
    (state: IStoreState) => state.task.taskListComponent.parentChildrenTasksLoading;
export const selectParentTaskDetailsLoading =
    (state: IStoreState) => state.task.taskListComponent.parentTaskDetailsLoading;
export const selectStoreTasksDeleteLoading =
    (state: IStoreState) => state.task.taskListComponent.storeTaskDeleteByIdLoading;
export const selectActiveParentTaskId =
    (state: IStoreState) => state.task.taskListComponent.activeParentTaskId;
export const selectStoreTaskToRemove = (state: IStoreState) => state.task.taskListComponent.storeTaskToRemove;
