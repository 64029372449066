import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { UtilColors } from 'utils/styles/constants';

const useStyles = makeStyles(() => ({
    loader: {
        backgroundColor: UtilColors.White,
        position: 'absolute',
        top: 0, left: 0, bottom: 0, right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export function LegacyPageLoader() {
    const classes = useStyles();

    return (
        <Box className={classes.loader}>
            <CircularProgress/>
        </Box>
    );
}
