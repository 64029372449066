import { IResponseErrorPayload } from 'model/error';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes } from 'utils/store/actionUtils';

const TASK_GET_INCOMPLETE_COUNT = 'task/GET_INCOMPLETE_COUNT';
export const requestIncompleteTasksCount = (
    createApiActionsByName<void, number, IResponseErrorPayload, typeof TASK_GET_INCOMPLETE_COUNT>(
        TASK_GET_INCOMPLETE_COUNT,
    )
);

const USER_TASK_GET_INCOMPLETE_COUNT = 'userTask/GET_INCOMPLETE_COUNT';
export const requestIncompleteUserTasksCount = (
    createApiActionsByName<void, number, IResponseErrorPayload, typeof USER_TASK_GET_INCOMPLETE_COUNT>(
        USER_TASK_GET_INCOMPLETE_COUNT,
    )
);

export type TaskCommonActions = (
    ActionsReturnTypes<typeof requestIncompleteTasksCount>
    | ActionsReturnTypes<typeof requestIncompleteUserTasksCount>
);
