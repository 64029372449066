import React from 'react';
import { Box } from '@material-ui/core';
import { DisplayedTaskStatus } from 'api/task/common/taskCommonModel';
import { StoreTaskFullModel } from 'api/task/storeTask/storeTaskModel';
import { ApproveTaskButton, DeclineTaskButton } from './buttons';
import { StickyPanel } from './StickyPanel';
import { useActions } from "utils/store/useActions";
import { requestApproveStoreTask } from 'api/task/storeTask/storeTaskActions';
import { useSelector } from 'react-redux';
import { selectApprovableByUserTasks, selectUpdatingStoreTasks } from 'store/modules/task/storeTask/storeTaskSelectors';
import { selectHasAppAccess } from 'store/modules/apps/appsSelector';
import { AppName } from 'store/modules/apps/appsModel';
import { useMediaBefore } from 'utils/hooks/useMediaBefore';
import { Breakpoints } from 'utils/styles/constants';
import { TaskDecline } from './taskDecline/TaskDecline';

export type TaskApproveDeclineProps = {
    task: StoreTaskFullModel | undefined;
}

export const TaskApproveDecline: React.FC<TaskApproveDeclineProps> = ({ task }) => {
    const isMobile = useMediaBefore(Breakpoints.MobileLarge);
    const approvableByUserTasks = useSelector(selectApprovableByUserTasks);
    const updatingStoreTasks = useSelector(selectUpdatingStoreTasks);
    const canCreateDelete = useSelector(selectHasAppAccess(AppName.TasksCreateDelete));
    const actions = useActions({ approve: requestApproveStoreTask.init });
    const handleApproveClick = () => actions.current.approve(task?.id || '');

    if (!canCreateDelete || !approvableByUserTasks[task?.id || '']) {
        return null;
    }

    if (!task || (task.displayedStatus !== DisplayedTaskStatus.Submitted
        && task.displayedStatus !== DisplayedTaskStatus.SubmittedOverdue)) {
        return null;
    }

    if (updatingStoreTasks.some(id => id === task.id) || !task?.id) {
        return (
            <StickyPanel>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                    alignItems="center"
                >
                    <DeclineTaskButton disabled/>
                    <Box pr="25px"/>
                    <ApproveTaskButton disabled/>
                </Box>

            </StickyPanel>
        );
    }

    return (
        <StickyPanel>
            <Box
                width="100%"
                display="flex"
                justifyContent={isMobile ? 'center' : 'flex-end'}
                alignItems="center"
            >
                <TaskDecline taskId={task.id}/>
                <Box pr="25px"/>
                <ApproveTaskButton onClick={handleApproveClick}/>
            </Box>
        </StickyPanel>
    );
};
