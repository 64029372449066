import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { VisibilityOff } from '@material-ui/icons';
import { useHideActiveChat } from '../../../../hooks/useHideActiveChat';
import { t } from 'i18next';

export function HideAction() {
    const { hideActiveChat } = useHideActiveChat();

    return (
        <Tooltip title={`${t('Hide Conversation')}`}  arrow placement="bottom">
            <IconButton onClick={hideActiveChat}>
                <VisibilityOff color="primary" />
            </IconButton>
        </Tooltip>
    );
}
