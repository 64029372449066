import { grey } from '@material-ui/core/colors';
import { darken, makeStyles, Theme } from '@material-ui/core/styles';
import { BlueColor, UtilColors } from 'utils/styles/constants';

export const useTaskCreatorStyles = makeStyles((theme: Theme) => ({
    autocompleteCreator: {
        width: '100%',
        maxWidth: '300px',
        display: 'flex',
        justifyContent: 'center',
    },
    saveBtn: {
        backgroundColor: BlueColor.BluePrimaryMain,
        color: UtilColors.White,
        '&:hover': {
            backgroundColor: darken(BlueColor.BluePrimaryMain, 0.5),
            borderColor: BlueColor.BluePrimaryMain,
        },
    },
    cancelBtn: {
        backgroundColor: grey[300],
        color: UtilColors.Black,
        '&:hover': {
            backgroundColor: darken(grey[300], 0.05),
            color: theme.palette.text.primary,
        },
    }
}));
