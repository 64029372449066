import * as yup from 'yup';
import { attachmentSchema } from 'modules/task/common/form/validation';

export const addTaskConversationSchema = yup.object().shape({
    text: yup.string().required(),
    replyTo: yup.string().notRequired(),
    attachments: yup.array().of(attachmentSchema),
    mentionedUsers: yup.array().of(yup.string().notRequired())
});

export const getMentions = (text: string): string[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const mentionElements = doc.querySelectorAll('.mention');
    const dataIds: string[] = [];

    mentionElements.forEach((mentionElement) => {
        const dataId = mentionElement.getAttribute('data-id');
        if (dataId) {
            dataIds.push(dataId);
        }
    });

    return dataIds;
};
