import { Box, styled } from "@material-ui/core";
import { UserInfo } from "components/userInfo/UserInfo";
import { ReactNode } from "react";

const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const StyledLabel = styled('span')(({ theme }) => ({
    fontSize: '1rem',
    marginRight: theme.spacing(0.5),
}));

export type TaskHeaderUserDetailsProps = {
    label: string | ReactNode;
    userId: string;
};

export const TaskHeaderUserDetails = ({ userId, label }: TaskHeaderUserDetailsProps) => {
    return (
        <StyledBox>
            <StyledLabel>{label}:</StyledLabel>
            <UserInfo
                className="task-header-creator"
                userId={userId}
            />
        </StyledBox>
    )
};
