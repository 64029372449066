import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { BlueColor, UtilColors } from 'utils/styles/constants';

export const useActionButtonStyles = makeStyles((theme: Theme) => ({
    moreIcon: {
        color: UtilColors.TextSecondary,
    },
    menu: ({ width, marginLeft }: { width: number | 'auto', marginLeft: number }) => ({
        marginLeft: width === 'auto' ? (theme.spacing(-1 * 20) / 2) - marginLeft : (theme.spacing(-1 * width) / 2) - marginLeft,
        width: width === 'auto' ? width : theme.spacing(width),
    }),
    menuItem: {
        '&:hover': {
            backgroundColor: UtilColors.Background,
        },
    },
    icon: {
        color: BlueColor.Blue70,
        marginRight: theme.spacing(1),
    },
}));
