import React from 'react';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { PageLoader } from 'components/router/PageLoader';
import { ThemeProvider } from '@material-ui/core';
import { newTextColorTheme } from 'modules/root/theme';
import { TasksFilterProvider } from './context/taskFilterContext';
import { CalendarTasksView } from './views/CalendarTasksView';

export const TaskListCalendarPage = () => {
    const currentUser = useCurrentUser();
    const isStore = currentUser?.isCompanyLocationStore;
    
    if (!currentUser) {
        return (
            <PageLoader />
        );
    }

    return (
        <ThemeProvider theme={newTextColorTheme}>
            <TasksFilterProvider isStore={isStore}>
                <CalendarTasksView locationId={currentUser.companyLocationId ?? ''} />
            </TasksFilterProvider>
        </ThemeProvider>
    );
};
