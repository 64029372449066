import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { createApiActionsByName } from 'utils/api/apiActionUtils';
import {
    IParentTaskChildren,
    ParentTaskDetailsModel,
} from './parentTaskDetailsModel';
import { IEntityError } from 'model/entity';

const PARENT_TASK_DETAILS_GET = 'tasks/PARENT_DETAILS_GET';

export const requestParentTaskDetails = createApiActionsByName<
    string, ParentTaskDetailsModel, IEntityError, typeof PARENT_TASK_DETAILS_GET
>(
    PARENT_TASK_DETAILS_GET
);

const PARENT_TASK_CHILDREN_GET = 'tasks/PARENT_CHILDREN_GET';

export const requestParentTaskChildren = createApiActionsByName<
    string, IParentTaskChildren, IEntityError, typeof PARENT_TASK_CHILDREN_GET
>(
    PARENT_TASK_CHILDREN_GET
);

const PARENT_DETAILS_COMBINED = 'tasks/PARENT_DETAILS_COMBINED';

export const requestParentTaskDetailsCombined = createApiActionsByName<
    string, void, IEntityError, typeof PARENT_DETAILS_COMBINED
>(PARENT_DETAILS_COMBINED);

export type TaskDetailsActions =
    | ActionsReturnTypes<typeof requestParentTaskDetails>
    | ActionsReturnTypes<typeof requestParentTaskChildren>
    | ActionsReturnTypes<typeof requestParentTaskDetailsCombined>;
