import React from 'react';
import { CellProps } from 'react-table';
import { Box, styled } from '@material-ui/core';
import { IParentTaskGridRowData } from '../model';
import { Skeleton } from '@material-ui/lab';
import { colorMap } from 'modules/task/taskList/model';
import { Breakpoints } from 'utils/styles/constants';
import { Trans } from 'react-i18next';

const StyledBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        fontSize: '10px',
        lineHeight: '12.1px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
}));

export const CompletionStatusCell: React.FC<CellProps<IParentTaskGridRowData, undefined>> = props => {
    if (props.row.original.isSkeleton) {
        return <Skeleton width="160px"/>;
    }

    const status = props.row.original.completionState || 'default';
    const color = status === 'default' ? undefined : colorMap[status];
    const boxProps = { color };
    return (
        <StyledBox {...boxProps}>
            <strong>
                {props.row.original.completedStoreTasks} <Trans>of</Trans> {props.row.original.storeTasks}
            </strong>
            &nbsp;
            <Trans>Stores completed</Trans>
        </StyledBox>
    );
};
