import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useTaskByLocationsHeaderStyles } from 'modules/task/taskList/views/TaskByLocationsView/hooks/useTaskByLocationsHeaderStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectParentTaskById } from 'store/modules/task/parentTask/parentTaskSelectors';
import { Skeleton } from '@material-ui/lab';
import { TaskHeaderDetails } from 'modules/task/taskList/components/TaskHeaderDetails/TaskHeaderDetails';
import { TaskIcon } from 'modules/task/singleTask/components/TaskIcon';
import { Box } from '@material-ui/core';
import { ApprovableHeaderIcon } from '../../components/ApprovableIcon';
import { ExpireHeaderIcon } from '../../components/ExpireIcon';
import { TagBoxColorTooltip } from 'modules/task/common/taglist/TagBoxColorTooltip';
import { useQueryParams } from 'utils/hooks';
import { selectParentTaskByLocationModalActiveId } from 'store/modules/task/calendarTask/calendarSelectors';
import { useActions } from 'utils/store/useActions';
import { parentTaskByLocationModalActiveId } from 'store/modules/task/calendarTask/calendarTaskActions';
import { StyledIconButton } from 'modules/task/common/TaskSlideout';

export function TaskByLocationsHeader() {
    const { uuid } = useParams<{ uuid: string }>();
    const parentTaskId = useSelector(selectParentTaskByLocationModalActiveId);
    const task = useSelector(selectParentTaskById)[uuid ?? parentTaskId];
    const classes = useTaskByLocationsHeaderStyles([]);
    const { taskGallery } = useQueryParams<{taskGallery: string}>();
    const actions = useActions({ setModalParentTaskId: parentTaskByLocationModalActiveId });
    const urlBackTo = taskGallery ? `/tasks/list?taskIdGallery=${uuid}` : '/tasks/list';

    return (
        <header>
            <div className={classes.infoWrapper}>
                { uuid ? 
                    <Link to={urlBackTo}>
                        <ArrowBack className={classes.backButton} />
                    </Link>
                : <StyledIconButton onClick={() => actions.current.setModalParentTaskId(null)}>
                    <ArrowBack />
                </StyledIconButton>
                }
                <Box ml={2} className={classes.info}>
                    <div className={classes.titleWrapper}>
                        {task ? (
                            <Box className={classes.headerTitleLocationContainer}>
                                <TaskIcon status={task.displayedStatus} />
                                <h2 className={classes.title}>
                                    {task.title}
                                    {task.isApproval && (
                                        <Box
                                            component="span"
                                            position="relative"
                                            top={4}
                                        >
                                            <ApprovableHeaderIcon />
                                        </Box>
                                    )}
                                    {!task?.isExpired && task?.isExpirable && (<Box
                                            component="span"
                                            position="relative"
                                            top={4}
                                        >
                                            <ExpireHeaderIcon />
                                        </Box>
                                    )}
                                    {task?.tag && (<Box display="flex" alignItems="center" ml={.5}><TagBoxColorTooltip tag={task?.tag} /></Box>)}
                                </h2>
                            </Box>
                        ) : (
                            <Skeleton height={32} width={250} />
                        )}
                    </div>

                    <TaskHeaderDetails
                        dueDate={task?.dueDate}
                        createdBy={task?.createdBy}
                        taskStatus={task?.displayedStatus}
                    />
                </Box>
            </div>
        </header>
    );
}
