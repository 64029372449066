import { generatePath, useParams } from 'react-router';
import { PageLoader } from 'components/router/PageLoader';
import { useSelector } from 'react-redux';
import { useActions } from 'utils/store/useActions';
import { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { EditEventForm } from './EditEventForm';
import { Routes } from 'components/router/model';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import { getPendingFiles } from 'store/modules/files/filesSelectors';
import { validateForms } from '../utils/validationPenddingFiles';
import { Trans } from 'react-i18next';
import { selectCalendarEventDetail, selectCalendarEventLoadingById, selectCalendarEventUpdatingById } from 'store/modules/task/calendarTask/calendarSelectors';
import { editCalendarEventSchema } from './model';
import { requestGetCalendarEvent, requestUpdateCalendarEvent } from 'api/task/calendarTask/calendaTaskActions';
import { CalendarEvent } from 'api/task/calendarTask/calendaTaskModel';

export function EditEventPage() {
    const { prototypeId } = useParams<{ prototypeId: string }>();
    const eventPrototype = useSelector(selectCalendarEventDetail);
    const isLoading = useSelector(selectCalendarEventLoadingById)[prototypeId];
    const isUpdating = useSelector(selectCalendarEventUpdatingById)[prototypeId];
    const pendingFiles = useSelector(getPendingFiles);
    const actions = useActions({
        getEventPrototype: requestGetCalendarEvent.init,
        updateEventPrototype: requestUpdateCalendarEvent.init,
    });

    const onSubmit = useCallback((values: CalendarEvent) => {
        actions.current.updateEventPrototype(values);
    }, [actions]);
    
    useEffect(() => {
        actions.current.getEventPrototype(prototypeId);
    }, [actions, prototypeId]);

    if (!eventPrototype || isLoading) {
        return <PageLoader />;
    }

    return (
        <FormPageView
            actionBar={<BackButton route={generatePath(Routes.TaskListCalendar)}><Trans>Back to Calendar</Trans></BackButton>}
        >
            <Formik
                initialValues={eventPrototype}
                validationSchema={editCalendarEventSchema}
                onSubmit={onSubmit}
                validate={() => validateForms(pendingFiles.length)}
            >
                {props => (
                    <EditEventForm
                        isUpdating={!!isUpdating}
                        handleSubmit={props.handleSubmit}
                    />
                )}
            </Formik>
        </FormPageView>
    );
}
