import React from 'react';
import { Box, styled } from '@material-ui/core';
import { CellProps } from 'react-table';
import { ChildTaskWithNested } from '../TaskByLocationsView';
import { Breakpoints } from 'utils/styles/constants';

type SubmittedCount = number | undefined;

const SubmittedWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down(Breakpoints.MobileLarge)]: {
        paddingRight: '48px',
        textAlign: 'right',
    },
}));

export const SubmittedCell: React.FC<CellProps<ChildTaskWithNested, SubmittedCount>> = ({
    value,
}) => (
    <SubmittedWrapper>
        { value }
    </SubmittedWrapper>
);
