import React from 'react';
import { FormPageView } from 'modules/shared/components/FormPageView';
import { BackButton } from 'modules/shared/components/BackButton';
import { UserEditForm } from './UserEditForm';
import { useDispatch, useSelector } from 'react-redux';
import { requestUserCreate } from 'api/user/userActions';
import { selectUserCreating } from 'store/modules/user/userSelectors';
import { UserFormData } from './model';
import { adminSubRoutes, Routes, UsersBetaAppName } from '../../../components/router/model';
import { EmbeddedSubRoutesTopMenu } from '../../../components/router/EmbeddedSubroutesTopMenu';
import { Box } from '@material-ui/core';
import { LanguageCode } from 'api/user/userModel';
import { Trans } from 'react-i18next';

const initialValues: UserFormData = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    employeeTitle: '',
    roleId: null,
    companyLocationId: null,
    language: LanguageCode.en,
};

export function AddUserPage() {
    const dispatch = useDispatch();
    const isCreating = useSelector(selectUserCreating);

    return (
        <FormPageView
            title={<Trans>Add User</Trans>}
            actionBar={
                <Box>
                    <EmbeddedSubRoutesTopMenu
                        apps={adminSubRoutes}
                        path={Routes.Admin}
                        currentAppName={UsersBetaAppName}
                    />
                    <Box marginTop='5px'>
                        <BackButton><Trans>Back to Users</Trans></BackButton>
                    </Box>
                </Box>
            }
            actionbarTopmost={true}
        >
            <UserEditForm
                initialValues={initialValues}
                onSubmit={(data) => dispatch(requestUserCreate.init(data))}
                isInProgress={isCreating}
            />
        </FormPageView>
    );
}
