import React from 'react';
import { FeatureFlags } from 'utils/featureFlags';
import { PageLoader } from '../PageLoader';
import { Routes } from '../model';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectFeatureFlags, selectFeatureFlagsLoading } from 'store/modules/featureFlags/selectors';
import { selectSelfId } from '../../../store/modules/auth/authSelectors';

export function withFeatureSwitch<TProps>(feature: FeatureFlags) {
    return (
        (WrappedComponent: React.FC<TProps>) =>
            (props: TProps) => {
                const isFeatureEnabled = useSelector(selectFeatureFlags)[feature];
                const user = useSelector(selectSelfId);
                const isFeatureLoading = useSelector(selectFeatureFlagsLoading);
                const displayLoader = !user && isFeatureLoading;

                if (displayLoader) {
                    return <PageLoader />;
                }
                if (!isFeatureEnabled?.enabled) {
                    return <Redirect to={Routes.NotFound} />;
                }
                return <WrappedComponent {...props} />;
            }
    );
}
