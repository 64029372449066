import React, { useCallback } from 'react';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import { ListItem, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { AttachmentItemAvatar, AttachmentItemMeta } from './attachmentItemElements';
import { resolveType } from 'utils/helpers/fileHelpers';
import { useActions } from 'utils/store/useActions';
import { removePendingFile } from 'store/modules/files/filesActions';

export type PendingAttachmentItemProps = {
    title: string;
    localKey: string;
    localFile: File;
}

export function PendingAttachmentItem({ title, localKey, localFile }: PendingAttachmentItemProps) {
    const actions = useActions({ removePendingFile });
    const handleRemoveClick = useCallback(() => {
        actions.current.removePendingFile(localKey);
    }, [actions, localKey]);

    return (
        <ListItem>
            <AttachmentItemAvatar />
            <AttachmentItemMeta
                title={title}
                size={localFile.size}
                type={resolveType(localFile.name, localFile.type)}
            />

            <ListItemSecondaryAction>
                <IconButton color="primary" onClick={handleRemoveClick}>
                    <ClearOutlinedIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
