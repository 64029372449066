import React, { useRef, useState } from 'react';
import { thumbnailHeight, useStyles } from './styles';
import noPreviewImageUrl from 'images/no-preview.png';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

export interface IFallbackImageProps {
    src: string;
    alt?: string;
    fallbackImageSrc?: string;
    fallbackImageHeigth?: number;
}

const ImageSkeleton = ({fallbackImageHeigth = thumbnailHeight}) => {
    return (
        <Box
            height="100%"
            width={fallbackImageHeigth}
            padding={1}
            boxSizing="border-box"
        >
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </Box>
    );
};

export function FallbackImage({ src, alt, fallbackImageSrc = noPreviewImageUrl, fallbackImageHeigth = thumbnailHeight }: IFallbackImageProps) {
    const classes = useStyles({fallbackImageHeigth});
    const [isLoading, setIsLoading] = useState(true);
    const ref = useRef<HTMLImageElement>(null);
    const handleError = () => {
        if (ref.current) {
            ref.current.src = fallbackImageSrc;
        }
    };

    return (
        <>
            <img
                className={classes.image}
                style={{ display: isLoading ? 'none' : '' }}
                ref={ref}
                alt={alt}
                src={src}
                onError={handleError}
                onLoad={() => setIsLoading(false)}
            />
            {isLoading && (
                <ImageSkeleton fallbackImageHeigth={fallbackImageHeigth}/>
            )}
        </>
    );
}
