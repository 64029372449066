import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { IResetStoresButtonProps } from '../types';
import { useResetStoresButtonStyles } from '../styles';

export function ResetStoresButton({ onClick }: IResetStoresButtonProps) {
    const classes = useResetStoresButtonStyles();
    return (
        <IconButton onClick={onClick} className={classes.resetButton}>
            <CloseIcon/>
        </IconButton>
    );
}
