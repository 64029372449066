import { Box, Button, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useButtonStyles } from "utils/styles/buttons";
import { useFormStyles } from "./formStyles";
import { Trans } from "react-i18next";
import { t } from "i18next";
import React from "react";

export type FormActionsProps = {
    isInProgress?: boolean;
    disabled?: boolean;
    btnSaveText?: string | React.ReactNode;
    btnAnotherText?: string | React.ReactElement;
    onClickBtnAnother?: () => void;
    onClickCancel?: () => void;
};

export const FormActions = ({ isInProgress, disabled, btnSaveText = `${t('Save')}`, btnAnotherText, onClickBtnAnother = ()=>{}, onClickCancel }: FormActionsProps) => {
    const history = useHistory();
    const classes = useFormStyles();
    const buttonClasses = useButtonStyles();
    const saveButtonClasses = clsx(buttonClasses.muiRoundedButton, 'primary');

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt="25px"
        >
            {!disabled && !isInProgress && btnAnotherText && (
                <Button
                    style={{ marginRight: '24px' }}
                    className={buttonClasses.muiRoundedButton}
                    onClick={onClickBtnAnother}
                >
                    { btnAnotherText }
                </Button>
            )}
            <Button
                onClick={()=> { 
                    if(onClickCancel){
                        onClickCancel();
                        return;
                    }
                    history.goBack();
                }}
                className={buttonClasses.muiRoundedButton}
            >
                <Trans>Cancel</Trans>
            </Button>

            {isInProgress && (
                <CircularProgress className={classes.createTaskItem} />
            )}
            {!disabled && !isInProgress && (
                <Button
                    style={{ marginLeft: '24px' }}
                    className={saveButtonClasses}
                    type="submit"
                >
                    { btnSaveText }
                </Button>
            )}
        </Box>
    );
};
