import { ActionsReturnTypes } from 'utils/store/actionUtils';
import { createApiActions } from 'utils/api/apiActionUtils';
import { AutomationTaskSearchRequest, AutomationTaskSearchResponse } from './automationTaskModel';
import { IResponseErrorPayload } from 'model/error';

const REQUEST_AUTOMATION_TASK_LIST = 'request/task/automation/GET_LIST';
const RESPONSE_AUTOMATION_TASK_LIST_SUCCESS = 'response/task/automation/GET_LIST_SUCCESS';
const RESPONSE_AUTOMATION_TASK_LIST_ERROR = 'response/task/automation/GET_LIST_ERROR';

export const requestAutomationTasks = (
    createApiActions<AutomationTaskSearchRequest, AutomationTaskSearchResponse, IResponseErrorPayload,
        typeof REQUEST_AUTOMATION_TASK_LIST,
        typeof RESPONSE_AUTOMATION_TASK_LIST_SUCCESS,
        typeof RESPONSE_AUTOMATION_TASK_LIST_ERROR
    >(
        REQUEST_AUTOMATION_TASK_LIST,
        RESPONSE_AUTOMATION_TASK_LIST_SUCCESS,
        RESPONSE_AUTOMATION_TASK_LIST_ERROR,
    )
);
export type AutomationTaskApiActions = (
    ActionsReturnTypes<typeof requestAutomationTasks>
);
