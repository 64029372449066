import { useCallback } from 'react';
import { useConnect } from '../../context/ConnectContext';
import { generatePath } from 'react-router-dom';
import { browserHistory, Routes } from 'components/router/model';
import { useChatContext } from 'stream-chat-react';

export function useHideActiveChat() {
    const { connectService } = useConnect() || {};
    const { channel } = useChatContext();
    const { conversationsService } = connectService || {};

    const hideActiveChat = useCallback(async () => {
        if (conversationsService && channel) {
            await channel.hide();
            browserHistory.push(generatePath(Routes.Connect));
            conversationsService.hideChat(channel.cid)
        }
    }, [conversationsService, channel]);

    return {
        hideActiveChat,
    };
}
