/* eslint-disable newline-per-chained-call */
import i18n from 'i18n';
import * as yup from 'yup';

export const editChannelValidationSchema = yup.object({
    channelId: yup.string().notRequired(),
    name: yup.string().trim().required().min(3).label(`${i18n.t('Channel name')}`),
    description: yup.string().trim().notRequired().max(500).label(`${i18n.t('Description')}`),
    frozen: yup.boolean().notRequired().label(`${i18n.t('Freeze Channel')}`),
    frozimageIden: yup.string().notRequired().label(`${i18n.t('Channel Image')}`),
}).required();

export type EditChannelValidationSchema = {
    name: string;
    description: string;
    frozen?: boolean;
    imageId?: string
    channelId: string;
}
