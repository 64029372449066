import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFile,
    faFileAlt,
    faFileArchive,
    faFileExcel,
    faFilePdf,
    faFileWord,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { thumbnailHeight, useStyles } from './styles';

interface INotSupportedThumbnailProps {
    fileExtension: string;
    notSupportedThumbnailHeight?: number;
}

const fileExtensionIconMap: Record<string, IconDefinition | undefined> = {
    'pdf': faFilePdf,
    'doc': faFileWord,
    'docx': faFileWord,
    'xls': faFileExcel,
    'xlsx': faFileExcel,
    'zip': faFileArchive,
    'log': faFileAlt,
};

export function NotSupportedThumbnail({ fileExtension, notSupportedThumbnailHeight = thumbnailHeight }: INotSupportedThumbnailProps) {
    const classes = useStyles({fallbackImageHeigth: notSupportedThumbnailHeight});
    const icon = fileExtensionIconMap[fileExtension] || faFile;

    return (
        <div className={classes.thumbnailPlaceholder}>
            <FontAwesomeIcon size="5x" icon={icon}/>
        </div>
    );
}
