import * as yup from 'yup';
import { colorSchema } from 'modules/task/common/form/validation';
import { CompanyType } from 'api/company/companyModel';
import { validateFileFormat } from 'utils/helpers/fileHelpers';
import i18n from 'i18n';

export const companyValidationSchema = yup.object({
    id: yup.string(),
    name: yup.string(),
    logoId: yup.string(),
    primaryColor: colorSchema
        .required(`${i18n.t('Primary color is required')}`),
    secondaryColor: colorSchema
        .required(`${i18n.t('Secondary color is required')}`)
});

export const messageErrorLightColor = `${i18n.t('Select another color')}`;
export const regExpLigthColor = (color:string) => /^#(?:[fF]{3,6}){1,2}$/.test(color);

export const validateForms = (values: CompanyType) => {
    if (regExpLigthColor(values.primaryColor as string)) {
        return { primaryColor: messageErrorLightColor };
    }
    if (regExpLigthColor(values.secondaryColor as string)) {
        return { secondaryColor: messageErrorLightColor };
    }
    return {};
}

const allowedFormats: string[] = ['jpg', 'png']

export const companyLogoSchema = yup.object({
    logoId: yup.string(),
    newLogo: yup.mixed()
        .required(`${i18n.t('File is a required field')}`)
        .test("file_format", `${i18n.t('Uploaded file has unsupported format.')}`, 
            value => !value || (value && validateFileFormat(value, allowedFormats))
        ),
    tenantId: yup.string()
});
