import React, { useCallback } from 'react';
import { Badge, Button, Fade, styled, Tooltip } from '@material-ui/core';
import { taskReviewModalOpen } from 'store/modules/task/taskReview/taskReviewActions';
import { useActions } from "utils/store/useActions";

import { useSelector } from 'react-redux';
import { selectTaskReviewCounter } from 'store/modules/task/taskReview/taskReviewSelectors';
import { UtilColors } from 'utils/styles/constants';
import reviewTaskIcon from 'images/review.png';
import { t } from 'i18next';

const StyledButton = styled(Button)({
    color: UtilColors.Red2,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
    minWidth: 44
});

const TasksToReviewButton: React.FC = () => {
    const counter = useSelector(selectTaskReviewCounter);
    const actions = useActions({
        openModal: taskReviewModalOpen,
    });

    const onClick = useCallback(() => {
        actions.current.openModal();
    }, [actions]);

    return (
        <StyledButton
            onClick={onClick}
            disabled={!counter}
            size='small'
        >
            <Tooltip title={`${counter} ${t('Tasks To Review')}`} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <Badge
                    badgeContent={counter}
                    max={99}
                    color="error"
                >
                    <img  src={reviewTaskIcon} alt={`${t('Tasks To Review')}`}/>
                </Badge>
            </Tooltip>
        </StyledButton>
    );
};

export default TasksToReviewButton;
