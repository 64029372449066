import { PageLoader } from 'components/router/PageLoader';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { ListPageLayout } from 'modules/shared/components/ListPageLayout';
import { Paper, styled } from '@material-ui/core';
import { Breakpoints } from 'utils/styles/constants';
import { IntegrationAISidekick } from 'modules/integrations/Integrations/IntegrationAISidekick';
import { SettingSidekick } from './SettingSidekick';
import { getIntegrations } from 'store/modules/integration/integrationActions';
import { useActions } from 'utils/store/useActions';
import { useEffect } from 'react';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: '100%',

    [theme.breakpoints.down(Breakpoints.Tablet)]: {
        marginTop: theme.spacing(1),
    },
}));

export const SidekickPage = () => {
    const currentUser = useCurrentUser();
    const actions = useActions({
        getIntegrations,
    });

    useEffect(() => {
        actions.current.getIntegrations();
    }, [actions]);

    if (!currentUser)
        return (
            <ListPageLayout width={'100%'}>
                <PageLoader />
            </ListPageLayout>
        );
    return (
        <ListPageLayout width={'100%'}>
            <StyledPaper>
                <IntegrationAISidekick />
                <SettingSidekick />
            </StyledPaper>
        </ListPageLayout>
    );
};
