import { Location, LocationTypes } from 'api/hierarchy/hierarchyModel';
import { HierarchyState } from './hierarchyReducer';

const getLocationStores = (locationId: string, { locationsByIds, locationsByParentId }: HierarchyState) => {
    function getStores(id: string): Array<Location> {
        const location = locationsByIds[id];

        if (!location) {
            return [];
        }

        const children = locationsByParentId[location.id];

        if (!children?.length) {
            return location.locationType === LocationTypes.Store ? [location] : [];
        }

        return children.reduce((acc: Array<Location>, item) => acc.concat(getStores(item.id)), []);
    }

    return getStores(locationId);
};

export const locationListGetStores = (hierarchy: HierarchyState, locationsIds: Array<string>) => {
    return locationsIds.map(id => getLocationStores(id, hierarchy)).flat();
};
