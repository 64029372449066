import { makeStyles } from "@material-ui/core";
import { BlueColor, UtilColors } from "utils/styles/constants";

export const useStylesViewSwtich = makeStyles({
    container: {
      height: 40
    },
  button: {
      whiteSpace: 'nowrap',

      "&.active": {
          color: BlueColor.Blue0,
          textDecoration: 'none',
          backgroundColor: UtilColors.HoverColor,
      },
      '&:hover': {
        backgroundColor: UtilColors.HoverColor,
      }
  },
  active: {
      color: BlueColor.Blue0,
      textDecoration: 'none',
      backgroundColor: UtilColors.HoverColor,
  },
});