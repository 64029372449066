import { put, select, takeLatest } from 'typed-redux-saga';
import { requestMultipleUsersInfo } from 'api/user/userActions';
import { selectUsersIds } from 'store/modules/user/userSelectors';
import difference from 'lodash/difference';
import { requestWatchers } from 'api/task/watcher/watcherActions';

function* watchersWatcher() {
    yield takeLatest(
        requestWatchers.successType,
        function* ({ payload: usersWatcherId }: ReturnType<typeof requestWatchers.success> ) {
            const userIds = new Set<string>(usersWatcherId);
            const loadedUserIds = yield* select(selectUsersIds);
            const idsToLoad = difference(Array.from(userIds), loadedUserIds);
            yield put(requestMultipleUsersInfo.init(idsToLoad));
        },
    );
}

export const watcherSagas = [
    watchersWatcher
];
