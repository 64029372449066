import { makeStyles, styled, Typography } from '@material-ui/core';

export const Required = styled(Typography)({
    display: 'inline-block',
    fontSize: '12px',
});

export const useTaskDeclineStyles = makeStyles(() => ({
    dialogPaper: {
        maxWidth: '528px',
        width: '100%',
    },
    dialogTitle: {
        padding: '16px 32px',
        fontSize: '20px',
        lineHeight: '32px',
        letterSpacing: '0.15px',
        fontWeight: 500,
    },
    dialogContentRoot: {
        padding: '0 32px',
    },
    reasonInput: {
        minHeight: '244px',
        alignItems: 'flex-start',
    },
    dialogActionsRoot: {
        padding: '13px 19px 19px',
    },
    dialogActionsSpacing: {
        '& > :not(:first-child)': {
            marginLeft: '25px',
        },
    },
}));
