import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Box, CircularProgress, Fade, IconButton, TextField, Tooltip } from '@material-ui/core';
import { StoreTaskChecklistModel } from 'api/task/storeTask/storeTaskModel';
import { Close, FileCopy as FileCopyIcon } from '@material-ui/icons';
import { KeyNames } from 'utils/globals';
import { useActions } from 'utils/store/useActions';
import { useSelector } from 'react-redux';
import { requestChecklistItemCustomResponseDelete, requestChecklistItemCustomResponseUpdate } from 'api/task/storeTask/storeTaskActions';
import { selectStoreTaskCustomResponseFetchingById } from 'store/modules/task/storeTask/storeTaskSelectors';
import { showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import { t } from 'i18next';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { StyledBoxChecklistAudit } from './StoreTaskChecklistAvatarCompleted';

interface IStoreTaskChecklistCustomResponseProps {
    checklistItem: StoreTaskChecklistModel,
    taskId: string | undefined,
    disabled?: boolean,
    isLoading?: boolean,
    showCopy?: boolean,
}

interface IActionCustomReponseBtnProps {
    disabled?: boolean,
    isDisabledClearValue: boolean,
    editMode: boolean,
    onCustomResponseFetching?: boolean,
    onClearValue: () => void,
}

const ActionCustomReponseBtn = ({ disabled, editMode, onCustomResponseFetching, onClearValue, isDisabledClearValue }: IActionCustomReponseBtnProps)=>{
    if(disabled || (editMode && !onCustomResponseFetching) || isDisabledClearValue ) return <></>;
    return (
    <>
        { !editMode && !onCustomResponseFetching?
            <IconButton aria-label="Close" onClick={onClearValue}>
                <Close fontSize="small" />
            </IconButton>
            :<CircularProgress size="25px"/>
        }
    </>)
}

export function StoreTaskChecklistCustomResponse({ checklistItem, taskId = '', disabled, showCopy = false }: IStoreTaskChecklistCustomResponseProps) {
    const actionCustomReponse = useActions({ 
        updateCustoResponse: requestChecklistItemCustomResponseUpdate.init,
        deleteCustoResponse: requestChecklistItemCustomResponseDelete.init,
        showMessage: showToastMessage,
    });
    const onCustomResponseFetching = useSelector(selectStoreTaskCustomResponseFetchingById)[checklistItem?.id];
    const [ editMode, setEditMode ] = useState<{status:boolean, isSubmitted: boolean}>({status:false, isSubmitted : false});
    const [ customResponse, setCustomResponse ] = useState<string>(checklistItem.customResponse || '');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const isTasksChecklistAuditEnabled = useFeatureState(FeatureFlags.TasksChecklistAudit);

    useEffect(()=>{
        if(editMode.isSubmitted && !onCustomResponseFetching){
            setCustomResponse(checklistItem?.customResponse || '');
            setEditMode({status:false, isSubmitted : false});
        }
        if(editMode.status && inputRef?.current && !editMode.isSubmitted){
            inputRef?.current?.focus();
        }
    }, [checklistItem, editMode, onCustomResponseFetching] )

    const handleInputChange = useCallback((valueName: string) => {
        setCustomResponse(valueName)
    },[])

    const deleteCustomResponse = useCallback(() => {
        setEditMode({status:true, isSubmitted : true})
        actionCustomReponse.current.deleteCustoResponse({taskId, checklistItemId: checklistItem.id, customResponse, itemName: checklistItem.name})    
    },[actionCustomReponse, taskId, checklistItem, customResponse])

    const handleSubmit = useCallback(()=>{
        if(customResponse === ''){
            deleteCustomResponse();
            return;
        }
        setEditMode({status:true, isSubmitted : true})
        actionCustomReponse.current.updateCustoResponse({taskId, checklistItemId: checklistItem.id, customResponse, itemName: checklistItem.name})
    },[actionCustomReponse, taskId, checklistItem, customResponse, deleteCustomResponse]);

    const handleKeyDown = useCallback(event => {
        if (event.key === KeyNames.Enter) {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit();
        }
    }, [handleSubmit]);

    const isDisabledBtn = useMemo(() => disabled || checklistItem.completed, [disabled, checklistItem]);

    const isDisabledField = useMemo(() => onCustomResponseFetching || isDisabledBtn, [onCustomResponseFetching, isDisabledBtn]);

    const handleOnClickField = useCallback(event => {
        event.stopPropagation();
        event.preventDefault();
        if(!isDisabledBtn && !editMode.status){
            setEditMode({status:true, isSubmitted : false});
        }
    }, [isDisabledBtn, editMode.status]);

    const handleClearValue = useCallback(() => {
        setCustomResponse('');
        deleteCustomResponse();
    },[deleteCustomResponse]);        

    const handleCopy = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(customResponse || '');
        actionCustomReponse.current.showMessage({
            message: `${t('Copied content')}: "${customResponse}"`, options: { variant: ToastVariant.Success, autoHideDuration: 2000 },
        })
    },[customResponse, actionCustomReponse]);

    const adornment = useMemo(()=>(
        <>
            {showCopy &&
                <Tooltip title="Copy" arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <IconButton aria-label="Copy" onClick={(e) => handleCopy(e)}>
                        <FileCopyIcon color="inherit"/>
                    </IconButton>
                </Tooltip>
            }
        </>
    ),[handleCopy, showCopy]);

    return (
        <Box display="flex" width="100%">
            {isTasksChecklistAuditEnabled && <StyledBoxChecklistAudit />}
            <Box ml={3} display="flex" justifyContent="space-between" alignItems="center" maxWidth={400} 
                sx={{ width: { sm: "100%", lg: '392px' }, }}>
                <TextField
                    disabled={isDisabledField}
                    id={`custom-response-description-${checklistItem.id}`}
                    name={`custom response description ${checklistItem.id}`}
                    onChange={ (event) => handleInputChange(event.target.value)}
                    onClick={handleOnClickField}
                    onKeyDown={handleKeyDown}
                    onBlur={handleSubmit}
                    value={customResponse}
                    variant="outlined"
                    placeholder={`${t('Record Here')}`}
                    aria-describedby={`${t('custom-response-description')}`}
                    fullWidth
                    inputProps={{ 
                        maxLength: 100,
                    }}
                    multiline
                    maxRows={4}
                    InputProps={{
                        endAdornment: adornment,
                        style:{padding: '8px 12px'}
                    }}
                    inputRef={inputRef}
                />
                <ActionCustomReponseBtn 
                    disabled={isDisabledBtn}
                    isDisabledClearValue={!!!checklistItem?.customResponse}
                    editMode={editMode.status}
                    onCustomResponseFetching={onCustomResponseFetching}
                    onClearValue={handleClearValue}
                />
            </Box>
        </Box>
    );
}
