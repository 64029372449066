import { useState, useRef, useCallback, ChangeEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, TextField } from '@material-ui/core';
import { Close, Done } from '@material-ui/icons';
import { TagBoxColor } from './TagBoxColor';
import { KeyNames } from 'utils/globals';
import { ITaskTag } from './model';
import { useActions } from 'utils/store/useActions';
import { requestUpdateTaskTag } from 'api/task/tag/tagActions';

const useStyles = makeStyles((theme: Theme) => ({
    inputColor: {
        visibility: 'hidden'
    },
    buttonAction: {
        padding: theme.spacing(.5, 0, .5, .5)
    },
    btnNewTag: {
        paddingLeft: 0,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        }
    }
}));

const MIN_TAG_NAME_LENGTH = 3;
type TagColorInputProps = {
    tagData: ITaskTag,
    onCloseTag:()=>void;
    isDisabled: boolean;
}

export const TagColorInput = ({ tagData, onCloseTag, isDisabled }: TagColorInputProps) => {
    const [tag, setTag] = useState<ITaskTag>(tagData)
    const inputColorRef = useRef<HTMLInputElement | null>(null);
    const classes = useStyles();
    const actionsTaskTags = useActions({
        updateTag: requestUpdateTaskTag.init,
    });
    
    const handlerColor = () => {
        inputColorRef.current?.click();
        inputColorRef.current?.focus();
    }

    const handlerSaveTag = useCallback(() => {
        actionsTaskTags.current.updateTag(tag);
    },[actionsTaskTags, tag])

    const handleColorChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setTag({...tag, color: event.target.value})
    },[setTag, tag])

    const handleInputChange = (valueName: string) => {
        setTag({...tag, name: valueName})
    }

    const handleKeyDown = useCallback(event => {
        if (event.key === KeyNames.Enter) {
            event.stopPropagation();
            event.preventDefault();
            handlerSaveTag();
        }
    }, [handlerSaveTag]);

    return (
        <Box display="flex" alignItems="center">
            <TagBoxColor bgcolor={tag.color} onClick={handlerColor}>
                <input ref={inputColorRef} className={classes.inputColor} id="input-color" type="color"onChange={handleColorChange} /> 
            </TagBoxColor>
            <TextField
                id="edit-tag-name"
                name="edit tag name"
                onChange={ (event) => handleInputChange(event.target.value)}
                onKeyDown={handleKeyDown}
                value={tag.name}
                variant="outlined"
                aria-describedby="edit-tag-name-description"
                inputProps={{ maxLength: 15 }}
            />
            <Box display="flex" flexDirection="column">
                <IconButton aria-label="Save" className={classes.buttonAction} onClick={handlerSaveTag} disabled={isDisabled || !!!tag?.name || tag?.name?.length < MIN_TAG_NAME_LENGTH}>
                    <Done fontSize="small" />
                </IconButton>
                <IconButton aria-label="Close" className={classes.buttonAction} onClick={onCloseTag} disabled={isDisabled}>
                    <Close fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
}
