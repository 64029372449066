import React from 'react';
import { Box } from '@material-ui/core';
import { useMainContentStyles } from 'components/mainContent/mainContentStyles';

export function withMainContent(ChildComponent: React.FC) {
    return function MainContent() {
        const classes = useMainContentStyles([]);

        return (
            <Box className={classes.root}>
                <ChildComponent/>
            </Box>
        );
    };
}
