import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Typography, Button, FormHelperText, CircularProgress, Box } from '@material-ui/core';
import { Publish, TableChart } from '@material-ui/icons';
import { PendingFile } from '../../../store/modules/files/filesModel';
import { useOpenedState } from '../../../utils/hooks/useOpenedState';
import { useUploadStyles } from './styles/uploadStyles';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export interface IFileInputProps {
    text?: string;
    note?: string;
    rejectMessage?: string | null;
    fileTypes: string | string[];
    error?: string | null;
    maxSizeMb: number;
    customClasses?: string;
    attachments: PendingFile[];
    onAttachmentChange: (files: File[]) => void;
    onAttachmentRemove: (file: PendingFile) => void;
    multiple?: boolean;
    isLoading?: boolean;
}

export default function FileInput({
    text = `${t('Upload')}`,
    note = '',
    rejectMessage = `${t('File type is not allowed.')}`,
    fileTypes,
    error,
    maxSizeMb,
    multiple = true,
    customClasses,
    attachments = [],
    onAttachmentChange,
    onAttachmentRemove,
    isLoading,
}: IFileInputProps) {
    const maxSize = maxSizeMb * 1024 * 1024;
    const classes = useUploadStyles();

    const {
        onOpen: onPreviewOpen,
        onClose: onPreviewClose,
    } = useOpenedState();

    const onDrop = useCallback(acceptedFiles => {
        onPreviewClose();
        onAttachmentChange(acceptedFiles);
    }, [onAttachmentChange, onPreviewClose]);

    const {
        getRootProps,
        getInputProps,
        isDragReject,
        fileRejections,
    } = useDropzone({
        accept: fileTypes,
        onDrop,
        noDrag: false,
        multiple: false,
        maxSize: maxSize,
        noDragEventsBubbling: true,
    });

    const isFileTooLarge = fileRejections.length > 0 && fileRejections.some(file => (file?.file?.size ?? 0) > maxSize);
    const canUploadMore = multiple || attachments.length === 0;

    const renderFilePreview = (attachment: PendingFile) => {
        return (
            <div className={clsx(classes.img, classes.previewImg)} style={{display: 'flex', alignItems: 'center'}}>
                <Box marginRight='5px'><TableChart/></Box>
                <Typography variant="subtitle2" align="center" className={classes.previewTitle}>
                    {attachments[0].title}
                </Typography>
            </div>
        );
    };

    return (
        <>
            {(attachments.length > 0) && (
                <section className={clsx(classes.preview, customClasses)}>
                    <div className={clsx(classes.filePreviewZone, { [classes.underLoading]: isLoading })}>
                        {attachments.length > 0 && renderFilePreview(attachments[0])}
                        {attachments.length > 1 && (
                            <Button
                                className = {classes.imageButton}
                                color="inherit"
                                onClick={onPreviewOpen}>
                                <Trans>See All</Trans> [{attachments.length}]
                            </Button>
                        )}
                    </div>
                    {isLoading && (
                        <Box className={classes.loader}>
                            <CircularProgress/>
                        </Box>
                    )}
                </section>
            )}

            {canUploadMore && (
                <section className={clsx(classes.fileUpload, customClasses)}>
                    <div
                        {...getRootProps()}
                        className={clsx(classes.fileDropzone, { [classes.error]: Boolean(error) })}
                    >
                        <div className={classes.uploadHeader}>
                            <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                            <Typography className={classes.uploadHeaderTitle}
                                        color="primary"
                                        variant="subtitle2"
                            >
                                {text}
                            </Typography>
                        </div>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            className={classes.note}
                        >
                            {note}
                        </Typography>
                        {isDragReject && rejectMessage && (
                            <Typography className={classes.uploadError}
                                        color="primary"
                                        variant="body1"
                            >
                                {rejectMessage}
                            </Typography>
                        )}
                        {isFileTooLarge && (
                            <Typography className={classes.uploadError}
                                        color="primary"
                                        variant="body1"
                            >
                                <Trans>File is too large</Trans>
                            </Typography>
                        )}
                    </div>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </section>
            )}
            <input {...getInputProps()} disabled={isLoading}/>
        </>
    );
}
