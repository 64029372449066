import React from 'react';
import { styled, Tooltip } from '@material-ui/core';
import { HowToRegOutlined } from '@material-ui/icons';
import { Trans } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const wrap = Icon => props => (
    <Tooltip title={<Trans>Task needs approval</Trans>}>
        <Icon {...props}/>
    </Tooltip>
);

export const ApprovableIcon = styled(wrap(HowToRegOutlined))({
    marginLeft: '9px',
    height: '14px',
    width: '14px',
});

export const ApprovableHeaderIcon = styled(wrap(HowToRegOutlined))({
    marginLeft: '10px',
    height: '24px',
    width: '24px',
});
