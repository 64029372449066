import { createApiActions } from 'utils/api/apiActionUtils';
import { ActionsReturnTypes, createSingleAction } from 'utils/store/actionUtils';
import { IEntityError, IEntitySuccess } from 'model/entity';
import { IResponseErrorPayload } from 'model/error';
import { ITaskTagUpdateSuccess, TaskTagType, TaskTagNewType } from './taskTagModel';

const REQUEST_TASK_TAG_GET = 'request/task/tags/GET';
const RESPONSE_TASK_TAG_GET_SUCCESS = 'response/task/tags/GET_SUCCESS';
const RESPONSE_TASK_TAG_GET_ERROR = 'response/task/tags/GET_ERROR';

export const requestTaskTags = createApiActions<void, Array<TaskTagType>, any, typeof REQUEST_TASK_TAG_GET,
    typeof RESPONSE_TASK_TAG_GET_SUCCESS, typeof RESPONSE_TASK_TAG_GET_ERROR
>(
    REQUEST_TASK_TAG_GET, RESPONSE_TASK_TAG_GET_SUCCESS, RESPONSE_TASK_TAG_GET_ERROR,
);

const REQUEST_TASK_TAG_UPDATE = 'request/task/tags/UPDATE';
const RESPONSE_TASK_TAG_UPDATE_SUCCESS = 'response/task/tags/UPDATE_SUCCESS';
const RESPONSE_TASK_TAG_UPDATE_ERROR = 'response/task/tags/UPDATE_ERROR';

export const requestUpdateTaskTag = (
    createApiActions<TaskTagType, ITaskTagUpdateSuccess, IResponseErrorPayload,
        typeof REQUEST_TASK_TAG_UPDATE,
        typeof RESPONSE_TASK_TAG_UPDATE_SUCCESS,
        typeof RESPONSE_TASK_TAG_UPDATE_ERROR
    >(
        REQUEST_TASK_TAG_UPDATE,
        RESPONSE_TASK_TAG_UPDATE_SUCCESS,
        RESPONSE_TASK_TAG_UPDATE_ERROR,
    )
);

const REQUEST_TASK_TAG_ADD = 'request/task/tags/ADD';
const RESPONSE_TASK_TAG_ADD_SUCCESS = 'response/task/ADD/ADD_SUCCESS';
const RESPONSE_TASK_TAG_ADD_ERROR = 'response/task/ADD/ADD_ERROR';

export const requestAddTaskTag = (
    createApiActions<TaskTagNewType, TaskTagType, IResponseErrorPayload,
        typeof REQUEST_TASK_TAG_ADD,
        typeof RESPONSE_TASK_TAG_ADD_SUCCESS,
        typeof RESPONSE_TASK_TAG_ADD_ERROR
    >(
        REQUEST_TASK_TAG_ADD,
        RESPONSE_TASK_TAG_ADD_SUCCESS,
        RESPONSE_TASK_TAG_ADD_ERROR,
    )
);

const REQUEST_TASK_TAG_DELETE = 'request/task/tags/DELETE';
const RESPONSE_TASK_TAG_DELETE_SUCCESS = 'response/task/tags/DELETE_SUCCESS';
const RESPONSE_TASK_TAG_DELETE_ERROR = 'response/task/tags/DELETE_ERROR';

export const requestDeleteTaskTag = (
    createApiActions<{id: string, isMock: boolean }, IEntitySuccess, IEntityError,
        typeof REQUEST_TASK_TAG_DELETE,
        typeof RESPONSE_TASK_TAG_DELETE_SUCCESS,
        typeof RESPONSE_TASK_TAG_DELETE_ERROR
    >(
        REQUEST_TASK_TAG_DELETE,
        RESPONSE_TASK_TAG_DELETE_SUCCESS,
        RESPONSE_TASK_TAG_DELETE_ERROR,
    )
);

export const TASK_TAG_FILTER_ACTIVE = 'task/tags/FILTER_ACTIVE';
export const addFilterTaskTag = createSingleAction<string[], typeof TASK_TAG_FILTER_ACTIVE>(
    TASK_TAG_FILTER_ACTIVE,
);

export type TaskTagActions = (
    | ActionsReturnTypes<typeof requestTaskTags>
    | ActionsReturnTypes<typeof requestUpdateTaskTag>
    | ActionsReturnTypes<typeof requestDeleteTaskTag>
    | ActionsReturnTypes<typeof requestAddTaskTag>
    | ReturnType<typeof addFilterTaskTag>
);
