import React from 'react';
import { useOpenedState } from 'utils/hooks/useOpenedState';
import { Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { TextInput } from 'components/basicInputs/textInput/TextInput';
import { StringSchema } from 'yup';
import { generateSchema } from 'components/basicInputs/inlineEdit/model';
import { makeStyles } from '@material-ui/core/styles';
import { KeyNames } from 'utils/globals';

interface IInlineEditProps {
    value: string;
    onChange: (newValue: string) => void;
    typographyClass?: string;
    title?: string;
    validation: StringSchema;
    showCounter?: boolean;
    maxLength?: number;
    disabled?: boolean;
}

const useInlineEditStyles = makeStyles(() => ({
    inputRoot: {
        flexGrow: 1,
    },
}));

export function InlineEdit({
    value,
    onChange,
    typographyClass,
    title,
    validation,
    showCounter,
    maxLength,
    disabled,
}: IInlineEditProps) {
    const classes = useInlineEditStyles([]);
    const { isOpened, onOpen, onClose } = useOpenedState();

    if (!isOpened || disabled) {
        return (
            <Typography className={typographyClass}
                title={title}
                onClick={disabled ? undefined : onOpen}
            >
                {value}
            </Typography>
        );
    }

    return (
        <Formik
            initialValues={{ value }}
            validationSchema={generateSchema(validation)}
            onSubmit={({ value: newValue }) => {
                if (validation.validateSync(newValue)) {
                    onClose();
                    onChange(newValue);
                }
            }}
        >
            {props => (
                <TextInput
                    name="value"
                    label=""
                    autoFocus
                    classes={{ root: classes.inputRoot }}
                    showAdornment={false}
                    separateLabel={false}
                    variant="standard"
                    onKeyDown={event => {
                        if (event.key === KeyNames.Enter) {
                            event.stopPropagation();
                            event.preventDefault();
                            props.handleSubmit();
                        }
                    }}
                    onBlur={() => {
                        props.handleSubmit();
                    }}
                    showCounter={showCounter}
                    maxLength={maxLength}
                />
            )}
        </Formik>
    );
}
