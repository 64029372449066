import React from 'react';
import clsx from 'clsx';
import { Cell, HeaderGroup } from 'react-table';
import { Box, TableSortLabel } from '@material-ui/core';
import { useTaskByLocationStyles } from 'modules/task/taskList/hooks/useTaskByLocationStyles';
import { ParentTaskChildModel } from 'api/task/parentTaskDetails/parentTaskDetailsModel';
import { TaskByLocationCellsIds } from 'modules/task/taskList/views/TaskByLocationsView/hooks/useTasksByLocationsColumns';
import TableCell from '@material-ui/core/TableCell';

interface ITaskByLocationCellRendererProps {
    classes: ReturnType<typeof useTaskByLocationStyles>;
    cell: Cell<ParentTaskChildModel>;
}

export function TaskByLocationCellRenderer({ cell, classes }: ITaskByLocationCellRendererProps) {
    const cellProps = cell.getCellProps();
    let className = '';

    if (cellProps.key.toString().includes(TaskByLocationCellsIds.Color)) {
        className = classes.colorCell;
    }

    return (
        <TableCell className={className} {...cellProps}>
            {cell.render('Cell')}
        </TableCell>
    );
}

interface ITaskByLocationHeaderCellRendererProps {
    classes: ReturnType<typeof useTaskByLocationStyles>;
    header: HeaderGroup<ParentTaskChildModel>;
}

export function TaskByLocationHeaderCellRenderer({ header, classes }: ITaskByLocationHeaderCellRendererProps) {
    const sortingProps = header.getSortByToggleProps();
    const cellProps = header.getHeaderProps(sortingProps);

    const { canSort, isSorted, isSortedDesc } = header;

    let className = classes.headerCell;

    if (cellProps.key.toString().includes(TaskByLocationCellsIds.Color)) {
        className = clsx(className, classes.colorCell);
    }

    const width = cellProps.key.toString().includes(TaskByLocationCellsIds.Location) ? undefined : header.width;

    return (
        <TableCell
            width={width}
            className={className}
            {...cellProps}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                {header.render('Header')}
                {canSort && (
                    <TableSortLabel
                        active={isSorted}
                        direction={isSortedDesc ? 'asc' : 'desc'}
                        title=""
                    />
                )}
            </Box>
        </TableCell>
    );
}
