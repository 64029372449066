import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { isProduction } from 'utils/globals';

/**
 * This component is intended to make side effects
 * depending on location key (hash of current item of browser history)
 */
export function LocationWatcher() {
    const location = useLocation();

    useEffect(() => {
        if (isProduction) {
            ReactGA.pageview(`${location.pathname}${location.search}`);
        }
    }, [location.pathname, location.search]);

    return null;
}
