import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DropzoneOptions } from 'react-dropzone';
import { getFileKey, validateFiles } from 'utils/helpers/fileHelpers';
import { useActions } from "utils/store/useActions";
import { addPendingConversationFiles, addPendingFiles, FILES_ADD_PENDING_FILES, FILES_ADD_PENDING_CONVERSATION_FILES } from './filesActions';
import { PendingFile, UploadedFile } from './filesModel';
import { getFilesConfig, getPendingFiles, getUploadedFiles } from './filesSelectors';
import { requestDownloadFile, requestUploadFile } from 'api/files/filesActions';
import { useSnackbar } from 'notistack';
import { ActionCreatorKnownArgs } from 'utils/store/actionUtils';
import { IStoreState } from 'store/rootReducer';

type AddPendingFilesType =  ActionCreatorKnownArgs<PendingFile[], {
    type: typeof FILES_ADD_PENDING_FILES;
    payload: PendingFile[];
}>;
type AddPendingConversationFilesType = ActionCreatorKnownArgs<PendingFile[], {
    type: typeof FILES_ADD_PENDING_CONVERSATION_FILES;
    payload: PendingFile[];
}>
export enum TypeUploadHandler {
    Task = 'task',
    TaskConversation = 'task-conversation'
}
export function useDropHandler(afterDrop?: () => void, 
    actionAddPendingFiles: AddPendingFilesType | AddPendingConversationFilesType = addPendingFiles): DropzoneOptions['onDrop']
{
    const actions = useActions({ addPendingFiles: actionAddPendingFiles });
    const handleDropFiles = useCallback((acceptedFiles: File[]) => {
        const attachments: PendingFile[] = acceptedFiles.map(file => ({
            localFile: file,
            localKey: getFileKey(file),
            title: file.name,
        }));
        actions.current.addPendingFiles(attachments);
        afterDrop && afterDrop();
    }, [actions, afterDrop]);
    return handleDropFiles;
}

export function useUploadHandler(
    selectGetPendingFiles: (state: IStoreState) => PendingFile[] = getPendingFiles,
    typeUploadHandler: TypeUploadHandler = TypeUploadHandler.Task
) {
    const { enqueueSnackbar } = useSnackbar();
    const pendingFiles = useSelector(selectGetPendingFiles);
    const actions = useActions({ upload: requestUploadFile.init });
    const filesConfig = useSelector(getFilesConfig);
    const handleFilesUpload = useCallback(() => {
        const message = validateFiles(pendingFiles.map(f => f.localFile), filesConfig?.maxFileSize);
        if (message) {
            enqueueSnackbar(message, { variant: 'error' });
            return;
        }
        if(pendingFiles.length) {actions.current.upload({pendingFiles, typeUploadHandler});
        }
    }, [actions, enqueueSnackbar, filesConfig?.maxFileSize, pendingFiles, typeUploadHandler]);
    return handleFilesUpload;
}

export function useAttachments(
    selectGetPendingFiles: (state: IStoreState) => PendingFile[] = getPendingFiles,
    selectGetUploadedFiles: (state: IStoreState) => UploadedFile[]  = getUploadedFiles
) {
    const pendingFiles = useSelector(selectGetPendingFiles);
    const uploadedFiles = useSelector(selectGetUploadedFiles);
    return { pendingFiles, uploadedFiles };
}

export function useDownloadFile(uuid?: string, name?: string) {
    const actions = useActions({
        getFile: requestDownloadFile.init,
    });
    const handleDownloadClick = useCallback(() => {
        uuid && name && actions.current.getFile({
            fileId: uuid ?? '',
            fileName: name ?? '',
        });
    }, [uuid, name, actions]);
    return handleDownloadClick;
}

export function useDropHandlerConversation(afterDrop?: () => void): DropzoneOptions['onDrop'] {
    const actions = useActions({ addPendingFiles: addPendingConversationFiles });
    const handleDropFiles = useCallback((acceptedFiles: File[]) => {
        const attachments: PendingFile[] = acceptedFiles.map(file => ({
            localFile: file,
            localKey: getFileKey(file),
            title: file.name,
        }));
        actions.current.addPendingFiles(attachments);
        afterDrop && afterDrop();
    }, [actions, afterDrop]);
    return handleDropFiles;
}