import { put, takeEvery } from 'typed-redux-saga';
import { updateTaskPrototype } from 'api/task/prototype/prototypeActions';
import { Routes } from 'components/router/model';
import { generatePath } from 'react-router-dom';
import { navigateTo, showToastMessage } from 'store/modules/appState/appStateActions';
import { ToastVariant } from 'store/modules/appState/appStateModel';
import i18n from 'i18n';

export function* updateTaskPrototypeWatcherSaga() {
    yield takeEvery(
        updateTaskPrototype.successType,
        function* () {
            yield put(navigateTo(generatePath(Routes.TaskList)));
            yield put(showToastMessage({
                message: i18n.t('Task Updated'),
                options: {
                    variant: ToastVariant.Success
                },
            }))
        },
    );
}

export const prototypesSagas = [
    updateTaskPrototypeWatcherSaga,
];
