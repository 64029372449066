import React, { PropsWithChildren } from 'react';
import { Box, Collapse, Fade } from '@material-ui/core';

export interface ICollapseBoxProps {
    show: boolean,
    timeoutMs?: number,
}

export function CollapseBox({
    show,
    timeoutMs = 300,
    children,
}: PropsWithChildren<ICollapseBoxProps>) {
    return (
        <Collapse in={show} timeout={timeoutMs}>
            <Fade in={show} timeout={timeoutMs}>
                <Box>
                    {children}
                </Box>
            </Fade>
        </Collapse>
    );
}
