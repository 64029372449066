import { CellProps } from "react-table";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Location } from "api/hierarchy/hierarchyModel";
import { useSelector } from "react-redux";
import {
    selectTasksStatsByLocationIds,
    selectTasksStatsLoadingByLocationIds,
} from 'store/modules/task/statistics/statisticsSelectors';
import { TaskCompletionPercentage } from './TaskCompletion';
import { TaskCounterCellProps } from "./TaskCounterCell";

export type TaskOverdueStatsCellProps = CellProps<Location, string>;

export const TaskOverdueStatsCell = (props: TaskCounterCellProps) => {
    const { id: locationId } = props.row.original;
    const tasksStatistics = useSelector(selectTasksStatsByLocationIds)[locationId];
    const isTasksStatisticsLoading = useSelector(selectTasksStatsLoadingByLocationIds)[locationId];
    const completedInTime = (tasksStatistics?.completed ?? 0) - (tasksStatistics?.completedOverdue ?? 0);

    if (isTasksStatisticsLoading || !tasksStatistics) {
        return (
            <Box>
                <Skeleton width="60%" />
                <Skeleton />
            </Box>
        )
    }

    return (
        <TaskCompletionPercentage
            total={tasksStatistics?.total ?? 0}
            completed={completedInTime}
        />
    );
};
